import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { AuthService } from "../../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { CompanyService } from "../../../services/Company";
import { TagService } from "../../../services/Tag";
import { Form } from "../../Shared/Form";
import { Validate } from "../../Shared/Validate";
import { Form as StyledForm } from "../../../styles/Form";
import {
  Container,
  Button,
  PageTitle,
  LinkClose,
} from "../../../styles/Common";
import { LoadingSubmit } from "../../../styles/Animation";

import imgCircleX from "../../../assets/circle-x.svg";
import { teamSizesBuild } from "../../Shared/Data";

export default function CompanyNew(props) {
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );

  const jwtInfo = AuthService.jwtInfo();

  //if user is not allowed to create user, will hv to redirect him then eh?
  let userCanAccessPage = false;
  if (jwtInfo && jwtInfo.roles.includes("Admin")) {
    userCanAccessPage = true;
  }

  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    reset,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);
  const [parentCompanies, setParentCompanies] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [initAvailableMarkets, setInitAvailableMarkets] = useState([]);
  const [initAvailableSpecialties, setInitAvailableSpecialties] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [initAvailableTechnologies, setInitAvailableTechnologies] = useState(
    []
  );
  const [technologies, setTechnologies] = useState([]);
  const initTeamSizes = teamSizesBuild;
  const teamSizes = initTeamSizes;
  const initSelectValues = {
    parentCompany: [],
    markets: [],
    specialties: [],
    technologies: [],
    size: [],
  };
  const [selectValues, setSelectValues] = useState(initSelectValues);

  //create new select option
  const onCreateOption = (value, name) => {
    let opt = { label: value, value: "-newCompany-", data: name };
    setParentCompanies([...parentCompanies, opt]);
    console.log("opt", opt);
    setValue(name, "-newCompany-"); //for react-hook
    setValue("newParentCompanyName", value);
    selectValues[name] = opt;
    setSelectValues({ ...selectValues });
  };
  const onSelectChange = (opt, actionMeta) => {
    console.log("opt", opt);
    console.log("actionMeta", actionMeta);
    let name = actionMeta.name;

    let value;
    //dropdown
    if (!opt) {
      //empty data
      value = null;
    } else if (opt && opt.value) {
      value = opt.value;
    } else {
      //muliselect
      value = opt;
    }
    console.log("name", name);
    selectValues[name] = opt;
    setValue(name, value); //for react-hook
    setSelectValues({ ...selectValues });

    //limit # selections for markets
    if (name === "markets") {
      switch (actionMeta.action) {
        case "select-option":
          if (opt.length >= 3) {
            setMarkets([]); //remove options LIMIT to 2
          }
          break;
        case "remove-value": //add values back in, minus currently selected
          if (!opt) {
            setMarkets(initAvailableMarkets);
          } else {
            setMarkets(initAvailableMarkets.filter(at => !opt.includes(at)));
          }
          break;
      }
    }
    if (name === "specialties") {
      switch (actionMeta.action) {
        case "select-option":
          if (opt.length >= 3) {
            setSpecialties([]); //remove options LIMIT to 2
          }
          break;
        case "remove-value": //add values back in, minus currently selected
          if (!opt) {
            setSpecialties(initAvailableSpecialties);
          } else {
            setSpecialties(
              initAvailableSpecialties.filter(at => !opt.includes(at))
            );
          }
          break;
      }
    }
    if (name === "technologies") {
      switch (actionMeta.action) {
        case "select-option":
          if (opt.length >= 3) {
            setTechnologies([]); //remove options LIMIT to 2
          }
          break;
        case "remove-value": //add values back in, minus currently selected
          if (!opt) {
            setTechnologies(initAvailableTechnologies);
          } else {
            setTechnologies(
              initAvailableTechnologies.filter(at => !opt.includes(at))
            );
          }
          break;
      }
    }
    //reset any errors
    setErrorMessage("");
  };

  const getMarketTags = async () => {
    //available tags
    let _tags = await TagService.get({ type: "market" });
    if (_tags._embedded) {
      let _availableTags = _tags._embedded.map(t => {
        return { value: t.tagId, label: t.tag, data: "markets" };
      });
      setInitAvailableMarkets(_availableTags);

      setMarkets(_availableTags);
    }
  };
  const getSubMarketTags = async () => {
    //available tags
    let _tags = await TagService.get({ type: "submarket" });
    if (_tags._embedded) {
      let _availableTags = _tags._embedded.map(t => {
        return { value: t.tagId, label: t.tag, data: "specialties" };
      });

      setInitAvailableSpecialties(_availableTags);
      setSpecialties(_availableTags);
    }
  };
  const getTechnologyTags = async () => {
    //available tags
    let _tags = await TagService.get({ type: "technology" });
    if (_tags._embedded) {
      let _availableTags = _tags._embedded.map(t => {
        return { value: t.tagId, label: t.tag, data: "technologies" };
      });

      setInitAvailableTechnologies(_availableTags);
      setTechnologies(_availableTags);
    }
  };
  //Parent Companies
  const getParentCompanies = async () => {
    let _parentCompanies = await CompanyService.list({ limit: 100000 });
    if (_parentCompanies._embedded) {
      setParentCompanies(
        _parentCompanies._embedded.map(pc => {
          return { label: pc.name, value: pc.companyId };
        })
      );
    }
  };

  useEffect(() => {
    getParentCompanies();
    getMarketTags();
    getSubMarketTags();
    getTechnologyTags();
  }, []);

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");

    if (e.target.name === "phone") {
      e.target.value = Validate.Phone(e.target.value);
    }
    if (e.target.name === "zip") {
      e.target.value = Validate.Zip(e.target.value);
    }
    if (e.target.name === "country") {
      e.target.value = e.target.value.replace(/(.{3}).*/, "$1");
    }
  };

  const onSubmit = async data => {
    //convert markets to string for api DB input
    if (data.markets) {
      data.markets = data.markets.map(t => t.value);
    } else {
      data.markets = undefined;
    }
    if (data.specialties) {
      data.specialties = data.specialties.map(t => t.value);
    } else {
      data.specialties = undefined;
    }
    if (data.technologies) {
      data.technologies = data.technologies.map(t => t.value);
    } else {
      data.technologies = undefined;
    }
    //linkedin validate
    if (data.linkedinUrl && !Validate.LinkedIn(data.linkedinUrl)) {
      setErrorMessage("Please enter a linkedin URL in the correct format");
      return;
    }

    //create parent company if needed
    if (data.parentCompany === "-newCompany-") {
      let responseParent = await CompanyService.create({
        ...data,
        name: data.newParentCompanyName,
        parentCompany: undefined,
        newParentCompanyName: undefined,
      });
      if (!responseParent.error) {
        data.parentCompany = responseParent.id;
      } else {
        setErrorMessage("There was an error creating the parent company");
        return;
      }
    } else if (!data.parentCompany) {
      data.parentCompany = undefined;
    }

    //SUBMIT FORM
    setFormSubmission(true);
    data.newParentCompanyName = undefined;
    let response = await CompanyService.create(data);
    setFormSubmission(false);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.id) {
      //clear form
      reset();
      //TODO reset linkedin, city, state, workExp
      setMarkets(initAvailableMarkets);
      setSpecialties(initAvailableSpecialties);
      setSelectValues(initSelectValues);

      //toast notificiation
      toast.success("Company (" + data.name + ") has been created", {
        position: "bottom-center",
        closeOnClick: false,
        onClick: () => props.history.push("/bd/company/" + response.id),
      });
    }
  };

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }
  if (!userCanAccessPage) {
    return <Redirect to="/bd/companies" />;
  }

  return (
    <Container className="form">
      <PageTitle>Create Company</PageTitle>

      <LinkClose to="/bd/companies">
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>

      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol>
            <Form.Fieldset legend="Company Information" className="rows">
              <Form.InputWrapper
                label="Company Name"
                name="name"
                errors={errors}
                required={true}
              >
                <Form.TextInput
                  name="name"
                  type="text"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <input
                type="hidden"
                ref={register({ name: "newParentCompanyName", value: "" })}
              />
              <Form.InputWrapper
                label="Parent Company"
                name="parentCompany"
                errors={errors}
                className="react-select position-title"
              >
                <CreatableSelect
                  ref={register({
                    name: "parentCompany",
                  })}
                  name="parentCompany"
                  isClearable={true}
                  className="select"
                  classNamePrefix="position-title"
                  options={parentCompanies}
                  placeholder=""
                  value={selectValues.parentCompany}
                  onCreateOption={value =>
                    onCreateOption(value, "parentCompany")
                  }
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Team Size"
                name="size"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "size",
                  })}
                  name="size"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={teamSizes}
                  value={selectValues.size}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="Market"
                name="markets"
                errors={errors}
                className="react-select tags"
              >
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  ref={register({ name: "markets" })}
                  name="markets"
                  isMulti
                  isClearable={false}
                  className="multi-select"
                  classNamePrefix="tags"
                  onChange={onSelectChange}
                  options={markets}
                  defaultValue={[]}
                  value={selectValues.markets}
                  placeholder=""
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Submarkets"
                name="specialties"
                errors={errors}
                className="react-select tags"
              >
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  ref={register({ name: "specialties" })}
                  isMulti
                  name="specialties"
                  isClearable={false}
                  className="multi-select"
                  classNamePrefix="tags"
                  onChange={onSelectChange}
                  options={specialties}
                  defaultValue={[]}
                  value={selectValues.specialties}
                  placeholder=""
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Technologies"
                name="technologies"
                errors={errors}
                className="react-select tags"
              >
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  ref={register({ name: "technologies" })}
                  isMulti
                  name="technologies"
                  isClearable={false}
                  className="multi-select"
                  classNamePrefix="tags"
                  onChange={onSelectChange}
                  options={technologies}
                  defaultValue={[]}
                  value={selectValues.technologies}
                  placeholder=""
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper label="Website" name="website" errors={errors}>
                <Form.TextInput
                  name="website"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="LinkedIn"
                name="linkedinUrl"
                errors={errors}
              >
                <Form.TextInput
                  name="linkedinUrl"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper label="Phone" name="phone" errors={errors}>
                <Form.TextInput
                  name="phone"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>
            <Form.Fieldset className="rows stacked"></Form.Fieldset>
            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="City"
                name="city"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="city"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="State"
                name="state"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="state"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                />
              </Form.InputWrapper>

              <Form.InputWrapper
                label="Zip"
                name="zip"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="zip"
                  type="text"
                  className="x-small"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  placeholder=""
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Country"
                name="country"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="country"
                  type="text"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  placeholder="USA"
                  className="x-small"
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.FieldsetActions>
              {errorMessage && <Form.Error message={errorMessage} />}
              {!formSubmission ? (
                <>
                  <Button primary>Save &amp; Create Company</Button>
                  <Button type="button">
                    <Link to="/clients">Cancel</Link>
                  </Button>
                </>
              ) : (
                <LoadingSubmit></LoadingSubmit>
              )}
            </Form.FieldsetActions>
          </ol>
        </form>
      </StyledForm>
    </Container>
  );
}
