import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../../services/Auth";
import { useForm } from "react-hook-form";
import { ConferenceService } from "../../../services/Conference";
import { CompanyService } from "../../../services/Company";
import { DocumentService } from "../../../services/Document";
import { ContactService } from "../../../services/Contact";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import styled from "styled-components";
import { Form } from "../../Shared/Form";
import { Dropdown } from "../../Shared/Common";
import { Validate } from "../../Shared/Validate";
import { Form as StyledForm } from "../../../styles/Form";
import {
  Container,
  Button,
  PageTitle,
  LinkClose,
  ButtonClose,
  ButtonDropdown,
} from "../../../styles/Common";

import {
  resetItem,
  addItem,
  deleteItem,
  handleIteratorChange,
  fieldsetActions,
  handleIteratorChangeDateTime,
  handleIteratorChangeCreateSelect,
} from "../../../helpers/FormFieldsIterator";
import { LoadingSubmit } from "../../../styles/Animation";

import { toast } from "react-toastify";

import imgCircleX from "../../../assets/circle-x.svg";
import imgX from "../../../assets/x.svg";
import imgUpload from "../../../assets/upload.svg";

import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import { sortBy as _sortBy } from "lodash";

moment.locale("en");
momentLocalizer();

const ButtonAgendaUpload = styled(Button)`
  height: 28px;
  padding: 0 25px;
  position: relative;
  margin: 20px 0 5px 43px;
  &:before {
    content: "";
    width: 26px;
    height: 26px;
    display: block;
    background: url(${imgUpload}) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: -45px;
  }
`;

export default function ConferenceNew(props) {
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );
  const jwtInfo = AuthService.jwtInfo();

  //react-hook-form
  const { register, handleSubmit, errors, reset } = useForm();

  //set whether a conference is saved
  const [BDConference, setBDConference] = useState(null);

  const [saveAndContinue, setSaveAndContinue] = useState(null);
  const [selectEvents, setSelectEvents] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);
  const [contactsList, setContactsList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);

  const iterableInputs = {
    addresses: {
      name: "addresses",
      fields: [
        { name: "city", type: "text" },
        { name: "state", type: "text" },
        { name: "zip", type: "text" },
        { name: "country", type: "text" },
      ],
    },
    contacts: {
      name: "contacts",
      fields: [
        { name: "firstName", type: "text" },
        { name: "lastName", type: "text" },
        { name: "emailWork", type: "email" },
        { name: "title", type: "text" },
        { name: "phone", type: "text" },
        { name: "linkedin", type: "text" },
        { name: "candidateId", type: "hidden" },
        { name: "create", type: "create", defaultValue: false },
        {
          name: "createPopulateKey",
          type: "create-key",
          defaultValue: "firstName",
        },
        { name: "connected", type: "select", defaultValue: "No" },
      ],
    },
    companies: {
      name: "companies",
      fields: [
        { name: "name", type: "text" },
        { name: "city", type: "text" },
        { name: "state", type: "text" },
        { name: "zip", type: "text" },
        { name: "country", type: "text" },
        { name: "description", type: "text" },
        { name: "companyId", type: "hidden" },
        { name: "create", type: "create", defaultValue: false },
        {
          name: "createPopulateKey",
          type: "create-key",
          defaultValue: "name",
        },
      ],
    },
  };
  const [addresses, setAddresses] = useState([
    resetItem(iterableInputs, "addresses"),
  ]);
  const [companies, setCompanies] = useState([
    resetItem(iterableInputs, "companies"),
  ]);

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");

    if (e.target.name === "zip") {
      e.target.value = Validate.Zip(e.target.value);
    }
    if (e.target.name === "country") {
      e.target.value = e.target.value.replace(/(.{3}).*/, "$1");
    }
  };
  /**
   * control save to redirect or continue on to select events after creating conference
   */
  const _saveBDConference = async continued => {
    await handleSubmit(onSubmit)();
    if (continued) {
      setSelectEvents(true);
    }
    //redirect em
    else {
      props.history.push("/bd/conferences");
    }
  };

  useEffect(() => {
    const getContacts = async () => {
      let _contacts = await ContactService.list({ limit: 10000 });
      console.log("_contacts", _contacts);
      if (_contacts && _contacts._embedded) {
        setContactsList(
          _contacts._embedded.map(c => {
            let name = c.firstName + " " + c.lastName;
            return {
              label: name,
              value: c.candidateId,
              id: c.candidateId,
            };
          })
        );
      }
    };
    getContacts();
    const getCompanies = async () => {
      let _companies = await CompanyService.list({ limit: 10000 });
      console.log("_companies", _companies);
      if (_companies && _companies._embedded) {
        setCompaniesList(
          _companies._embedded.map(c => {
            return {
              label: c.name,
              value: c.companyId,
              id: c.companyId,
            };
          })
        );
      }
    };
    getCompanies();
  }, []);

  //Submit Form
  const onSubmit = async data => {
    //Presenting Companies
    console.log("presentingCompanies", companies);
    data.companies = [];
    companies.forEach(company => {
      if (company.name.trim() || company.companyId) {
        if (company.city.trim() || company.state.trim()) {
          //build nested address
          company.address = {
            city: company.city,
            state: company.state,
            zip: company.zip,
            country: company.country,
          };
          //remove values flattend address values
          company.city = undefined;
          company.state = undefined;
          company.zip = undefined;
          company.country = undefined;
        }
        data.companies.push({
          ...company,
          create: undefined,
          createPopulateKey: undefined,
          companyId: company.companyId || undefined,
          city: undefined,
          state: undefined,
          zip: undefined,
          country: undefined,
        });
      }
    });

    //People to Meet
    console.log("contacts", contacts);
    data.contacts = [];
    contacts.forEach(contact => {
      if (contact.firstName.trim() || contact.candidateId) {
        //candidate still needs first/last for POST
        if (contact.candidateId) {
          //const _contact = contactsList.find(c => c.id === contact.candidateId);
          contact = {
            candidateId: contact.candidateId,
            connected: contact.connected,
          }; //remove all other inputs
        }
        data.contacts.push({
          ...contact,
          connected: contact.connected === "Yes" ? true : false,
          create: undefined,
          createPopulateKey: undefined,
          candidateId: contact.candidateId || undefined,
        });
      }
    });

    //Addresses
    //temporary till i get address iterator going
    data.addresses = [];
    addresses.forEach(address => {
      if (address.city.trim() || address.state.trim()) {
        data.addresses.push({ ...address });
      }
    });

    console.log("data", data);

    //email validation
    /*if (!Validate.Email(data.email)) {
      setErrorMessage("Please enter a valid personal email address");
      return;
    }*/

    console.log("submit before", data);

    //startDate
    const _startDate = document.querySelector('input[name="startDate"]');
    if (_startDate) {
      data.startDate = moment(_startDate.value).toDate();
    }
    //endDate
    const _endDate = document.querySelector('input[name="endDate"]');
    if (_endDate) {
      data.endDate = moment(_endDate.value).toDate();
    }

    //agenda file
    if (agendaFile) {
      data.file = agendaFile;
    }

    //TEMP stopgap until API is corrected
    data.category = "";

    console.log("submit after", data);

    //SUBMIT FORM
    setFormSubmission(true);

    let response = await ConferenceService.create({ ...data, file: undefined });

    console.log("response", response);
    setFormSubmission(false);
    if (response.error || response.errors) {
      setErrorMessage(
        response.error ? response.error : response.errors.message
      );
      toast.error(response.error ? response.error : response.errors.message, {
        position: "bottom-center",
      });
    } else if (response.id) {
      //success
      setBDConference({ ...data, id: response.id });
      //clear form
      reset();

      //toast notificiation
      toast.success("Conference (" + data.name + ") has been created", {
        position: "bottom-center",
        closeOnClick: false,
        onClick: () => props.history.push("/bd/conference/" + response.id),
      });

      //agenda upload?
      if (data.file) {
        data.type = "conferences";
        data.id = response.id;
        let agendaResponse = DocumentService.create(data);

        if (agendaResponse.error) {
          toast.error(
            "Conference was created, but there was a problem with the uploading the agenda file",
            {
              position: "bottom-center",
              closeOnClick: false,
            }
          );
        }
      }
    }
  };

  const [contacts, setContacts] = useState([
    resetItem(iterableInputs, "contacts"),
  ]);

  /*
   * Button to add new contact
   */
  const fieldsetActionsContacts = () => {
    return (
      <Button
        type="button"
        fieldsetAction
        size="small"
        style={{ marginBottom: "14px" }}
        onClick={() =>
          addItem(contacts, setContacts, iterableInputs, "contacts")
        }
      >
        Add Another Contact
      </Button>
    );
  };

  const [agendaFile, setAgendaFile] = useState();

  const prepareAgendaFile = e => {
    let reader = new FileReader();
    reader.onload = () => {
      console.log("result src", reader.result);
      //fileExtension = fileName.replace(/^.*\./, "");
    };
    //reader.readAsDataURL(e.target.files[0]);
    let file = e.target.files[0];
    file.ext = file.name.replace(/^.*\./, "");
    file.error = file.ext.match(/docx|doc|pdf|pages/)
      ? false
      : "Error: .docx, .pages & .pdf accepted";

    console.log("type", file.name.replace(/^.*\./, ""));

    setAgendaFile(file); //e.target.files[0]
  };

  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }
  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Container className="form">
      <PageTitle>
        {!selectEvents || !BDConference
          ? "Create Conference"
          : "Set Conference Events"}
      </PageTitle>

      <LinkClose to="/bd/conferences">
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>

      {!selectEvents || !BDConference ? (
        <StyledForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <>
              <ol>
                <Form.Fieldset legend="Conference Details" className="rows">
                  <Form.InputWrapper
                    label="Conference Name"
                    name="name"
                    errors={errors}
                    required={true}
                  >
                    <Form.TextInput
                      name="name"
                      type="text"
                      value={""}
                      forwardRef={register({ required: true })}
                      onChange={onChange}
                    />
                  </Form.InputWrapper>

                  <Form.InputWrapper
                    label="Website"
                    name="website"
                    errors={errors}
                  >
                    <Form.TextInput
                      name="website"
                      type="text"
                      forwardRef={register}
                      onChange={onChange}
                    />
                  </Form.InputWrapper>

                  <Form.InputWrapper
                    label="Virtual Link"
                    name="virtualLink"
                    errors={errors}
                  >
                    <Form.TextInput
                      name="virtualLink"
                      type="text"
                      forwardRef={register}
                      onChange={onChange}
                    />
                  </Form.InputWrapper>

                  <Form.InputWrapper
                    label="Start Time"
                    name="startDate"
                    errors={errors}
                    required={true}
                  >
                    <DateTimePicker
                      time={false}
                      name="startDate"
                      defaultValue={new Date()}
                      ref={register({
                        name: "startDate",
                        value: new Date(),
                      })}
                    />
                  </Form.InputWrapper>
                  <Form.InputWrapper
                    label="End Date"
                    name="endDate"
                    errors={errors}
                    required={true}
                  >
                    <DateTimePicker
                      time={false}
                      name="endDate"
                      defaultValue={new Date()}
                      ref={register({
                        name: "endDate",
                        value: new Date(),
                      })}
                    />
                  </Form.InputWrapper>

                  <Form.InputWrapper
                    label="Description"
                    name="description"
                    errors={errors}
                  >
                    <Form.Textarea
                      className="span-3"
                      name="description"
                      forwardRef={register}
                      onChange={onChange}
                    />
                  </Form.InputWrapper>
                  <Form.InputWrapper
                    label="Upload Agenda"
                    name="agenda"
                    errors={errors}
                    style={{ verticalAlign: "top" }}
                    className="file-upload"
                  >
                    <div
                      className="mimic-input file-upload"
                      style={{
                        textAlign: "center",
                        height: "104px",
                      }}
                    >
                      <input
                        type="file"
                        id="agendaFile"
                        name="agenda"
                        style={{ display: "none" }}
                        onChange={e => prepareAgendaFile(e)}
                      />
                      <ButtonAgendaUpload
                        type="button"
                        onClick={() => {
                          document.getElementById("agendaFile").click();
                        }}
                      >
                        Select File
                      </ButtonAgendaUpload>
                      <span className="note" style={{ display: "block" }}>
                        {!agendaFile && ".docx, .pages and .pdf accepted"}
                        {agendaFile && agendaFile.error && (
                          <span className="error">{agendaFile.error}</span>
                        )}
                        {agendaFile && !agendaFile.error && (
                          <span
                            className={
                              "filename " + agendaFile.ext.toLowerCase()
                            }
                          >
                            <span className="ext">
                              {agendaFile.ext.toUpperCase()}
                            </span>
                            <span className="name">{agendaFile.name}</span>
                          </span>
                        )}
                      </span>
                    </div>
                  </Form.InputWrapper>
                </Form.Fieldset>

                <Form.Fieldset
                  legend="Addresses"
                  fieldsetActions={fieldsetActions(
                    addresses,
                    setAddresses,
                    iterableInputs,
                    "addresses",
                    "Add Address"
                  )}
                >
                  {addresses.length &&
                    addresses.map((item, index) => (
                      <li className="row group" key={index}>
                        <ol>
                          <Form.InputWrapper
                            label="City"
                            name="city"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="city"
                              onChange={e =>
                                handleIteratorChange(
                                  e,
                                  index,
                                  addresses,
                                  setAddresses
                                )
                              }
                              controlledValue={item.city}
                            />
                          </Form.InputWrapper>
                          <Form.InputWrapper
                            label="State"
                            name="state"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="state"
                              onChange={e =>
                                handleIteratorChange(
                                  e,
                                  index,
                                  addresses,
                                  setAddresses
                                )
                              }
                              controlledValue={item.state}
                            />
                          </Form.InputWrapper>

                          <Form.InputWrapper
                            label="Zip"
                            name="zip"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="zip"
                              type="text"
                              className="x-small"
                              onChange={e =>
                                handleIteratorChange(
                                  e,
                                  index,
                                  addresses,
                                  setAddresses
                                )
                              }
                              placeholder=""
                              controlledValue={item.zip}
                            />
                          </Form.InputWrapper>
                          <Form.InputWrapper
                            label="Country"
                            name="country"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="country"
                              type="text"
                              onChange={e =>
                                handleIteratorChange(
                                  e,
                                  index,
                                  addresses,
                                  setAddresses
                                )
                              }
                              placeholder="USA"
                              className="x-small"
                              controlledValue={item.country}
                            />
                          </Form.InputWrapper>
                        </ol>
                        {index ? (
                          <ButtonClose
                            type="button"
                            style={{ marginTop: 30 }}
                            onClick={() =>
                              deleteItem(
                                index,
                                addresses,
                                setAddresses,
                                iterableInputs,
                                "addresses"
                              )
                            }
                          >
                            <img src={imgX} alt="Delete" title="Delete" />
                          </ButtonClose>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                </Form.Fieldset>

                <Form.Fieldset
                  legend="Presenting Companies"
                  fieldsetActions={fieldsetActions(
                    companies,
                    setCompanies,
                    iterableInputs,
                    "companies",
                    "Add New Company"
                  )}
                >
                  {companies.length &&
                    companies.map((item, index) => (
                      <li className="row group" key={index}>
                        <ol>
                          {item.create ? (
                            <>
                              <Form.InputWrapper
                                label="Name"
                                name={"companies[name]"}
                                errors={errors}
                                style={{ width: "100%" }}
                                required={true}
                              >
                                <Form.TextInput
                                  name={"companies[name]"}
                                  type="text"
                                  controlledValue={item.name}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      companies,
                                      setCompanies
                                    )
                                  }
                                />
                              </Form.InputWrapper>

                              <Form.InputWrapper
                                label="City"
                                name={"companies[city]"}
                                errors={errors}
                              >
                                <Form.TextInput
                                  name={"companies[city]"}
                                  type="text"
                                  className="smaller"
                                  controlledValue={item.city}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      companies,
                                      setCompanies
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                              <Form.InputWrapper
                                label="State"
                                name={"companies[state]"}
                                errors={errors}
                              >
                                <Form.TextInput
                                  name={"companies[state]"}
                                  type="text"
                                  className="smaller"
                                  controlledValue={item.state}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      companies,
                                      setCompanies
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                              <Form.InputWrapper
                                label="Zip"
                                name={"companies[zip]"}
                                errors={errors}
                              >
                                <Form.TextInput
                                  name={"companies[zip]"}
                                  type="text"
                                  className="smaller"
                                  controlledValue={item.zip}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      companies,
                                      setCompanies
                                    )
                                  }
                                />
                              </Form.InputWrapper>

                              <Form.InputWrapper
                                label="Country"
                                name={"companies[country]"}
                                errors={errors}
                              >
                                <Form.TextInput
                                  name={"companies[country]"}
                                  controlledValue={item.country}
                                  type="text"
                                  className="smaller"
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      companies,
                                      setCompanies
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                              <Form.InputWrapper
                                label="Description"
                                name={"companies[description]"}
                                errors={errors}
                                className="span-3"
                              >
                                <Form.Textarea
                                  name={"companies[description]"}
                                  controlledValue={item.description}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      companies,
                                      setCompanies
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                            </>
                          ) : (
                            <Form.InputWrapper
                              label={"Select Company"}
                              name={"companies[companyId]"}
                              errors={errors}
                              className="react-select position-title"
                            >
                              <CreatableSelect
                                id={"companies-" + item.index}
                                name={"companies[companyId]"}
                                isClearable={true}
                                createOptionPosition="first"
                                className="select"
                                classNamePrefix="position-title"
                                options={companiesList}
                                placeholder=""
                                value={
                                  item.companyId
                                    ? {
                                        label: companiesList.find(
                                          c => c.id === item.companyId
                                        ).label,
                                        value: companiesList.find(
                                          c => c.id === item.companyId
                                        ).value,
                                      }
                                    : null
                                }
                                onChange={(opt, actionMeta) =>
                                  handleIteratorChangeCreateSelect(
                                    opt,
                                    actionMeta,
                                    index,
                                    companies,
                                    setCompanies
                                  )
                                }
                              />
                            </Form.InputWrapper>
                          )}
                        </ol>
                        {index || item.create ? (
                          <ButtonClose
                            type="button"
                            style={{ marginTop: 30 }}
                            onClick={() =>
                              deleteItem(
                                index,
                                companies,
                                setCompanies,
                                iterableInputs,
                                "companies"
                              )
                            }
                          >
                            <img src={imgX} alt="Delete" title="Delete" />
                          </ButtonClose>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                </Form.Fieldset>

                <Form.Fieldset
                  legend="People to Meet"
                  fieldsetActions={fieldsetActionsContacts()}
                >
                  {contacts.length &&
                    contacts.map((item, index) => (
                      <li className="row group" key={index}>
                        <ol>
                          {item.create ? (
                            <>
                              <Form.InputWrapper
                                label="First Name"
                                name={"contacts[firstName]"}
                                errors={errors}
                                required={true}
                              >
                                <Form.TextInput
                                  name={"contacts[firstName]"}
                                  type="text"
                                  controlledValue={
                                    item.firstName || item.create || ""
                                  }
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      contacts,
                                      setContacts
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                              <Form.InputWrapper
                                label="Last Name"
                                name={"contacts[lastName]"}
                                errors={errors}
                                required={true}
                              >
                                <Form.TextInput
                                  name={"contacts[lastName]"}
                                  type="text"
                                  controlledValue={item.lastName}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      contacts,
                                      setContacts
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                              <Form.InputWrapper
                                label="Title"
                                name={"contacts[title]"}
                                errors={errors}
                              >
                                <Form.TextInput
                                  name={"contacts[title]"}
                                  type="text"
                                  controlledValue={item.title}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      contacts,
                                      setContacts
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                              <Form.InputWrapper
                                label="Email"
                                name={"contacts[emailWork]"}
                                errors={errors}
                              >
                                <Form.TextInput
                                  name={"contacts[emailWork]"}
                                  type="email"
                                  controlledValue={item.emailWork}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      contacts,
                                      setContacts
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                              <Form.InputWrapper
                                label="Phone"
                                name={"contacts[phone]"}
                                errors={errors}
                              >
                                <Form.TextInput
                                  name={"contacts[phone]"}
                                  type="text"
                                  controlledValue={item.phone}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      contacts,
                                      setContacts
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                              <Form.InputWrapper
                                label="LinkedIn"
                                name={"contacts[linkedin]"}
                                errors={errors}
                              >
                                <Form.TextInput
                                  name={"contacts[linkedin]"}
                                  type="text"
                                  controlledValue={item.linkedin}
                                  onChange={e =>
                                    handleIteratorChange(
                                      e,
                                      index,
                                      contacts,
                                      setContacts
                                    )
                                  }
                                />
                              </Form.InputWrapper>
                            </>
                          ) : (
                            <Form.InputWrapper
                              label={"Select Contact"}
                              name={"contacts[candidateId]"}
                              errors={errors}
                              className="react-select position-title"
                            >
                              <CreatableSelect
                                id={"contacts-" + item.index}
                                name={"contacts[candidateId]"}
                                isClearable={true}
                                createOptionPosition="first"
                                className="select"
                                classNamePrefix="position-title"
                                options={contactsList}
                                placeholder=""
                                value={
                                  item.candidateId
                                    ? {
                                        label: contactsList.find(
                                          c => c.id === item.candidateId
                                        ).label,
                                        value: contactsList.find(
                                          c => c.id === item.candidateId
                                        ).value,
                                      }
                                    : null
                                }
                                onChange={(opt, actionMeta) =>
                                  handleIteratorChangeCreateSelect(
                                    opt,
                                    actionMeta,
                                    index,
                                    contacts,
                                    setContacts
                                  )
                                }
                              />
                            </Form.InputWrapper>
                          )}

                          <Form.InputWrapper
                            label="LinkedIn Connected"
                            name="contacts[connected]"
                            className="react-select position-title"
                          >
                            <Select
                              name="contacts[connected]"
                              onChange={(opt, actionMeta) =>
                                handleIteratorChangeCreateSelect(
                                  opt,
                                  actionMeta,
                                  index,
                                  contacts,
                                  setContacts
                                )
                              }
                              options={[
                                { value: "No", label: "No" },
                                { value: "Yes", label: "Yes" },
                              ]}
                              defaultValue={{ value: "No", label: "No" }}
                            />
                          </Form.InputWrapper>
                        </ol>
                        {index || item.create ? (
                          <ButtonClose
                            type="button"
                            style={{ marginTop: 30 }}
                            onClick={() =>
                              deleteItem(
                                index,
                                contacts,
                                setContacts,
                                iterableInputs,
                                "contacts"
                              )
                            }
                          >
                            <img src={imgX} alt="Delete" title="Delete" />
                          </ButtonClose>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                </Form.Fieldset>

                <Form.FieldsetActions>
                  {errorMessage && <Form.Error message={errorMessage} />}
                  {!formSubmission ? (
                    <>
                      <Dropdown>
                        <ButtonDropdown primary>Save</ButtonDropdown>
                        <div className="list">
                          <ul>
                            <li onClick={() => _saveBDConference(true)}>
                              Save &amp; Add Schedule
                            </li>
                            <li onClick={() => _saveBDConference(false)}>
                              Save &amp; Close
                            </li>
                          </ul>
                        </div>
                      </Dropdown>
                      <Button type="button">
                        <Link to="/bd/conferences">Cancel</Link>
                      </Button>
                    </>
                  ) : (
                    <LoadingSubmit></LoadingSubmit>
                  )}
                </Form.FieldsetActions>
              </ol>
            </>
          </form>
        </StyledForm>
      ) : (
        ""
      )}

      {selectEvents && BDConference && (
        <AddSchedule BDConference={BDConference} history={props.history} />
      )}
    </Container>
  );
}

/**
 * will cycle through conference start/end dates and create events within those
 */
const AddSchedule = ({ BDConference, history }) => {
  //react-hook-form
  const { handleSubmit, errors } = useForm();

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);

  const iterableInputs = {
    events: {
      name: "events",
      fields: [
        { name: "name", type: "text" },
        { name: "date", type: "hidden" },
        { name: "startTime", type: "text" },
        { name: "endTime", type: "text" },
      ],
    },
  };

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");
  };
  //Submit Form
  const onSubmit = async data => {
    console.log("---");

    console.log("data", data);

    data.events = [];
    if (events.length) {
      events.forEach(s => {
        if (s.name.trim()) {
          s.key = undefined;
          s.initial = undefined;
          s.index = undefined;
          s.focus = undefined;
          //could be blank
          s.date = moment(s.date).toDate();
          data.events.push(s);
        }
      });
    }
    //post to database
    let error = false;

    console.log("events", events);
    console.log("data.events", data.events);
    for (const ev of data.events) {
      let _evResponse = await ConferenceService.createEvent({
        conferenceId: BDConference.id,
        data: ev,
      });

      if (_evResponse.error) {
        error = _evResponse.error;
      }
    }

    if (error) {
      //toast notificiation
      toast.error(error, {
        position: "bottom-center",
      });
    } else {
      //toast notificiation
      toast.success("Events have been added to the conference", {
        position: "bottom-center",
      });
      //redirect
      history.push("/bd/conference/" + BDConference.id);
    }

    return;
  };

  const [events, setEvents] = useState([]); //resetItem(true, false, "events")

  const addEvent = date => {
    console.log("event date", date);
    //set them, then add new item to the mix
    let newEvent = resetItem(iterableInputs, "events");
    newEvent.date = date.toDate();
    setEvents(_sortBy([...events, newEvent], "date"));
  };

  const [conferenceDays, setConferenceDays] = useState([]);
  //const [tempDate, setTempDate] = useState("");
  let eventDisplay = { events: [], date: null };
  let eventGroups = [];
  let tempDate = moment(BDConference.startDate); //make sure to start a day behind moment(BDConference.startDate)

  useEffect(() => {
    //tests
    //let startDate = moment();
    //let endDate = moment(startDate).add(2, "days");
    let startDate = moment(BDConference.startDate);
    let endDate = moment(BDConference.endDate);

    setConferenceDays([...conferenceDays, moment(startDate.toDate())]);

    let _conferenceDays = [moment(startDate.toDate())];
    let newEvent = resetItem(iterableInputs, "events");
    newEvent.date = startDate.toDate();
    let _events = [newEvent];
    let _date;

    //determine # of days we're dealing w/ and setup array based on each day
    let numDays = moment.duration(endDate.diff(startDate)).asDays();
    console.log("numDays", numDays);
    for (let d = 0; d < numDays; d++) {
      // //console.log("d", startDate.add(1, "days").format("DD"));
      // console.log("d", d);
      _date = startDate.add(1, "days").toDate();
      // _conferenceDays.push(moment(_date));
      // //set init event for day
      newEvent = resetItem(iterableInputs, "events");
      newEvent.date = _date;
      _events.push(newEvent);
    }
    //setEvents([...setItemInputValues("events"), newEvent]);
    setEvents(_events);
    setConferenceDays(_conferenceDays);
  }, []);

  /*
   * Button to add new hiring manager
   */
  const fieldsetActionsEvents = date => {
    return (
      <Button
        type="button"
        fieldsetAction
        size="small"
        style={{ marginBottom: "14px" }}
        onClick={() => {
          addEvent(date);
        }}
      >
        Add Another Event
      </Button>
    );
  };

  return (
    <>
      <LinkClose to="/bd/conferences">
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>
      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <ol>
              <>
                {events.length
                  ? events.map((item, index) => {
                      //check if new fieldset should start
                      // console.log("tempDate", tempDate.format("MM:DD:YY"));
                      // console.log("index", index);
                      // console.log("events.length", events.length - 1);
                      eventDisplay = [];
                      if (
                        moment(item.date).format("MM:DD:YY") !==
                        tempDate.format("MM:DD:YY")
                      ) {
                        eventDisplay = [
                          {
                            events: eventGroups,
                            date: tempDate,
                          },
                        ];
                        tempDate = moment(item.date);

                        eventGroups = []; //reset
                      }

                      eventGroups.push(
                        <li className="row group" data-key={index} key={index}>
                          <ol>
                            <Form.InputWrapper
                              label="Event"
                              name={"events[name]"}
                              errors={errors}
                              required={true}
                            >
                              <Form.TextInput
                                name={"events[name]"}
                                type="text"
                                value={item.name}
                                onBlur={e =>
                                  handleIteratorChange(
                                    e,
                                    index,
                                    events,
                                    setEvents
                                  )
                                }
                              />
                            </Form.InputWrapper>

                            <Form.InputWrapper
                              label="Start Time"
                              name={"events[startTime]"}
                              errors={errors}
                            >
                              <DateTimePicker
                                date={false}
                                name={"events[startTime]"}
                                defaultValue={moment(
                                  item.startTime ? item.startTime : item.date,
                                  "h:mm:ss A"
                                ).toDate()}
                                onChange={date =>
                                  handleIteratorChangeDateTime(
                                    date,
                                    index,
                                    events,
                                    setEvents,
                                    "startTime"
                                  )
                                }
                              />
                            </Form.InputWrapper>

                            <Form.InputWrapper
                              label="End Time"
                              name={"events[endTime]"}
                              errors={errors}
                            >
                              <DateTimePicker
                                date={false}
                                name={"events[endTime]"}
                                defaultValue={moment(
                                  item.endTime ? item.endTime : item.date,
                                  "h:mm:ss A"
                                ).toDate()}
                                onChange={date =>
                                  handleIteratorChangeDateTime(
                                    date,
                                    index,
                                    events,
                                    setEvents,
                                    "endTime"
                                  )
                                }
                              />
                            </Form.InputWrapper>
                          </ol>
                          <input
                            type="hidden"
                            value={item.date}
                            name={"events[date]"}
                          />
                          {index ? (
                            <ButtonClose
                              type="button"
                              style={{ marginTop: 30 }}
                              onClick={() =>
                                deleteItem(
                                  index,
                                  events,
                                  setEvents,
                                  iterableInputs,
                                  "events"
                                )
                              }
                            >
                              <img src={imgX} alt="Delete" title="Delete" />
                            </ButtonClose>
                          ) : (
                            ""
                          )}
                        </li>
                      );

                      //last one
                      if (index === events.length - 1) {
                        eventDisplay.push({
                          events: eventGroups,
                          date: tempDate,
                        });
                      }
                      return (
                        <>
                          {eventDisplay.length
                            ? eventDisplay.map(ed =>
                                ed.events.length ? (
                                  <Form.Fieldset
                                    key={Math.random()}
                                    legend={ed.date.format(
                                      "dddd, MMMM D, YYYY"
                                    )}
                                    fieldsetActions={fieldsetActionsEvents(
                                      ed.date
                                    )}
                                  >
                                    {ed.events}
                                  </Form.Fieldset>
                                ) : (
                                  ""
                                )
                              )
                            : ""}
                        </>
                      );
                    })
                  : ""}
              </>

              <Form.FieldsetActions>
                {errorMessage && <Form.Error message={errorMessage} />}
                {!formSubmission ? (
                  <>
                    <Button primary>Save Conference</Button>
                    <Button type="button">
                      <Link to="/bd/conferences">Cancel</Link>
                    </Button>
                  </>
                ) : (
                  <LoadingSubmit></LoadingSubmit>
                )}
              </Form.FieldsetActions>
            </ol>
          </>
        </form>
      </StyledForm>
    </>
  );
};
