import Config from "../../config/config";
import { filterTable } from "../../helpers/Search";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
// Thank you https://github.com/benjamingr/RegExp.escape/blob/master/polyfill.js
// With some massaging for perf.
const escapeRegexLiteral = /[\\^$*+?.()|[\]{}]/g;
function RegExpEscape(s) {
  return String(s).replace(escapeRegexLiteral, "\\$&");
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
//function processReplacers(base:string, replacers?:Record<string,string>):string {
function processReplacers(base, replacers) {
  if (!replacers) {
    return base;
  }
  return Object.entries(replacers).reduce(
    (prev, [tag, value]) =>
      prev.replace(
        new RegExp(`:${RegExpEscape(tag)}\\b`, "g"),
        encodeURIComponent(value)
      ),
    base
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export async function create(
  path /*: string */,
  inputData /*: unknown*/,
  options /*?: {replacements?: Record<string, string>} */
) {
  const url = new URL(Config.API_URL);
  url.pathname = options ? processReplacers(path, options.replacements) : path;

  try {
    const response = await fetch(url, {
      method: "POST",
      body: inputData ? JSON.stringify(inputData) : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return error;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export async function del(
  path /*: string */,
  options /*?: {replacements?: Record<string, string>} */
) {
  const url = new URL(Config.API_URL);
  url.pathname = options ? processReplacers(path, options.replacements) : path;

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return error;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export async function get(
  path /*: string */,
  options /*?: {replacements?: Record<string, string>} */
) {
  const url = new URL(Config.API_URL);
  url.pathname = options ? processReplacers(path, options.replacements) : path;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return error;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export async function list(
  path /*: string */,
  options /*?: {query?: {limit?:number, offset?:number, filter?:Filter, sortBy?:string|string[]} & Record<string, string|number>, replacements?: Record<string, string> */
) {
  const url = new URL(Config.API_URL);
  url.pathname = options ? processReplacers(path, options.replacements) : path;

  if (options?.query) {
    if (
      typeof options.query.limit !== "undefined" &&
      options.query.limit >= 0
    ) {
      url.searchParams.append("limit", options.query.limit);
    } else {
      // HACK: Work around the server enforcing low limits by default.
      url.searchParams.append("limit", 1e10);
    }

    if (
      typeof options.query.offset !== "undefined" &&
      options.query.offset >= 0
    ) {
      url.searchParams.append("offset", options.query.offset);
    }

    const search = filterTable(options.query.filter);
    if (search) {
      url.searchParams.append("q", search);
    }

    if (typeof options.query.sortBy === "string" && options.query.sortBy) {
      url.searchParams.append("sort_by", options.query.sortBy);
    } else if (options.query.sortBy && Array.isArray(options.query.sortBy)) {
      url.searchParams.append("sort_by", options.query.sortBy.join(","));
    }

    // Handle the non-standardized fields
    for (const [fieldName, fieldValue] of Object.entries(options.query).filter(
      ([fieldName]) =>
        !["filter", "limit", "offset", "sortBy"].includes(fieldName)
    )) {
      url.searchParams.append(fieldName, fieldValue);
    }
  } else {
    // HACK: Work around the server enforcing low limits by default.
    url.searchParams.append("limit", 1e10);
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return error;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export async function replace(
  path /*: string */,
  inputData /*: unknown*/,
  options /*?: {replacements?: Record<string, string>} */
) {
  const url = new URL(Config.API_URL);
  url.pathname = options ? processReplacers(path, options.replacements) : path;

  try {
    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(inputData),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return error;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const rpc = create;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/**
 * Patches the given path with the input data.
 */
export async function update(
  path /*: string */,
  inputData /*: unknown*/,
  options /*?: {replacements?: Record<string, string>} */
) {
  const url = new URL(Config.API_URL);
  url.pathname = options ? processReplacers(path, options.replacements) : path;

  try {
    const response = await fetch(url, {
      method: "PATCH",
      body: JSON.stringify(inputData),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return error;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/**
 * Does a patch operation but using the PUT method.  Workaround for badly designed API.
 */
export const update_PUT = replace;
