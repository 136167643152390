import React, { useState, useEffect } from "react";
import device from "../../config/device";
import { Redirect, Link } from "react-router-dom";
import { AuthService } from "../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import { startCase as _startCase } from "lodash";
import moment from "moment";
import { ReportService } from "../../services/Report";
import { NoteService } from "../../services/Note";
import { NotificationService } from "../../services/Notification";
import { Card } from "../Shared/Common";
import { Container, PageTitle, Button } from "../../styles/Common";
import { Tabs } from "../../styles/Form";
import { Colors } from "../../styles/Colors";
import imgPolygon from "../../assets/polygon.svg";
import imgChevron from "../../assets/chevron.svg";

import styled from "styled-components";

const Styled = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: "positions kpis";
  grid-gap: 15px;
  @media ${device.tabletMMax} {
    display: block;
    .card {
      margin-bottom: 15px;
    }
  }
  .kpi-recent {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "kpi kpi"
      "today recent";
    grid-gap: 15px;
    .overview {
      grid-area: kpi;
    }
  }
  .kpi-recent {
    @media ${device.laptopMax} {
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-areas:
        "kpi"
        "recent";
    }
  }
`;

export default function DashboardManager() {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);

  const [dashboardData, setDashboardData] = useState();

  useEffect(() => {
    const getDashboardData = async () => {
      let _dashboardData = await ReportService.getDashboardData({});
      console.log("dash", _dashboardData);
      if (!_dashboardData.error) {
        setDashboardData(_dashboardData);
      }
    };
    getDashboardData();
  }, []);

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      <PageTitle>Dashboard</PageTitle>

      <Styled>
        <div className="col">
          <Card>
            <Positions
              openPositions={
                dashboardData ? dashboardData.positionsOpenData : {}
              }
            />
          </Card>
        </div>
        <div className="col kpi-recent">
          <Card className="overview">
            <Kpi expanded={true} dashboardData={dashboardData} />
          </Card>

          <TodaysActivities />
          <Card title="Recently Viewed" className="recent">
            <RecentlyViewed />
          </Card>
        </div>
      </Styled>
    </Container>
  );
}

/*
 * My Positions
 */
const StyledPositions = styled.div`
  grid-area: positions;
  min-width: 260px;
  .active {
    h2 {
      margin-bottom: 5px;
      .company {
        .chevron {
          transform: rotate(180deg);
        }
      }
    }
    dl,
    .content {
      display: block;
    }
  }
  h2 {
    margin: 0 0 0 0;
    padding: 0 0 0 15px;
    background: #eaeff3;
    height: 40px;
    line-height: 31px;
    font-size: 0.857rem;
    font-weight: 600;
    position: relative;
    display: flex;
    flex: 0 auto;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    &:before {
      content: "";
      display: block;
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.low:before {
      background-color: #5fb540;
    }
    &.high:before {
      background-color: #db3537;
    }
    &.medium:before {
      background-color: #f1c82c;
    }
  }
  .company {
    margin-right: 15px;
    .chevron {
      content: "";
      vertical-align: middle;
      width: 15px;
      height: 9px;
      margin-left: 10px;
      display: inline-block;
      background: url(${imgChevron}) no-repeat;
      background-size: cover;
      cursor: pointer;
    }
  }
  .content {
    display: none;
    &.positions {
      display: block;
    }
  }
  dl {
    margin: 0 19px 15px 15px;
    display: none;
    &:last-child {
      margin-bottom: 11px;
    }
    div:last-child {
      dt,
      dd {
        border-bottom: 0;
      }
    }
  }
  dt,
  dd {
    font-size: 0.95em;
    height: 34px;
    line-height: 34px;
    display: inline-block;
    width: 50%;
    border-bottom: 1px solid;
    border-bottom: 1px solid #d8d8d8;
  }

  dd {
    text-align: right;
  }
`;

const Positions = ({ openPositions }) => {
  console.log("dashdata", openPositions);

  //Position Expand
  const [positionExpanded, setPositionExpanded] = useState([]);
  const [openPositionsStandalone, setOpenPositionsStandalone] = useState(
    openPositions
  );

  console.log("dashdata", openPositions);

  //toggle position
  const togglePosition = position => {
    let foundPosition = positionExpanded.find(
      p => p.id === position.positionId
    );
    if (!foundPosition) {
      positionExpanded.push({ id: position.positionId, expanded: true });
    } else {
      foundPosition.expanded = !foundPosition.expanded;
    }

    console.log("positionExpanded", positionExpanded);

    setPositionExpanded([...positionExpanded]);
  };

  console.log("positionExpanded reneder", positionExpanded);

  return (
    <StyledPositions>
      {openPositions
        ? [
            { type: "owner", title: "My Open Job Orders" },
            { type: "primary", title: "Primary Job Orders You're On" },
            { type: "secondary", title: "Secondary Job Orders You're On" },
          ].map((relation, i) => {
            return (
              <React.Fragment key={i}>
                <h1>{relation.title}</h1>
                {openPositions[relation.type] &&
                openPositions[relation.type].length ? (
                  openPositions[relation.type].map((position, e) => {
                    let found = positionExpanded.find(
                      pe => pe.id === position.positionId
                    );
                    let expanded;
                    if (found && found.expanded) {
                      expanded = true;
                    } else {
                      expanded = false;
                    }
                    return (
                      <div className={expanded ? "active" : ""} key={e}>
                        <h2
                          className={
                            position.urgency
                              ? position.urgency.toLowerCase()
                              : "low"
                          }
                        >
                          <span className="title">
                            <Link to={"/position/" + position.positionId}>
                              {position.title}
                            </Link>
                          </span>
                          <span className="company">
                            <Link to={"/company/" + position.companyId}>
                              {position.companyName}
                            </Link>
                            <span
                              className="chevron"
                              onClick={() => togglePosition(position)}
                            ></span>
                          </span>
                        </h2>

                        {position.stages && position.stages.length ? (
                          <dl>
                            {position.stages.map(stage => {
                              return (
                                <div key={stage}>
                                  <dt>{stage}</dt>
                                  <dd>
                                    {position.stageData[stage]
                                      ? position.stageData[stage]
                                      : 0}
                                  </dd>
                                </div>
                              );
                            })}
                          </dl>
                        ) : (
                          <div className="content">
                            Position does not have stages
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="content positions">No open positions</div>
                )}
              </React.Fragment>
            );
          })
        : ""}
    </StyledPositions>
  );
};

const Section = ({ openPositions, title }) => {
  let listTitles = ["Possible", "Qualified", "Telephone", "Onsite", "Offered"];
  return (
    <>
      <h2>{title}</h2>
      {openPositions && openPositions.length ? (
        <dl>
          {openPositions.map((total, i) => (
            <div key={i}>
              <dt>{listTitles[i]}</dt>
              <dd>{total}</dd>
            </div>
          ))}
        </dl>
      ) : (
        <div className="content">No recently viewed pages</div>
      )}
    </>
  );
};

/*
 * Kpi's
 */
const StyledKpi = styled.div`
  grid-area: kpi;
  .extended {
  }
  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 62px;
    @media ${device.tabletMMax} {
      margin: 0 0;
    }
  }
  dl div {
    width: 33.333%;
    margin: 20px 0 30px;
  }
  dt,
  dd {
    text-align: center;
    padding: 2px;
    margin-top: 10px;
  }
  dt {
    font-size: 0.857rem;
    font-weight: 600;
    white-space: nowrap;
  }
  dd {
    font-size: 2.857rem;
    font-weight: 300;
  }
  @media ${device.laptopMax} {
    dl div {
      width: 50%;
    }
  }
`;

const Kpi = ({ dashboardData, expanded }) => {
  console.log("dashdata", dashboardData);
  const kpis = [
    { label: "Open Job Orders", key: "openPositions", privilege: [], url: "" },
    { label: "Job Orders 30+ Days", key: "positions30", privilege: [] },
    { label: "Waiting on Fee", key: "waitingOnFee", privilege: [] },
    {
      label: "Candidates With Offers",
      key: "candidatesPendingOffer",
      privilege: [],
    },
    { label: "Offers/Accepted", key: "acceptDeclineRatio", privilege: [] },
    { label: "Job Orders in Process", key: "", privilege: [] },
    { label: "Job Orders On Hold", key: "", privilege: [] },
  ];

  return (
    <StyledKpi>
      <section className={"kpi" + (expanded ? " expanded" : "")}>
        <dl>
          {kpis.map(kpi => {
            if (
              dashboardData &&
              typeof dashboardData[kpi.key] !== "undefined"
            ) {
              return (
                <div key={kpi.key}>
                  {kpi.key === "positions30" ? (
                    <>
                      <dt>{kpi.label}</dt>
                      <dd>{dashboardData[kpi.key]}</dd>
                    </>
                  ) : (
                    <>
                      <dt>{kpi.label}</dt>
                      <dd>{dashboardData[kpi.key]}</dd>
                    </>
                  )}
                </div>
              );
            }
          })}
        </dl>
      </section>
    </StyledKpi>
  );
};

/*
 * Recently Viewed
 */

const StyledRecentlyViewed = styled.div`
  grid-area: recent;
  padding: 0 0 18px;
  ol {
    margin: 0 10px 0 40px;
  }
  li {
    position: relative;
    margin: 0 0 20px;
    &:before {
      content: "";
      width: 16px;
      height: 15px;
      background: url(${imgPolygon}) no-repeat;
      background-size: cover;
      display: block;
      position: absolute;
      left: -28px;
      top: 5px;
    }
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: 1rem;
  }
  .note {
    display: block;
    font-size: 0.857rem;
    margin: 2px 0 0;
    color: ${Colors.primary}bf;
  }
  .actions {
    margin-top: 25px;
    text-align: center;
  }
`;

const RecentlyViewed = props => {
  let limit = 10; //initial search display limit
  const [historyToShow, setHistoryToShow] = useState([]);
  const [showMoreVisible, setShowMoreVisible] = useState(true);

  //get history from local storage
  let pageHistory = localStorage.getItem("pageHistory");
  if (pageHistory) {
    pageHistory = JSON.parse(pageHistory);
  }

  //handle initial subset and handle view more
  const handlePageHistoryDisplay = initial => {
    setHistoryToShow(pageHistory.splice(0, initial ? limit : (limit += limit)));
    if (historyToShow.length >= pageHistory.length) {
      setShowMoreVisible(false);
    }
  };
  useEffect(() => {
    if (pageHistory) {
      handlePageHistoryDisplay(true);
    }
  }, []);

  return (
    <StyledRecentlyViewed>
      <section>
        {historyToShow && historyToShow.length ? (
          <>
            <ol>
              {historyToShow.map((item, i) => (
                <li key={i}>
                  <span className="title">
                    <Link to={item.url}>{item.title}</Link>
                  </span>
                  <span
                    className="note"
                    dangerouslySetInnerHTML={{ __html: item.info }}
                  />
                </li>
              ))}
            </ol>
            {showMoreVisible && (
              <div className="actions">
                <Button
                  type="button"
                  size="small"
                  onClick={() => handlePageHistoryDisplay(false)}
                >
                  View More
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="content">No recently viewed pages</div>
        )}
      </section>
    </StyledRecentlyViewed>
  );
};

/*
 * Todays Activities
 */

const StyledTodaysActivities = styled.div`
grid-area:today;
min-width:300px;
@media ${device.laptopMax} {
  grid-area:recent;
}
  .filter {
  }
  ul {
    margin: 0 15px 0 15px;
  }
  li {
    margin: 0 0 15px;
    }
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: 1rem;
  }
  .note {
    font-size: 0.857rem;
    margin: 2px 0 0;
    color: ${Colors.primary}bf;
    time{
      font-style:italic;
    }
  }
`;
const TodaysActivities = () => {
  //get activities for today only

  const [filteredActivities, setFilteredActivities] = useState([]);

  const getNotifications = async () => {
    let response = await NotificationService.get();
    if (!response.error && !response.errors) {
      console.log("notifications", response._embedded);
      let _notifications = response._embedded
        .filter(
          n =>
            n.type.match(/task|call/i) &&
            moment(n.date).format("D") === moment().format("D")
        )
        .map(n => {
          let target;
          switch (n.target) {
            case "Candidate":
              target = "candidate";
              break;
            case "Position":
              target = "position";
              break;
            case "Company":
              target = "client";
              break;
            default:
              break;
          }
          n.url = "/" + target + "/" + n.id;
          return n;
        });
      setFilteredActivities(_notifications);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Card>
      <StyledTodaysActivities>
        <h1>Today&apos;s Activities</h1>

        {filteredActivities && filteredActivities.length ? (
          <ul>
            {filteredActivities.map((a, i) => {
              return (
                <li key={i}>
                  <Link to={a.url} className="title">
                    {_startCase(a.type)}
                  </Link>
                  <div className="note">
                    <div dangerouslySetInnerHTML={{ __html: a.title }} /> at{" "}
                    <time>{moment(a.date).format("h:mm:A")}</time>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="content">No activity for today</div>
        )}
      </StyledTodaysActivities>
    </Card>
  );
};
