import Config from "../config/config";

const get = inputData => {
  return fetch(Config.API_URL + "interviews/" + inputData.id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const create = inputData => {
  return fetch(
    Config.API_URL +
      "interviews/" +
      inputData.positionId +
      "/create/" +
      inputData.candidateId,
    {
      method: "POST",
      //body: formData,
      body: JSON.stringify({ type: inputData.type, manual: inputData.manual }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const update = inputData => {
  return fetch(
    Config.API_URL +
      "interviews/" +
      inputData.id +
      "?manager=" +
      inputData.manager,
    {
      method: "POST",
      body: JSON.stringify({ ...inputData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const confirm = inputData => {
  return fetch(Config.API_URL + "interviews/" + inputData.id + "/confirm", {
    method: "POST",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
export const InterviewService = {
  get,
  create,
  update,
  confirm,
};
