import React, { useState, useEffect } from "react";
import { PositionService } from "../../services/Position";
import { Email } from "../../components/Shared/Email";

export default function PositionEmail(props) {
  //get candidate
  const [position, setPosition] = useState(false);

  const getPosition = async () => {
    let _position = await PositionService.get(props.match.params.id);
    _position.id = props.match.params.id;
    if (_position.errors) {
      props.history.push("/positions");
    } else {
      setPosition(_position);
      getPositionCandidates(_position.id);
    }
    console.log("pos", _position);
  };
  const [emailAddresses, setEmailAddresses] = useState([]);
  const getPositionCandidates = async id => {
    let response = await PositionService.candidates({ id: id, limit: 200 });

    console.log("candidates", response);
    if (response._embedded) {
      let _candidates = response._embedded
        .filter(c => c.emailPersonal || c.emailWork)
        .map(c => (c.emailPersonal ? c.emailPersonal : c.emailWork));
      console.log("_candidates: let", _candidates);
      setEmailAddresses(_candidates);
    }
  };
  useEffect(() => {
    getPosition();
  }, []);

  return (
    <Email
      entity={position}
      detail={{ type: "position", title: "New Email", history: props.history }}
      emailAddresses={emailAddresses}
    />
  );
}
