import device from "../config/device";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Colors } from "./Colors";
import imgChevron from "../assets/chevron.svg";
import imgCircleX from "../assets/circle-x.svg";

export const Container = styled.div`
  max-width: 1440px;
  width: 1440px;
  margin: 0 auto;
  position: relative;
  &.form {
    max-width: 870px;
    padding: 0 1rem;
  }

  @media ${device.laptopLMax} {
    width: auto;
    margin: 0 auto;
    min-width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    &.full {
      padding: 0;
    }
    &.form {
      min-width: auto;
    }
  }
`;

export const PageTitle = styled.h1`
  margin-top: 51px;
  font-weight: 300;
  font-size: 2.857rem;
  line-height: normal;
  margin-right: 25px;

  @media ${device.mobileLMax} {
    font-size: 2.5rem;
  }
  @media ${device.mobileMMax} {
    font-size: 2rem;
  }
`;

export const TableHeader = styled.div`
  margin-top: 51px;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  margin: 51px 2em 0;

  h1 {
    margin: 0 30px 0 0;
  }
  .button {
    margin-right: 0;
    text-align: center;
  }
  .search {
    grid-area: 1 / 4 / 2 / 5;
  }
  .dropdown {
    grid-area: 1 / 4 / 2 / 5;
    margin-right: 30px;
  }

  @media ${device.tabletMMax} {
    grid-template-columns: auto auto 1fr;
    grid-template-rows: auto auto;
    .search {
      padding: 25px 0 10px;
      grid-area: 2 / 1 / 3 / 4;
      input[type="text"] {
        width: 100%;
        box-sizing: border-box;
      }
      &:after,
      .go {
        top: 38px !important;
      }
    }
  }
  @media ${device.mobileLMax} {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    h1 {
      margin-bottom: 30px;
    }
    .button {
      grid-area: 2 / 1 / 3 / 2;
    }
    .search {
      grid-area: 3 / 1 / 4 / 2;
    }
  }
`;

export const MediaTable = css`
  .m {
    display: block;
  }
  th,
  thead {
    display: none;
  }
  tr {
    padding: 15px;
    display: block;
    border-top: 1px solid ${Colors.gray_accent};
    &:first-child {
      border-top: none;
    }
  }
  td {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 0;
    height: auto;
    padding: 0;
  }
  .wrap {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
  .m {
    width: 30%;
    margin: 0 5px 2px 0;
    overflow: hidden;
    background: ${Colors.gray};
    vertical-align: middle;
    padding: 5px 5px;
    text-align: left;
    font-weight: 400;
  }
  .render {
    padding: 5px;
    width: 70%;
  }
`;

export const MediaTableMin = css`
  .wrap {
    flex-direction: column;
  }
  .m,
  .render {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Table = styled.div`
  margin: 2em;
  border: 1px solid ${Colors.gray_accent};
  border-radius: 3px;
  .outer-wrap {
    border-bottom: 0;
    border-top: 0;
    font-size: 1rem;
    overflow: hidden;
  }
  table {
    width: 100%;
  }
  thead {
    background: #eaeff3;
    th {
      border-top: none;
    }
  }
  th,
  td,
  .pagination {
    height: 18px;
    padding: 0 15px;
    vertical-align: middle;
    border-top: 1px solid ${Colors.gray_accent};
    background: #eaeff3;
    height: 41px;
    align-items: center;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  th {
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.75rem;
    position: relative;
    &.sort {
      padding-right: 26px;
    }
    &.sort:before {
      position: absolute;
      right: 15px;
      top: 44%;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    &.sort.desc:before {
      border-top: 5px solid ${Colors.primary};
    }
    &.sort.asc:before {
      border-bottom: 5px solid ${Colors.primary};
    }
  }

  td {
    background: #fff;
  }

  .pagination {
  }

  @media ${device.tabletMMax} {
    ${MediaTable}
  }
  @media ${device.mobileLMax} {
    ${MediaTableMin}
  }
`;

export const TableEmpty = styled.div`
  margin: 2em;
`;

export const LinkClose = styled(Link)`
  width: 32px;
  height: 32px;
  position: absolute;
  display: block;
  top: 0;
  right: 0;

  @media ${device.laptopLMax} {
    right: 15px;
  }
`;
export const Button = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 1px solid ${props => (props.primary ? "transparent" : Colors.primary)};
  height: ${props => (props.size === "small" ? "30px" : "40px")};
  padding: 0 ${props => (props.size === "small" ? "15px" : "25px")};
  background-color: ${props => (props.primary ? Colors.primary : "#fff")};
  color: ${props => (props.primary ? "#d8d8d8" : Colors.primary)};
  ${props =>
    props.type === "alt"
      ? "color:" +
        Colors.gray +
        ";background-color:transparent;border-color:" +
        Colors.gray +
        ";"
      : ""}
  font-size: 14px;
  font-weight: 600;
  margin: 0 15px 0 0;
  outline: none;
  white-space: nowrap;
`;
export const ButtonLink = styled(Link)`
  border-radius: 3px;
  border: 1px solid ${props => (props.primary ? "transparent" : Colors.primary)};
  height: ${props => (props.size === "small" ? "30px" : "40px")};
  padding: 0 ${props => (props.size === "small" ? "15px" : "25px")};
  background-color: ${props => (props.primary ? Colors.primary : "#fff")};
  color: ${props => (props.primary ? "#d8d8d8" : Colors.primary)};
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin: 0 15px 0 0;
  line-height: ${props => (props.size === "small" ? "31px" : "41px")};
  white-space: nowrap;
`;
export const BackLink = styled.div`
  padding: 50px 0 0;
  a {
    vertical-align: middle;
  }
  a:before {
    content: "";
    vertical-align: middle;
    width: 14px;
    height: 8px;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
    background: url(${imgChevron}) no-repeat;
    background-size: cover;
    transform: rotate(90deg);
  }
`;
export const ButtonClose = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  width: 16px;
  height: 16px;
  margin: 13px 0 0 11px;
`;

export const ButtonDropdown = styled(Button).attrs({ type: "button" })`
    &:after {
        content:'';
        vertical-align:middle;
        width:15px;height:9px;
        margin-left:10px;
        display:inline-block;
        background:url(${imgChevron}) no-repeat;
        background-size:cover;        
        ${props => (props.primary ? "background-position: 0;" : "")};
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  .list {
    position: absolute;
    top: 100%;
    ${props => (props.position === "right" ? "right:0" : "left: 0")};
    min-width: calc(100% - 15px);
    border: 1px solid rgba(198, 203, 211, 0.5);
    background: #fff;
    box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);
    border-radius: 3px;
    padding: 10px 15px;
    max-height: 400px;
    overflow-y: auto;
    color: ${Colors.primary_accent};
    font-weight: 400;
    box-sizing: border-box;
    margin-right: ${props => (props.position === "right" ? "15px;" : "-15px;")};
    display: none;
    z-index: 1;
  }
  li {
    white-space: nowrap;
    padding: 5px 0;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: none;
    }
  }
  &.active {
    .list {
      display: block;
    }
  }
  input {
    overflow: visible;
    border-radius: 3px;
    border: 1px solid #ddd;
    height: 24px;
    padding: 0 3px;
  }
`;

export const TagsWrapper = styled.ul`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 0;

  li {
    background: ${Colors.primary};
    color: #fff;
    border-radius: 10px;
    height: 20px;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    .label {
      color: #fff;
      font-size: 0.857rem;
      font-weight: 500;
      line-height: 1.3rem;
      margin-left: 8px;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      border-radius: 2px;
      overflow: hidden;
    }
    .remove {
      border-radius: 0;
      margin-right: 3px;
      padding: 0;
      margin-left: 5px;
      width: 14.5px;
      height: 20px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      &:before {
        content: "";
        display: block;
        height: 14.5px;
        width: 14.5px;
        background: url(${imgCircleX}) no-repeat;
        background-size: cover;
        background-position: 0 -14.5px;
      }
    }
  }
`;
export const TagSelector = styled.div`
  .tags__menu {
    border: 1px solid #979797;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0;
    padding: 0;
    width: 100%;
  }
  .tags__option {
    font-size: 1rem;
    color: ${Colors.primary_accent}bf;
  }
  .tags__option--is-focused {
    background: #eee;
    color: ${Colors.primary_accent};
  }

  .inline {
    width: 220px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 13px;
  }
`;

export const Editable = css`
  input {
    border: solid ${Colors.highlight};
    border-width: 0 0 1px 0;
    text-align: right;
    outline: none;
    box-sizing: border-box;
    width: 100%;
  }
  .edit-handle {
    border-bottom: 1px solid ${Colors.highlight};
    opacity: 0.4;
    line-height: 1.5em;
  }
  .select {
    min-width: auto !important;
    &.edit {
      line-height: normal;
    }
  }
  .date {
    overflow: visible;

    .rw-widget-container.rw-state-focus,
    .rw-state-focus > .rw-widget-container,
    .rw-widget-container.rw-state-focus:hover,
    .rw-state-focus > .rw-widget-container:hover {
      box-shadow: none;
      border-color: none;
      background: transparent;
    }
    .rw-widget-container {
      border: none;
      background-color: transparent;
    }
    .rw-widget-picker {
      height: auto;
    }
    .rw-widget-input,
    .rw-filter-input {
      box-shadow: none;
    }
    .rw-select-bordered {
      border: none;
    }
    .rw-i {
      color: ${Colors.highlight};
    }
    .rw-popup-container {
      background-color: #fff;
    }
  }
  .select {
    &.edit {
      overflow: visible;
    }
    input {
      height: 20px;
      width: 100px;
    }
    .position-title__value-container {
      line-height: 0;
      padding: 0;
    }
    .position-title__control {
      min-height: auto;
      border-color: ${Colors.highlight};
      border-width: 0;
      border-bottom-width: 1px;
      border-radius: 0;
    }
    .position-title__control--is-focused {
      outline: none;
      box-shadow: none;
    }
    .position-title__input {
      line-height: auto;
    }
    .position-title__indicator {
      padding: 0 5px;
      color: ${Colors.highlight};
      svg {
        margin-top: 5px;
      }
    }
    .position-title__indicator-separator {
      background-color: ${Colors.highlight};
      margin-top: 10px;
      margin-bottom: 6px;
    }
    .position-title__single-value {
      height: 19px;
      line-height: 19px;
    }

    .position-title__menu {
      margin-top: 0;
    }
    .position-title__option {
      padding: 0 5px 0 0;
      line-height: 24px;
    }
  }
  .select {
    min-width: 150px;
  }
  .select,
  .date {
    .saving {
      position: absolute;
      top: 0;
      left: 0;
      text-align: right;
      background: #fff;
      width: 100%;
      height: 100%;
      margin-right: 5px;
      padding-right: 8px;
      box-sizing: border-box;
    }
  }
`;

export const ListDl = styled.dl`
  margin: 0;
  &:last-child {
    margin-bottom: 11px;
  }
  div:last-child {
    dd,
    dt {
      border-bottom-width: 0;
    }
  }
  dt,
  dd {
    height: 34px;
    line-height: 34px;
    display: inline-block;
    width: 50%;
    border-bottom: 1px solid;
    border-bottom: 1px solid #d8d8d8;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
  dd {
    text-align: right;
    cursor: default;
    max-width: 400px;
  }

  ${Editable}
`;

export const Card = styled.section`
  background: #fff;
  border: 1px solid rgba(198, 203, 211, 0.5);
  box-shadow: 2px 2px 21px 0 rgba(81, 91, 105, 0.2);
  border-radius: 3px;
  h1 {
    font-size: 12px;
    font-weight: 600;
    margin: 15px 0 15px 15px;
  }
  .content {
    margin: 0 19px 15px 15px;
  }
  .expandable {
    margin-bottom: 15px;
  }

  .table {
    margin: 0;
  }
  &.card {
    padding: 0;
    .table {
      border: 0;
      width: 100%;
    }
  }
`;
export const Content = styled.div`
  p {
    margin: 1em 0;
  }
  p:first-child {
    margin-top: 0;
  }
  &.expandable {
    .content {
      position: relative;
      overflow: hidden;
      padding-bottom: 0;
    }
    &.contracted {
      .content {
        ${props =>
          props.initialHeight ? "max-height:" + props.initialHeight + ";" : ""}
        padding-bottom: 30px;
        &:after {
          ${props => (!props.edit ? "content: '';" : "")}
          display: block;
          background: red;
          height: 30px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(to top, #fff 0%, transparent 100%);
        }
      }
    }
    &.expanded {
    }
    button {
      display: block;
      margin: 0 auto;
    }
  }
  ${Editable}
`;

export const DateTimePicker = styled.div`
  margin: 20px 0;
  background: #fff;
  .rw-calendar {
    border: 0;
    padding: 15px;
  }
  .rw-calendar-month .rw-cell {
    vertical-align: middle;
  }
  .rw-cell.rw-state-selected,
  .rw-cell.rw-state-selected:hover {
    background-color: ${Colors.highlight};
    border-color: ${Colors.highlight};
  }
  .rw-cell.rw-state-focus,
  .rw-cell.rw-state-focus:hover {
    border-color: ${Colors.highlight};
  }
  .rw-head-cell {
    border-bottom: none;
  }
`;
