import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { CompanyService } from "../../services/Company";
import { TagService } from "../../services/Tag";
import { AuthService } from "../../services/Auth";
import { TitleService } from "../../services/Title";
import { DegreeService } from "../../services/Degree";
import { NewsService } from "../../services/News";
import { List } from "./Shared/List";
import Roles from "./Roles";
import { CandidateService } from "../../services/Candidate";
import { PositionService } from "../../services/Position";
import { ActivityTypeService } from "../../services/ActivityType";

export default function Entity(props) {
  const jwtInfo = AuthService.jwtInfo();
  let userCanAccessPage = false;
  if (jwtInfo && jwtInfo.roles.includes("Admin")) {
    userCanAccessPage = true;
  }

  const [items, setItems] = useState([]);
  const [detail, setDetail] = useState();
  const [Service, setService] = useState();
  const [template, setTemplate] = useState();

  const entity = props.match.params.entity;

  //GET COMPANIES
  const getCompanies = async () => {
    let response = await CompanyService.list({});
    if (!response.error) {
      setItems(
        response._embedded.map(i => {
          i.edit = false;
          i.id = i.companyId;
          return i;
        })
      );
    }
  };
  //GET CANDIDATES
  const getCandidates = async () => {
    let response = await CandidateService.list({});
    if (!response.error) {
      setItems(
        response._embedded.map(i => {
          i.edit = false;
          i.id = i.candidateId;
          return i;
        })
      );
    }
  };
  //GET POSITIONS
  const getPositions = async () => {
    let response = await PositionService.list({});
    if (!response.error) {
      setItems(
        response._embedded.map(i => {
          i.edit = false;
          return i;
        })
      );
    }
  };

  //GET TAGS
  const getTags = async () => {
    let response = await TagService.get({ type: "" });
    if (!response.error) {
      setItems(
        response._embedded.map(i => {
          i.edit = false;
          i.id = i.tagId;
          return i;
        })
      );
    }
  };

  //GET BD Activity TAGS
  const getBDActivityTags = async () => {
    let response = await ActivityTypeService.list({});
    if (!response.error) {
      setItems(
        response._embedded.map(i => {
          i.edit = false;
          return i;
        })
      );
    }
  };
  //Get Titles
  const getTitles = async () => {
    let response = await TitleService.get({ title: "" });
    if (response && response._embedded) {
      setItems(
        response._embedded.map(i => {
          i.edit = false;
          return i;
        })
      );
    }
  };
  //Get Degrees
  const getDegrees = async () => {
    let response = await DegreeService.get({ limit: 1000, degree: "" });

    console.log("degree", response);
    if (response && response._embedded) {
      setItems(
        response._embedded.map(i => {
          i.edit = false;
          return i;
        })
      );
    }
  };
  //Get News Sources
  const getNewsSources = async () => {
    let response = await NewsService.get({});
    if (response && response._embedded) {
      setItems(
        response._embedded.map(i => {
          i.edit = false;
          i.id = encodeURIComponent(i.feedUrl); //no "id" field in database
          return i;
        })
      );
    }
  };
  useEffect(() => {
    console.log(entity);
    switch (entity) {
      case "roles":
        setTemplate(<Roles />);
        break;
      case "companies":
        setDetail({
          titles: { singular: "Company", plural: "Companies" },
          inputs: [{ name: "name", type: "text" }],
          additionalDatas: false,
          access: { update: true, delete: true, create: "/client/new" },
        });
        getCompanies();
        setService(CompanyService);
        break;
      case "candidates":
        setDetail({
          titles: { singular: "Candidate", plural: "Candidates" },
          inputs: [
            { name: "firstName", type: "text" },
            { name: "lastName", type: "text" },
          ],
          additionalDatas: false,
          access: { update: true, delete: true, create: "/candidate/new" },
        });
        getCandidates();
        setService(CandidateService);
        break;
      case "positions":
        setDetail({
          titles: { singular: "Job Order", plural: "Job Orders" },
          inputs: [{ name: "title", type: "text" }],
          additionalDatas: ["company"],
          access: { update: true, delete: true, create: "/position/new" },
        });
        getPositions();
        setService(PositionService);
        break;
      case "tags":
        setDetail({
          titles: { singular: "Tag", plural: "Tags" },
          inputs: [
            { name: "tag", type: "text" },
            {
              name: "type",
              type: "select",
              options: [
                { label: "Skill", value: "skill" },
                { label: "Tag", value: "positionTag" },
                { label: "Market", value: "market" },
                { label: "Submarket", value: "submarket" },
                { label: "Technology", value: "technology" },
              ],
            },
          ],
          additionalDatas: ["type"],
          access: { update: true, delete: true, create: true },
        });
        getTags();
        setService(TagService);
        break;
      case "bd-activity-tags":
        setDetail({
          titles: { singular: "BD Activity Tag", plural: "BD Activity Tags" },
          inputs: [{ name: "activityType", type: "text" }],
          access: { update: true, delete: true, create: true },
        });
        getBDActivityTags();
        setService(ActivityTypeService);
        break;

      case "titles":
        setDetail({
          titles: { singular: "Title", plural: "Titles" },
          inputs: [{ name: "name", type: "text" }],
          access: { update: true, delete: true, create: true },
        });
        getTitles();
        setService(TitleService);
        break;
      case "degrees":
        setDetail({
          titles: { singular: "Degree", plural: "Degrees" },
          inputs: [{ name: "name", type: "text" }],
          access: { update: true, delete: true, create: true },
        });
        getDegrees();
        setService(DegreeService);
        break;
      case "news-sources":
        setDetail({
          param: "news-sources",
          titles: { singular: "News Source", plural: "New Sources" },
          inputs: [
            { name: "name", type: "text" },
            { name: "feedUrl", type: "text" },
          ],
          access: { update: true, delete: true, create: true },
        });
        getNewsSources();
        setService(NewsService);
        break;
      default:
        break;
    }
  }, []);

  //only admin users allowed
  if (!userCanAccessPage) {
    return <Redirect to="/settings" />;
  }

  return (
    <>
      {detail ? (
        <List
          detail={detail}
          items={items}
          setItems={setItems}
          Service={Service}
        />
      ) : (
        ""
      )}
      {template || ""}
    </>
  );
}
