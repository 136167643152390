import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export const formatAddress = (city, state, country, time, link) => {
  if (city || state || country) {
    let build =
      (city ? city : "") +
      (city && state ? ", " : "") +
      (state ? state : "") +
      (country && (state || city)
        ? " &bull; " + country
        : country
        ? country
        : "");
    return (
      <div className="address">
        <p>
          {link ? (
            <a
              target="_mullings"
              href={
                "https://www.google.com/maps/place/" +
                (city ? city.replace(/\s+/s, "+") : "") +
                ", " +
                (state ? state.replace(/\s+/s, "+") : "") +
                " " +
                (country ? country.replace(/\s+/s, "+") : "") +
                "/"
              }
            >
              <span dangerouslySetInnerHTML={{ __html: build }} />
            </a>
          ) : (
            build
          )}
        </p>
        {time ? <time>{moment().format("h:mmA")}</time> : ""}
      </div>
    );
  }
};
export const formatAddressWithStreet = ({
  address,
  city,
  state,
  country,
  time,
  link,
}) => {
  if (city || state || country) {
    let build =
      (address ? address + "<br>" : "") +
      (city ? city : "") +
      (city && state ? ", " : "") +
      (state ? state : "") +
      (country && (state || city)
        ? " &bull; " + country
        : country
        ? country
        : "");
    return (
      <div className="address">
        <p>
          {link ? (
            <a
              target="_mullings"
              href={
                "https://www.google.com/maps/place/" +
                (address ? address.replace(/\s+/s, "+") : "") +
                ", " +
                (city ? city.replace(/\s+/s, "+") : "") +
                ", " +
                (state ? state.replace(/\s+/s, "+") : "") +
                " " +
                (country ? country.replace(/\s+/s, "+") : "") +
                "/"
              }
            >
              <span dangerouslySetInnerHTML={{ __html: build }} />
            </a>
          ) : (
            build
          )}
        </p>
        {time ? <time>{moment().format("h:mmA")}</time> : ""}
      </div>
    );
  }
};

/*
 * Display UUID in desired format, for position it should be first 8 chars of UUID
 */
export const formatUUID = (uuid, type) => {
  let uuidFormatted;
  switch (type) {
    case "position":
    default:
      uuidFormatted = "#" + uuid.slice(0, 8);
      break;
  }

  return uuidFormatted;
};

/* Display
 * Display compensation in short format ex. $100k
 */
export const formatCompensation = (compensation, type) => {
  return type === "short"
    ? "$" + compensation.slice(0, -3) + "k"
    : parseInt(compensation).toLocaleString();
};
