import React, { useState, useEffect } from "react";

import { Colors } from "../../../styles/Colors";
import styled from "styled-components";
import {
  Container,
  PageTitle,
  Button,
  ButtonLink,
} from "../../../styles/Common";
import { Input } from "../../../styles/Form";
import { Form } from "../../Shared/Form";
import { BackLink } from "../../Shared/Common";
import imgCircleX from "../../../assets/circle-x.svg";
import { toast } from "react-toastify";
import { LoadingSubmit } from "../../../styles/Animation";

const Styled = styled.div`
  .news-sources {
    .items {
      ul {
        column-count: 1;
        column-gap: 0;
      }
      li {
        input {
          max-width: 400px;
          width: 400px;
        }
      }
    }
  }
  .actions {
    display: flex;
    border-bottom: 1px solid ${Colors.gray};
    margin: 0 0 30px;
    padding: 0 0 30px;
  }
  .search {
    width: 500px;
    input {
      width: 100%;
    }
    margin: 0 30px 0 0;
  }
  .items {
    ul {
      column-count: 3;
      column-gap: 20px;
    }
    li {
      padding: 2px 0 2px 10px;
      break-inside: avoid;
      border-left: 1px solid ${Colors.gray};
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 3px;
      height: 35px;
      .title {
        font-weight: 500;
        i {
          font-weight: 300;
          display: block;
          font-style: italic;
        }
      }
      &.active {
        justify-content: left;
      }
      &.active,
      &:hover {
        background: #eee;
        button {
          display: block;
        }
      }
      input,
      select {
        margin-right: 5px;
        border: 1px solid ${Colors.gray};
        padding: 5px;
        max-width: 135px;
      }
      button {
        display: none;
        margin-right: 5px;
      }
      .loading {
        margin: 5px 14px 0 0;
      }
    }
  }
`;

const ConfirmModal = styled.div`
  .bg {
    display: block;
    content: "";
    background: #000000c7;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    left: 0;
    top: 0;
  }
  .display {
    background: #fff;
    width: 500px;
    padding: 20px 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    p {
      margin-bottom: 5px;
    }
    em {
      font-style: italic;
      font-weight: 500;
    }
  }
  .cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    cursor: pointer;
  }
  .input-confirm {
    display: flex;
    button {
      margin-left: 15px;
    }
  }
`;

export const List = ({ detail, items, setItems, Service }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmedDelete, setConfirmedDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const cancelEdit = item => {
    item.edit = false;

    setItems([...items.filter(i => i !== item)]);
  };

  const onBlur = (e, item) => {
    if (!e.target.value.trim()) {
      item.edit = false;
      setItems([...items.filter(i => i !== item)]);
    }
    e.stopPropagation();
  };

  const onChange = (e, item) => {
    item[e.target.name] = e.target.value;
    setItems([...items]);
  };

  const addItem = () => {
    //add the first item to create a new entry
    let newItem = { ...items[0] };
    for (let i in newItem) {
      newItem[i] = "";
    }
    newItem.edit = true;
    setItems([newItem, ...items]);
  };

  const saveItem = async item => {
    let response = {};
    setLoading(true);

    //build object for save
    let names = {};
    detail.inputs.forEach(input => {
      names[input.name] = item[input.name].replace(/\t/, " ");
    });
    if (item.id) {
      response = await Service.update({
        id: item.id,
        ...names,
      });
    } else {
      //create new company
      response = await Service.create({ ...names });
    }

    if (!response.error) {
      //add in id if newly created
      if (!item.id) {
        item.id = response.id;
      }
      toast.success("Item has been successfully saved", {
        position: "bottom-center",
      });
    } else {
      toast.error("There was an error saving the item", {
        position: "bottom-center",
      });
    }
    setLoading(false);
    item.edit = false;
    setItems([...items]);
  };

  const confirmDelete = item => {
    setConfirmModalOpen(item);
  };

  const cancelDeletion = () => {
    setConfirmModalOpen(false);
  };

  const checkConfirmedType = (e, value) => {
    console.log("e", e.target);
    if (e.target.value.toLowerCase() === "confirm") {
      setConfirmedDelete(true);
    } else {
      setConfirmedDelete(false);
    }
  };

  const deleteItem = async item => {
    //TagService.delete();
    let response = {};

    response = await Service.del(item.id);

    if (!response.error) {
      toast.success("Item has been successfully deleted", {
        position: "bottom-center",
      });
      setItems([...items.filter(i => i !== item)]);
    } else {
      toast.error(
        `There was an error deleting this item: ${response?.error ??
          "<No error message>"}`,
        {
          position: "bottom-center",
        }
      );
    }
    setConfirmModalOpen(false);
  };

  //Filter from search
  const [filterInput, setFilterInput] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    handleFilterChange();
  }, [items]);
  const handleFilterChange = e => {
    let value, regex;
    if (!e) {
      value = filterInput;
    } else {
      value = e.target.value || undefined;
    }
    regex = new RegExp(value, "i");
    if (value) {
      setFilteredItems(
        items.filter(i => {
          for (let _i of detail.inputs) {
            if (i[_i.name].match(regex)) {
              return i;
            }
          }
        })
      );
    } else {
      setFilteredItems(items);
    }
    setFilterInput(value);
  };

  console.log("items", items);
  console.log("filterdItmes", filteredItems);
  return (
    <>
      <Styled>
        <Container className={detail.param || ""}>
          <BackLink to="/settings" verbiage="Back to settings" />
          <PageTitle>Manage {detail.titles.plural}</PageTitle>

          <div className="actions">
            <Input className="search" type="search">
              <Form.TextInput
                name="search"
                type="text"
                value={filterInput}
                onChange={handleFilterChange}
                placeholder={"Filter " + detail.titles.plural}
              />
              <span className="go"></span>
            </Input>
            {detail.access.create === true ? (
              <Button onClick={addItem}>
                Create New {detail.titles.singular}
              </Button>
            ) : (
              <ButtonLink to={detail.access.create}>
                Create New {detail.titles.singular}
              </ButtonLink>
            )}
          </div>

          <div className="items">
            {filteredItems.length ? (
              <ul>
                {filteredItems.map((item, i) => {
                  if (!item.edit) {
                    return (
                      <li key={i} id={"itemId-" + i}>
                        <span className="title">
                          {detail.inputs.map(input => {
                            return input.type === "text"
                              ? item[input.name] + " "
                              : "";
                          })}
                          {detail.additionalDatas ? (
                            <i>{item[detail.additionalDatas[0]]}</i>
                          ) : (
                            ""
                          )}
                        </span>

                        <Button
                          size="small"
                          primary
                          onClick={() => confirmDelete(item)}
                        >
                          Delete
                        </Button>
                      </li>
                    );
                  } else {
                    return (
                      <li key={i} id={"itemId-" + i} className="active">
                        {detail.inputs.map((input, k) => {
                          if (input.type === "text") {
                            return (
                              <input
                                key={k}
                                name={input.name}
                                autoFocus={k === 0 ? true : false}
                                placeholder={"Add " + input.name}
                                defaultValue={item[input.name]}
                                onChange={e => onChange(e, item)}
                                onBlur={e => onBlur(e, item)}
                              />
                            );
                          } else if (input.type === "select") {
                            return (
                              <select
                                key={k}
                                name={input.name}
                                value={item[input.name]}
                                onChange={e => onChange(e, item)}
                              >
                                <option></option>
                                {input.options.map((o, io) => (
                                  <option key={io} value={o.value}>
                                    {o.label}
                                  </option>
                                ))}
                              </select>
                            );
                          }
                        })}
                        {loading ? (
                          <LoadingSubmit
                            className="loading"
                            size="15"
                          ></LoadingSubmit>
                        ) : (
                          <>
                            <Button
                              size="small"
                              primary
                              onClick={() => saveItem(item)}
                            >
                              Save
                            </Button>
                            <Button
                              size="small"
                              onClick={() => cancelEdit(item)}
                            >
                              Cancel
                            </Button>
                          </>
                        )}
                      </li>
                    );
                  }
                })}
              </ul>
            ) : (
              "No items found"
            )}
          </div>
        </Container>
      </Styled>
      {confirmModalOpen && (
        <ConfirmModal>
          <div className="bg"></div>
          <div className="display">
            <img
              className="cancel"
              onClick={cancelDeletion}
              src={imgCircleX}
              title="Cancel"
              alt="Cancel"
            />
            <p>This deletion is irreversible.</p>
            <p>
              Type <b>confirm</b> to confirm deletion of{" "}
              {detail.titles.singular}:{" "}
              <em>{confirmModalOpen[detail.inputs[0].name]}</em>
            </p>
            <div className="input-confirm">
              <Input type="text">
                <Form.TextInput
                  type="text"
                  value={""}
                  onChange={e =>
                    checkConfirmedType(
                      e,
                      confirmModalOpen[detail.inputs[0].name]
                    )
                  }
                />
              </Input>

              {confirmedDelete ? (
                <Button primary onClick={() => deleteItem(confirmModalOpen)}>
                  Delete
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};
