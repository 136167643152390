import { list } from "./lib";
import Config from "../config/config";

const create = inputData => {
  return fetch(Config.API_URL + "companies/", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const update = inputData => {
  return fetch(Config.API_URL + "companies/" + inputData.id, {
    method: "PUT",
    body: JSON.stringify({ ...inputData, id: undefined }), //error from api if id is sent in body
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const del = id => {
  return fetch(Config.API_URL + "companies/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const get = id => {
  return fetch(Config.API_URL + "companies/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

/* NOTES
 */
const getNotes = inputData => {
  return fetch(
    Config.API_URL +
      "companies/" +
      inputData.id +
      "/notes?limit=" +
      inputData.limit +
      "&offset=" +
      inputData.offset,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const addNote = inputData => {
  return fetch(Config.API_URL + "companies/" + inputData.id + "/notes", {
    method: "POST",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

/* POSITIONS
 */
const getPositions = inputData => {
  return fetch(
    Config.API_URL +
      "companies/" +
      inputData.id +
      "/positions?limit=" +
      inputData.limit,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
//TAGS (Markets/Specialties)

const addTag = inputData => {
  //tag Creation is inconsistent in api between candidate/position tags and client markets/specialties
  // inputData.tagIds = [inputData.tagId];

  return fetch(
    Config.API_URL + "companies/" + inputData.id + "/" + inputData.typePlural,
    {
      method: "POST",
      body: JSON.stringify([inputData.tagId]),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteTag = inputData => {
  return fetch(
    Config.API_URL +
      "companies/" +
      inputData.entityId +
      "/" +
      inputData.typePlural +
      "/" +
      inputData.tagId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
//ADDRESS
const updateAddress = inputData => {
  return fetch(Config.API_URL + "companies/" + inputData.id + "/addresses", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

//EMPLOYEE - add
const addEmployee = inputData => {
  return fetch(
    Config.API_URL +
      "companies/" +
      inputData.id +
      "/employees/" +
      inputData.candidateId,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteEmployee = inputData => {
  return fetch(
    Config.API_URL +
      "companies/" +
      inputData.id +
      "/employees/" +
      inputData.candidateId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
export const CompanyService = {
  create,
  del,
  get,
  list: query => list("companies/", { query }),
  update,

  // NOTES
  getNotes,
  addNote,
  getPositions,
  addTag,
  deleteTag,
  updateAddress,
  addEmployee,
  deleteEmployee,
};
