import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/Colors";

const Styled = styled.div`
  &.enter {
    &:before {
      content: "Drop Resume";
      position: absolute;
      font-size: 3em;
      font-weight: 300;
      text-align: center;
      line-height: 2.7em;
      top: 0;
      left: 0;
      z-index: 100;
      display: block;
      height: 100%;
      width: 100%;
      background: #eee;
      border: 2px dashed ${Colors.primary};
    }
  }
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  .card {
    padding: 25px;
    text-align: center;
    height: 60px;
    cursor: pointer;

    &.no-resume {
      display: flex;
      font-size: 2.3em;
      justify-content: center;
      align-items: center;
      font-weight: 300;
    }
  }
  .mimic-input {
    margin-bottom: 8px;
  }

  .error {
    color: ${Colors.error};
  }
`;
export const DropFileUpload = props => {
  const [status, setStatus] = useState("");

  const onDragEnter = e => {
    setStatus("enter");
    e.preventDefault();
    e.stopPropagation();
  };
  const onDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const doNothing = e => {
    e.preventDefault();
  };
  const onDrop = e => {
    setStatus("");
    e.target.files = e.dataTransfer.files;
    props.prepareResumeFile(e);
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Styled
      className={status}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={doNothing}
      onDrop={onDrop}
    >
      {props.children}
    </Styled>
  );
};
