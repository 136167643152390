import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "../Shared/Form";
import { BackLink } from "../Shared/Common";
import { AuthService } from "../../services/Auth";
import { useForm } from "react-hook-form";
import { UserService } from "../../services/User";
import styled from "styled-components";
import { Container, Button, PageTitle } from "../../styles/Common";
import { Input } from "../../styles/Form";
import { LoadingSubmit } from "../../styles/Animation";

const Styled = styled.div`
  .content {
    margin: 1em 0;
  }
  input {
    margin-right: 15px;
  }
`;

export default function AuthorizeEmail(props) {
  const jwtInfo = AuthService.jwtInfo();
  let userCanAccessPage = false;
  if (jwtInfo && jwtInfo.roles.includes("Admin")) {
    userCanAccessPage = true;
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  //react-hook-form
  const { register, reset, handleSubmit } = useForm();

  const onSubmit = async data => {
    console.log("submitted", data);
    setLoading(true);
    setErrorMessage("");
    if (data.email.trim()) {
      let response = await UserService.resetPassword({ email: data.email });
      if (response.error) {
        setErrorMessage(response.error);
      } else {
        reset();
        toast.success(
          "User has been sent an email (" + data.email + ") to reset password",
          {
            position: "bottom-center",
          }
        );
      }
      setLoading(false);
    } else {
      setErrorMessage("Please enter a valid email address");
      setLoading(false);
    }
  };

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");
  };

  //user created or user not allowed on page, send to dashboard
  if (!userCanAccessPage) {
    return <Redirect to="/settings" />;
  }

  return (
    <Container className="form">
      <BackLink to="/settings" verbiage="Back to settings" />
      <PageTitle>Reset User&apos;s Password</PageTitle>
      <Styled>
        <div className="content">
          <p>Input email address of the user you would like to reset</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input>
            <Form.TextInput
              name="email"
              type="email"
              value=""
              forwardRef={register({ required: true })}
              onChange={onChange}
            />
            {!loading ? <Button primary>Send Reset</Button> : ""}
            {loading ? <LoadingSubmit size={20}></LoadingSubmit> : ""}
          </Input>
          {errorMessage && <Form.Error message={errorMessage} />}
        </form>
      </Styled>
    </Container>
  );
}
