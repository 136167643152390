import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { Form } from "../Shared/Form";
import { BackLink } from "../Shared/Common";
import { UserService } from "../../services/User";
import { AuthService } from "../../services/Auth";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Container, Button, PageTitle } from "../../styles/Common";
import { Form as StyledForm, Input } from "../../styles/Form";
import styled from "styled-components";
import { NoteService } from "../../services/Note";
import SanitizedHTML from "react-sanitized-html";
import moment from "moment";

const StyledNotes = styled.div`
  .search {
    margin: 0 0 50px;
    input {
      width: 100%;
    }
  }
  li {
    margin: 20px 0 0;
    border-bottom: 1px solid #eee;
    time {
      font-weight: bold;
    }
    article {
      padding: 20px 0;
    }
  }
`;

export default function Index(props) {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);
  const jwtInfo = AuthService.jwtInfo();
  const [notes, setNotes] = useState();
  const [filteredNotes, setFilteredNotes] = useState([]);

  const getNotes = async () => {
    let response = await NoteService.list({
      filter: { search: { field: "type", value: "note" } },
    });

    if (!response.error) {
      setNotes(response._embedded);
      setFilteredNotes(response._embedded);
    } else {
      toast.error(response.error);
    }
    console.log("response", response);
  };
  useEffect(() => {
    getNotes();
  }, []);

  const [filterInput, setFilterInput] = useState();
  const handleFilterChange = e => {
    const input = e.target.value.trim().toLowerCase();
    if (input) {
      let m = new RegExp(input, "i");

      setFilterInput(input);
      setFilteredNotes(notes.filter(n => n.note.match(m)));
    } else {
      setFilterInput("");
      setFilteredNotes(notes);
    }
  };

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <Container className="form">
      <PageTitle>My Notes</PageTitle>
      <StyledNotes>
        <Input className="search" type="search">
          <Form.TextInput
            name="search"
            type="text"
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Filter notes"}
          />
          <span className="go"></span>
        </Input>
        {filteredNotes.length ? (
          <ul>
            {filteredNotes.map((n, i) => (
              <li key={i}>
                <time>{moment(n.date).format("MMM D, YYYY")}</time>
                <article>
                  <SanitizedHTML html={n.note} />
                </article>
              </li>
            ))}
          </ul>
        ) : (
          <p>No notes were found</p>
        )}
      </StyledNotes>
    </Container>
  );
}
