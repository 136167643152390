import React, { useState, useEffect } from "react";
import { Button } from "../../styles/Common";
import { NoteService } from "../../services/Note";

const audioUtils = require("../../helpers/audioUtils"); // for encoding audio data as PCM
const crypto = require("crypto"); // tot sign our pre-signed URL
const v4 = require("../../helpers/aws-signature-v4"); // to generate our pre-signed URL
const marshaller = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON
const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
const mic = require("microphone-stream"); // collect microphone input as a stream of raw bytes

// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
  util_utf8_node.toUtf8,
  util_utf8_node.fromUtf8
);

// our global variables for managing state
let languageCode = "en-US";
let region = "us-east-1";
let sampleRate = 44100;
let transcription = "";
let socket;
let micStream;
let socketError = false;
let transcribeException = false;

export default function SpeechToText(props) {
  const [transcriptionText, settranscriptionText] = useState("");
  const [isRecording, setRecordingState] = useState(false);

  //get credentials
  const [awsCredentials, setAwsCredentials] = useState(false);
  useEffect(() => {
    const getCredentials = async () => {
      let _credentials = await NoteService.speech2Text(); //limit required... but should it be?

      setAwsCredentials(_credentials);
    };

    getCredentials();
  }, []);
  //TODO: clean this mess up

  // check to see if the browser allows mic access
  if (!window.navigator.mediaDevices.getUserMedia) {
    // Use our helper method to show an error on the page

    // maintain enabled/distabled state for the start and stop buttons
    alert("Cannot access user media");
  }

  let startAudio = () => {
    setRecordingState(true);
    settranscriptionText(""); // clear any previous text. This is asynchronous though so there could theoretically be race conditions, but it should never be slower than the api call

    // first we get the microphone input from the browser (as a promise)...
    window.navigator.mediaDevices
      .getUserMedia({
        video: false,
        audio: true,
      })
      // ...then we convert the mic stream to binary event stream messages when the promise resolves
      .then(streamAudioToWebSocket)
      .catch(function(error) {
        alert(
          "There was an error streaming your audio to Amazon Transcribe. Please try again."
        );
      });
  };

  let streamAudioToWebSocket = userMediaStream => {
    //retrieve the mic input from the browser, via the microphone-stream module
    micStream = new mic();
    micStream.setStream(userMediaStream);

    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = createPresignedUrl();

    //open up our WebSocket connection
    socket = new WebSocket(url);
    socket.binaryType = "arraybuffer";

    // when we get audio data from the mic, send it to the WebSocket if possible
    socket.onopen = function() {
      micStream.on("data", function(rawAudioChunk) {
        // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
        let binary = convertAudioToBinaryMessage(rawAudioChunk);

        if (socket.OPEN) {
          socket.send(binary);
        } else {
          // console.log("socket is not open");
        }
      });
    };

    wireSocketEvents();
  };

  let convertAudioToBinaryMessage = audioChunk => {
    let raw = mic.toRaw(audioChunk);

    if (raw == null) return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = audioUtils.downsampleBuffer(raw, sampleRate);
    let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
  };

  let wireSocketEvents = () => {
    // handle inbound messages from Amazon Transcribe
    socket.onmessage = function(message) {
      //convert the binary event stream message to JSON
      let messageWrapper = eventStreamMarshaller.unmarshall(
        Buffer(message.data)
      );
      let messageBody = JSON.parse(
        String.fromCharCode.apply(String, messageWrapper.body)
      );
      if (messageWrapper.headers[":message-type"].value === "event") {
        handleEventStreamMessage(messageBody);
      } else {
        transcribeException = true;
      }
    };

    socket.onerror = function() {
      socketError = true;
      alert(
        "There was an error connecting to the webSocket. Please try again."
      );
    };

    socket.onclose = function(closeEvent) {
      setRecordingState(false);
      micStream.stop();

      // the close event immediately follows the error event; only handle one.
      if (!socketError && !transcribeException) {
        if (closeEvent.code !== 1000) {
          /*console.log(
            "</i><strong>Streaming Exception</strong><br>" + closeEvent.reason
          ); */
        }
      }
    };
  };

  let handleEventStreamMessage = messageJson => {
    let results = messageJson.Transcript.Results;
    let transcript = "";
    if (results.length > 0) {
      if (results[0].Alternatives.length > 0) {
        transcript = results[0].Alternatives[0].Transcript;

        // fix encoding for accented characters
        transcript = decodeURIComponent(escape(transcript));

        // update the textarea with the latest result
        settranscriptionText(transcript);

        // if this transcript segment is final, add it to the overall transcription
        if (!results[0].IsPartial) {
          transcription += transcript + "\n";
          settranscriptionText(transcription);
        }
      }
    }
  };
  let getAudioEventMessage = buffer => {
    // wrap the audio data in a JSON envelope
    return {
      headers: {
        ":message-type": {
          type: "string",
          value: "event",
        },
        ":event-type": {
          type: "string",
          value: "AudioEvent",
        },
      },
      body: buffer,
    };
  };

  let closeSocket = () => {
    if (socket && socket.OPEN) {
      micStream.stop();

      // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
      let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
      let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
      socket.send(emptyBuffer);
    }
  };

  let createPresignedUrl = () => {
    let endpoint = "transcribestreaming." + region + ".amazonaws.com:8443";

    // get a preauthenticated URL that we can use to establish our WebSocket
    return v4.createPresignedURL(
      "GET",
      endpoint,
      "/stream-transcription-websocket",
      "transcribe",
      crypto
        .createHash("sha256")
        .update("", "utf8")
        .digest("hex"),
      {
        key: awsCredentials.key,
        secret: awsCredentials.secret,
        protocol: "wss",
        expires: 180,
        region: region,
        query:
          "language-code=" +
          languageCode +
          "&media-encoding=pcm&sample-rate=" +
          sampleRate,
      }
    );
  };

  let saveRecording = () => {
    closeSocket(); // always close the socket or there will be issues
    settranscriptionText(""); // this doesn't work consistently
    props.insertAudio(transcriptionText); // send transcription text back to parent
    transcription = "";
  };
  let cancelRecording = () => {
    closeSocket(); // always close the socket or there will be issues
    settranscriptionText(""); // this doesn't work consistently
    props.setMicSelected(false); // toggle the microphone modal
    transcription = "";
  };
  return (
    <div className="microphone-modal">
      <div className="audio-text">{transcriptionText}</div>
      <div className="actions">
        <Button
          type="button"
          size="small"
          onClick={() => {
            cancelRecording();
          }}
        >
          Cancel
        </Button>
        {isRecording ? (
          <>
            <Button
              style={{ backgroundColor: "#ff0000", color: "#ffffff" }}
              type="button"
              size="small"
              primary
              onClick={() => saveRecording()}
            >
              Insert Recording
            </Button>
            <div className="ripple"></div>
          </>
        ) : (
          <Button
            type="button"
            size="small"
            primary
            onClick={() => startAudio()}
          >
            Start Recording
          </Button>
        )}
      </div>
    </div>
  );
}
