import Moment from "moment";

const timeSince = (dateStart, dateEnd) => {
  return Moment(dateEnd).diff(dateStart, "days");
};

const formatDate = date => {
  return Moment(date).format("M/D/YY");
};
const formatTime = date => {
  return Moment(date).format("h:mmA");
};
export const DateTime = {
  timeSince,
  formatDate,
  formatTime,
};
