import React, { useState, useEffect } from "react";
import device from "../../config/device";
import { Redirect, Link, useLocation } from "react-router-dom";
import { AuthService } from "../../services/Auth";
import { ConferenceService } from "../../services/Conference";
import { ActivitiesService } from "../../services/Activities";
import { CandidateService } from "../../services/Candidate";
import { CompanyService } from "../../services/Company";
import { ContactService } from "../../services/Contact";
import { UserService } from "../../services/User";
import { EventService } from "../../services/Event";
import { PositionService } from "../../services/Position";
import { NotificationService } from "../../services/Notification";
import { FormInvoiceInfo } from "../Shared/FormInvoiceInfo";
import { useStore } from "@kwhitley/use-store";
import moment from "moment";
import { Card, Dropdown } from "../Shared/Common";
import Select from "react-select";
import {
  Container,
  Button,
  ButtonClose,
  ButtonDropdown,
  ListDl,
  ButtonLink,
} from "../../styles/Common";
import { Tabs, Form as StyledForm } from "../../styles/Form";
import { Form } from "../../components/Shared/Form";

import { Colors } from "../../styles/Colors";
import imgChevron from "../../assets/chevron.svg";
import imgViewBoard from "../../assets/board.svg";
import imgViewTable from "../../assets/table.svg";
import imgCircleCheck from "../../assets/circle-check-green.svg";
import imgCircle from "../../assets/circle.svg";
import imgExternal from "../../assets/external.svg";
import imgBookmark from "../../assets/bookmark.svg";
import imgX from "../../assets/x.svg";
import imgCircleX from "../../assets/circle-x.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";

import styled, { css } from "styled-components";
import { TodoService } from "../../services/Todo";
import { toast } from "react-toastify";
import { BookmarkService } from "../../services/Bookmark";
import { NewsService } from "../../services/News";
import Parser from "rss-parser";
import { MentionsInput, Mention } from "react-mentions";
import { LoadingSubmit } from "../../styles/Animation";
import {
  orderBy as _orderBy,
  unescape as _unescape,
  startCase as _startCase,
} from "lodash";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import {
  resetItem,
  addItem,
  deleteItem,
  handleIteratorChange,
} from "../../helpers/FormFieldsIterator";
import "react-widgets/dist/css/react-widgets.css";
import PropTypes from "prop-types";

const Styled = styled.div`
  .main {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    grid-template-areas: "col-1 col-2 col-3";
    grid-gap: 15px;

    .col {
      .actions {
        margin-top: 20px;
        text-align: center;
        button {
          margin-right: 0;
        }
      }
      li {
        time {
          display: block;
          font-size: 12px;
        }
      }
    }
    .col-1 {
      grid-area: col-1;
      min-width: 250px;
      max-width: 400px;
    }
    .col-2 {
      grid-area: col-2;
    }
    .col-3 {
      grid-area: col-3;
    }
  }
  @media ${device.tabletMMax} {
  }
`;

const EditableInlineInput = css`
  input {
    border: 0;
    outline: none;
    height: 31px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid ${Colors.gray};
    &:focus {
      border-bottom-color: ${Colors.highlight};
    }
  }
`;

export default function BizDevDashboard() {
  const [isLoggedIn] = useStore("isLoggedIn", AuthService.verifyLogin());
  const jwtInfo = AuthService.jwtInfo();

  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      <Styled>
        <Kanban>kanban</Kanban>
        <div className="main">
          <div className="col col-1">
            <UpcomingConferences />
            <TodoList />
          </div>
          <div className="col col-2">
            <LatestNews />
          </div>
          <div className="col col-3">
            <TodaysActivities />
            <Bookmarks />
          </div>
        </div>
      </Styled>
    </Container>
  );
}

/**
 * Upcoming Conferences
 */
const StyledUpcomingConferences = styled(Card)`
  margin-bottom: 15px;

  dl div:last-child dd,
  dl div:last-child dt {
    border-bottom-width: 1px;
  }
`;

const UpcomingConferences = () => {
  const [conferences, setConferences] = useState([]);

  useEffect(() => {
    const getConferences = async () => {
      let _items = await ConferenceService.list({
        limit: 10,
        offset: 0,
        //filter: filter,
      });

      if (!_items.error) {
        setConferences(_items._embedded);
      } else {
        //
      }
    };
    getConferences();
  }, []);

  return (
    <StyledUpcomingConferences>
      <h1>Upcoming Conferences</h1>
      <div className="content">
        {conferences && conferences.length ? (
          <>
            <ListDl>
              {conferences.map((conference, i) => (
                <div key={i}>
                  <dt>
                    <Link to={"/bd/conference/" + conference.conferenceId}>
                      {conference.name}
                    </Link>
                  </dt>
                  <dd>
                    {moment(conference.startDate).format("MMM D")} -{" "}
                    {moment(conference.endDate).format("MMM D, YYYY")}
                  </dd>
                </div>
              ))}
            </ListDl>
            <div className="actions">
              <ButtonLink size="small" to={"/bd/conferences"}>
                View All Conferences
              </ButtonLink>
            </div>
          </>
        ) : (
          <>
            <p>No conferences found</p>
            <div className="actions">
              <ButtonLink size="small" to={"/bd/conference/new"}>
                Add Conference
              </ButtonLink>
            </div>
          </>
        )}
      </div>
    </StyledUpcomingConferences>
  );
};
/**
 * To-do List
 */
const StyledTodoList = styled(Card)`
  .filter {
    margin: 20px;
  }
  .content li {
    min-height: 31px;
    line-height: 26px;

    padding: 6px;
    border-bottom: 1px solid ${Colors.gray};
    margin: 0 0 0 31px;
    position: relative;
    display: flex;
    .status {
      display: block;
      position: absolute;
      top: 10px;
      left: -27px;
      content: "";
      width: 22px;
      height: 22px;
      background: url(${imgCircle});
      background-size: cover;
    }
    &.complete {
      .status {
        left: -31px;
        width: 28px;
        height: 28px;
        background-image: url(${imgCircleCheck});
      }
    }
    &.add {
      ${EditableInlineInput}
    }
    &.edit {
      &:before {
        display: none;
      }
      ${EditableInlineInput}
    }
    .delete {
      display: none;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      .delete {
        display: block;
      }
    }
    .spacer {
      flex: 1 1 auto;
    }
  }
  .mentions {
    width: 100%;
    .mentions__suggestions__item {
      margin-left: 5px;
      &:hover {
        background: #eee;
      }
    }
  }
  .actions {
    text-align: left !important;
  }
`;

const TodoList = () => {
  const [todos, setTodos] = useState([]);
  const [showAddItem, setShowAddItem] = useState(false);
  const [newTodoValue, setNewTodoValue] = useState(["", ""]);
  const [newMentions, setNewMentions] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      let response = await UserService.list({});

      if (response._embedded) {
        setUsers(
          response._embedded.map(o => {
            return {
              id: o.id,
              display: o.firstName + " " + (o.lastName ? o.lastName[0] : ""),
            };
          })
        );
      }
    };
    const getTodos = async () => {
      const response = await TodoService.list({});
      if (!response.error) {
        setTodos(
          response._embedded.map(t => {
            t.edit = false;
            return t;
          })
        );
      }
    };
    getTodos();
    getUsers(); //needed for @ mentions
  }, []);

  const addTodo = async (e, clickedSuggestion) => {
    if (!clickedSuggestion && newTodoValue[1].trim()) {
      //create it
      const response = await TodoService.create({
        todo: newTodoValue[1],
        mentioned: newMentions.map(m => m.id),
      });

      if (!response.error) {
        setTodos([
          ...todos,
          { todo: newTodoValue[1], completed: false, id: response.id },
        ]);
        setNewTodoValue(["", ""]);
        setShowAddItem(false);
      } else {
        toast.error("There was an error creating the todo", {
          position: "bottom-center", //hh
        });
      }
    }
  };

  const deleteTodo = async item => {
    const response = await TodoService.del(item.id);

    if (!response.error) {
      setTodos(todos.filter(i => i !== item));
    } else {
      toast.error("There was an error deleting the todo", {
        position: "bottom-center",
      });
    }
  };

  const completeItem = async item => {
    const response = await TodoService.update({
      id: item.id,
      data: { completed: !item.completed },
    });

    if (!response.error) {
      item.completed = !item.completed;
      setTodos([...todos]);
    } else {
      toast.error("There was an error updating the todo", {
        position: "bottom-center",
      });
    }
  };

  const updateItem = async item => {
    const _item = document.getElementById("todoItem-edit");

    item.todo = _item.value;
    const response = await TodoService.update({
      id: item.id,
      data: { todo: item.todo },
    });

    if (!response.error) {
      item.todo = _item.value;
      item.edit = false;
      setTodos([...todos]);
    } else {
      toast.error("There was an error updating the todo", {
        position: "bottom-center",
      });
    }
  };

  const editItem = item => {
    item.edit = true;
    setTodos([...todos]);
  };

  const onMentionChange = (event, newValue, newPlainTextValue, mentions) => {
    setNewTodoValue([newValue, newPlainTextValue]);
    setNewMentions(mentions);
  };

  //filter
  const [filter, setFilter] = useState(false);

  const filterTypes = filter => {
    setFilter(filter); //todosFiltered
  };

  return (
    <StyledTodoList>
      <h1>To-do List</h1>
      <Tabs className="filter">
        <ul>
          <li
            className={!filter ? "active" : ""}
            onClick={() => filterTypes(false)}
          >
            Todo
          </li>
          <li
            className={filter ? "active" : ""}
            onClick={() => filterTypes(true)}
          >
            Completed
          </li>
        </ul>
      </Tabs>
      <div className="content">
        {todos.length ? (
          <ul>
            {todos
              .filter(t => t.completed === filter)
              .map((item, i) => {
                return (
                  <li
                    key={i}
                    className={
                      (item.completed ? "complete" : "") +
                      (item.edit ? " edit" : "")
                    }
                  >
                    <span
                      className="status"
                      onClick={() => completeItem(item)}
                    ></span>
                    {item.edit ? (
                      <input
                        id="todoItem-edit"
                        defaultValue={item.todo}
                        autoFocus={true}
                        onBlur={() => updateItem(item)}
                      />
                    ) : (
                      <>
                        <span className="title" onClick={() => editItem(item)}>
                          {item.todo}
                        </span>
                        <span className="spacer"></span>
                        <ButtonClose
                          className="delete"
                          type="button"
                          onClick={() => deleteTodo(item)}
                        >
                          <img src={imgX} alt="Delete" title="Delete" />
                        </ButtonClose>
                        <span className="info"></span>
                      </>
                    )}
                  </li>
                );
              })}
          </ul>
        ) : (
          <p>No items found</p>
        )}

        {showAddItem ? (
          <ul>
            <li className="add">
              <span className="status"></span>
              <MentionsInput
                className="mentions"
                value={newTodoValue[0]}
                onChange={onMentionChange}
                singleLine={true}
                onBlur={addTodo}
              >
                <Mention trigger="@" data={users} />
              </MentionsInput>
            </li>
          </ul>
        ) : (
          ""
        )}
        <div className="actions">
          <Button
            size="small"
            onClick={() => {
              setShowAddItem(!showAddItem);
              filterTypes(false);
            }}
          >
            Add To-Do Item
          </Button>
        </div>
      </div>
    </StyledTodoList>
  );
};

/**
 * Latest Activities
 */
// const StyledLatestActivities = styled(Card)`
//   li {
//     margin-bottom: 21px;
//   }
//   .user {
//     font-weight: 600;
//     margin: 0 10px 0 0;
//   }
//   time {
//     font-size: 14px;
//   }
// `;

// const LatestActivities = () => {
//   const [userActivities, setUserActivities] = useState([]);

//   useEffect(() => {
//     const getUserActivities = async () => {
//       let limit = 10;
//       let response = await UserActivityService.list({ limit: limit });
//       if (!response.error) {
//         let _activities =
//           response._embedded.length > limit
//             ? response._embedded.splice(0, limit)
//             : response._embedded;
//         setUserActivities(
//           _activities.map(a => {
//             //hack replaceent, need to fix routes to accomodate api return
//             a.targetUrl = a.targetUrl
//               .replace(/events/, "event")
//               .replace(/contacts/, "contact")
//               .replace(/conferences/, "conference")
//               .replace(/companies/, "company");
//             return a;
//           })
//         );
//       } else {
//         toast.error("Error retrieving users activities", {
//           position: "bottom-center",
//         });
//       }
//     };
//     getUserActivities();
//   }, []);

//   return (
//     <StyledLatestActivities>
//       <h1>Latest Activities</h1>
//       <div className="content">
//         {userActivities.length ? (
//           <ul>
//             {userActivities.map((activity, i) => (
//               <li key={i}>
//                 <Link to={"/bd" + activity.targetUrl} className="title">
//                   {activity.activityMessage}
//                 </Link>
//                 <time>{moment(activity.createdAt).fromNow()}</time>
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <p>No recent activities</p>
//         )}
//       </div>
//     </StyledLatestActivities>
//   );
// };

/*
 * Todays Activities
 */

const StyledTodaysActivities = styled(Card)`
  grid-area: today;
  min-width: 300px;
  margin-bottom: 15px;
  @media ${device.laptopMax} {
    grid-area: recent;
  }
  .filter {
  }
  ul {
    margin: 0 15px 0 15px;
  }
  li {
    margin: 0 0 15px;
  }

  .title {
    display: block;
    font-weight: 700;
    font-size: 1rem;
  }
  .note {
    font-size: 0.857rem;
    margin: 2px 0 0;
    color: ${Colors.primary}bf;
    time {
      font-style: italic;
    }
  }
`;
const TodaysActivities = () => {
  //get activities for today only

  const [filteredActivities, setFilteredActivities] = useState([]);

  const getNotifications = async () => {
    let response = await NotificationService.get();
    if (!response.error && !response.errors) {
      let _notifications = response._embedded
        .filter(
          n =>
            n.type.match(/task|call/i) &&
            moment(n.date).diff(new Date(), "days") >= -7
        )
        .map(n => {
          let target;
          switch (n.target) {
            case "Contact":
              target = "bd/contact";
              break;
            case "Position":
              target = "bd/position";
              break;
            case "Company":
              target = "bd/company";
              break;
            case "Event":
              target = "bd/event";
              break;
            default:
              break;
          }

          n.url = target ? "/" + target + "/" + n.id : "#";

          return n;
        });
      setFilteredActivities(_notifications);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <StyledTodaysActivities>
      <h1>This Week&apos;s Activities</h1>

      {filteredActivities && filteredActivities.length ? (
        <ul>
          {filteredActivities.map((a, i) => {
            return (
              <li key={i}>
                <Link to={a.url} className="title">
                  {_startCase(a.type)}
                </Link>
                <div className="note">
                  <div dangerouslySetInnerHTML={{ __html: a.title }} /> at{" "}
                  <time>{moment(a.date).format("h:mm:A")}</time>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="content">No activities for this week</div>
      )}
    </StyledTodaysActivities>
  );
};

/**
 * Latest News
 */
const StyledLatestNews = styled(Card)`
  margin-bottom: 15px;
  .content {
    max-height: 400px;
    overflow-x: auto;
  }
  li {
    margin-bottom: 21px;
  }
  .title {
    font-weight: 700;
    display: block;

    a {
      vertical-align: middle;
    }

    &:after {
      display: inline-block;
      content: "";
      width: 14px;
      height: 14px;
      background: url(${imgExternal});
      background-size: cover;
      vertical-align: middle;
      margin: 0 0 0 5px;
    }
  }
  time {
    display: inline !important;
    font-size: 14px;
    margin: 0 0 0 5px;
  }
  .source {
    margin: 0 5px 0 0;
  }
  .bookmarked {
    time {
      &:after {
        display: inline-block;
        content: "";
        width: 10px;
        height: 12px;
        background: url(${imgBookmark});
        background-size: cover;
        margin: 0 0 0 5px;
        vertical-align: middle;
      }
    }
  }
`;

const LatestNews = () => {
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState(true);
  const [companies, setCompanies] = useState([]);
  const limitNews = 10;

  const parser = new Parser();

  const buildFeeds = async sources => {
    let build = [];
    let _newsItems = [];
    for (const n of sources) {
      try {
        let response = await parser.parseURL(
          "https://cors-anywhere.herokuapp.com/" + n.feedUrl
        );
        if (response) {
          build.push(response);
        }
        if (build.length) {
          build.forEach(b => {
            if (b.items.length) {
              b.items.forEach(item => {
                if (
                  !_newsItems.find(n => n.title === _unescape(item.title)) &&
                  companies.find(company =>
                    _unescape(item.title).includes(company)
                  )
                ) {
                  _newsItems.push({
                    link: item.link,
                    source: b.title,
                    title: _unescape(item.title),
                    isoDate: item.isoDate,
                  });
                }
              });
            }
          });
        }
        //sort by date
        //_orderBy(_newsItems, ['isoDate'], ['desc'])
        //set visible after first loaded item
        if (_newsItems.length) {
          setLoading(false);
          setNews(
            _orderBy(_newsItems, ["isoDate"], ["desc"]).splice(0, limitNews)
          );
        }
      } catch (e) {
        //
      }
    }
    //set finished loading regardless of news or not
    setLoading(false);
  };

  const getSources = async () => {
    const response = await NewsService.list();

    if (!response.error) {
      buildFeeds(response._embedded);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    const getCompanies = async () => {
      let response = await CompanyService.list({});
      if (!response.error) {
        setCompanies(response._embedded.map(c => c.name));
      } else {
        //
      }
    };
    getCompanies();
  }, []);

  //get sources once companies is set
  useEffect(() => {
    getSources();
  }, [companies]);
  // const news = [
  //   {
  //     title: "Bioholding Opens New Robitics Lab",
  //     source: "LinkedIn",
  //     url: "#",
  //     bookmarked: true,
  //     date: moment([2020, 4, 1]).fromNow(),
  //   },
  //   {
  //     title: "Codehow Fires CEO",
  //     url: "#",
  //     source: "EU-Startups",
  //     bookmarked: false,
  //     date: moment([2020, 1, 1]).fromNow(),
  //   },
  //   {
  //     title: "gBETA Medtech Program Returns",
  //     source: "MobiHealthNews",
  //     url: "#",
  //     bookmarked: false,
  //     date: moment([2020, 2, 14]).fromNow(),
  //   },
  // ];
  return (
    <StyledLatestNews>
      <h1>Latest News</h1>
      <div className="content">
        {loading ? <LoadingSubmit></LoadingSubmit> : ""}
        {!loading && news.length ? (
          <ul>
            {news.map((item, i) => {
              return (
                <li key={i} className={item.bookmarked ? "bookmarked" : ""}>
                  <span className="title">
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  </span>
                  <span className="source">{item.source}</span>&bull;
                  <time>{moment(item.isoDate).fromNow()}</time>
                </li>
              );
            })}
          </ul>
        ) : (
          <>{!loading ? "No matching articles were found" : ""}</>
        )}

        {/* <div className="actions">
          <Button size="small">View All News</Button>
        </div> */}
      </div>
    </StyledLatestNews>
  );
};
/**
 * Latest Bookmarks
 */
const StyledBookmarks = styled(Card)`
  li {
    padding: 11px 0 15px 0;
    height: 16px;

    border-bottom: 1px solid ${Colors.gray};
    display: flex;
    align-items: center;
    .delete {
      display: none;
    }
    &:hover {
      .delete {
        display: block;
      }
    }
    .spacer {
      flex: 1 1 auto;
    }
  }
  .title {
    font-weight: 700;
    display: block;

    &:after {
      display: inline-block;
      content: "";
      width: 14px;
      height: 14px;
      background: url(${imgExternal});
      background-size: cover;
      vertical-align: middle;
      margin: 0 0 0 5px;
    }
  }
  .actions {
    text-align: left !important;
  }
  #add-bookmark {
    margin-top: 20px;
    ${EditableInlineInput}
  }
`;

const Bookmarks = () => {
  const [bookmarks, setBookmarks] = useState([]);

  useEffect(() => {
    const getBookmarks = async () => {
      const response = await BookmarkService.list({});
      if (!response.error) {
        setBookmarks(response._embedded);
      }
    };
    getBookmarks();
  }, []);

  const addBookmark = async () => {
    const _url = document.getElementById("bookmarkAdd-url");
    const _name = document.getElementById("bookmarkAdd-name");

    if (_url.value.match(/https?:\/\//)) {
      const response = await BookmarkService.create({
        name: _name.value,
        url: _url.value,
      });

      if (!response.error) {
        setBookmarks([
          ...bookmarks,
          { url: _url.value, name: _name.value, id: response.id },
        ]);
        _url.value = "";
        _name.value = "";
      } else {
        toast.error("There was an error adding the bookmark", {
          position: "bottom-center",
        });
      }
    } else {
      toast.error("Please add a valid url", { position: "bottom-center" });
    }
  };

  const deleteBookmark = async item => {
    const response = await BookmarkService.del(item.id);

    if (!response.error) {
      setBookmarks(bookmarks.filter(i => i !== item));
    } else {
      toast.error("There was an error deleting the bookmark", {
        position: "bottom-center",
      });
    }
  };
  return (
    <StyledBookmarks>
      <h1>Bookmarks</h1>
      <div className="content">
        {bookmarks.length ? (
          <ul>
            {bookmarks.map((item, i) => (
              <li key={i}>
                <span className="title">
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.name || item.url}
                  </a>
                </span>
                <span className="spacer"></span>
                <ButtonClose
                  className="delete"
                  type="button"
                  onClick={() => deleteBookmark(item)}
                >
                  <img src={imgX} alt="Delete" title="Delete" />
                </ButtonClose>
              </li>
            ))}
          </ul>
        ) : (
          <p>No bookmarks saved</p>
        )}

        <div id="add-bookmark">
          <input placeholder="URL" id="bookmarkAdd-url" />
          <input placeholder="Title (optional)" id="bookmarkAdd-name" />
        </div>

        <div className="actions">
          <Button size="small" onClick={() => addBookmark()}>
            Add Bookmark
          </Button>
        </div>
      </div>
    </StyledBookmarks>
  );
};

/**
 * Kanban
 */
const StyledKanban = styled(Card)`
  margin-top: 44px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  .view {
    font-weight: 600;
    ul {
      display: grid;
      grid-template-columns: auto auto auto 1fr;
      align-items: center;
      margin-bottom: 10px;
      .dropdown ul {
        display: block;
      }
      .dropdown-urgency ul {
        li {
          display: flex;
          align-items: center;
          &:before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: #eee;
            border-radius: 10px;
            margin: 0 3px 0 0;
          }
          &.onHold {
            &:before {
              background-color: ${Colors.urgency_onhold};
            }
          }
          &.low {
            &:before {
              background-color: ${Colors.urgency_low};
            }
          }
          &.medium {
            &:before {
              background-color: ${Colors.urgency_medium};
            }
          }
          &.high {
            &:before {
              background-color: ${Colors.urgency_high};
            }
          }
        }
      }
      .org-filter,
      .urgency-filter {
        display: flex;
        align-items: center;
      }
      .urgency-filter {
        &:before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          background: #eee;
          border-radius: 10px;
          margin: 0 3px 0 0;
        }
        &.onhold {
          &:before {
            background-color: ${Colors.urgency_onhold};
          }
        }
        &.low {
          &:before {
            background-color: ${Colors.urgency_low};
          }
        }
        &.medium {
          &:before {
            background-color: ${Colors.urgency_medium};
          }
        }
        &.high {
          &:before {
            background-color: ${Colors.urgency_high};
          }
        }
      }
    }
    li {
      &.icon {
        margin: 0 0 0 15px;
        overflow: hidden;
        width: 20px;
        height: 20px;
        text-indent: -6000px;
        background-size: cover;
        &.disabled {
          visibility: hidden;
        }
      }
      &.view-columns {
        background-image: url(${imgViewBoard});
      }
      &.view-table {
        background-image: url(${imgViewTable});
      }
      &.active {
        background-position: 0 -60px;
      }
      &.candidate-view {
        margin: 0 0 0 60px;
      }
      &.export {
        text-align: right;
      }
    }
  }
  & > .header {
    display: flex;
    background: #8792a3;
    color: #fff;
    align-items: center;
    height: 40px;
    padding: 0 0 0 15px;
    h2 {
      margin-right: 30px;
      a {
        color: #fff;
      }
    }

    dl {
      display: flex;
      dt {
        margin: 0 5px 0 30px;
        &:after {
          content: ":";
          display: inline;
        }
      }
      dd {
        margin: 0 30px 0 0;
      }
    }
  }
  .columns {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    .column {
      position: relative;
      margin: 0 13.5px;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      min-height: 164px;
      min-width: 50px;

      .header {
        display: flex;
        h2 {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 0.857rem;
          .count {
            margin-left: 5px;
          }
        }
        .contract {
          border: 1px solid ${Colors.primary};
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 21px;
          height: 14px;
          :before {
            content: "";
            display: block;
            width: 7px;
            height: 4.8px;
            transform: rotate(-90deg);
            background: url(${imgChevron});
            background-size: cover;
            background-position: 0 0;
            margin: 1px 2.5px 0 3.5px;
          }
        }
      }
      .board {
        margin-top: 11px;
        padding: 5px;
        background: ${Colors.gray_accent_opaque};
        border-radius: 3px;
        height: calc(100% - 26px);
        overflow-y: auto;
      }

      li {
        .name {
          font-weight: 600;
          color: ${Colors.primary};
          margin-bottom: 5px;
          white-space: nowrap;
        }
        background: #fff;
        padding: 10px;
        color: #8792a3;
        margin-bottom: 5px;
        height: 89px;
        box-sizing: border-box;
      }

      &.collapsed {
        width: 58px;
        flex-grow: 0;
        h2 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%) rotate(90deg);
        }
        .contract {
          margin: 0 29px 0 21px;
          &:before {
            transform: rotate(90deg);
          }
        }
        ul {
          display: none;
        }
      }
    }
  }
  @media ${device.tabletLMax} {
    display: none;
  }

  #possible-candidates {
    h2 {
      font-size: 12px;
      font-weight: 600;
      margin: 15px 0 15px 15px;
    }
    .pagination {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .col-1 {
        justify-content: start;
      }
      .col-2 {
        justify-content: flex-end;
      }
    }
  }
`;
const Columns = styled.div`
  margin-top: 16px;
  margin-bottom: 31px;
  display: flex;
  position: relative;
  min-height: 300px;

  /* @media ${device.laptopLMax} {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto auto auto auto;
  } */
  &.modal:after {
    content: "";
    background: #000;
    opacity: 0.8;
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .column {
    position: relative;
    margin: 0 13.5px;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 164px;
    min-width: 50px;

    .header {
      display: flex;
      h2 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.857rem;
        white-space: nowrap;
        .count {
          margin-left: 5px;
        }
      }
      .contract {
        border: 1px solid ${Colors.primary};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 21px;
        height: 14px;
        :before {
          content: "";
          display: block;
          width: 7px;
          height: 4.8px;
          transform: rotate(-90deg);
          background: url(${imgChevron});
          background-size: cover;
          background-position: 0 0;
          margin: 1px 2.5px 0 3.5px;
        }
      }
    }
    .board {
      margin-top: 11px;
      padding: 5px;
      background: ${Colors.gray_accent_opaque};
      border-radius: 3px;
      height: calc(100% - 26px);
      overflow-y: auto;
      height: 400px;

      .handle {
        cursor: pointer;
      }
    }
    @media ${device.laptopLMax} {
      .board {
        height: 300px;
        margin-bottom: 11px;
      }
    }

    li {
      position: relative;
      .handle {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
      }
      &.urgency-high {
        &:before {
          background: ${Colors.urgency_high};
        }
      }
      &.urgency-medium {
        &:before {
          background: ${Colors.urgency_medium};
        }
      }
      &.urgency-low {
        &:before {
          background: ${Colors.urgency_low};
        }
      }
      &.urgency-hold {
        &:before {
          background: ${Colors.urgency_onhold};
        }
      }
      .name {
        font-weight: 600;
        color: ${Colors.primary};
        margin-bottom: 5px;
        white-space: nowrap;
      }
  .org-abbr {
    position: absolute;
    top: 0;
    right: 0;
    background: #eee;
    padding: 5px;
    border-bottom-left-radius: 3px;
  }
      user-select: "none";
      background: #fff;
      padding: 10px;
      color: #8792a3;
      margin-bottom: 5px;
      min-height: 89px;
      box-sizing: border-box;
      .days {
        position: absolute;
        top: 5px;
        right: 5px;
        border: 4px solid ${Colors.highlight};
        display: block;
        width: 21px;
        height: 21px;
        line-height: 21px;
        font-weight: 600;
        text-align: center;
        border-radius: 21px;
        font-size: 0.9rem;
        background: #fff;
      }
      .create-interview {
        font-weight: 600;
        cursor: pointer;
      }
      .interview-date {
        white-space: nowrap;
      }
    }

    &.collapsed {
      width: 58px;
      flex-grow: 0;
      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
      }
      .contract {
        margin: 0 29px 0 21px;
        &:before {
          transform: rotate(90deg);
        }
      }
      ul {
        display: none;
      }
    }
  }
`;
const Kanban = () => {
  const location = useLocation();

  const [columns, setColumns] = useState([]);
  const [events, setEvents] = useState([]);
  const [formInvoiceOpen, setFormInvoiceOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState();
  const [kanbanResult, setKanbanResult] = useState();

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removedEvent] = sourceClone.splice(droppableSource.index, 1);

    //update event in database... toBeInvoiced|engagementFee has their own update processes
    if (!droppableDestination.droppableId.match(/toBeInvoiced|placementFee/)) {
      EventService.update({
        id: removedEvent.bdEventId,
        data: { stage: droppableDestination.droppableId },
      });
    }

    destClone.splice(droppableDestination.index, 0, removedEvent);

    return [sourceClone, destClone];
  };

  const setCollapsedColumn = column => {
    column.collapsed = !column.collapsed;
    const storedColumns = localStorage.getItem(
      "bizdevDashboardKanbanCollapsedColumns"
    );
    const collapsedColumns = storedColumns ? JSON.parse(storedColumns) : [];

    if (column.collapsed) {
      collapsedColumns.push(column.stage);
    } else {
      collapsedColumns.splice(collapsedColumns.indexOf(column.stage), 1);
    }
    localStorage.setItem(
      "bizdevDashboardKanbanCollapsedColumns",
      JSON.stringify(collapsedColumns)
    );
    setColumns([...columns]);
  };

  const prepareKanbanMove = result => {
    const { source, destination } = result;

    //set source
    let sourceColumn = columns.find(c => c.stage === source.droppableId);
    let destinationColumn = columns.find(
      c => c.stage === destination.droppableId
    );

    return {
      source: source,
      sourceColumn: sourceColumn,
      destination,
      destinationColumn,
    };
  };

  const invoiceFormCompleted = () => {
    const result = prepareKanbanMove(kanbanResult);

    // if moving to a new column
    const moveResult = move(
      result.sourceColumn.events,
      result.destinationColumn.events,
      result.source,
      result.destination
    );

    //update new event lists and set new state
    result.sourceColumn.events = moveResult[0];
    result.destinationColumn.events = moveResult[1];

    //merge into stages
    setColumns([...columns]);
  };

  const onDragEnd = resultRaw => {
    //const { source, destination } = result;
    const result = prepareKanbanMove(resultRaw);

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === result.destination.droppableId) {
      //no change in order
      if (result.source.index === result.destination.index) {
        return;
      }
      // if moving with a column
      const reorderResult = reorder(
        result.sourceColumn.events,
        result.source.index,
        result.destination.index
      );

      result.sourceColumn.events = reorderResult;
      //merge into stages
      setColumns([...columns]);
    } else {
      if (
        result.destinationColumn.stage === "placementFee" ||
        result.destinationColumn.stage === "toBeInvoiced"
      ) {
        const sourceClone = Array.from(result.sourceColumn.events);
        const [removedEvent] = sourceClone.splice(result.source.index, 1);

        removedEvent.nextStage = result.destinationColumn.stage;
        setCurrentEvent(removedEvent);
        setKanbanResult(result);
        setFormInvoiceOpen(true);
      } else {
        // if moving to a new column
        const moveResult = move(
          result.sourceColumn.events,
          result.destinationColumn.events,
          result.source,
          result.destination
        );

        //update new event lists and set new state
        result.sourceColumn.events = moveResult[0];
        result.destinationColumn.events = moveResult[1];

        //merge into stages
        setColumns([...columns]);
      }
    }
  };

  const orgAbbreviation = org => {
    let abbr = "";
    switch (org) {
      case "Dragonfly Stories":
      case "Dragonfly":
        abbr = "DF";
        break;
      case "TMG360":
        abbr = "TMG360";
        break;
      case "Interim":
        abbr = "INT";
        break;
      case "Permanent":
        abbr = "PERM";
        break;
    }
    return abbr;
  };

  useEffect(() => {
    const getEvents = async () => {
      let _items = await EventService.list({
        filter: {
          search: `userId:me AND ( ( NOT stage:completed ) OR ( stage:completed AND updatedAt:[${new Date(
            new Date() - 30 * 24 * 3600000
          ).toISOString()} TO inf] ) )`,
        },
      });

      if (!_items.error) {
        const _events = _items._embedded.map(e => {
          e.stage = e.stage || "bd";
          //this should be only temporarly needed
          e.activities = e.activities || {
            activityId: e.activityId || 1,
            org: e.org || "Dragonfly",
            urgency: e.urgency || "high",
            abbr: orgAbbreviation(e.org || "Dragonfly"),
          };
          e.colorMenuOpen = false;
          return e;
        });

        setEvents(_events);
      } else {
        toast.error(_items.error);
      }
    };
    getEvents();

    //let _columns = ;
    //setColumns(_columns);
  }, []);

  const [urgencySelected, setUrgencySelected] = useState(
    JSON.parse(localStorage.getItem(location.pathname + "?filters") ?? "null")
      ?.urgencySelected ?? {
      label: "Any",
      value: "",
    }
  );
  const urgencies = [
    { label: "Any", value: "" },
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
    { label: "On Hold", value: "onHold" },
  ];
  const filterUrgency = urgency => {
    setUrgencySelected(urgency);
  };
  const [orgSelected, setOrgSelected] = useState(
    JSON.parse(localStorage.getItem(location.pathname + "?filters") ?? "null")
      ?.orgSelected ?? {
      label: "Any",
      value: "",
    }
  );
  const organizations = [
    { label: "Any", value: "" },
    {
      label: "Dragonfly Stories",
      value: "Dragonfly Stories",
    },
    { label: "Interim", value: "Interim" },
    { label: "Permanent", value: "Permanent" },
    { label: "TMG360", value: "TMG360" },
  ];
  const filterOrganization = org => {
    setOrgSelected(org);
  };

  const commonFilter = e =>
    (!orgSelected.value || e.org === orgSelected.value) &&
    (!urgencySelected.value || e.urgency === urgencySelected.value);

  // Save filter state to storage when changed
  useEffect(() => {
    localStorage.setItem(
      location.pathname + "?filters",
      JSON.stringify({
        orgSelected,
        urgencySelected,
      })
    );

    // Update display when filtered list changes.
    //check collapsed columns from storage
    const storedColumns = localStorage.getItem(
      "bizdevDashboardKanbanCollapsedColumns"
    );
    const collapsedColumns = storedColumns ? JSON.parse(storedColumns) : [];

    setColumns([
      {
        title: "BD",
        stage: "bd",
        events: events.filter(e => e.stage === "bd" && commonFilter(e)),
        collapsed: collapsedColumns.includes("bd"),
      },
      {
        title: "Tap Sent",
        stage: "tapSent",
        events: events.filter(e => e.stage === "tapSent" && commonFilter(e)),
        collapsed: collapsedColumns.includes("tapSent"),
      },
      {
        title: "TAP Signed",
        stage: "tapSigned",
        events: events.filter(e => e.stage === "tapSigned" && commonFilter(e)),
        collapsed: collapsedColumns.includes("tapSigned"),
      },
      {
        title: "Engagement Fee",
        stage: "placementFee",
        events: events.filter(
          e => e.stage === "placementFee" && commonFilter(e)
        ),
        collapsed: collapsedColumns.includes("placementFee"),
      },
      {
        title: "Work in Process",
        stage: "workInProcess",
        events: events.filter(
          e => e.stage === "workInProcess" && commonFilter(e)
        ),
        collapsed: collapsedColumns.includes("workInProcess"),
      },
      {
        title: "To Be Invoiced",
        stage: "toBeInvoiced",
        events: events.filter(
          e => e.stage === "toBeInvoiced" && commonFilter(e)
        ),
        collapsed: collapsedColumns.includes("toBeInvoiced"),
      },
      {
        title: "Completed",
        stage: "completed",
        events: events.filter(e => e.stage === "completed" && commonFilter(e)),
        collapsed: collapsedColumns.includes("completed"),
      },
    ]);
  }, [orgSelected, urgencySelected, events]);

  return (
    <>
      <StyledKanban>
        <h1></h1> {/* removing broke CSS */}
        <div className="view">
          <ul>
            {/* <li className="icon view-columns active">Columns</li>
          <li className="icon view-table">Table</li> */}
            <li className="filter candidate-view">
              <ButtonLink to="/bd/event/new">Create BD Event</ButtonLink>
            </li>
            <li>
              <Dropdown className="dropdown dropdown-urgency">
                <ButtonDropdown
                  className={
                    "urgency-filter " +
                    urgencies.find(u => u.label === urgencySelected.label).value
                  }
                >
                  <span>Urgency: {urgencySelected.value}</span>
                </ButtonDropdown>
                <div className="list">
                  <ul>
                    {urgencies.map((urgency, i) => {
                      return (
                        urgency.label !== urgencySelected.label && (
                          <li
                            className={urgency.value}
                            key={i}
                            onClick={() => filterUrgency(urgency)}
                          >
                            {urgency.label}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              </Dropdown>
            </li>
            <li>
              <Dropdown className="dropdown dropdown-org">
                <ButtonDropdown className={"org-filter"}>
                  <span>Organization: {orgSelected.value}</span>
                </ButtonDropdown>
                <div className="list">
                  <ul>
                    {organizations.map((org, i) => {
                      return (
                        org.label !== orgSelected.label && (
                          <li
                            className={org.value}
                            key={i}
                            onClick={() => filterOrganization(org)}
                          >
                            {org.label}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              </Dropdown>
            </li>
            {/* <li className="export">
            <span>
              <ButtonDropdown>Export</ButtonDropdown>
            </span>
          </li> */}
          </ul>
        </div>
        {columns.length ? (
          <Columns>
            <DragDropContext onDragEnd={onDragEnd}>
              <>
                {columns.map((col, i) => {
                  return (
                    <div
                      className={"column" + (col.collapsed ? " collapsed" : "")}
                      key={i}
                    >
                      <div className="header">
                        <h2>
                          {col.title}{" "}
                          <span className="count">({col.events.length})</span>
                        </h2>

                        <span
                          className="contract"
                          onClick={() => setCollapsedColumn(col)}
                        ></span>
                      </div>
                      <div className="board">
                        <Board
                          key={i}
                          droppableId={col.stage}
                          events={col.events}
                          columns={columns}
                          setColumns={setColumns}
                          filterUrgency={filterUrgency}
                          urgencySelected={urgencySelected}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            </DragDropContext>
          </Columns>
        ) : (
          ""
        )}
      </StyledKanban>
      {formInvoiceOpen ? (
        <FormInvoiceInfo
          currentEvent={currentEvent}
          setFormInvoiceOpen={setFormInvoiceOpen}
          callback={invoiceFormCompleted}
          // kanbanResult={kanbanResult}
          // prepareKanbanMove={prepareKanbanMove}
          // move={move}
        />
      ) : (
        ""
      )}
    </>
  );
};

const StyledSwatches = styled(Card)`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 10px;
  background: #fff;
  border-radius: 3px;
  padding: 40px 20px 20px;
  cursor: pointer;
  ul {
    overflow: hidden;
    li {
      float: left;
      min-height: auto !important;
      &:nth-child(n + 7) {
        margin: 10px 0 0;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li {
        width: 60px;
        height: 35px;
        border-radius: 2px;
        cursor: pointer;
        margin: 0 3px 2px;
      }
    }
  }
  .urgency {
    padding: 10px 44px 10px 10px;
    margin: 0 0 0;
    li {
      width: 82px;
      height: 35px;
      border-radius: 2px;
      cursor: pointer;
      margin: 0 3px 9px;
      text-align: center;
      box-sizing: border-box;
      color: #000;
      float: none;
    }
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: ${Colors.primary};
    width: 16px;
    height: 16px;
    overflow: hidden;
  }
`;

/*
 * Boards
 */
const Board = ({
  events,
  columns,
  setColumns,
  droppableId,
  urgencySelected,
  filterUrgency,
}) => {
  const getListStyle = (/*isDraggingOver*/) => ({
    /*background: isDraggingOver ? "lightblue" : "lightgrey",
        width: 250*/
    height: "100%",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    backgroundColor: isDragging ? Colors.highlight : "",
    ...draggableStyle,
  });

  const setMenuOpen = (e, event) => {
    e.stopPropagation();

    event.colorMenuOpen = !event.colorMenuOpen;
    setColumns([...columns]);
  };

  const setUrgency = async (event, urgency) => {
    //update swatch
    let response;
    if (!event.activityId) {
      //fill in defaults if doesn't exist
      const data = {
        org: "Dragonfly Stories",
        urgency: urgency,
        activityType: "Dragonfly",
      };
      response = await ActivitiesService.create({
        ...data,
        eventId: event.bdEventId,
      });

      if (response.id) {
        event.activityId = response.id;
        event.org = data.org;
        event.urgency = data.urgency;
      } else {
        toast.error(response.error);
      }
    } else {
      response = await ActivitiesService.update({
        id: event.activities?.activityId,
        data: { urgency: urgency },
      });
    }

    if (!response.error) {
      event.activities.urgency = urgency;
      event.urgency = urgency;
      setColumns([...columns]);
      if (urgencySelected.value) {
        setTimeout(() => {
          filterUrgency(urgencySelected);
        }, 100);
      }
    } else {
      toast.error(response.error, { position: "bottom-center" });
    }
  };

  const getUrgencyColor = urgency => {
    switch (urgency) {
      case "onHold":
        return Colors.urgency_onhold;
      case "low":
        return Colors.urgency_low;
      case "medium":
        return Colors.urgency_medium;
      case "high":
        return Colors.urgency_high;
    }
  };

  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          <ul
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {events.map((event, index) => {
              return (
                <Draggable
                  key={event.bdEventId}
                  draggableId={event.bdEventId}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      data-date={moment(event.createdAt).format("MMM/DD")}
                    >
                      <span
                        className="handle"
                        style={{
                          backgroundColor: getUrgencyColor(event.urgency),
                        }}
                        onClick={e => setMenuOpen(e, event)}
                      ></span>
                      {event.colorMenuOpen ? (
                        <StyledSwatches>
                          <span
                            className="close"
                            onClick={e => setMenuOpen(e, event)}
                          >
                            <img src={imgCircleX} title="Cancel" alt="Cancel" />
                          </span>
                          <ul className="urgency">
                            <li
                              style={{ backgroundColor: Colors.urgency_low }}
                              onClick={() => setUrgency(event, "low")}
                            >
                              low
                            </li>
                            <li
                              style={{ backgroundColor: Colors.urgency_medium }}
                              onClick={() => setUrgency(event, "medium")}
                            >
                              medium
                            </li>
                            <li
                              style={{ backgroundColor: Colors.urgency_high }}
                              onClick={() => setUrgency(event, "high")}
                            >
                              high
                            </li>
                            <li
                              style={{ backgroundColor: Colors.urgency_onhold }}
                              onClick={() => setUrgency(event, "onHold")}
                            >
                              on hold
                            </li>
                          </ul>
                          {/* <ul>
                            eventColors.map((colorGroup, i) => (
                              <li key={i}>
                                <ul>
                                  {colorGroup.map((color, j) => (
                                    <li
                                      key={j}
                                      style={{ backgroundColor: color }}
                                      onClick={() => setColor(event, color)}
                                    ></li>
                                  ))}
                                </ul>
                              </li>
                                  ))
                          </ul>*/}
                        </StyledSwatches>
                      ) : (
                        ""
                      )}
                      <span className="company">
                        <Link
                          target="_blank"
                          to={"/bd/event/" + event.bdEventId}
                        >
                          {event.companyName}
                        </Link>
                      </span>
                      <br />
                      {event.position ? (
                        <>
                          <span className="position">{event.position}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {/* <span className="name">
                        <a href="#">
                          {event.contactFirstName} {event.contactLastName}
                        </a>
                      </span> */}
                      <span className="org-abbr" title="">
                        {event.activities ? event.activities.abbr : ""}
                      </span>
                    </li>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        </div>
      )}
    </Droppable>
  );
};
Board.propTypes = {
  events: PropTypes.array,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
  droppableId: PropTypes.string,
  urgencySelected: PropTypes.object,
  filterUrgency: PropTypes.func,
};

// const StyledFormInvoiceInfo = styled.div`
//   .bg {
//     display: block;
//     content: "";
//     background: #000000c7;
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     z-index: 1000;
//     left: 0;
//     top: 0;
//   }
//   .display {
//     background: #fff;
//     width: 900px;
//     height: 650px;
//     overflow-x: auto;
//     padding: 20px 30px;
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 1001;
//     p {
//       margin-bottom: 5px;
//     }
//     em {
//       font-style: italic;
//       font-weight: 500;
//     }
//   }
//   .cancel {
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     width: 30px;
//     cursor: pointer;
//   }
//   .input-confirm {
//     display: flex;
//     button {
//       margin-left: 15px;
//     }
//   }
//   form li.actions {
//     margin-top: 10px;
//     fieldset {
//       margin-top: 0;
//     }
//   }
// `;

// const FormInvoiceInfo = ({
//   currentEvent,
//   kanbanResult,
//   prepareKanbanMove,
//   move,
//   setFormInvoiceOpen,
// }) => {
//   const titles = [
//     { label: "Buyer Planner", value: "Buyer Planner" },
//     {
//       label: "Design Engineer, Electrical",
//       value: "Design Engineer, Electrical",
//     },
//     {
//       label: "Design Engineer, Mechanical",
//       value: "Design Engineer, Mechanical",
//     },
//     { label: "Design Engineer, Software", value: "Design Engineer, Software" },
//     {
//       label: "Design Engineer, Machine Development",
//       value: "Design Engineer, Machine Development",
//     },
//     {
//       label: "Clinical Research Associate",
//       value: "Clinical Research Associate",
//     },
//     { label: "Regulatory Affairs", value: "Regulatory Affairs" },
//     { label: "Regulatory Associate", value: "Regulatory Associate" },
//     { label: "Sales", value: "Sales" },
//     { label: "Marketing", value: "Marketing" },
//     {
//       label: "Marketing, Product Manager",
//       value: "Marketing, Product Manager",
//     },
//     { label: "Marketing, Communications", value: "Marketing, Communications" },
//     { label: "Manufacturing Engineer", value: "Manufacturing Engineer" },
//     {
//       label: "Manufacturing Engineer Process",
//       value: "Manufacturing Engineer Process",
//     },
//     {
//       label: "Manufacturing Engineer Validation",
//       value: "Manufacturing Engineer Validation",
//     },
//     {
//       label: "Manufacturing Engineer Operrations",
//       value: "Manufacturing Engineer Operrations",
//     },
//     {
//       label: "Manufacturing Engineer Automation",
//       value: "Manufacturing Engineer Automation",
//     },
//     {
//       label: "Manufacturing, Materials Engineer",
//       value: "Manufacturing, Materials Engineer",
//     },
//     { label: "Quality Engineer", value: "Quality Engineer" },
//     { label: "Field Service", value: "Field Service" },
//     { label: "Scientist", value: "Scientist" },
//     { label: "Scientist, Polymers", value: "Scientist, Polymers" },
//     { label: "Scientist, Chemicals", value: "Scientist, Chemicals" },
//     { label: "Other", value: "Other" },
//   ];

//   const levels = [
//     { label: "Individual", value: "Individual" },
//     { label: "Manager", value: "Manager" },
//     { label: "Director Vice President", value: "Director Vice President" },
//     { label: "President", value: "President" },
//   ];

//   const [loading, setLoading] = useState(false);

//   const [fieldsRequired, setFieldsRequired] = useState({
//     positionId: false,
//     companyId: false,
//     bdContactId: false,
//     hiringManagerId: false,
//   });
//   //get position
//   const [hiringManagers, setHiringManagers] = useState([]);
//   const getHiringManagers = async () => {
//     let response = await CandidateService.list({ limit: 10000 });

//     if (!response.error) {
//       setHiringManagers(
//         response._embedded.map(r => {
//           return {
//             label: r.firstName + " " + r.lastName,
//             value: r.candidateId,
//           };
//         })
//       );
//     } else {
//       toast.error(response.error);
//     }
//   };
//   const getPosition = async () => {
//     if (currentEvent.positionId) {
//       let response = await PositionService.get(currentEvent.positionId);

//       if (response && response.hiringManagers) {
//         const _hm = response.hiringManagers[0];
//         selectValues.hiringManagerId = {
//           label: _hm.firstName + " " + _hm.lastName,
//           value: _hm.candidateId,
//         };
//       } else {
//         setFieldsRequired({ ...fieldsRequired, hiringManagerId: true });
//       }
//     } else {
//       setFieldsRequired({ ...fieldsRequired, positionId: true });
//       setFieldsRequired({ ...fieldsRequired, hiringManagerId: true });
//     }
//   };

//   const iterableInputs = {
//     splits: {
//       name: "splits",
//       fields: [
//         { name: "name", type: "text" },
//         { name: "percentage", type: "number", defaultValue: "100" },
//       ],
//     },
//   };
//   const [splits, setSplits] = useState([resetItem(iterableInputs, "splits")]);

//   /*
//    * Button to add new contact
//    */
//   const fieldsetActionsSplits = () => {
//     return (
//       <Button
//         type="button"
//         fieldsetAction
//         size="small"
//         style={{ marginBottom: "14px" }}
//         onClick={() => addItem(splits, setSplits, iterableInputs, "splits")}
//       >
//         Add Another Split
//       </Button>
//     );
//   };

//   //get contacts
//   const [contacts, setContacts] = useState([]);
//   const getContacts = async () => {
//     let response = await ContactService.list({
//       limit: 10000,
//       offset: 0,
//     });
//     if (!response.error) {
//       setContacts(
//         response._embedded.map(r => {
//           if (r.candidateId === currentEvent.candidateId) {
//             selectValues.candidateId = {
//               label: r.firstName + " " + r.lastName,
//               value: r.candidateId,
//             };
//           }
//           return {
//             label: r.firstName + " " + r.lastName,
//             value: r.candidateId,
//           };
//         })
//       );
//       if (!selectValues.candidateId) {
//         setFieldsRequired({ ...fieldsRequired, bdContactId: true });
//       }
//     } else {
//       toast.error(response.error);
//     }
//   };
//   //get positions
//   const [positions, setPositions] = useState([]);
//   const getPositions = async () => {
//     let response = await PositionService.list({
//       limit: 10000,
//       offset: 0,
//     });

//     if (!response.error) {
//       setPositions(
//         response._embedded.map(r => {
//           return {
//             label: r.title,
//             value: r.id,
//           };
//         })
//       );
//     } else {
//       toast.error(response.error);
//     }
//   };

//   useEffect(() => {
//     //getEvent(); //dont need it
//     //getCandidates(); //dont need it
//     getPosition(); //need list of hiring managers
//     getPositions();
//     getContacts();
//     getHiringManagers();
//   }, []);

//   const initSelectValues = {
//     stage: { label: "Engagement", value: "toBeInvoiced" },
//     title: titles[0],
//     level: levels[0],
//     split: { label: "No", value: "No" },
//     hiringManagerId: null,
//     invoicedEmployeeId: { label: "", value: "" },
//     positionId: currentEvent.positionId
//       ? {
//           label: currentEvent.position,
//           value: currentEvent.positionId,
//         }
//       : null,
//     companyId: currentEvent.companyId
//       ? {
//           label: currentEvent.companyName,
//           value: currentEvent.companyId,
//         }
//       : null,
//     candidateId: currentEvent.candidateId
//       ? {
//           label:
//             currentEvent.contactFirstName + " " + currentEvent.contactLastName,
//           value: currentEvent.candidateId,
//         }
//       : null,
//   };
//   const [selectValues, setSelectValues] = useState(initSelectValues);

//   const { register, handleSubmit, errors, setValue } = useForm({
//     defaultValues: {
//       candidate: {
//         metrics: {
//           level: selectValues.level.value,
//           title: selectValues.title.value,
//         },
//       },
//       split: selectValues.split.value,
//     },
//   });

//   const onSubmit = async data => {
//     setLoading(true);

//     //splits
//     data.split = [];
//     splits.forEach(split => {
//       if (split.name.trim()) {
//         data.split.push({
//           name: split.name,
//           percentage: Number(split.percentage) / 100,
//         });
//       }
//     });

//     //adding/updating
//     data.companyId =
//       selectValues.companyId?.value &&
//       selectValues.companyId.value !== currentEvent.companyId
//         ? selectValues.companyId?.value
//         : undefined;
//     data.positionId =
//       selectValues.positionId?.value &&
//       selectValues.positionId.value !== currentEvent.positionId
//         ? selectValues.positionId?.value
//         : undefined;
//     data.hiringManagerId =
//       selectValues.hiringManagerId?.value &&
//       selectValues.hiringManagerId.value !== currentEvent.companyId
//         ? selectValues.hiringManagerId?.value
//         : undefined;
//     if (
//       selectValues.candidateId &&
//       selectValues.candidateId.value !== currentEvent.candidateId
//     ) {
//       const _contact = contacts.find(
//         c => c.value === selectValues.candidateId.value
//       );
//       data.bdContactId = _contact.bdContactId;
//     }

//     //check required items
//     let error = false;
//     if (!data.hiringManagerId && fieldsRequired.hiringManagerId) {
//       toast.error("Hiring Manager is required");
//       error = true;
//     }
//     if (!data.positionId && fieldsRequired.positionId) {
//       toast.error("Position is required");
//       error = true;
//     }
//     if (!data.bdContactId && fieldsRequired.bdContactId) {
//       toast.error("Contact is required");
//       error = true;
//     }

//     //send
//     if (!error) {
//       const response = await EventService.update({
//         id: currentEvent.bdEventId,
//         data: {
//           ...data,
//           stage: currentEvent.nextStage,
//           split: data.split.length ? data.split : undefined,
//           payouts:
//             currentEvent.nextStage === "toBeInvoiced"
//               ? data.payouts
//               : undefined,
//           startDate:
//             currentEvent.nextStage === "toBeInvoiced"
//               ? data.startDate
//               : undefined,
//           candidateName:
//             currentEvent.nextStage === "toBeInvoiced"
//               ? data.candidateName
//               : undefined,
//         },
//       });

//       if (response.error) {
//         //set new stage for user
//         const result = prepareKanbanMove(kanbanResult);

//         // if moving to a new column
//         const moveResult = move(
//           result.sourceColumn.events,
//           result.destinationColumn.events,
//           result.source,
//           result.destination
//         );

//         //update new event lists and set new state
//         result.sourceColumn.events = moveResult[0];
//         result.destinationColumn.events = moveResult[1];

//         //close form
//         setFormInvoiceOpen(false);
//         //set success response
//         toast.success("Invoice has been sent");
//       } else {
//         toast.error(response.error);
//       }
//     }
//     setLoading(false);
//   };

//   const onSelectChange = (opt, actionMeta) => {
//     let name = actionMeta.name;
//     let value;

//     //dropdown
//     if (!opt) {
//       //empty data
//       value = null;
//     } else if (opt && opt.value) {
//       value = opt.value;
//     } else {
//       //multiselect
//       value = opt;
//     }
//     selectValues[name.replace(/(.*)\[(.*)\]$/g, "$2")] = opt;
//     setValue(name, value); //for react-hook
//     setSelectValues({ ...selectValues });
//   };

//   const cancelStageMove = () => {
//     setFormInvoiceOpen(false);
//   };

//   return (
//     <StyledFormInvoiceInfo>
//       <div className="bg"></div>
//       <div className="display">
//         <h1>Invoice</h1>
//         <img
//           className="cancel"
//           onClick={cancelStageMove}
//           src={imgCircleX}
//           title="Cancel"
//           alt="Cancel"
//         />
//         <StyledForm>
//           <form onSubmit={handleSubmit(onSubmit)}>
//             <>
//               <ol>
//                 <Form.Fieldset
//                   legend="Split"
//                   className="rows stacked"
//                   fieldsetActions={fieldsetActionsSplits()}
//                 >
//                   {splits.length &&
//                     splits.map((item, index) => (
//                       <li className="row group" key={index}>
//                         <ol>
//                           <Form.InputWrapper
//                             label="Split with Who"
//                             name="split[name]"
//                             errors={errors}
//                           >
//                             <Form.TextInput
//                               name="split[name]"
//                               type="text"
//                               forwardRef={register}
//                               controlledValue={item.name}
//                               onChange={e =>
//                                 handleIteratorChange(
//                                   e,
//                                   index,
//                                   splits,
//                                   setSplits
//                                 )
//                               }
//                             />
//                           </Form.InputWrapper>
//                           <Form.InputWrapper
//                             label="Split Percentage"
//                             name="split[percentage]"
//                             errors={errors}
//                           >
//                             <Form.TextInput
//                               name="split[percentage]"
//                               type="number"
//                               min="0"
//                               max="100"
//                               step=".01"
//                               placeholder=""
//                               forwardRef={register}
//                               controlledValue={item.percentage}
//                               onChange={e =>
//                                 handleIteratorChange(
//                                   e,
//                                   index,
//                                   splits,
//                                   setSplits
//                                 )
//                               }
//                             />
//                           </Form.InputWrapper>
//                         </ol>
//                         {index ? (
//                           <ButtonClose
//                             type="button"
//                             style={{ marginTop: 30 }}
//                             onClick={() =>
//                               deleteItem(
//                                 index,
//                                 splits,
//                                 setSplits,
//                                 iterableInputs,
//                                 "splits"
//                               )
//                             }
//                           >
//                             <img src={imgX} alt="Delete" title="Delete" />
//                           </ButtonClose>
//                         ) : (
//                           ""
//                         )}
//                       </li>
//                     ))}
//                 </Form.Fieldset>

//                 <Form.Fieldset legend="Invoice To" className="stacked rows">
//                   <Form.InputWrapper
//                     label="Employee Name"
//                     name="toInvoice[name]"
//                     errors={errors}
//                     required={true}
//                   >
//                     <Form.TextInput
//                       name="toInvoice[name]"
//                       type="text"
//                       forwardRef={register({ required: true })}
//                       value={""}
//                     />
//                   </Form.InputWrapper>
//                   <Form.InputWrapper
//                     label="Employee Email"
//                     name="toInvoice[emailAddress]"
//                     errors={errors}
//                     required={true}
//                   >
//                     <Form.TextInput
//                       name="toInvoice[emailAddress]"
//                       type="email"
//                       value={""}
//                       forwardRef={register({ required: true })}
//                     />
//                   </Form.InputWrapper>
//                   <Form.InputWrapper label="Fee" name="fee" errors={errors}>
//                     <Form.TextInput
//                       name="fee"
//                       type="text"
//                       forwardRef={register({ required: true })}
//                     />
//                   </Form.InputWrapper>
//                 </Form.Fieldset>

//                 <Form.Fieldset legend="Candidate" className="rows stacked">
//                   <Form.InputWrapper
//                     label="Contact"
//                     name="candidateId"
//                     errors={errors}
//                     required={true}
//                     className="react-select position-title"
//                   >
//                     <Select
//                       isClearable={true}
//                       className="select"
//                       ref={register({ name: "candidateId" })}
//                       options={contacts}
//                       placeholder=""
//                       name="candidateId"
//                       value={selectValues.candidateId}
//                       onChange={onSelectChange}
//                     />
//                   </Form.InputWrapper>

//                   <Form.InputWrapper
//                     label="Company"
//                     name="companyId"
//                     errors={errors}
//                     required={true}
//                     className="react-select position-title"
//                   >
//                     <Select
//                       isClearable={true}
//                       className="select"
//                       ref={register({ name: "companyId" })}
//                       options={positions}
//                       placeholder=""
//                       name="companyId"
//                       value={selectValues.companyId}
//                       onChange={onSelectChange}
//                     />
//                   </Form.InputWrapper>

//                   <Form.InputWrapper
//                     label="Position"
//                     name="positionId"
//                     errors={errors}
//                     required={true}
//                     className="react-select position-title"
//                   >
//                     <Select
//                       isClearable={true}
//                       className="select"
//                       ref={register({ name: "positionId" })}
//                       options={positions}
//                       placeholder=""
//                       name="positionId"
//                       value={selectValues.positionId}
//                       onChange={onSelectChange}
//                     />
//                   </Form.InputWrapper>

//                   {currentEvent.nextStage === "toBeInvoiced" ? (
//                     <Form.InputWrapper
//                       label="Start Date"
//                       name="startDate"
//                       errors={errors}
//                       required={true}
//                     >
//                       <DateTimePicker
//                         time={false}
//                         name="startDate"
//                         defaultValue={new Date()}
//                         ref={register({
//                           name: "startDate",
//                           value: new Date(),
//                           required: true,
//                         })}
//                       />
//                     </Form.InputWrapper>
//                   ) : (
//                     ""
//                   )}
//                   <Form.InputWrapper
//                     label="Hiring Manager"
//                     name="hiringManagerId"
//                     errors={errors}
//                     required={true}
//                     className="react-select position-title"
//                   >
//                     <Select
//                       isClearable={true}
//                       className="select"
//                       ref={register({ name: "hiringManagerId" })}
//                       options={hiringManagers}
//                       placeholder=""
//                       name="hiringManagerId"
//                       value={selectValues.hiringManagerId}
//                       onChange={onSelectChange}
//                     />
//                   </Form.InputWrapper>
//                   {/* {selectValues.invoicedEmployeeId.value !== "-new-" ? (
//                     <Form.InputWrapper
//                       label="Who Gets Invoice"
//                       name="invoicedEmployeeId"
//                       errors={errors}
//                       required={true}
//                       className="react-select position-title"
//                     >
//                       <CreatableSelect
//                         ref={register({
//                           name: "invoicedEmployeeId",
//                         })}
//                         name="invoicedEmployeeId"
//                         isClearable={true}
//                         className="select"
//                         classNamePrefix="position-title"
//                         options={employees}
//                         placeholder=""
//                         value={selectValues.employees}
//                         onCreateOption={value =>
//                           onCreateEmployeeOption(value, "invoicedEmployeeId")
//                         }
//                         onChange={onSelectChange}
//                       />
//                     </Form.InputWrapper>
//                   ) : (
//                     ""
//                   )} */}

//                   {currentEvent.nextStage === "toBeInvoiced" ? (
//                     <Form.InputWrapper
//                       label="Candidate Bonuses or Payouts"
//                       name="payouts"
//                       errors={errors}
//                     >
//                       <Form.TextInput
//                         name="payouts"
//                         type="text"
//                         value={""}
//                         forwardRef={register}
//                       />
//                     </Form.InputWrapper>
//                   ) : (
//                     ""
//                   )}
//                 </Form.Fieldset>

//                 {/* <Form.Fieldset className="rows stacked">
//                   <Form.InputWrapper
//                     label="Title"
//                     name="title"
//                     errors={errors}
//                     required={true}
//                     className="react-select position-title"
//                   >
//                     <Select
//                       isClearable={true}
//                       className="select"
//                       ref={register({
//                         name: "title",
//                         required: true,
//                       })}
//                       options={titles}
//                       placeholder=""
//                       name="title"
//                       value={selectValues.title}
//                       onChange={onSelectChange}
//                     />
//                   </Form.InputWrapper>
//                   <Form.InputWrapper
//                     label="Level"
//                     name="level"
//                     errors={errors}
//                     required={true}
//                     className="react-select position-title"
//                   >
//                     <Select
//                       isClearable={true}
//                       className="select"
//                       ref={register({
//                         name: "level",
//                         required: true,
//                       })}
//                       options={levels}
//                       placeholder=""
//                       name="level"
//                       value={selectValues.level}
//                       onChange={onSelectChange}
//                     />
//                   </Form.InputWrapper>
//                 </Form.Fieldset> */}

//                 <Form.FieldsetActions>
//                   {loading ? (
//                     <LoadingSubmit></LoadingSubmit>
//                   ) : (
//                     <>
//                       <Button type="submit" primary>
//                         Send
//                       </Button>
//                       <Button
//                         type="button"
//                         onClick={() => setFormInvoiceOpen(false)}
//                       >
//                         Cancel
//                       </Button>
//                     </>
//                   )}
//                 </Form.FieldsetActions>
//               </ol>
//             </>
//           </form>
//         </StyledForm>
//       </div>
//     </StyledFormInvoiceInfo>
//   );
// };
// FormInvoiceInfo.propTypes = {
//   currentEvent: PropTypes.exact({
//     label: PropTypes.string.required,
//     value: PropTypes.string.required,
//   }),
//   kanbanResult: PropTypes.object,
//   prepareKanbanMove: PropTypes.func,
//   move: PropTypes.func,
//   setFormInvoiceOpen: PropTypes.func,
// };
