import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

let renderBarChart = props => {
  // console.log('------barchart props-----', props);
  let chartData = props.data;
  let bars = [];
  if (Object.keys(chartData).length !== 0) {
    let keys = Object.keys(chartData[0]);
    keys.forEach((k, i) => {
      // create a bar for each object, only keys are needed
      if (k !== "name") {
        bars.push(
          <Bar
            dataKey={k}
            key={k + props.colorArray[i]}
            stackId="a"
            fill={props.colorArray[i] || props.chartColors.primaryColor}
            maxBarSize={80}
          />
        );
      }
    });
  } else {
    return "No data found";
  }

  return (
    <ResponsiveContainer width={"100%"} height={250}>
      <BarChart
        width={props.chartConfigs.width}
        height={props.chartConfigs.height}
        data={chartData}
        margin={props.chartConfigs.margin}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="bottom" height={36} />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default renderBarChart;
