import React, { useState, useCallback, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { formatAddress } from "../../helpers/Display";
import { CandidateService } from "../../services/Candidate";
import { RenderTable } from "../Shared/ListTable";

export default function CandidateList(props) {
  //check login
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState(null);
  const fetchIdRef = useRef(0);

  //get candidates
  const fetchData = useCallback(({ pageIndex, pageSize, filter }) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      getData({ pageIndex, pageSize, filter });
    }
  }, []);

  const getData = async ({ pageIndex, pageSize, filter }) => {
    let _candidates = await CandidateService.list({
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter: filter,
    });

    let _data = [];
    _candidates._embedded &&
      _candidates._embedded.forEach(i => {
        _data.push({
          //check: <Form.Checkbox option={{ checked: false }} />,
          id: i.candidateId,
          main: i.firstName + " " + i.lastName,
          emailPersonal: i.emailPersonal,
          emailWork: i.emailWork,
          phone: i.phone,
          title: i.titles && i.titles.length ? i.titles[0] : "",
          location: i.address
            ? formatAddress(i.address.city, i.address.state, null, false)
            : "",
        });
      });
    setData(_data);
    setPageCount(Math.ceil(_candidates.totalCount / pageSize));
  };

  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "main",
    },
    {
      Header: "Personal Email",
      accessor: "emailPersonal",
    },
    {
      Header: "Work Email",
      accessor: "emailWork",
    },
    {
      Header: "Mobile",
      accessor: "phone",
    },
    {
      Header: "Level",
      accessor: "title",
    },
    {
      Header: "Location",
      accessor: "location",
    },
  ];

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <RenderTable
      detail={{
        title: "Candidates",
        name: "candidate",
        name_plural: "candidates",
        url: "candidate",
        filterableColumns: ["firstName", "lastName"],
      }}
      columns={columns}
      data={data}
      heading={{ title: true, search: true, createNew: true }}
      pageCount={pageCount}
      fetchData={fetchData}
      filter={filter}
      setFilter={setFilter}
    />
  );
}
