import React, { useState, useEffect } from "react";
import { useStore } from "@kwhitley/use-store";
import { EditorState, ContentState, convertToRaw, Modifier } from "draft-js";
import { AuthService } from "../../services/Auth";
import { UserService } from "../../services/User";
import { DateTime } from "../../helpers/DateTime";
import SanitizedHTML from "react-sanitized-html";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Colors } from "../../styles/Colors";
import {
  startCase as _startCase,
  orderBy as _orderBy,
  truncate as _truncate,
} from "lodash";
import SpeechToText from "../../components/SpeechToText/SpeechToText";
import { Button, Card } from "../../styles/Common";
import { Editor as StyledEditor, Input } from "../../styles/Form";
import { Form } from "../../components/Shared/Form";
import styled from "styled-components";

import imgPhone from "../../assets/phone.svg";
import imgNote from "../../assets/edit.svg";
import imgMicrophone from "../../assets/mic.svg";
import imgMicrophoneAlt from "../../assets/mic-alt.svg";
import imgList from "../../assets/list.svg";
import { NoteService } from "../../services/Note";

const StyledActivities = styled(Card)`
  .content {
    position: relative;
    z-index: 100;
  }
  .add {
    form > ul {
      display: flex;
      align-items: center;
      li {
        font-weight: 600;
        position: relative;
        height: 24px;
        margin: 0 15px;
        text-align: center;
        overflow: hidden;
        &:first-child {
          margin-left: 5px;
        }
        .checked {
          padding-bottom: 0;
          border-bottom: 3px solid ${Colors.highlight};
          height: 21px;
        }
        .box {
          position: absolute;
          top: -20px;
          left: -20px;
        }
      }
    }

    .rdw-editor-toolbar {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #eee;
    }
    .rdw-option-wrapper {
      background: #eee;
    }

    .title {
      input {
        width: 100%;
      }
    }
    .expanded {
      display: grid;
      grid-template-columns: 1fr 260px;
      grid-gap: 10px;
      margin: 10px 0 0;
      justify-content: space-between;
    }
  }

  .list {
    margin: 30px 31px 0 26px;
    h2 {
      text-align: center;
      position: relative;
      font-weight: 600;
      font-size: 0.857rem;
      margin-bottom: 15px;
      &:before {
        content: "";
        background: #c6cbd3;
        width: 100%;
        position: absolute;
        top: 9px;
        left: 0;
        height: 1px;
        z-index: 1;
      }
      time {
        background: #fff;
        padding: 0 15px;
        position: relative;
        z-index: 2;
      }
    }

    li {
      padding-left: 34px;
      position: relative;
      margin-bottom: 15px;
      line-height: 22px;
      min-height: 40px;
      padding-bottom: 30px;
      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 17px;
        left: 0;
        background: no-repeat;
        background-size: cover;
      }
      &.note {
        &:before {
          background-image: url(${imgNote});
        }
      }
      &.call {
        &:before {
          background-image: url(${imgPhone});
        }
      }
      &.task {
        &:before {
          background-image: url(${imgList});
        }
      }
      &.completed {
        opacity: 0.5;
        article {
          text-decoration: line-through;
        }
      }
      article {
        & > div {
          display: inline-block;
        }
      }
      .show-all {
        cursor: pointer;
        font-weight: 400;
        display: inline-block;
        text-decoration: underline;
        font-style: italic;
      }
      &.medium,
      &.high,
      &.low {
        .date {
          border-radius: 5px;
          padding: 0px 7px;
          color: #fff;
          text-shadow: 1px 1px 5px #00000059;
        }
      }
      &.medium {
        .date {
          background: ${Colors.urgency_medium};
        }
      }
      &.high {
        .date {
          background: ${Colors.urgency_high};
        }
      }
      &.low {
        .date {
          background: ${Colors.urgency_low};
        }
      }
    }
    dl {
      display: flex;
      justify-content: space-between;
    }
    dt {
      display: none;
    }
    dd {
      font-weight: 600;
      &.date {
        font-size: 0.857rem;
        color: #8792a3;
        font-weight: 400;
      }
      &.status {
        position: absolute;
        right: 0;
        top: 23px;
        font-weight: 400;
      }
      &.user {
        position: absolute;
        bottom: 0;
        left: 35px;
        font-style: italic;
        font-weight: 400;
        font-size: 0.7rem;
      }
    }
    article {
      margin-right: 30%;
      ul {
        list-style-type: disc;
      }
      ol {
        list-style-type: decimal;
      }

      li {
        padding-left: 0;
        margin-bottom: 0;
        margin-left: 20px;
      }
      em {
        font-style: italic;
      }
      .mention {
        font-weight: 500;
      }
      a {
        text-decoration: underline;
      }
    }
    button {
      margin: 0 auto 26px;
      display: block;
    }
  }

  .microphone-modal {
    background: #fff;
    width: 400px;
    min-height: 100px;
    position: absolute;
    z-index: 1000;
    transform: translate(50%, -50%);
    right: 50%;
    bottom: 7px;
    box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);
    border: 1px solid rgba(198, 203, 211, 0.5);
    border-radius: 3px;
    padding: 15px;
    &:before {
      display: inline-block;
      background: ${Colors.primary} no-repeat;
      background-image: url(${imgMicrophoneAlt});
      background-position: 50% 50%;
      background-size: 50%;
      border-radius: 20px;
      height: 30px;
      width: 30px;
      vertical-align: middle;
      margin-left: 10px;
    }
    .ripple {
      display: inline-block;
      position: relative;
      width: 30px;
      height: 30px;
      margin-left: 5px;
    }
    .ripple:after,
    .ripple:before {
      content: "";
      position: absolute;
      border: 2px solid #ff0000;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .ripple:before {
      animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
      0% {
        top: 14px;
        left: 14px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 0px;
        left: 0px;
        width: 28px;
        height: 28px;
        opacity: 0;
      }
    }
    .recording {
      font-size: 0.9em;
      font-style: italic;
      margin-left: 20px;
    }
    .audio-text {
      background: #eee;
      padding: 5px;
      margin: 10px 5px;
      border-radius: 3px;
      min-height: 40px;
    }
    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        margin-top: 0;
      }
    }
  }
`;

export const Activities = ({ detail, entity, Service }) => {
  const jwtInfo = AuthService.jwtInfo();
  const [notifications, setNotifications] = useStore("notifications");

  //get users
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    let response = await UserService.list({
      limit: 1000,
      sortBy: "lastName.asc",
      filter: {
        search: {
          NOT: {
            field: "password",
            value: "^$"
          }
        }
      }
    })

    if (response._embedded) {
      setUsers(
        response._embedded.map(o => {
          return {
            text: o.firstName + " " + (o.lastName ? o.lastName[0] : ""),
            value: o.firstName + " " + (o.lastName ? o.lastName[0] : ""),
            url: o.id,
          };
        })
      );
    }
  };

  const limit = 10; //# of activities to display initially

  //react-hook-form
  const { register, handleSubmit, setValue, getValues, reset } = useForm();

  const [activities, setActivities] = useState([]);
  const [viewMore, setViewMore] = useState(true);
  //const [currentTab, setCurrentTab] = useState("note");
  let currentTabEl = "note";
  let currentTab = "note";

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const fetchActivities = async more => {
    let _activities = await Service.getNotes({
      id: entity.id,
      limit: limit,
      offset: activities && activities.length ? activities.length - 1 : 0,
    });

    if (_activities._embedded) {
      let _activitiesCombined = [
        ..._activities._embedded.filter(a => a.type === "note"),
        ..._activities._embedded.filter(a => a.type === "call"),
        ..._activities._embedded.filter(a => a.type === "task"),
      ];
      _activitiesCombined = _activitiesCombined.map(n => {
        return {
          date: n.date,
          id: n.noteId,
          title: n.title,
          note: n.note,
          type: n.type,
          completed: n.completed,
          archived: n.archived,
          createdBy: n.createdBy,
          showFullNote: n.note && n.note.length > 500 ? false : true,
        };
      });

      _activitiesCombined = _orderBy(_activitiesCombined, ["date"], ["desc"]);

      setActivities([...activities, ..._activitiesCombined]);

      if (_activitiesCombined.length < limit) {
        setViewMore(false);
      }
    }
  };
  useEffect(() => {
    fetchActivities(false);
    getUsers();
  }, []);

  const onViewMore = async () => {
    fetchActivities("more");
  };

  let activitiesFormatted = [];
  let _month = "";
  let _year = "";
  let i = 0;
  let e = 0;
  let _date;

  //radio for activity
  const onChecked = async e => {
    currentTab = e.target.value;
  };

  currentTabEl = document.querySelector("input[name='type']:checked");
  if (currentTabEl) {
    currentTab = currentTabEl.value;
  }
  let _completedTasks = false;
  activities
    .filter(a => !a.archived)
    .forEach(activity => {
      if (!activity.date) {
        //TODO: delete this, won't be necessary after DB update
        _date = activity.date = new Date();
      } else {
        _date = new Date(activity.date);
      }

      //new heading?
      if (
        e === 0 ||
        _month != _date.getMonth() ||
        _date.getFullYear() != _year
      ) {
        //set i for new activities group
        if (e !== 0) {
          i++;
        }
        _month = _date.getMonth();
        _year = _date.getFullYear();
        /*if (
        currentTab != "task" ||
        (currentTab == "task" && activity.type == "task")
      ) {
        activitiesFormatted[i] = {
          monthYear: new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long"
          }).format(_date),
          activities: []
        };
      }*/
      }
      //give a class for each type
      switch (activity.type) {
        case "In Person Interview":
        case "Interview":
          activity.classType = "interview";
          break;
        case "note":
          activity.classType = "note";
          break;
        case "call":
        case "Phone Interview":
          activity.classType = "call";
          break;
        case "task":
          activity.classType = "task";
          break;
      }
      //add in activity
      if (
        currentTab != "task" ||
        (currentTab == "task" && activity.type == "task")
      ) {
        if (!activitiesFormatted[i]) {
          activitiesFormatted[i] = {
            monthYear: new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
            }).format(_date),
            activities: [],
          };
        }
        if (activity.completed) {
          _completedTasks = true;
        }
        activitiesFormatted[i].activities.push({
          dateRaw: activity.date,
          date:
            new Intl.DateTimeFormat("en-US", options).format(
              new Date(activity.date)
            ) +
            (activity.type === "note"
              ? ""
              : " @ " + DateTime.formatTime(activity.date)),
          id: activity.id,
          title: activity.title,
          note: activity.showFullNote
            ? activity.note
            : _truncate(activity.note, { length: 500, separator: /,?\.* +/ }),
          showFullNote: activity.showFullNote,
          type: activity.type,
          completed: activity.completed,
          classType: activity.classType,
          createdBy: activity.createdBy,
        });
        e++;
      }
    });

  //ADD ACTIVITY
  const [activityErrorMessage, setActivityErrorMessage] = useState("");
  //activity type options
  const [activityTypeOptions, setActivityTypeOptions] = useState([
    {
      label: "Note",
      value: "note",
      checked: true,
    },
    { label: "Call", value: "call", checked: false },
    { label: "Task", value: "task", checked: false },
  ]);

  const [activityEditorState, setActivityEditorState] = useState(
    EditorState.createEmpty()
  );

  const onEditorStateChange = editorState => {
    setActivityEditorState(editorState);
    setActivityErrorMessage("");
  };

  //Custom Mic Button
  const [micSelected, setMicSelected] = useState(false);
  const insertAudio = receivedText => {
    setMicSelected(!micSelected);
    let contentState = Modifier.insertText(
      activityEditorState.getCurrentContent(),
      activityEditorState.getSelection(),
      receivedText,
      activityEditorState.getCurrentInlineStyle()
    );
    setActivityEditorState(
      EditorState.push(activityEditorState, contentState, "insert-characters")
    );
    setMicSelected(false);
  };

  const onChange = e => {
    setActivityErrorMessage("");
  };
  const onDateChange = async (dateRaw, dateFormatted) => {
    setValue("date", dateFormatted);
  };

  const onTaskCompleted = activity => {
    let response = NoteService.update({
      id: activity.id,
      completed: !activity.completed,
    });

    if (response) {
      setActivities(
        activities.map(a => {
          if (a.id === activity.id) {
            a.completed = !a.completed;
          }
          return a;
        })
      );
    }
  };

  //ADD Activity / Note
  const onActivityAdd = async data => {
    //if empty, do not submit
    let contentEmpty = false;
    if (
      !activityEditorState.getCurrentContent() ||
      !activityEditorState.getCurrentContent().hasText()
    ) {
      contentEmpty = true;
    }
    if (data.type === "note" && contentEmpty) {
      setActivityErrorMessage("Note cannot be empty");
      return;
    }

    if (data.type === "task" || data.type === "call") {
      data.title = document.getElementById("title").value;
    }

    if (data.type === "task") {
      if (!data.title || data.title.trim() === "") {
        setActivityErrorMessage("Task should have a title");
        return;
      }
    }

    if (data.type === "call" && contentEmpty && data.title.trim() === "") {
      setActivityErrorMessage("Call title and content cannot be empty");
      return;
    }

    data.type = document.querySelector('input[name="type"]:checked').value;
    data.note = draftToHtml(
      convertToRaw(activityEditorState.getCurrentContent())
    );

    data.id = entity.id;
    if (data.type.match(/task|call/)) {
      data.date = moment(
        document.querySelector('input[name="date"]').value
      ).toDate();
    } else {
      data.date = new Date();
    }
    //set notification if needed
    //client decided to remove notifications
    /*if (
      +moment(data.date) >= +moment() && //not in the past
      +moment(data.date) <= +moment().add(3, "days") //within 3 days of future
    ) {
      setNotifications([
        ...notifications,
        {
          ...data,
          target: detail.type,
          seen: 0,
          body: data.note,
          id: entity.id,
          url: "/" + (detail.type.toLowerCase() + "/" + entity.id),
        },
      ]);
    } */

    //add mentions to notifications
    data.mentioned = [];
    let mentions = Object.values(
      convertToRaw(activityEditorState.getCurrentContent()).entityMap
    );
    mentions
      .filter(m => m.type === "MENTION")
      .forEach(m => {
        data.mentioned.push(m.data.url);
      });
    //remove any mentions from the content
    if (data.mentioned.length) {
      data.note = data.note.replace(
        /<a(.*?)wysiwyg\-mention(.*?)>(.*?)<\/a>/g,
        '<span class="mention">$3</span>'
      );
    }

    //save to database
    let response = await Service.addNote(data);

    if (!response.error) {
      //may have to update Offset here as well for "View More"
      //add activity
      data.id = response._links.next[1].href.replace("/notes/", "");
      data.createdBy = jwtInfo.lastName + ", " + jwtInfo.firstName;

      data.showFullNote = true;

      setActivities([data, ...activities]);

      //reset content of editor
      let _editorState = EditorState.push(
        activityEditorState,
        ContentState.createFromText("")
      );
      reset();
      //reset title, sometimes it seems to stick
      if (document.getElementById("title")) {
        document.getElementById("title").value = "";
      }
      setActivityEditorState(_editorState);
    }
  };

  //archive completed tasks
  const archiveCompletedTasks = () => {
    activities
      .filter(a => a.completed)
      .forEach(aa => {
        NoteService.update({ archived: true, id: aa.id });
      });

    setActivities(activities.filter(a => !a.completed));
  };

  const uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      try {
        reader.onloadend = function() {
          resolve({ data: { link: reader.result } });
        };
        reader.readAsDataURL(file);
      } catch {
        reject();
      }
    });
  };

  const onShowFullNote = id => {
    let activity = activities.find(a => a.id === id);
    activity.showFullNote = true;

    setActivities([...activities]);
  };
  return (
    <StyledActivities>
      <h1>Activity</h1>

      <div className="content">
        <div className="add">
          <form onSubmit={handleSubmit(onActivityAdd)}>
            <Form.RadioButtons
              name="type"
              forwardRef={register}
              options={activityTypeOptions}
              onChange={onChecked}
              setOptions={setActivityTypeOptions}
            />

            {activityTypeOptions.map((type, i) => {
              if (
                (type.value == "call" || type.value == "task") &&
                type.checked
              ) {
                return (
                  <div className="expanded" key={i}>
                    <Input type="text" className="title">
                      <Form.TextInput
                        id={"title"}
                        name={"title"}
                        type="text"
                        placeholder="Title"
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Input>
                    {type.value.match(/task|call/) ? (
                      <Input
                        type="text"
                        className="date-picker time-picker calendar"
                      >
                        <DateTimePicker
                          name="date"
                          defaultValue={new Date()}
                          ref={register({
                            name: "date",
                            value: new Date(),
                          })}
                          onChange={onDateChange}
                        />
                      </Input>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}

            <StyledEditor>
              <Editor
                toolbar={{
                  options: ["inline", "link", "image", "list"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                  link: { options: ["link"] },
                  image: {
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    uploadCallback: uploadImageCallBack,
                    alt: { present: false, mandatory: false },
                  },
                  list: { options: ["unordered", "ordered"] },
                }}
                placeholder="Add a note..."
                onEditorStateChange={onEditorStateChange}
                editorState={activityEditorState}
                toolbarCustomButtons={[
                  <Microphone
                    key="mic"
                    activityEditorState={activityEditorState}
                    micSelected={micSelected}
                    setMicSelected={setMicSelected}
                  />,
                ]}
                mention={{
                  separator: " ",
                  trigger: "@",
                  suggestions: users,
                }}
              />
              {/* Speech to Text component */}
              {micSelected ? (
                <SpeechToText
                  setMicSelected={setMicSelected}
                  insertAudio={insertAudio}
                />
              ) : null}
              {activityErrorMessage && (
                <Form.Error message={activityErrorMessage} />
              )}
              <div className="actions">
                <Button primary size="small">
                  Add New Activity
                </Button>
              </div>
            </StyledEditor>
          </form>
        </div>
      </div>

      <div className="list">
        {currentTab == "task" && _completedTasks ? (
          <Button size="small" onClick={archiveCompletedTasks}>
            Archive Completed Tasks
          </Button>
        ) : (
          ""
        )}

        {activitiesFormatted.length
          ? activitiesFormatted.map((af, i) => {
              return (
                <React.Fragment key={i}>
                  <h2>
                    <time>{af.monthYear}</time>
                  </h2>
                  <ol>
                    {af.activities.map((activity, i) => {
                      let priority = "";
                      if (detail.type === "Event" && activity.type === "task") {
                        const dateAgo = moment(activity.dateRaw).diff(
                          new Date(),
                          "days"
                        );
                        if (dateAgo <= 7) {
                          priority = "high";
                        } else if (dateAgo <= 14) {
                          priority = "medium";
                        } else {
                          priority = "low";
                        }
                      }
                      return (
                        <li
                          key={i}
                          className={
                            priority +
                            " " +
                            activity.classType +
                            (activity.completed ? " completed" : "")
                          }
                        >
                          <dl>
                            <dt>Title</dt>
                            <dd className="title">
                              {activity.title
                                ? activity.title
                                : _startCase(activity.type)}
                            </dd>
                            {activity.type === "task" ? (
                              <>
                                <dt>Status</dt>
                                <dd className="status">
                                  <Form.Checkbox
                                    options={[
                                      {
                                        label: "Done",
                                        checked: activity.completed,
                                        value: 1,
                                      },
                                    ]}
                                    option={{
                                      label: "Done",
                                      checked: activity.completed,
                                      value: 1,
                                    }}
                                    onChange={() => onTaskCompleted(activity)}
                                  />
                                </dd>
                              </>
                            ) : (
                              ""
                            )}
                            <dt>Date</dt>
                            <dd className="date">{activity.date}</dd>{" "}
                            <dt>User</dt>
                            <dd className="user">
                              {activity.createdBy ===
                              jwtInfo.lastName + ", " + jwtInfo.firstName
                                ? "You"
                                : activity.createdBy}{" "}
                            </dd>{" "}
                          </dl>
                          <article>
                            <SanitizedHTML html={activity.note} />
                            {!activity.showFullNote ? (
                              <span
                                className="show-all"
                                onClick={() => onShowFullNote(activity.id)}
                              >
                                read more
                              </span>
                            ) : (
                              ""
                            )}
                          </article>
                        </li>
                      );
                    })}
                  </ol>
                </React.Fragment>
              );
            })
          : ""}
        {viewMore ? (
          <Button size="small" onClick={() => onViewMore()}>
            View More
          </Button>
        ) : (
          ""
        )}
      </div>
    </StyledActivities>
  );
};

const Microphone = ({ activityEditorState, micSelected, setMicSelected }) => {
  const micClicked = () => {
    setMicSelected(true);
  };
  return (
    <div
      className={
        "rdw-option-wrapper" + (micSelected ? " rdw-option-active" : "")
      }
      aria-selected={micSelected ? "true" : "false"}
      title="Microphone"
      onClick={() => micClicked(activityEditorState)}
    >
      <img src={imgMicrophone} alt="Microphone" width="13" />
    </div>
  );
};
