import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { PositionService } from "../../services/Position";
import { formatAddress } from "../../helpers/Display";
import { formatUUID } from "../../helpers/Display";
import styled from "styled-components";

import { Colors } from "../../styles/Colors";
import { Container, Button, PageTitle, LinkClose } from "../../styles/Common";
import { Form } from "../../components/Shared/Form";
import { Input, Tabs } from "../../styles/Form";
import imgCircleX from "../../assets/circle-x.svg";
import { toast } from "react-toastify";

const Styled = styled.div`
  .display {
    margin: 44px auto 0;
    width: 721px;
  }
  .search {
    input {
      width: 100%;
    }
    .go {
      cursor: pointer;
    }
  }
  .filter {
    margin: 29px 0 0;
  }

  .results {
    margin: 30px 0 0;

    ul {
      margin-top: 11px;
    }
    li {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-areas: "title actions";
      border-bottom: 1px solid #d8d8d8;
      padding: 8px 0;
      align-items: center;
      &:hover {
        padding: 8px 15px;
        background: ${Colors.gray};
        .actions {
          display: block;
        }
      }
      .actions {
        display: none;
        grid-area: actions;
        text-align: right;
        color: ${Colors.primary};
        font-weight: 700;
        span {
          margin: 0 15px;
          cursor: pointer;
        }
      }
    }
  }
`;

export default function SearchAddToPosition(props) {
  let params = useParams();

  console.log("params", params);

  const candidateId = params.candidateId;

  //ADD CANDIDATE TO POSITION
  const addToPosition = async positionId => {
    let _positionAdded = await PositionService.addCandidates({
      id: positionId,
      candidateIds: [candidateId],
    });

    console.log("posadd", _positionAdded);

    if (_positionAdded.id) {
      setCanidateAdded(true); //set for redirect

      //update to possible TODO: set automatic default? clear up Possible vs Qualified
      //update candidate in database
      PositionService.updateCandidateStage({
        candidateId: candidateId,
        positionId: _positionAdded.id,
        stage: "Short List",
      });

      //toast notificiation //TODO get names of position/candidate
      toast.success("Candidate added to position", {
        position: "bottom-center",
        closeOnClick: false,
        onClick: () => props.history.push("/position/" + _positionAdded.id),
      });

      if (params.positionId) {
        props.history.push("/position/" + _positionAdded.id);
      } else {
        //props.history.push("/candidate/" + candidateId); //TODO... not sure if we will actually ever want this
        props.history.push("/position/" + _positionAdded.id);
      }
    } else if (_positionAdded.error || _positionAdded.errors) {
      console.log("ERROR!!!!");
      //toast.dismiss(_positionAdded.id);
      toast.error(
        _positionAdded.error
          ? _positionAdded.error
          : _positionAdded.errors[0].message,
        {
          toastId: _positionAdded.id,
          position: "bottom-center",
        }
      );
      if (params.positionId) {
        props.history.push(
          "/search/filter:candidates/positionId:" + params.positionId
        );
      }
    }
  };

  //get candidate
  const [positions, setPositions] = useState([]);
  const [initPositions, setInitPositions] = useState([]);
  const [candidateAdded, setCanidateAdded] = useState(false);

  useEffect(() => {
    const getPositions = async () => {
      let _positions = await PositionService.list({
        limit: 200,
        filter: { owner: false },
      });

      if (_positions._embedded) {
        console.log("positions", _positions);
        setPositions(_positions._embedded);
        setInitPositions(_positions._embedded);
      }
    };
    getPositions();

    //if position is already sent, go ahead and add candidate
    if (params.positionId) {
      addToPosition(params.positionId);
    }
  }, []);

  const onChange = e => {
    let search = e.target.value;
    if (search) {
      let regex = new RegExp(search, "i");
      setPositions(initPositions.filter(p => p.title.match(regex)));
    } else {
      setPositions(initPositions);
    }
  };

  return (
    <Styled>
      <Container className="container">
        <div className="display">
          <PageTitle>Add to Job Order</PageTitle>
          <Input className="search" type="search">
            <Form.TextInput
              name="search"
              type="text"
              value={""}
              onChange={onChange}
              placeholder={"Search job orders..."}
            />
            <span className="go"></span>
          </Input>

          <div className="results">
            {positions.length ? (
              <ul>
                {positions.map((position, i) => {
                  return (
                    <li key={i}>
                      <span className="title">
                        {formatUUID(position.id, "position")} {position.title}{" "}
                        {position.company ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: "&bull; " + position.company,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div className="actions">
                        <span onClick={() => addToPosition(position.id)}>
                          Add to Job Order
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              "No job orders found"
            )}
          </div>
        </div>
      </Container>
    </Styled>
  );
}
