import React, { useState, useEffect, useCallback, useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import device from "../../../config/device";
import { AuthService } from "../../../services/Auth";
import { DateTime } from "../../../helpers/DateTime";
import { formatUUID, formatAddress } from "../../../helpers/Display";
import { RenderTable } from "../../Shared/ListTable";
import styled from "styled-components";

import { ContactService } from "../../../services/Contact";
import { UserService } from "../../../services/User";

const Styled = styled.div`
  .filters {
    justify-self: end;
  }
  @media ${device.laptopMax} {
    .positions {
      grid-template-columns: auto auto 1fr;
      grid-template-rows: auto auto;
    }

    .search {
      padding: 15px 0 10px;
      grid-area: 2 / 1 / 3 / 4;
      input[type="text"] {
        width: 100%;
        box-sizing: border-box;
      }
      &:after,
      .go {
        top: 38px !important;
      }
    }
  }
  @media ${device.tabletMMax} {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    .filters {
      margin-top: 10px;
      justify-self: start;
      grid-area: 2 / 1 / 3 / 4;
      button {
        margin-bottom: 10px;
      }
    }
    .search {
      grid-area: 3 / 1 / 4 / 4;
    }
  }
  @media ${device.tabletLMax} {
    .positions {
      .filters {
        grid-area: 2 / 1 / 3 / 2;
      }
      .search {
        grid-area: 3 / 1 / 4 / 4;
      }
    }
  }
  @media ${device.mobileLMax} {
    .positions {
      display: block;
      a {
      }
    }
  }
`;
export default function PositionList(props) {
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );
  const jwtInfo = AuthService.jwtInfo();

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [filter, setFilter] = useState({
    users: {
      owner: jwtInfo.user,
      primary: jwtInfo.user,
      secondary: jwtInfo.user,
    },
  });

  //get candidates
  const fetchData = useCallback(({ pageIndex, pageSize, filter }) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      getData({ pageIndex, pageSize, filter });
    }
  }, []);

  const getData = async ({ pageIndex, pageSize, filter }) => {
    let _items = await ContactService.list({
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter: filter,
    });

    let _data = [];
    _items._embedded &&
      _items._embedded.forEach(i => {
        _data.push({
          id: i.bdContactId,
          candidateId: i.candidateId,
          main: i.firstName + " " + i.lastName,
          email: i.emailPersonal,
          phone: i.phone,
          title: i.titles && i.titles.length ? i.titles[0] : "",
          location: i.address
            ? formatAddress(
                i.address.city,
                i.address.state,
                i.country,
                false,
                false
              )
            : "",
        });
      });
    setData(_data);

    setPageCount(Math.ceil(_items.totalCount / pageSize));
  };
  //STATUSES
  const [statusSelected, setStatusSelected] = useState("Any");
  const statuses = [
    { label: "Any", value: "" },
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
    { label: "On Hold", value: "On Hold" },
  ];

  const filterStatus = status => {
    setStatusSelected(status.label);
    setFilter({ ...filter, status: status.value ? status.value : undefined });
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "main",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Location",
      accessor: "location",
    },
  ];

  //not bizdev
  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  console.log("data", data);

  return (
    <Styled>
      <RenderTable
        detail={{
          title: "Contacts",
          name: "contact",
          name_plural: "contacts",
          url: "bd/contact",
          filterableColumns: ["name"],
        }}
        data={data}
        columns={columns}
        heading={{
          title: true,
          search: true,
          createNew: true,
          className: "contacts",
        }}
        pageCount={pageCount}
        fetchData={fetchData}
        filter={filter}
        setFilter={setFilter}
        statuses={statuses}
        statusSelected={statusSelected}
        setStatusSelected={setStatusSelected}
        filterStatus={filterStatus}
      />
    </Styled>
  );
}
