import React, { useState, useEffect } from "react";
import Select from "react-select";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import {
  resetItem,
  addItem,
  deleteItem,
  handleIteratorChange,
} from "../../helpers/FormFieldsIterator";
import { EventService } from "../../services/Event";
import { ContactService } from "../../services/Contact";
import { CandidateService } from "../../services/Candidate";
import { PositionService } from "../../services/Position";
import { Form as StyledForm } from "../../styles/Form";
import { useForm } from "react-hook-form";
import { LoadingSubmit } from "../../styles/Animation";

import { Button, ButtonClose } from "../../styles/Common";
import { Form } from "../../components/Shared/Form";
import styled from "styled-components";
import imgX from "../../assets/x.svg";
import imgCircleX from "../../assets/circle-x.svg";
import { toast } from "react-toastify";

const StyledFormInvoiceInfo = styled.div`
  .bg {
    display: block;
    content: "";
    background: #000000c7;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    left: 0;
    top: 0;
  }
  .display {
    background: #fff;
    width: 900px;
    height: 650px;
    overflow-x: auto;
    padding: 20px 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    p {
      margin-bottom: 5px;
    }
    em {
      font-style: italic;
      font-weight: 500;
    }
  }
  .cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    cursor: pointer;
  }
  .input-confirm {
    display: flex;
    button {
      margin-left: 15px;
    }
  }
  form li.actions {
    margin-top: 10px;
    fieldset {
      margin-top: 0;
    }
  }
`;

export const FormInvoiceInfo = ({
  currentEvent,
  setFormInvoiceOpen,
  callback,
}) => {
  const titles = [
    { label: "Buyer Planner", value: "Buyer Planner" },
    {
      label: "Design Engineer, Electrical",
      value: "Design Engineer, Electrical",
    },
    {
      label: "Design Engineer, Mechanical",
      value: "Design Engineer, Mechanical",
    },
    { label: "Design Engineer, Software", value: "Design Engineer, Software" },
    {
      label: "Design Engineer, Machine Development",
      value: "Design Engineer, Machine Development",
    },
    {
      label: "Clinical Research Associate",
      value: "Clinical Research Associate",
    },
    { label: "Regulatory Affairs", value: "Regulatory Affairs" },
    { label: "Regulatory Associate", value: "Regulatory Associate" },
    { label: "Sales", value: "Sales" },
    { label: "Marketing", value: "Marketing" },
    {
      label: "Marketing, Product Manager",
      value: "Marketing, Product Manager",
    },
    { label: "Marketing, Communications", value: "Marketing, Communications" },
    { label: "Manufacturing Engineer", value: "Manufacturing Engineer" },
    {
      label: "Manufacturing Engineer Process",
      value: "Manufacturing Engineer Process",
    },
    {
      label: "Manufacturing Engineer Validation",
      value: "Manufacturing Engineer Validation",
    },
    {
      label: "Manufacturing Engineer Operrations",
      value: "Manufacturing Engineer Operrations",
    },
    {
      label: "Manufacturing Engineer Automation",
      value: "Manufacturing Engineer Automation",
    },
    {
      label: "Manufacturing, Materials Engineer",
      value: "Manufacturing, Materials Engineer",
    },
    { label: "Quality Engineer", value: "Quality Engineer" },
    { label: "Field Service", value: "Field Service" },
    { label: "Scientist", value: "Scientist" },
    { label: "Scientist, Polymers", value: "Scientist, Polymers" },
    { label: "Scientist, Chemicals", value: "Scientist, Chemicals" },
    { label: "Other", value: "Other" },
  ];

  const levels = [
    { label: "Individual", value: "Individual" },
    { label: "Manager", value: "Manager" },
    { label: "Director Vice President", value: "Director Vice President" },
    { label: "President", value: "President" },
  ];

  const [loading, setLoading] = useState(false);

  const [fieldsRequired, setFieldsRequired] = useState({
    positionId: false,
    companyId: false,
    bdContactId: false,
    hiringManagerId: false,
  });
  //get position
  const [hiringManagers, setHiringManagers] = useState([]);
  const getHiringManagers = async () => {
    let response = await CandidateService.list({ limit: 10000 });

    if (!response.error) {
      setHiringManagers(
        response._embedded.map(r => {
          return {
            label: r.firstName + " " + r.lastName,
            value: r.candidateId,
          };
        })
      );
    } else {
      toast.error(response.error);
    }
  };
  const getPosition = async () => {
    if (currentEvent.positionId) {
      let response = await PositionService.get(currentEvent.positionId);

      if (response && response.hiringManagers) {
        const _hm = response.hiringManagers[0];
        selectValues.hiringManagerId = {
          label: _hm.firstName + " " + _hm.lastName,
          value: _hm.candidateId,
        };
      } else {
        setFieldsRequired({ ...fieldsRequired, hiringManagerId: true });
      }
    } else {
      setFieldsRequired({ ...fieldsRequired, positionId: true });
      setFieldsRequired({ ...fieldsRequired, hiringManagerId: true });
    }
  };

  const iterableInputs = {
    splits: {
      name: "splits",
      fields: [
        { name: "name", type: "text" },
        { name: "percentage", type: "number", defaultValue: "100" },
      ],
    },
  };
  const [splits, setSplits] = useState([resetItem(iterableInputs, "splits")]);

  /*
   * Button to add new contact
   */
  const fieldsetActionsSplits = () => {
    return (
      <Button
        type="button"
        fieldsetAction
        size="small"
        style={{ marginBottom: "14px" }}
        onClick={() => addItem(splits, setSplits, iterableInputs, "splits")}
      >
        Add Another Split
      </Button>
    );
  };

  //get contacts
  const [contacts, setContacts] = useState([]);
  const getContacts = async () => {
    let response = await ContactService.list({
      limit: 10000,
      offset: 0,
    });
    if (!response.error) {
      setContacts(
        response._embedded.map(r => {
          if (r.candidateId === currentEvent.candidateId) {
            selectValues.candidateId = {
              label: r.firstName + " " + r.lastName,
              value: r.candidateId,
            };
          }
          return {
            label: r.firstName + " " + r.lastName,
            value: r.candidateId,
          };
        })
      );
      if (!selectValues.candidateId) {
        setFieldsRequired({ ...fieldsRequired, bdContactId: true });
      }
    } else {
      toast.error(response.error);
    }
  };
  //get positions
  const [positions, setPositions] = useState([]);
  const getPositions = async () => {
    let response = await PositionService.list({
      limit: 10000,
      offset: 0,
    });

    if (!response.error) {
      setPositions(
        response._embedded.map(r => {
          return {
            label: r.title,
            value: r.id,
          };
        })
      );
    } else {
      toast.error(response.error);
    }
  };

  useEffect(() => {
    getPosition(); //need list of hiring managers
    getPositions();
    getContacts();
    getHiringManagers();
  }, []);

  const initSelectValues = {
    stage: { label: "Engagement", value: "toBeInvoiced" },
    title: titles[0],
    level: levels[0],
    split: { label: "No", value: "No" },
    hiringManagerId: null,
    invoicedEmployeeId: { label: "", value: "" },
    positionId: currentEvent.positionId
      ? {
          label: currentEvent.position,
          value: currentEvent.positionId,
        }
      : null,
    companyId: currentEvent.companyId
      ? {
          label: currentEvent.companyName,
          value: currentEvent.companyId,
        }
      : null,
    candidateId: currentEvent.candidateId
      ? {
          label:
            currentEvent.contactFirstName + " " + currentEvent.contactLastName,
          value: currentEvent.candidateId,
        }
      : null,
  };
  const [selectValues, setSelectValues] = useState(initSelectValues);

  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      candidate: {
        metrics: {
          level: selectValues.level.value,
          title: selectValues.title.value,
        },
      },
      split: selectValues.split.value,
    },
  });

  const onSubmit = async data => {
    setLoading(true);

    //splits
    data.split = [];
    splits.forEach(split => {
      if (split.name.trim()) {
        data.split.push({
          name: split.name,
          percentage: Number(split.percentage) / 100,
        });
      }
    });

    //adding/updating
    data.companyId =
      selectValues.companyId?.value &&
      selectValues.companyId.value !== currentEvent.companyId
        ? selectValues.companyId?.value
        : undefined;
    data.positionId =
      selectValues.positionId?.value &&
      selectValues.positionId.value !== currentEvent.positionId
        ? selectValues.positionId?.value
        : undefined;
    data.hiringManagerId =
      selectValues.hiringManagerId?.value &&
      selectValues.hiringManagerId.value !== currentEvent.companyId
        ? selectValues.hiringManagerId?.value
        : undefined;
    if (
      selectValues.candidateId &&
      selectValues.candidateId.value !== currentEvent.candidateId
    ) {
      const _contact = contacts.find(
        c => c.value === selectValues.candidateId.value
      );
      data.bdContactId = _contact.bdContactId;
    }

    //check required items
    let error = false;
    if (!data.hiringManagerId && fieldsRequired.hiringManagerId) {
      toast.error("Hiring Manager is required");
      error = true;
    }
    if (!data.positionId && fieldsRequired.positionId) {
      toast.error("Position is required");
      error = true;
    }
    if (!data.bdContactId && fieldsRequired.bdContactId) {
      toast.error("Contact is required");
      error = true;
    }

    //send
    if (!error) {
      const response = await EventService.update({
        id: currentEvent.bdEventId,
        data: {
          ...data,
          stage: currentEvent.nextStage,
          split: data.split.length ? data.split : undefined,
          payouts:
            currentEvent.nextStage === "toBeInvoiced"
              ? data.payouts
              : undefined,
          startDate:
            currentEvent.nextStage === "toBeInvoiced"
              ? data.startDate
              : undefined,
          candidateName:
            currentEvent.nextStage === "toBeInvoiced"
              ? data.candidateName
              : undefined,
        },
      });

      if (!response.error) {
        //update stage
        callback(currentEvent.nextStage);
        //close form
        setFormInvoiceOpen(false);
        //set success response
        toast.success("Invoice has been sent");
      } else {
        toast.error(response.error);
      }
    }
    setLoading(false);
  };

  const onSelectChange = (opt, actionMeta) => {
    let name = actionMeta.name;
    let value;

    //dropdown
    if (!opt) {
      //empty data
      value = null;
    } else if (opt && opt.value) {
      value = opt.value;
    } else {
      //multiselect
      value = opt;
    }
    selectValues[name.replace(/(.*)\[(.*)\]$/g, "$2")] = opt;
    setValue(name, value); //for react-hook
    setSelectValues({ ...selectValues });
  };

  const cancelStageMove = () => {
    setFormInvoiceOpen(false);
  };

  return (
    <StyledFormInvoiceInfo>
      <div className="bg"></div>
      <div className="display">
        <h1>Invoice</h1>
        <img
          className="cancel"
          onClick={cancelStageMove}
          src={imgCircleX}
          title="Cancel"
          alt="Cancel"
        />
        <StyledForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <>
              <ol>
                <Form.Fieldset
                  legend="Split"
                  className="rows stacked"
                  fieldsetActions={fieldsetActionsSplits()}
                >
                  {splits.length &&
                    splits.map((item, index) => (
                      <li
                        className="row group"
                        style={{ display: "flex" }}
                        key={index}
                      >
                        <ol>
                          <Form.InputWrapper
                            label="Split with Who"
                            name="split[name]"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="split[name]"
                              type="text"
                              forwardRef={register}
                              controlledValue={item.name}
                              onChange={e =>
                                handleIteratorChange(
                                  e,
                                  index,
                                  splits,
                                  setSplits
                                )
                              }
                            />
                          </Form.InputWrapper>
                          <Form.InputWrapper
                            label="Split Percentage"
                            name="split[percentage]"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="split[percentage]"
                              type="number"
                              min="0"
                              max="100"
                              step=".01"
                              placeholder=""
                              forwardRef={register}
                              controlledValue={item.percentage}
                              onChange={e =>
                                handleIteratorChange(
                                  e,
                                  index,
                                  splits,
                                  setSplits
                                )
                              }
                            />
                          </Form.InputWrapper>
                        </ol>
                        {index ? (
                          <ButtonClose
                            type="button"
                            style={{ marginTop: 30 }}
                            onClick={() =>
                              deleteItem(
                                index,
                                splits,
                                setSplits,
                                iterableInputs,
                                "splits"
                              )
                            }
                          >
                            <img src={imgX} alt="Delete" title="Delete" />
                          </ButtonClose>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                </Form.Fieldset>

                <Form.Fieldset legend="Invoice To" className="stacked rows">
                  <Form.InputWrapper
                    label={
                      currentEvent.nextStage === "toBeInvoiced"
                        ? "Employee Name"
                        : "Name/Title"
                    }
                    name="toInvoice[name]"
                    errors={errors}
                    required={true}
                  >
                    <Form.TextInput
                      name="toInvoice[name]"
                      type="text"
                      forwardRef={register({ required: true })}
                      value={""}
                    />
                  </Form.InputWrapper>
                  <Form.InputWrapper
                    label={
                      (currentEvent.nextStage === "toBeInvoiced"
                        ? "Employee "
                        : "") + "Email"
                    }
                    name="toInvoice[emailAddress]"
                    errors={errors}
                    required={true}
                  >
                    <Form.TextInput
                      name="toInvoice[emailAddress]"
                      type="email"
                      value={""}
                      forwardRef={register({ required: true })}
                    />
                  </Form.InputWrapper>
                  <Form.InputWrapper label="Fee" name="fee" errors={errors}>
                    <Form.TextInput
                      name="fee"
                      type="text"
                      forwardRef={register({ required: true })}
                    />
                  </Form.InputWrapper>
                </Form.Fieldset>

                <Form.Fieldset
                  legend={
                    currentEvent.nextStage === "toBeInvoiced" ? "Candidate" : ""
                  }
                  className="rows stacked"
                >
                  {currentEvent.nextStage === "toBeInvoiced" && (
                    <Form.InputWrapper
                      label="Contact"
                      name="candidateId"
                      errors={errors}
                      required={true}
                      className="react-select position-title"
                    >
                      <Select
                        isClearable={true}
                        className="select"
                        ref={register({ name: "candidateId" })}
                        options={contacts}
                        placeholder=""
                        name="candidateId"
                        value={selectValues.candidateId}
                        onChange={onSelectChange}
                      />
                    </Form.InputWrapper>
                  )}

                  <Form.InputWrapper
                    label="Company"
                    name="companyId"
                    errors={errors}
                    required={true}
                    className="react-select position-title"
                  >
                    <Select
                      isClearable={true}
                      className="select"
                      ref={register({ name: "companyId" })}
                      options={positions}
                      placeholder=""
                      name="companyId"
                      value={selectValues.companyId}
                      onChange={onSelectChange}
                    />
                  </Form.InputWrapper>

                  <Form.InputWrapper
                    label="Position"
                    name="positionId"
                    errors={errors}
                    required={true}
                    className="react-select position-title"
                  >
                    <Select
                      isClearable={true}
                      className="select"
                      ref={register({ name: "positionId" })}
                      options={positions}
                      placeholder=""
                      name="positionId"
                      value={selectValues.positionId}
                      onChange={onSelectChange}
                    />
                  </Form.InputWrapper>

                  {currentEvent.nextStage === "toBeInvoiced" ? (
                    <Form.InputWrapper
                      label="Start Date"
                      name="startDate"
                      errors={errors}
                      required={true}
                    >
                      <DateTimePicker
                        time={false}
                        name="startDate"
                        defaultValue={new Date()}
                        ref={register({
                          name: "startDate",
                          value: new Date(),
                          required: true,
                        })}
                      />
                    </Form.InputWrapper>
                  ) : (
                    ""
                  )}
                  <Form.InputWrapper
                    label="Hiring Manager"
                    name="hiringManagerId"
                    errors={errors}
                    required={true}
                    className="react-select position-title"
                  >
                    <Select
                      isClearable={true}
                      className="select"
                      ref={register({ name: "hiringManagerId" })}
                      options={hiringManagers}
                      placeholder=""
                      name="hiringManagerId"
                      value={selectValues.hiringManagerId}
                      onChange={onSelectChange}
                    />
                  </Form.InputWrapper>

                  {currentEvent.nextStage === "toBeInvoiced" ? (
                    <Form.InputWrapper
                      label="Candidate Bonuses or Payouts"
                      name="payouts"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="payouts"
                        type="text"
                        value={""}
                        forwardRef={register}
                      />
                    </Form.InputWrapper>
                  ) : (
                    ""
                  )}
                </Form.Fieldset>

                <Form.FieldsetActions>
                  {loading ? (
                    <LoadingSubmit></LoadingSubmit>
                  ) : (
                    <>
                      <Button type="submit" primary>
                        Send
                      </Button>
                      <Button
                        type="button"
                        onClick={() => setFormInvoiceOpen(false)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Form.FieldsetActions>
              </ol>
            </>
          </form>
        </StyledForm>
      </div>
    </StyledFormInvoiceInfo>
  );
};
