import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Card } from "../../styles/Common";
import { ContentExpandable } from "../Shared/Common";
import { Form } from "../Shared/Form";
import { Input } from "../../styles/Form";

const StyledUpdatableEntity = styled(Card)``;

/*
 * Quick Notes
 */
export const UpdatableEntity = ({ detail, entity, setEntity, Service }) => {
  //react-hook-form
  const { register, getValues } = useForm();
  const [edit, setEdit] = useState(false);

  //edit
  const prepareEdit = async () => {
    await setEdit(true);
    document.getElementById(detail.key).focus();
  };
  //check for ENTER
  const handleKeyDown = e => {
    if (e.key === "Enter" || e.key === "Tab") {
      saveInfo();
    }
  };

  //save to DB
  const saveInfo = async () => {
    let data = getValues();
    //set back to a closed state
    setEdit(false);
    //update db
    data.id = entity.id;
    let response = await Service.update(data);
    //update position on page
    if (response._links) {
      setEntity({ ...entity, ...data });
    }
  };

  return (
    <StyledUpdatableEntity>
      <h1>{detail.title}</h1>
      <ContentExpandable
        enabled={detail.expandable}
        initialHeight="120px"
        buttonSize="small"
        edit={edit || !entity[detail.key]}
      >
        <div className="content">
          {!edit ? (
            <div onClick={() => prepareEdit()}>
              <div>{entity[detail.key]}</div>
              {!entity[detail.key] ? (
                <span className="edit-handle" onClick={() => prepareEdit()}>
                  edit
                </span>
              ) : (
                ""
              )}
            </div>
          ) : (
            <Input className="auto">
              <Form.Textarea
                id={detail.key}
                name={detail.key}
                forwardRef={register}
                value={entity[detail.key]}
                onBlur={() => saveInfo()}
                onKeyDown={e => handleKeyDown(e)}
              />
            </Input>
          )}
        </div>
      </ContentExpandable>
    </StyledUpdatableEntity>
  );
};
