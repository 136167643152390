export function makeLocalUrl(
  path /*: string | (string | string[])[]*/,
  options /*?: { query?: Record<string, string | undefined> }*/
) /*: string*/ {
  const pathString =
    typeof path === "string"
      ? path
      : path
          .map((pathElement, index) =>
            typeof pathElement === "string"
              ? index === 0 && pathElement.startsWith("/")
                ? "/" + encodeURIComponent(pathElement.substr(1))
                : encodeURIComponent(pathElement)
              : pathElement
                  .map(subEl => encodeURIComponent(subEl).replace("~", "%7E"))
                  .join("~")
          )
          .join("/");

  const url = new URL("http://x");

  url.pathname = pathString;

  if (options?.query) {
    for (const [key, value] of Object.entries(options.query)) {
      if (typeof value !== "undefined") {
        url.searchParams.append(key, value);
      }
    }
  }

  return url.href.substr(
    "http://x".length + (1 - Number(pathString.startsWith("/"))) // Include the leading slash if and only if the incoming path did.
  );
}
