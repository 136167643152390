import { list } from "./lib";
import Config from "../config/config";

const create = inputData => {
  return fetch(Config.API_URL + "events", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const update = inputData => {
  return fetch(Config.API_URL + "events/" + inputData.id, {
    method: "PUT",
    body: JSON.stringify({ ...inputData.data }), //error from api if id is sent in body
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const del = id => {
  return fetch(Config.API_URL + "events/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const get = id => {
  return fetch(Config.API_URL + "events/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

/* NOTES */
const getNotes = inputData => {
  return fetch(
    Config.API_URL +
      "events/" +
      inputData.id +
      "/notes?limit=" +
      inputData.limit +
      "&offset=" +
      inputData.offset,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const addNote = inputData => {
  return fetch(Config.API_URL + "events/" + inputData.id + "/notes", {
    method: "POST",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const helloSignCreateUrl = path => {
  return fetch(Config.API_URL + path, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

export const EventService = {
  create,
  del,
  get,
  list: query => list("/events", { query }),
  update,

  // NOTES
  addNote,
  getNotes,

  // HELLOSIGN
  helloSignCreateUrl,
};
