import Config from "../config/config";

const get = inputData => {
  return fetch(
    Config.API_URL +
      "titles/?limit=" +
      (inputData.limit ? inputData.limit : 1000) +
      "&title=" +
      inputData.title,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const create = inputData => {
  return fetch(Config.API_URL + "titles/", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const update = inputData => {
  return fetch(Config.API_URL + "titles/" + inputData.id, {
    method: "PUT",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const del = id => {
  return fetch(Config.API_URL + "titles/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

export const TitleService = {
  get,
  update,
  create,
  del,
};
