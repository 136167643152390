import React, { useState, useEffect, useMemo } from "react";
import { AuthService } from "../../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import { Link, Redirect } from "react-router-dom";
import device from "../../../config/device";
import { useTable, useFilters } from "react-table";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { uniq as _uniq } from "lodash";
import { ExportCompanyPositions } from "../../Shared/Export";
import { ProfileImage } from "../../Shared/ProfileImage";
import { Tags } from "../../Shared/Tags";
import { Activities } from "../../Shared/DetailActivities";
import { DocumentUpload } from "../../Shared/DocumentUpload";
import { CompanyService } from "../../../services/Company";
import { Colors } from "../../../styles/Colors";
import { addToPageHistory } from "../../../helpers/Search";
import { formatAddress, formatCompensation } from "../../../helpers/Display";
import { DateTime } from "../../../helpers/DateTime";
import { DragNDrop } from "../../Shared/DragNDrop";
import { UpdateableInfo } from "../../Shared/UpdateableInfo";

import {
  Container,
  Card,
  ListDl,
  TableEmpty,
  Table,
  ButtonLink,
} from "../../../styles/Common";
import styled from "styled-components";
import imgChevron from "../../../assets/chevron.svg";
import imgLinkedIn from "../../../assets/linkedin.svg";
import imgPhone from "../../../assets/phone.svg";
import imgMail from "../../../assets/mail.svg";
import imgSend from "../../../assets/send.svg";
import imgCopy from "../../../assets/copy.svg";
import imgUserPlus from "../../../assets/user-plus.svg";
import imgViewBoard from "../../../assets/board.svg";
import imgViewTable from "../../../assets/table.svg";
import { toast } from "react-toastify";
import { teamSizesBuild } from "../../Shared/Data";

const Styled = styled.div`
  display: flex;
  &:before {
    content: "";
    display: block;
    height: 276px;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background-image: linear-gradient(0deg, #5f6a7b 0%, #515b69 100%);
    box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
    background: ${Colors.primary};
  }
  @media ${device.laptopLMax} {
    &:before {
      height: auto;
    }
  }

  .main {
    grid-gap: 15px;
    display: grid;
    .section {
      margin-top: 15px;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 33% 1fr;
      grid-gap: 15px;
      @media ${device.tabletLMax} {
        display: block;
      }
    }
    .col-1 {
      display: grid;
      grid-gap: 15px;
      align-content: baseline;
    }
    .col-2 {
      grid-area: 1 / 2 / 2 / 3;
      display: grid;
      grid-gap: 15px;
      align-content: baseline;
    }
    @media ${device.laptopMax} {
      .col-1 {
        margin-bottom: 15px;
      }
    }
  }
`;

const Header = styled.header`
  color: ${Colors.gray};
  display: grid;
  height: 242px;
  margin-bottom: 36px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto auto;
  grid-template-areas:
    "top top top"
    "img name name"
    "img address address"
    "img meta resources"
    "client meta resources";

  @media ${device.laptopLMax} {
    height: auto;
    background: ${Colors.primary};
    grid-template-areas:
      "top top top"
      "img name resources"
      "img address resources"
      "img meta meta"
      "client meta meta";
    margin: 0 -1rem;
    padding: 0 1rem 30px 1rem;
  }

  .back {
    grid-area: top;
    font-weight: 300;
    font-size: 1.143rem;
    height: 51px;
    padding: 11px 0 0;
    a {
      color: ${Colors.gray};
      vertical-align: middle;
    }
    a:before {
      content: "";
      vertical-align: middle;
      width: 14px;
      height: 17px;
      margin-right: 5px;
      vertical-align: middle;
      display: inline-block;
      background: url(${imgChevron}) no-repeat;
      background-size: cover;
      transform: rotate(90deg);
    }
  }

  .company {
    grid-area: name;
    height: 42px;
  }
  .description {
    grid-area: description;
    height: 38px;
    align-self: end;
    font-size: 1.143em;
    max-width: 500px;
  }
  .website {
    display: inline-block;
    height: 20px;
    padding-top: 13px;
    vertical-align: middle;
    span {
      font-size: 0.8571rem;
      display: block;
      height: 100%;
      box-sizing: border-box;
      padding: 2px 15px 0 15px;
      border-radius: 10px;
    }
    a {
      color: ${Colors.gray};
    }
  }
  .meta {
    grid-area: meta;
    dl {
      display: flex;
    }
    dl div {
      margin-right: 45px;
    }
    dt {
      font-weight: 600;
      font-size: 0.857rem;
      display: block;
      margin-bottom: 4px;
    }
    dd {
      font-weight: 700;
      font-size: 1.286rem;
      display: block;
    }
    .markets {
      font-size: 1.1rem;
      max-width: 300px;
      line-height: 1.2em;
    }
    a {
      color: ${Colors.gray};
    }
  }

  .is-client {
    grid-area: client;
    text-transform: uppercase;
    color: #ffffff;
    background: ${Colors.highlight};
    border-radius: 3px;
    padding: 5px 10px;
    display: inline-block;
    text-align: center;
    justify-self: start;
    align-self: baseline;
  }
  h1 {
    font-weight: 300;
    font-size: 2.857rem;
    margin-top: 12px;
    margin-bottom: 12px;
    vertical-align: middle;
    margin-right: 15px;
    display: inline-block;
  }

  h2 {
    grid-area: position;
    font-weight: 500;
    font-size: 1.143rem;
    margin-top: 17px;
    span {
      font-weight: 300;
    }
  }
  .address {
    grid-area: address;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.286rem;
    line-height: 1.286rem;
    a {
      color: ${Colors.gray};
    }
    p {
      margin-top: 12px;
      display: inline-block;
      margin-right: 30px;
    }
    time {
      font-weight: 400;
      color: #a4acb9;
    }
  }

  #resources {
    grid-area: resources;
    text-align: right;
    color: #a4acb9;
    align-self: end;
    font-weight: 600;
    text-align: center;
    ul {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 100;
      ul {
        display: block;
        position: absolute;
        bottom: -75px;
        right: -15px;
        border-radius: 3px;
        background: #fff;
        padding: 0 15px;
        box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);
        border: 1px solid ${Colors.gray_accent};
      }
    }
    li {
      position: relative;
      margin-right: 30px;
      &:last-child {
        margin-right: 15px;
      }
      li {
        display: block;
        text-align: right;
        border-bottom: 1px solid ${Colors.gray_accent};
        margin: 0 !important;
        padding: 5px 0 7px 0;
        &:last-child {
          border-bottom: 0;
        }
        a,
        span {
          display: flex;
          flex-direction: row;
          height: auto;
          font-weight: normal;
          cursor: pointer;
          align-items: center;
          justify-content: flex-end;
          color: ${Colors.primary};
          &:after {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            background: no-repeat;
            background-size: contain;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        &.send {
          a:after {
            background-image: url(${imgSend});
          }
        }
        &.copy {
          span:after {
            background-image: url(${imgCopy});
          }
        }
      }
    }
    li {
      a:before,
      span:before {
        content: "";
        display: block;
        background: no-repeat;
        background-size: contain;
        margin: 0 auto 5px;
      }
      & > a,
      & > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: #a4acb9;
        height: 44px;
      }
      &.email {
        span {
          cursor: default;
        }
        & > a:before,
        & > span:before {
          width: 20px;
          height: 16px;
          background-image: url(${imgMail});
        }
        > span:after {
          content: "";
          width: 8px;
          height: 9px;
          position: absolute;
          bottom: 7px;
          right: -13px;
          background: url(${imgChevron}) no-repeat;
          background-size: cover;
        }
      }
      &.linkedin {
        a:before {
          width: 20px;
          height: 19px;
          background-image: url(${imgLinkedIn});
        }
      }
      &.phone {
        a:before {
          width: 21px;
          height: 21px;
          background-image: url(${imgPhone});
        }
      }
      &.add-contacts {
        a:before {
          width: 20px;
          height: 19px;
          background-image: url(${imgUserPlus});
        }
      }
    }
  }
  @media ${device.laptopLMax} {
    .meta {
      dl {
        display: block;
        column-count: 3;
        div {
          break-inside: avoid;
          page-break-inside: avoid;
          margin-top: 10px;
        }
      }
    }
    #resources {
      align-self: start;
      ul {
        flex-direction: column;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
  @media ${device.laptopMax} {
    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
    .meta {
      dl {
        column-count: 2;
      }
      dd {
        font-size: 1rem;
      }
    }
    #resources {
      margin-top: 10px;
    }
    .company {
      grid-area: name;
      height: auto;
    }
  }
  @media ${device.tabletSMax} {
    grid-template-areas:
      "top top top"
      "img name name"
      "resources address address"
      "resources meta meta"
      "client meta meta";
    .meta {
      dl {
        column-count: 1;
      }
    }
  }
`;

const Contacts = styled(Card)`
  h2 {
    font-weight: 600;
  }
  li {
    margin-bottom: 15px;
    line-height: 20px;
  }
  .name {
    display: inline-block;
    margin-bottom: 3px;
  }
`;

const Stats = styled(Card)``;

const WeeklyDashboard = styled(Card)`
  .view {
    font-weight: 600;
    ul {
      display: grid;
      grid-template-columns: auto auto auto auto 1fr;
      align-items: center;
      margin-bottom: 10px;
    }
    li {
      &.icon {
        margin: 0 0 0 15px;
        overflow: hidden;
        width: 20px;
        height: 20px;
        text-indent: -6000px;
        background-size: cover;
      }
      &.view-columns {
        background-image: url(${imgViewBoard});
      }
      &.view-table {
        background-image: url(${imgViewTable});
      }
      &.active {
        background-position: 0 -60px;
      }
      &.my-positions {
        margin: 0 30px 0 60px;
      }
      &.export {
        text-align: right;
      }
    }
  }
  .position {
    & > .header {
      display: flex;
      justify-content: space-between;
      background: #8792a3;
      color: #fff;
      align-items: center;
      height: 40px;
      padding: 0 0 0 15px;
      margin-bottom: 1px;
      a {
        color: ${Colors.gray};
      }
      h2 {
        width: 20%;
        margin-right: 30px;
        a {
          color: #fff;
        }
      }
      .handle {
        vertical-align: middle;
        width: 28px;
        height: 18px;
        margin-right: 10px;
        margin-bottom: 7px;
        vertical-align: middle;
        display: inline-block;
        background: url(${imgChevron}) no-repeat;
        background-size: cover;
        transform: rotate(180deg);
        background-position: 0 -18px;
      }

      dl {
        display: flex;
        width: 75%;
        div {
          display: flex;
        }
        dt {
          margin: 0 5px 0 30px;
          &:after {
            content: ":";
            display: inline;
          }
        }
        dd {
          margin: 0 30px 0 0;
        }
      }
    }
    &.visible {
      .handle {
        transform: rotate(0);
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
  }
  @media ${device.tabletLMax} {
    display: none;
  }
`;

const StyledOpenPositions = styled(Card)`
  margin-bottom: 15px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
    }
    .week-filter {
      color: #8792a3;
      vertical-align: middle;
      margin-right: 15px;
    }
  }
  .prev-week {
    font-weight: 500;
    font-size: 0.7rem;
    margin: 0 0 0 5px;
  }
  .reduced {
    color: ${Colors.error};
    &:before {
      content: "-";
    }
  }
  .added {
    color: #3eb509;
    &:before {
      content: "+";
    }
  }
`;

export default function CompanyDetail(props) {
  const [isLoggedIn] = useStore("isLoggedIn", AuthService.verifyLogin());
  const jwtInfo = AuthService.jwtInfo();
  //console.log("jwt", jwtInfo);

  //get company
  const [company, setCompany] = useState(null);
  const [positions, setPositions] = useState([]);

  // company ********************************
  const prepareCompany = async () => {
    let _company = await CompanyService.get(props.match.params.id);
    _company.id = props.match.params.id;

    if (_company.error || _company.errors) {
      props.history.push("/bd/companies");
    } else {
      //format display dates
      _company.createdAtFormatted = DateTime.formatDate(
        new Date(_company.createdAt)
      );

      //so linkedIn wont break layout
      _company.linkedinShortened = _company.linkedinUrl
        ? _company.linkedinUrl.replace(/https?:\/\/www\.linkedin\.com\/in/, "")
        : "";

      //fill in missing data for Info list
      /* ["size", "phone", "website", "name"].forEach(key => {
        if (!_company[key]) {
          _company[key] = "";
        }
      });*/

      //address... turn it flat for consistent updating purposes
      if (_company.address) {
        Object.entries(_company.address).forEach(obj => {
          _company[obj[0]] = obj[1];
        });
      }

      //isClient
      _company.isClientDisplay = _company.isClient ? "Yes" : "No";

      //markets / specialties / tech
      _company.markets = _company.markets ? _company.markets : [];
      _company.submarkets = _company.submarkets ? _company.submarkets : [];
      _company.technologies = _company.technologies
        ? _company.technologies
        : [];

      //save into search history
      addToPageHistory({
        title: _company.name,
        info: "Available",
        url: "/client/" + _company.id,
      });
      console.log("_company", _company);
      setCompany(_company);
    }
  };

  //get company Positions
  const getPositions = async () => {
    console.log("pos....");
    let _positions = await CompanyService.getPositions({
      id: props.match.params.id,
      limit: 100,
    });
    console.log("pos:init", _positions._embedded);

    if (_positions._embedded) {
      let positionBuild = _positions._embedded.map((pos, i) => {
        pos.visible = i === 0 ? true : false;
        //weed out possible stage
        if (pos.stages) {
          pos.stages = pos.stages.filter(
            stage => stage.name !== "Possible" && stage.name !== "Short List"
          );
        }
        pos.activeStages = [];
        if (pos.candidates) {
          console.log("cand", pos.candidates);
          pos.candidates = pos.candidates
            .filter(
              c => c.stage.name !== "Possible" && c.stage.name !== "Short List"
            )
            .map(c => {
              pos.activeStages.push(c.stage);

              return {
                id: c.candidateId,
                name: c.firstName + " " + c.lastName,
                stage: c.stage,
                compensation: c.compensation
                  ? formatCompensation(c.compensation, "short")
                  : "",
                interviews: c.interviews,
                rejectionReason: c.rejectionReason,
                /*compensation: "$168k",
              visa: "H1b w/ I140",
              relocation: "Relocate",
              interviewStatus: "",
              date: "",
              actionNotes: "action notes"*/
              };
            });
        } else {
          pos.candidates = [];
        }

        pos.activeStages = _uniq(pos.activeStages);

        return pos;
      });

      if (_positions.history) {
        positionBuild.history = _positions.history;
      }
      setPositions(positionBuild);
      console.log("positionBuild", positionBuild);
    }
  };
  useEffect(() => {
    prepareCompany();
    getPositions();
  }, []);

  //filter by my position or recently uploaded
  const filterPositions = () => {};
  const positionVisibility = position => {
    position.visible = !position.visible;
    setPositions([...positions]);
  };
  //email resource click
  const [openEmailOptions, setOpenEmailOptions] = useState(false);
  const handleEmailOpen = () => {
    if (!openEmailOptions) {
      document.addEventListener("click", removeEmailOpen);
      setOpenEmailOptions(true);
    }
  };
  const removeEmailOpen = () => {
    document.removeEventListener("click", removeEmailOpen);
    setOpenEmailOptions(false);
  };

  //Dashboard candidates //TODO... array should be organized by position

  //dashboard view
  const [dashboardView, setDashboardView] = useState("columns");

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <Styled>
      {company && (
        <Container>
          <section>
            <Header>
              <div className="company">
                <h1>{company.name}</h1>
                {company.website && (
                  <p className="website">
                    <span>
                      <a
                        target="_mullings"
                        href={
                          company.website.match(/http/)
                            ? company.website
                            : "http://" + company.website
                        }
                      >
                        {company.website.replace(/https?:\/\//, "")}
                      </a>
                    </span>
                  </p>
                )}
              </div>

              <p className="back">
                <a href="#" onClick={() => props.history.goBack()}>
                  Back
                </a>
              </p>

              <ProfileImage
                detail={{
                  type: "companies",
                  uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                }}
                entity={company}
                setEntity={setCompany}
              />
              {company.isClient ? <div className="is-client">Client</div> : ""}

              {formatAddress(
                company.city,
                company.state,
                company.country,
                false, //TODO: display time based on geo data from DB
                true
              )}

              <address id="resources">
                <ul>
                  {company.linkedinUrl && (
                    <li className="linkedin">
                      <a
                        href={company.linkedinUrl}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </li>
                  )}
                  {company.phone && (
                    <li className="phone">
                      <a href={"tel:" + company.phone.replace(/[()\s-]+/g, "")}>
                        {company.phone}
                      </a>
                    </li>
                  )}
                  <li className="add-contacts">
                    <Link
                      to={
                        "/bd/search/filter:candidates/companyId:" + company.id
                      }
                    >
                      Add Employees
                    </Link>
                  </li>
                  {company.email && (
                    <li className="email" onClick={() => handleEmailOpen()}>
                      <span>Email</span>
                      {openEmailOptions && (
                        <ul>
                          <li className="send">
                            <a href={"mailto:" + company.email}>Send Email</a>
                          </li>
                          <li
                            className="copy"
                            onClick={() => {
                              navigator.clipboard.writeText(company.email);
                              toast.success("Email address copied");
                            }}
                          >
                            <span id="email-address">{company.email}</span>
                          </li>
                        </ul>
                      )}
                    </li>
                  )}
                </ul>
              </address>

              <div className="meta">
                <dl>
                  {company.parentCompany && (
                    <div>
                      <dt>Parent Company</dt>
                      <dd>
                        <a href={"/client/" + company.parentCompany.companyId}>
                          {company.parentCompany.name}
                        </a>
                      </dd>
                    </div>
                  )}

                  {company.markets && company.markets.length ? (
                    <div className="markets">
                      <dt>Markets</dt>
                      <dd>
                        {company.markets.map((m, i) => {
                          return (
                            m.market +
                            (company.markets.length - 1 > i ? ", " : "")
                          );
                        })}
                      </dd>
                    </div>
                  ) : (
                    ""
                  )}
                  {company.submarkets && company.submarkets.length ? (
                    <div className="specialties">
                      <dt>Submarkets</dt>
                      <dd>
                        {company.submarkets.map((m, i) => {
                          return (
                            m.specialty +
                            (company.submarkets.length - 1 > i ? ", " : "")
                          );
                        })}
                      </dd>
                    </div>
                  ) : (
                    ""
                  )}
                  {company.technologies && company.technologies.length ? (
                    <div className="technologies">
                      <dt>Technologies</dt>
                      <dd>
                        {company.technologies.map((m, i) => {
                          return (
                            m.technology +
                            (company.technologies.length - 1 > i ? ", " : "")
                          );
                        })}
                      </dd>
                    </div>
                  ) : (
                    ""
                  )}
                  {company.createdAt && (
                    <div>
                      <dt>Last Activity</dt>
                      <dd>
                        {new Intl.DateTimeFormat("en-US", {
                          month: "long",
                          year: "numeric",
                        }).format(new Date(company.updatedAt))}
                      </dd>
                    </div>
                  )}
                </dl>
              </div>
            </Header>

            <div className="main">
              <div className="section">
                <div className="col-1">
                  {positions && positions.length ? (
                    <Stats className="card">
                      <h1>Stats</h1>
                      <div className="content">
                        <ListDl>
                          <div>
                            <dt>Active Positions</dt>
                            <dd>{company.positionStats.positions}</dd>
                          </div>
                          <div>
                            <dt>Closed Positions</dt>
                            <dd>{company.positionStats.closed}</dd>
                          </div>
                          <div>
                            <dt>Candidates Hired</dt>
                            <dd>{company.positionStats.placed}</dd>
                          </div>
                          <div>
                            <dt>Active Hires</dt>
                            <dd>{company.positionStats.open}</dd>
                          </div>
                        </ListDl>
                      </div>
                    </Stats>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-2">
                  <OpenPositions company={company} positions={positions} />
                </div>
              </div>

              {company.isClient && positions && positions.length ? (
                <WeeklyDashboard className="card">
                  <h1>Weekly Dashboard</h1>

                  <div className="view">
                    <ul>
                      <li
                        className={
                          "icon view-columns" +
                          (dashboardView === "columns" ? " active" : "")
                        }
                        onClick={() => setDashboardView("columns")}
                      >
                        Columns
                      </li>
                      <li
                        className={
                          "icon view-table" +
                          (dashboardView === "table" ? " active" : "")
                        }
                        onClick={() => setDashboardView("table")}
                      >
                        Table
                      </li>
                      <li
                        className="filter my-positions"
                        onClick={() => filterPositions("myPositions")}
                      >
                        {/*Current Searches*/} {/*Only My Positions*/}
                      </li>
                      <li className="filter updated">{/*Recently Updated*/}</li>
                      <li className="export">
                        <ExportCompanyPositions
                          company={company}
                          positions={positions}
                        />
                      </li>
                    </ul>
                  </div>

                  {positions && positions.length ? (
                    positions.map(position => {
                      return (
                        <div
                          className={
                            "position" + (position.visible ? " visible" : "")
                          }
                          key={position.id}
                        >
                          <div className="header">
                            <h2>
                              <Link to={"/position/" + position.id}>
                                {position.title}
                              </Link>
                            </h2>
                            <dl>
                              <div className="hiringManagers">
                                <dt>
                                  Hiring Manager
                                  {position.hiringManagers &&
                                  position.hiringManagers.length > 1
                                    ? "s"
                                    : ""}
                                </dt>
                                <dd>
                                  {position.hiringManagers &&
                                    position.hiringManagers.map((hm, i) => {
                                      return (
                                        <span key={i}>
                                          <Link to={"/candidate/" + hm.id}>
                                            {hm.firstName} {hm.lastName}
                                          </Link>
                                          {i <
                                          position.hiringManagers.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      );
                                    })}
                                </dd>
                              </div>
                              <div className="owner">
                                <dt>Owner</dt>
                                <dd>
                                  {position.ownerInfo
                                    ? position.ownerInfo.lastName +
                                      ", " +
                                      position.ownerInfo.firstName
                                    : ""}
                                </dd>
                              </div>
                              <div className="total">
                                <dt>Total</dt>
                                <dd>{position.candidates.length}</dd>
                              </div>
                            </dl>
                            <span
                              className="handle"
                              onClick={() => positionVisibility(position)}
                            ></span>
                          </div>

                          {dashboardView === "table" && position.visible && (
                            <RenderTable candidates={position.candidates} />
                          )}
                          {dashboardView === "columns" && position.visible && (
                            <DragNDrop
                              candidates={position.candidates}
                              stages={position.stages}
                              positionId={position.id}
                              position={position}
                            />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="content">No positions found</div>
                  )}
                </WeeklyDashboard>
              ) : (
                ""
              )}

              <div className="section">
                <div className="col-1">
                  <Tags
                    entity={company}
                    setEntity={setCompany}
                    Service={CompanyService}
                    detail={{
                      typePlural: "markets",
                      typeDisplay: "markets",
                      type: "market",
                      title: "Markets",
                    }}
                  />
                  <Tags
                    entity={company}
                    setEntity={setCompany}
                    Service={CompanyService}
                    detail={{
                      typePlural: "submarkets",
                      typeDisplay: "submarkets",
                      type: "submarket",
                      title: "Submarkets",
                    }}
                  />
                  <Tags
                    entity={company}
                    setEntity={setCompany}
                    Service={CompanyService}
                    detail={{
                      typePlural: "technologies",
                      typeDisplay: "technologies",
                      type: "technology",
                      title: "Technologies",
                    }}
                  />
                  <Info
                    company={company}
                    setCompany={setCompany}
                    jwtInfo={jwtInfo}
                  />

                  {company.employees && company.employees.length ? (
                    <Contacts>
                      <h1>Employees</h1>
                      <div className="content">
                        <ul>
                          {company.employees.map((contact, i) => (
                            <li key={i}>
                              <h2>{contact.title}</h2>
                              <span className="name">
                                <Link to={"/candidate/" + contact.candidateId}>
                                  {contact.firstName} {contact.lastName}{" "}
                                </Link>
                              </span>
                              <br />
                              {contact.emailPersonal || contact.emailWork ? (
                                <>
                                  <a
                                    href={
                                      "mailto:" +
                                      (contact.emailPersonal
                                        ? contact.emailPersonal
                                        : contact.emailWork)
                                    }
                                    className="email"
                                  >
                                    {contact.emailPersonal
                                      ? contact.emailPersonal
                                      : contact.emailWork}
                                  </a>
                                  <br />
                                </>
                              ) : (
                                ""
                              )}
                              {contact.phone ? (
                                <>
                                  <a
                                    href={
                                      "tel:" +
                                      contact.phone.replace(/[()\s-]+/g, "")
                                    }
                                    className="phone"
                                  >
                                    {contact.phone}
                                  </a>
                                  <br />
                                </>
                              ) : (
                                ""
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Contacts>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-2">
                  <Activities
                    detail={{ type: "Company" }}
                    entity={company}
                    Service={CompanyService}
                  />
                  <DocumentUpload
                    detail={{
                      type: "companies",
                      uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                    }}
                    entity={company}
                    setEntity={setCompany}
                    Service={CompanyService}
                  />
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}
    </Styled>
  );
}

const RenderTable = ({ candidates }) => {
  console.log(candidates);
  //table columns
  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Candidate",
        accessor: "candidate",
      },
      {
        Header: "Visa",
        accessor: "visa",
      },
      {
        Header: "Compensation",
        accessor: "compensation",
      },
      {
        Header: "Relocation",
        accessor: "relocation",
      },
      {
        Header: "Interview Status",
        accessor: "interviewStatus",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Actions/Notes",
        accessor: "actionNotes",
      },
    ],
    []
  );

  const data = [];
  candidates &&
    candidates.forEach(i => {
      data.push({
        id: i.id,
        candidate: i.name,
        visa: i.visa,
        compensation: i.compensation,
        relocation: i.relocation,
        interviewStatus: i.interviewStatus,
        date: i.date,
        actionNotes: i.actionNotes,
      });
    });

  // Use the state and functions returned from useTable to build your UI
  const {
    headerGroups,
    rows,
    prepareRow,
    state: {},
  } = useTable(
    {
      columns,
      data: useMemo(() => data, []),
      initialState: { hiddenColumns: "id" },
    },
    useFilters
  );

  return (
    <>
      {candidates.length ? (
        <Table className="table">
          <table>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr key={Math.random()}>
                  {headerGroup.headers.map(column => (
                    // eslint-disable-next-line react/jsx-key
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr key={Math.random()}>
                    {row.cells.map(cell => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td {...cell.getCellProps()}>
                          {cell.column.id === "candidate" ? (
                            <Link to={"/candidate/" + row.values.id}>
                              {cell.render("Cell")}
                            </Link>
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Table>
      ) : (
        <TableEmpty>
          <p>There were no candidates found</p>
        </TableEmpty>
      )}
    </>
  );
};

/* OPEN POSITIONS */
const OpenPositions = ({ company, positions }) => {
  const [openPositions, setOpenPositions] = useState();
  const getOpenPositions = async () => {
    /*
    let _company = await CompanyService.get(props.match.params.id);
    _company.id = props.match.params.id;
    _company.currentStage = Math.ceil(Math.random() * 5); //TODO - make sure this is pulled in api response

    console.log("company", _company);
    if (_company.errors) {
      props.history.push("/companies");
    }
    setOpenPositions(_company);
    */
  };

  console.log("POSITIONS HISTSORY", positions.history);

  useEffect(() => {
    getOpenPositions();
  }, []);

  if (positions.history && positions.history.rows.length) {
    positions.history.rows.forEach((row, i) => {
      console.log("last week", positions.history.data[i][0]);
      console.log("this week", positions.history.data[i][1]);
      //set totals here
      positions.history.rows[i].totals = 0;
      positions.history.rows[i].prevTotals = 0;

      positions.history.data[i].forEach((week, e) => {
        //positions.history.details[e].label;
        console.log("week", positions.history.details[e].label);

        week.forEach((numInStage, p) => {
          console.log(
            "stage",
            positions.history.columns[p].label + " has: " + numInStage
          );
          switch (
            positions.history.columns[p].label // //Telephone|F2F|Onsite|Reference|On Hold
          ) {
            case "Presented":
              if (e === 1 || positions.history.details.length === 1) {
                //if there is only 1 week, there will be no prev
                positions.history.rows[i].presented = positions.history.rows[i]
                  .presented
                  ? positions.history.rows[i].presented + numInStage
                  : numInStage;
                positions.history.rows[i].totals =
                  positions.history.rows[i].totals + numInStage;
              } else {
                positions.history.rows[i].prevPresented = positions.history
                  .rows[i].prevPresented
                  ? positions.history.rows[i].prevPresented + numInStage
                  : numInStage;
                positions.history.rows[i].prevTotals =
                  positions.history.rows[i].prevTotals + numInStage;
              }
              break;
            case "Telephone":
            case "F2F":
            case "Onsite":
            case "Reference Checking":
            case "Offer":
            case "Offer Conversations":
              if (e === 1 || positions.history.details.length === 1) {
                positions.history.rows[i].inProcess = positions.history.rows[i]
                  .inProcess
                  ? positions.history.rows[i].inProcess + numInStage
                  : numInStage;
                positions.history.rows[i].totals =
                  positions.history.rows[i].totals + numInStage;
              } else {
                positions.history.rows[i].prevInProcess = positions.history
                  .rows[i].prevInProcess
                  ? positions.history.rows[i].prevInProcess + numInStage
                  : numInStage;
                positions.history.rows[i].prevTotals =
                  positions.history.rows[i].prevTotals + numInStage;
              }
              break;
            case "Passed":
              if (e === 1 || positions.history.details.length === 1) {
                positions.history.rows[i].passed = positions.history.rows[i]
                  .passed
                  ? positions.history.rows[i].passed + numInStage
                  : numInStage;
                positions.history.rows[i].totals =
                  positions.history.rows[i].totals + numInStage;
              } else {
                positions.history.rows[i].prevPassed = positions.history.rows[i]
                  .prevPassed
                  ? positions.history.rows[i].prevPassed + numInStage
                  : numInStage;
                positions.history.rows[i].prevTotals =
                  positions.history.rows[i].prevTotals + numInStage;
              }
              break;
            default:
              break;
          }
        });
      });

      /* */
      console.log("ROWS", positions.history.rows);
    });
  }

  return (
    <>
      {positions.history && positions.history.rows.length ? (
        <StyledOpenPositions className="card">
          <div className="header">
            <h1>Open Positions</h1>

            <span className="week-filter">This Week vs Last Week</span>
          </div>

          <Table className="table">
            <table>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Presented</th>
                  <th>In Process</th>
                  <th>Passed</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {positions.history.rows.map((position, i) => {
                  /*
                  let numPresented = p.activeStages.filter(s =>
                    s.match(/Presented/)
                  ).length;
                  let numInProcess = p.activeStages.filter(s =>
                    s.match(/Telephone|F2F|Onsite|Reference|On Hold/)
                  ).length;
                  let numPassed = p.activeStages.filter(s => s.match(/Passed/))
                    .length;
                    */
                  return (
                    <tr key={i}>
                      <td>
                        <div className="wrap">
                          <div className="m">Position</div>
                          <div className="render">
                            <Link to={"/position/" + position.value}>
                              {position.label}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">Presented</div>
                          <div className="render">
                            {position.presented ? position.presented : 0}
                            {position.prevPresented &&
                            position.prevPresented ? (
                              <span
                                className={
                                  "prev-week" +
                                  (position.prevPresented &&
                                  position.prevPresented > position.presented
                                    ? " added"
                                    : " reduced")
                                }
                              >
                                {position.prevPresented}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">In Process</div>
                          <div className="render">
                            {position.inProcess ? position.inProcess : 0}
                            {position.prevInProcess &&
                            position.prevInProcess ? (
                              <span
                                className={
                                  "prev-week" +
                                  (position.prevInProcess &&
                                  position.prevInProcess > position.inProcess
                                    ? " added"
                                    : " reduced")
                                }
                              >
                                {position.prevInProcess}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">Passed</div>
                          <div className="render">
                            {position.passed ? position.passed : 0}
                            {position.prevPassed && position.prevPassed ? (
                              <span
                                className={
                                  "prev-week" +
                                  (position.prevPassed &&
                                  position.prevPassed > position.passed
                                    ? " added"
                                    : " reduced")
                                }
                              >
                                {position.prevPassed}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">Total</div>
                          <div className="render">
                            {position.totals ? position.totals : 0}
                            {position.prevTotals && position.prevTotals ? (
                              <span
                                className={
                                  "prev-week" +
                                  (position.prevTotals &&
                                  position.prevTotals > position.totals
                                    ? " added"
                                    : " reduced")
                                }
                              >
                                {position.prevTotals}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Table>
        </StyledOpenPositions>
      ) : (
        ""
      )}
    </>
  );
};

const Info = ({ company, setCompany, jwtInfo }) => {
  const [parentCompanies, setParentCompanies] = useState([]);

  useEffect(() => {
    const getParentCompanies = async () => {
      let _companies = await CompanyService.list({ limit: 10000 });
      console.log("_companies", _companies);
      if (_companies && _companies._embedded) {
        setParentCompanies(
          _companies._embedded
            .filter(pc => pc.companyId !== company.id)
            .map(c => {
              return {
                value: c.companyId,
                label: c.name,
                data: c.companyId, //needed when creating new employment
              };
            })
        );
      }
    };
    getParentCompanies();
  }, []);
  const info = [
    {
      label: "Name",
      value: "name",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Parent Company",
      value: "parentCompany[name]",
      type: "select",
      edit: false,
      saving: false,
      editable: true,
      options: parentCompanies,
      data: parentCompanies,
      admin: jwtInfo.roles.includes("Admin"),
    },
    {
      label: "Client",
      value: "isClientDisplay",
      type: "select",
      options: [
        { label: "No", value: "No" },
        { label: "Yes", value: "Yes" },
      ],
      edit: false,
      saving: false,
      editable: jwtInfo.roles.includes("Admin") ? true : false,
    },
    {
      label: "City",
      value: "city",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "State",
      value: "state",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Zip",
      value: "zip",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Country",
      value: "country",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Website",
      value: "website",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "LinkedIn",
      value: "linkedinShortened",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Phone",
      value: "phone",
      edit: false,
      saving: false,
      editable: true,
      type: "phone",
    },
    {
      label: "Team Size",
      value: "size",
      edit: false,
      editable: true,
      type: "select",
      saving: false,
      options: teamSizesBuild,
    },
  ];

  return (
    <>
      {parentCompanies.length ? (
        <UpdateableInfo
          info={info}
          detail={{ title: "Info", type: "company" }}
          entity={company}
          setEntity={setCompany}
          Service={CompanyService}
        />
      ) : (
        ""
      )}
    </>
  );
};
