import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { AuthService } from "../../services/Auth";
import { DocumentService } from "../../services/Document";
import {
  Table,
  Card,
  Button,
  ButtonDropdown,
  ButtonClose,
} from "../../styles/Common";
import { Dropdown } from "../Shared/Common";
import { parseResumeData } from "../../helpers/Parser";
import { DateTime } from "../../helpers/DateTime";
import { LoadingSubmit } from "../../styles/Animation";
import imgX from "../../assets/x.svg";
import { ParsedData } from "./ParsedData";

import { toast } from "react-toastify";

const StyledDocumentUpload = styled(Card)``;

/*
 * Quick Notes
 */
export const DocumentUpload = ({ detail, entity, setEntity, Service }) => {
  const jwtInfo = AuthService.jwtInfo();

  //react-hook-form
  const { register, getValues, setValue } = useForm();
  const [edit, setEdit] = useState(false);

  //upload file
  const [fileUploading, setFileUploading] = useState(false);
  const [parsedResumeData, setParsedResumeData] = useState(false);
  const uploadFile = async e => {
    setFileUploading(true);

    let file = e.target.files[0];
    console.log("file", file);

    for (let file of e.target.files) {
      if (documentType !== "resume") {
        //process via api
        let response = await DocumentService.create({
          type:
            detail.type === "contacts"
              ? "candidates"
              : detail.type === "events"
              ? "companies"
              : detail.type,
          id: entity.id,
          file: file,
          documentType: documentType,
        });

        if (response.id) {
          let _file = {
            documentId: response.id,
            url: response._embedded.url,
            name: response._embedded.fileName,
            createdAt: new Date().getTime(),
            uploadedBy: response._embedded.uploadedBy,
            documentType: documentType,
          };
          entity.documents.push(_file);
          //set resume for candidate if necessary
        } else {
          toast.error(response.error, { position: "bottom-center" });
        }
        //TODO: maybe set up toastify
        console.log("entity", entity);
      } else {
        //give option to overwrite current entries

        //scrape resume parser
        //START LOADER

        //GET PARSE INFO
        file.documentType = "resume";
        let parseResponse = await DocumentService.resumeParser(file);
        if (parseResponse) {
          let parsedData = parseResumeData(parseResponse._embedded);

          if (!parseResponse.error) {
            let _file = {
              documentId: parseResponse.id,
              name: file.name,
              createdAt: new Date().getTime(),
              uploadedBy: jwtInfo.lastName + ", " + jwtInfo.firstName,
              documentType: "resume",
            };
            entity.documents.push(_file);
            entity.resume = _file;
            console.log("parseResponse", parseResponse);

            let resumeResponse = await Service.associateCandidateDocument({
              type: "candidates",
              id: entity.id,
              documentId: parseResponse.id,
            });

            setParsedResumeData(parsedData);
          }
        }
      }
    }

    setEntity({ ...entity });
    setFileUploading(false);
  };
  const [documentType, setDocumentType] = useState("generic");
  const [documentTypeOptions, setDocumentTypeOptions] = useState([
    { label: "Generic File", value: "generic", checked: false },
    { label: "Resume", value: "resume", checked: false },
  ]);
  const onTypeClick = type => {
    setDocumentType(type);
    let button = document.getElementById("fileToUpload");
    //only allow multiple on generic files
    button.multiple = type === "resume" ? false : true;
    button.click();
  };

  const deleteFile = async doc => {
    DocumentService.del(doc.documentId);

    if (doc.documentType === "resume") {
      entity.resume = undefined;
    }
    //update candidate table as well if deleting current profile pic
    if (doc.url === entity.profilePicture) {
      Service.update({ profilePicture: "", id: entity.id });
      entity.profilePicture = undefined;
    }
    setEntity({
      ...entity,
      documents: entity.documents.filter(d => d.documentId !== doc.documentId),
    });
  };

  return (
    <>
      <StyledDocumentUpload className="card">
        <h1>Files</h1>

        {entity.documents && entity.documents.length ? (
          <Table className="table">
            <table>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Uploaded By</th>
                  <th>Uploaded Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {entity.documents.map((doc, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className="wrap">
                          <div className="m">File Name</div>
                          <div className="render">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                DocumentService.getUrl(doc.documentId)
                              }
                            >
                              {doc.name}{" "}
                              {doc.documentType === "profile_picture"
                                ? "(profile picture)"
                                : ""}
                              {doc.documentType === "resume" ? "(resume)" : ""}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">Uploaded By</div>
                          <div className="render">{doc.uploadedBy || ""}</div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">Uploaded Date</div>
                          <div className="render">
                            {DateTime.formatDate(new Date(doc.createdAt))}
                          </div>
                        </div>
                      </td>
                      <td>
                        <ButtonClose
                          type="button"
                          onClick={() => deleteFile(doc)}
                        >
                          <img src={imgX} alt="Delete" title="Delete" />
                        </ButtonClose>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Table>
        ) : (
          ""
        )}

        <div className="content">
          <input
            type="file"
            id="fileToUpload"
            multiple
            style={{ display: "none" }}
            onChange={e => uploadFile(e)}
          />
          {!fileUploading ? (
            <>
              {detail.type === "candidates" ? (
                <Dropdown>
                  <ButtonDropdown size="small">Upload File</ButtonDropdown>
                  <div className="list">
                    <ul>
                      {documentTypeOptions.map((o, i) => (
                        <li key={i} onClick={() => onTypeClick(o.value)}>
                          {o.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Dropdown>
              ) : (
                <Button
                  size="small"
                  onClick={() => {
                    document.getElementById("fileToUpload").click();
                  }}
                >
                  Upload File
                </Button>
              )}
            </>
          ) : (
            <LoadingSubmit size={"20"}></LoadingSubmit>
          )}
        </div>
      </StyledDocumentUpload>

      {parsedResumeData ? (
        <ParsedData
          medium={"resume"}
          parsedData={parsedResumeData}
          entity={entity}
          setEntity={setEntity}
          Service={Service}
        />
      ) : (
        ""
      )}
    </>
  );
};
