import Config from "../config/config";
import { AuthService } from "./Auth";

const get = inputData => {
  const jwtInfo = AuthService.jwtInfo();
  return fetch(Config.API_URL + "notifications/" + jwtInfo.user, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const update = inputData => {
  return fetch(Config.API_URL + "notifications/" + inputData.id + "/", {
    method: "PUT",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

export const NotificationService = {
  get,
  update,
};
