import React from "react";
import PropTypes from "prop-types";
import {
  Input as StyledInput,
  FormError as StyledFormError,
  CheckboxRadio as StyledCheckboxRadio,
} from "../../styles/Form";
import { Colors } from "../../styles/Colors";
import styled from "styled-components";
import imgChecked from "../../assets/check.svg";
import { get as _get } from "lodash";

const Fieldset = props => {
  return (
    <li className={props.className} style={props.style}>
      <fieldset>
        {props.legend && <legend>{props.legend}</legend>}
        <ol>{props.children}</ol>
        {props.fieldsetActions}
      </fieldset>
    </li>
  );
};

const FieldsetActions = props => {
  return (
    <li className="actions">
      <fieldset>{props.children}</fieldset>
    </li>
  );
};

const InputWrapper = props => {
  let _error = _get(props.errors, props.name);
  return (
    <li
      style={props.style ? props.style : {}}
      className={
        (props.className ? props.className : "") + (_error ? " error" : "")
      }
    >
      {props.label && (
        <div className="title">
          <label htmlFor={props.name}>
            {props.label}
            {props.required ? <abbr title="Required">*</abbr> : ""}
          </label>
          {_error && <InputError message={_error.message} />}
        </div>
      )}
      <StyledInput className="input">{props.children}</StyledInput>
      {props.notes ? <small>{props.notes}</small> : ""}
    </li>
  );
};

const TextInput = props => {
  return (
    <input
      id={props.name}
      className={props.className}
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      defaultValue={props.value}
      value={props.controlledValue}
      ref={props.forwardRef}
      onChange={props.onChange}
      onBlur={props.onBlur}
      autoFocus={props.focus ? true : false}
      autoComplete={props.autoComplete ? "Off" : "On"}
      onKeyDown={props.onKeyDown}
      min={props.min}
      max={props.max}
      step={props.step}
    />
  );
};
const Textarea = props => {
  return (
    <textarea
      id={props.name}
      name={props.name}
      className={props.className}
      placeholder={props.placeholder}
      defaultValue={props.value}
      ref={props.forwardRef}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
    />
  );
};
const RadioButtons = props => {
  return (
    <ul>
      {props.options.map((option, i) => (
        <li key={i} className={option.checked ? "active" : ""}>
          <RadioButton
            forwardRef={props.forwardRef}
            name={props.name}
            option={option}
            id={i}
            options={props.options}
            setOptions={props.setOptions}
            onChange={props.onChange}
          />
        </li>
      ))}
    </ul>
  );
};

const RadioButton = props => {
  //console.log(props);

  const overlayClicked = e => {
    if (e.target.tagName === "SPAN") {
      let input = e.target.children[0].children[0]; //get input elem
      if (input && !input.readOnly) {
        input.click();
        if (props.options) {
          props.options.map(i => {
            i.checked = false;
            if (i.value === input.value) {
              i.checked = true;
            }
          });
        }
        if (props.setOptions) {
          props.setOptions([...props.options]);
        }
      }
    }
  };
  return (
    <StyledCheckboxRadio
      type="radio"
      className={
        "radio " +
        props.name +
        (props.option.checked ? " checked" : "") +
        (props.option.indeterminate ? " indeterminate" : "")
      }
      onClick={overlayClicked}
    >
      <span className="box">
        <input
          id={props.name + "-" + props.id}
          ref={props.forwardRef}
          type="radio"
          name={props.name}
          onChange={props.onChange}
          onClick={props.onClick}
          value={props.option.value}
          defaultChecked={props.option.checked}
        />
      </span>
      {props.option.label && (
        <label htmlFor={props.name + "-" + props.id}>
          {props.option.label}
        </label>
      )}
    </StyledCheckboxRadio>
  );
};

const Checkboxes = props => {
  return (
    <ul>
      {props.options.map((option, i) => (
        <li key={i}>
          <Checkbox
            forwardRef={props.forwardRef}
            name={props.name}
            option={option}
            id={i}
            options={props.options}
            setOptions={props.setOptions}
            onChange={props.onChange}
          />
        </li>
      ))}
    </ul>
  );
};
const Checkbox = props => {
  const overlayClicked = async e => {
    if (e.target.tagName === "SPAN") {
      let input = e.target.children[0].children[0]; //get input elem

      if (input && !input.readOnly) {
        input.click();
        if (props.options) {
          props.options.map(i => {
            if (i.value == input.value) {
              i.checked = !i.checked;
            }
          });
        }
        if (props.setOptions) {
          await props.setOptions([...props.options]);
        }
      }
    }
  };

  return (
    <>
      {props.option ? (
        <StyledCheckboxRadio
          type="checkbox"
          className={
            "checkbox" +
            (props.option.checked ? " checked" : "") +
            (props.option.readOnly ? " read-only" : "") +
            (props.option.indeterminate ? " indeterminate" : "")
          }
          onClick={overlayClicked}
        >
          <span className="box">
            <input
              id={props.name + (props.id ? "-" + props.id : "")}
              ref={props.forwardRef}
              type="checkbox"
              name={props.name}
              value={props.option.value}
              onChange={props.onChange}
              onClick={props.onClick}
              defaultChecked={props.option.checked}
              readOnly={props.option.readOnly}
            />
          </span>
          {props.option.label && (
            <label htmlFor={props.name + "-" + props.id}>
              {props.option.label}
            </label>
          )}
        </StyledCheckboxRadio>
      ) : (
        ""
      )}
    </>
  );
};

TextInput.defaultProps = {
  type: "text",
  className: "",
};

TextInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "number",
    "checkbox",
    "password",
    "search",
    "email",
    "range",
  ]),
  className: PropTypes.string,
  value: PropTypes.any,
  requirements: PropTypes.any,
};

const InputError = props => {
  return <p className="msg">{props.message || "Required"}</p>;
};

/*
 * Error pertaining to entire form
 */
const Error = props => {
  return (
    <StyledFormError>
      {props.message || "There were errors in the form"}
    </StyledFormError>
  );
};

export const Form = {
  Fieldset,
  FieldsetActions,
  InputWrapper,
  Checkboxes,
  Checkbox,
  RadioButtons,
  RadioButton,
  Textarea,
  TextInput,
  Error,
};
