const Phone = number => {
  /* NOT CURRENTLY VALIDATING PHONE ENTRIES
  if (number != "") {
    number = number.replace(/[^\d]/g, ""); // then you strip off all the spaces

    number =
      "(" +
      number.substring(0, 3) +
      ") " +
      number.substring(3, 6) +
      "-" +
      number.substring(6, 10);
  }

  if (number == "() -") {
    number = "";
  } 
  */
  return number;
};

const Zip = number => {
  //NO CURRENT VALIDATION FOR ZIP
  //return number.replace(/[^\d]/, "").replace(/([\d]{1,5}).*/, "$1");
  return number;
};

const Email = address => {
  if (address && address.trim()) {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(address);
  }
  return true;
};

const LinkedIn = value => {
  return value.match(/https?:\/\/(www\.)?linkedin\.com\/(in|company)/);
};

const UUID = value => {
  return value.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/
  );
};

const TrimInputs = data => {
  for (let key in data) {
    if (data[key] && typeof data[key] === "string") {
      data[key] = data[key].trim();
    }
  }
  return data;
};

export const Validate = {
  Zip,
  Phone,
  Email,
  LinkedIn,
  UUID,
  TrimInputs,
};
