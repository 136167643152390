import React, { useState, useCallback, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../../services/Auth";
import { RenderTable } from "../../Shared/ListTable";
import { formatAddress } from "../../../helpers/Display";
import { CompanyService } from "../../../services/Company";

export default function CompanyList(props) {
  //console.log(props);
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);

  //get candidates
  const fetchData = useCallback(({ pageIndex, pageSize, filter }) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      getData({ pageIndex, pageSize, filter });
    }
  }, []);

  const getData = async ({ pageIndex, pageSize, filter }) => {
    let _items = await CompanyService.list({
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter: filter,
      sortBy: "asc(name)",
    });

    let _data = [];
    _items._embedded &&
      _items._embedded.forEach(i => {
        _data.push({
          id: i.companyId,
          main: i.name,
          parentCompany: i.parentCompany ? i.parentCompany.name : "",
          markets: i.markets ? i.markets.map(m => m.market).join(", ") : "",
          submarkets: i.submarkets
            ? i.submarkets.map(s => s.specialty).join(", ")
            : "",
          technologies: i.technologies
            ? i.technologies.map(m => m.technology).join(", ")
            : "",
          location: formatAddress(i.city, i.state, false, false, false),
          country: i.country,
          phone: i.phone,
          activePositions: i.activePositions ? i.activePositions : 0,
          approvedByUserId: i.approvedByUserId,
        });
      });
    setData(_data);

    setPageCount(Math.ceil(_items.totalCount / pageSize));
  };

  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Company",
      accessor: "main",
    },
    {
      Header: "Parent Company",
      accessor: "parentCompany",
    },
    {
      Header: "Markets",
      accessor: "markets",
    },
    {
      Header: "Submarkets",
      accessor: "submarkets",
    },
    {
      Header: "Technologies",
      accessor: "technologies",
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Active Positions",
      accessor: "activePositions",
    },
    {
      Header: "Approval",
      accessor: "approvedByUserId",
    },
  ];
  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <RenderTable
      detail={{
        title: "Companies",
        name: "company",
        name_plural: "companies",
        url: "bd/company",
        filterableColumns: ["name"],
      }}
      columns={columns}
      data={data}
      filter={filter}
      setFilter={setFilter}
      heading={{ title: true, search: true, createNew: true }}
      pageCount={pageCount}
      fetchData={fetchData}
    />
  );
}
