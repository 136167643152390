import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Redirect, Link } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../services/Auth";
import { DateTime } from "../../helpers/DateTime";
import { formatUUID } from "../../helpers/Display";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import Select from "react-select";
import { RenderTable } from "../Shared/ListTable";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Form } from "../Shared/Form";
import styled from "styled-components";
import device from "../../config/device";
import { Colors } from "../../styles/Colors";
import {
  Container,
  Table,
  TableHeader,
  TableEmpty,
  DateTimePicker as StyledDateTimePicker,
  Button,
  ButtonDropdown,
  Card,
} from "../../styles/Common";
import { Dropdown } from "../Shared/Common";
import { Input } from "../../styles/Form";
import { PositionService } from "../../services/Position";
import imgChevron from "../../assets/chevron.svg";
import imgLinkedIn from "../../assets/linkedin.svg";
import imgPhone from "../../assets/phone.svg";
import imgMail from "../../assets/mail.svg";
import imgSend from "../../assets/send.svg";
import imgCopy from "../../assets/copy.svg";
import imgViewBoard from "../../assets/board.svg";
import imgViewTable from "../../assets/table.svg";
import imgUserPlus from "../../assets/user-plus.svg";
import moment from "moment";
import { UserService } from "../../services/User";

const Styled = styled.div`
  &:before {
    content: "";
    display: block;
    height: 256px;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background-image: linear-gradient(0deg, #5f6a7b 0%, #515b69 100%);
    box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
    background: ${Colors.primary};
  }

  h2 {
    font-weight: 700;
    font-size: 0.857rem;
    margin: 0 0 10px;
  }
  @media ${device.laptopLMax} {
    h2 {
      padding: 0 1rem;
    }
  }
`;

const Header = styled.header`
  color: ${Colors.gray};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 86px 1fr;
  margin-bottom: 36px;
  align-items: center;

  .dropdown-days {
    justify-self: start;
    .selected {
      font-weight: 300;
      font-size: 2.857rem;
      margin-top: 12px;
      margin-bottom: 12px;
      vertical-align: middle;
      margin-right: 15px;
      display: inline-block;
      &:after {
        content: "";
        vertical-align: middle;
        width: 20px;
        height: 16px;
        margin-left: 10px;
        display: inline-block;
        background: url(${imgChevron}) no-repeat;
        background-size: cover;
        background-position: 0 -10px;
      }
    }
    .list {
      left: auto;
      right: 16px;
      width: 150px;
      min-width: auto;
    }
  }

  .dropdown-positions {
    justify-self: right;
  }

  #chart {
    color: ${Colors.primary};
    padding: 20px;
    grid-column: 1/3;
    grid-row: 2/3;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    min-height: 322px;
    .recharts-wrapper {
      padding: 20px;
    }

    .range {
      align-items: baseline;
      display: flex;
      .rw-datetime-picker {
        margin: 0 10px;
      }
    }
  }

  @media ${device.laptopLMax} {
    padding: 0 1rem;
  }
`;
export default function PositionReporting(props) {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);
  const jwtInfo = AuthService.jwtInfo();

  const [chartData, setChartData] = useState([]);
  const [daysOpen, setDaysOpen] = useState(30);
  const [pastMonths, setPastMonths] = useState(6);

  const daysOpenOptions = [
    { label: "All", value: 0 },
    { label: "7", value: 7 },
    { label: "30", value: 30 },
    { label: "60", value: 60 },
    { label: "90", value: 90 },
    { label: "365", value: 365 },
  ];
  const monthsSelectorOptions = [
    { label: "3", value: 3 },
    { label: "6", value: 6 },
    { label: "9", value: 9 },
    { label: "12", value: 12 },
  ];

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }
  const { width } = useWindowDimensions();

  //get positions
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [filter, setFilter] = useState({
    status: "Open",
    kickoffStart: moment()
      .subtract(6, "months")
      .format("YYYY-MM-DD"),
    kickoffEnd: moment().format("YYYY-MM-DD"),
    owner: jwtInfo.user,
  });

  //get candidates
  const fetchData = useCallback(({ pageIndex, pageSize, filter }) => {
    console.log("fetchDATA", filter);
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      console.log("pageIndex1", pageIndex);
      getPositions({ pageIndex, pageSize, filter });
    }
  }, []);

  const getPositions = async ({ pageIndex, pageSize, filter }) => {
    let _items = await PositionService.chartOpen({
      days: daysOpen, //not operational pending db fix
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter: filter,
    });

    //TABLE DATA
    let _data = [];
    _items.list &&
      _items.list.forEach(i => {
        let daysSinceKickOff = DateTime.timeSince(
          new Date(i.kickoff),
          new Date()
        );
        _data.push({
          id: i.id,
          formattedId: formatUUID(i.id, "position"),
          urgency: i.urgency,
          kickOff: i.kickoff ? DateTime.formatDate(new Date(i.kickoff)) : "",
          daysSinceKickOff: daysSinceKickOff > 0 ? daysSinceKickOff : "-",
          owner: i.owner,
          primary: i.primary ? i.primary : "",
          secondary: i.secondary ? i.secondary : "",
          main: i.title,
          company: i.company,
          status: i.status,
          jobLevel: i.jobLevel,
        });
      });
    setData(_data);

    setPageCount(Math.ceil(_items.totalCount / pageSize));

    //CHART DATA
    let _dataPoints = [];
    let _months = [];
    if (_items.history) {
      _items.history.columns.map((c, key) => {
        let column = moment(c.value).format("MMM");
        let point;
        if (!_months.includes(column)) {
          //create point
          _dataPoints.push({
            key: key,
            amt: _items.history.series.Open.data[key],
            name: column,
            total: _items.history.series.Open.data[key],
            pv: _items.history.series.Open.data[key],
          });
          //add to the list of months used
          _months.push(column);
        } else {
          //existing point
          point = _dataPoints.find(d => d.name === column);
          point.amt = point.amt + _items.history.series.Open.data[point.key];
          point.total = point.amt;
          point.pv = point.amt;
        }
      });
      console.log("_dtaPoints", _dataPoints);
      setChartData(_dataPoints);
    }
  };

  //ALL USERS
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(false);

  const getUsers = async () => {
    let response = await UserService.list({ limit: 1000 });

    if (response._embedded) {
      console.log("users", response._embedded);
      setUsers([...response._embedded]);
      setUserSelected(response._embedded.find(u => u.id === jwtInfo.user));
      setFilteredUsers([
        ...response._embedded.filter(u => u.id !== jwtInfo.user),
      ]);
    }
  };
  const setUser = async user => {
    setUserSelected(user);
    setFilter({ ...filter, owner: user.id });
    setFilteredUsers([...users.filter(u => u !== user)]);
  };

  useEffect(() => {
    getUsers();
  }, []);

  //const tableData = useMemo(() => [], []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "ID",
        accessor: "formattedId",
      },
      {
        Header: "Client",
        accessor: "company",
      },
      {
        Header: "Position",
        accessor: "main",
      },
      {
        Header: "Stage",
        accessor: "stage",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Urgency",
        accessor: "urgency",
      },
      {
        Header: "Kick Off",
        accessor: "kickOff",
      },
      {
        Header: "Days Since Kick Off",
        accessor: "daysSinceKickOff",
      },
      {
        Header: "Primary",
        accessor: "primary",
      },
      {
        Header: "Secondary",
        accessor: "secondary",
      },
      {
        Header: "Owner",
        accessor: "owner",
      },
      {
        Header: "Job Level",
        accessor: "jobLevel",
      },
    ],
    []
  );

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  const widthPercentage = 0.9;
  const chartConfigs = {
    width: width * widthPercentage,
    height: 250,
    margin: { top: 5, right: 60, bottom: 5, left: 0 },
  };

  const filterUserDropdown = e => {
    console.log(e.target.value);
    let value = e.target.value.toLowerCase();

    let regex = new RegExp(value, "i");
    setFilteredUsers([
      ...users.filter(u => {
        if (u.firstName.match(regex) || u.lastName.match(regex)) {
          return u;
        }
      }),
    ]);
  };

  const setDateRange = () => {
    let start = moment(
      document.getElementById("dateRangeStart_input").value
    ).format("YYYY-MM-DD");
    let end = moment(
      document.getElementById("dateRangeEnd_input").value
    ).format("YYYY-MM-DD");
    setFilter({ ...filter, kickoffStart: start, kickoffEnd: end });
  };

  return (
    <Styled>
      <Container>
        <Header>
          <Dropdown className="dropdown-days">
            <span className="selected">
              Positions Open for {daysOpen}+ Days
            </span>
            <div className="list">
              <ul>
                {daysOpenOptions.map((d, i) => {
                  return (
                    <React.Fragment key={i}>
                      {d.value !== daysOpen && (
                        <li onClick={() => setDaysOpen(d.value)}>
                          {d.label}+ days
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </Dropdown>

          {users.length && userSelected ? (
            <Dropdown
              className="dropdown-users"
              listId="users-filter"
              position="right"
            >
              <ButtonDropdown size="small" className="users-filter">
                {userSelected.firstName + " " + userSelected.lastName}
              </ButtonDropdown>
              <div className="list" id="users-filter">
                <input onKeyUp={filterUserDropdown} />
                <ul>
                  {filteredUsers.map((u, i) => {
                    return (
                      !u.selected && (
                        <li key={i} onClick={() => setUser(u)}>
                          {u.firstName + " " + u.lastName}
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </Dropdown>
          ) : (
            ""
          )}
          <Card id="chart">
            <div className="range">
              <h1>Range</h1>
              <StyledDateTimePicker>
                <DateTimePicker
                  time={false}
                  id="dateRangeStart"
                  defaultValue={moment(filter.kickoffStart).toDate()}
                />
              </StyledDateTimePicker>
              <span className="divider">to</span>
              <StyledDateTimePicker>
                <DateTimePicker
                  time={false}
                  id="dateRangeEnd"
                  defaultValue={moment(filter.kickoffEnd).toDate()}
                />
              </StyledDateTimePicker>
              <Button size="small" onClick={setDateRange}>
                Go
              </Button>
            </div>
            {chartData.length ? (
              <ResponsiveContainer width={"100%"} height={250}>
                <AreaChart
                  width={chartConfigs.width}
                  height={chartConfigs.height}
                  data={chartData}
                  margin={chartConfigs.margin}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#24adf2" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#24adf2" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid stroke="#ddd" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="total"
                    stroke="#24adf2"
                    fillOpacity={1}
                    fill="#24adf2"
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              "No data found"
            )}
          </Card>
        </Header>
        <h2>Current Positions Open for {daysOpen}+ Days</h2>

        {users.length && userSelected ? (
          <RenderTable
            detail={{
              title: "Positions",
              name: "position",
              name_plural: "positions",
            }}
            data={data}
            columns={columns}
            history={props.history}
            heading={{ title: false, search: false, createNew: false }}
            styleTableWrapper={{ margin: "0" }}
            pageCount={pageCount}
            fetchData={fetchData}
            filter={filter}
            userSelected={userSelected}
          />
        ) : (
          ""
        )}
      </Container>
    </Styled>
  );
}
