import { list } from "./lib";

import Config from "../config/config";

const get = id => {
  return fetch(Config.API_URL + "users/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const create = inputData => {
  return fetch(Config.API_URL + "users", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

//PASSWORD
const setPassword = inputData => {
  return fetch(Config.API_URL + "users/reset_password", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const updatePassword = inputData => {
  return fetch(Config.API_URL + "users/" + inputData.id, {
    method: "PATCH",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
//reset user password
const resetPassword = inputData => {
  return fetch(Config.API_URL + "users/forgot_password", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

//NYLAS
const nylasAuth = inputData => {
  return fetch(Config.API_URL + "users/auth", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
//ROLES
const roles = inputData => {
  return fetch(Config.API_URL + "users/roles", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const getRoles = inputData => {
  return fetch(Config.API_URL + "users/roles/" + inputData.id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const addRole = inputData => {
  return fetch(
    Config.API_URL + "users/" + inputData.id + "/roles/" + inputData.roleId,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteRole = inputData => {
  return fetch(
    Config.API_URL + "users/" + inputData.id + "/roles/" + inputData.roleId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteUser = userId => {
  return fetch(
      Config.API_URL + "users/" + userId,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
  )
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        return error;
      });
};

export const UserService = {
  create,
  get,
  list: query => list("/users", { query }),

  // PASSWORD
  setPassword,
  resetPassword,
  updatePassword,
  nylasAuth,
  roles,
  getRoles,
  addRole,
  deleteRole,
  deleteUser
};
