/*
 * TMG decided not to use an web integrated email interface to the preference of sending emails through their default providers
 */

import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import Calendar from "react-widgets/lib/Calendar";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import moment from "moment";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { InterviewService } from "../../services/Interview";
import { Container, Button, ButtonClose, PageTitle } from "../../styles/Common";
import { Colors } from "../../styles/Colors";
import { Form as StyledForm } from "../../styles/Form";
import { Form } from "../Shared/Form";
import { Card } from "../Shared/Common";
import imgX from "../../assets/x.svg";
import { toast } from "react-toastify";

Moment.locale("en");
momentLocalizer();

const Styled = styled.div`
  margin: 0 auto;
  width: 760px;
  .display {
    margin: 70px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .times {
    padding-top: 20px;
  }
  form {
    .number {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0 15px 0 0;
    }
    .title {
      margin: 0 0 2px 28px;
      font-weight: 600;
      font-size: 0.75rem;
    }
    .rw-widget {
      display: inline-block;
      margin: 0 0 20px;
    }

    .actions {
      margin: 0 0 0 28px;
    }
  }
  .confirmed {
    background: ${Colors.success};
    padding: 20px;
    color: #fff;
    font-size: 1.1rem;
  }
`;

const CalendarWrapper = styled.div`
  width: 321px;
  h1 {
    font-size: 0.75rem;
    font-weight: 600;
  }
`;
const StyledCalendar = styled(Card)`
  margin: 20px 0;
  .rw-calendar {
    border: 0;
    padding: 15px;
  }
  .rw-calendar-month .rw-cell {
    vertical-align: middle;
  }
  .rw-cell.rw-state-selected,
  .rw-cell.rw-state-selected:hover {
    background-color: ${Colors.highlight};
    border-color: ${Colors.highlight};
  }
  .rw-cell.rw-state-focus,
  .rw-cell.rw-state-focus:hover {
    border-color: ${Colors.highlight};
  }
  .rw-head-cell {
    border-bottom: none;
  }
`;

export default function JobOrderInterviewHiringManager(props) {
  let params = useParams();

  console.log("params", params);
  if (!params || !params.target) {
    // props.history.push("/");
  }
  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    reset,
  } = useForm();

  //get interviewparams.target
  const [interview, setInterview] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [manager, setManager] = useState(
    params.target === "manager" ? true : false
  );
  const [interviewConfirmed, setInterviewConfirmed] = useState(false);
  const [interviewTimes, setInterviewTimes] = useState([
    { date: new Date(), set: false },
  ]);
  const [lastSelectedDateTime, setLastSelectedDateTime] = useState(false);

  const getInterview = async () => {
    let response = await InterviewService.get({ id: params.id });
    console.log("interview", response);
    if (response._links) {
      setInterview(response);
    } else {
      toast.error("Interview could not be found", { position: "top-center" });
      props.history.push("/");
    }
  };
  useEffect(() => {
    getInterview();
  }, []);

  const onSubmit = async () => {
    console.log("interviews", interviewTimes);

    let times;
    let timesBuild = "{";
    interviewTimes.forEach((time, i) => {
      timesBuild += '"suggestedDate' + (i + 1) + '":"' + time.date + '",';
    });
    timesBuild += "}";

    times = JSON.parse(timesBuild.replace(",}", "}"));
    //return;
    let response = await InterviewService.update({
      manager: manager,
      id: interview.interviewId,
      ...times,
    });
    if (!response.error) {
      setInterviewConfirmed(true);
    } else {
      setErrorMessage(response.error);
    }
  };

  const addTimeOption = () => {
    //update all other times to be set
    setInterviewTimes([
      ...interviewTimes.map(i => {
        i.dateSet = true;
        return i;
      }),
      {
        date: lastSelectedDateTime ? lastSelectedDateTime : new Date(),
        dateSet: false,
      },
    ]);
  };

  const deleteTime = time => {
    setInterviewTimes([...interviewTimes.filter(t => t !== time)]);
  };

  const onCalendarChange = date => {
    //set last selected, so that next date will pick up from this noe
    setLastSelectedDateTime(date);

    setInterviewTimes([
      ...interviewTimes.map(t => {
        if (!t.dateSet) {
          t.date = date;
        }
        return t;
      }),
    ]);
  };
  const onTimeChange = date => {
    setInterviewTimes([
      ...interviewTimes.map(t => {
        if (!t.dateSet) {
          t.date = date;
        }
        return t;
      }),
    ]);
  };

  return (
    <Container>
      <Styled>
        <PageTitle>Confirm Interview</PageTitle>
        {!interviewConfirmed ? (
          <>
            <p>
              {manager
                ? "Please confirm dates and times to have " +
                  interview.type +
                  " interview with " +
                  interview.candidateFirstName +
                  " " +
                  interview.candidateLastName
                : "Please confirm dates and times for your interview"}
            </p>
          </>
        ) : (
          <p className="confirmed">Interview times have been set, thank you!</p>
        )}

        {!interviewConfirmed ? (
          <>
            <div className="display">
              <CalendarWrapper>
                <h1>Interview Dates</h1>
                <StyledCalendar className="calendar">
                  <Calendar
                    dateFormat={dt => String(dt.getDate())}
                    dayFormat={day =>
                      ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
                        day.getDay()
                      ]
                    }
                    min={new Date()}
                    footer={false}
                    onChange={onCalendarChange}
                  />
                </StyledCalendar>
                {errorMessage && <Form.Error message={errorMessage} />}
                <p>
                  Please select 3 dates and times that you would like to
                  interview on.
                </p>
              </CalendarWrapper>
              <div className="times">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul>
                    {interviewTimes.map((time, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Form.InputWrapper
                            label={moment(time.date).format("MMMM D, YYYY")}
                            name="times[]"
                            errors={errors}
                          >
                            <span className="number">{i + 1}.</span>
                            <DateTimePicker
                              name="times[]"
                              date={false}
                              value={time.date}
                              ref={register({
                                name: "date",
                                value: time.date,
                              })}
                              onChange={onTimeChange}
                            />

                            <ButtonClose
                              type="button"
                              onClick={() => deleteTime(time)}
                            >
                              <img src={imgX} alt="Delete" title="Delete" />
                            </ButtonClose>
                          </Form.InputWrapper>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                  <div className="actions">
                    {interviewTimes.length < 3 ? (
                      <Button type="button" onClick={() => addTimeOption()}>
                        Add
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button primary onClick={onSubmit}>
                      Confirm Dates &amp; Times
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </Styled>
    </Container>
  );
}
