import Config from "../config/config";
import { filterTable } from "../helpers/Search";

const create = inputData => {
  return fetch(Config.API_URL + "conferences", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const update = inputData => {
  return fetch(Config.API_URL + "conferences/" + inputData.id, {
    method: "PUT",
    body: JSON.stringify({ ...inputData, id: undefined }), //error from api if id is sent in body
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const list = inputData => {
  //build search query if set in filter
  let search = filterTable(inputData.filter);

  return fetch(
    Config.API_URL +
      "conferences?limit=" +
      (inputData.limit ? inputData.limit : 1000) +
      (inputData.offset ? "&offset=" + inputData.offset : "") +
      (inputData.filter ? (search ? "&q=" + (search ? search : "") : "") : ""),
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const del = id => {
  return fetch(Config.API_URL + "conferences/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const get = id => {
  return fetch(Config.API_URL + "conferences/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

/* EVENTS */
const createEvent = inputData => {
  return fetch(
    Config.API_URL + "conferences/" + inputData.conferenceId + "/events",
    {
      method: "POST",
      body: JSON.stringify({ ...inputData.data }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
/* CONTACT */
const createContact = inputData => {
  return fetch(
    Config.API_URL + "conferences/" + inputData.conferenceId + "/people",
    {
      method: "POST",
      body: JSON.stringify({ ...inputData.data }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
/* COMPANY */
const createCompany = inputData => {
  return fetch(
    Config.API_URL + "conferences/" + inputData.conferenceId + "/companies",
    {
      method: "POST",
      body: JSON.stringify({ ...inputData.data }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
/* Address */
const createAddress = inputData => {
  return fetch(
    Config.API_URL + "conferences/" + inputData.conferenceId + "/addresses",
    {
      method: "POST",
      body: JSON.stringify({ ...inputData.data }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

/* NOTES */
const getNotes = inputData => {
  return fetch(
    Config.API_URL +
      "conferences/" +
      inputData.id +
      "/notes?limit=" +
      inputData.limit +
      "&offset=" +
      inputData.offset,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const addNote = inputData => {
  console.log("addNote", inputData);
  return fetch(Config.API_URL + "conferences/" + inputData.id + "/notes", {
    method: "POST",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
export const ConferenceService = {
  create,
  update,
  del,
  list,
  get,
  getNotes,
  addNote,
  createEvent,
  createContact,
  createCompany,
  createAddress,
};
