import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../services/Auth";
import { useForm } from "react-hook-form";
import { CandidateService } from "../../services/Candidate";
import { TagService } from "../../services/Tag";
import { DegreeService } from "../../services/Degree";
import { TitleService } from "../../services/Title";
import { CompanyService } from "../../services/Company";
import { DocumentService } from "../../services/Document";

import { getDegreeType } from "../../helpers/Parser";

import styled from "styled-components";
import { Colors } from "../../styles/Colors";
import Select from "react-select";
import { Form } from "../Shared/Form";
import { Card } from "../Shared/Common";
import { DropFileUpload } from "../Shared/DropFileUpload";
import { compensationBuild, visaBuild } from "../Shared/Data";
import { Validate } from "../Shared/Validate";
import { Form as StyledForm } from "../../styles/Form";
import {
  Container,
  Button,
  PageTitle,
  LinkClose,
  ButtonClose,
} from "../../styles/Common";
import { LoadingSubmit } from "../../styles/Animation";
import { startCase as _startCase } from "lodash";

import { toast } from "react-toastify";

import imgUpload from "../../assets/upload.svg";
import imgCircleX from "../../assets/circle-x.svg";
import imgX from "../../assets/x.svg";

const StyledResumeSelection = styled.div``;

export default function CandidateNew(props) {
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );
  //used when url coming in from linkedin
  const [loginChecked, setLoginChecked] = useState(false);

  //react-hook-form
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setValue,
    reset,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);
  //if true, load data from resume, false, no resume to add
  const [resumeLoading, setResumeLoading] = useState(false);
  const [documentId, setDocumentId] = useState(undefined);
  const [linkedinDataLoading, setLinkedinDataLoading] = useState(false);
  const [linkedinDataSet, setLinkedinDataSet] = useState(false);
  const [parsedInfo, setParsedInfo] = useState({});
  const [locationInfo, setLocationInfo] = useState({});
  const [resumeParseSelected, setResumeParseSelected] = useState(false);
  const [linkedInParserError, setLinkedInParserError] = useState(false);
  const [linkedinLoaded, setLinkedinLoaded] = useState(false);
  const initSelectValues = {
    compensation: [],
    "currentEmployments[companyName]": [],
    "currentEmployments[title]": [],
    tags: [],
    visa: [],
  };
  const [selectValues, setSelectValues] = useState(initSelectValues);

  const onSelectChange = (opt, actionMeta) => {
    let name = actionMeta.name;
    let value;

    //dropdown
    if (!opt) {
      //empty data
      value = null;
    } else if (opt && opt.value) {
      value = opt.value;
    } else {
      //multiselect
      value = opt;
    }
    selectValues[name] = opt;
    setValue(name, value); //for react-hook
    setSelectValues({ ...selectValues });
    //reset any errors
    setErrorMessage("");
  };

  const [tags, setTags] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [titles, setTitles] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const getTags = async () => {
      //available tags
      let _tags = await TagService.get({ type: "skill" });
      if (!_tags.error) {
        setTags(
          _tags._embedded.map(t => {
            return { value: t.tagId, label: t.tag };
          })
        );
      }
    };
    const getDegrees = async () => {
      //available degrees
      let _degrees = await DegreeService.get({ limit: 200, degree: "" });
      if (_degrees && _degrees._embedded) {
        setDegrees(
          _degrees._embedded.map(d => {
            return { value: d.name, label: d.name };
          })
        );
      }
    };
    const getTitles = async () => {
      //available titles
      let _titles = await TitleService.get({ limit: 200, title: "" });
      if (_titles && _titles._embedded) {
        setTitles(
          _titles._embedded.map(t => {
            return {
              value: t.name,
              label: t.name,
            };
          })
        );
      }
    };

    const getCompanies = async () => {
      let _companies = await CompanyService.list({ limit: 10000 });
      if (_companies && _companies._embedded) {
        setCompanies(
          _companies._embedded.map(c => {
            return {
              value: c.companyId,
              label: c.name,
              data: "currentEmployments[companyName]",
            };
          })
        );
      }
    };
    if (isLoggedIn) {
      getTags();
      getDegrees();
      getTitles();
      getCompanies();
    }
  }, [isLoggedIn]);

  //fetch linked in Data if necessary
  const getLinkedInData = async url => {
    setLinkedinDataLoading(true);
    setResumeParseSelected(true);
    let response = await DocumentService.linkedIn({ url: url });

    if (!response.error) {
      response.linkedinUrl = url;
      prepareLinkedInData(response);
    } else {
      setResumeParseSelected(false);
      setLinkedinDataLoading(false);
      setLinkedInParserError(response.error);
    }
  };

  const checkCandidateByUrl = async url => {
    //check that user doesn't already exist
    let linkedInResponse = await CandidateService.linkedin(url);

    if (linkedInResponse.totalCount) {
      //redirect to candidate detail
      props.history.push(
        "/candidate/" + linkedInResponse._embedded[0].candidateId + "/linkedIn"
      );
    }
    //if candidate does not exist... start the parse
    else {
      getLinkedInData(url);
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      let q = window.location.search;
      let matches;
      if (q) {
        matches = q.match(/\?linkedin\=(.*)&t=(.*)/);
        if (matches && matches[2]) {
          //set login
          localStorage.setItem("token", matches[2]);
          setIsLoggedIn(true);
        }
      }
    }
    setLoginChecked(true);
  }, []);

  //pull and parse linkedin info
  useEffect(() => {
    if (companies.length && titles.length && !linkedinLoaded) {
      let q = window.location.search;
      let linkedinUrl = false;
      let matches;
      if (q) {
        matches = q.match(/\?linkedin\=(.*)(&t=)?/);
      }

      if (matches && matches.length >= 2) {
        setLinkedinLoaded(true);
        linkedinUrl = decodeURIComponent(matches[1].replace(/&t=(.*?)$/, ""));
        checkCandidateByUrl(linkedinUrl);
      }
    }
  }, [companies, titles, linkedinLoaded]);

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");

    //remove anything over 4 digits
    if (e.target.name.match(/yearEntered|endYear|workExperience/)) {
      e.target.value = e.target.value.replace(/(.{4}).*/, "$1");
    }
    if (e.target.name === "phone") {
      e.target.value = Validate.Phone(e.target.value);
    }
    if (e.target.name === "zip") {
      e.target.value = Validate.Zip(e.target.value);
    }
    if (e.target.name === "country") {
      e.target.value = e.target.value.replace(/(.{3}).*/, "$1");
    }
  };

  //Submit Form
  const onSubmit = async data => {
    //email validation
    if (!Validate.Email(data.emailPersonal)) {
      setErrorMessage("Please enter a valid personal email address");
      return;
    }
    if (!Validate.Email(data.emailWork)) {
      setErrorMessage("Please enter a valid work email address");
      return;
    }
    //linkedin validate
    if (data.linkedinUrl && !Validate.LinkedIn(data.linkedinUrl)) {
      setErrorMessage("Please enter a linkedin URL in the correct format");
      return;
    }

    //map tags to values only if set
    if (data.tags && data.tags.length) {
      data.tags = data.tags.map(t => t.value);
    } else {
      data.tags = [];
    }

    //must be int
    //data.yearEntered = data.yearEntered ? parseInt(data.yearEntered) : 0;
    data.workExperience = data.workExperience
      ? parseInt(data.workExperience)
      : 0;

    //schools
    data.education = [];
    if (schools.length) {
      data.education = schools
        .map(s => {
          if (s.name.trim()) {
            return {
              name: s.name.trim(),
              degreeType: s.degreeType,
              endYear: s.endYear,
            };
          }
        })
        .filter(s => typeof s !== "undefined");
    }

    //current employment (can't be empty)
    if (data.currentEmployments && data.currentEmployments.companyName) {
      Validate.UUID(data.currentEmployments.companyName);
      data.currentEmployments = [
        {
          title: data.currentEmployments.title,
          companyName: Validate.UUID(data.currentEmployments.companyName)
            ? undefined
            : data.currentEmployments.companyName,
          companyId: Validate.UUID(data.currentEmployments.companyName)
            ? data.currentEmployments.companyName
            : undefined,
        },
      ];
    } else {
      data.currentEmployments = undefined;
    }

    //make sure address is not sending empty values if set
    if (!data.address.city || !data.address.state) {
      data.address = undefined;
    }

    //compensation
    data.compensation = data.compensation ? data.compensation.toString() : "";

    //trim whitespace from inputs
    data = Validate.TrimInputs(data);

    //SUBMIT FORM
    setFormSubmission(true);

    let response = await CandidateService.create(data);

    setFormSubmission(false);
    if (response.error || response.errors) {
      setErrorMessage(
        response.error ? response.error : response.errors.message
      );
    } else if (response.id) {
      //success
      //clear form
      setResumeFile();
      reset();
      setSchools([resetSchool(true, false)]);
      setSelectValues(initSelectValues);

      //toast notificiation
      toast.success(
        "Candidate (" +
          data.firstName +
          " " +
          data.lastName +
          ") has been created",
        {
          position: "bottom-center",
          closeOnClick: false,
          onClick: () => props.history.push("/candidate/" + response.id),
        }
      );
      //resume upload?
      if (resumeFile) {
        data.type = "candidates";
        data.id = response.id;
        data.documentType = "resume";
        data.documentId = documentId;
        data.file = resumeFile;
        let resumeResponse = await CandidateService.associateCandidateDocument(
          data
        );

        if (resumeResponse.error) {
          toast.error(
            "Candidate was created, but there was a problem with the resume upload",
            {
              position: "bottom-center",
              closeOnClick: false,
            }
          );
        }
      }
      //additionalEmployments from linkedin load
      if (additionalEmployment && additionalEmployment.length) {
        additionalEmployment.forEach(e => {
          //add note
          CandidateService.addNote({
            title: "Additional Employment",
            note:
              (e.companyName ? "<b>" + e.companyName + "</b> - " : "") +
              e.title.replace(/Title|\n/, ""),
            type: "linkedin",
            id: response.id,
          });
        });
      }
    }
  };

  const [resumeFile, setResumeFile] = useState();
  const prepareResumeFile = async e => {
    let reader = new FileReader();
    reader.onload = () => {
      //fileExtension = fileName.replace(/^.*\./, "");
    };
    //reader.readAsDataURL(e.target.files[0]);
    let file = e.target.files[0];
    file.ext = file.name.replace(/^.*\./, "");
    file.documentType = "resume";
    file.error = file.ext.match(/docx|doc|pdf|pages/)
      ? false
      : "Error: .docx, .pages & .pdf accepted";

    //get parser data
    if (!file.error) {
      setResumeLoading(true);
      let response = await DocumentService.resumeParser(file);
      if (!response.error) {
        setDocumentId(response.id);

        setResumeParseSelected(true); //show full form
        setResumeLoading(false);

        //define fields we want to fill
        let valueSet = [
          { name: "firstName", type: "single" },
          { name: "lastName", type: "single" },
          { name: "employment", type: "employment" },
          { name: "education", type: "education" },
          { name: "contactInfo", type: "contactInfo" },
          { name: "desc", type: "single", map: "bio" },
        ];
        valueSet.forEach(v => {
          switch (v.type) {
            case "single":
              setValue(
                v.map ? v.map : v.name,
                _startCase(response._embedded[v.name].toLowerCase())
              );
              break;
            case "employment":
              if (response._embedded[v.name].length) {
                let employment = response._embedded[v.name][0]; //last employment
                employment.companyName = _startCase(
                  employment.companyName.toLowerCase()
                );
                employment.title = _startCase(employment.title.toLowerCase());
                let newCompany = {
                  label: employment.companyName,
                  value: employment.companyName,
                  data: "currentEmployments[companyName]",
                };
                let newTitle = {
                  label: employment.title,
                  value: employment.title,
                  data: "currentEmployments[title]",
                };
                if (!titles.find(t => t.label == employment.title)) {
                  setTitles([...titles, newTitle]);
                }
                if (!companies.find(c => c.label == employment.companies)) {
                  setCompanies([...companies, newCompany]);
                }
                //update entry values
                selectValues["currentEmployments[companyName]"] =
                  employment.companyName;
                selectValues["currentEmployments[title]"] = employment.title;
                setSelectValues({ ...selectValues });
                onSelectChange(newCompany, {
                  name: "currentEmployments[companyName]",
                });
                onSelectChange(newTitle, { name: "currentEmployments[title]" });
              }

              break;
            case "contactInfo":
              response._embedded[v.name].map(contact => {
                if (contact.city) {
                  setValue("address['city']", contact.city);
                }
                if (contact.state) {
                  setValue("address['state']", contact.state);
                }
                if (contact.telephone) {
                  setValue("phone", Validate.Phone(contact.telephone));
                } else if (contact.mobilePhone) {
                  setValue("phone", Validate.Phone(contact.mobilePhone));
                } else if (contact.emailPersonal) {
                  setValue("emailPersonal", contact.emailPersonal);
                } else if (contact.linkedinUrl) {
                  setValue("linkedinUrl", contact.linkedinUrl);
                }
              });

              break;
            case "education":
              {
                let education = [];
                let workExperience = 0;
                response._embedded[v.name].map(edu => {
                  let r = resetSchool(false, false);
                  r.name = _startCase(edu.name.toLowerCase());
                  r.degreeType = edu.degree ? getDegreeType(edu.degree) : "";
                  r.endYear = edu.endDate;
                  education.push(r);

                  if (edu.endDate && workExperience < parseInt(edu.endDate)) {
                    workExperience = edu.endDate;
                    setValue("workExperience", workExperience);
                  }
                });
                setSchools(education);
              }
              break;
            default:
              break;
          }
        });
      }
    }

    setResumeFile(file); //e.target.files[0]
  };

  const [additionalEmployment, setAdditionalEmployment] = useState([]);

  //prepare LinkedIn pull
  const prepareLinkedInData = data => {
    parsedInfo.linkedinUrl = data.linkedinUrl;

    //education
    if (data.education && data.education.length) {
      let education = [];
      parsedInfo.workExperience = 0;
      data.education.map(edu => {
        let r = resetSchool(false, false);
        r.name = edu.name ? edu.name : "";
        r.degreeType = edu.degree ? getDegreeType(edu.degree) : "";
        r.endYear = edu.endDate ? edu.endDate : "";
        education.push(r);
        //add in workExp if available
        if (edu.endDate && parsedInfo.workExperience < parseInt(edu.endDate)) {
          parsedInfo.workExperience = edu.endDate;
        }
      });
      setSchools(education);
    }
    //bio
    if (data.desc) {
      parsedInfo.bio = data.desc;
    }
    //employment
    if (
      data.employment &&
      data.employment.length &&
      data.employment[0].companyName &&
      data.employment[0].title
    ) {
      let _additionalEmployment = [];
      data.employment.forEach((e, i) => {
        //store first one as current position
        if (i === 0) {
          let newCompany = {
            label: e.companyName,
            value: e.companyName,
            data: "currentEmployments[companyName]",
          };
          let newTitle = {
            label: e.title,
            value: e.title,
            data: "currentEmployments[title]",
          };

          if (!titles.find(t => t.label == e.title)) {
            setTitles([...titles, newTitle]);
          }
          if (!companies.find(c => c.label == e.companyName)) {
            setCompanies([...companies, newCompany]);
          }
          //update entry values
          setTimeout(() => {
            //add timeout wrapper, otherwise this overwrites titles/companies
            selectValues["currentEmployments[companyName]"] = e.companyName;
            selectValues["currentEmployments[title]"] = e.title;
            setSelectValues({ ...selectValues });
            onSelectChange(newCompany, {
              name: "currentEmployments[companyName]",
            });
            onSelectChange(newTitle, { name: "currentEmployments[title]" });
          }, 0);
        }
        //store them as notes (after candidate is created)
        else {
          _additionalEmployment.push(e);
        }
      });
      setAdditionalEmployment(_additionalEmployment);
    }
    //split and set name
    //Name data is not always accurate. The string length is to separate a real name from the long text blob that LinkIn send when a name isn't available. The length is arbitrary
    if (data.name) {
      //if there are comma's (like degrees after name, split it first)
      if (data.name.match(",")) {
        let _name = data.name.split(",");
        data.name = _name[0];
      }

      let name = data.name
        .replace(/\((.*?)\)/g, "") //update names like FirstName (Nickname) LastName
        .replace(/\s\s/g, " ") //replace multiple spaces w/ single space
        .split(" ");
      if (name.length === 2) {
        parsedInfo.firstName = name[0];
        parsedInfo.lastName = name[1];
      }
      setParsedInfo({ ...parsedInfo });
    }
    //bio
    if (data.desc) {
      parsedInfo.bio = data.desc;
    }

    /*
      If location data is available split on the first comma and assume index 0 = city and index 1 = state
      The user can edit if it's wrong
    */
    if (data.location) {
      let location = data.location.split(",");
      if (location.length < 2) {
        locationInfo.city = location[0].trim();
      } else {
        locationInfo.city = location[0].trim();
        locationInfo.state = location[1].trim();
      }
      setLocationInfo({ ...locationInfo });
    }

    setLinkedinDataLoading(false);
    setLinkedinDataSet(true);
  };

  //set initial school
  const resetSchool = (initial, index) => {
    let initial_index = index ? index : Math.random();
    return {
      initial: initial,
      index: initial_index,
      key: initial_index,
      name: "",
      degreeType: "",
      endYear: "",
      focus: "",
    };
  };
  const [schools, setSchools] = useState([resetSchool(true, false)]);

  const addSchool = () => {
    //set them, then add new school to the mix
    setSchools([...setSchoolInputValues(), resetSchool(false, false)]);
  };

  const deleteSchool = school => {
    //set values, then unregister input hooks
    setSchools(setSchoolInputValues().filter(e => e.index != school.index));
    unregister("education[" + school.index + "][name]");
    unregister("education[" + school.index + "][degreeType]");
    unregister("education[" + school.index + "][endYear]");
  };

  const setSchoolInputValues = () => {
    //need to get all current values of inputs before rewriting
    let inputs = document.querySelectorAll('input[name^="education"]');
    let _schools = [];

    let _id,
      id = "";
    let _buildItem;
    let i = 0;
    Array.from(inputs).forEach((input, x) => {
      id = input.name.split("][")[0].split("education[")[1];
      if (i === 0 && x === 0) {
        _buildItem = resetSchool(true, id);
      }
      _buildItem.index = _buildItem.key = id;
      //get changed value
      //set value for react hook
      if (input.name.match(/name/)) {
        _buildItem.name = input.value;
        if (input.value !== schools[i].name) {
          _buildItem.focus = "degreeType";
        }
      } else if (input.name.match(/degreeType/)) {
        _buildItem.degreeType = input.value;
        if (input.value !== schools[i].degreeType) {
          _buildItem.focus = "endYear";
        }
      } else if (input.name.match(/endYear/)) {
        _buildItem.endYear = input.value;
        //_buildItem.focus = "";
      }
      //reset build for new input group
      if (_id && (x + 1) % 3 === 0) {
        _schools.push(_buildItem);
        _buildItem = resetSchool(false);
        i++;
      }
      //keep _id set to latest id
      _id = id;
    });

    return _schools;
  };

  /*
   * Button to add new school
   */
  const fieldsetActionsEducation = () => {
    return (
      <Button
        type="button"
        fieldsetAction
        size="small"
        style={{ marginBottom: "14px" }}
        onClick={() => addSchool()}
      >
        Additional Education
      </Button>
    );
  };

  const School = props => {
    return (
      <li className="row group">
        <ol>
          <Form.InputWrapper
            label="School"
            name={"education[" + props.school.index + "][name]"}
            errors={errors}
          >
            <Form.TextInput
              name={"education[" + props.school.index + "][name]"}
              type="text"
              value={props.school.name}
              onBlur={() => setSchools(setSchoolInputValues)}
              focus={props.school.focus === "name"}
            />
          </Form.InputWrapper>

          <Form.InputWrapper
            label="Degree"
            name={"education[" + props.school.index + "][degreeType]"}
            errors={errors}
            className="react-select position-title"
          >
            <Select
              name={"education[" + props.school.index + "][degreeType]"}
              isClearable={false}
              className="select"
              classNamePrefix="position-title"
              options={degrees}
              placeholder=""
              defaultValue={
                props.school.degreeType !== ""
                  ? degrees.find(d => d.label === props.school.degreeType)
                  : null
              } //degrees.find(d => d.label === props.school.degree)
              onBlur={() => setSchools(setSchoolInputValues)}
              autoFocus={props.school.focus === "degreeType"}
            />
          </Form.InputWrapper>

          <Form.InputWrapper
            label="End Year"
            name={"education[" + props.school.index + "][endYear]"}
            errors={errors}
          >
            <Form.TextInput
              name={"education[" + props.school.index + "][endYear]"}
              type="number"
              onChange={onChange}
              placeholder="yyyy"
              value={props.school.endYear}
              onBlur={() => setSchools(setSchoolInputValues)}
              focus={props.school.focus === "endYear"}
            />
          </Form.InputWrapper>
        </ol>
        {!props.school.initial && (
          <ButtonClose
            style={{ marginTop: 35 }}
            type="button"
            onClick={() => deleteSchool(props.school)}
          >
            <img src={imgX} alt="Delete" title="Delete" />
          </ButtonClose>
        )}
      </li>
    );
  };

  //check authentication
  if (loginChecked && !isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Container className="form">
      <PageTitle>Create Candidate</PageTitle>

      {linkedInParserError ? (
        <p style={{ color: Colors.error }}>{linkedInParserError}</p>
      ) : (
        ""
      )}
      {!linkedInParserError && !linkedinDataLoading && !linkedinDataSet ? (
        <p>
          We suggest that you create a candidate by starting on LinkedIn first.
          <br />
          You can import the LinkedIn data, then modify it as necessary.
        </p>
      ) : (
        <>{linkedinDataSet ? <p>LinkedIn data loaded successfully</p> : ""}</>
      )}
      <LinkClose to="/candidates">
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>
      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!resumeParseSelected ? (
            <StyledResumeSelection>
              {!resumeLoading ? (
                <DropFileUpload prepareResumeFile={prepareResumeFile}>
                  <Card
                    onClick={() => {
                      document.getElementById("resumeFile").click();
                    }}
                  >
                    <div className="mimic-input file-upload">
                      <input
                        type="file"
                        id="resumeFile"
                        name="resume"
                        style={{ display: "none" }}
                        onChange={e => prepareResumeFile(e)}
                      />
                      <ButtonResumeUpload type="button">
                        Upload Resume
                      </ButtonResumeUpload>
                    </div>
                    <span className="note" style={{ display: "block" }}>
                      {!resumeFile && ".docx, .pages and .pdf accepted"}
                      {resumeFile && resumeFile.error && (
                        <span className="error">{resumeFile.error}</span>
                      )}
                      {resumeFile && !resumeFile.error && (
                        <span
                          className={"filename " + resumeFile.ext.toLowerCase()}
                        >
                          <span className="ext">
                            {resumeFile.ext.toUpperCase()}
                          </span>
                          <span className="name">{resumeFile.name}</span>
                        </span>
                      )}
                    </span>
                  </Card>

                  <Card
                    className="no-resume"
                    onClick={() => setResumeParseSelected(true)}
                  >
                    <p>No Resume</p>
                  </Card>
                </DropFileUpload>
              ) : (
                <LoadingSubmit></LoadingSubmit>
              )}
            </StyledResumeSelection>
          ) : (
            <>
              {linkedinDataLoading ? (
                <div className="loading linkedin-loading">
                  <LoadingSubmit></LoadingSubmit>
                  <span style={{ marginLeft: "20px" }}>
                    loading data from LinkedIn
                  </span>
                </div>
              ) : (
                <ol>
                  <Form.Fieldset
                    legend="Candidate Information"
                    className="rows"
                  >
                    <Form.InputWrapper
                      label="First Name"
                      name="firstName"
                      errors={errors}
                      required={true}
                    >
                      <Form.TextInput
                        name="firstName"
                        type="text"
                        value={parsedInfo.firstName || ""}
                        forwardRef={register({ required: true })}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="Last Name"
                      name="lastName"
                      errors={errors}
                      required={true}
                    >
                      <Form.TextInput
                        name="lastName"
                        type="text"
                        value={parsedInfo.lastName || ""}
                        forwardRef={register({ required: true })}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="Mobile"
                      name="phone"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="phone"
                        type="text"
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="Personal Email"
                      name="personalEmail"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="emailPersonal"
                        type="email"
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="Work Email"
                      name="emailWork"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="emailWork"
                        type="email"
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                  </Form.Fieldset>
                  <Form.Fieldset className="rows stacked">
                    <Form.InputWrapper
                      label="LinkedIn"
                      name="linkedinUrl"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="linkedinUrl"
                        type="text"
                        value={parsedInfo.linkedinUrl || ""}
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    {resumeFile && (
                      <Form.InputWrapper
                        label="Resume"
                        name="resume"
                        errors={errors}
                        className="file-upload"
                      >
                        <div
                          className="mimic-input file-upload"
                          style={{
                            textAlign: "center",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span className="note">
                            {!resumeFile && ".docx, .pages and .pdf accepted"}
                            {resumeFile && resumeFile.error && (
                              <span className="error">{resumeFile.error}</span>
                            )}
                            {resumeFile && !resumeFile.error && (
                              <span
                                className={
                                  "filename " + resumeFile.ext.toLowerCase()
                                }
                                style={{ display: "block", padding: "1px 3px" }}
                              >
                                <span className="ext">
                                  {resumeFile.ext.toUpperCase()}
                                </span>
                                <span className="name">{resumeFile.name}</span>
                              </span>
                            )}
                          </span>
                        </div>
                      </Form.InputWrapper>
                    )}
                    <Form.InputWrapper
                      label="Year Graduated"
                      name="workExperience"
                      errors={errors}
                      style={{ verticalAlign: "top" }}
                      notes="Leave blank for no degree"
                    >
                      <Form.TextInput
                        name="workExperience"
                        type="number"
                        value={parsedInfo.workExperience || ""}
                        forwardRef={register}
                        onChange={onChange}
                        placeholder="yyyy"
                      />
                    </Form.InputWrapper>
                  </Form.Fieldset>
                  <Form.Fieldset className="rows stacked">
                    <Form.InputWrapper
                      label="City"
                      name="address['city']"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="address['city']"
                        forwardRef={register}
                        onChange={onChange}
                        value={locationInfo.city || ""}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="State"
                      name="address['state']"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="address['state']"
                        forwardRef={register}
                        onChange={onChange}
                        value={locationInfo.state || ""}
                      />
                    </Form.InputWrapper>

                    <Form.InputWrapper
                      label="Zip"
                      name="address['zip']"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="address['zip']"
                        type="text"
                        className="x-small"
                        forwardRef={register}
                        onChange={onChange}
                        placeholder=""
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="Country"
                      name="address['country']"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="address['country']"
                        type="text"
                        forwardRef={register}
                        onChange={onChange}
                        placeholder="USA"
                        className="x-small"
                      />
                    </Form.InputWrapper>
                  </Form.Fieldset>
                  {parsedInfo && parsedInfo.bio ? (
                    <Form.Fieldset className="rows stacked">
                      <Form.InputWrapper label="Bio" name="bio" errors={errors}>
                        <Form.Textarea
                          className="span-2"
                          name="bio"
                          value={parsedInfo.bio}
                          forwardRef={register}
                          onChange={onChange}
                        />
                      </Form.InputWrapper>
                    </Form.Fieldset>
                  ) : (
                    ""
                  )}

                  <Form.Fieldset className="rows stacked">
                    <Form.InputWrapper
                      label="Base Salary"
                      name="compensation"
                      errors={errors}
                      className="react-select position-title"
                    >
                      <Select
                        ref={register({ name: "compensation" })}
                        isClearable={true}
                        filterOption={(option, filter) => {
                          let regexp = new RegExp("^" + filter);
                          if (
                            !filter ||
                            (filter && option.value.toString().match(regexp))
                          ) {
                            return true;
                          }
                        }}
                        className="select"
                        classNamePrefix="position-title"
                        options={compensationBuild()}
                        value={selectValues.compensation}
                        placeholder=""
                        name="compensation"
                        onChange={onSelectChange}
                      />
                    </Form.InputWrapper>

                    <Form.InputWrapper
                      label="Immigration Status"
                      name="visa"
                      errors={errors}
                      className="react-select position-title"
                    >
                      <Select
                        ref={register({ name: "visa" })}
                        isClearable={true}
                        className="select"
                        classNamePrefix="position-title"
                        options={visaBuild}
                        value={selectValues.visa}
                        placeholder=""
                        name="visa"
                        onChange={onSelectChange}
                      />
                    </Form.InputWrapper>
                  </Form.Fieldset>
                  <Form.Fieldset
                    legend="Skills"
                    /*fieldsetActions={fieldsetActionsSkills()} CLIENT-DELETE*/
                    className="skills"
                  >
                    <li className="row group">
                      <ol>
                        <Form.InputWrapper
                          label="Tags"
                          name="tags"
                          errors={errors}
                          className="react-select tags"
                        >
                          <Select
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            ref={register({ name: "tags" })}
                            isMulti
                            isClearable={false}
                            className="multi-select span-2"
                            classNamePrefix="tags"
                            onChange={onSelectChange}
                            options={tags}
                            name="tags"
                            value={selectValues.tags}
                            placeholder=""
                          />
                        </Form.InputWrapper>
                      </ol>
                    </li>
                  </Form.Fieldset>
                  <Form.Fieldset
                    legend="Education"
                    fieldsetActions={fieldsetActionsEducation()}
                  >
                    {schools.length &&
                      schools.map((school, index) => (
                        <School key={school.key} school={school} />
                      ))}
                  </Form.Fieldset>
                  <Form.Fieldset legend="Current Position" className="rows">
                    <Form.InputWrapper
                      label="Company Name"
                      name="currentEmployments[companyName]"
                      errors={errors}
                      className="react-select position-title"
                    >
                      <Select
                        ref={register({
                          name: "currentEmployments[companyName]",
                        })}
                        value={selectValues["currentEmployments[companyName]"]}
                        onChange={onSelectChange}
                        name="currentEmployments[companyName]"
                        isClearable={true}
                        className="select"
                        classNamePrefix="position-title"
                        options={companies}
                        placeholder=""
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="Level"
                      name="currentEmployments[title]"
                      errors={errors}
                      className="react-select position-title"
                    >
                      <Select
                        ref={register({ name: "currentEmployments[title]" })}
                        value={selectValues["currentEmployments[title]"]}
                        onChange={onSelectChange}
                        name="currentEmployments[title]"
                        isClearable={true}
                        className="select"
                        classNamePrefix="position-title"
                        options={titles}
                        placeholder=""
                      />
                    </Form.InputWrapper>
                  </Form.Fieldset>
                  <Form.FieldsetActions>
                    {errorMessage && <Form.Error message={errorMessage} />}
                    {!formSubmission ? (
                      <>
                        <Button primary>Create Candidate</Button>
                        <Button type="button">
                          <Link to="/candidates">Cancel</Link>
                        </Button>
                      </>
                    ) : (
                      <LoadingSubmit></LoadingSubmit>
                    )}
                  </Form.FieldsetActions>
                </ol>
              )}
            </>
          )}
        </form>
      </StyledForm>
    </Container>
  );
}

const ButtonResumeUpload = styled(Button)`
  height: 40px;
  padding: 0 25px;
  position: relative;
  margin-left: 30px;
  &:before {
    content: "";
    vertical-align: middle;
    width: 32px;
    height: 32px;
    display: block;
    background: url(${imgUpload}) no-repeat;
    background-size: cover;
    position: absolute;
    top: 5px;
    left: -50px;
  }
`;
