import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { Form } from "../Shared/Form";
import { BackLink } from "../Shared/Common";
import { UserService } from "../../services/User";
import { AuthService } from "../../services/Auth";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Container, Button, PageTitle } from "../../styles/Common";
import { Form as StyledForm } from "../../styles/Form";
import styled from "styled-components";
import imgChevron from "../../assets/chevron.svg";

export default function UpdatePassword(props) {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);
  const jwtInfo = AuthService.jwtInfo();

  const [errorMessage, setErrorMessage] = useState("");

  //react-hook-form
  const { register, handleSubmit, errors } = useForm();

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  const onSubmit = async data => {
    //validate password match
    if (data.newPassword.trim() === data.confirmPassword.trim()) {
      data.resetToken = props.match.params.token;
      let response = await UserService.updatePassword({
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
        id: jwtInfo.user,
      });

      if (response._links) {
        //toast notificiation
        toast.success("Password has been successfully updated", {
          position: "bottom-center",
        });
        //redirect
        props.history.push("/settings");
      } else if (response.error) {
        setErrorMessage(response.error);
      } else {
        setErrorMessage("There was an error processing the update");
      }
    } else {
      setErrorMessage("The passwords did not match");
    }
  };

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");
  };

  return (
    <Container className="form">
      <BackLink to="/settings" verbiage="Back to settings" />
      <PageTitle>Update Password</PageTitle>

      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol>
            <Form.Fieldset>
              <Form.InputWrapper
                label="Old Password"
                name="oldPassword"
                errors={errors}
              >
                <Form.TextInput
                  name="oldPassword"
                  type="password"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="New Password"
                name="newPassword"
                errors={errors}
              >
                <Form.TextInput
                  name="newPassword"
                  type="password"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Confirm Password"
                name="confirmPassword"
                errors={errors}
              >
                <Form.TextInput
                  name="confirmPassword"
                  type="password"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.FieldsetActions>
              {errorMessage && <Form.Error message={errorMessage} />}
              <Button primary>Submit</Button>
            </Form.FieldsetActions>
          </ol>
        </form>
      </StyledForm>
    </Container>
  );
}
