import Config from "../config/config";

const list = inputData => {
  return fetch(
    Config.API_URL +
      "searches?" +
      (inputData.limit ? "limit=" + inputData.limit : "") +
      (inputData.offset ? "&offset=" + inputData.offset : ""),
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const search = inputData => {
  console.log("search called", inputData);
  return fetch(
    Config.API_URL +
      "search?" +
      (inputData.limit ? "limit=" + inputData.limit : "") +
      (inputData.offset ? "&offset=" + inputData.offset : ""),
    {
      method: "POST",
      body: JSON.stringify(inputData.searchJSON),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const save = inputData => {
  return fetch(Config.API_URL + "searches", {
    method: "POST",
    body: JSON.stringify(inputData.searchJSON),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const get = inputData => {
  return fetch(Config.API_URL + "search/" + inputData.id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const suggestions = inputData => {
  return fetch(
    Config.API_URL +
      "suggestions?q=" +
      inputData.query +
      "&limit=" +
      (inputData.limit ? inputData.limit : ""),
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const del = id => {
  return fetch(Config.API_URL + "search/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

export const SearchService = {
  list,
  search,
  save,
  del,
  get,
  suggestions,
};
