import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "../Shared/Form";
import { BackLink } from "../Shared/Common";
import { AuthService } from "../../services/Auth";
import { useForm } from "react-hook-form";
import { UserService } from "../../services/User";
import { EmailService } from "../../services/Email";
import styled from "styled-components";
import { Container, Button, PageTitle } from "../../styles/Common";
import { Input } from "../../styles/Form";
import { Email } from "../Shared/Email";
import { LoadingSubmit } from "../../styles/Animation";

const Styled = styled.div`
  .content {
    margin: 1em 0;
  }
  input {
    margin-right: 15px;
  }
`;

export default function AuthorizeEmail(props) {
  const jwtInfo = AuthService.jwtInfo();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const updateNylasCode = async code => {
    //update user
    let response = await UserService.nylasAuth({ code: code });
    if (!response.error) {
      toast.success("Email account has been successfully linked", {
        position: "bottom-center",
      });
    } else {
      setErrorMessage(
        "There was an error integrating email address: Database could not be updated"
      );
    }
    props.history.push("/settings/authorize-email");
  };

  useEffect(() => {
    //check if was sent back successfully from google account authorization
    let responseParts;
    if (props.location.search) {
      console.log(props.location.search);

      console.log("location", props.location);
      if (props.location.search.match(/error/)) {
        responseParts = props.location.search.split("error=");
        setErrorMessage(
          "There was an error integrating email address: " + responseParts[1]
        );
      } else if (props.location.search.match(/code/)) {
        responseParts = props.location.search.split("=");
        updateNylasCode(responseParts[1]);
      }
    }
  }, []);

  //react-hook-form
  const { register, handleSubmit } = useForm();

  const onSubmit = async data => {
    console.log("submitted", data);
    setLoading(true);
    setErrorMessage("");
    if (data.email) {
      let response = await EmailService.authorize({ email: data.email });
      if (response.auth_url) {
        //send client to nylas redirected url to choose google account
        window.location.href = response.auth_url;
      }
    }
  };

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");
  };

  return (
    <Container className="form">
      <BackLink to="/settings" verbiage="Back to settings" />
      <PageTitle>Authorize Email</PageTitle>
      <Styled>
        <div className="content">
          <p>
            Authorize your email address to work with <b>Nylas</b> for calendar
            syncing and email integration
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input>
            <Form.TextInput
              name="email"
              type="email"
              value={jwtInfo.email}
              forwardRef={register({ required: true })}
              onChange={onChange}
            />
            {!loading ? <Button primary>Authorize</Button> : ""}
            {loading ? <LoadingSubmit size={20}></LoadingSubmit> : ""}
          </Input>
          {errorMessage && <Form.Error message={errorMessage} />}
        </form>
      </Styled>
    </Container>
  );
}
