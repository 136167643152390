import Config from "../config/config";

const get = id => {
  return fetch(Config.API_URL + "uploads/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const create = inputData => {
  let formData = new FormData();
  formData.append("fileData", inputData.file);
  formData.append("name", inputData.file["name"]);

  return fetch(
    Config.API_URL +
      "uploads/" +
      inputData.type +
      "/" +
      inputData.id +
      "?documentType=" +
      inputData.documentType,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const del = id => {
  return fetch(Config.API_URL + "uploads/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const resumeParser = file => {
  let formData = new FormData();
  formData.append("fileData", file);

  return fetch(Config.API_URL + "uploads/resume", {
    method: "POST",
    body: formData,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const getUrl = async id => {
  let response = await DocumentService.get(id);
  if (response.url) {
    window.open(response.url, "mullings");
  } else {
    return false;
  }
};

const createProfile = inputData => {
  let formData = new FormData();
  formData.append("fileData", inputData.file);

  return fetch(
    Config.API_URL +
      "uploads/" +
      inputData.type +
      "/" +
      inputData.id +
      "/profile_picture",
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

//LINKED IN
const linkedIn = inputData => {
  return fetch(Config.API_URL + "linkedin?linkedinUrl=" + inputData.url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

export const DocumentService = {
  create,
  createProfile,
  resumeParser,
  get,
  getUrl,
  del,
  linkedIn,
};
