import React, { useState } from "react";
import styled from "styled-components";
import { DocumentService } from "../../services/Document";
import { LoadingSubmit } from "../../styles/Animation";
import imgProfileMissing from "../../assets/profile-missing.svg";

const StyledProfileUpload = styled.div`
  .saving {
    background: #fff;
    width: 110px;
    height: 110px;
    text-align: center;
    line-height: 98px;
    padding-right: 9px;
    box-sizing: border-box;
  }
  background: #ddd;
  grid-area: img;
  margin-right: 30px;
  width: 110px;
  max-height: 110px;
  line-height: 98px;
  overflow: hidden;
  text-align: center;
  align-self: flex-start;
  img {
    vertical-align: middle;
  }
`;

/*
 * Quick Notes
 */
export const ProfileImage = ({ detail, entity, setEntity }) => {
  entity.documents = entity.documents || [];

  let profile = entity.documents.find(
    d => d.documentType === "profile_picture"
  );

  //upload file
  const [fileUploading, setFileUploading] = useState(false);
  const uploadFile = async e => {
    setFileUploading(true);

    let file = e.target.files[0];

    //process via api
    let response = await DocumentService.createProfile({
      type: detail.type,
      id: entity.id,
      file: file,
    });

    if (response.id) {
      let file = {
        documentId: response.id,
        url: response._embedded.url,
        name: response._embedded.fileName,
        createdAt: new Date().getTime(),
        uploadedBy: response._embedded.uploadedBy,
        documentType: "profile_picture",
      };

      entity.profilePicture = file.url;
      entity.documents.push(file);

      setEntity({ ...entity });
      //TODO: maybe set up toastify
    } else {
      //error
    }
    setFileUploading(false);
  };

  return (
    <StyledProfileUpload
      className={"profile-pic" + (fileUploading ? " saving" : "")}
    >
      {!fileUploading ? (
        <>
          <div>
            <img
              src={
                entity.profilePicture
                  ? entity.profilePicture
                  : imgProfileMissing
              }
              width={entity.profilePicture ? "110" : 50}
              alt=""
              title=""
              onClick={
                detail.type === "positions"
                  ? null
                  : () => {
                      document.getElementById("profileToUpload").click();
                    }
              }
            />
          </div>
          <input
            type="file"
            id="profileToUpload"
            style={{ display: "none" }}
            onChange={e => uploadFile(e)}
          />
        </>
      ) : (
        <div className="saving">
          <LoadingSubmit size={"30"}></LoadingSubmit>
        </div>
      )}
    </StyledProfileUpload>
  );
};
