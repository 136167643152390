export const Colors = {
  primary: "#515b69",
  primary_accent: "#5c6062",
  secondary: "#000000",
  highlight: "#82CE36",
  gray: "#eaeff3",
  gray_light: "#8792a3",
  gray_accent_opaque: "#c6cbd350",
  gray_accent: "#c6cbd3",
  error: "#ff0000",
  warn: "#ffa300",
  success: "green",
  urgency_onhold: "#8a5e09",
  urgency_low: "#5fb540",
  urgency_high: "#DB3537",
  urgency_medium: "#F1C82C",
};
