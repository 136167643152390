import React, { useState, useEffect, useMemo, useParams } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { AuthService } from "../../services/Auth";
import { UserService } from "../../services/User";
import { BackLink } from "../Shared/Common";

import { Colors } from "../../styles/Colors";
import styled from "styled-components";
import { Container, PageTitle, Button } from "../../styles/Common";
import { Form as StyledForm } from "../../styles/Form";
import { Form } from "../Shared/Form";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Styled = styled.div`
  .items {
    ul {
      column-count: 4;
      column-gap: 20px;
    }
    li {
      padding: 2px 0 2px 10px;
      break-inside: avoid;
      border-left: 1px solid ${Colors.gray};
      display: flex;
      justify-content: space-between;
      border-radius: 3px;
      span {
        margin-bottom: 10px;
      }
      &:hover {
        background: #eee;
      }
      input,
      select {
        margin-right: 5px;
        border: 1px solid ${Colors.gray};
      }
      button {
        margin-right: 0;
      }
    }
  }
`;

export default function Roles(props) {
  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    reset,
  } = useForm();

  //users
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [currentUserId, setCurrentId] = useState();

  useEffect(() => {
    const getRoles = async () => {
      let response = await UserService.roles({});
      if (response._embedded) {
        console.log("roles", response._embedded);
        setRoles(
          response._embedded.map(r => {
            return {
              label: r.name,
              value: r.roleId,
            };
          })
        );
      }
    };
    const getUsers = async () => {
      let response = await UserService.list({
        limit: 1000,
        sortBy: "lastName.asc",
      });

      if (response._embedded) {
        console.log("users", response._embedded);
        setUsers(
          response._embedded.map(u => {
            return {
              label: u.firstName + " " + u.lastName,
              value: u.id,
            };
          })
        );
      }
    };
    getUsers();
    getRoles();
  }, []);
  //roles

  const onUserChange = async (opt, actionMeta) => {
    console.log("opt", opt);
    console.log("actionMeta", actionMeta);

    //get and display the specific users roles
    let response = await UserService.get(opt.value);
    console.log("user roles", response.accountRoles);
    if (!response.errors) {
      setUserRoles(
        roles.map(r => {
          r.checked = response.accountRoles.includes(r.value) ? true : false;
          return r;
        })
      );
      setCurrentId(opt.value);
    } else {
      toast.error("User was not found", { position: "bottom-center" });
    }
  };

  const onChange = e => {
    let response;
    if (e.currentTarget.checked) {
      response = UserService.addRole({
        id: currentUserId,
        roleId: e.currentTarget.value,
      });
      toast.success("Role successfully added", { position: "bottom-center" });
    } else {
      response = UserService.deleteRole({
        id: currentUserId,
        roleId: e.currentTarget.value,
      });
      toast.success("Role successfully deleted", { position: "bottom-center" });
    }
  };

  //first select user, then show associated roles... click change as necessary
  return (
    <Styled>
      <Container className="form">
        <BackLink to="/settings" verbiage="Back to settings" />
        <PageTitle>Account Roles</PageTitle>

        <StyledForm>
          <form>
            <ol>
              <Form.Fieldset>
                <Form.InputWrapper
                  label="Select User"
                  name="user"
                  errors={errors}
                  className="react-select position-title"
                >
                  <Select
                    ref={register}
                    name="user"
                    isClearable={false}
                    className="select"
                    classNamePrefix="position-title"
                    options={users}
                    placeholder=""
                    onChange={onUserChange}
                  />
                </Form.InputWrapper>

                {currentUserId ? (
                  <Form.InputWrapper
                    className="row"
                    name="accountRoles"
                    errors={errors}
                    label="Current Roles"
                  >
                    <Form.Checkboxes
                      name="accountRoles"
                      forwardRef={register}
                      options={userRoles}
                      setOptions={setUserRoles}
                      onChange={onChange}
                    />
                  </Form.InputWrapper>
                ) : (
                  ""
                )}
              </Form.Fieldset>
            </ol>
          </form>
        </StyledForm>
      </Container>
    </Styled>
  );
}
