import React, { useState, useEffect, useCallback, useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import device from "../../config/device";
import { AuthService } from "../../services/Auth";
import { DateTime } from "../../helpers/DateTime";
import { formatUUID } from "../../helpers/Display";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { RenderTable } from "../Shared/ListTable";
import { Form } from "../Shared/Form";
import styled from "styled-components";
import {
  Container,
  Table,
  TableHeader,
  TableEmpty,
  PageTitle,
} from "../../styles/Common";
import { Input } from "../../styles/Form";

import { PositionService } from "../../services/Position";
import { UserService } from "../../services/User";

const Styled = styled.div`
  .filters {
    justify-self: end;
  }
  @media ${device.laptopMax} {
    .positions {
      grid-template-columns: auto auto 1fr;
      grid-template-rows: auto auto;
    }

    .search {
      padding: 15px 0 10px;
      grid-area: 2 / 1 / 3 / 4;
      input[type="text"] {
        width: 100%;
        box-sizing: border-box;
      }
      &:after,
      .go {
        top: 38px !important;
      }
    }
  }
  @media ${device.tabletMMax} {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    .filters {
      margin-top: 10px;
      justify-self: start;
      grid-area: 2 / 1 / 3 / 4;
      button {
        margin-bottom: 10px;
      }
    }
    .search {
      grid-area: 3 / 1 / 4 / 4;
    }
  }
  @media ${device.tabletLMax} {
    .positions {
      .filters {
        grid-area: 2 / 1 / 3 / 2;
      }
      .search {
        grid-area: 3 / 1 / 4 / 4;
      }
    }
  }
  @media ${device.mobileLMax} {
    .positions {
      display: block;
      a {
      }
    }
  }
`;
export default function PositionList(props) {
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );
  const jwtInfo = AuthService.jwtInfo();

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [filter, setFilter] = useState({
    users: {
      owner: jwtInfo.user,
      primary: jwtInfo.user,
      secondary: jwtInfo.user,
    },
  });

  //get candidates
  const fetchData = useCallback(({ pageIndex, pageSize, filter }) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      getData({ pageIndex, pageSize, filter });
    }
  }, []);

  const getData = async ({ pageIndex, pageSize, filter }) => {
    let _items = await PositionService.list({
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter: filter,
    });

    let _data = [];
    _items._embedded &&
      _items._embedded.forEach(i => {
        let daysSinceKickOff = DateTime.timeSince(
          new Date(i.kickoff),
          new Date()
        );
        _data.push({
          id: i.id,
          formattedId: formatUUID(i.id, "position"),
          urgency:
            i.urgency.length > 0
              ? `${i.urgency.slice(0, 1).toUpperCase()}${i.urgency.slice(1)}`
              : i.urgency,
          kickOff: i.kickoff ? DateTime.formatDate(new Date(i.kickoff)) : "",
          daysSinceKickOff: daysSinceKickOff > 0 ? daysSinceKickOff : "-",
          owner: i.ownerInfo
            ? i.ownerInfo.lastName + ", " + i.ownerInfo.firstName
            : "",
          primary: i.primaryInfo
            ? i.primaryInfo.lastName + ", " + i.primaryInfo.firstName
            : "",
          secondary: i.secondaryInfo
            ? i.secondaryInfo.lastName + ", " + i.secondaryInfo.firstName
            : "",
          main: i.title,
          company: i.company,
          // stage: i.stage,
          jobStatus: i.status, // should have check
        });
      });
    setData(_data);

    setPageCount(Math.ceil(_items.totalCount / pageSize));
  };
  //STATUSES
  const [statusSelected, setStatusSelected] = useState("Any");
  const statuses = [
    { label: "Any", value: "" },
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
    { label: "On Hold", value: "On Hold" },
  ];
  //ALL USERS
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userSelected, setUserSelected] = useState({
    id: jwtInfo.user,
    firstName: jwtInfo.firstName,
    lastName: jwtInfo.lastName,
  });

  const getUsers = async () => {
    let response = await UserService.list({
      limit: 1000,
      sortBy: "lastName.asc",
      filter: {
        search: {
          NOT: {
            field: "password",
            value: "^$"
          }
        }
      }
    })

    if (response._embedded) {
      setUsers([
        { firstName: "All", lastName: "Users", id: "allUsers" },
        ...response._embedded,
      ]);
      setUserSelected(response._embedded.find(u => u.id === jwtInfo.user));
      setFilteredUsers([
        { firstName: "All", lastName: "Users", id: "allUsers" },
        ...response._embedded.filter(u => u.id !== jwtInfo.user),
      ]);
    }
  };
  const setUser = async user => {
    //user.selected = true;
    setUserSelected(user);
    setFilteredUsers([...users.filter(u => u !== user)]);
    if (user.id === "allUsers") {
      setFilter({
        ...filter,
        users: undefined,
      });
    } else {
      setFilter({
        ...filter,
        users: { owner: user.id, secondary: user.id, primary: user.id },
      });
    }
  };

  const filterStatus = status => {
    setStatusSelected(status.label);
    setFilter({ ...filter, status: status.value ? status.value : undefined });
  };

  const filterUserDropdown = e => {
    let value = e.target.value.toLowerCase();

    let regex = new RegExp(value, "i");
    setFilteredUsers([
      ...users.filter(u => {
        if (u.firstName.match(regex) || u.lastName.match(regex)) {
          return u;
        }
      }),
    ]);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "ID",
      accessor: "formattedId",
    },
    {
      Header: "Client",
      accessor: "company",
    },
    {
      Header: "Position",
      accessor: "main",
    },
    /*{
      Header: "Stage",
      accessor: "stage"
    }, */
    {
      Header: "Urgency",
      accessor: "urgency",
    },
    {
      Header: "Kick Off",
      accessor: "kickOff",
    },
    {
      Header: "Days Since Kick Off",
      accessor: "daysSinceKickOff",
    },
    {
      Header: "Primary",
      accessor: "primary",
    },
    {
      Header: "Secondary",
      accessor: "secondary",
    },
    {
      Header: "Owner",
      accessor: "owner",
    },
    {
      Header: "Job Status",
      accessor: "jobStatus",
    },
  ];

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Styled>
      {users.length ? (
        <RenderTable
          detail={{
            title: "Job Orders",
            name: "position",
            name_plural: "job orders",
            url: "position",
            filterableColumns: ["title"],
          }}
          data={data}
          columns={columns}
          heading={{
            title: true,
            search: true,
            createNew: true,
            className: "positions",
          }}
          pageCount={pageCount}
          fetchData={fetchData}
          filter={filter}
          setFilter={setFilter}
          statuses={statuses}
          statusSelected={statusSelected}
          setStatusSelected={setStatusSelected}
          filterUserDropdown={filterUserDropdown}
          userSelected={userSelected}
          filteredUsers={filteredUsers}
          setUser={setUser}
          users={users}
          filterStatus={filterStatus}
        />
      ) : (
        ""
      )}
    </Styled>
  );
}
