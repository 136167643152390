import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { TagService } from "../../services/Tag";
import { CandidateService } from "../../services/Candidate";
import { PositionService } from "../../services/Position";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";
import { Button, ButtonClose, Card } from "../../styles/Common";
import { Colors } from "../../styles/Colors";
import { Form } from "../Shared/Form";
import { Validate } from "../Shared/Validate";
import { Input } from "../../styles/Form";
import { Form as StyledForm } from "../../styles/Form";
import { LoadingSubmit } from "../../styles/Animation";
import imgX from "../../assets/x.svg";

const StyledHiringManagers = styled.div`
  fieldset {
    margin-top: 30px;
  }
  .actions {
    margin: 0 0 0 auto;
  }
`;

export const HiringManagers = ({ position, setPosition }) => {
  console.log("potions", position);
  //react-hook-form
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
    reset,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState("");
  const [candidates, setCandidates] = useState([]);

  //get candidates
  const getCandidates = async () => {
    let _candidates = await CandidateService.list({ limit: 10000 });
    console.log("_candidates", _candidates);
    if (_candidates._embedded) {
      setCandidates(
        _candidates._embedded
          .filter(c => {
            if (
              !position.hiringManagers ||
              (position.hiringManagers &&
                !position.hiringManagers.find(
                  hm => hm.candidateId === c.candidateId
                ))
            ) {
              return c;
            }
          })
          .map(c => {
            return {
              value: c.candidateId,
              label: c.firstName + " " + c.lastName,
              email: c.emailPersonal ? c.emailPersonal : c.emailWork,
              phone: c.phone,
            };
          })
      );
    }
  };
  useEffect(() => {
    getCandidates();
  }, []);

  //set/reset manager entry
  const resetItem = (initial, index) => {
    let initial_index = index ? index : Math.random();
    return {
      initial: initial,
      index: initial_index,
      key: initial_index,
      candidateId: "",
      candidate: null,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      focus: "",
      isPrimary: initial ? true : false,
    };
  };
  //const [hiringManagers, setItems] = useState([resetItem(true, false)]);
  const [hiringManagers, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const addItem = () => {
    //set them, then add new item to the mix
    setItems([...setItemInputValues(), resetItem(false, false)]);
  };
  const deleteItem = item => {
    //set values, then unregister input hooks
    setItems(setItemInputValues().filter(e => e.index != item.index));
    unregister("hiringManagers[" + item.index + "][firstName]");
    unregister("hiringManagers[" + item.index + "][lastName]");
    unregister("hiringManagers[" + item.index + "][phone]");
    unregister("hiringManagers[" + item.index + "][email]");
    unregister("hiringManagers[" + item.index + "][isPrimary]");
  };

  //radio check for primary hiring manager
  const setHiringManagerPrimary = item => {
    setItems([
      ...hiringManagers.map(i => {
        if (i === item) {
          i.isPrimary = true;
        } else {
          i.isPrimary = false;
        }
        return i;
      }),
    ]);
    //setItemInputValues();
  };
  //create new select option
  const onCreateHiringManager = (value, item) => {
    console.log("value", value);
    let ele = document.getElementsByName(
      "hiringManagers[" + item.index + "][candidateId]"
    )[0];
    console.log("ele", ele);
    ele.value = "NEW MANAGER-" + value;
    setItems([...setItemInputValues()]);

    return;
  };

  const selectHiringManager = (opt, actionMeta, item) => {
    let ele = document.getElementsByName(actionMeta.name)[0];
    if (actionMeta.action === "clear") {
      ele.value = "";
    } else {
      ele.value = opt.value;
    }
    setItems([...setItemInputValues()]);
  };

  const setItemInputValues = () => {
    //need to get all current values of inputs before rewriting
    let inputs = document.querySelectorAll('input[name^="hiringManagers"]'); //, [id^="hiringManagers-"] input
    let _items = [];

    let _id,
      id = "";
    let _buildItem;
    let i = 0;
    let nodes = Array.from(inputs);
    console.log("nodes", nodes);
    let numNodes = nodes.length;
    let numInGroup = 0;
    let temp_id = "";
    let r = 1; //iterates to know when next item starts based on prev id being the same
    nodes.forEach((input, x) => {
      //seleced candidate from list
      id = input.name.split("][")[0].split("hiringManagers[")[1];
      if (temp_id !== id) {
        r = 1;
        temp_id = id;
      }
      numInGroup = nodes.filter(n => n.name.match(new RegExp(id))).length;
      console.log("numingroup", numInGroup);

      console.log("id", id);
      if (i === 0 && x === 0) {
        _buildItem = resetItem(true, id);
      }
      _buildItem.index = _buildItem.key = id;
      //get changed value
      //set value for react hook
      if (input.name.match(/candidateId/)) {
        console.log("input", input);
        console.log("candidateId", _buildItem.candidateId);
        console.log("input?", input.value);

        //create a new one!
        if (input.value.match(/NEW MANAGER-/)) {
          console.log("what");
          _buildItem.candidateId = false;
          _buildItem.firstName = input.value.split("-")[1];
          _buildItem.focus = "lastName";
        } else {
          console.log("huh?");
          //fill in candidate
          _buildItem.firstName = ""; //reset if needed
          _buildItem.candidateId = input.value;
          _buildItem.candidate = candidates.find(
            c => c.value === _buildItem.candidateId
          );
        }
        console.log("here right?", _buildItem.candidate);
      } else if (input.name.match(/firstName/)) {
        _buildItem.firstName = input.value;
        if (input.value !== hiringManagers[i].firstName) {
          _buildItem.focus = "lastName";
        }
      } else if (input.name.match(/lastName/)) {
        _buildItem.lastName = input.value;
        if (input.value !== hiringManagers[i].lastName) {
          _buildItem.focus = "phone";
        }
      } else if (input.name.match(/phone/)) {
        _buildItem.phone = Validate.Phone(input.value);
        if (input.value !== hiringManagers[i].phone) {
          _buildItem.focus = "email";
        }
      } else if (input.name.match(/email/)) {
        _buildItem.email = input.value;
      } else if (input.name.match(/isPrimary/)) {
        console.log("input", input.checked);
        _buildItem.isPrimary = input.checked;
      }

      _id = id;
      if (_id && r % numInGroup === 0) {
        _items.push(_buildItem);
        _buildItem = resetItem(false);
        i++;
      }
      r++;
    });

    return _items;
  };
  /*
   * Button to add new hiring manager
   */
  const fieldsetActionsHiringManager = () => {
    return (
      <Button
        type="button"
        fieldsetAction
        size="small"
        style={{ marginBottom: "14px" }}
        onClick={() => addItem()}
      >
        Add Hiring Manager
      </Button>
    );
  };

  const onChange = e => {
    if (e.target.name.match(/phone/)) {
      e.target.value = Validate.Phone(e.target.value);
    }
    //reset any errors
    setErrorMessage("");
  };
  //Submit Form
  const onSubmit = async data => {
    setLoading(true);
    console.log("data before", data);

    data.hiringManagers = [];
    let _hiringManagers = []; //for adding to position upon creation

    if (hiringManagers.length) {
      hiringManagers.map(s => {
        let _hiringManager = {};
        if (s.firstName.trim()) {
          //could be blank
          _hiringManager = {
            candidateId: undefined,
            firstName: s.firstName,
            lastName: s.lastName,
            phone: s.phone,
            email: s.email,
            isPrimary: s.isPrimary,
          };
          _hiringManagers.push(_hiringManager);
          data.hiringManagers.push(_hiringManager);
        } else if (s.candidateId !== "false" && s.candidateId) {
          let candidate = candidates.find(c => c.value === s.candidateId);
          _hiringManager = {
            candidateId: s.candidateId,
            isPrimary: s.isPrimary,
          };
          _hiringManagers.push({
            ..._hiringManager,
            firstName: candidate.label.split(" ")[0],
            lastName: candidate.label.split(" ")[1],
          });

          data.hiringManagers.push(_hiringManager);
        }
      });
    }

    //post to database
    let error = false;
    for (const hm of data.hiringManagers) {
      let _hmResponse = await PositionService.createHiringManager({
        ...hm,
        id: position.id,
      });

      if (_hmResponse.error) {
        error = true;
      } else {
        let newHiringManager = {
          ..._hmResponse._embedded,
          hiringManagerId: _hmResponse.id,
        };

        //add to position
        position.hiringManagers = [
          ...(position.hiringManagers ? position.hiringManagers : []),
          newHiringManager,
        ];
      }
    }
    setItems([]);

    setLoading(false);
    if (error) {
      setErrorMessage("There were errors adding hiring managers");
    } else {
      setItems([]);

      setPosition({ ...position });
      //toast notificiation
      toast.success("Hiring managers have been updated", {
        position: "bottom-center",
      });
    }
  };

  const HiringManager = props => {
    return (
      <li className="row group">
        <ol>
          {props.item.candidateId !== "false" &&
          props.item.candidateId !== false ? (
            <Form.InputWrapper
              label="Select Manager"
              name={"hiringManagers[" + props.item.index + "][candidateId]"}
              errors={errors}
              className="react-select position-title"
            >
              <CreatableSelect
                id={"hiringManagers-" + props.item.index}
                name={"hiringManagers[" + props.item.index + "][candidateId]"}
                isClearable={true}
                createOptionPosition="first"
                className="select"
                classNamePrefix="position-title"
                options={candidates}
                placeholder=""
                value={
                  props.item.candidateId
                    ? {
                        label: props.item.candidate
                          ? props.item.candidate.label
                          : "",
                        value: props.item.candidateId,
                      }
                    : null
                }
                onCreateOption={value =>
                  onCreateHiringManager(value, props.item)
                }
                onChange={(opt, actionMeta) =>
                  selectHiringManager(opt, actionMeta, props.item)
                }
              />
            </Form.InputWrapper>
          ) : (
            <input
              type="hidden"
              value="false"
              name={"hiringManagers[" + props.item.index + "][candidateId]"}
            />
          )}

          {props.item.candidateId === false ||
          props.item.candidateId === "false" ? (
            <Form.InputWrapper
              label="First Name"
              name={"hiringManagers[" + props.item.index + "][firstName]"}
              errors={errors}
            >
              <Form.TextInput
                name={"hiringManagers[" + props.item.index + "][firstName]"}
                type="text"
                className="smaller"
                value={props.item.firstName}
                onBlur={() => setItems(setItemInputValues)}
                focus={props.item.focus === "firstName"}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}

          {props.item.candidateId === false ||
          props.item.candidateId === "false" ? (
            <Form.InputWrapper
              label={props.item.candidateId === false ? "Last Name" : "Name"}
              name={"hiringManagers[" + props.item.index + "][lastName]"}
              errors={errors}
            >
              <Form.TextInput
                name={"hiringManagers[" + props.item.index + "][lastName]"}
                type="text"
                className="smaller"
                value={props.item.lastName}
                onBlur={() => setItems(setItemInputValues)}
                focus={props.item.focus === "lastName"}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Phone"
              name={"hiringManagers[" + props.item.index + "][phone]"}
              errors={errors}
            >
              {props.item.candidateId === false ||
              props.item.candidateId === "false" ? (
                <Form.TextInput
                  name={"hiringManagers[" + props.item.index + "][phone]"}
                  type="text"
                  className="smaller"
                  value={props.item.phone}
                  onBlur={() => setItems(setItemInputValues)}
                  focus={props.item.focus === "phone"}
                  onChange={onChange}
                />
              ) : (
                <div className="static">
                  {props.item.candidate.phone
                    ? props.item.candidate.phone
                    : "-"}
                </div>
              )}
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Email"
              name={"hiringManagers[" + props.item.index + "][email]"}
              errors={errors}
            >
              {props.item.candidateId === false ||
              props.item.candidateId === "false" ? (
                <Form.TextInput
                  name={"hiringManagers[" + props.item.index + "][email]"}
                  type="email"
                  className="smaller"
                  value={props.item.email}
                  onBlur={() => setItems(setItemInputValues)}
                  focus={props.item.focus === "email"}
                />
              ) : (
                <div className="static">
                  {props.item.candidate.email
                    ? props.item.candidate.email
                    : "-"}
                </div>
              )}
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Primary?"
              name={"hiringManagers[" + props.item.index + "][isPrimary]"}
              errors={errors}
            >
              <Form.RadioButton
                id={props.item.index}
                name={"hiringManagers[" + props.item.index + "][isPrimary]"}
                option={{
                  value: props.item.isPrimary ? 1 : 0,
                  checked: props.item.isPrimary,
                }}
                onClick={() => setHiringManagerPrimary(props.item)}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}
        </ol>
        {!props.item.initial && (
          <ButtonClose
            style={{ marginTop: 34 }}
            type="button"
            onClick={() => deleteItem(props.item)}
          >
            <img src={imgX} alt="Delete" title="Delete" />
          </ButtonClose>
        )}
      </li>
    );
  };

  return (
    <StyledHiringManagers>
      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol>
            <Form.Fieldset fieldsetActions={fieldsetActionsHiringManager()}>
              {hiringManagers && hiringManagers.length
                ? hiringManagers.map((hiringManager, index) => (
                    <HiringManager
                      key={hiringManager.key}
                      item={hiringManager}
                    />
                  ))
                : ""}
            </Form.Fieldset>

            {hiringManagers && hiringManagers.length ? (
              <Form.FieldsetActions>
                {errorMessage && <Form.Error message={errorMessage} />}
                {!loading ? (
                  <>
                    <Button primary>Save</Button>
                  </>
                ) : (
                  <LoadingSubmit></LoadingSubmit>
                )}
              </Form.FieldsetActions>
            ) : (
              ""
            )}
          </ol>
        </form>
      </StyledForm>
    </StyledHiringManagers>
  );
};
