import Config from "../config/config";

const getDashboardData = inputData => {
  return fetch(Config.API_URL + "reports/dashStats", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

export const ReportService = {
  getDashboardData,
};
