import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../services/Auth";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Container, Button, PageTitle } from "../../styles/Common";

const Styled = styled.div`
  display: flex;
  ul {
    display: flex;
    flex-direction: column;
    margin: 0 50px 0 0;
    &:nth-child(2) {
      padding: 0 0 0 50px;
      border-left: 1px solid #eee;
    }
  }
  li {
    cursor: pointer;
    margin: 0 0 20px;
  }
  button {
    width: 100%;
  }
`;
export default function SettingsIndex() {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);

  const jwtInfo = AuthService.jwtInfo();

  const [errorMessage, setErrorMessage] = useState("");
  const [userCreated, setUserCreated] = useState(false);

  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    setValue,
    reset,
  } = useForm();

  const onSubmit = async data => {
    console.log("submitted", data);
  };

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");
  };

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Container className="form">
      <PageTitle>Settings</PageTitle>
      <Styled>
        <ul>
          {jwtInfo && jwtInfo.roles.includes("Admin") ? (
            <li>
              <Link to="/settings/add-user">
                <Button type="button">Add User</Button>
              </Link>
            </li>
          ) : (
            ""
          )}
          {jwtInfo && jwtInfo.roles.includes("Admin") ? (
              <li>
                <Link to="/settings/remove-user">
                  <Button type="button">Remove User</Button>
                </Link>
              </li>
          ) : (
              ""
          )}
          {jwtInfo && jwtInfo.roles.includes("Admin") ? (
            <>
              <li>
                <Link to="/settings/reset-users-password">
                  <Button type="button">Reset User&apos;s Password</Button>
                </Link>
              </li>
              <li>
                <Link to="/settings/admin/roles">
                  <Button type="button">Manage User Roles</Button>
                </Link>
              </li>
            </>
          ) : (
            ""
          )}

          <li>
            <Link to="/settings/update-password">
              <Button type="button">Update Password</Button>
            </Link>
          </li>
          <li>
            <Link to="/settings/authorize-email">
              <Button type="button">Authorize Email</Button>
            </Link>
          </li>
        </ul>
        {jwtInfo && jwtInfo.roles.includes("Admin") ? (
          <ul>
            <li>
              <Link to="/settings/admin/companies">
                <Button type="button">Manage Companies</Button>
              </Link>
            </li>
            <li>
              <Link to="/settings/admin/candidates">
                <Button type="button">Manage Candidates</Button>
              </Link>
            </li>
            <li>
              <Link to="/settings/admin/positions">
                <Button type="button">Manage Positions</Button>
              </Link>
            </li>
            <li>
              <Link to="/settings/admin/titles">
                <Button type="button">Manage Titles</Button>
              </Link>
            </li>
            <li>
              <Link to="/settings/admin/tags">
                <Button type="button">Manage Tags</Button>
              </Link>
            </li>
            <li>
              <Link to="/settings/admin/bd-activity-tags">
                <Button type="button">Manage BD Activity Tags</Button>
              </Link>
            </li>
            <li>
              <Link to="/settings/admin/degrees">
                <Button type="button">Manage Degrees</Button>
              </Link>
            </li>
            <li>
              <Link to="/settings/admin/news-sources">
                <Button type="button">Manage News Sources</Button>
              </Link>
            </li>
          </ul>
        ) : (
          ""
        )}
      </Styled>
    </Container>
  );
}
