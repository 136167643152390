import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { AuthService } from "../../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import device from "../../../config/device";
import {
  camelCase as _camelCase,
  orderBy as _orderBy,
  truncate as _truncate,
} from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { ConferenceService } from "../../../services/Conference";
import { ContactService } from "../../../services/Contact";
import { DateTime } from "../../../helpers/DateTime";
import { DocumentService } from "../../../services/Document";
import { TitleService } from "../../../services/Title";
import { CompanyService } from "../../../services/Company";
import { PositionService } from "../../../services/Position";
import { DegreeService } from "../../../services/Degree";
import { InterviewService } from "../../../services/Interview";
import { Activities } from "../../Shared/DetailActivities";
import { DocumentUpload } from "../../Shared/DocumentUpload";
import { ProfileImage } from "../../Shared/ProfileImage";

import { UpdatableEntity } from "../../Shared/UpdatableEntity";
import { UpdateableInfo } from "../../Shared/UpdateableInfo";
import { ParsedData } from "../../Shared/ParsedData";
import { parseLinkedInData } from "../../../helpers/Parser";
import { Colors } from "../../../styles/Colors";
import { addToPageHistory } from "../../../helpers/Search";
import {
  formatAddress,
  formatAddressWithStreet,
  formatCompensation,
  formatUUID,
} from "../../../helpers/Display";
import { LoadingSubmit } from "../../../styles/Animation";

//import { Tags as StyledTags } from "../../../styles/Form";
import {
  Container,
  Button,
  Card,
  ButtonDropdown,
  ButtonClose,
  Editable,
  Table,
  MediaTable,
} from "../../../styles/Common";
import { Form } from "../../../components/Shared/Form";
import { Form as StyledForm, Input } from "../../../styles/Form";
import { Dropdown } from "../../../components/Shared/Common";
import styled from "styled-components";
import imgChevron from "../../../assets/chevron.svg";
import imgDocument from "../../../assets/document.svg";
import imgLinkedIn from "../../../assets/linkedin.svg";
import imgPhone from "../../../assets/phone.svg";
import imgMail from "../../../assets/mail.svg";
import imgCircleCheck from "../../../assets/circle-check.svg";
import imgSend from "../../../assets/send.svg";
import imgX from "../../../assets/x.svg";
import imgCircleX from "../../../assets/circle-x.svg";
import imgExternal from "../../../assets/external.svg";
import { toast, ToastPosition } from "react-toastify";

import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";

moment.locale("en");
momentLocalizer();

const Styled = styled.div`
  display: flex;
  &:before {
    content: "";
    display: block;
    height: 256px;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background-image: linear-gradient(0deg, #5f6a7b 0%, #515b69 100%);
    box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
    background: ${Colors.primary};
  }
  @media ${device.laptopLMax} {
    &:before {
      height: auto;
    }
  }

  .main {
    margin-top: 15px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 15px;
    align-items: flex-start;

    @media ${device.tabletLMax} {
      display: block;
    }
    .col-1 {
      grid-area: 1 / 1 / 2 / 2;
      display: grid;
      grid-row-gap: 15px;
      @media ${device.laptopMax} {
        margin-bottom: 15px;
      }
    }
    .col-2 {
      display: grid;
      grid-row-gap: 15px;
      grid-area: 1 / 2 / 2 / 3;
      @media ${device.laptopMax} {
        .table {
          ${MediaTable}
        }
      }
    }
    .col-3 {
      display: grid;
      grid-row-gap: 15px;
      grid-area: 1 / 3 / 2 / 4;
      @media ${device.laptopMax} {
        .table {
          ${MediaTable}
        }
      }
    }
  }
`;

const Header = styled.header`
  position: relative;
  color: ${Colors.gray};
  display: grid;
  height: 240px;
  margin-bottom: 36px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-areas:
    "top top top"
    "img name name"
    "img description description"
    "img address address"
    "img meta meta"
    "onhold meta meta";
  &.collapse {
    display: block;
    padding-bottom: 10px;
    .description,
    .profile-pic,
    .meta {
      display: none;
    }
    .back {
      height: auto;
    }
    .collapse-handle {
      &:before {
        transform: rotate(0);
      }
    }
  }
  @media ${device.laptopLMax} {
    height: auto;
    background: ${Colors.primary};
    margin: 0 -1rem;
    padding: 0 1rem 30px 1rem;
  }

  .back {
    grid-area: top;
    font-weight: 300;
    font-size: 1.143rem;
    height: 51px;
    padding: 11px 0 0;
    a {
      color: ${Colors.gray};
      vertical-align: middle;
    }
    a:before {
      content: "";
      vertical-align: middle;
      width: 14px;
      height: 17px;
      margin-right: 5px;
      vertical-align: middle;
      display: inline-block;
      background: url(${imgChevron}) no-repeat;
      background-size: cover;
      transform: rotate(90deg);
    }
  }

  .conference {
    grid-area: name;
    min-height: 45px;
    vertical-align: middle;
    position: relative;
  }
  .status {
    display: inline-block;
    height: 20px;
    padding-top: 13px;
    vertical-align: middle;
    span {
      font-size: 0.8571rem;
      display: block;
      height: 100%;
      box-sizing: border-box;
      background-color: ${Colors.gray};
      color: ${Colors.primary};
      padding: 0 15px 0 15px;
      border-radius: 10px;
    }
  }
  h1 {
    font-weight: 300;
    font-size: 2.857rem;
    margin-top: 12px;
    margin-bottom: 12px;
    vertical-align: middle;
    margin-right: 15px;
    display: inline-block;
    white-space: nowrap;
  }

  h2 {
    grid-area: position;
    font-weight: 500;
    font-size: 1.143rem;
    margin-top: 17px;
    span {
      font-weight: 300;
    }
    a {
      color: ${Colors.gray};
    }
  }
  .description {
    grid-area: description;
    font-size: 18px;
    margin-bottom: 10px;
    &.short {
      cursor: pointer;
    }
  }
  .address {
    grid-area: address;
    font-weight: bold;
    font-size: 1.286rem;
    margin: 0 0 0 30px;
    p {
      margin-top: 12px;
      display: inline-block;
      margin-right: 30px;
    }
    time {
      font-weight: 400;
      color: #a4acb9;
    }
    a {
      color: ${Colors.gray};
    }
  }
  @media ${device.tabletLMax} {
    h1 {
      font-size: 2rem;
    }
    .address {
      font-size: 1rem;
    }
  }
  .website {
    display: inline-block;
    height: 20px;
    padding-top: 13px;
    vertical-align: middle;
    span {
      font-size: 1rem;
      display: block;
      height: 100%;
      box-sizing: border-box;
      padding: 2px 15px 0 15px;
      border-radius: 10px;
    }
    a {
      color: ${Colors.gray};
    }
  }
  .meta {
    grid-area: meta;
    dl {
      display: flex;
    }
    dl div {
      margin-right: 45px;
    }
    dt {
      font-weight: 600;
      font-size: 0.857rem;
      display: block;
      margin-bottom: 4px;
    }
    dd {
      font-weight: 700;
      font-size: 1.286rem;
      display: block;
    }

    .time-since {
      font-weight: 400;
      margin-left: 10px;
    }
    a {
      color: ${Colors.gray};
    }

    @media ${device.tabletSMax} {
      dl {
        display: block;
        columns: 1;
        div {
          break-inside: avoid;
          page-break-inside: avoid;
          margin-top: 10px;
        }
      }
    }
  }
  @media ${device.laptopMax} {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
      "top top"
      "img name"
      "img description"
      "img meta";
    .recruiter {
      display: none;
    }
  }
  @media ${device.tabletMMax} {
    grid-template-areas:
      "top top"
      "img name"
      "img description"
      "meta description";
    .status {
      display: none;
    }
    .address time {
      margin-top: 5px;
      display: block;
    }
  }
  .collapse-handle {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 17px;
    height: 19px;
    &:before {
      content: "";
      vertical-align: middle;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      display: inline-block;
      background: url(${imgChevron}) no-repeat;
      background-size: cover;
      transform: rotate(180deg);
    }
  }
`;

const StyledQuickNotes = styled(Card)``;

export default function ConferenceDetail(props) {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);
  const jwtInfo = AuthService.jwtInfo();
  console.log("jwt", jwtInfo);

  console.log("props history", props.history);

  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
  } = useForm();

  const [conference, setConference] = useState(null);

  // conference ********************************

  const getConference = async () => {
    let _conference = await ConferenceService.get(props.match.params.id);

    console.log("_conference", _conference);

    if (_conference.error) {
      props.history.push("/bd/conferences");
    } else {
      _conference.id = props.match.params.id;
      _conference.documents = _conference.documents || [];

      //format display dates
      _conference.startDateConferenceFormatted = _conference.startDate
        ? DateTime.formatDate(new Date(_conference.startDate))
        : "";
      _conference.endDateConferenceFormatted = _conference.endDate
        ? DateTime.formatDate(new Date(_conference.endDate))
        : "";

      //description
      const descLimit = 200;
      if (_conference.description.length > descLimit) {
        _conference.descriptionShortened = _truncate(_conference.description, {
          length: descLimit,
          separator: /,?\.* +/,
        });
      }

      setConference(_conference);
    }
  };

  useEffect(() => {
    getConference();
  }, []);

  const showFullDescription = () => {
    setConference({ ...conference, descriptionShortened: undefined });
  };

  const [headerCollapse, setHeaderCollapse] = useState(false);
  const collapseHeader = () => {
    setHeaderCollapse(!headerCollapse);
  };

  //not bizdev
  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Styled>
        {conference && (
          <Container>
            <section>
              <Header className={headerCollapse ? "collapse" : "test"}>
                <div className="conference">
                  <h1>{conference.name}</h1>
                  {conference.website && (
                    <p className="website">
                      <span>
                        <a
                          target="_mullings"
                          rel="noopener noreferrer"
                          href={
                            conference.website.match(/http/)
                              ? conference.website
                              : "http://" + conference.website
                          }
                        >
                          Conference Website
                        </a>
                      </span>
                    </p>
                  )}
                </div>

                <p className="back">
                  <a href="#" onClick={() => props.history.goBack()}>
                    Back
                  </a>
                </p>

                <div
                  className={
                    "description" +
                    (conference.descriptionShortened ? " short" : "")
                  }
                  onClick={
                    conference.descriptionShortened
                      ? () => showFullDescription()
                      : null
                  }
                >
                  {conference.descriptionShortened || conference.description}
                </div>

                <ProfileImage
                  detail={{
                    type: "conferences",
                    uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                  }}
                  entity={conference}
                  setEntity={setConference}
                />

                {conference.address && conference.address.city
                  ? formatAddress(
                      conference.address.city,
                      conference.address.state,
                      conference.address.country,
                      false, //TODO: display time based on geo data from DB
                      true //link to maps
                    )
                  : ""}

                <div className="meta">
                  <dl>
                    <div>
                      <dt>Start</dt>
                      <dd>{conference.startDateConferenceFormatted}</dd>
                    </div>
                    <div>
                      <dt>End</dt>
                      <dd>{conference.endDateConferenceFormatted}</dd>
                    </div>

                    {conference.category && (
                      <div>
                        <dt>Category</dt>
                        <dd>{conference.category}</dd>
                      </div>
                    )}
                  </dl>
                </div>
                <span
                  className="collapse-handle"
                  onClick={() => collapseHeader()}
                ></span>
              </Header>

              <div className="main">
                <div className="col-1">
                  <UpdatableEntity
                    entity={conference}
                    setEntity={setConference}
                    Service={ConferenceService}
                    detail={{
                      title: "Quick Notes",
                      key: "quickNote",
                      expandable: false,
                    }}
                  />
                  <UpdatableEntity
                    entity={conference}
                    setEntity={setConference}
                    Service={ConferenceService}
                    detail={{
                      title: "Travel Plans",
                      key: "travelPlans",
                      expandable: false,
                    }}
                  />
                  <UpdatableEntity
                    entity={conference}
                    setEntity={setConference}
                    Service={ConferenceService}
                    detail={{
                      title: "Description",
                      key: "description",
                      expandable: false,
                    }}
                  />
                  <Info conference={conference} setConference={setConference} />

                  <Addresses
                    conference={conference}
                    setConference={setConference}
                  />

                  <PresentingCompanies
                    conference={conference}
                    setConference={setConference}
                  />

                  <DocumentUpload
                    detail={{
                      type: "conferences",
                      uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                    }}
                    entity={{ ...conference }}
                    Service={ConferenceService}
                    setEntity={setConference}
                  />
                </div>
                <div className="col-2">
                  <Contacts
                    conference={conference}
                    setConference={setConference}
                  />
                </div>
                <div className="col-3">
                  <Events
                    conference={conference}
                    setConference={setConference}
                  />
                </div>
              </div>
            </section>
          </Container>
        )}
      </Styled>
    </>
  );
}

const StyledAddresses = styled(Card)`
  .addresses {
    li {
      padding: 10.5px 0;
      border-bottom: 1px solid #e2e5e9;
      a {
        font-weight: bold;
        font-size: 14px;
        .external {
          background: url(${imgExternal});
          background-size: cover;
          width: 14px;
          height: 14px;
          display: inline-block;
          vertical-align: middle;
          margin: 0 0 0 10px;
        }
      }
    }
  }
  .cancel {
    width: 32px;
    height: 32px;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
  }
  .form {
    margin: 30px 0 0;
    position: relative;
    fieldset {
      margin-top: 0;
    }
    .actions {
      margin-top: 0;
    }
  }

  button {
    margin-top: 30px;
  }
`;
const Addresses = ({ conference, setConference }) => {
  const [formSubmission, setFormSubmission] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  //react-hook-form
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
    reset,
  } = useForm();

  const onChange = e => {};

  //Submit Form
  const onSubmit = async data => {
    console.log("dat", data);

    setFormSubmission(true);

    let response = await ConferenceService.createAddress({
      conferenceId: conference.id,
      data: data,
    });

    console.log("response", response);

    if (response.error || response.errors) {
      toast.error(response.error, { position: "bottom-center" });
    } else if (response.id) {
      toast.success("Address added", { position: "bottom-center" });

      setConference({
        ...conference,
        addresses: [
          ...conference.addresses,
          { ...data, addressId: response.id },
        ], //response.id may have to be updated to numeric return instead of UUID (for deletion etc)
      });

      setAddAddress(false); //display "add company" button
    }

    setFormSubmission(false);
  };

  return (
    <StyledAddresses>
      <h1>Addresses</h1>
      <div className="content">
        {conference.addresses && conference.addresses.length ? (
          <ul className="addresses">
            {conference.addresses.map((address, i) => (
              <li key={i}>
                {address && address.city
                  ? formatAddressWithStreet({
                      address: address.address,
                      city: address.city,
                      state: address.state,
                      country: address.country,
                      time: false, //TODO: display time based on geo data from DB
                      link: true, //link to maps
                    })
                  : ""}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
        {!addAddress ? (
          <div className="actions">
            <Button onClick={() => setAddAddress(true)}>Add Address</Button>
          </div>
        ) : (
          ""
        )}

        {addAddress ? (
          <StyledForm className="form">
            <span className="cancel" onClick={() => setAddAddress(false)}>
              <img src={imgCircleX} title="Cancel" alt="Cancel" />
            </span>
            <form onSubmit={handleSubmit(onSubmit)}>
              <>
                <ol>
                  <Form.Fieldset legend="Add Address" className="rows stacked">
                    <Form.InputWrapper
                      label="Address"
                      name="address"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="address"
                        type="text"
                        value={""}
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper label="City" name="city" errors={errors}>
                      <Form.TextInput
                        name="city"
                        type="text"
                        className="smaller"
                        value={""}
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="State"
                      name="state"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="state"
                        type="text"
                        className="smaller"
                        value={""}
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper label="Zip" name="zip" errors={errors}>
                      <Form.TextInput
                        name="zip"
                        type="text"
                        className="smaller"
                        value={""}
                        forwardRef={register}
                        onChange={onChange}
                      />
                    </Form.InputWrapper>
                    <Form.InputWrapper
                      label="Country"
                      name="country"
                      errors={errors}
                    >
                      <Form.TextInput
                        name="country"
                        type="text"
                        className="smaller"
                        value={""}
                        forwardRef={register}
                        onChange={onChange}
                        placeholder="USA"
                      />
                    </Form.InputWrapper>
                  </Form.Fieldset>
                  <Form.FieldsetActions>
                    {!formSubmission ? (
                      <Button primary>Save Address</Button>
                    ) : (
                      <LoadingSubmit></LoadingSubmit>
                    )}
                  </Form.FieldsetActions>
                </ol>
              </>
            </form>
          </StyledForm>
        ) : (
          ""
        )}
      </div>
    </StyledAddresses>
  );
};

const StyledPresentingCompanies = styled(Card)`
  .companies {
    margin: 0 0 20px;
    li {
      padding: 14.5px 0;
      border-bottom: 1px solid #e2e5e9;

      a {
        font-weight: bold;
        font-size: 14px;
      }
      .address a {
        font-weight: normal;
      }
    }

    button {
      margin-top: 30px;
    }
  }
  .cancel {
    width: 32px;
    height: 32px;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
  }
  .form {
    margin: 30px 0 0;
    position: relative;
    fieldset {
      margin-top: 0;
    }
    .actions {
      margin-top: 0;
    }
  }

  .filter {
    position: relative;
    ul {
      position: absolute;
      top: 42px;
      background: #fff;
      width: 100%;
      border: 1px solid #eee;
      padding: 5px;
    }
    li {
      display: flex;
      align-items: center;
      padding: 5px;
      border: 1px solid #eee;
      &:hover {
        background: #eee;
      }
      button {
        margin-right: 20px;
      }
    }
  }
`;

const PresentingCompanies = ({ conference, setConference }) => {
  const [addCompany, setAddCompany] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [loading, setLoading] = useState(false);
  const [formSubmission, setFormSubmission] = useState(false);
  //react-hook-form
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    const getCompanies = async () => {
      let _companies = await CompanyService.list({ limit: 10000 });
      console.log("_companies", _companies);
      if (_companies && _companies._embedded) {
        setCompanies(
          _companies._embedded.map(c => {
            //add in address, as company api returns a flat format, but conference ocmpany includes address object
            c.address = {
              city: c.city,
              state: c.state,
              zip: c.zip,
              country: c.country,
            };
            return c;
          })
        );
        setFilteredCompanies(_companies._embedded);
      }
    };
    getCompanies();
  }, []);
  const onChange = e => {};
  //Submit Form
  const onSubmit = async data => {
    console.log("dat", data);

    setFormSubmission(true);

    let response = await ConferenceService.createCompany({
      conferenceId: conference.id,
      data: data,
    });

    console.log("response", response);

    if (response.error || response.errors) {
      toast.error(response.error, { position: "bottom-center" });
    } else if (response.id) {
      toast.success("Company added", { position: "bottom-center" });

      setConference({
        ...conference,
        companies: [
          ...conference.companies,
          { ...data, companyId: response.id },
        ], //response.id may have to be updated to numeric return instead of UUID (for deletion etc)
      });

      setAddCompany(false); //display "add company" button
      setFilterInput(""); //reset input filter
      setNewCompany(false); //hide new company form
    }

    setFormSubmission(false);
  };

  const saveCompany = async company => {
    let response = await ConferenceService.createCompany({
      conferenceId: conference.id,
      data: { companyId: company.companyId },
    });

    console.log("response", response);

    if (response.error || response.errors) {
      toast.error(response.error, { position: "bottom-center" });
    } else if (response.id) {
      toast.success("Company added", { position: "bottom-center" });

      setConference({
        ...conference,
        companies: [...conference.companies, company], //response.id may have to be updated to numeric return instead of UUID (for deletion etc)
      });

      setAddCompany(false); //display "add company" button
      setFilterInput(""); //reset input filter
    }
  };
  //Filter from search
  const [filterInput, setFilterInput] = useState("");
  const [newCompany, setNewCompany] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState("");
  const handleFilterChange = e => {
    const value = e.target.value || undefined;

    if (value) {
      let regex = new RegExp(value, "i");
      setFilteredCompanies(companies.filter(c => c.name.match(regex)));
    } else {
      setFilteredCompanies(companies);
    }
    //setFilter("main", value); //useFilter()
    setFilterInput(value);
  };

  console.log("comopanies", companies);
  return (
    <StyledPresentingCompanies>
      <h1>Presenting Companies</h1>

      <div className="content">
        {conference.companies && conference.companies.length ? (
          <ul className="companies">
            {conference.companies.map((company, i) => (
              <li key={i}>
                <p>
                  <Link to={"/bd/company/" + company.companyId}>
                    {company.name}
                  </Link>
                </p>
                {company.address
                  ? formatAddress(
                      company.address.city,
                      company.address.state,
                      company.address.country,
                      false,
                      true
                    )
                  : ""}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}

        {!addCompany && (
          <Button onClick={() => setAddCompany(true)}>Add Company</Button>
        )}

        {addCompany ? (
          <>
            {!newCompany && (
              <div className="filter">
                <Input className="search" type="search">
                  <Form.TextInput
                    name="search"
                    className="full"
                    type="text"
                    autoComplete={"Off"}
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder={"Type company name"}
                  />
                  <span className="go"></span>
                </Input>
                {filterInput ? (
                  <ul>
                    {filteredCompanies.length
                      ? filteredCompanies.map((c, i) => (
                          <li key={i}>
                            <Button size="small" onClick={() => saveCompany(c)}>
                              Add
                            </Button>
                            <span>{c.name}</span>
                          </li>
                        ))
                      : ""}
                    <li onClick={() => setNewCompany(true)}>
                      {filterInput} (Create new)
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            )}

            {newCompany ? (
              <StyledForm className="form">
                <span className="cancel" onClick={() => setAddCompany(false)}>
                  <img src={imgCircleX} title="Cancel" alt="Cancel" />
                </span>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <>
                    <ol>
                      <Form.Fieldset
                        legend="Add Company"
                        className="rows stacked"
                      >
                        <Form.InputWrapper
                          label="Name"
                          name="name"
                          errors={errors}
                          required={true}
                        >
                          <Form.TextInput
                            name="name"
                            type="text"
                            value={filterInput}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                        <Form.InputWrapper
                          label="City"
                          name="address[city]"
                          errors={errors}
                        >
                          <Form.TextInput
                            name="address[city]"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                        <Form.InputWrapper
                          label="State"
                          name="address[state]"
                          errors={errors}
                        >
                          <Form.TextInput
                            name="address[state]"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                        <Form.InputWrapper
                          label="Zip"
                          name="address[zip]"
                          errors={errors}
                        >
                          <Form.TextInput
                            name="address[zip]"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                        <Form.InputWrapper
                          label="Country"
                          name="address[country]"
                          errors={errors}
                        >
                          <Form.TextInput
                            name="address[country]"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>

                        <Form.InputWrapper
                          label="Description"
                          name="description"
                          errors={errors}
                        >
                          <Form.Textarea
                            name="description"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                      </Form.Fieldset>
                      <Form.FieldsetActions>
                        {!formSubmission ? (
                          <Button primary>Save Company</Button>
                        ) : (
                          <LoadingSubmit></LoadingSubmit>
                        )}
                      </Form.FieldsetActions>
                    </ol>
                  </>
                </form>
              </StyledForm>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </StyledPresentingCompanies>
  );
};

const StyledContacts = styled(Card)`
  .company {
    font-weight: 600;
    color: ${Colors.primary};
  }
  .website {
    color: ${Colors.primary};
  }
  .content {
    position: relative;
  }
  .search {
    margin: 0 0 10px;
  }
  .contacts {
    margin: 0 0 20px;
    .name {
      font-weight: bold;
    }
    li {
      margin: 0 0 5px;
      padding: 10px 0;
      border-bottom: 1px solid #c6cbd3;
    }
  }
  .cancel {
    width: 32px;
    height: 32px;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
  }
  .form {
    margin: 30px 0 0;
    position: relative;
    fieldset {
      margin-top: 0;
    }
    .actions {
      margin-top: 0;
    }
  }

  .filter {
    position: relative;
    ul {
      position: absolute;
      top: 42px;
      background: #fff;
      width: 100%;
      border: 1px solid #eee;
      padding: 5px;
    }
    li {
      display: flex;
      align-items: center;
      padding: 5px;
      border: 1px solid #eee;
      &:hover {
        background: #eee;
      }
      button {
        margin-right: 20px;
      }
    }
  }
`;

const Contacts = ({ conference, setConference }) => {
  const [addContact, setAddContact] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [filteredListContacts, setFilteredListContacts] = useState(
    conference.contacts
  );
  const [loading, setLoading] = useState(false);
  const [formSubmission, setFormSubmission] = useState(false);
  //react-hook-form
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      connected: { label: "No", value: "No" },
    },
  });

  useEffect(() => {
    const getContacts = async () => {
      let _contacts = await ContactService.list({ limit: 10000 });
      console.log("_contacts", _contacts);
      if (_contacts && _contacts._embedded) {
        setContacts(_contacts._embedded);
        setFilteredContacts(_contacts._embedded);
      }
    };
    getContacts();
  }, []);

  //Filter from search
  const [filterListInput, setFilterListInput] = useState("");
  const handleFilterListChange = e => {
    const value = e.target.value || undefined;

    if (value) {
      let regex = new RegExp(value, "i");
      setFilteredListContacts(
        conference.contacts.filter(
          c => c.firstName.match(regex) || c.lastName.match(regex)
        )
      );
    } else {
      setFilteredListContacts(conference.contacts);
    }
    //setFilter("main", value); //useFilter()
    setFilterListInput(value);
  };
  useEffect(() => {
    //TODO: filter based on current filterInput
    if (filterListInput) {
      let regex = new RegExp(filterListInput, "i");
      setFilteredListContacts(
        conference.contacts.filter(
          c => c.firstName.match(regex) || c.lastName.match(regex)
        )
      );
    } else {
      setFilteredListContacts(conference.contacts);
    }
  }, [conference.contacts]);

  const onChange = e => {};
  //Submit Form
  const onSubmit = async data => {
    console.log("dat", data);

    setFormSubmission(true);

    //convert structure and to boolean
    data.connected = data.connected.value === "Yes" ? true : false;

    let response = await ConferenceService.createContact({
      conferenceId: conference.id,
      data: { ...data },
    });

    console.log("response", response);

    if (response.error || response.errors) {
      toast.error(response.error, { position: "bottom-center" });
    } else if (response.id) {
      toast.success("Contact added", { position: "bottom-center" });

      setConference({
        ...conference,
        contacts: [...conference.contacts, { ...data, id: response.id }], //response.id may have to be updated to numeric return instead of UUID (for deletion etc)
      });

      setAddContact(false); //display "add company" button
      setFilterInput(""); //reset input filter
      setNewContact(false); //hide new company form
    }

    setFormSubmission(false);
  };

  const saveContact = async contact => {
    console.log("contact", contact);
    let response = await ConferenceService.createContact({
      conferenceId: conference.id,
      data: {
        candidateId: contact.candidateId,
        connected: contact.connected || false,
      }, //still needs contacts to return linkedin in results
    });

    console.log("response", response);

    if (response.error || response.errors) {
      toast.error(response.error, { position: "bottom-center" });
    } else if (response.id) {
      toast.success("Contact added", { position: "bottom-center" });

      setConference({
        ...conference,
        contacts: [...conference.contacts, contact], //response.id may have to be updated to numeric return instead of UUID (for deletion etc)
      });

      setAddContact(false); //display "add company" button
      setFilterInput(""); //reset input filter
    }
  };
  //Filter from search
  const [filterInput, setFilterInput] = useState("");
  const [newContact, setNewContact] = useState(false);
  const [filteredContacts, setFilteredContacts] = useState("");
  const handleFilterChange = e => {
    const value = e.target.value || undefined;

    if (value) {
      let regex = new RegExp(value, "i");
      setFilteredContacts(
        contacts.filter(
          c => c.firstName.match(regex) || c.lastName.match(regex)
        )
      );
    } else {
      setFilteredContacts(contacts);
    }
    //setFilter("main", value); //useFilter()
    setFilterInput(value);
  };

  console.log("contacts", contacts);

  return (
    <StyledContacts>
      <h1>People to Meet</h1>
      <div className="content">
        <Input className="search" type="search">
          <Form.TextInput
            name="search"
            className="full"
            type="text"
            value={filterListInput}
            onChange={handleFilterListChange}
            placeholder={"Type to filter..."}
          />
          <span className="go"></span>
        </Input>
        {filteredListContacts.length ? (
          <ul className="contacts">
            {filteredListContacts.map((contact, i) => (
              <li key={i}>
                <span className="name">
                  <Link to={"/bd/contact/" + contact.bdContactId}>
                    {contact.firstName} {contact.lastName}
                  </Link>

                  {contact.connected ? " - Connected" : ""}
                </span>
                {contact.title && (
                  <>
                    <br />
                    <span className="title">
                      {contact.title}
                      {/*,<Link to={"/bd/company/" + contact.companyId}>
                  {contact.company.name}
                </Link>*/}
                    </span>
                  </>
                )}
                {contact.email || contact.phone ? (
                  <>
                    <br />
                    <span className="email-phone">
                      {contact.email ? (
                        <a
                          href={"mailto:" + contact.email}
                          className="email"
                          target="_blank"
                          without
                          rel="noopener noreferrer"
                        >
                          {contact.email}
                        </a>
                      ) : (
                        ""
                      )}
                      {contact.phone ? (
                        <>
                          <span>
                            &nbsp;&bull;{" "}
                            <a
                              href={"tel:" + contact.phone}
                              className="phone"
                              target="_blank"
                              without
                              rel="noopener noreferrer"
                            >
                              {contact.phone}
                            </a>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </>
                ) : (
                  ""
                )}
                {contact.linkedin ? (
                  <>
                    <br />
                    <a
                      href={contact.linkedin}
                      target="_mullings"
                      rel="noopener noreferrer"
                    >
                      <img src={imgLinkedIn} />
                    </a>
                  </>
                ) : (
                  ""
                )}
                {/*<br />
              LINKEDIN IMAGE | WHATSAPP*/}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}

        {!addContact && (
          <Button onClick={() => setAddContact(true)}>Add Contact</Button>
        )}

        {addContact ? (
          <>
            {!newContact && (
              <div className="filter">
                <Input className="search" type="search">
                  <Form.TextInput
                    name="search"
                    className="full"
                    type="text"
                    autoComplete={"Off"}
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder={"Type contacts name"}
                  />
                  <span className="go"></span>
                </Input>
                {filterInput ? (
                  <ul>
                    {filteredContacts.length
                      ? filteredContacts.map((c, i) => (
                          <li key={i}>
                            <Button size="small" onClick={() => saveContact(c)}>
                              Add
                            </Button>
                            <span>
                              {c.firstName} {c.lastName}
                            </span>
                          </li>
                        ))
                      : ""}
                    <li onClick={() => setNewContact(true)}>
                      {filterInput} (Create new)
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            )}

            {newContact ? (
              <>
                <StyledForm className="form">
                  <span className="cancel" onClick={() => setAddContact(false)}>
                    <img src={imgCircleX} title="Cancel" alt="Cancel" />
                  </span>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <>
                      <ol>
                        <Form.Fieldset
                          legend="Add Contact"
                          className="rows stacked"
                        >
                          <Form.InputWrapper
                            label="First Name"
                            name="firstName"
                            className="smaller"
                            errors={errors}
                            required={true}
                          >
                            <Form.TextInput
                              name="firstName"
                              type="text"
                              className="smaller"
                              value={filterInput.split(" ")[0]}
                              forwardRef={register}
                              onChange={onChange}
                            />
                          </Form.InputWrapper>

                          <Form.InputWrapper
                            label="Last Name"
                            name="lastName"
                            errors={errors}
                            required={true}
                          >
                            <Form.TextInput
                              name="lastName"
                              type="text"
                              className="smaller"
                              value={filterInput.split(" ")[1] || ""}
                              forwardRef={register}
                              onChange={onChange}
                            />
                          </Form.InputWrapper>

                          <Form.InputWrapper
                            label="Email"
                            name="emailWork"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="emailWork"
                              type="email"
                              className="smaller"
                              value={""}
                              forwardRef={register}
                              onChange={onChange}
                            />
                          </Form.InputWrapper>
                          <Form.InputWrapper
                            label="Phone"
                            name="phone"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="phone"
                              type="text"
                              className="smaller"
                              value={""}
                              forwardRef={register}
                              onChange={onChange}
                            />
                          </Form.InputWrapper>
                          <Form.InputWrapper
                            label="Title"
                            name="title"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="title"
                              type="text"
                              className="smaller"
                              value={""}
                              forwardRef={register}
                              onChange={onChange}
                            />
                          </Form.InputWrapper>
                          <Form.InputWrapper
                            label="LinkedIn"
                            name="linkedin"
                            className="smaller"
                            errors={errors}
                          >
                            <Form.TextInput
                              name="linkedin"
                              type="text"
                              className="smaller"
                              value={""}
                              forwardRef={register}
                              onChange={onChange}
                            />
                          </Form.InputWrapper>

                          <Form.InputWrapper
                            label="LinkedIn Connected"
                            name="connected"
                            className="react-select position-title"
                          >
                            <Controller
                              as={
                                <Select
                                  name="connected"
                                  options={[
                                    { value: "No", label: "No" },
                                    { value: "Yes", label: "Yes" },
                                  ]}
                                  defaultValue={{ value: "No", label: "No" }}
                                />
                              }
                              name="connected"
                              control={control}
                            />
                          </Form.InputWrapper>

                          <Form.InputWrapper
                            label="Notes"
                            name="quickNote"
                            errors={errors}
                          >
                            <Form.Textarea
                              name="quickNote"
                              type="text"
                              className="smaller"
                              value={""}
                              forwardRef={register}
                              onChange={onChange}
                            />
                          </Form.InputWrapper>
                        </Form.Fieldset>
                        <Form.FieldsetActions>
                          {!formSubmission ? (
                            <Button primary>Save Contact</Button>
                          ) : (
                            <LoadingSubmit></LoadingSubmit>
                          )}
                        </Form.FieldsetActions>
                      </ol>
                    </>
                  </form>
                </StyledForm>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </StyledContacts>
  );
};

const StyledEvents = styled(Card)`
  position: relative;
  .header {
    display: block;
    height: 46px;
    font-weight: bold;
    text-transform: uppercase;
    background: #eaeff3;
    line-height: 23px;
    padding: 10px;
    box-sizing: border-box;
  }

  .events {
    padding: 20px 0 0;
    li {
      display: flex;
      margin: 0 0 15px;
      time {
        width: 100px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
`;

const StyledQuickEvent = styled(Card)`
  width: 298px;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  ${props =>
    props.relative
      ? "margin:20px;"
      : "position: absolute; z-index: 20; top: 44px; left: 0;"}

  .actions {
    margin-top: 0;
    fieldset {
      margin-top: 0;
    }
  }
`;

const Events = ({ conference, setConference }) => {
  const [eventDates, setEventDates] = useState([]);
  const [formSubmission, setFormSubmission] = useState(false);
  const [activeQuickEvent, setActiveQuickEvent] = useState(null);
  const [updateEvents, setUpdateEvents] = useState(true);
  const [initDate, setInitDate] = useState([
    conference.startDate,
    conference.endDate,
  ]);
  //react-hook-form
  const { register, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    //build events for each day
    if (
      updateEvents ||
      initDate[0] !== conference.startDate ||
      initDate[1] !== conference.endDate
    ) {
      //set/reset initDates
      setInitDate([conference.startDate, conference.endDate]);
      let _eventDates = {};
      let numDays = moment(conference.endDate).diff(
        conference.startDate,
        "days"
      );
      for (let d = 0; d <= numDays; d++) {
        let date = moment(conference.startDate).add(d, "days");
        let dateIndex = date.format("YYYY-MM-DD");

        _eventDates[dateIndex] = {
          date: date.utc().format(),
          addEvent: false,
          events: [],
        };
      }
      console.log("seventDates", _eventDates);

      //order conf events
      conference.events = _orderBy(conference.events, ["date", "startTime"]);

      //organize events into days as needed
      conference.events.forEach(event => {
        console.log("event starttime", event.startTime);
        let dateIndex = moment(event.date).format("YYYY-MM-DD");
        _eventDates[dateIndex].events.push(event);
      });
      setEventDates(_eventDates);
      setUpdateEvents(false);
    }
  }, [updateEvents, conference.startDate, conference.endDate]);

  const handleQuickEventOpen = key => {
    if (!activeQuickEvent) {
      setActiveQuickEvent(key);
      document.addEventListener("click", closeQuickEvent);
    }
  };
  const closeQuickEvent = e => {
    let containers = document.getElementsByClassName("quickEvent");

    let close = true;
    for (const container of containers) {
      if (container.contains(e.target)) {
        close = false;
        break;
      }
    }
    if (containers && close) {
      document.removeEventListener("click", closeQuickEvent);
      setActiveQuickEvent(null);
    }
  };

  const onChange = e => {};
  const onChangeDateTime = (date, name) => {
    setValue(name, date);
  };
  //Submit Form
  const onSubmit = async data => {
    setFormSubmission(true);
    let response = await ConferenceService.createEvent({
      conferenceId: conference.id,
      data: data,
    });

    if (response.error || response.errors) {
      toast.error(response.error, { position: "bottom-center" });
    } else if (response.id) {
      toast.success("Event added", { position: "bottom-center" });

      setConference({
        ...conference,
        events: [
          ...conference.events,
          {
            ...data,
            id: response.id,
            //api returns startDate/endDate on conference GET
            startTime: moment(data.startTime)
              .utc()
              .format(),
            endTime: moment(data.endTime)
              .utc()
              .format(),
          },
        ], //response.id may have to be updated to numeric return instead of UUID (for deletion etc)
      });
    }
    //remove addEvent from all
    setActiveQuickEvent(null);
    setUpdateEvents(true);

    setFormSubmission(false);
  };

  const QuickEvent = ({ date, relative }) => {
    return (
      <StyledQuickEvent relative={relative}>
        <StyledForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" value={date} name={"date"} ref={register} />
            <>
              <ol>
                <Form.Fieldset className="rows stacked">
                  <Form.InputWrapper
                    label="Event"
                    name="name"
                    errors={errors}
                    required={true}
                  >
                    <Form.TextInput
                      name="name"
                      type="text"
                      value={""}
                      forwardRef={register}
                      onChange={onChange}
                    />
                  </Form.InputWrapper>

                  <Form.InputWrapper
                    label="Start Time"
                    name="startTime"
                    errors={errors}
                  >
                    <DateTimePicker
                      date={false}
                      name="startTime"
                      defaultValue={new Date()}
                      ref={register({
                        name: "startTime",
                        value: new Date(),
                      })}
                      onChange={date => onChangeDateTime(date, "startTime")}
                    />
                  </Form.InputWrapper>

                  <Form.InputWrapper
                    label="End Time"
                    name="endTime"
                    errors={errors}
                  >
                    <DateTimePicker
                      date={false}
                      name="endTime"
                      defaultValue={new Date()}
                      ref={register({
                        name: "endTime",
                        value: new Date(),
                      })}
                      onChange={date => onChangeDateTime(date, "endTime")}
                    />
                  </Form.InputWrapper>
                </Form.Fieldset>
                <Form.FieldsetActions>
                  {!formSubmission ? (
                    <Button primary>Add as Event</Button>
                  ) : (
                    <LoadingSubmit></LoadingSubmit>
                  )}
                </Form.FieldsetActions>
              </ol>
            </>
          </form>
        </StyledForm>
      </StyledQuickEvent>
    );
  };

  console.log("eventDates", eventDates);

  return (
    <>
      <StyledEvents>
        {Object.keys(eventDates).length ? (
          <ul>
            {Object.keys(eventDates).map((key, i) => (
              <li key={i}>
                <time className="header">
                  {moment(eventDates[key].date).format("dddd, MMMM Do, YYYY")}
                </time>
                <div className="content">
                  <ul className="events">
                    {eventDates[key].events.map((event, j) => (
                      <li key={j}>
                        <time>
                          {moment(event.startTime).format("hh:mmA")}

                          {event.endTime
                            ? " - " + moment(event.endTime).format("hh:mmA")
                            : ""}
                        </time>
                        <div className="name">{event.name}</div>
                      </li>
                    ))}
                  </ul>
                  <div className="quickEvent" style={{ position: "relative" }}>
                    <Button onClick={() => handleQuickEventOpen(key)}>
                      Add Event
                    </Button>

                    {activeQuickEvent === key && (
                      <QuickEvent
                        date={eventDates[key].date}
                        relative={false}
                      />
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <QuickEvent date={conference.startDate} relative />
        )}
      </StyledEvents>
    </>
  );
};

const Info = ({ conference, setConference }) => {
  const info = [
    {
      label: "Name",
      value: "name",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Website",
      value: "website",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Virtual Link",
      value: "virtualLink",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Start Date",
      value: "startDateConferenceFormatted",
      edit: false,
      type: "date",
      saving: false,
      editable: true,
    },
    {
      label: "End Date",
      value: "endDateConferenceFormatted",
      edit: false,
      type: "date",
      saving: false,
      editable: true,
    },
    // {
    //   label: "Description",
    //   value: "description",
    //   edit: false,
    //   saving: false,
    //   editable: true,
    // },
  ];

  return (
    <UpdateableInfo
      info={info}
      detail={{ title: "Info", type: "conference" }}
      entity={conference}
      setEntity={setConference}
      Service={ConferenceService}
    />
  );
};
