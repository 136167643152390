import React from "react";
import { AuthService } from "../../services/Auth";
import { useStore } from "@kwhitley/use-store";

export default function Logout() {
  //TODO: clean this mess up
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);
  AuthService.logout();

  window.location.href = "/login";

  return <div />;
}
