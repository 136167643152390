import moment from "moment";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { AuthService } from "../../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import device from "../../../config/device";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";
import { EventService } from "../../../services/Event";
import { ContactService } from "../../../services/Contact";
import { UserActivityService } from "../../../services/UserActivity";
import { CompanyService } from "../../../services/Company";
import { PositionService } from "../../../services/Position";
import { Activities } from "../../Shared/DetailActivities";
import { DocumentUpload } from "../../Shared/DocumentUpload";
import { FormInvoiceInfo } from "../../Shared/FormInvoiceInfo";
import { TagsActivityTypes } from "../../Shared/TagsActivityTypes";
import { UpdateableInfo } from "../../Shared/UpdateableInfo";
import { Colors } from "../../../styles/Colors";
import { Form as StyledForm } from "../../../styles/Form";
import { formatAddress } from "../../../helpers/Display";
import { LoadingSubmit } from "../../../styles/Animation";

//import { TagsActivityTypes } from "../../../styles/Form";
import {
  Container,
  Button,
  Card,
  ButtonDropdown,
  ButtonClose,
  MediaTable,
} from "../../../styles/Common";
import { Dropdown } from "../../../components/Shared/Common";
import styled from "styled-components";
import imgChevron from "../../../assets/chevron.svg";
import imgCircleCheck from "../../../assets/circle-check.svg";
import imgX from "../../../assets/x.svg";
import imgCircleX from "../../../assets/circle-x.svg";
import { toast } from "react-toastify";
import { ActivitiesService } from "../../../services/Activities";

const Styled = styled.div`
  display: flex;
  &:before {
    content: "";
    display: block;
    height: 256px;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background-image: linear-gradient(0deg, #5f6a7b 0%, #515b69 100%);
    box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
    background: ${Colors.primary};
  }
  @media ${device.laptopLMax} {
    &:before {
      height: auto;
    }
  }

  .main {
    margin-top: 15px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 33% 1fr;
    grid-gap: 15px;
    align-items: flex-start;

    @media ${device.tabletLMax} {
      display: block;
    }
    .col-1 {
      grid-area: 1 / 1 / 2 / 2;
      display: grid;
      grid-row-gap: 15px;
      @media ${device.laptopMax} {
        margin-bottom: 15px;
      }
    }
    .col-2 {
      display: grid;
      grid-row-gap: 15px;
      grid-area: 1 / 2 / 2 / 3;
      @media ${device.laptopMax} {
        .table {
          ${MediaTable}
        }
      }
    }
  }
`;

const Header = styled.header`
  color: ${Colors.gray};
  display: grid;
  min-height: 245px;
  margin-bottom: 30px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "top top"
    "img name"
    "img address";

  @media ${device.laptopLMax} {
    height: auto;
    background: ${Colors.primary};
    margin: 0 -1rem;
    padding: 0 1rem 30px 1rem;
  }

  .back {
    grid-area: top;
    font-weight: 300;
    font-size: 1.143rem;
    height: 51px;
    padding: 11px 0 0;
    a {
      color: ${Colors.gray};
      vertical-align: middle;
    }
    a:before {
      content: "";
      vertical-align: middle;
      width: 14px;
      height: 17px;
      margin-right: 5px;
      vertical-align: middle;
      display: inline-block;
      background: url(${imgChevron}) no-repeat;
      background-size: cover;
      transform: rotate(90deg);
    }
  }

  .event {
    grid-area: name;
    height: 74px;
    vertical-align: middle;
    position: relative;
    .handle {
      cursor: pointer;
      display: inline-block;
      width: 10px;
      background: #eee;
      height: 100%;
      vertical-align: middle;
      margin: 0 20px 0 0;
    }
    .event-colors {
      width: 151px;
      position: absolute;
      z-index: 101;
      top: 0;
      left: 20px;
      background: #fff;
      border-radius: 3px;
      padding: 30px 20px 20px;
      cursor: pointer;
      ul {
        overflow: hidden;
        li {
          float: left;
          &:nth-child(n + 7) {
            margin: 10px 0 0;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          li {
            width: 60px;
            height: 35px;
            border-radius: 2px;
            cursor: pointer;
            margin: 0 3px 2px;
          }
        }
      }
      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: ${Colors.primary};
        width: 16px;
        height: 16px;
        overflow: hidden;
      }
      .urgency {
        color: #000;
        li {
          width: 126px;
          height: 35px;
          border-radius: 2px;
          cursor: pointer;
          margin: 0 3px 9px;
          text-align: center;
          box-sizing: border-box;
          padding: 8px 0 0;
          float: none;
        }
      }
    }
  }
  .status {
    display: inline-block;
    height: 20px;
    padding-top: 13px;
    vertical-align: middle;
    span {
      font-size: 0.8571rem;
      display: block;
      height: 100%;
      box-sizing: border-box;
      background-color: ${Colors.gray};
      color: ${Colors.primary};
      padding: 0 15px 0 15px;
      border-radius: 10px;
    }
  }
  h1 {
    font-weight: 300;
    font-size: 2.857rem;
    margin-top: 12px;
    margin-bottom: 12px;
    vertical-align: middle;
    margin-right: 15px;
    display: inline-block;
    white-space: nowrap;
  }

  h2 {
    grid-area: position;
    font-weight: 500;
    font-size: 1.143rem;
    margin-top: 17px;
    span {
      font-weight: 300;
    }
    a {
      color: ${Colors.gray};
    }
  }
  #activities {
    grid-area: activities;
    font-weight: bold;
    font-size: 1.286rem;
    margin: 0 0 0 30px;
    .org {
      display: block;
      margin: 10px 0 0;
    }
    .type {
      display: block;
      font-size: 0.9em;
    }
  }
  @media ${device.tabletLMax} {
    h1 {
      font-size: 2rem;
    }
    #activities {
      font-size: 1rem;
    }
  }
  .recruiter {
    color: #a4acb9;
    grid-area: recruiter;
    text-align: right;
    align-self: end;
    .name {
      font-weight: 600;
    }
  }

  @media ${device.laptopMax} {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
      "top top"
      "img name"
      "img activities"
      "img activities";
    .recruiter {
      display: none;
    }
  }
  @media ${device.tabletMMax} {
    .status {
      display: none;
    }
  }
`;

const CurrentStage = styled(Card)`
  margin-top: 25px;
  font-size: 0.8571rem;
  font-weight: 600;
  padding: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  height: 41px;
  align-items: center;
  grid-column-gap: 30px;
  h2 {
    margin-left: 7px;
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
  }
  ol {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;
    align-items: center;
    background: ${Colors.gray};
    border-radius: 20.5px;
    height: 100%;
    position: relative;
    border: 1px solid ${Colors.gray_accent};
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      height: calc(100% - 6px);
      width: calc(100% - 6px);
      top: 3px;
      left: 3px;
      background: ${Colors.highlight};
      background: linear-gradient(to bottom right, #3fb509, #82ce36);
      grid-area: 1 / 1 / 2 / ${props => props.currentStageColumn};
      z-index: 1;
      border-radius: 20.5px;
    }
  }
  li {
    position: relative;
    z-index: 10;
    text-align: center;
    cursor: pointer;
    time {
      font-size: 0.9em;
      font-style: italic;
      margin-left: 5px;
      color: ${Colors.primary};
    }
  }
  li.recruited:before {
    content: "";
    width: 17px;
    height: 17px;
    display: inline-block;
    background: url(${imgCircleCheck}) no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin: 0 8px 0 0;
  }

  @media ${device.laptopMax} {
    ol {
      grid-template-columns: repeat(
        ${props =>
          props.currentStageColumn === 2 || props.currentStageColumn === 6
            ? "2"
            : "3"},
        1fr
      );
      &:before {
        grid-area: 1 / 1 / 2 /
          ${props => (props.currentStageColumn === 2 ? "2" : "3")};
      }
    }
    li {
      display: none;
    }
    li.show {
      display: block;
    }
  }
  @media ${device.tabletSMax} {
    ol {
      grid-template-columns: auto;
      &:before {
        grid-area: 1 / 1 / 2 / 2;
      }
    }
    li.show {
      display: none;
    }
    li.active {
      display: block;
    }
  }
`;

export default function EventDetail(props) {
  const history = useHistory();
  const [isLoggedIn] = useStore("isLoggedIn", false);
  const jwtInfo = AuthService.jwtInfo();

  //react-hook-form
  const [event, setEvent] = useState(null);

  // EVENT ********************************
  const getEvent = async () => {
    let _event = await EventService.get(props.match.params.id);

    //set currentStage
    _event.currentStage = currentStage(_event.stage);

    if (_event.error) {
      history.push("/bd/events");
    } else {
      _event.id = props.match.params.id;
      _event.documents = _event.documents || [];

      if (_event.activityTypes && typeof _event.activityTypes === "string") {
        _event.activityTypes = _event.activityTypes.split("\t").map(a => {
          return { label: a, value: a };
        });
      }

      if (_event.contact) {
        //only needed because api doesn't return candidateId yet... should remove once it does
        let _contact = await ContactService.get(_event.contact.contactId);
        if (
          !_contact.error &&
          !(_contact.message && _contact.message === "Failed validation")
        ) {
          _event.candidateId = _contact.candidateId;
        }
      }
      setEvent(_event);
    }
  };

  const currentStage = stage => {
    let currentStage = 1;
    switch (stage) {
      case "bd":
        currentStage = 1;
        break;
      case "tapSent":
        currentStage = 2;
        break;
      case "tapSigned":
        currentStage = 3;
        break;
      case "placementFee":
        currentStage = 4;
        break;
      case "workInProcess":
        currentStage = 5;
        break;
      case "toBeInvoiced":
        currentStage = 6;
        break;
      case "completed":
        currentStage = 7;
        break;
    }
    return currentStage;
  };

  /* COLORS */
  const [openColorMenu, setOpenColorMenu] = useState(false);
  const [formInvoiceOpen, setFormInvoiceOpen] = useState(false);

  const handleMenuOpen = () => {
    if (!openColorMenu) {
      document.addEventListener("click", removeMenu);
      setOpenColorMenu(true);
    }
  };
  const removeMenu = () => {
    document.removeEventListener("click", removeMenu);
    setOpenColorMenu(false);
  };

  const setStage = async stage => {
    //disabled for now because toBeInvoiced/placementFee requires Form entries
    if (stage.match(/toBeInvoiced|placementFee/)) {
      setEvent({ ...event, nextStage: stage });
      setFormInvoiceOpen(true);
    } else {
      updateStage(stage);
    }
  };

  const updateStage = async stage => {
    let response = await EventService.update({
      id: event.bdEventId,
      data: { stage: stage },
    });
    if (!response.error) {
      setEventStage(stage);
    } else {
      toast.error(response.error, { position: "bottom-center" });
    }
  };

  //callback if form was used
  const setEventStage = stage => {
    setEvent({ ...event, stage: stage, currentStage: currentStage(stage) });
  };

  const setUrgency = async urgency => {
    //update swatch
    let response;

    if (event.activityId) {
      response = await ActivitiesService.update({
        id: event.activityId,
        data: { urgency: urgency },
      });
    } else {
      //fill in defaults if doesn't exist
      const data = {
        org: "unassigned",
        urgency: urgency,
        activityType: "",
      };
      response = await ActivitiesService.create({
        ...data,
        eventId: event.bdEventId,
      });

      if (response.id) {
        event.activityId = response.id;
        event.org = data.org;
        event.urgency = data.urgency;
        event.activityTypes = [
          { label: data.activityType, value: data.activityType },
        ];
      } else {
        toast.error(response.error);
      }
    }

    if (!response.error) {
      setEvent({
        ...event,
        urgency: urgency,
      });
    } else {
      toast.error(response.error, { position: "bottom-center" });
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  const getUrgencyColor = urgency => {
    switch (urgency) {
      case "onHold":
        return Colors.urgency_onhold;
      case "low":
        return Colors.urgency_low;
      case "medium":
        return Colors.urgency_medium;
      case "high":
        return Colors.urgency_high;
    }
  };

  //not bizdev
  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Styled>
        {event && (
          <Container>
            <section>
              <Header>
                <div className="event">
                  <span
                    className="handle"
                    style={{
                      backgroundColor: getUrgencyColor(event.urgency) || "#eee",
                    }}
                    onClick={handleMenuOpen}
                  ></span>
                  <h1>
                    {event.position && event.position.title
                      ? event.position.title
                      : event.company.name}
                  </h1>

                  {event.org ? (
                    <div id="activities">
                      <span className="org">{event.org}</span>
                      <br />
                      {event.activityTypes ? (
                        <span className="type">
                          {event.activityTypes.map(a => a.label).join(", ")}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {openColorMenu && (
                    <Card className="event-colors">
                      <span className="close" onClick={handleMenuOpen}>
                        <img src={imgCircleX} title="Cancel" alt="Cancel" />
                      </span>
                      <ul className="urgency">
                        <li
                          style={{ backgroundColor: Colors.urgency_low }}
                          onClick={() => setUrgency("low")}
                        >
                          low
                        </li>
                        <li
                          style={{ backgroundColor: Colors.urgency_medium }}
                          onClick={() => setUrgency("medium")}
                        >
                          medium
                        </li>
                        <li
                          style={{ backgroundColor: Colors.urgency_high }}
                          onClick={() => setUrgency("high")}
                        >
                          high
                        </li>
                        <li
                          style={{ backgroundColor: Colors.urgency_onhold }}
                          onClick={() => setUrgency("onHold")}
                        >
                          on hold
                        </li>
                      </ul>
                    </Card>
                  )}
                </div>

                <p className="back">
                  <a href="#" onClick={() => history.goBack()}>
                    Back
                  </a>
                </p>

                {event.address && event.address.city
                  ? formatAddress(
                      event.address.city,
                      event.address.state,
                      event.address.country,
                      false, //TODO: display time based on geo data from DB
                      true //link to maps
                    )
                  : ""}
              </Header>

              {event.currentStage && event.org !== "Dragonfly Stories" ? (
                <CurrentStage currentStageColumn={event.currentStage + 1}>
                  <h2>
                    <span>Current Stage</span>
                  </h2>
                  <ol className={"stage-" + event.currentStage}>
                    <li
                      className={
                        "recruited" +
                        ([1, 2].includes(event.currentStage) ? " show" : "") +
                        (event.currentStage === 1 ? " active" : "")
                      }
                      onClick={() => setStage("bd")}
                    >
                      BD
                    </li>
                    <li
                      className={
                        ([1, 2, 3].includes(event.currentStage)
                          ? " show"
                          : "") + (event.currentStage === 2 ? " active" : "")
                      }
                      onClick={() => setStage("tapSent")}
                    >
                      Tap Sent
                    </li>
                    <li
                      className={
                        ([2, 3, 4, 5].includes(event.currentStage)
                          ? " show"
                          : "") + (event.currentStage === 3 ? " active" : "")
                      }
                      onClick={() => setStage("tapSigned")}
                    >
                      TAP Signed
                    </li>
                    <li
                      className={
                        ([2, 3, 4, 5, 6].includes(event.currentStage)
                          ? " show"
                          : "") + (event.currentStage === 4 ? " active" : "")
                      }
                      onClick={() => setStage("placementFee")}
                    >
                      Engagement Fee
                    </li>
                    <li
                      className={
                        ([2, 3, 4, 5, 6].includes(event.currentStage)
                          ? " show"
                          : "") + (event.currentStage === 5 ? " active" : "")
                      }
                      onClick={() => setStage("workInProcess")}
                    >
                      Work In Process
                    </li>
                    <li
                      className={
                        ([2, 3, 4, 5, 6, 7].includes(event.currentStage)
                          ? " show"
                          : "") + (event.currentStage === 6 ? " active" : "")
                      }
                      onClick={() => setStage("placementFee")}
                    >
                      To Be Invoiced
                    </li>
                    <li
                      className={
                        ([2, 3, 4, 5, 6, 7].includes(event.currentStage)
                          ? " show"
                          : "") + (event.currentStage === 7 ? " active" : "")
                      }
                      onClick={() => setStage("completed")}
                    >
                      Completed
                    </li>
                  </ol>
                </CurrentStage>
              ) : (
                ""
              )}

              <div className="main">
                <div className="col-1">
                  <Info setEvent={setEvent} event={event} />

                  <TagsActivityTypes
                    entity={event}
                    setEntity={setEvent}
                    Service={ActivitiesService}
                    tags={event.activityTypes}
                    detail={{
                      type: "activityTypes",
                      typePlural: "activityTypes",
                      typeDisplay: "activityTypes",
                      title: "Activities",
                    }}
                  />

                  <Company event={event} />

                  <Contacts setEvent={setEvent} event={event} />

                  <Position setEvent={setEvent} event={event} />

                  <HelloSign event={event} />

                  <LatestActivities event={event} />
                </div>
                <div className="col-2" id="activity-notes">
                  <Activities
                    detail={{ type: "Event" }}
                    entity={event}
                    Service={EventService}
                  />

                  <DocumentUpload
                    detail={{
                      type: "events",
                      uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                    }}
                    entity={{ ...event, id: event.company.companyId }}
                    Service={CompanyService}
                    setEntity={setEvent}
                  />
                </div>
              </div>
            </section>
          </Container>
        )}
      </Styled>
      {formInvoiceOpen ? (
        <FormInvoiceInfo
          currentEvent={{
            ...event,
            positionId: event.position?.positionId,
            position: event.position?.title,
            companyId: event.company?.companyId,
            companyName: event.company?.name,
            firstName: event.contact?.firstName,
            lastName: event.contact?.lastName,
          }}
          setFormInvoiceOpen={setFormInvoiceOpen}
          callback={setEventStage}
        />
      ) : (
        ""
      )}
    </>
  );
}
EventDetail.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

const StyledHelloSign = styled(Card)``;

const HelloSign = ({ event }) => {
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState({});
  useEffect(() => {
    setTemplates(
      event._links.next
        .filter(t => t.name.match(/HELLOSIGN/))
        .map(t => {
          return { label: t.name.replace(/HELLOSIGN/g, ""), value: t.href };
        })
    );
  }, []);

  const [formLoading, setFormLoading] = useState(false);
  const onChange = opt => {
    setTemplate(opt);
  };
  const openDraft = async () => {
    setFormLoading(true);
    //redirect
    if (template.value) {
      let response = await EventService.helloSignCreateUrl(
        template.value.replace(/^\//, "")
      );
      if (!response.error) {
        window.open(response._links.next.href, "_blank");
      } else {
        toast.error(response.error, { position: "bottom-center" });
      }
      setFormLoading(false);
    }
  };

  return (
    <StyledHelloSign>
      <h1>Hello Sign Templates</h1>
      <div className="content">
        <StyledForm>
          <div style={{ display: "flex" }}>
            <Select
              name="templates"
              components={{
                IndicatorSeparator: () => null,
              }}
              className="isolated react-select"
              classNamePrefix="select"
              isClearable={true}
              options={templates}
              onChange={onChange}
            />
            {!formLoading ? (
              <Button primary onClick={openDraft}>
                Open Draft
              </Button>
            ) : (
              <LoadingSubmit size="25"></LoadingSubmit>
            )}
          </div>
        </StyledForm>
      </div>
    </StyledHelloSign>
  );
};
HelloSign.propTypes = {
  event: PropTypes.object.isRequired,
};

/**
 * Latest Activities
 */
const StyledLatestActivities = styled(Card)`
  li {
    margin-bottom: 21px;
  }
  .user {
    font-weight: 600;
    margin: 0 10px 0 0;
  }
  time {
    font-size: 12px;
    display: block;
  }
`;

const LatestActivities = ({ event }) => {
  const [userActivities, setUserActivities] = useState([]);

  useEffect(() => {
    const getUserActivities = async () => {
      let limit = 10;
      let response = await UserActivityService.list({
        limit: limit,
        filter: 'targetUrl:"' + event.id + '"',
      });
      if (!response.error) {
        let _activities =
          response._embedded.length > limit
            ? response._embedded.splice(0, limit)
            : response._embedded;

        setUserActivities(
          _activities.map(a => {
            //hack replaceent, need to fix routes to accomodate api return
            a.targetUrl = a.targetUrl
              .replace(/events/, "event")
              .replace(/contacts/, "contact")
              .replace(/conferences/, "conference")
              .replace(/companies/, "company");
            return a;
          })
        );
      } else {
        toast.error("Error retrieving users activities", {
          position: "bottom-center",
        });
      }
    };
    getUserActivities();
  }, []);

  return (
    <StyledLatestActivities>
      <h1>Latest Activities</h1>
      <div className="content">
        {userActivities.length ? (
          <ul>
            {userActivities.map((activity, i) => (
              <li key={i}>
                <span className="title">{activity.activityMessage}</span>
                <time>{moment(activity.createdAt).fromNow()}</time>
              </li>
            ))}
          </ul>
        ) : (
          <p>No recent activities</p>
        )}
      </div>
    </StyledLatestActivities>
  );
};
LatestActivities.propTypes = {
  event: PropTypes.object.isRequired,
};

const StyledLink = styled(Link)`
  ${props => (props.hidetext ? "width: 0; height: 0;overflow: hidden;" : "")}
  display: flex;
  :after {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0 auto auto;
    content: "";
    vertical-align: middle;
    width: 16px;
    height: 10px;
    display: inline-block;
    background: url(${imgChevron}) no-repeat;
    background-size: cover;
    background-position: 0 -21px;
    transform: rotate(-90deg);
  }
`;
const StyledCompany = styled(Card)`
  .company {
    font-weight: 600;
    color: ${Colors.primary};
  }
  .website {
    color: ${Colors.primary};
  }
  .content {
    position: relative;
  }
`;
const Company = ({ event }) => {
  return (
    <StyledCompany>
      <h1>Company</h1>
      <div className="content">
        <span className="company">
          <Link to={"/bd/company/" + event.company.companyId}>
            {event.company.name}
          </Link>
        </span>
        {event.company.website ? (
          <>
            <br />
            <span className="website">
              <a
                target="_blank"
                href={event.company.website}
                rel="noopener noreferrer"
              >
                {event.company.website}
              </a>
            </span>
          </>
        ) : (
          ""
        )}
        {event.company.linkedin ? (
          <>
            <br />
            <span className="linkedin">
              <a
                target="_blank"
                href={event.company.linkedin}
                rel="noopener noreferrer"
              >
                {event.company.linkedin}
              </a>
            </span>
          </>
        ) : (
          ""
        )}
        {event.company.phone ? (
          <>
            <br />
            <span className="phone">{event.company.phone}</span>
          </>
        ) : (
          ""
        )}
        {event.company.email ? (
          <>
            <br />
            <span className="email">
              <a href={"mailto:" + event.company.email}>
                {event.company.email}
              </a>
            </span>
          </>
        ) : (
          ""
        )}

        <StyledLink
          hidetext="true"
          to={"/bd/company/" + event.company.companyId}
        >
          View Company
        </StyledLink>
      </div>
    </StyledCompany>
  );
};
Company.propTypes = {
  event: PropTypes.object.isRequired,
};

const StyledContacts = styled(Card)`
  position: relative;
  z-index: 11;
  .name {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.primary};
    vertical-align: middle;
  }
  .contacts {
    li {
      display: flex;
      align-items: center;
      position: relative;
    }
    button {
      vertical-align: middle;
      margin: 3px 0 0 30px;
    }
  }
  .dropdown-positions {
    input {
      width: 100%;
      box-sizing: border-box;
      height: 34px;
    }
    li {
      cursor: default;
      display: flex;
      white-space: nowrap;
      align-items: center;
      .title {
        margin-right: 20px;
      }
    }
  }
`;

const Contacts = ({ event, setEvent }) => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getContacts = async () => {
    let response = await ContactService.list({
      limit: 10000,
    });
    if (!response.error) {
      //filter out current candidate positions
      setContacts(response._embedded);
      setFilteredContacts(response._embedded);
    }
  };
  useEffect(() => {
    getContacts();
  }, []);

  const filterDropdown = e => {
    let value = e.target.value.toLowerCase();

    let regex = new RegExp(value, "i");
    setFilteredContacts([
      ...contacts.filter(p => {
        if (p.firstName.match(regex) || p.lastName.match(regex)) {
          return p;
        }
      }),
    ]);
  };

  /**
   * Add contact to event
   * @param {object} position
   */
  const addContact = async contact => {
    setLoading(true);

    let response = await EventService.update({
      id: event.bdEventId,
      data: { bdContactId: contact.bdContactId },
    });
    if (!response.error) {
      //set contact... also need to manually add contactId as api returns for event.contact vs contact are inconsistent
      setEvent({
        ...event,
        contact: {
          ...contact,
          contactId: contact.bdContactId,
          email: contact.emailPersonal || contact.emailWork,
        },
      });
    } else {
      toast.error(response.error, { position: "bottom-center" });
    }
    setLoading(false);
  };

  const removeContact = async () => {
    let response = await EventService.update({
      id: event.bdEventId,
      data: { bdContactId: null },
    });
    if (!response.error) {
      event.contact = {};
      setEvent({ ...event });
    } else {
      toast.error(response.error, { position: "bottom-center" });
    }
  };

  return (
    <StyledContacts>
      <h1>Contact</h1>
      <div className="content">
        {event.contact && event.contact.contactId ? (
          <ul className="contacts">
            <li>
              <div className="display">
                <Link
                  className="name"
                  to={"/bd/contact/" + event.contact.contactId}
                >
                  {event.contact.firstName} {event.contact.lastName}
                </Link>

                {event.contact.email && (
                  <>
                    <br />
                    <a href={"mailto:" + event.contact.email}>
                      {event.contact.email}
                    </a>
                  </>
                )}
                {event.contact.phone && (
                  <>
                    <br />
                    <a href={"tel:" + event.contact.phone}>
                      {event.contact.phone}
                    </a>
                  </>
                )}
              </div>

              <StyledLink
                hidetext="true"
                to={"/bd/contact/" + event.contact.contactId}
              >
                View Contact
              </StyledLink>
              <ButtonClose type="button" onClick={() => removeContact()}>
                <img src={imgX} alt="Delete" title="Delete" />
              </ButtonClose>
            </li>
          </ul>
        ) : (
          ""
        )}

        {(!event.contact || !event.contact.contactId) && !loading ? (
          <Dropdown className="dropdown-positions" listId="positions-filter">
            <ButtonDropdown className="positions-filter">Select</ButtonDropdown>
            <div className="list" id="positions-filter">
              <input onKeyUp={filterDropdown} />
              <ul>
                {filteredContacts.map((contact, i) => {
                  return (
                    <li key={i} onClick={() => addContact(contact)}>
                      <span className="title">
                        {contact.firstName} {contact.lastName}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Dropdown>
        ) : (
          <>
            {!event.contact || !event.contact.contactId ? (
              <LoadingSubmit size="25"></LoadingSubmit>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </StyledContacts>
  );
};
Contacts.propTypes = {
  event: PropTypes.object.isRequired,
  setEvent: PropTypes.func.isRequired,
};

const StyledPositions = styled(Card)`
  position: relative;
  z-index: 10;
  .name {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.primary};
    vertical-align: middle;
  }
  .positions {
    li {
      display: flex;
      align-items: center;
      position: relative;
    }
    button {
      vertical-align: middle;
      margin: 3px 0 0 30px;
    }
  }
  .dropdown-positions {
    input {
      width: 100%;
      box-sizing: border-box;
      height: 34px;
    }
    li {
      cursor: default;
      display: flex;
      white-space: nowrap;
      align-items: center;
      .title {
        margin-right: 20px;
      }
    }
  }
`;

const Position = ({ event, setEvent }) => {
  const [positions, setPositions] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPositions = async () => {
    let response = await PositionService.list({
      limit: 10000,
    });
    if (!response.error) {
      //filter out current candidate positions
      setPositions(response._embedded);
      setFilteredPositions(response._embedded);
    }
  };
  useEffect(() => {
    getPositions();
  }, []);

  const filterDropdown = e => {
    let value = e.target.value.toLowerCase();

    let regex = new RegExp(value, "i");
    setFilteredPositions([
      ...positions.filter(p => {
        if (p.title.match(regex)) {
          return p;
        }
      }),
    ]);
  };

  /**
   * Add position to event
   * @param {object} position
   */
  const addPosition = async position => {
    setLoading(true);

    let response = await EventService.update({
      id: event.bdEventId,
      data: { positionId: position.id },
    });
    if (!response.error) {
      //set position... also need to manually add positionId as api returns for event.position vs position are inconsistent
      setEvent({
        ...event,
        position: {
          ...position,
          positionId: position.id,
          email: position.emailPersonal || position.emailWork,
        },
      });
    } else {
      toast.error(response.error, { position: "bottom-center" });
    }
    setLoading(false);
  };

  const removePosition = async () => {
    let response = await EventService.update({
      id: event.bdEventId,
      data: { positionId: null },
    });
    if (!response.error) {
      event.position = {};
      setEvent({ ...event });
    } else {
      toast.error(response.error, { position: "bottom-center" });
    }
  };

  return (
    <StyledPositions>
      <h1>Position</h1>
      <div className="content">
        {event.position && event.position.positionId ? (
          <ul className="positions">
            <li>
              <div className="display">
                <Link
                  className="name"
                  to={"/bd/position/" + event.position.positionId}
                >
                  {event.position.title}
                </Link>
              </div>

              <StyledLink
                hidetext="true"
                to={"/bd/position/" + event.position.positionId}
              >
                View Position
              </StyledLink>
              <ButtonClose type="button" onClick={() => removePosition()}>
                <img src={imgX} alt="Delete" title="Delete" />
              </ButtonClose>
            </li>
          </ul>
        ) : (
          ""
        )}

        {(!event.position || !event.position.positionId) && !loading ? (
          <Dropdown className="dropdown-positions" listId="positions-filter">
            <ButtonDropdown className="positions-filter">Select</ButtonDropdown>
            <div className="list" id="positions-filter">
              <input onKeyUp={filterDropdown} />
              <ul>
                {filteredPositions.map((position, i) => {
                  return (
                    <li key={i} onClick={() => addPosition(position)}>
                      <span className="title">{position.title}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Dropdown>
        ) : (
          <>
            {!event.position || !event.position.positionId ? (
              <LoadingSubmit size="25"></LoadingSubmit>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </StyledPositions>
  );
};
Position.propTypes = {
  event: PropTypes.object.isRequired,
  setEvent: PropTypes.func.isRequired,
};

const Info = ({ event, setEvent }) => {
  const info = [
    {
      label: "TMG Company",
      value: "org",
      edit: false,
      saving: false,
      type: "select",
      options: [
        { label: "unassigned", value: "unassigned" },
        {
          label: "Dragonfly Stories",
          value: "Dragonfly Stories",
        },
        { label: "Interim", value: "Interim" },
        { label: "Permanent", value: "Permanent" },
        { label: "TMG360", value: "TMG360" },
      ],
      editable: true,
    },
    {
      label: "Urgency",
      value: "urgency",
      edit: false,
      saving: false,
      type: "select",
      options: [
        { label: "Low", value: "low" },
        { label: "Medium", value: "medium" },
        { label: "High", value: "high" },
        { label: "On Hold", value: "onHold" },
      ],
      editable: true,
    },
  ];

  return (
    <div style={{ zIndex: 21, position: "relative" }}>
      <UpdateableInfo
        info={info}
        detail={{ title: "Info", type: "event" }}
        entity={event}
        setEntity={setEvent}
        Service={ActivitiesService}
      />
    </div>
  );
};
Info.propTypes = {
  event: PropTypes.object.isRequired,
  setEvent: PropTypes.func.isRequired,
};
