import PropTypes from "prop-types";
import * as QueryString from "query-string";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import { toast } from "react-toastify";
import styled from "styled-components";

import device from "../../config/device";
import { AuthService } from "../../services/Auth";
import {
  Container,
  Table,
  TableHeader,
  TableEmpty,
  PageTitle,
  ButtonLink,
  ButtonDropdown,
  Button,
} from "../../styles/Common";
import { makeLocalUrl } from "../../helpers/url.js";
import { Form } from "../Shared/Form";
import { Dropdown } from "../Shared/Common";
import { Input } from "../../styles/Form";
import { Pagination } from "../../components/Shared/Pagination";
import imgExternal from "../../assets/external.svg";
import { CompanyService } from "../../services/Company";
import { EventService } from "../../services/Event";

const Styled = styled.div`
  @media ${device.tabletMMax} {
    .name {
      font-weight: 600;
    }
  }
  tbody {
    tr {
      cursor: pointer;

      &.urgency td:first-child {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 5px;
          height: 100%;
        }
      }
      @media ${device.tabletMMax} {
        position: relative;
        &.urgency td:first-child {
          &:before {
            display: none;
          }
        }
        &.urgency:before {
          content: " ";
          display: block;
          width: 5px;
          height: calc(100% - 32px);
          position: absolute;
          top: 15px;
          left: 10px;
          box-sizing: border-box;
          background-color: #f1c82c;
        }
        &.urgency-high:before {
          background-color: #db3537;
        }
        &.urgency-medium:before {
          background-color: #f1c82c;
        }
        &.urgency-low:before {
          background-color: #5fb540;
        }
      }
      &.urgency-high td:first-child:before {
        background-color: #db3537;
      }
      &.urgency-medium td:first-child:before {
        background-color: #f1c82c;
      }
      &.urgency-low td:first-child:before {
        background-color: #5fb540;
      }
      td {
        position: relative;
      }
      .main {
        white-space: nowrap;
        font-weight: 500;
        padding: 0 20px 0 0;
        .external {
          position: absolute;
          top: 13px;
          right: 0;
          background: url(${imgExternal});
          background-size: cover;
          width: 14px;
          height: 14px;
          display: inline-block;
        }
      }
    }
  }
  .all-users {
    font-weight: 600;
  }
`;

export const RenderTable = ({
  styleTableWrapper,
  detail,
  columns,
  data,
  heading,
  pageCount: controlledPageCount,
  fetchData,
  filter,
  setFilter,
  filterUserDropdown,
  userSelected,
  filteredUsers,
  setUser,
  users,
  statuses,
  statusSelected,
  filterStatus,
}) => {
  const location = useLocation();
  const history = useHistory();
  let jwtInfo = AuthService.jwtInfo();

  let hiddenColumns = "id";

  const queryParams = QueryString.parse(location.search, {
    parseBooleans: true,
    parseNumbers: true,
  });
  const upsertQueryParams = (params /*:{page?:number; search?:string} */) => {
    for (const [key, value] of Object.entries(params)) {
      if (value && !(key === "page" && value === 1)) {
        queryParams[key] = value;
      } else {
        delete queryParams[key];
      }
    }

    const localUrl = makeLocalUrl(location.pathname, { query: queryParams });

    if (Object.keys(params).length === 1 && "search" in params) {
      return history.replace(localUrl); // Don't add search changes to history.
    }

    history.push(localUrl);
  };

  //prepare initial pageIndex (when landing directly on page or using "back")
  let initPageIndex = 0;
  if (queryParams["page"]) {
    initPageIndex =
      (Array.isArray(queryParams["page"])
        ? queryParams["page"][0]
        : queryParams["page"]) - 1;
  }

  let initSearch = "";
  if (queryParams["search"]) {
    initSearch = Array.isArray(queryParams["search"])
      ? queryParams["search"][0]
      : queryParams["search"];
  }

  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
    // setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageIndex: initPageIndex,
        pageSize: parseInt(localStorage.getItem("globalPageSize")) || 10,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
    },

    //useFilters,
    useSortBy,
    usePagination
  );

  if (pageIndex !== initPageIndex) {
    gotoPage(initPageIndex);
  }

  //Filter from search
  const [filterInput, setFilterInput] = useState("");
  const handleFilterChange = e => {
    const value = e.target.value || undefined;

    // Reset to the first page on search.
    if (pageIndex !== 0) {
      pageJump(0);
    }

    setFilterInput(value);
    upsertQueryParams({ search: value });
    setFilter({
      ...filter,
      search:
        detail.filterableColumns && detail.filterableColumns.length && value
          ? {
              OR: detail.filterableColumns.map(fieldName => ({
                field: fieldName,
                value: value,
              })),
            }
          : undefined,
    });
  };
  if (initSearch !== filterInput) {
    // Duplicates the functionality of the above because useEffect was only giving me empty strings for filterInput.
    setFilterInput(initSearch);
    upsertQueryParams({ search: initSearch });
    setFilter({
      ...filter,
      search:
        detail.filterableColumns &&
        detail.filterableColumns.length &&
        initSearch
          ? {
              OR: detail.filterableColumns.map(fieldName => ({
                field: fieldName,
                value: initSearch,
              })),
            }
          : undefined,
    });
  }

  useEffect(() => {
    fetchData({ pageIndex, pageSize, filter });
  }, [fetchData, pageIndex, pageSize, filter]); //positionFilter

  const pageJump = (newPageIndex /*: number | "next" | "prev" */) => {
    if (typeof newPageIndex === "string") {
      if (newPageIndex === "next") {
        newPageIndex = pageIndex + 1;
      } else {
        newPageIndex = pageIndex - 1;
      }
    }
    upsertQueryParams({ page: newPageIndex + 1 });
    return gotoPage(newPageIndex);
  };

  const external = (e, row) => {
    window.open("/" + detail.url + "/" + row.values.id, "_blank");
    e.stopPropagation();
    e.preventDefault();
  };

  const approveCompany = async (e, companyId) => {
    //thinking in order to refresh ... will hv to refetchData... so send back fetchData({ pageIndex, pageSize, filter });

    e.stopPropagation();
    e.preventDefault();

    let response = await CompanyService.update({
      id: companyId,
      approved: true,
    });

    if (!response.error) {
      fetchData({ pageIndex, pageSize, filter });
      toast.success("Company approved");
    } else {
      toast.error("There was an error approving the company");
    }
  };

  const deleteBDEvent = async (e, bdEventId) => {
    e.stopPropagation();
    e.preventDefault();

    console.log("bdEventId", bdEventId);

    let response = await EventService.del(bdEventId);

    if (!response.error) {
      fetchData({ pageIndex, pageSize, filter });
      toast.success("Event deleted");
    } else {
      toast.error(response.error);
    }
  };

  return (
    <Styled className={detail.name}>
      <Container>
        {heading.title || heading.search || heading.createNew ? (
          <TableHeader className={heading.className ? heading.className : ""}>
            {heading.title ? <PageTitle>{detail.title}</PageTitle> : ""}

            {heading.createNew &&
            (detail.name !== "client" ||
              (detail.name === "client" &&
                jwtInfo &&
                jwtInfo.roles.includes("Admin"))) ? (
              <ButtonLink
                className="button"
                to={"/" + detail.url + "/new"}
                type="button"
              >
                Create New
              </ButtonLink>
            ) : (
              ""
            )}

            {detail.name === "position" ? (
              <>
                {users.length && userSelected ? (
                  <div className="filters">
                    <Dropdown className="dropdown-users" listId="users-filter">
                      <ButtonDropdown className="users-filter">
                        {userSelected.id === "allUsers"
                          ? userSelected.firstName + " " + userSelected.lastName
                          : userSelected.lastName +
                            ", " +
                            userSelected.firstName}
                      </ButtonDropdown>
                      <div className="list" id="users-filter">
                        <input onKeyUp={filterUserDropdown} />
                        <ul>
                          {filteredUsers.map((u, i) => {
                            return (
                              !u.selected && (
                                <li
                                  className={
                                    u.id === "allUsers" ? "all-users" : ""
                                  }
                                  key={i}
                                  onClick={() => setUser(u)}
                                >
                                  {u.id === "allUsers"
                                    ? u.firstName + " " + u.lastName
                                    : u.lastName + ", " + u.firstName}
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    </Dropdown>
                    <Dropdown className="dropdown-status">
                      <ButtonDropdown className="status-filter">
                        Status: {statusSelected}
                      </ButtonDropdown>
                      <div className="list">
                        <ul>
                          {statuses.map((status, i) => {
                            return (
                              status.label !== statusSelected && (
                                <li
                                  key={i}
                                  onClick={() => filterStatus(status)}
                                >
                                  {status.label}
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    </Dropdown>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {heading.search ? (
              <Input className="search" type="search">
                <Form.TextInput
                  name="search"
                  type="text"
                  value={filterInput}
                  onChange={handleFilterChange}
                  placeholder={"Search " + detail.title}
                />
                <span className="go"></span>
              </Input>
            ) : (
              ""
            )}
          </TableHeader>
        ) : (
          ""
        )}

        {pageCount ? (
          <Table style={styleTableWrapper}>
            <div className="outer-wrap">
              <table>
                <thead>
                  {headerGroups.map(headerGroup => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        // eslint-disable-next-line react/jsx-key
                        <th
                          {...column.getHeaderProps(
                            //not seeming to be compatible w/ manualpagination
                            column.getSortByToggleProps()
                          )}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "sort desc"
                                : "sort asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {page.map(row => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps({
                          onClick: () =>
                            history.push(
                              "/" + detail.url + "/" + row.values.id
                            ),
                          className:
                            detail.name === "position" &&
                            "urgency" +
                              (row.cells[3].value
                                ? " urgency-" + row.cells[3].value.toLowerCase()
                                : ""),
                        })}
                        key={Math.random()}
                      >
                        {row.cells.map(cell => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <td {...cell.getCellProps()}>
                              <div className="wrap">
                                <div className="m">{cell.column.Header}</div>
                                <div className={"render " + cell.column.id}>
                                  {cell.column.id === "main" ? (
                                    <>
                                      {cell.render("Cell")}
                                      <span
                                        className="external"
                                        onClick={e => external(e, row)}
                                      ></span>
                                    </>
                                  ) : (
                                    <>
                                      {cell.column.id === "approvedByUserId" ? (
                                        <>
                                          {jwtInfo.roles.includes("Admin") &&
                                          !row.values.approvedByUserId ? (
                                            <Button
                                              size="small"
                                              onClick={e =>
                                                approveCompany(e, row.values.id)
                                              }
                                            >
                                              Approve
                                            </Button>
                                          ) : (
                                            <>
                                              {row.values.approvedByUserId
                                                ? "Approved"
                                                : ""}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {cell.column.id ===
                                          "deleteBDEvent" ? (
                                            <>
                                              {jwtInfo.roles.includes(
                                                "Admin"
                                              ) ? (
                                                <Button
                                                  size="small"
                                                  onClick={e =>
                                                    deleteBDEvent(
                                                      e,
                                                      row.values.id
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </Button>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            cell.render("Cell")
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination.Wrapper>
              <Pagination.RowsPerPage
                verbiage="Rows per page:"
                items={[1, 10, 20, 40, 60, 80]}
                setPageSize={setPageSize}
                pageSize={pageSize}
              />
              {pageCount > 1 && (
                <>
                  <div className="nav">
                    <Pagination.Direction
                      verbiage="Prev"
                      direction="previous"
                      gotoPage={() => pageJump("prev")}
                      canPage={canPreviousPage}
                    />
                    <Pagination.Paging
                      pageCount={pageCount}
                      pageOptions={pageOptions}
                      pageIndex={pageIndex}
                      gotoPage={pageJump}
                      pagesShownLimit={5}
                    />
                    <Pagination.Direction
                      verbiage="Next"
                      direction="next"
                      gotoPage={() => pageJump("next")}
                      canPage={canNextPage}
                    />
                  </div>
                </>
              )}
            </Pagination.Wrapper>
          </Table>
        ) : (
          <TableEmpty>
            <p>{"There were no " + detail.name_plural + " found"}</p>
          </TableEmpty>
        )}
      </Container>
    </Styled>
  );
};
RenderTable.propTypes = {
  styleTableWrapper: PropTypes.object,
  detail: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  heading: PropTypes.object,
  pageCount: PropTypes.number,
  controlledPageCount: PropTypes.number,
  fetchData: PropTypes.func,
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setFilter: PropTypes.func,
  filterUserDropdown: PropTypes.func,
  userSelected: PropTypes.object,
  filteredUsers: PropTypes.array,
  setUser: PropTypes.func,
  users: PropTypes.array,
  statuses: PropTypes.array,
  statusSelected: PropTypes.string,
  filterStatus: PropTypes.func,
};
