import React, { useState, useEffect } from "react";
import { EmailService } from "../../services/Email";
import { toast } from "react-toastify";
import styled from "styled-components";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Colors } from "../../styles/Colors";
import { Container, Button, PageTitle, LinkClose } from "../../styles/Common";
import { Form } from "../../components/Shared/Form";
import { Editor as StyledEditor, Input } from "../../styles/Form";
import imgCircleX from "../../assets/circle-x.svg";

const Styled = styled.div`
  .container {
    position: relative;
  }
  .editor {
    max-width: 897px;
    margin:0 auto;
  }
  .rdw-editor-wrapper {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-height: 252px;
  }
  .rdw-editor-main {
  }
  .header {
    line-height: 33px;
    padding-left: 15px;
    border: solid ${Colors.gray_accent};
    border-width: 1px 1px 0 1px;
    label {vertical-align: middle;margin-right:10px;font-weight:500}
  }
  .email-address {vertical-align:middle;}
  .subject {
    input {
      padding:0;
      height:auto;
      border:0;
      outline:none;
      color:${Colors.primary};
    }
  }
  .footer {
    position: relative;
    height: 1px;
  }
  .button-templates {
    position: absolute;
    right: 16px;
    bottom: 17px;
  }

  .actions {
    display:grid;
    grid-template-columns:1fr 1fr;
    justify-content:end;
    .col-1 {
      justify-self:start;
      text-align:left;
    }
    .col-2 {
      
    }
  }
  .templates {
    background:#fff;
    width:272px;
    position: absolute;
    right: 16px;
    bottom: 53px;
    box-shadow: 2px 12px 25px 0 rgba(81,91,105,.3);
    border:1px solid rgba(198, 203, 211,0.5)
    border-radius:3px;
    padding:15px 12px;
    h1{font-weight:600;font-size:12px}
    li {
      line-height:1.6em;
      display: flex;
      padding:0 3px;
      border-radius:3px;
      justify-content: space-between;
      &:hover {
        background:#eee;
      }
      .subject {cursor:default}
      .delete {
        cursor:pointer;
      }
    }

    .type {
        display:flex;
        width:50%;
        margin:0 auto;
        border:1px solid ${Colors.primary};
        height:20px;
        border-radius:10px;
        font-weight:500;
        font-size:12px;
        justify-content:space-evenly;
        li {
            line-height:20px;
            position:relative;
            width: 50%;
            text-align: center;
            span {
                display:inline-block;
                position:relative;
                z-index:5;
                cursor:default;
            }
            &.active {                
                color:#fff;
            }
            &.active:before {
                content:"";
                display:block;
                height:100%;
                
                position:absolute;
                z-index:1;
                left:0;
                top:0;
                width:100%;
                border-radius:9px;
                background:${Colors.primary};
            }
        }

    }
  }
`;

export const Email = ({ detail, entity, emailAddresses }) => {
  const [templates, setTemplates] = useState([]);
  const [editorEmpty, setEditorEmpty] = useState(true);

  console.log("email addresses", emailAddresses);

  useEffect(() => {
    const getTemplates = async () => {
      let _response = await EmailService.templates({ limit: 100 });

      if (_response._embedded) {
        console.log("templates", _response);
        setTemplates(_response._embedded);
      }
    };
    getTemplates();
  }, []);

  const [emailEditorState, setEmailEditorState] = useState(
    EditorState.createEmpty()
  );
  const onEditorStateChange = editorState => {
    setEmailEditorState(editorState);
    checkEditorEmpty();
  };

  const onChange = () => {
    checkEditorEmpty();
  };

  //Check if Empty: if empty, do not submit or save template
  const checkEditorEmpty = () => {
    setErrorMessage("");
    if (
      emailEditorState.getCurrentContent() &&
      emailEditorState.getCurrentContent().hasText() &&
      document.getElementById("subject").value
    ) {
      setEditorEmpty(false);
    } else {
      setEditorEmpty(true);
    }
  };

  const [template, setTemplate] = useState("");
  const [emailType, setEmailType] = useState("email");
  const loadTemplate = template => {
    setTemplate(template);
    setShowTemplates(false);

    //insert template content to Editor
    const blocksFromHtml = htmlToDraft(template.body);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    document.getElementById("subject").value = template.subject;
    setEmailEditorState(EditorState.createWithContent(contentState));
  };

  //save template
  const saveTemplate = async () => {
    let newTemplate = {
      type: emailType,
      permission: "public",
      category: detail.type,
      subject: document.getElementById("subject").value,
      body: draftToHtml(convertToRaw(emailEditorState.getCurrentContent())),
    };
    let response = await EmailService.saveTemplate(newTemplate);
    //set success
    if (response.id) {
      toast.success("Template saved");
      newTemplate.id = response.id;
      setTemplates([...templates, newTemplate]);
      console.log("new teamplae", templates);
    } else {
      //toast.error("There was an error saving template");
      setErrorMessage(response.error);
    }
  };
  //update template
  const updateTemplate = async () => {
    let updatedTemplate = {
      id: template.id,
      subject: document.getElementById("subject").value,
      body: draftToHtml(convertToRaw(emailEditorState.getCurrentContent())),
    };
    let response = await EmailService.updateTemplate(updatedTemplate);
    //set success
    if (!response.error && !response.errors) {
      toast.success("Template updated");
      template.subject = updatedTemplate.subject;
      template.body = updatedTemplate.body;
      console.log("template", template);
      setTemplate(template);
      setTemplates([...templates]);
    } else {
      toast.error("There was an error updating template");
    }
  };

  //delete template
  const deleteTemplate = async temp => {
    let response = await EmailService.deleteTemplate(temp.id);
    if (!response.error) {
      setTemplates([...templates.filter(t => t !== temp)]);
      //check if this is the currently used template
      if (temp === template) {
        setTemplate("");
      }
    }
  };

  //send email
  const [errorMessage, setErrorMessage] = useState("");
  const [showTemplates, setShowTemplates] = useState(false);
  const sendEmail = async () => {
    //if empty, do not submit
    /*if (editorEmpty) {
      setErrorMessage("Content cannot be empty");
      return;
    } */
    //no candidates for position have email addresses
    if (!emailAddresses.length) {
      setErrorMessage("There were no email addresses found");
      return;
    }
    let body = draftToHtml(convertToRaw(emailEditorState.getCurrentContent()));

    let subject = document.getElementById("subject").value;
    if (!subject.trim()) {
      setErrorMessage("Please fill in a subject");
      return;
    }
    let response = await EmailService.send({
      body: body,
      to: emailAddresses,
      subject: subject,
      type: emailType,
    });
    if (response) {
      toast.success("Email sent");
      detail.history.push("/" + detail.type + "/" + entity.id);
    }
    console.log(body);
  };

  const uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      try {
        reader.onloadend = function() {
          resolve({ data: { link: reader.result } });
        };
        reader.readAsDataURL(file);
      } catch {
        reject();
      }
    });
  };

  return (
    <>
      <Styled>
        <Container className="container">
          <LinkClose to={"/" + detail.type + "/" + entity.id}>
            <img src={imgCircleX} title="Cancel" alt="Cancel" />
          </LinkClose>

          <StyledEditor className="editor">
            <PageTitle>{detail.title}</PageTitle>
            <div className="header">
              <label>To:</label>
              <span className="email-address">
                {detail.type === "candidate" || detail.type === "interview" ? (
                  <>
                    {entity.firstName} {entity.lastName}{" "}
                    {"<" +
                      (entity.emailPersonal
                        ? entity.emailPersonal
                        : entity.emailWork) +
                      ">"}
                  </>
                ) : (
                  "All Candidates"
                )}
              </span>
              <div className="subject">
                <Input type="text">
                  <label>Subject:</label>
                  <Form.TextInput
                    name="subject"
                    type="text"
                    value={template ? template.subject : ""}
                    autoComplete="Off"
                    onChange={onChange}
                  />
                </Input>
              </div>
            </div>
            <Editor
              toolbar={{
                options: ["inline", "link", "image", "list"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                link: { options: ["link"] },
                image: {
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  uploadCallback: uploadImageCallBack,
                  alt: { present: false, mandatory: false },
                },
                list: { options: ["unordered", "ordered"] },
              }}
              placeholder="Compose email..."
              onEditorStateChange={onEditorStateChange}
              editorState={emailEditorState}
            />
            <div className="footer">
              {showTemplates && (
                <div className="templates">
                  {detail.type === "TODO:candidate" ? (
                    <>
                      <ul className="type">
                        <li
                          className={emailType === "email" ? "active" : ""}
                          onClick={() => setEmailType("email")}
                        >
                          <span>Email</span>
                        </li>
                        <li
                          className={emailType === "inmail" ? "active" : ""}
                          onClick={() => setEmailType("inmail")}
                        >
                          <span>InMail</span>
                        </li>
                      </ul>

                      <h1>Interested</h1>
                      <ul>
                        <li>Direct</li>
                        <li>Indirect</li>
                      </ul>
                      <h1>Position</h1>
                      <ul>
                        <li>Introduction to job</li>
                        <li>Introduction to company</li>
                        <li>Schedule interview</li>
                        <li>Extend offer</li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <h1>Templates</h1>
                      {templates.length ? (
                        <ul>
                          {templates.map((t, i) => {
                            return (
                              <li key={i}>
                                <span
                                  className="subject"
                                  onClick={() => loadTemplate(t)}
                                >
                                  {t.subject}
                                </span>
                                <span
                                  className="delete"
                                  onClick={() => deleteTemplate(t)}
                                >
                                  x
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        "No templates have been added"
                      )}
                    </>
                  )}
                </div>
              )}
              {errorMessage && <Form.Error message={errorMessage} />}
              <div className="actions">
                <div className="col col-1">
                  <Button
                    size="small"
                    className="button-templates"
                    onClick={() => setShowTemplates(!showTemplates)}
                  >
                    Templates
                  </Button>

                  {template && (
                    <Button size="small" onClick={updateTemplate}>
                      Update Template
                    </Button>
                  )}
                  {!editorEmpty ? (
                    <Button size="small" onClick={saveTemplate}>
                      Save as New Template
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col col-2">
                  <Button
                    onClick={() =>
                      detail.history.push("/" + detail.type + "/" + entity.id)
                    }
                  >
                    Discard
                  </Button>
                  <Button primary onClick={() => sendEmail()}>
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </StyledEditor>
        </Container>
      </Styled>
    </>
  );
};
