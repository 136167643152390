import Config from "../config/config";

const templates = inputData => {
  return fetch(Config.API_URL + "emails/templates?limit=" + inputData.limit, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      console.log("json", responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const send = inputData => {
  return fetch(Config.API_URL + "emails", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const updateTemplate = inputData => {
  return fetch(Config.API_URL + "emails/templates/" + inputData.id, {
    method: "PUT",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const saveTemplate = inputData => {
  return fetch(Config.API_URL + "emails/templates", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteTemplate = id => {
  return fetch(Config.API_URL + "emails/templates/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

//AUTHORIZE
const authorize = inputData => {
  return fetch(Config.API_URL + "emails/auth", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

export const EmailService = {
  send,
  templates,
  updateTemplate,
  saveTemplate,
  deleteTemplate,
  authorize,
};
