import React, { useState, useCallback, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../../services/Auth";
import { RenderTable } from "../../Shared/ListTable";
import { formatAddress } from "../../../helpers/Display";
import { EventService } from "../../../services/Event";
import moment from "moment";

export default function EventList(props) {
  //console.log(props);
  //authenticate
  const [isLoggedIn] = useStore("isLoggedIn", AuthService.verifyLogin());
  const jwtInfo = AuthService.jwtInfo();

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);

  //get events
  const fetchData = useCallback(({ pageIndex, pageSize, filter }) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      getData({ pageIndex, pageSize, filter });
    }
  }, []);

  const getData = async ({ pageIndex, pageSize, filter }) => {
    let _items = await EventService.list({
      limit: pageSize,
      offset: pageIndex * pageSize,
      filter:
        filter && filter.search
          ? {
              search: {
                AND: [
                  { field: "userId", value: "me" },
                  { OR: filter.search.OR },
                ],
              },
            }
          : { search: { field: "userId", value: "me" } },
      //sortBy: "name.desc",
    });

    console.log("event", _items);

    let _data = [];
    _items._embedded &&
      _items._embedded.forEach(i => {
        _data.push({
          id: i.bdEventId,
          main: i.companyName,
          position: i.position,
          location: i.address
            ? formatAddress(
                i.address.city,
                i.address.state,
                false,
                false,
                false
              )
            : "",
          createdAt: moment(i.createdAt).format("MM/D/YY"),
          contact: (i.contactFirstName || "") + " " + (i.contactLastName || ""),
        });
      });
    setData(_data);

    setPageCount(Math.ceil(_items.totalCount / pageSize));
  };

  console.log("data", data);
  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Company",
      accessor: "main",
    },
    {
      Header: "Point of Contact",
      accessor: "contact",
    },
    {
      Header: "Position",
      accessor: "position",
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
    },
    {
      Header: "",
      accessor: "deleteBDEvent",
    },
  ];

  //not bizdev
  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }
  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <RenderTable
      detail={{
        title: "BD Event companies",
        name: "events",
        name_plural: "events",
        url: "bd/event",
        filterableColumns: ["companyName"],
      }}
      columns={columns}
      data={data}
      filter={filter}
      setFilter={setFilter}
      heading={{ title: true, search: true, createNew: true }}
      pageCount={pageCount}
      fetchData={fetchData}
    />
  );
}
