import React, { useState, useEffect } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import device from "../../config/device";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../services/Auth";
import { CandidateService } from "../../services/Candidate";
import { CompanyService } from "../../services/Company";
import { PositionService } from "../../services/Position";
import { TagService } from "../../services/Tag";
import { SearchService } from "../../services/Search";
import { ExportSearchResults } from "../Shared/Export";

import { formatAddress, formatUUID } from "../../helpers/Display";
import {
  emptyInput,
  isOperator,
  isParenthesis,
  parseUserInput,
  convertPartsToJSON,
  searchInputValidation,
  addToSearchHistory,
  convertTextualToInput as searchConvertTextualToInput,
} from "../../helpers/Search";
import styled from "styled-components";

import { LoadingSubmit } from "../../styles/Animation";
import { Colors } from "../../styles/Colors";
import { Container, Button, LinkClose, Card } from "../../styles/Common";

import { Input, Tabs } from "../../styles/Form";
import imgCircleX from "../../assets/circle-x.svg";
import imgCircleCheck from "../../assets/circle-check.svg";
import imgProfileMissing from "../../assets/profile-missing.svg";
import { escapeRegExp as _escapeRegExp, uniq as _uniq } from "lodash";
import { toast } from "react-toastify";

const Styled = styled.div`
  .container {
    ${props =>
      !props.position && !props.company
        ? "display: grid;grid-template-columns: 273px 1fr 273px; grid-gap: 50px;"
        : ""}
  }
  #search-save {
    align-self: baseline;
    margin-top: 180px;
    max-width: 273px;
    h1 {
      margin-right: 5px;
    }
    li {
      cursor: pointer;
      padding: 5px 10px 5px 0;
      border-bottom: 1px solid ${Colors.gray};
      display: flex;
      justify-content: space-between;
      &:hover {
        background: ${Colors.gray};
        .delete {
          display: inline-block;
        }
      }
    }
    .text {
    }
    .delete {
      display: none;
    }
    .save {
      margin: 10px 0;
    }
  }
  .display {
    margin: 25px auto 0;
    width: 721px;
    position: relative;
  }
  #search-wrapper {
    display: flex;
    padding-top: 26px;
  }
  .editable {
    background: #fff;
    color: #fff;
    display: flex;
    align-items: center;
    flex: 1;

    .input {
      min-height: 40px;
      padding: 5px 30px 0 10px;
      border: 1px solid #c6cbd3;
      border-radius: 3px;
      vertical-align: middle;
      box-sizing: border-box;
      flex-wrap: wrap;
    }
    .go {
      cursor: pointer;
      top: 38px;
    }

    .input-item {
      white-space: nowrap;
      margin: 0 1px;
      position: relative;
      cursor: default;
      display: flex;
      height: 25px;
      line-height: 25px;
      font-size: 0.9em;
      margin-bottom: 4px;
      padding-bottom: 1px;
      &:last-child {
        flex: 1;
      }
      &:hover {
        .delete {
          display: block;
        }
      }
      &.error {
        border: solid ${Colors.warn};
        border-width: 0 0 1px;
      }
      &.search-error {
        border-color: ${Colors.error};
      }
    }

    .tag {
      color: #fff;
      .value {
        height: 100%;
        background: #555;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 5px 0 3px;
      }
      &:before {
        background: #eee;
        content: "tag";
        color: #555;
        padding: 0 3px 0 5px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &.document {
        &:before {
          content: "resume";
        }
      }
      &.note {
        &:before {
          content: "note";
        }
      }
      &.interview {
        &:before {
          content: "interview";
        }
      }
      &.company {
        &:before {
          content: "company";
        }
      }
      &.location {
        &:before {
          content: "location";
        }
      }
      &.position {
        &:before {
          content: "position";
        }
      }
      &.candidate {
        &:before {
          content: "candidate";
        }
      }
    }
    .empty {
      padding: 0 3px;
      color: #000;
      background: #efefef1a;
      outline: none;
    }
    .operator {
      font-weight: bold;
      color: #000;
    }
    .parenthesis {
      color: #555;
      font-weight: 600;
      font-size: 1.2em;
      .delete {
        width: 8px;
        font-size: 0.8em;
      }
    }
    .delete {
      display: none;
      position: absolute;
      top: 2px;
      right: 2px;
      color: #fff;
      width: 21px;
      height: 21px;
      background: #f03f3f;
      text-align: center;
      border-radius: 10px;
      line-height: 21px;
    }
    .parenthesis,
    .operator {
      .delete {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  #search-loading {
    text-align: center;
    margin: 50px 0 0;
  }
  #search-error {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: ${Colors.error};
  }
  #searching-for {
    margin: 20px 0;
    font-size: 1.2em;
    .actions {
      text-align: right;
    }
  }
  #format-suggestions {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 40;
    text-align: right;
    p {
      color: white;
      background: ${Colors.warn};
      padding: 2px 5px;
      display: inline-block;
    }
    &.error {
      p {
        background: ${Colors.error};
      }
    }
  }

  #suggestions {
    position: fixed;
    width: 300px;
    background: #fff;
    top: 100px;
    left: 200px;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);

    .fulltext,
    .operators {
      margin: 0 0 10px;
      ul {
        display: flex;
      }
      li {
        border-radius: 2px;
        border: 1px solid #555;
        padding: 2px 10px;
        color: #555;
        margin: 0 5px 5px 0;
        cursor: pointer;
      }
    }
    h2 {
      font-weight: 700;
      margin: 0 0 5px;
    }
    h3 {
      display: flex;
      align-items: center;
      font-weight: 500;
      &:after {
        content: "";
        border-bottom: 1px solid #a6a0a040;
        display: inline-block;
        flex: 1;
        margin: 0 10px;
      }
    }
    li {
      &:focus {
        background: #eee;
      }
    }
    .suggestion {
      cursor: default;
      display: block;
      padding: 2px 10px;
      &:active {
        outline: none;
      }
      &:hover {
        background: #eee;
        border-radius: 5px;
      }
    }
    .category {
      font-weight: 500;
    }
  }

  .filter {
    margin: 29px 0 0;
  }

  .results {
    h2 {
      font-weight: 700;
      font-size: 1.125rem;
    }
    .group {
      margin-top: 30px;
      ul {
        margin-top: 11px;
      }
      li {
        border-bottom: 1px solid #d8d8d8;
        min-height: 40px;
        display: grid;
        align-items: center;
        &:hover {
          padding: 0 15px;
          background: ${Colors.gray};
          .actions {
            display: block;
          }
        }
      }
    }
    .recent-searches {
      p {
        margin: 10px 0;
      }
      li {
        cursor: pointer;
      }
    }
    .candidates {
      li {
        grid-template-columns: auto auto 1fr;
        grid-template-areas: "img profile actions";
        padding: 8px 0;
        &:hover {
          padding: 8px 15px;
        }
      }
      .img {
        grid-area: img;
        margin-right: 21px;
        background: #ddd;
        width: 40px;
        height: 40px;
        line-height: 37px;
        overflow: hidden;
        text-align: center;
        align-self: flex-start;
        img {
          vertical-align: middle;
        }
      }
      dl {
        grid-area: profile;
      }
      dt {
        display: none;
      }
      dd {
        font-size: 0.75rem;
        color: ${Colors.gray_light};
        &.title {
          font-size: 0.875rem;
          font-weight: 500;
          color: ${Colors.primary};
        }
      }
      .actions {
        ${props => (!props.company && !props.position ? "display: none;" : "")};
        grid-area: actions;
        text-align: right;
        color: ${Colors.primary};
        font-weight: 700;
        a {
          margin: 0 15px;
        }
      }
    }
    .positions,
    .companies {
      li {
        a {
          align-items: center;
          display: flex;
          height: 100%;
        }
      }
    }
    .not-shown {
      margin: 10px;
      color: #d85a00;
    }
  }
`;
export default function Search(props) {
  console.log("device", device);
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );

  const params = useParams();

  const [position, setPosition] = useState();
  const [company, setCompany] = useState();
  const [candidateIds, setCandidateIds] = useState([]);

  let initFilter = "all";

  //get position if coming from job order page
  const getPosition = async id => {
    let position = await PositionService.get(id);
    if (position.errors) {
      props.history.push("/positions");
    } else {
      position.id = id;
      setPosition(position);
    }
  };
  //get position if coming from job order page
  const getPositionCandidates = async id => {
    let response = await PositionService.candidates({ id: id, limit: 200 });

    if (response._embedded) {
      setCandidateIds(
        response._embedded.map(c =>
          c._links.item[0].href.replace(/\/candidates\//, "")
        )
      ); //id is no longer returned));
    }
  };
  //get company if coming from company page to add contacts
  const getCompany = async id => {
    let company = await CompanyService.get(id);
    if (company.errors) {
      props.history.push("/clients");
    } else {
      company.id = id;
      setCompany(company);
      if (company.employees) {
        setCandidateIds(company.employees.map(c => c.candidateId));
      }
    }
  };

  if (params) {
    //filters
    if (
      params.filter &&
      params.filter.match(/^candidates|positions|companies$/)
    ) {
      initFilter = params.filter;
    }
  }

  const filterTypes = filter => {
    setFilter(filter);
  };

  //get candidate
  const [candidates, setCandidates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [initCandidates, setInitCandidates] = useState([]);
  const [initCompanies, setInitCompanies] = useState([]);
  const [initPositions, setInitPositions] = useState([]);
  const [filter, setFilter] = useState(initFilter);

  //search input vars
  const [searchValue, setSearchValue] = useState("");
  const [searchInputError, setSearchInputError] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchJSON, setSearchJSON] = useState([]);
  //search results
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsRaw, setSearchResultsRaw] = useState([]);
  const [showSave, setShowSave] = useState(true);
  const [savedSearches, setSavedSearches] = useState([]);
  //stored search results
  //get history from local storage
  let searchHistory = localStorage.getItem("searchHistory");
  const [recentSearches, setRecentSearches] = useState(
    searchHistory ? JSON.parse(searchHistory) : []
  );
  //suggestions
  const [searchOperatorSuggestions, setSearchOperatorSuggestions] = useState(
    false
  );
  const [searchFullTextSuggestions, setSearchFullTextSuggestions] = useState(
    false
  );
  const [searchTagSuggestions, setSearchTagSuggestions] = useState([]);
  const [suggestionDataFiltered, setSuggestionDataFiltered] = useState([]);
  const [inputPosition, setInputPosition] = useState({ top: 60, left: 100 });

  //initialize user input area
  const initInputElements = [emptyInput(true)];
  const [inputElements, setInputElements] = useState(initInputElements);

  //add candidates to position excluding ones currently set for position
  const addAllCandidatesToPosition = async () => {
    console.log("raw", searchResultsRaw);
    let _candidateIds = _uniq(
      searchResultsRaw.candidates.map(c =>
        c._links.self.href.replace("/candidates/", "")
      )
    ).filter(id => !candidateIds.includes(id));

    let _positionAdded = await PositionService.addCandidates({
      id: position.id,
      candidateIds: _candidateIds,
    });

    const updateCandidateStage = async id => {
      let response = await PositionService.updateCandidateStage({
        candidateId: id,
        positionId: position.id,
        stage: "Short List",
      });
    };
    //update candidate to default stage
    for (let id of _candidateIds) {
      updateCandidateStage(id);
    }

    if (_positionAdded.id) {
      //addedToPosition
      searchResultsRaw.candidates.map(c => {
        c.added = true;
        return c;
      });
      setCandidateIds([...candidateIds, ..._candidateIds]);
      //error
      toast.success("All candidates have been added to the position");
    }
  };
  //add single candidate to position
  const addCandidateToPosition = async (e, candidate) => {
    e.preventDefault();
    let _positionAdded = await PositionService.addCandidates({
      id: position.id,
      candidateIds: [candidate.id],
    });

    if (_positionAdded.id) {
      candidate.added = true;
      //update candidate to default position
      await PositionService.updateCandidateStage({
        candidateId: candidate.id,
        positionId: position.id,
        stage: "Short List",
      });
      setCandidateIds([...candidateIds, candidate.id]);
    } else {
      //error
      toast.error(
        "There was an error trying to add candidate to this position"
      );
    }
  };
  //add candidate to company
  const addCandidateToCompany = async (e, candidate) => {
    e.preventDefault();

    //already has employment? then update instead of insert
    let response, employmentId;
    if (candidate.currentEmployments) {
      response = await CandidateService.updateEmployment({
        id: candidate.id,
        employmentId: candidate.currentEmployments.employmentId,
        //data: {companyName: company.name, title: "Individual Contributer"} //can also use this
        data: { companyId: company.id }, //{title:'Individual Contributer'}
      });
      employmentId = candidate.currentEmployments.employmentId;
    } else {
      response = await CompanyService.addEmployee({
        id: company.id,
        candidateId: candidate.id,
      });
      employmentId = response._embedded.id;
    }

    if (!response.error) {
      candidate.currentEmployments = {
        company: company.name,
        title: "Individual Contributer",
        employmentId: employmentId,
      };
      candidate.added = true;
      setCandidateIds([...candidateIds, candidate.id]);
    } else {
      //error
      toast.error("There was an error trying to add candidate to this company");
    }
  };

  //saved searches
  const getSavedSearches = async () => {
    let response = await SearchService.list({ limit: 100, offset: 0 });
    if (!response.error) {
      setSavedSearches(
        response._embedded.map(s => {
          s.id = s._links.item.href.replace("/search/", "");
          return s;
        })
      );
    }
  };

  //get tags for suggestions
  const [tags, setTags] = useState([]);
  const getTags = async () => {
    let response = await TagService.get({ type: "" });

    if (response._embedded) {
      setTags(response._embedded);
    }
  };
  useEffect(() => {
    //position
    if (params.positionId) {
      getPosition(params.positionId);
      //return candidates already in position
      getPositionCandidates(params.positionId);
    } else if (params.companyId) {
      getCompany(params.companyId);
      //return candidates already in position
      //getPositionCandidates(params.positionId);
    } else {
      setPosition();
      getSavedSearches();
    }
  }, [params]);

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    //set correct focus
    let inputFocused = false;
    inputElements.map(ie => {
      if (ie.focus) {
        inputFocused = true;
        let el = document.getElementById(ie.key);
        if (el) {
          setFocus(ie);
        }
      }
    });
    //don't start out w/ this open
    if (inputElements.length > 1 && inputFocused) {
      setSearchOperatorSuggestions(true);
    }
    //validate new input
    let errors = searchInputValidation(inputElements);

    setSearchInputError(errors ? errors : "");

    return () => {};
  }, [inputElements]);

  const setFocus = ie => {
    setTimeout(function() {
      let el = document.getElementById(ie.key);
      if (el) {
        el.focus();
        setInputPosition(el.getBoundingClientRect());
      }
    }, 0);
  };

  const onInputKeyDown = e => {
    //set position for suggestions drop
    setInputPosition(e.target.getBoundingClientRect());

    let value = e.target.innerText.trim();
    //get index of currently focused item
    let index = inputElements.findIndex(ie => ie.focus);
    //SPACE - if they type [paren + space] it auto assumes its a paren, same w/ AND, OR, NOT
    if (e.key === " " || e.key === "Spacebar") {
      //operator or paren?
      if (isOperator(value)) {
        setTag(e.target, value.toUpperCase(), "operator", value.toLowerCase());
      } else if (isParenthesis(value)) {
        setTag(
          e.target,
          value,
          "parenthesis",
          value === ")" ? "close" : "open"
        );
      }
    }
    //Backspace
    if (e.key === "Backspace") {
      //make sure input is empty
      if (!e.target.innerHTML && e.target.previousElementSibling) {
        //splice in new item
        let index = inputElements.findIndex(
          ie => ie.key == e.target.dataset.key
        );
        inputElements.splice(index - 2, 2);

        //lets set some focus
        let currentElement = inputElements.find(
          ie => ie.key == e.target.dataset.key
        );
        currentElement.focus = true;
        setInputElements([...inputElements]);
        e.preventDefault();
      }
    }
    //ENTER -> submit search
    if (e.key === "Enter") {
      e.target.innerText = "";
      onSearch();
    }
    // TAB or Arrow Next
    if (e.key === "Tab" || e.key === "ArrowRight") {
      if (value) {
        //determine tag type
        if (isOperator(value)) {
          setTag(
            e.target,
            value.toUpperCase(),
            "operator",
            value.toLowerCase()
          );
        } else if (isParenthesis(value)) {
          setTag(
            e.target,
            value,
            "parenthesis",
            value === "(" ? "open" : "close"
          );
        } else {
          //cannot create your own tag!
        }
      } else {
        //if no value, just move ahead
        moveAhead(e);
      }

      e.preventDefault();
    }

    if (e.key === "ArrowLeft") {
      if (value) {
        //operator or paren?
        if (isOperator(value)) {
          setTag(
            e.target,
            value.toUpperCase(),
            "operator",
            value.toLowerCase()
          );
        } else if (isParenthesis(value)) {
          setTag(
            e.target,
            value,
            "parenthesis",
            value === "(" ? "open" : "close"
          );
        }
      } else if (e.target.previousElementSibling) {
        //remove focus from current element
        inputElements[index].focus = false;
        //place focus on new element
        let prevSibling;
        if (e.target.previousElementSibling.previousElementSibling) {
          prevSibling = e.target.previousElementSibling.previousElementSibling;
        } else {
          prevSibling = e.target.previousElementSibling;
        }

        prevSibling.focus();

        let newElement = inputElements.find(
          ie => ie.key == prevSibling.dataset.key
        );
        //update state
        newElement.focus = true;

        setInputElements([...inputElements]);
        e.preventDefault();
      }
    }
    //Delete
    if (e.key === "Delete") {
      if (!value && e.target.nextElementSibling) {
        //splice in new item
        let index = inputElements.findIndex(
          ie => ie.key == e.target.dataset.key
        );
        inputElements.splice(index + 1, 2);
        setInputElements([...inputElements]);
        e.preventDefault();
      }
    }
  };

  const onInputKeyUp = async e => {
    //set position for suggestions drop
    setInputPosition(e.target.getBoundingClientRect());

    let value = e.target.innerText.trim();
    //get index of currently focused item
    let index = inputElements.findIndex(ie => ie.focus);

    //SUGGESTIONS
    if (value.length >= 2) {
      //notes, resumes, interviews
      setSearchFullTextSuggestions(true);
      //tags
      setSearchTagSuggestions(
        tags.filter(t => t.tag.match(new RegExp(_escapeRegExp(value), "i")))
      );
    } else {
      //reset
      setSearchFullTextSuggestions(false);
      setSearchTagSuggestions([]);
    }
    if (value.length >= 3) {
      //query api
      let response = await SearchService.suggestions({
        query: value,
        limit: 5,
      });
      if (response) {
        let convertedToArray = Object.entries(response).map(([key, value]) => ({
          key,
          value,
        }));
        setSuggestionDataFiltered(convertedToArray); //remove _links
      }
    } else {
      //reset
      setSuggestionDataFiltered([]);
    }
  };

  const clearInputFocus = () => {
    inputElements.map(i => {
      i.focus = false;
    });
    let emptyInputElements = document.querySelectorAll(".input-item.empty");
    if (emptyInputElements) {
      Array.from(emptyInputElements).forEach(i => {
        i.innerText = "";
      });
    }
  };
  //tag receives focus
  const onInputClick = e => {
    //if tag is clicked... move along to next tag
    if (e.target.dataset.type === "tag") {
      moveAhead(e);
    } else {
      clearInputFocus();
      inputElements[e.target.dataset.index].focus = true;
      setInputElements([...inputElements]);
    }
  };
  //tag is blurred, check for parenthesis
  const onInputBlur = e => {
    let value = e.target.innerText.trim();

    //operator or paren?
    if (isOperator(value)) {
      setTag(e.target, value.toUpperCase(), "operator", value.toLowerCase());
    } else if (isParenthesis(value)) {
      setTag(e.target, value, "parenthesis", value === "(" ? "open" : "close");
    }
  };
  //delete tag is checked
  const onInputDelete = e => {
    //delete current input + preceding empty editable
    inputElements.splice(e.target.dataset.index - 1, 2);
    //no need to reset focus
    setInputElements([...inputElements]);
    e.stopPropagation();
    setSearchError(false);
  };

  //SET TAG
  const setTag = (element, value, type, subtype) => {
    //reset errors
    setSearchError(false);
    element.innerText = "";
    setSuggestionDataFiltered([]);
    setSearchTagSuggestions([]);
    setSearchFullTextSuggestions(false);
    let index = inputElements.findIndex(ie => ie.focus);

    let updatedElements = inputElements.map(ie => {
      ie.focus = false;
      return ie;
    });

    //splice in new item
    updatedElements.splice(
      index + 1,
      0,
      {
        type: type,
        subtype: subtype,
        value: value.trim(),
        key: Math.random(),
        focus: false,
      },
      emptyInput(true)
    );

    setInputElements([...updatedElements]);
  };

  //when ArrowRight or Empty Tab/Shift pressed
  const moveAhead = e => {
    //if next element exists
    if (e.target.nextElementSibling) {
      //remove ALL inputs focus (otherwise if tag is clicked, focus gets funky)
      clearInputFocus();
      //place focus on new element
      let nextSibling;
      //if its an empty tag, jump ahead by two
      if (
        e.target.dataset.type === "empty" &&
        e.target.nextElementSibling.nextElementSibling
      ) {
        nextSibling = e.target.nextElementSibling.nextElementSibling;
      } else {
        //must be on a tag
        nextSibling = e.target.nextElementSibling;
      }
      nextSibling.focus();

      let newElement = inputElements.find(
        ie => ie.key == nextSibling.dataset.key
      );
      //update state
      newElement.focus = true;
      setInputElements([...inputElements]);

      e.preventDefault();
    }
  };

  //DROPDOWN CLICK
  const onSuggestionSelect = entity => {
    //blank? use what they typed
    //find focused input element
    let inputElement = inputElements.filter(i => i.focus)[0];
    let el = document.getElementById(inputElement.key);
    if (!entity.value) {
      entity.value = el.innerText;
      //do nothing if they haven't typed an entry
      if (!entity.value.trim()) {
        return;
      }
    }
    setTag(el, entity.value, entity.type, entity.subtype);
  };

  const searchResultLimit = 11;
  //BUILD THE SEARCH
  const onSearch = async () => {
    setSearchLoading(true);
    //reset
    setSearchOperatorSuggestions(false);
    setSearchFullTextSuggestions(false);
    setSuggestionDataFiltered([]);

    //no submissions w/ errors
    if (searchInputError) {
      setSearchError("Please correct errors");
      setSearchLoading(false);
      return false;
    }
    //pass elements into parser, remove empty elements
    let filteredElements = inputElements.filter(t => t.type !== "empty");

    //process parts if needed
    let parts = [];
    if (filteredElements.find(t => t.type === "operator")) {
      parts = parseUserInput(parts, filteredElements);
    }
    //otherwise must be just a single search item
    else {
      if (filteredElements.find(t => t.type === "tag")) {
        parts.push({
          pieces: filteredElements.filter(t => t.type === "tag"),
          children: [],
          operator: "AND",
        });
      }
    }

    if (!parts.length) {
      setSearchError("Please enter search terms");
      setSearchLoading(false);
      return false;
    }

    let _searchJSON = convertPartsToJSON(parts);

    setSearchJSON(_searchJSON);
    if (_searchJSON) {
      //send to search

      let response = await SearchService.search({
        searchJSON: _searchJSON,
        limit: searchResultLimit,
        offset: 0,
      });

      console.log("resp", response);
      if (response.error) {
        //setSearchError(response.error);
        setSearchError("There were no results found for this search");
        setSearchResults([]);
        setSearchLoading(false);
      } else {
        setSearchLoading(false);
        //convert results
        setSearchResultsRaw(response);

        //if position, set state for already being added to position
        if (response.candidates) {
          //(position || company) &&
          response.candidates.map(c => {
            c.added = false;
            c.currentEmployments =
              c.currentEmployments && c.currentEmployments.length
                ? c.currentEmployments[0]
                : undefined;
            return c;
          });
        }
        let convertedToArray = Object.entries(response).map(([key, value]) => ({
          key,
          value,
        }));

        setSearchResults(convertedToArray);

        //set recent searches
        addToSearchHistory(response.search);
      }
    } else {
      setSearchError(
        "System could not understand your search query, please correct format"
      );
      setSearchLoading(false);
    }
  };

  //save search
  const saveSearch = async () => {
    let response = await SearchService.save({ searchJSON: searchJSON });
    if (response.id) {
      searchResults[1].value.id = response.id;
      setSavedSearches([searchResults[1].value, ...savedSearches]);
    }
  };
  //delete search
  const deleteSearch = async id => {
    let response = await SearchService.del(id);
    if (!response.error) {
      setSavedSearches([...savedSearches.filter(s => s.id !== id)]);
    }
  };
  //load saved search
  const loadSavedSearch = async id => {
    let response = await SearchService.get({ id: id });
    if (response.display) {
      loadSearch(response.display);
    }
  };
  //load search (recent or saved)... converts array into input elements for display
  const loadSearch = display => {
    setSearchError(false);
    setInputElements([...searchConvertTextualToInput(display)]);
  };

  //show hide on click outside of dropdown
  const hideSuggestionDropdown = e => {
    let suggestionDropdown = document.getElementById("suggestions");

    //don't remove suggestions if suggestions box clicked, or still in input area
    if (
      suggestionDropdown &&
      !suggestionDropdown.contains(e.target) &&
      !e.target.classList.value.includes("input-item")
    ) {
      //reset
      setSearchOperatorSuggestions(false);
      setSearchFullTextSuggestions(false);
      setSuggestionDataFiltered([]);
      //clear unfinished inputs
      clearInputFocus();
    }
  };
  if (
    searchOperatorSuggestions ||
    searchFullTextSuggestions ||
    suggestionDataFiltered
  ) {
    document.addEventListener("click", hideSuggestionDropdown);
  }
  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Styled position={position} company={company}>
      <Container className="container">
        {position || company ? (
          <LinkClose
            to={position ? "/position/" + position.id : "/client/" + company.id}
          >
            <img src={imgCircleX} title="Cancel" alt="Cancel" />
          </LinkClose>
        ) : (
          ""
        )}
        {!position && !company ? (
          <Card id="search-save">
            <h1>Saved Searches</h1>
            <div className="content">
              {savedSearches.length ? (
                <ul>
                  {savedSearches.map((s, i) => {
                    return (
                      <li key={i}>
                        <span
                          className="text"
                          onClick={() => loadSavedSearch(s.id)}
                        >
                          {s.textual}
                        </span>
                        <span
                          className="delete"
                          onClick={() => deleteSearch(s.id)}
                        >
                          x
                        </span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                "No saved searches"
              )}

              {searchResults.length ? (
                <>
                  <Button
                    className="save"
                    primary
                    size="small"
                    onClick={() => saveSearch()}
                  >
                    Save Search
                  </Button>
                  <ExportSearchResults results={searchResults} />
                </>
              ) : (
                ""
              )}
            </div>
          </Card>
        ) : (
          ""
        )}
        <div className="display">
          {searchError ? (
            <div id="search-error">
              <p>{searchError}</p>
            </div>
          ) : (
            ""
          )}
          {searchInputError ? (
            <div id="format-suggestions" className={searchError ? "error" : ""}>
              <p>{searchInputError}</p>
            </div>
          ) : (
            ""
          )}

          <Input id="search-wrapper" type="search" className="editable search">
            <div className="input editable">
              {inputElements && inputElements.length
                ? inputElements.map((el, i) => {
                    return (
                      <span
                        key={el.key}
                        tabIndex="0"
                        id={el.key}
                        data-key={el.key}
                        data-index={i}
                        data-type={el.type}
                        data-subtype={el.subtype}
                        className={
                          "input-item " +
                          el.type +
                          " " +
                          el.subtype +
                          (el.error ? " error" : "") +
                          (searchError ? " search-error" : "")
                        }
                        contentEditable={el.type === "empty" ? true : false}
                        suppressContentEditableWarning={true}
                        onKeyDown={e => onInputKeyDown(e)}
                        onKeyUp={e => onInputKeyUp(e)}
                        onBlur={e => onInputBlur(e)}
                        autoFocus={el.focus}
                        onClick={e => onInputClick(e)}
                      >
                        {el.type !== "empty" ? (
                          <span className="value" data-index={i}>
                            {el.value}
                          </span>
                        ) : (
                          ""
                        )}
                        {el.type !== "empty" ? (
                          <span
                            className="delete"
                            data-index={i}
                            onClick={onInputDelete}
                          >
                            X
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    );
                  })
                : ""}
            </div>
            <span className="go" onClick={onSearch}></span>
          </Input>

          {searchTagSuggestions.length ||
          searchOperatorSuggestions ||
          searchFullTextSuggestions ||
          suggestionDataFiltered.length ? (
            <div
              id="suggestions"
              className={"dropdown"}
              style={{
                top: inputPosition.top + 40,
                left:
                  inputPosition.left + 300 > window.innerWidth
                    ? inputPosition.left -
                      (inputPosition.left + 340 - window.innerWidth)
                    : inputPosition.left,
              }}
            >
              {searchOperatorSuggestions ? (
                <div className="operators">
                  <h2>Operators:</h2>
                  <ul>
                    <li
                      onClick={() =>
                        onSuggestionSelect({
                          value: "AND",
                          type: "operator",
                          subtype: "and",
                        })
                      }
                    >
                      AND
                    </li>
                    <li
                      onClick={() =>
                        onSuggestionSelect({
                          value: "OR",
                          type: "operator",
                          subtype: "or",
                        })
                      }
                    >
                      OR
                    </li>
                    <li
                      onClick={() =>
                        onSuggestionSelect({
                          value: "NOT",
                          type: "operator",
                          subtype: "not",
                        })
                      }
                    >
                      NOT
                    </li>
                    <li
                      onClick={() =>
                        onSuggestionSelect({
                          value: "(",
                          type: "parenthesis",
                          subtype: "close",
                        })
                      }
                    >
                      (
                    </li>
                    <li
                      onClick={() =>
                        onSuggestionSelect({
                          value: ")",
                          type: "parenthesis",
                          subtype: "close",
                        })
                      }
                    >
                      )
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}

              {searchFullTextSuggestions ? (
                <div className="fulltext">
                  <h2>Search in:</h2>
                  <ul>
                    <li
                      onClick={() =>
                        onSuggestionSelect({
                          value: "",
                          type: "tag",
                          subtype: "note",
                        })
                      }
                    >
                      Notes
                    </li>
                    <li
                      onClick={() =>
                        onSuggestionSelect({
                          value: "",
                          type: "tag",
                          subtype: "document",
                        })
                      }
                    >
                      Résumés
                    </li>
                    {/*<li
                      onClick={() =>
                        onSuggestionSelect({
                          value: "",
                          type: "tag",
                          subtype: "interview"
                        })
                      }
                    >
                      Interviews
                    </li> */}
                  </ul>
                </div>
              ) : (
                ""
              )}

              {(searchTagSuggestions && searchTagSuggestions.length) ||
              (suggestionDataFiltered && suggestionDataFiltered.length) ? (
                <div id="suggestion-wrapper">
                  <h2>Suggestions:</h2>
                  {searchTagSuggestions && searchTagSuggestions.length ? (
                    <div className="group">
                      <h3>Tags</h3>
                      <ul>
                        {searchTagSuggestions.map((tag, i) => {
                          return (
                            <li
                              key={i}
                              onClick={() =>
                                onSuggestionSelect({
                                  value: tag.tag,
                                  type: "tag",
                                  subtype: "tag",
                                })
                              }
                            >
                              <span className="suggestion">{tag.tag}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}

                  {suggestionDataFiltered && suggestionDataFiltered.length
                    ? suggestionDataFiltered.map((sd, i) => {
                        let title, subtype;

                        switch (sd.key) {
                          case "candidates":
                            title = "Candidates";
                            subtype = "candidate";
                            break;
                          case "companies":
                            title = "Companies";
                            subtype = "company";
                            if (position || company) {
                              //only searching candidates
                              return;
                            }
                            break;
                          case "positions":
                            title = "Job Orders";
                            subtype = "position";
                            if (position) {
                              //only searching candidates
                              return;
                            }
                            break;
                          case "tags":
                            title = "Tags";
                            subtype = "tag";
                            break;
                          case "locations":
                            title = "Locations";
                            subtype = "location";
                            break;
                          default:
                            return;
                        }
                        return (
                          <div className="group" key={i}>
                            <h3>{title}</h3>
                            <ul>
                              {sd.value.map((s, e) => {
                                return (
                                  <li
                                    key={e}
                                    tabIndex={i + 1}
                                    onClick={() =>
                                      onSuggestionSelect({
                                        value: s.suggestion,
                                        type: "tag",
                                        subtype: subtype,
                                      })
                                    }
                                  >
                                    <span className="suggestion">
                                      {s.suggestion}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {position ? (
            <>
              <div id="searching-for">
                Searching Candidates for{" "}
                <Link to={"/position/" + position.id}>
                  <b>
                    {formatUUID(position.id)} - {position.title}
                  </b>
                </Link>
                {searchResultsRaw.candidates ? (
                  <div className="actions">
                    <Button onClick={() => addAllCandidatesToPosition()}>
                      Add All
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {company ? (
            <>
              <div id="searching-for">
                Searching Contacts for{" "}
                <Link to={"/client/" + company.id}>
                  <b>{company.name}</b>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}

          {searchLoading ? (
            <div id="search-loading">
              <LoadingSubmit />
            </div>
          ) : (
            ""
          )}

          {!position && !company && !searchLoading && searchResults.length ? (
            <Tabs className="filter">
              <ul>
                <li
                  className={filter === "all" ? "active" : ""}
                  onClick={() => filterTypes("all")}
                >
                  All
                </li>
                {searchResultsRaw.candidates ? (
                  <li
                    className={filter === "candidates" ? "active" : ""}
                    onClick={() => filterTypes("candidates")}
                  >
                    Only Candidates
                  </li>
                ) : (
                  ""
                )}
                {searchResultsRaw.positions ? (
                  <li
                    className={filter === "positions" ? "active" : ""}
                    onClick={() => filterTypes("positions")}
                  >
                    Only Job Orders
                  </li>
                ) : (
                  ""
                )}

                {searchResultsRaw.companies ? (
                  <li
                    className={filter === "companies" ? "active" : ""}
                    onClick={() => filterTypes("companies")}
                  >
                    Only Companies
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </Tabs>
          ) : (
            ""
          )}

          <div className="results">
            {!position &&
            !company &&
            !searchLoading &&
            !searchResults.length ? (
              <div className="group recent-searches">
                <h2>Recent Searches</h2>
                {recentSearches.length ? (
                  <ul>
                    {recentSearches.map((r, i) => {
                      return (
                        <li key={i} onClick={() => loadSearch(r.display)}>
                          {r.textual}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p>No recent searches</p>
                )}
              </div>
            ) : (
              ""
            )}

            {!searchLoading && searchResults.length ? (
              <>
                {searchResults.map((res, s) => {
                  let title, type, results;
                  switch (res.key) {
                    case "candidates":
                      title = "Candidates";
                      type = "candidate";
                      break;
                    case "companies":
                      title = "Companies";
                      type = "client";
                      break;
                    case "positions":
                      title = "Positions";
                      type = "position";
                      break;
                    default:
                      return;
                  }

                  results = [...res.value];
                  if (results.length >= searchResultLimit) {
                    results.splice(-1, 1);
                  }

                  return (
                    <React.Fragment key={s}>
                      {filter === "all" || filter === res.key ? (
                        <div className={"group " + res.key}>
                          <h2>{title}</h2>
                          <ul>
                            {" "}
                            {results.map((entity, i) => {
                              //grab id
                              entity.id = entity._links.self.href.replace(
                                /\/(.*?)\//,
                                ""
                              );
                              //addedToPosition ?
                              if (
                                (position || company) &&
                                candidateIds.includes(entity.id)
                              ) {
                                entity.added = true;
                              }
                              return (
                                <li key={i}>
                                  {type === "candidate" ? (
                                    <>
                                      <dl>
                                        <dt>Name</dt>
                                        <dd className="title">
                                          <Link to={"/candidate/" + entity.id}>
                                            {entity.firstName} {entity.lastName}
                                          </Link>
                                        </dd>
                                        {entity.currentEmployments ? (
                                          <>
                                            <dt>Employment</dt>
                                            <dd className="employment">
                                              {entity.currentEmployments.title}{" "}
                                              at{" "}
                                              {
                                                entity.currentEmployments
                                                  .company
                                              }
                                            </dd>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <dt>Location</dt>
                                        <dd className="location">
                                          {formatAddress(
                                            entity.city,
                                            entity.state,
                                            entity.country,
                                            false,
                                            false //link to maps
                                          )}
                                        </dd>
                                      </dl>
                                      <div className="img">
                                        <Link to={"/candidate/" + entity.id}>
                                          <img
                                            src={
                                              entity.profilePicture
                                                ? entity.profilePicture
                                                : imgProfileMissing
                                            }
                                            width={
                                              entity.profilePicture ? "40" : 20
                                            }
                                            alt=""
                                            title={
                                              entity.firstName +
                                              " " +
                                              entity.lastName
                                            }
                                          />
                                        </Link>
                                      </div>
                                      <div className="actions">
                                        {!entity.added ? (
                                          <>
                                            {company ? (
                                              <Link
                                                to="#"
                                                onClick={e =>
                                                  addCandidateToCompany(
                                                    e,
                                                    entity
                                                  )
                                                }
                                              >
                                                Add to Company
                                              </Link>
                                            ) : (
                                              <>
                                                {entity.emailPersonal ? (
                                                  <a
                                                    href={
                                                      "mailto:" +
                                                      entity.emailPersonal
                                                    }
                                                  >
                                                    Email
                                                  </a>
                                                ) : (
                                                  ""
                                                )}
                                                <Link
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  to={
                                                    "/search/add-to-position/" +
                                                    entity.id +
                                                    (position && position.id
                                                      ? "/" + position.id
                                                      : "")
                                                  }
                                                  onClick={
                                                    position
                                                      ? e =>
                                                          addCandidateToPosition(
                                                            e,
                                                            entity
                                                          )
                                                      : null
                                                  }
                                                >
                                                  Add to Job Order
                                                </Link>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <img src={imgCircleCheck} />
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <Link to={"/" + type + "/" + entity.id}>
                                      {type === "client" ? (
                                        <span>{entity.name}</span>
                                      ) : (
                                        ""
                                      )}
                                      {type === "position" ? (
                                        <>
                                          <span>{entity.title}</span>
                                          &nbsp;for&nbsp;
                                          <span>{entity.company}</span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Link>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                          {res.value.length >= searchResultLimit ? (
                            <p className="not-shown">
                              Some results are not shown for {res.key}. If
                              needed please refine search
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </Styled>
  );
}
