import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../services/Auth";
import { useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { CompanyService } from "../../services/Company";
import { CandidateService } from "../../services/Candidate";
import { StageService } from "../../services/Stage";
import { TagService } from "../../services/Tag";
import styled from "styled-components";
import Select from "react-select";

import {
  urgencyOptionsBuild,
  jobLevelBuild,
  functionsBuild,
  regionsBuild,
  lookingToHireBuild,
} from "../Shared/Data";

import { Form } from "../Shared/Form";
import { Validate } from "../Shared/Validate";
import { Form as StyledForm } from "../../styles/Form";
import { Container, Button, PageTitle, LinkClose } from "../../styles/Common";
import { LoadingSubmit } from "../../styles/Animation";

import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";

import { toast } from "react-toastify";

import imgUpload from "../../assets/upload.svg";
import imgCircleX from "../../assets/circle-x.svg";
import imgX from "../../assets/x.svg";
import { PositionService } from "../../services/Position";
import { UserService } from "../../services/User";
import moment from "moment";

Moment.locale("en");
momentLocalizer();

export default function PositionNew(props) {
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );

  //needed for default owner
  const jwtInfo = AuthService.jwtInfo();

  const initSelectValues = {
    companyId: [],
    tags: [],
    jobLevel: jobLevelBuild[0],
    functionality: functionsBuild[0],
    region: regionsBuild[0],
    deadline: lookingToHireBuild[0],
    owner: {
      label: jwtInfo.lastName + ", " + jwtInfo.firstName,
      value: jwtInfo.user,
    },
    primary: [],
    secondary: [],
  };
  const [selectValues, setSelectValues] = useState(initSelectValues);
  //react-hook-form
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      deadline: selectValues.deadline.value,
      region: selectValues.region.value,
      owner: selectValues.owner.value,
      jobLevel: selectValues.jobLevel.value,
      functionality: selectValues.functionality.value,
    },
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);
  const [defaultSelect, setDefaultSelect] = useState(null);

  const [urgencyOptions, setUrgencyOptions] = useState(urgencyOptionsBuild);
  //Get those Stages
  const [initStageOptions, setInitStageOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  //get those companies
  const [companies, setCompanies] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [users, setUsers] = useState([]);

  const onSelectChange = (opt, actionMeta) => {
    let name = actionMeta.name;
    let value;
    //dropdown
    if (!opt) {
      //empty data
      value = null;
    } else if (opt && opt.value) {
      value = opt.value;
    } else {
      //multiselect
      value = opt;
    }
    selectValues[name] = opt;

    setValue(name, value); //for react-hook
    setSelectValues({ ...selectValues });
    //reset any errors
    setErrorMessage("");

    //when choosing a company, automatically fill out address if not already filled in
    if (name === "companyId") {
      let data = getValues();
      let companySelected = companies.find(c => c.data.companyId === value);
      if (!data["address[city]"]) {
        setValue("address[city]", companySelected.data.city);
      }
      if (!data["address[state]"]) {
        setValue("address[state]", companySelected.data.state);
      }
      if (!data["address[zip]"]) {
        setValue("address[zip]", companySelected.data.zip);
      }
      if (!data["address[country]"]) {
        setValue("address[country]", companySelected.data.country);
      }
    }
  };

  const prepareStages = _stages => {
    return _stages.map(s => {
      let readOnly = false;
      if (
        s.name.match(/Possible|Short List|Presented|Rejected|Passed|^Offer$/)
      ) {
        readOnly = true;
      }
      return {
        label: s.name,
        value: s.stageId,
        checked: true,
        readOnly: readOnly,
      };
    });
  };
  //get candidates
  const getCandidates = async () => {
    let _candidates = await CandidateService.list({ limit: 10000 });

    if (_candidates._embedded) {
      setCandidates(
        _candidates._embedded.map(c => {
          return {
            value: c.candidateId,
            label: c.firstName + " " + c.lastName,
            email: c.emailPersonal ? c.emailPersonal : c.emailWork,
            phone: c.phone,
          };
        })
      );
    }
  };

  //get users
  const getUsers = async () => {
    let response = await UserService.list({
      limit: 1000,
      sortBy: "lastName.asc",
      filter: {
        search: {
          NOT: {
            field: "password",
            value: "^$"
          }
        }
      }
    })

    if (response._embedded) {
      setUsers(
        response._embedded.map(o => {
          return {
            label: o.lastName + ", " + o.firstName,
            value: o.id,
          };
        })
      );
    }
  };
  //get companies
  const getCompanies = async () => {
    let _companies = await CompanyService.list({ limit: 10000 });
    if (_companies && _companies._embedded) {
      setCompanies(
        _companies._embedded.map(c => {
          return { value: c.companyId, label: c.name, data: c };
        })
      );
    }
  };
  //get Stages
  const getStages = async () => {
    let _stages = await StageService.list({ limit: 100, stage: "" });

    if (_stages._embedded) {
      setInitStageOptions(prepareStages(_stages._embedded));
      setStageOptions(prepareStages(_stages._embedded));
    }
  };
  useEffect(() => {
    getStages();
    getCompanies();
    getUsers();
    getCandidates();
  }, []);

  //Get those Tags
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      //available tags
      let _tags = await TagService.get({ type: "positionTag" });
      if (_tags) {
        setTags(
          _tags._embedded.map(t => {
            return { value: t.tagId, label: t.tag };
          })
        );
      }
    };
    fetchTags();
  }, []);

  const onChange = e => {
    if (e.target.name.match(/phone/)) {
      e.target.value = Validate.Phone(e.target.value);
    }
    //reset any errors
    setErrorMessage("");
  };
  //Submit Form
  const onSubmit = async data => {
    //prevent company coming up as type undefined
    //data.company = data.company ? data.company : "";
    if (!data.companyId) {
      setErrorMessage("Please choose a Client for this position");
      return;
    }
    //company Name is required by API (doesn't look it up dynamically)
    //data.company = companies.find(c => c.companyId === data.companyId).name;
    //return;

    data.onHold = "No";
    data.status = "Open";

    //reverse it and change to boolean
    data.manualInterview = data.manualInterview ? false : true;

    //map tags to values only if set
    if (data.tags && data.tags.length) {
      data.tags = data.tags.map(t => t.value);
    } else {
      data.tags = [];
    }
    //map stages to ints only if set
    if (data.stages && data.stages.length) {
      data.stages = data.stages.map(s => parseInt(s));
    } else {
      data.stages = [];
    }
    //startDate
    data.kickoff = moment(
      document.querySelector('input[name="kickoff"]').value
    ).toDate();

    data.hiringManagers = [];
    if (hiringManagers.length) {
      hiringManagers.map(s => {
        if (s.firstName.trim()) {
          //could be blank

          data.hiringManagers.push({
            candidateId: false,
            firstName: s.firstName,
            lastName: s.lastName,
            phone: s.phone,
            email: s.email,
            isPrimary: s.isPrimary,
          });
        } else if (s.candidateId !== "false" && s.candidateId) {
          data.hiringManagers.push({
            candidateId: s.candidateId,
            isPrimary: s.isPrimary,
          });
        }
      });
    }

    //api errors on null
    data.primary = data.primary ? data.primary : undefined;
    data.secondary = data.secondary ? data.secondary : undefined;

    //trim whitespace from inputs
    data = Validate.TrimInputs(data);

    //post to database
    setFormSubmission(true);
    let response = await PositionService.create(data);
    setFormSubmission(false);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.id) {
      //clear form
      reset();
      setItems([resetItem(true, false)]);
      setDefaultSelect(null);
      setSelectValues(initSelectValues);
      setStageOptions(initStageOptions);
      setUrgencyOptions(urgencyOptionsBuild);

      //toast notificiation
      toast.success("Position (" + data.title + ") has been created", {
        position: "bottom-center",
        closeOnClick: false,
        onClick: () => props.history.push("/position/" + response.id),
      });
    }
  };

  //set initial school
  const resetItem = (initial, index) => {
    let initial_index = index ? index : Math.random();
    return {
      initial: initial,
      index: initial_index,
      key: initial_index,
      candidateId: "",
      candidate: null,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      focus: "",
      isPrimary: initial ? true : false,
    };
  };
  const [hiringManagers, setItems] = useState([resetItem(true, false)]);

  const addItem = () => {
    //set them, then add new item to the mix
    setItems([...setItemInputValues(), resetItem(false, false)]);
  };
  const deleteItem = item => {
    //set values, then unregister input hooks
    setItems(setItemInputValues().filter(e => e.index != item.index));
    unregister("hiringManagers[" + item.index + "][firstName]");
    unregister("hiringManagers[" + item.index + "][lastName]");
    unregister("hiringManagers[" + item.index + "][phone]");
    unregister("hiringManagers[" + item.index + "][email]");
    unregister("hiringManagers[" + item.index + "][isPrimary]");
  };

  //radio check for primary hiring manager
  const setHiringManagerPrimary = item => {
    setItems([
      ...hiringManagers.map(i => {
        if (i === item) {
          i.isPrimary = true;
        } else {
          i.isPrimary = false;
        }
        return i;
      }),
    ]);
    //setItemInputValues();
  };
  //create new select option
  const onCreateHiringManager = (value, item) => {
    let ele = document.getElementsByName(
      "hiringManagers[" + item.index + "][candidateId]"
    )[0];
    ele.value = "NEW MANAGER-" + value;
    setItems([...setItemInputValues()]);

    return;
  };

  const selectHiringManager = (opt, actionMeta, item) => {
    let ele = document.getElementsByName(actionMeta.name)[0];
    if (actionMeta.action === "clear") {
      ele.value = "";
    } else {
      ele.value = opt.value;
    }

    //ele.id = actionMeta.name;
    //setItems(setItemInputValues);
    setItems([...setItemInputValues()]);

    //document.getElementById(item.index).value = opt.value;
    //hiringManagers[" + props.item.index + "][candidateId]
  };

  const setItemInputValues = () => {
    //need to get all current values of inputs before rewriting
    let inputs = document.querySelectorAll('input[name^="hiringManagers"]'); //, [id^="hiringManagers-"] input
    let _items = [];

    let _id,
      id = "";
    let _buildItem;
    let i = 0;
    let nodes = Array.from(inputs);
    let numNodes = nodes.length;
    let numInGroup = 0;
    let temp_id = "";
    let r = 1; //iterates to know when next item starts based on prev id being the same
    nodes.forEach((input, x) => {
      //seleced candidate from list
      id = input.name.split("][")[0].split("hiringManagers[")[1];
      if (temp_id !== id) {
        r = 1;
        temp_id = id;
      }
      numInGroup = nodes.filter(n => n.name.match(new RegExp(id))).length;

      if (i === 0 && x === 0) {
        _buildItem = resetItem(true, id);
      }
      _buildItem.index = _buildItem.key = id;
      //get changed value
      //set value for react hook
      if (input.name.match(/candidateId/)) {
        //create a new one!
        if (input.value.match(/NEW MANAGER-/)) {
          _buildItem.candidateId = false;
          _buildItem.firstName = input.value.split("-")[1];
          _buildItem.focus = "lastName";
        } else {
          //fill in candidate
          _buildItem.firstName = ""; //reset if needed
          _buildItem.candidateId = input.value;
          _buildItem.candidate = candidates.find(
            c => c.value === _buildItem.candidateId
          );
        }
      } else if (input.name.match(/firstName/)) {
        _buildItem.firstName = input.value;
        if (input.value !== hiringManagers[i].firstName) {
          _buildItem.focus = "lastName";
        }
      } else if (input.name.match(/lastName/)) {
        _buildItem.lastName = input.value;
        if (input.value !== hiringManagers[i].lastName) {
          _buildItem.focus = "phone";
        }
      } else if (input.name.match(/phone/)) {
        _buildItem.phone = Validate.Phone(input.value);
        if (input.value !== hiringManagers[i].phone) {
          _buildItem.focus = "email";
        }
      } else if (input.name.match(/email/)) {
        _buildItem.email = input.value;
      } else if (input.name.match(/isPrimary/)) {
        _buildItem.isPrimary = input.checked;
      }
      //reset build for new input group
      //keep _id set to latest id
      _id = id;
      if (_id && r % numInGroup === 0) {
        _items.push(_buildItem);
        _buildItem = resetItem(false);
        i++;
      }
      r++;
    });

    return _items;
  };
  /*
   * Button to add new hiring manager
   */
  const fieldsetActionsHiringManager = () => {
    return (
      <Button
        type="button"
        fieldsetAction
        size="small"
        style={{ marginBottom: "14px" }}
        onClick={() => addItem()}
      >
        Add Hiring Manager
      </Button>
    );
  };

  const ButtonClose = styled.button`
    border: 0;
    padding: 0;
    background: none;
    width: 16px;
    height: 16px;
    margin: ${props => (props.skills ? "13px 0 0 11px" : "35px 0 0")};
  `;
  const HiringManager = props => {
    return (
      <li className="row group">
        <ol>
          {props.item.candidateId !== "false" &&
          props.item.candidateId !== false ? (
            <Form.InputWrapper
              label="Select Manager"
              name={"hiringManagers[" + props.item.index + "][candidateId]"}
              errors={errors}
              className="react-select position-title"
            >
              <CreatableSelect
                id={"hiringManagers-" + props.item.index}
                name={"hiringManagers[" + props.item.index + "][candidateId]"}
                isClearable={true}
                createOptionPosition="first"
                className="select"
                classNamePrefix="position-title"
                options={candidates}
                placeholder=""
                value={
                  props.item.candidateId
                    ? {
                        label: props.item.candidate
                          ? props.item.candidate.label
                          : "",
                        value: props.item.candidateId,
                      }
                    : null
                }
                onCreateOption={value =>
                  onCreateHiringManager(value, props.item)
                }
                onChange={(opt, actionMeta) =>
                  selectHiringManager(opt, actionMeta, props.item)
                }
              />
            </Form.InputWrapper>
          ) : (
            <input
              type="hidden"
              value="false"
              name={"hiringManagers[" + props.item.index + "][candidateId]"}
            />
          )}

          {props.item.candidateId === false ||
          props.item.candidateId === "false" ? (
            <Form.InputWrapper
              label="First Name"
              name={"hiringManagers[" + props.item.index + "][firstName]"}
              errors={errors}
            >
              <Form.TextInput
                name={"hiringManagers[" + props.item.index + "][firstName]"}
                type="text"
                className="smaller"
                value={props.item.firstName}
                onBlur={() => setItems(setItemInputValues)}
                focus={props.item.focus === "firstName"}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}

          {props.item.candidateId === false ||
          props.item.candidateId === "false" ? (
            <Form.InputWrapper
              label={props.item.candidateId === false ? "Last Name" : "Name"}
              name={"hiringManagers[" + props.item.index + "][lastName]"}
              errors={errors}
            >
              <Form.TextInput
                name={"hiringManagers[" + props.item.index + "][lastName]"}
                type="text"
                className="smaller"
                value={props.item.lastName}
                onBlur={() => setItems(setItemInputValues)}
                focus={props.item.focus === "lastName"}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Phone"
              name={"hiringManagers[" + props.item.index + "][phone]"}
              errors={errors}
            >
              {props.item.candidateId === false ||
              props.item.candidateId === "false" ? (
                <Form.TextInput
                  name={"hiringManagers[" + props.item.index + "][phone]"}
                  type="text"
                  className="smaller"
                  value={props.item.phone}
                  onBlur={() => setItems(setItemInputValues)}
                  focus={props.item.focus === "phone"}
                  onChange={onChange}
                />
              ) : (
                <div className="static">
                  {props.item.candidate.phone
                    ? props.item.candidate.phone
                    : "-"}
                </div>
              )}
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Email"
              name={"hiringManagers[" + props.item.index + "][email]"}
              errors={errors}
            >
              {props.item.candidateId === false ||
              props.item.candidateId === "false" ? (
                <Form.TextInput
                  name={"hiringManagers[" + props.item.index + "][email]"}
                  type="email"
                  className="smaller"
                  value={props.item.email}
                  onBlur={() => setItems(setItemInputValues)}
                  focus={props.item.focus === "email"}
                />
              ) : (
                <div className="static">
                  {props.item.candidate.email
                    ? props.item.candidate.email
                    : "-"}
                </div>
              )}
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Primary?"
              name={"hiringManagers[" + props.item.index + "][isPrimary]"}
              errors={errors}
            >
              <Form.RadioButton
                id={props.item.index}
                name={"hiringManagers[" + props.item.index + "][isPrimary]"}
                option={{
                  value: props.item.isPrimary ? 1 : 0,
                  checked: props.item.isPrimary,
                }}
                onClick={() => setHiringManagerPrimary(props.item)}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}
        </ol>
        {!props.item.initial && (
          <ButtonClose type="button" onClick={() => deleteItem(props.item)}>
            <img src={imgX} alt="Delete" title="Delete" />
          </ButtonClose>
        )}
      </li>
    );
  };

  const [automaticScheduling, setAutomaticScheduling] = useState([
    { label: "Automatic Scheduling?", value: 1, checked: false },
  ]);

  return (
    <Container className="form">
      <PageTitle>Create Job Order</PageTitle>

      <LinkClose to="/positions">
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>

      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol>
            <Form.Fieldset legend="Job Order Details" className="rows">
              <Form.InputWrapper
                label="Title/Role"
                name="title"
                errors={errors}
                required={true}
              >
                <Form.TextInput
                  name="title"
                  type="text"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Company"
                name="companyId"
                errors={errors}
                className="react-select position-title"
                required={true}
              >
                <Select
                  ref={register({
                    name: "companyId",
                  })}
                  name="companyId"
                  isClearable={true}
                  className="select"
                  classNamePrefix="position-title"
                  options={companies}
                  value={selectValues.companyId}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Job Level"
                name="jobLevel"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "jobLevel",
                  })}
                  name="jobLevel"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={jobLevelBuild}
                  value={selectValues.jobLevel}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Function"
                name="functionality"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "functionality",
                  })}
                  name="functionality"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={functionsBuild}
                  value={selectValues.functionality}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>

              <Form.InputWrapper
                label="Compensation"
                name="compensation"
                errors={errors}
              >
                <Form.TextInput
                  name="compensation"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>

              <Form.InputWrapper
                label="Position Brief"
                name="brief"
                errors={errors}
              >
                <Form.TextInput
                  name="brief"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>
            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="City"
                name="address[city]"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="address[city]"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="State"
                name="address[state]"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="address[state]"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  className="smaller"
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Zip"
                name="address[zip]"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="address[zip]"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  className="smaller"
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Country"
                name="address[country]"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="address[country]"
                  type="text"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  placeholder="USA"
                  className="x-small"
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Region"
                name="region"
                errors={errors}
                className="react-select region"
                style={{ width: 214 }}
              >
                <Select
                  ref={register({
                    name: "region",
                  })}
                  name="region"
                  isClearable={false}
                  className="select"
                  classNamePrefix="region"
                  options={regionsBuild}
                  value={selectValues.region}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="Description"
                name="description"
                errors={errors}
              >
                <Form.Textarea
                  className="span-2"
                  name="description"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>

              <Form.InputWrapper
                label="Tags"
                name="tags"
                errors={errors}
                className="react-select tags rowspan-2 "
              >
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  ref={register({ name: "tags" })}
                  isMulti
                  name="tags"
                  isClearable={false}
                  className="multi-select"
                  classNamePrefix="tags"
                  onChange={onSelectChange}
                  options={tags}
                  value={selectValues.tags}
                  placeholder=""
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset
              legend="Hiring Manager(s)"
              fieldsetActions={fieldsetActionsHiringManager()}
            >
              {hiringManagers.length &&
                hiringManagers.map((hiringManager, index) => (
                  <HiringManager key={hiringManager.key} item={hiringManager} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset
              className="rows stacked"
              style={{ position: "relative", zIndex: 1 }}
            >
              <Form.InputWrapper name={"manualInterview"} errors={errors}>
                <Form.Checkboxes
                  forwardRef={register}
                  name={"manualInterview"}
                  options={automaticScheduling}
                  setOptions={setAutomaticScheduling}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset legend="Intake Details" className="rows">
              <Form.InputWrapper
                label="Looking to Hire"
                name="deadline"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "deadline",
                  })}
                  name="deadline"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={lookingToHireBuild}
                  value={selectValues.deadline}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Kick Off Date"
                name="kickoff"
                errors={errors}
              >
                <DateTimePicker
                  time={false}
                  name="kickoff"
                  defaultValue={new Date()}
                  ref={register({
                    name: "kickoff",
                    value: new Date(),
                  })}
                />
              </Form.InputWrapper>
            </Form.Fieldset>
            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="Owner"
                name="owner"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "owner",
                  })}
                  name="owner"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={users}
                  value={selectValues.owner}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Primary"
                name="primary"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "primary",
                  })}
                  name="primary"
                  isClearable={true}
                  className="select"
                  classNamePrefix="position-title"
                  options={users}
                  value={selectValues.primary}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Secondary"
                name="secondary"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "secondary",
                  })}
                  name="secondary"
                  isClearable={true}
                  className="select"
                  classNamePrefix="position-title"
                  options={users}
                  value={selectValues.secondary}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset
              className="rows stacked"
              style={{ position: "relative", zIndex: 1 }}
            >
              <Form.InputWrapper
                label="Urgency"
                name="urgency"
                className="tabs"
                errors={errors}
              >
                <Form.RadioButtons
                  name="urgency"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                  options={urgencyOptions}
                  setOptions={setUrgencyOptions}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset className="stacked">
              <Form.InputWrapper
                label="Stages"
                name="stages"
                className="columns"
                errors={errors}
              >
                <Form.Checkboxes
                  name="stages"
                  type="text"
                  forwardRef={register}
                  options={stageOptions}
                  setOptions={setStageOptions}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.FieldsetActions>
              {errorMessage && <Form.Error message={errorMessage} />}
              {!formSubmission ? (
                <>
                  <Button primary>Save &amp; Create Job Order</Button>
                  <Button type="button">
                    <Link to="/positions">Cancel</Link>
                  </Button>
                </>
              ) : (
                <LoadingSubmit></LoadingSubmit>
              )}
            </Form.FieldsetActions>
          </ol>
        </form>
      </StyledForm>
    </Container>
  );
}
