import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import device from "../../config/device";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../services/Auth";
import { NotificationService } from "../../services/Notification";
import { DateTime } from "../../helpers/DateTime";

import styled from "styled-components";
import { Container } from "../../styles/Common";
import { Colors } from "../../styles/Colors";
import imgUser from "../../assets/user.svg";
import imgLogo from "../../assets/logo.svg";
import imgLogoBizDev from "../../assets/logo-bd.svg";
import imgSearch from "../../assets/search.svg";
import imgNotifications from "../../assets/notifications.svg";
import imgHamburger from "../../assets/hamburger.svg";
import imgLogoFull from "../../assets/logo-full.svg";

const Styles = styled.div`
  display: flex;
  background: #000;
  display: block;
  height: 60px;
  box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
  &.external-user {
    background: ${Colors.primary};
    header {justify-content: center;}
  }
  &.bizdev {
    background: ${Colors.primary};
  }
  header {
    height: 60px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 200;
  }
  .logo {
    margin-left: 30px;
    img {
      display: block;
    }
  }
  nav {
    height: 100%;
    margin-left: 158px;
    ul {
      height: 100%;
      display: flex;
      justify-content: space-between;
    }
    li {
      height: 100%;
      margin-right: 30px;
      border-top: 3px solid transparent;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border-top-color: #8cc440;
        a {
          color: #8cc440;
        }
      }
    }
    a {
      display: block;
      height: 100%;
      line-height: 57px;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }

  @media ${device.tabletMMax} {
    nav {
      width: 32px;
      height: 32px;
      margin: 0 auto 0 20px;
      align-self: top;
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: url(${imgHamburger}) no-repeat;
        background-size: contain;
      }
      ul {
        display: none;
        position: absolute;
        width: 100%;
        height: 32px;
        left: 0;
        bottom: -33px;
        margin: 0 auto;
        justify-content: space-around;
        background: #000;
        box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
      }
      li {
        margin-right: 15px;
        a {
          line-height: 26px;
        }
      }
      &.active {
        ul {
          display: flex;
        }
      }
    }

    &.bizdev {
      nav {
        ul {
          background: ${Colors.primary};
        }
      }
    }
  }
  .actions {
    ul {
      display: flex;
      justify-content: space-between;
      ul {
        background: #fff;
        position: absolute;
        top: 30px;
        right: -10px;
        padding: 0 15px;
        border-radius: 3px;
        box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);
        display: block;
      }
    }
    li {
      width: 21px;
      height: 22px;
      margin-right: 30px;
      position: relative;
      li {
        width: auto;
        min-width: 100px;
        text-align: right;
        white-space: nowrap;
        border-bottom: 1px solid #eee;
        padding: 10px 0 3px;
        margin-right: 0;
        a {
          display: block;
        }
      }
    }
    .search img {
      width: 21px;
      height: 21px;
    }
    .notifications {
      &.active {
        &:before {
          position: absolute;
          top: -10px;
          right: -10px;
          color: #fff;
          content: "${props => props.notifications}";
          background: red;
          width: 12px;
          height: 12px;
          display: block;
          border-radius: 10px;
          border: 1px solid #000;
          font-size: 0.7rem;
          text-align: center;
          line-height: 13px;
          padding: 3px;
        }
      }

      img {
        width: 20px;
        height: 21px;
      }
      ul {
        right:-25px;
      }
      .date {font-weight:600;}
    }

    .user img {
      width: 21px;
      height: 22px;
    }
  }

  &.bizdev {
    .notifications {
      &.active {
        &:before {
          border-color: ${Colors.primary};
        }
      }
    }
  }
`;

export default function Header(props) {
  const routeProps = useContext(React.createContext(null));
  const jwtInfo = AuthService.jwtInfo();

  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.isLoggedIn()
  );

  //force relogin w/ for users w/ token
  if (jwtInfo && !jwtInfo.firstName) {
    AuthService.logout();
    window.location.href = "/login";
  }
  const [notifications, setNotifications] = useStore("notifications", []);

  const getNotifications = async () => {
    let response = await NotificationService.get();
    const bd = window.location.pathname.match(/^\/bd\//) ? true : false;

    if (!response.error && !response.errors) {
      let _notifications = response._embedded
        .filter(n => n.type.match(/task|call|note|todo/i))
        .map(n => {
          let target;
          let bdOnly = false;
          let mainOnly = false;
          switch (n.target) {
            case "Candidate":
              mainOnly = true;
              target = "candidate";
              break;
            case "Position":
              target = "position";
              break;
            case "Company":
              if (bd) {
                bdOnly = true;
                target = "company";
              } else {
                mainOnly = true;
                target = "client";
              }
              break;
            case "Contact":
              bdOnly = true;
              target = "contact";
              break;
            case "Event":
              bdOnly = true;
              target = "event";
              break;
            case "Todo":
              bdOnly = true;
              target = "dashboard";
              break;
          }
          n.url =
            ((bd || bdOnly) && !mainOnly ? "/bd" : "") +
            "/" +
            target +
            (n.id ? "/" + n.id : "");
          return n;
        });
      setNotifications(_notifications);
    }
  };

  useEffect(() => {
    if (jwtInfo) {
      getNotifications();
      //check for new notifications on an interval
      let intervalTime = 10; //seconds
      const notificationInterval = setInterval(() => {
        getNotifications();
      }, intervalTime * 1000);
      return () => clearInterval(notificationInterval);
    }
  }, []);

  //bizdev
  if (window.location.pathname.match(/^\/bd\//)) {
    return (
      <Styles className="bizdev" notifications={notifications.length}>
        <Container>
          <header>
            <Link className="logo" to="/bd/dashboard">
              <img
                src={imgLogoBizDev}
                title="The Mullings Group: BizDev"
                alt="The Mullings Group: BizDev"
              />
            </Link>
            <Nav section="bizdev" jwtInfo={jwtInfo} />
            <Actions section="bizdev" jwtInfo={jwtInfo} />
          </header>
        </Container>
      </Styles>
    );
  }
  //main app
  else if (jwtInfo || window.location.href.match(/\/login$/)) {
    return (
      <Styles notifications={notifications.length}>
        <Container className="full">
          <header>
            <Link className="logo" to="/">
              <img
                src={imgLogo}
                title="The Mullings Group"
                alt="The Mullings Group"
              />
            </Link>
            {isLoggedIn && <Nav section="main" jwtInfo={jwtInfo} />}
            {isLoggedIn && <Actions section="main" jwtInfo={jwtInfo} />}
          </header>
        </Container>
      </Styles>
    );
  }
  //3rd party header (interview confirm time)
  else {
    return (
      <Styles className="external-user">
        <Container className="full">
          <header>
            <span className="logo" to="/">
              <img
                src={imgLogoFull}
                title="The Mullings Group"
                alt="The Mullings Group"
              />
            </span>
          </header>
        </Container>
      </Styles>
    );
  }
}

/*
 * Nav, only shown when user is logged in
 */
const Nav = ({ section, jwtInfo }) => {
  const [menuOpen, setMenuOpen] = useState();

  let activeNav;
  if (window.location.pathname.match(/^\/candidates?/)) {
    activeNav = "candidates";
  } else if (window.location.pathname.match(/^\/clients?|company/)) {
    activeNav = "clients";
  } else if (window.location.pathname.match(/^\/positions?/)) {
    activeNav = "positions";
  } else if (window.location.pathname.match(/^\/bd\/events?/)) {
    activeNav = "events";
  } else if (window.location.pathname.match(/^\/bd\/companies|company/)) {
    activeNav = "companies";
  } else if (window.location.pathname.match(/^\/bd\/contacts?/)) {
    activeNav = "contacts";
  } else if (window.location.pathname.match(/^\/bd\/conferences?/)) {
    activeNav = "conferences";
  }

  return (
    <nav
      className={menuOpen ? "active" : ""}
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <ul>
        <>
          {section === "main" ? (
            <>
              <li className={activeNav === "clients" ? "active" : ""}>
                <Link to="/clients">Companies</Link>
              </li>
              <li className={activeNav === "candidates" ? "active" : ""}>
                <Link to="/candidates">Candidates</Link>
              </li>
              <li className={activeNav === "positions" ? "active" : ""}>
                <Link to="/positions">Job Orders</Link>
              </li>
              {jwtInfo && jwtInfo.roles.includes("BizDev") ? (
                <li>
                  <a href="/bd/dashboard">BizDev</a>
                </li>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {section === "bizdev" ? (
            <>
              <li className={activeNav === "companies" ? "active" : ""}>
                <Link to="/bd/companies">Companies</Link>
              </li>
              <li className={activeNav === "contacts" ? "active" : ""}>
                <Link to="/bd/contacts">Contacts</Link>
              </li>
              <li className={activeNav === "events" ? "active" : ""}>
                <Link to="/bd/events">
                  BD<span className="mo inline">&nbsp;Events</span>
                </Link>
              </li>
              <li className={activeNav === "conferences" ? "active" : ""}>
                <Link to="/bd/conferences">Conferences</Link>
              </li>
              <li>
                <a href="/">Main</a>
              </li>
            </>
          ) : (
            ""
          )}
        </>
      </ul>
    </nav>
  );
};
/*
 * Actions, only shown when user is logged in
 */
const Actions = ({ jwtInfo }) => {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
  const [notifications, setNotifications] = useStore("notifications");

  //console.log("notifications", notifications);

  const handleMenuOpen = () => {
    if (!openProfileMenu) {
      document.addEventListener("click", removeMenu);
      setOpenProfileMenu(true);
      setOpenNotificationsMenu(false);
    }
  };
  const removeMenu = () => {
    document.removeEventListener("click", removeMenu);
    setOpenProfileMenu(false);
  };
  const handleNotificationsMenuOpen = () => {
    if (!openNotificationsMenu) {
      document.addEventListener("click", removeNotificationsMenu);
      setOpenNotificationsMenu(true);
      setOpenProfileMenu(false);
    }
  };
  const removeNotificationsMenu = () => {
    document.removeEventListener("click", removeNotificationsMenu);
    setOpenNotificationsMenu(false);
  };

  //mark as seen on click
  const markAsSeen = notification => {
    notifications.forEach(n => {
      if (n.id === notification.id) {
        NotificationService.update({ id: n.notificationId, seen: true });
      }
    });

    //mark all associated records as seen
    setNotifications([...notifications.filter(n => n.id !== notification.id)]);
  };

  return (
    <div className="actions">
      <ul>
        <li className="search">
          <Link
            to={
              (window.location.pathname.match(/^\/bd\//) ? "/bd" : "") +
              "/search"
            }
          >
            <img src={imgSearch} title="Search" alt="Search" />
          </Link>
        </li>
        <li
          className={"notifications" + (notifications.length ? " active" : "")}
          onClick={handleNotificationsMenuOpen}
        >
          <img
            src={imgNotifications}
            title="Notifications"
            alt="Notifications"
          />
          {openNotificationsMenu && notifications.length ? (
            <ul>
              {notifications.map((n, i) => {
                return (
                  <li key={i}>
                    <Link to={n.url} onClick={() => markAsSeen(n)}>
                      {n.title} @ {DateTime.formatTime(n.date)},{" "}
                      <span className="date">
                        {DateTime.formatDate(n.date)}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </li>
        <li className="user" onClick={handleMenuOpen}>
          <img src={imgUser} title="Profile" alt="Profile" />

          {openProfileMenu && (
            <ul>
              <li>
                <b>{`Welcome ${jwtInfo.firstName}`}</b>
              </li>
              <li>
                <Link
                  to={
                    (window.location.pathname.match(/^\/bd\//) ? "/bd" : "") +
                    "/notes"
                  }
                >
                  My Notes
                </Link>
              </li>
              <li>
                <Link to="/settings">System Settings</Link>
              </li>
              <li>
                <Link to="/logout">Logout</Link>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};
