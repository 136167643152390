export const compensationBuild = () => {
  let _compensation = [];
  for (let i = 80000; i <= 500000; i += 5000) {
    _compensation.push({
      label: "$" + parseInt(i).toLocaleString(),
      value: i,
      data: "compensation",
    });
    if (i === 500000) {
      _compensation.push({
        label: "$" + parseInt(i).toLocaleString() + " + option",
        value: i + " + option",
        data: "compensation",
      });
    }
  }
  return _compensation;
};

export const urgencyOptionsBuild = [
  { label: "High", value: "high", checked: true },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
  { label: "On Hold", value: "onHold" },
];

export const teamSizesBuild = [
  { label: "0-50", value: "0-50", data: "size" },
  { label: "50-200", value: "50-200", data: "size" },
  { label: "200-500", value: "200-500", data: "size" },
  { label: "500+", value: "500+", data: "size" },
];

export const visaBuild = [
  { label: "Needs Sponsorship", value: "Needs Sponsorship", data: "visa" },
  {
    label: "Does Not Need Sponsorship",
    value: "Does Not Need Sponsorship",
    data: "visa",
  },
];

export const jobLevelBuild = [
  {
    label: "Individual Contributor",
    value: "Individual Contributor",
  },
  {
    label: "Leadership",
    value: "Leadership",
  },
  {
    label: "Executive",
    value: "Executive",
  },
];

export const functionsBuild = [
  {
    label: "Business Development",
    value: "Business Development",
  },
  {
    label: "Clinical",
    value: "Clinical",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "General Management",
    value: "General Management",
  },
  {
    label: "Manufacturing",
    value: "Manufacturing",
  },
  {
    label: "Operations",
    value: "Operations",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "R&D",
    value: "R&D",
  },
  {
    label: "Regulatory",
    value: "Regulatory",
  },
  {
    label: "Reimbursement/Market Access",
    value: "Reimbursement/Market Access",
  },
  {
    label: "Sales",
    value: "Sales",
  },
  {
    label: "Software",
    value: "Software",
  },
];

export const regionsBuild = [
  {
    label: "US – Bay Area",
    value: "US – Bay Area",
  },
  {
    label: "US – Boston",
    value: "US – Boston",
  },
  {
    label: "US – Midwest",
    value: "US – Midwest",
  },
  {
    label: "US – Twin Cities",
    value: "US – Twin Cities",
  },
  {
    label: "US – NE",
    value: "US – NE",
  },
  {
    label: "US – NW",
    value: "US – NW",
  },
  {
    label: "US – SE",
    value: "US – SE",
  },
  {
    label: "US – Remote",
    value: "US – Remote",
  },
  {
    label: "Southern California",
    value: "Southern California",
  },
  {
    label: "Outside US (OUS)",
    value: "Outside US (OUS)",
  },
];
export const lookingToHireBuild = [
  { label: "Q1", value: "Q1" },
  { label: "Q2", value: "Q2" },
  { label: "Q3", value: "Q3" },
  { label: "Q4", value: "Q4" },
];
