import Config from "../config/config";
import { filterTable } from "../helpers/Search";

const create = inputData => {
  return fetch(Config.API_URL + "positions", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const update = inputData => {
  return fetch(Config.API_URL + "positions/" + inputData.id, {
    method: "PUT",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const get = id => {
  return fetch(Config.API_URL + "positions/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const del = id => {
  return fetch(Config.API_URL + "positions/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
//stages
const stages = inputData => {
  return fetch(Config.API_URL + "positions/" + inputData.id + "/stages", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

/* NOTES */
const getNotes = inputData => {
  return fetch(
    Config.API_URL +
      "positions/" +
      inputData.id +
      "/notes?limit=" +
      inputData.limit +
      "&offset=" +
      inputData.offset,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const addNote = inputData => {
  return fetch(Config.API_URL + "positions/" + inputData.id + "/notes", {
    method: "POST",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

/*CANDIDATES*/
const candidates = inputData => {
  return fetch(
    Config.API_URL +
      "positions/" +
      inputData.id +
      "/candidates?limit=" +
      inputData.limit,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const addCandidates = inputData => {
  return fetch(Config.API_URL + "positions/" + inputData.id + "/candidates", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteCandidate = inputData => {
  return fetch(
    Config.API_URL +
      "positions/" +
      inputData.id +
      "/candidates/" +
      inputData.candidateId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
//STAGES
const addStage = inputData => {
  return fetch(Config.API_URL + "positions/" + inputData.id + "/stages", {
    method: "POST",
    body: JSON.stringify(inputData.stageIds),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteStage = inputData => {
  return fetch(
    Config.API_URL +
      "positions/" +
      inputData.id +
      "/stages/" +
      inputData.stageId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const updateCandidateStage = inputData => {
  return fetch(
    Config.API_URL +
      "positions/" +
      inputData.positionId +
      "/candidates/" +
      inputData.candidateId,
    {
      method: "PUT",
      body: JSON.stringify({ ...inputData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

/* TAGS */
const addTag = inputData => {
  return fetch(Config.API_URL + "positions/" + inputData.id + "/tags", {
    method: "POST",
    body: JSON.stringify([inputData.tagId]),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteTag = inputData => {
  console.log("inputData", inputData);
  return fetch(
    Config.API_URL +
      "positions/" +
      inputData.entityId +
      "/tags/" +
      inputData.tagId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const list = inputData => {
  console.log("inputData,", inputData);
  let search = filterTable(inputData.filter);

  return fetch(
    Config.API_URL +
      "positions?limit=" +
      (inputData.limit ? inputData.limit : 1000) +
      "&offset=" +
      (inputData.offset ? inputData.offset : 0) +
      (inputData.filter
        ? (inputData.filter.users || inputData.filter.status || search
            ? "&q="
            : "") +
          (inputData.filter.users
            ? "(" +
              (inputData.filter.users.owner
                ? 'ownerUserId:"' + inputData.filter.users.owner + '"'
                : "") +
              (inputData.filter.users.primary
                ? ' OR primaryUserId:"' + inputData.filter.users.primary + '"'
                : "") +
              (inputData.filter.users.secondary
                ? ' OR secondaryUserId:"' +
                  inputData.filter.users.secondary +
                  '"'
                : "") +
              ")"
            : "") +
          (search ? (inputData.filter.users ? " AND " : "") + search : "") +
          (inputData.filter.status
            ? (inputData.filter.users || search ? " AND " : "") +
              'status:"' +
              inputData.filter.status +
              '"'
            : "")
        : "") +
      (inputData.sortBy ? "&sort_by=" + inputData.sortBy : ""),
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
//HIRING MANAGER
const createHiringManager = inputData => {
  return fetch(Config.API_URL + "positions/" + inputData.id + "/managers", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const deleteHiringManager = inputData => {
  return fetch(
    Config.API_URL +
      "positions/" +
      inputData.id +
      "/managers/" +
      inputData.hiringManagerId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

//CHARTS
const chartOpen = inputData => {
  return fetch(
    Config.API_URL +
      "reports/positions?limit=" +
      inputData.limit +
      "&offset=" +
      (inputData.offset ? inputData.offset : 0) +
      ' &q=status:"' +
      inputData.filter.status +
      '"' +
      (inputData.filter.owner
        ? ' AND ownerUserId:"' + inputData.filter.owner + '"'
        : "") +
      (inputData.filter.kickoffStart
        ? " AND kickedoff:[" +
          inputData.filter.kickoffStart +
          " TO " +
          inputData.filter.kickoffEnd +
          "]"
        : ""),
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
export const PositionService = {
  create,
  update,
  list,
  get,
  del,
  getNotes,
  addNote,
  addCandidates,
  deleteCandidate,
  candidates,
  addTag,
  deleteTag,
  addStage,
  deleteStage,
  stages,
  updateCandidateStage,
  createHiringManager,
  deleteHiringManager,
  chartOpen,
};
