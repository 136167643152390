import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { AuthService } from "../../services/Auth";
import { StageService } from "../../services/Stage";
import { useStore } from "@kwhitley/use-store";
import { ExportPositionCandidates } from "../Shared/Export";
import device from "../../config/device";
import { DateTime } from "../../helpers/DateTime";
import {
  formatUUID,
  formatAddress,
  formatCompensation,
} from "../../helpers/Display";
import { useTable, useFilters } from "react-table";
import { useForm } from "react-hook-form";

import {
  urgencyOptionsBuild,
  jobLevelBuild,
  functionsBuild,
  regionsBuild,
  lookingToHireBuild,
} from "../Shared/Data";

import { Tags } from "../Shared/Tags";
import { Activities } from "../Shared/DetailActivities";
import { UpdatableEntity } from "../Shared/UpdatableEntity";
import { DocumentUpload } from "../Shared/DocumentUpload";
import { DragNDrop } from "../Shared/DragNDrop";
import { HiringManagers } from "../Shared/HiringManager";
import { ProfileImage } from "../Shared/ProfileImage";
import { PositionService } from "../../services/Position";
import { UserService } from "../../services/User";
import { UpdateableInfo } from "../Shared/UpdateableInfo";
import { Colors } from "../../styles/Colors";

import {
  Container,
  Button,
  Card,
  ListDl,
  TableEmpty,
  Table,
  ButtonClose,
} from "../../styles/Common";
import { Form } from "../../components/Shared/Form";
import { Pagination } from "../../components/Shared/Pagination";
import styled from "styled-components";
import imgChevron from "../../assets/chevron.svg";
import imgLogoWhite from "../../assets/logo-white.svg";
import imgMail from "../../assets/mail.svg";
import imgSend from "../../assets/send.svg";
import imgCopy from "../../assets/copy.svg";
import imgViewBoard from "../../assets/board.svg";
import imgViewTable from "../../assets/table.svg";
import imgUserPlus from "../../assets/user-plus.svg";
import imgX from "../../assets/x.svg";
import { toast } from "react-toastify";
import { addToPageHistory } from "../../helpers/Search";
import ChartsWrapper from "../Charts/ChartsWrapper";

const Styled = styled.div`
  display: flex;
  &:before {
    content: "";
    display: block;
    height: 256px;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background-image: linear-gradient(0deg, #5f6a7b 0%, #515b69 100%);
    box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
    background: ${Colors.primary};
  }
  @media ${device.laptopLMax} {
    &:before {
      height: auto;
    }
  }

  .main {
    .section {
      margin-top: 15px;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 33% 1fr;
      grid-gap: 15px;
      @media ${device.tabletLMax} {
        display: block;
      }
    }
    .col-1 {
      display: grid;
      grid-gap: 15px;
      align-content: baseline;
    }
    .col-2 {
      grid-area: 1 / 2 / 2 / 3;
      display: grid;
      grid-gap: 15px;
      align-content: baseline;
    }
  }
`;

const Header = styled.header`
  color: ${Colors.gray};
  display: grid;
  height: 240px;
  margin-bottom: 36px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-areas:
    "top top top"
    "img name name"
    "img company company"
    "img address address"
    "img meta resources"
    "onhold meta resources";

  @media ${device.laptopLMax} {
    height: auto;
    background: ${Colors.primary};
    margin: 0 -1rem;
    padding: 0 1rem 30px 1rem;
  }
  @media ${device.tabletSMax} {
    grid-template-areas:
      "top top top"
      "img name name"
      "img company company"
      "resources address address"
      "resources meta meta"
      "onhold meta meta";
  }

  .back {
    grid-area: top;
    font-weight: 300;
    font-size: 1.143rem;
    height: 51px;
    padding: 11px 0 0;
    a {
      color: ${Colors.gray};
      vertical-align: middle;
    }
    a:before {
      content: "";
      vertical-align: middle;
      width: 14px;
      height: 17px;
      margin-right: 5px;
      vertical-align: middle;
      display: inline-block;
      background: url(${imgChevron}) no-repeat;
      background-size: cover;
      transform: rotate(90deg);
    }
  }

  .position {
    grid-area: name;
    height: 42px;
    @media ${device.tabletLMax} {
      height: auto;
    }
  }
  .company {
    grid-area: company;
    height: 38px;
    align-self: end;
    font-size: 1.143em;
    max-width: 500px;
    line-height: 36px;
    a {
      color: ${Colors.gray};
    }
  }
  .position-id {
    display: inline-block;
    height: 20px;
    padding-top: 13px;
    vertical-align: middle;
    span {
      font-size: 0.8571rem;
      display: block;
      height: 100%;
      box-sizing: border-box;
      padding: 2px 15px 0 15px;
      border-radius: 10px;
    }
  }
  .meta {
    grid-area: meta;
    dl {
      display: flex;
    }
    dl div {
      margin-right: 45px;
    }
    dt {
      font-weight: 600;
      font-size: 0.857rem;
      display: block;
      margin-bottom: 4px;
    }
    dd {
      font-weight: 700;
      font-size: 1.286rem;
      display: block;
    }

    .time-since {
      font-weight: 400;
      margin-left: 10px;
    }
    a {
      color: ${Colors.gray};
    }

    @media ${device.tabletSMax} {
      dl {
        display: block;
        columns: 1;
        div {
          break-inside: avoid;
          page-break-inside: avoid;
          margin-top: 10px;
        }
      }
    }
  }
  h1 {
    font-weight: 300;
    font-size: 2.857rem;
    margin-top: 12px;
    margin-bottom: 12px;
    vertical-align: middle;
    margin-right: 15px;
    display: inline-block;
  }
  @media ${device.tabletLMax} {
    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  h2 {
    grid-area: position;
    font-weight: 500;
    font-size: 1.143rem;
    margin-top: 17px;
    span {
      font-weight: 300;
    }
  }
  .address {
    grid-area: address;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.286rem;
    a {
      color: ${Colors.gray};
    }
    p {
      margin-top: 12px;
      display: inline-block;
      margin-right: 30px;
    }
    time {
      font-weight: 400;
      color: #a4acb9;
    }
  }
  .on-hold {
    grid-area: onhold;
    text-transform: uppercase;
    color: #ffffff;
    background: ${Colors.highlight};
    border-radius: 3px;
    padding: 5px 10px;
    display: inline-block;
    text-align: center;
    justify-self: start;
    align-self: baseline;
  }

  #resources {
    grid-area: resources;
    text-align: right;
    color: #a4acb9;
    align-self: end;
    font-weight: 600;
    text-align: center;
    ul {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 100;
      ul {
        display: block;
        position: absolute;
        bottom: -75px;
        right: -15px;
        border-radius: 3px;
        background: #fff;
        padding: 0 15px;
        box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);
        border: 1px solid ${Colors.gray_accent};
      }
    }
    li {
      position: relative;
      margin-right: 30px;
      &:last-child {
        margin-right: 15px;
      }
      li {
        display: block;
        text-align: right;
        border-bottom: 1px solid ${Colors.gray_accent};
        margin: 0 !important;
        padding: 5px 0 7px 0;
        &:last-child {
          border-bottom: 0;
        }
        a,
        span {
          white-space: nowrap;
          display: flex;
          flex-direction: row;
          height: auto;
          font-weight: normal;
          cursor: pointer;
          align-items: center;
          justify-content: flex-end;
          color: ${Colors.primary};
          &:after {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            background: no-repeat;
            background-size: contain;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        &.send {
          a:after {
            background-image: url(${imgSend});
          }
        }
        &.copy {
          span:after {
            background-image: url(${imgCopy});
          }
        }
      }
    }
    li {
      a:before,
      span:before {
        content: "";
        display: block;
        background: no-repeat;
        background-size: contain;
        margin: 0 auto 5px;
      }
      & > a,
      & > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: #a4acb9;
        height: 44px;
      }
      &.email {
        span {
          cursor: default;
        }
        & > a:before,
        & > span:before {
          width: 20px;
          height: 16px;
          background-image: url(${imgMail});
        }
        > span:after {
          content: "";
          width: 8px;
          height: 9px;
          position: absolute;
          bottom: 7px;
          right: -13px;
          background: url(${imgChevron}) no-repeat;
          background-size: cover;
        }
      }
      &.search-candidates {
        a:before {
          width: 20px;
          height: 19px;
          background-image: url(${imgUserPlus});
        }
      }
      &.brief {
        span {
          cursor: default;
        }
        & > a:before,
        & > span:before {
          width: 20px;
          height: 16px;
          background-image: url(${imgLogoWhite});
        }
        > span:after {
          content: "";
          width: 8px;
          height: 9px;
          position: absolute;
          bottom: 7px;
          right: -13px;
          background: url(${imgChevron}) no-repeat;
          background-size: cover;
        }
      }
    }
  }
`;

const StyledCandidateSummary = styled(Card)``;
const StyledStages = styled(Card)`
  .stage-options {
    ul {
      columns: 3;
      @media ${device.tabletSMax} {
        columns: 2;
      }
      @media ${device.mobileLMax} {
        columns: 1;
      }
    }
    li {
      padding: 5px 0;
    }
  }
`;
const StyledHiringManagers = styled(Card)`
  .list {
    padding-bottom: 30px;
    border-bottom: 1px solid ${Colors.gray};
    column-count: 3;
    li {
      display: flex;
      align-items: flex-end;
      break-inside: avoid;
    }
  }
`;

const CandidateDashboard = styled(Card)`
  margin-top: 15px;
  padding-bottom: 15px;
  .view {
    font-weight: 600;
    ul {
      display: grid;
      grid-template-columns: auto auto auto 1fr;
      align-items: center;
      margin-bottom: 10px;
    }
    li {
      &.icon {
        margin: 0 0 0 15px;
        overflow: hidden;
        width: 20px;
        height: 20px;
        text-indent: -6000px;
        background-size: cover;
        &.disabled {
          visibility: hidden;
        }
      }
      &.view-columns {
        background-image: url(${imgViewBoard});
      }
      &.view-table {
        background-image: url(${imgViewTable});
      }
      &.active {
        background-position: 0 -60px;
      }
      &.candidate-view {
        margin: 0 0 0 60px;
      }
      &.export {
        text-align: right;
      }
    }
  }
  & > .header {
    display: flex;
    background: #8792a3;
    color: #fff;
    align-items: center;
    height: 40px;
    padding: 0 0 0 15px;
    h2 {
      margin-right: 30px;
      a {
        color: #fff;
      }
    }

    dl {
      display: flex;
      dt {
        margin: 0 5px 0 30px;
        &:after {
          content: ":";
          display: inline;
        }
      }
      dd {
        margin: 0 30px 0 0;
      }
    }
  }
  .columns {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    .column {
      position: relative;
      margin: 0 13.5px;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      min-height: 164px;

      .header {
        display: flex;
        h2 {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 0.857rem;
          .count {
            margin-left: 5px;
          }
        }
        .contract {
          border: 1px solid ${Colors.primary};
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 21px;
          height: 14px;
          :before {
            content: "";
            display: block;
            width: 7px;
            height: 4.8px;
            transform: rotate(-90deg);
            background: url(${imgChevron});
            background-size: cover;
            background-position: 0 0;
            margin: 1px 2.5px 0 3.5px;
          }
        }
      }
      .board {
        margin-top: 11px;
        padding: 5px;
        background: ${Colors.gray_accent_opaque};
        border-radius: 3px;
        height: calc(100% - 26px);
      }

      li {
        .name {
          font-weight: 600;
          color: ${Colors.primary};
          margin-bottom: 5px;
          white-space: nowrap;
        }
        background: #fff;
        padding: 10px;
        color: #8792a3;
        margin-bottom: 5px;
        height: 89px;
        box-sizing: border-box;
      }

      &.collapsed {
        width: 58px;
        flex-grow: 0;
        h2 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%) rotate(90deg);
        }
        .contract {
          margin: 0 29px 0 21px;
          &:before {
            transform: rotate(90deg);
          }
        }
        ul {
          display: none;
        }
      }
    }
  }
  @media ${device.tabletLMax} {
    display: none;
  }

  #possible-candidates {
    h2 {
      font-size: 12px;
      font-weight: 600;
      margin: 15px 0 15px 15px;
    }
    .pagination {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .col-1 {
        justify-content: start;
      }
      .col-2 {
        justify-content: flex-end;
      }
    }
  }
`;

export default function PositionDetail(props) {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);
  const jwtInfo = AuthService.jwtInfo();

  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
  } = useForm();

  //get position
  const [position, setPosition] = useState(null);

  // position ********************************

  const preparePosition = async () => {
    let _position = await PositionService.get(props.match.params.id);

    if (_position.errors || _position.error) {
      props.history.push("/positions");
    } else {
      _position.id = props.match.params.id;
      _position.tags = _position.tagsArray;

      //compute time since kickoff
      let _timeSinceKickOff = DateTime.timeSince(
        new Date(_position.kickoff),
        new Date()
      );
      _position.timeSinceKickOff =
        _timeSinceKickOff > 0 ? _timeSinceKickOff : 0;

      //format display dates
      _position.startDateFormatted = _position.kickoff
        ? DateTime.formatDate(new Date(_position.kickoff))
        : "";
      //format rekickoff
      _position.rekickoffFormatted = _position.rekickoff
        ? DateTime.formatDate(new Date(_position.rekickoff))
        : "";
      //format holdDate
      _position.holdDateFormatted = _position.holdDate
        ? DateTime.formatDate(new Date(_position.holdDate))
        : "";

      //ownerInfo
      _position.ownerInfoFormatted = _position.ownerInfo
        ? _position.ownerInfo.lastName + ", " + _position.ownerInfo.firstName
        : "";
      //primaryInfo
      _position.primaryInfoFormatted = _position.primaryInfo
        ? _position.primaryInfo.lastName +
          ", " +
          _position.primaryInfo.firstName
        : "";
      //secondaryInfo
      _position.secondaryInfoFormatted = _position.secondaryInfo
        ? _position.secondaryInfo.lastName +
          ", " +
          _position.secondaryInfo.firstName
        : "";

      //address... turn it flat
      if (_position.address) {
        Object.entries(_position.address).forEach(obj => {
          _position[obj[0]] = obj[1];
        });
      }
      //tags may be undefined from api
      _position.positionTags = _position.positionTags
        ? _position.positionTags
        : [];

      //need to reverse manualInterview (indicates wrong)
      _position.manualInterviewDisplay = _position.manualInterview
        ? "No"
        : "Yes";

      //save into search history
      addToPageHistory({
        title: _position.title,
        info:
          formatUUID(_position.id) + " for <b>" + _position.company + "</b>",
        url: "/position/" + _position.id,
      });
      setPosition(_position);
      getPositionStages(_position.id);
      getPositionCandidates(_position.id);
    }
  };

  const [stages, setStages] = useState([]);
  const getPositionStages = async id => {
    let response = await PositionService.stages({ id: id });

    if (response._embedded) {
      let _stages = response._embedded.filter(
        s => s.name !== "Possible" && s.name !== "Short List"
      );
      setStages(_stages);
    }
  };
  const [candidates, setCandidates] = useState([]);
  const getPositionCandidates = async id => {
    let response = await PositionService.candidates({ id: id, limit: 200 });

    if (response._embedded) {
      let _candidates = response._embedded.map(c => {
        c.id = c.candidateId;
        c.name = c.firstName + " " + c.lastName;
        c.compensation = c.compensation
          ? formatCompensation(c.compensation, "short")
          : "";
        c.daysInStage = DateTime.timeSince(new Date(c.updatedAt), new Date());
        return c;
      });
      setCandidates(_candidates);
    }
  };

  const numQualifiedCandidates = candidates.filter(
    c => c.stage !== "Possible" && c.stage !== "Short List"
  ).length;

  const [candidateView, setCandidateView] = useState("qualified");

  useEffect(() => {
    setCandidateView(numQualifiedCandidates ? "qualified" : "possible");
  }, [numQualifiedCandidates]);

  useEffect(() => {
    preparePosition();
  }, []);

  //email resource click
  const [openEmailOptions, setOpenEmailOptions] = useState(false);
  const handleEmailOpen = () => {
    if (!openEmailOptions) {
      document.addEventListener("click", removeEmailOpen);
      setOpenEmailOptions(true);
    }
  };
  const removeEmailOpen = () => {
    document.removeEventListener("click", removeEmailOpen);
    setOpenEmailOptions(false);
  };

  //position brief click
  const [openBriefOptions, setOpenBriefOptions] = useState(false);
  const handleBriefOpen = () => {
    if (!openBriefOptions) {
      document.addEventListener("click", removeBriefOpen);
      setOpenBriefOptions(true);
    }
  };
  const removeBriefOpen = () => {
    document.removeEventListener("click", removeBriefOpen);
    setOpenBriefOptions(false);
  };

  //Placement History
  const [placementHistory, setPlacementHistory] = useState(false);

  //candidate display (qualified || possible)

  //dashboard view
  const [dashboardView, setDashboardView] = useState("columns");
  const [collapseRejections, setCollapseRejections] = useState(false);

  //delete hiring manager
  const deleteHiringManager = async hiringManagerId => {
    let response = await PositionService.deleteHiringManager({
      id: position.id,
      hiringManagerId: hiringManagerId,
    });
    if (!response.error) {
      setPosition({
        ...position,
        hiringManagers: position.hiringManagers.filter(
          hm => hm.hiringManagerId !== hiringManagerId
        ),
      });
    }
  };
  return (
    <Styled>
      {position && (
        <Container>
          <section>
            <Header>
              <div className="position">
                <h1>{position.title}</h1>
                <p className="position-id">
                  <span>{formatUUID(position.id, "position")}</span>
                </p>
              </div>
              {position.company && (
                <p className="company">
                  <Link to={"/company/" + position.companyId}>
                    {position.company}
                  </Link>
                </p>
              )}

              <p className="back">
                <a href="#" onClick={() => props.history.goBack()}>
                  Back
                </a>
              </p>

              <ProfileImage
                detail={{
                  type: "positions",
                  uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                }}
                entity={position}
                setEntity={setPosition}
              />
              {position.status && position.status === "On Hold" ? (
                <div className="on-hold">On Hold</div>
              ) : (
                ""
              )}

              {formatAddress(
                position.city,
                position.state,
                null, //position does not return country
                false, //TODO: display time based on geo data from DB
                true //link to maps
              )}

              <address id="resources">
                <ul>
                  {position.brief ? (
                    <li className="brief" onClick={() => handleBriefOpen()}>
                      <span>Position Brief</span>
                      {openBriefOptions && (
                        <ul>
                          <li className="open">
                            <a
                              target="_mullings"
                              href={
                                position.brief.includes("http")
                                  ? position.brief
                                  : `//${position.brief}`
                              }
                            >
                              Open Brief
                            </a>
                          </li>
                          <li className="send">
                            <a
                              target="_mullings"
                              href={`mailto:?Subject=Position%20Brief?&Body=Position%20Brief:%20${position.brief}`}
                            >
                              Share via email
                            </a>
                          </li>
                          <li
                            className="copy"
                            onClick={() => {
                              navigator.clipboard.writeText(position.brief);
                              toast.success("Position brief address copied");
                            }}
                          >
                            <span id="email-address">Copy link</span>
                          </li>
                        </ul>
                      )}
                    </li>
                  ) : null}
                  <li className="search-candidates">
                    <Link
                      to={"/search/filter:candidates/positionId:" + position.id}
                    >
                      Search Candidates
                    </Link>
                  </li>

                  {/*candidates && candidates.length ? (
                    <li className="email">
                      <Link to={"/position/email/" + position.id}>
                        Send Email
                      </Link>
                    </li>
                  ) : (
                    ""
                  )*/}
                </ul>
              </address>

              <div className="meta">
                <dl>
                  {position.urgency && (
                    <div>
                      <dt>Urgency</dt>
                      <dd>{position.urgency}</dd>
                    </div>
                  )}
                  {position.stage && (
                    <div>
                      <dt>Stage</dt>
                      <dd>{position.stage}</dd>
                    </div>
                  )}
                  {position.startDate && (
                    <div>
                      <dt>Kick Off</dt>
                      <dd>
                        {position.startDateFormatted}

                        <span className="time-since">
                          {position.timeSinceKickOff > 0
                            ? position.timeSinceKickOff + " Days"
                            : ""}
                        </span>
                      </dd>
                    </div>
                  )}
                  {position.hiringManagers && position.hiringManagers.length ? (
                    <div>
                      <dt>
                        Hiring Manager
                        {position.hiringManagers.length > 1 ? "s" : ""}
                      </dt>
                      <dd>
                        {position.hiringManagers.map((hm, i) => {
                          return (
                            <span key={i}>
                              <Link to={"/candidate/" + hm.candidateId}>
                                {hm.firstName} {hm.lastName}
                              </Link>
                              {i < position.hiringManagers.length - 1
                                ? ", "
                                : ""}
                            </span>
                          );
                        })}
                      </dd>
                    </div>
                  ) : (
                    ""
                  )}
                </dl>
              </div>
            </Header>

            <div className="main">
              {/* Charts Begin */}

              {/* only show if there is data */}
              {position && position.history ? (
                <ChartsWrapper chartData={position.history} />
              ) : null}

              {/* Charts End */}
              {candidates && candidates.length ? (
                <CandidateDashboard>
                  <h1>Candidates</h1>

                  <div className="view">
                    <ul>
                      <li
                        className={
                          "icon view-columns" +
                          (dashboardView === "columns" ? " active" : "") +
                          (candidateView === "possible" ? " disabled" : "")
                        }
                        onClick={() => setDashboardView("columns")}
                      >
                        Columns
                      </li>
                      <li
                        className={
                          "icon view-table" +
                          (dashboardView === "table" ? " active" : "") +
                          (candidateView === "possible" ? " disabled" : "")
                        }
                        onClick={() => setDashboardView("table")}
                      >
                        Table
                      </li>
                      <li className="filter candidate-view">
                        {candidateView === "qualified" && (
                          <Button onClick={() => setCandidateView("possible")}>
                            View Short List
                          </Button>
                        )}
                        {candidateView === "possible" && (
                          <Button onClick={() => setCandidateView("qualified")}>
                            View Qualified
                          </Button>
                        )}
                      </li>
                      {candidates && (
                        <li className="export">
                          <ExportPositionCandidates
                            position={position}
                            candidates={candidates.filter(
                              c => c.stage !== "Possible"
                            )}
                          />
                        </li>
                      )}
                    </ul>
                  </div>

                  {numQualifiedCandidates &&
                  candidateView === "qualified" &&
                  dashboardView === "table" ? (
                    <RenderTable
                      candidates={candidates.filter(
                        c => c.stage !== "Possible" && c.stage !== "Short List"
                      )}
                    />
                  ) : (
                    ""
                  )}

                  {numQualifiedCandidates &&
                  position.stages &&
                  candidateView === "qualified" &&
                  dashboardView === "columns" ? (
                    <DragNDrop
                      candidates={candidates.filter(
                        c => c.stage !== "Possible" && c.stage !== "Short List"
                      )}
                      stages={position.stages.filter(
                        s => s.name !== "Short List"
                      )}
                      positionId={position.id}
                      position={position}
                    />
                  ) : (
                    <>
                      {!numQualifiedCandidates &&
                      candidateView === "qualified" ? (
                        <div className="content">
                          No qualified candidates found. To add qualified
                          candidates, view short listed candidates and select as
                          qualified.
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {candidateView === "possible" && (
                    <PossibleCandidates
                      positionId={position.id}
                      candidates={candidates}
                      setCandidates={setCandidates}
                      setCandidateView={setCandidateView}
                      history={props.history}
                    />
                  )}
                </CandidateDashboard>
              ) : (
                ""
              )}

              <div className="section">
                <div className="col-1">
                  <CandidateSummary position={position} className="card" />

                  <Info position={position} setPosition={setPosition} />

                  <Tags
                    entity={position}
                    setEntity={setPosition}
                    Service={PositionService}
                    detail={{
                      type: "positionTag",
                      typePlural: "positionTags",
                      typeDisplay: "positionTags",
                      title: "Tags",
                    }}
                  />

                  <UpdatableEntity
                    entity={position}
                    setEntity={setPosition}
                    Service={PositionService}
                    detail={{
                      title: "Description",
                      key: "description",
                      expandable: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <Activities
                    detail={{ type: "Position" }}
                    entity={position}
                    Service={PositionService}
                  />

                  <DocumentUpload
                    detail={{
                      type: "positions",
                      uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                    }}
                    entity={position}
                    setEntity={setPosition}
                    Service={PositionService}
                  />
                  <StyledHiringManagers>
                    <h1>Hiring Managers</h1>
                    <div className="content">
                      {position.hiringManagers ? (
                        <ul className="list">
                          {position.hiringManagers &&
                            position.hiringManagers.map((hm, i) => {
                              return (
                                <li key={i}>
                                  <Link to={"/candidate/" + hm.candidateId}>
                                    {hm.firstName} {hm.lastName}
                                  </Link>
                                  <ButtonClose
                                    type="button"
                                    onClick={() =>
                                      deleteHiringManager(hm.hiringManagerId)
                                    }
                                  >
                                    <img
                                      src={imgX}
                                      alt="Delete"
                                      title="Delete"
                                    />
                                  </ButtonClose>
                                </li>
                              );
                            })}
                        </ul>
                      ) : (
                        <p>No hiring managers assigned</p>
                      )}

                      <HiringManagers
                        position={position}
                        setPosition={setPosition}
                      />
                    </div>
                  </StyledHiringManagers>

                  <Stages position={position} setPosition={setPosition} />
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}
    </Styled>
  );
}

const CandidateSummary = ({ position: position }) => {
  const [accumulatedStages, setAccumulatedStages] = useState();
  useEffect(() => {
    if (position.history) {
      prepareCandidateSummary();
    }
  }, []);
  const prepareCandidateSummary = () => {
    //accumulatedStages
    let _accumulatedStages = Object.keys(position.history.series).reduce(
      (stages, stageName) => {
        stages[stageName] = position.history.series[stageName].data.reduce(
          (total, count) => total + (count || 0),
          0
        );
        return stages;
      },
      {}
    );
    setAccumulatedStages(_accumulatedStages);
  };

  return (
    <>
      {accumulatedStages ? (
        <StyledCandidateSummary>
          <h1>Candidate Summary</h1>
          <div className="content">
            <ListDl>
              {Object.entries(accumulatedStages).map((stage, i) => {
                return (
                  <div key={i}>
                    <dt>{stage[0]}</dt>
                    <dd>{stage[1]}</dd>
                  </div>
                );
              })}
            </ListDl>
          </div>
        </StyledCandidateSummary>
      ) : (
        ""
      )}
    </>
  );
};

const Stages = ({ position, setPosition }) => {
  //Get those Stages
  const [initStageOptions, setInitStageOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const prepareStages = _stages => {
    return _stages.map(s => {
      let checked = false;
      let readOnly = false;
      if (
        s.name.match(/Possible|Short List|Presented|Rejected|Passed|^Offer$/)
      ) {
        checked = true;
        readOnly = true;
      }
      //already in position
      else if (
        position.stages &&
        position.stages.find(t => t.name === s.name)
      ) {
        checked = true;
      }
      return {
        label: s.name,
        value: s.stageId,
        checked: checked,
        readOnly: readOnly,
      };
    });
  };
  //get Stages
  const getStages = async () => {
    let _stages = await StageService.list({ limit: 100, stage: "" });

    if (_stages._embedded) {
      setInitStageOptions(prepareStages(_stages._embedded));
      setStageOptions(prepareStages(_stages._embedded));
    } else {
      toast.error("There was an error retrieving stages");
    }
  };
  useEffect(() => {
    getStages();
  }, []);

  const onChange = async e => {
    let stage = stageOptions.find(s => s.value === parseInt(e.target.value));
    let response;
    if (!stage.checked) {
      response = await PositionService.addStage({
        id: position.id,
        stageIds: [stage.value],
      });
      if (!response.error) {
        setPosition({
          ...position,
          stages: [
            ...position.stages,
            { name: stage.label, stageId: stage.value },
          ],
        });
        toast.success("Stage has been added", { position: "bottom-center" });
      } else {
        toast.error("There was an error adding stage", {
          position: "bottom-center",
        });
      }
    } else {
      response = await PositionService.deleteStage({
        id: position.id,
        stageId: stage.value,
      });
      if (!response.error) {
        position.stages = position.stages.filter(s => s.name !== stage.label);
        setPosition({ ...position });
        toast.success("Stage has been deleted", { position: "bottom-center" });
      } else {
        toast.error("There was an error deleting stage", {
          position: "bottom-center",
        });
      }
    }
  };

  return (
    <StyledStages>
      <h1>Stages</h1>
      {stageOptions.length ? (
        <div className="content stage-options">
          <Form.Checkboxes
            name="stages"
            type="text"
            options={stageOptions}
            setOptions={setStageOptions}
            onChange={onChange}
          />
        </div>
      ) : (
        ""
      )}
    </StyledStages>
  );
};

const PossibleCandidates = ({
  candidates,
  setCandidates,
  positionId,
  setCandidateView,
  history,
}) => {
  const checkedPossibleHandle = "checkedPossibleHandle"; //used for id of handle
  const [candidateIds, setCandidateIds] = useState([]);

  let _data = [];
  let _candidateIds = [];

  useEffect(() => {
    candidates
      .filter(c => c.stage === "Possible" || c.stage === "Short List")
      .forEach(i => {
        _candidateIds.push({
          name: "",
          id: i.id,
          checked: false,
          value: i.id,
          label: "",
        });
      });
    //setData(_data);
    setCandidateIds(_candidateIds);
  }, [candidates]);

  const [checkedHandle, setCheckedHandle] = useState({
    name: "",
    id: checkedPossibleHandle,
    checked: false,
    value: "",
    indeterminate: false,
  });

  // Use the state and functions returned from useTable to build your UI

  const onCheck = id => {
    let _checks = candidateIds.filter(c => c.checked);
    let _row = candidateIds.filter(c => c.value === id)[0];

    //will always be a step ahead/behind
    let checkedLength = _checks.length + (!_row.checked ? 1 : -1);

    //set parent checkbox handle
    if (checkedLength === candidateIds.length) {
      checkedHandle.checked = true;
      checkedHandle.indeterminate = false;
      setCheckedHandle({ ...checkedHandle });
      //document.getElementById(checkedPossibleHandle);
    } else if (checkedLength) {
      //any length, make it indeterminate
      checkedHandle.checked = false;
      checkedHandle.indeterminate = true;
      setCheckedHandle({ ...checkedHandle });
    } else {
      checkedHandle.checked = false;
      checkedHandle.indeterminate = false;
      setCheckedHandle({
        ...checkedHandle,
      });
    }
  };
  const onCheckAll = () => {
    let checked = !checkedHandle.checked;
    checkedHandle.indeterminate = false;
    setCheckedHandle({
      ...checkedHandle,
      checked: checked,
    });
    setCandidateIds([
      ...candidateIds.map(c => {
        c.checked = checked;
        return c;
      }),
    ]);
  };
  const moveToQualified = async () => {
    //TODO: api should have a way to update multiple candidate stages at a time

    let updatedIds = candidateIds
      .filter(c => c.checked)
      .map(c => {
        PositionService.updateCandidateStage({
          positionId: positionId,
          candidateId: c.id,
          stage: "Presented",
        });
        return c.id;
      });

    //update stages for candidates being updated
    setCandidates([
      ...candidates.map(pc => {
        if (updatedIds.find(u => u.id === pc.id)) {
          pc.stage = "Presented";
        }
        return pc;
      }),
    ]);

    setCandidates([
      ...candidates.map(pc => {
        if (updatedIds.find(id => id === pc.id)) {
          pc.stage = "Presented";
        }
        return pc;
      }),
    ]);

    //reset handle
    setCheckedHandle({
      name: "",
      id: checkedPossibleHandle,
      checked: false,
      value: "",
      indeterminate: false,
    });
    setCandidateView("qualified");
  };

  const removeFromShortList = async id => {
    //let response = {error: false};
    let response = await PositionService.deleteCandidate({
      id: positionId,
      candidateId: id,
    });

    if (!response.error) {
      setCandidateIds([...candidateIds.filter(c => c.id !== id)]);
      setCandidates([...candidates.filter(c => c.candidateId !== id)]);
    }
  };

  return (
    <div id="possible-candidates">
      <h2>Short List ({candidates.length})</h2>
      {candidates.filter(
        c => c.stage === "Possible" || c.stage === "Short List"
      ).length ? (
        <Table className="table">
          <div className="outer-wrap">
            <table>
              <thead>
                <tr>
                  <th>
                    <Form.Checkbox
                      onChange={() => {
                        onCheckAll();
                      }}
                      option={checkedHandle}
                      options={[checkedHandle]}
                    />
                  </th>
                  <th>Candidate</th>
                  <th>Visa</th>
                  <th>Compensation</th>
                </tr>
              </thead>
              <tbody>
                {candidates
                  .filter(
                    c => c.stage === "Possible" || c.stage === "Short List"
                  )
                  .map((candidate, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Form.Checkbox
                            onChange={() => onCheck(candidate.id)}
                            option={candidateIds.find(
                              c => c.value === candidate.id
                            )}
                            options={candidateIds}
                            setOptions={setCandidateIds}
                          />
                        </td>
                        <td>
                          <div
                            className="candidate"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              maxWidth: 325,
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Link to={"/candidate/" + candidate.id}>
                              {candidate.firstName} {candidate.lastName}
                            </Link>
                            <Button
                              onClick={() => removeFromShortList(candidate.id)}
                              primary
                              size="small"
                            >
                              Remove
                            </Button>
                          </div>
                        </td>
                        <td>{candidate.visa}</td>
                        <td>{candidate.compensation}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <Pagination.Wrapper>
            {checkedHandle.checked || checkedHandle.indeterminate ? (
              <div className="col col-1">
                <Button size="small" primary onClick={() => moveToQualified()}>
                  Move selected to qualified
                </Button>
              </div>
            ) : (
              ""
            )}
          </Pagination.Wrapper>
        </Table>
      ) : (
        <TableEmpty>
          <p>
            There were no short-listed candidates found.{" "}
            <Link
              style={{ fontWeight: "600" }}
              to={"/search/filter:candidates/positionId:" + positionId}
            >
              Search now
            </Link>
          </p>
        </TableEmpty>
      )}
    </div>
  );
};

const RenderTable = ({ candidates }) => {
  //table columns
  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Positions",
        accessor: "position",
      },
      {
        Header: "Visa",
        accessor: "visa",
      },
      {
        Header: "Compensation",
        accessor: "compensation",
      },
      {
        Header: "Stage",
        accessor: "stage",
      },
      {
        Header: "Date",
        accessor: "date",
      },
    ],
    []
  );

  const data = [];
  candidates &&
    candidates.forEach(i => {
      data.push({
        id: i.id,
        position: i.name,
        visa: i.visa,
        compensation: i.compensation,
        stage: i.stage,
        date: DateTime.formatDate(new Date(i.updatedAt)),
      });
    });

  // Use the state and functions returned from useTable to build your UI
  const {
    headerGroups,
    getRowProps,
    getHeaderProps,
    rows,
    prepareRow,
    state: {},
    setFilter,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, []),
      initialState: { hiddenColumns: "id" },
    },
    useFilters
  );

  //Filter from search
  const [filterInput, setFilterInput] = useState("");
  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("name", value);
    setFilterInput(value);
  };

  return (
    <Container>
      {candidates.length ? (
        <Table className="table">
          <table>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr key={Math.random()}>
                  {headerGroup.headers.map(column => (
                    // eslint-disable-next-line react/jsx-key
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {rows.map(row => {
                prepareRow(row);
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Table>
      ) : (
        <TableEmpty>
          <p>There were no positions found</p>
        </TableEmpty>
      )}
    </Container>
  );
};

const Info = ({ position, setPosition }) => {
  //react-hook-form
  const { register, getValues } = useForm();

  //get users
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    let response = await UserService.list({ limit: 1000 });

    if (response._embedded) {
      setUsers(
        response._embedded.map(o => {
          return {
            label: o.lastName + ", " + o.firstName,
            value: o.id,
          };
        })
      );
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  //position info
  const info = [
    {
      label: "Position",
      value: "title",
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "Urgency",
      value: "urgency",
      edit: false,
      editable: true,
      options: urgencyOptionsBuild,
      saving: false,
      type: "select",
    },
    {
      label: "Kick Off",
      value: "startDateFormatted",
      edit: false,
      editable: true,
      type: "date",
      saving: false,
    },
    {
      label: "Days Since Kick Off",
      value: "timeSinceKickOff",
      editable: false,
      saving: false,
    },
    {
      label: "Looking to Hire",
      value: "deadline",
      edit: false,
      editable: true,
      type: "select",
      options: lookingToHireBuild,
      saving: false,
    },
    {
      label: "Auto-Scheduling",
      value: "manualInterviewDisplay",
      edit: false,
      editable: true,
      saving: false,
      type: "select",
      options: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
    },
    {
      label: "Status",
      value: "status",
      edit: false,
      editable: true,
      saving: false,
      type: "select",
      options: [
        { label: "Open", value: "Open" },
        { label: "On Hold", value: "On Hold" },
        { label: "Closed", value: "Closed" },
      ],
    },
    {
      label: "Hold Date",
      value: "holdDateFormatted",
      edit: false,
      editable: true,
      saving: false,
      type: "date",
    },
    {
      label: "Re-Kick Off",
      value: "rekickoffFormatted",
      edit: false,
      type: "date",
      editable: true,
      saving: false,
    },
    {
      label: "Owner",
      value: "ownerInfoFormatted",
      type: "select",
      options: users,
      edit: false,
      editable: true,
      saving: false,
      data: users,
    },
    {
      label: "Primary",
      value: "primaryInfoFormatted",
      type: "select",
      options: users,
      edit: false,
      editable: true,
      saving: false,
      data: users,
    },
    {
      label: "Secondary",
      value: "secondaryInfoFormatted",
      type: "select",
      options: users,
      edit: false,
      editable: true,
      saving: false,
      data: users,
    },
    {
      label: "Job Level",
      value: "jobLevel",
      type: "select",
      options: jobLevelBuild,
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "Function",
      value: "functionality",
      type: "select",
      options: functionsBuild,
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "Position Brief",
      value: "brief",
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "City",
      value: "city",
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "State",
      value: "state",
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "Zip",
      value: "zip",
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "Country",
      value: "country",
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "Region",
      value: "region",
      type: "select",
      options: regionsBuild,
      edit: false,
      editable: true,
      saving: false,
    },
    {
      label: "Remote",
      value: "remoteOk",
      edit: false,
      editable: true,
      saving: false,
      type: "select",
      options: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
    },
  ];
  const [infoOptions, setInfoOptions] = useState();

  return (
    <>
      {users.length ? (
        <UpdateableInfo
          info={info}
          detail={{ title: "Info", type: "position" }}
          entity={position}
          setEntity={setPosition}
          Service={PositionService}
        />
      ) : (
        ""
      )}
    </>
  );
};
