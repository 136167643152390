import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Pagination as StylesPagination } from "../../styles/Pagination";

/**
 * Wrapper
 */
const Wrapper = props => {
  return (
    <StylesPagination.Wrapper className="pagination">
      {props.children}
    </StylesPagination.Wrapper>
  );
};
Wrapper.propTypes = {
  children: PropTypes.any,
};

/**
 * Next / Previous Buttons
 */
const Direction = ({ verbiage, direction, gotoPage, canPage }) => {
  return (
    <StylesPagination.Direction
      direction={direction}
      onClick={() => gotoPage()}
      disabled={!canPage}
    >
      {verbiage ? verbiage : ""}
    </StylesPagination.Direction>
  );
};
Direction.propTypes = {
  verbiage: PropTypes.string,
  direction: PropTypes.string,
  gotoPage: PropTypes.func,
  canPage: PropTypes.bool,
};

/**
 * Rows Per Page
 * user selection
 */
const RowsPerPage = ({ setPageSize, pageSize, verbiage, items }) => {
  //console.log(props);
  const [showOptions, setShowOptions] = useState(false);

  const handleFilterAdd = () => {
    if (!showOptions) {
      document.addEventListener("click", removeFilter);
      setShowOptions(true);
    }
  };
  const removeFilter = () => {
    document.removeEventListener("click", removeFilter);
    setShowOptions(false);
  };

  return (
    <StylesPagination.RowsPerPage>
      {verbiage ? <p className="verbiage">{verbiage}</p> : ""}
      <div
        className={"filter " + (showOptions ? "show" : "")}
        onClick={handleFilterAdd}
      >
        <ol>
          <li key={pageSize}>{pageSize}</li>
          {items.map(i => {
            return (
              <React.Fragment key={i}>
                {pageSize !== i && (
                  <li
                    onClick={
                      pageSize !== i
                        ? () => {
                            setPageSize(i);
                            localStorage.setItem("globalPageSize", i);
                          }
                        : false
                    }
                  >
                    {i}
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ol>
      </div>
    </StylesPagination.RowsPerPage>
  );
};
RowsPerPage.propTypes = {
  setPageSize: PropTypes.func,
  pageSize: PropTypes.number,
  verbiage: PropTypes.string,
  items: PropTypes.array,
};

const Paging = ({ pageCount, pageIndex, gotoPage, pagesShownLimit }) => {
  let showSkipToLastPage = false;
  let showSkipToFirstPage = false;
  let currentPage = pageIndex + 1;

  let pages = [];

  //the before limit will be half of overall page limit
  let pagesShownPrevLimit = Math.floor(pagesShownLimit / 2);

  //show skip to first page
  if (currentPage - pagesShownPrevLimit > 2 && pageCount > pagesShownLimit) {
    showSkipToFirstPage = true;
  }

  //pages to display prev
  for (
    let i = currentPage - 1;
    i > 0;
    i--, pagesShownPrevLimit--, pagesShownLimit--
  ) {
    //break if half prev has reached limit and not close enough to pageCount
    if (pagesShownPrevLimit <= 0 && pageCount - i > pagesShownLimit) {
      break;
    }
    pages.push(i);
  }
  //add in first page if needed (set before reversing array)
  if (pages.length && !showSkipToFirstPage && !pages.includes(1)) {
    pages.push(1);
  }
  pages.reverse();
  //pages to display prev
  for (let i = currentPage; i < pageCount; i++, pagesShownLimit--) {
    if (pagesShownLimit <= 0) {
      break;
    }
    pages.push(i);
  }
  //show skip to last page
  if (!pages.includes(pageCount) && !pages.includes(pageCount - 1)) {
    showSkipToLastPage = true;
  }
  //add in last page if needed
  if (!showSkipToLastPage && !pages.includes(pageCount)) {
    pages.push(pageCount);
  }

  return (
    <StylesPagination.Paging>
      {showSkipToFirstPage && (
        <li className="skip-to-page first" onClick={() => gotoPage(0)}>
          <span>1</span>
        </li>
      )}
      {pages.map((v, k) => {
        return (
          <li key={k} className={pageIndex === v - 1 ? "active" : ""}>
            {pageIndex === v - 1 ? (
              v
            ) : (
              <span onClick={() => gotoPage(v - 1)}>{v}</span>
            )}
          </li>
        );
      })}
      {showSkipToLastPage && (
        <li className="skip-to-page last">
          <span onClick={() => gotoPage(pageCount - 1)}>{pageCount}</span>
        </li>
      )}
    </StylesPagination.Paging>
  );
};
Paging.propTypes = {
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  gotoPage: PropTypes.func,
  pagesShownLimit: PropTypes.number,
};

export const Pagination = {
  Wrapper,
  Direction,
  RowsPerPage,
  Paging,
};
