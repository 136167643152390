import styled from "styled-components";
import { Colors } from "./Colors";

export const LoadingSubmit = styled.div`
  display: inline-block;
  width: ${props => (props.size ? props.size + "px" : "30px")};
  height: ${props => (props.size ? props.size + "px" : "30px")};
  padding-bottom: ${props => (props.size ? props.size + "px" : "15px")};
  vertical-align: middle;

  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: ${props => (props.size ? props.size / 5 + "px" : "6px")} solid;
    margin-top: ${props => (props.size ? props.size / 7.2 + "px" : "4px")};
    border-color: ${props => (props.color ? props.color : Colors.primary)}
      transparent;
    animation: loadingSubmit 0.9s linear infinite;
    box-sizing: border-box;
  }
  @keyframes loadingSubmit {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
