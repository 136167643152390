const size = {
  mobileS: 320, //usage format: @media ${device.mobileSMin} {}
  mobileM: 375,
  mobileL: 430,
  tabletS: 700,
  tabletM: 768,
  tabletL: 860,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const device = Object.keys(size).reduce((acc, cur) => {
  acc[cur + "Min"] = `(min-width: ${size[cur]}px)`;
  acc[cur + "Max"] = `(max-width: ${size[cur]}px)`;
  return acc;
}, {});

export default device;
