import React, { useState, useEffect } from "react";
import { TagService } from "../../services/Tag";
import { useForm } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { Button, Card, TagSelector, TagsWrapper } from "../../styles/Common";

const StyledTags = styled(Card)`
  position: relative;
`;

export const Tags = ({ entity, setEntity, Service, detail }) => {
  const { register } = useForm();
  //tag editor
  const [openTagEditor, setOpenTagEditor] = useState(false);

  const [availableTags, setAvailableTags] = useState([]);

  const [selectedTags, setSelectedTags] = useState(null);

  const getAvailableTags = async entityTags => {
    if (entity && entityTags) {
      //setCandidateTags(candidate.tags);
      let _availableTags = await TagService.get({
        type: detail.type === "specialty" ? "submarket" : detail.type,
      });

      if (_availableTags._embedded) {
        if (
          (detail.type === "market" ||
            detail.type === "submarket" ||
            detail.type === "technology" ||
            detail.type === "specialty") &&
          entityTags.length >= 3
        ) {
          setAvailableTags([]);
        } else {
          setAvailableTags(
            _availableTags._embedded
              .filter(t => {
                //remove tags already selected for candidate
                let notFound = true;
                entityTags.forEach(ct => {
                  if (ct.tagId === t.tagId) {
                    notFound = false;
                  }
                });
                return notFound;
              })
              .map(t => {
                return { label: t.tag, value: t.tagId };
              })
          );
        }
      }
    }
  };
  useEffect(() => {
    getAvailableTags(entity[detail.typePlural]);
  }, []);

  const onTagChange = (tag, actionMeta) => {
    //setEntityTags([...entityTags, opt[0].value]);
    setEntity({
      ...entity,
      [detail.typePlural]: [
        ...entity[detail.typePlural],
        {
          tagId: tag[0].value,
          [getDetailType(detail.type)]: tag[0].label,
        },
      ],
      [detail.typeDisplay]: [
        ...entity[detail.typeDisplay],
        {
          tagId: tag[0].value,
          [getDetailType(detail.type)]: tag[0].label,
        },
      ],
    });
    setSelectedTags(false);
    //save to database
    Service.addTag({
      id: entity.id,
      tagId: tag[0].value,
      typePlural:
        detail.typePlural === "submarkets" ? "specialties" : detail.typePlural,
    });

    //make sure 3's the limit
    if (
      (detail.type === "market" ||
        detail.type === "submarket" ||
        detail.type === "technology" ||
        detail.type === "specialty") &&
      entity[detail.typePlural].length >= 2
    ) {
      setAvailableTags([]);
      //also remove Add Tag
    } else {
      setAvailableTags(availableTags.filter(t => t.value !== tag[0].value));
    }
  };
  const deleteTag = tag => {
    //delete from database
    Service.deleteTag({
      ...tag,
      entityId: entity.id,
      typePlural:
        detail.typePlural === "submarkets" ? "specialties" : detail.typePlural,
    });

    setEntity({
      ...entity,
      [detail.typeDisplay]: entity[detail.typeDisplay].filter(t => t !== tag),
      [detail.typePlural]: entity[detail.typePlural].filter(t => t !== tag),
    });

    //if options were removed (due to being at max, repopulate)
    if (!availableTags.length) {
      getAvailableTags(entity[detail.typePlural].filter(t => t !== tag));
    } else {
      setAvailableTags([
        ...availableTags,
        {
          label: tag[getDetailType(detail.type)],
          value: tag.tagId,
        },
      ]);
    }
  };

  /**
   * workaround for inconsistent detail type returns from api
   */
  const getDetailType = type => {
    switch (type) {
      case "submarket":
        return "specialty";
      case "skill":
      case "positionTag":
        return "tag";
      default:
        return type;
    }
  };

  return (
    <StyledTags>
      <h1>{detail.title}</h1>
      <div className="content">
        {entity[detail.typePlural] && entity[detail.typePlural].length ? (
          <TagsWrapper>
            {entity[detail.typePlural].map((tag, i) => (
              <li key={i}>
                <span className="label">{tag[getDetailType(detail.type)]}</span>
                <span className="remove" onClick={() => deleteTag(tag)}></span>
              </li>
            ))}
          </TagsWrapper>
        ) : (
          "No tags have been added"
        )}
        <TagSelector>
          {openTagEditor && availableTags.length ? (
            <Select
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              ref={register({ name: "tags" })}
              isMulti
              isClearable={false}
              className="multi-select inline"
              classNamePrefix="tags"
              onChange={onTagChange}
              options={availableTags}
              placeholder=""
              value={selectedTags}
            />
          ) : (
            ""
          )}
          {availableTags.length ? (
            <Button
              size="small"
              style={{
                marginTop: "13px",
                marginRight: "0",
              }}
              onClick={() => setOpenTagEditor(!openTagEditor)}
            >
              Add Tags
            </Button>
          ) : (
            ""
          )}
        </TagSelector>
      </div>
    </StyledTags>
  );
};
