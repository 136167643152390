import React, { useState, useEffect } from "react";
import Select from "react-select";

// import LineChart from './LineChart';
import AreaChart from "./AreaChart";
import BarChart from "./BarChart";

let ChartsWrapper = props => {
  const cd = props.chartData;
  const cdataLabels = Object.keys(cd.series); // parse out labels dynamically
  const cdataXAxisLabels = cd.columns.map(item => {
    return item.label;
  });
  let cdataStructured = []; // default chart structure is an array of objects

  /* each position can have different stages, so stages must be dynamically created for all the charts based on labels from api */
  let axisLabelsLength = cdataXAxisLabels.length;

  for (let i = 0; i < axisLabelsLength; i++) {
    let chartDataObj = {};
    for (const k of cdataLabels) {
      chartDataObj["name"] = cdataXAxisLabels[i];
      if (Object.prototype.hasOwnProperty.call(cd.series, k)) {
        chartDataObj[k] = cd.series[k].data[i]; // keys must match labels in each chart in order to render: change the label in the chart files and you must update this
      }
    }
    cdataStructured.push(chartDataObj);
  }

  let colorArray = [
    // color array is to dynamically set the colors for each chart
    "#2e0e80",
    "#6b0082",
    "#99007d",
    "#c00074",
    "#e00067",
    "#fa1858",
    "#ff4946",
    "#ff7031",
    "#ff9512",
    "#ffba00",
    "#ffdd00",
    "#fdff00",
  ];

  // chart resizing: this listens for window resizing to change charts on demand
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }
  const { width } = useWindowDimensions(); // width can only be changed by resizing window
  // end of window resizing code

  const chartSelectorOptions = [
    // chart options for the users
    { value: "bar", label: "Bar Chart" },
    // {value: 'line', label: 'Line Chart'},
    { value: "area", label: "Area Chart" },
  ];
  const [selectedChart, setSelectedChart] = useState();
  //chart selection
  let selectChart = val => {
    switch (val.value) {
      // case "line":
      //   setSelectedChart( <LineChart data={cdataStructured} chartConfigs={chartConfigs} chartColors={chartColors} renderLegend={renderLegend} /> );
      //   break;
      case "area":
        setSelectedChart(
          <AreaChart
            data={cdataStructured}
            chartConfigs={chartConfigs}
            chartColors={chartColors}
            renderLegend={renderLegend}
            colorArray={colorArray}
          />
        );
        break;
      default:
        setSelectedChart(null);
    }
  };

  const renderLegend = () => {
    // to override the legend text you must pass in a jsx function
    return (
      <ul style={{ marginLeft: "52" }}>
        <li style={{ fontWeight: "bold", fontSize: "20px" }} key={`item-`}>
          Candidate Count
        </li>
      </ul>
    );
  };

  // basic style configurations to pass to each chart
  const chartConfigs = { margin: { top: 5, right: 5, bottom: 5, left: 0 } };
  const chartColors = { primaryColor: "#5F6A7B" }; // this is the primary color from the designs, we only render one color right now

  console.log("width", width);
  return (
    <div>
      {/* Default to bar chart */}
      {selectedChart ? (
        selectedChart
      ) : (
        <BarChart
          data={cdataStructured}
          chartConfigs={chartConfigs}
          chartColors={chartColors}
          renderLegend={renderLegend}
          colorArray={colorArray}
        />
      )}

      {/* selector for changing charts */}
      {cdataLabels && cdataLabels.length > 1 ? (
        <div style={{ marginLeft: "52", width: "200px" }}>
          <Select
            options={chartSelectorOptions}
            onChange={selectChart}
            className="select"
            defaultValue={"Select Chart"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ChartsWrapper;
