/*
 * file use is temporary until database is updated to use same tag structure as other tags
 */
import React, { useState, useEffect } from "react";
import { TagService } from "../../services/Tag";
import { ActivityTypeService } from "../../services/ActivityType";
import { useForm } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { Button, Card, TagSelector, TagsWrapper } from "../../styles/Common";

const StyledTags = styled(Card)`
  position: relative;
`;

export const TagsActivityTypes = ({ entity, setEntity, Service, detail }) => {
  const { register } = useForm();
  //tag editor
  const [openTagEditor, setOpenTagEditor] = useState(false);

  const [availableTags, setAvailableTags] = useState([]);

  const [selectedTags, setSelectedTags] = useState(null);

  const getAvailableTags = async entityTags => {
    let _availableTags = await ActivityTypeService.list({});
    //if (entity && entityTags) {

    console.log("_availableTags", _availableTags);
    if (_availableTags._embedded) {
      setAvailableTags(
        _availableTags._embedded
          .filter(t => {
            //remove tags already selected for candidate
            let notFound = true;
            Array.isArray(entityTags) &&
              entityTags.forEach(ct => {
                if (ct.value === t.activityType) {
                  notFound = false;
                }
              });
            return notFound;
          })
          .map(t => {
            return { label: t.activityType, value: t.activityType };
          })
      );
    }
    //}
  };
  useEffect(() => {
    //entityTags
    getAvailableTags(entity[detail.typePlural]);
  }, []);

  const onTagChange = async (tag, actionMeta) => {
    //setEntityTags([...entityTags, opt[0].value]);

    let newActivityTypes;

    if (!entity.activityId) {
      newActivityTypes = [tag[0]];
      //fill in defaults if doesn't exist
      const data = {
        org: "Dragonfly Stories",
        urgency: "medium",
        activityType: newActivityTypes.map(t => t.value).join("\t"),
      };
      let _actresp = await Service.create({
        ...data,
        eventId: entity.bdEventId,
      });

      if (_actresp.id) {
        entity = {
          ...entity,
          activityId: _actresp.id,
          org: data.org,
          urgency: data.urgency,
        };
      }
    } else {
      newActivityTypes = [...entity.activityTypes, tag[0]];
      //save to database
      Service.update({
        id: entity.activityId,
        data: {
          activityType: newActivityTypes.map(t => t.value).join("\t"),
        },
      });
    }
    setEntity({
      ...entity,
      activityTypes: newActivityTypes,
    });

    console.log("new tag", tag);
    console.log("entity2", entity);
    setSelectedTags(false);

    setAvailableTags(availableTags.filter(t => t.value !== tag[0].value));
  };
  const deleteTag = tag => {
    console.log("delete tag", tag);
    let newActivityTypes = [
      ...entity.activityTypes.filter(at => at.value !== tag.value),
    ];
    //save to database
    Service.update({
      id: entity.activityId,
      data: {
        activityType: newActivityTypes.map(t => t.value).join("\t"),
      },
    });

    setEntity({
      ...entity,
      activityTypes: newActivityTypes,
    });

    //if options were removed (due to being at max, repopulate)
    if (!availableTags.length) {
      getAvailableTags(entity[detail.typePlural].filter(t => t !== tag));
    } else {
      setAvailableTags([...availableTags, tag]);
    }
  };

  return (
    <div style={{ zIndex: 20 }}>
      <StyledTags>
        <h1>{detail.title}</h1>
        <div className="content">
          {entity[detail.typePlural] && entity[detail.typePlural].length ? (
            <TagsWrapper>
              {entity[detail.typePlural].map((tag, i) => (
                <li key={i}>
                  <span className="label">{tag.label}</span>
                  <span
                    className="remove"
                    onClick={() => deleteTag(tag)}
                  ></span>
                </li>
              ))}
            </TagsWrapper>
          ) : (
            "No tags have been added"
          )}
          <TagSelector>
            {openTagEditor && availableTags.length ? (
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                ref={register({ name: "tags" })}
                isMulti
                isClearable={false}
                className="multi-select inline"
                classNamePrefix="tags"
                onChange={onTagChange}
                options={availableTags}
                placeholder=""
                value={selectedTags}
              />
            ) : (
              ""
            )}
            {availableTags.length ? (
              <Button
                size="small"
                style={{
                  marginTop: "13px",
                  marginRight: "0",
                }}
                onClick={() => setOpenTagEditor(!openTagEditor)}
              >
                Add Tags
              </Button>
            ) : (
              <p>
                Activities and org have never been associated with this event
              </p>
            )}
          </TagSelector>
        </div>
      </StyledTags>
    </div>
  );
};
