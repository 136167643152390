import { del, get, list, update_PUT } from "./lib";

export const NoteService = {
  del: noteId => del("/notes/:noteId", { replacements: { noteId } }),
  list: query => list("/notes/", { query }),
  update: inputData =>
    update_PUT(
      "/notes/:noteId",
      { ...inputData, id: undefined },
      {
        replacements: { noteId: inputData.id },
      }
    ),

  // SPEECH TO TEXT
  speech2Text: () => get("/notes/speechcreds"),
};
