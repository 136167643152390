import jwt_decode from "jwt-decode";
import Config from "../config/config";

const authorize = inputData => {
  console.log("data", inputData);
  return fetch(Config.API_URL + "users/login", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      //if token returned, go ahead and set it
      console.log("resp", responseJson);
      if (responseJson.token) {
        localStorage.setItem("token", responseJson.token);
      }
      return responseJson;
    })
    .catch(error => {
      console.log("error: ", error);
      return error;
    });
};
const getNewPasswordToken = userId => {
  return fetch(Config.API_URL + "user/" + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      console.log("error: ", error);
      return error;
    });
};
const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  return token ? true : false;
};

const verifyLogin = () => {
  let info = jwtInfo();
  //token not expired?
  if (info && info.expire > Date.now() / 1000) {
    return true;
  }
  return false;
};

const jwtInfo = () => {
  let token = localStorage.getItem("token");
  if (token) {
    return jwt_decode(token);
  }

  return null;
};

const logout = () => {
  localStorage.clear();
};

export const AuthService = {
  authorize,
  isLoggedIn,
  logout,
  jwtInfo,
  verifyLogin,
  getNewPasswordToken,
};
