import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthService } from "../services/Auth";
import { useStore } from "@kwhitley/use-store";

import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Shared/Header";

export default function App() {
  return (
    <Router>
      <div id="canvas">
        <Header />
        <Routes />
        <ToastContainer progressClassName="toast-progress-bar" />
      </div>
    </Router>
  );
}
