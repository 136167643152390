import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { useStore } from "@kwhitley/use-store";
import { AuthService } from "../../../services/Auth";
import { useForm } from "react-hook-form";
import { ContactService } from "../../../services/Contact";
import { CompanyService } from "../../../services/Company";

import styled from "styled-components";
import Select from "react-select";
import { Form } from "../../Shared/Form";
import { Card } from "../../Shared/Common";
import { Validate } from "../../Shared/Validate";
import { Form as StyledForm } from "../../../styles/Form";
import {
  Container,
  Button,
  PageTitle,
  LinkClose,
  ButtonClose,
} from "../../../styles/Common";
import { LoadingSubmit } from "../../../styles/Animation";
import { startCase as _startCase } from "lodash";

import { toast } from "react-toastify";

import imgCircleX from "../../../assets/circle-x.svg";

export default function ContactNew(props) {
  //authenticate
  const [isLoggedIn, setIsLoggedIn] = useStore(
    "isLoggedIn",
    AuthService.verifyLogin()
  );
  const jwtInfo = AuthService.jwtInfo();
  console.log("jwt", jwtInfo);

  //react-hook-form
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
    reset,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);
  const [companies, setCompanies] = useState([]);
  const initSelectValues = {
    compensation: [],
    "currentEmployments[companyName]": [],
    "currentEmployments[title]": [],
    tags: [],
    visa: [],
  };
  const [selectValues, setSelectValues] = useState(initSelectValues);

  const onSelectChange = (opt, actionMeta) => {
    let name = actionMeta.name;
    let value;
    console.log("opt", opt);
    console.log("actionMeta", actionMeta);

    //dropdown
    if (!opt) {
      //empty data
      value = null;
    } else if (opt && opt.value) {
      value = opt.value;
    } else {
      //multiselect
      value = opt;
    }
    selectValues[name] = opt;
    setValue(name, value); //for react-hook
    setSelectValues({ ...selectValues });
    //reset any errors
    setErrorMessage("");
  };

  useEffect(() => {
    const getCompanies = async () => {
      let _companies = await CompanyService.list({
        limit: 10000,
        sortBy: "name.asc",
      });
      console.log("_companies", _companies);
      if (_companies && _companies._embedded) {
        setCompanies(
          _companies._embedded.map(c => {
            return {
              value: c.companyId,
              label: c.name,
              data: "currentEmployments[companyName]",
            };
          })
        );
      }
    };
    getCompanies();
  }, []);

  const onChange = e => {
    console.log("data test", getValues());

    //reset any form error messages
    setErrorMessage("");

    //remove anything over 4 digits
    if (e.target.name.match(/yearEntered|endYear|workExperience/)) {
      console.log(e.target.name);
      e.target.value = e.target.value.replace(/(.{4}).*/, "$1");
    }
    if (e.target.name === "phone") {
      e.target.value = Validate.Phone(e.target.value);
    }
    if (e.target.name === "zip") {
      e.target.value = Validate.Zip(e.target.value);
    }
    if (e.target.name === "country") {
      e.target.value = e.target.value.replace(/(.{3}).*/, "$1");
    }
  };

  //Submit Form
  const onSubmit = async data => {
    //email validation
    if (!Validate.Email(data.email)) {
      setErrorMessage("Please enter a valid personal email address");
      return;
    }
    if (!Validate.Email(data.emailWork)) {
      setErrorMessage("Please enter a valid work email address");
      return;
    }
    //linkedin validate
    if (data.linkedin && !Validate.LinkedIn(data.linkedin)) {
      setErrorMessage("Please enter a linkedin URL in the correct format");
      return;
    }

    //make sure address is not sending empty values if set
    if (!data.address.city || !data.address.state) {
      data.address = undefined;
    }

    //no title
    if (!data.title.trim()) {
      data.title = undefined;
    }
    //no company
    if (!data.companyId) {
      data.companyId = undefined;
    }

    console.log("submit after", data);

    //SUBMIT FORM
    setFormSubmission(true);

    let response = await ContactService.create(data);

    console.log("response", response);
    setFormSubmission(false);
    if (response.error || response.errors) {
      setErrorMessage(
        response.error ? response.error : response.errors.message
      );
    } else if (response.id) {
      //success
      //clear form
      reset();
      setSelectValues(initSelectValues);

      //toast notificiation
      toast.success(
        "Contact (" +
          data.firstName +
          " " +
          data.lastName +
          ") has been created",
        {
          position: "bottom-center",
          closeOnClick: false,
          onClick: () => props.history.push("/bd/contact/" + response.id),
        }
      );
    }
  };

  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }
  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Container className="form">
      <PageTitle>Create Contact</PageTitle>

      <LinkClose to="/bd/contacts">
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>
      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <ol>
              <Form.Fieldset legend="Contact Information" className="rows">
                <Form.InputWrapper
                  label="First Name"
                  name="firstName"
                  errors={errors}
                  required={true}
                >
                  <Form.TextInput
                    name="firstName"
                    type="text"
                    value={""}
                    forwardRef={register({ required: true })}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="Last Name"
                  name="lastName"
                  errors={errors}
                  required={true}
                >
                  <Form.TextInput
                    name="lastName"
                    type="text"
                    value={""}
                    forwardRef={register({ required: true })}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="Personal Email"
                  name="personalEmail"
                  errors={errors}
                >
                  <Form.TextInput
                    name="email"
                    type="email"
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper label="Phone" name="phone" errors={errors}>
                  <Form.TextInput
                    name="phone"
                    type="text"
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper label="Title" name="title" errors={errors}>
                  <Form.TextInput
                    name="title"
                    type="text"
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="Company"
                  name="companyId"
                  errors={errors}
                  className="react-select position-title"
                >
                  <Select
                    ref={register({
                      name: "companyId",
                    })}
                    value={selectValues["companyId"]}
                    onChange={onSelectChange}
                    name="companyId"
                    isClearable={true}
                    className="select"
                    classNamePrefix="position-title"
                    options={companies}
                    placeholder=""
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="LinkedIn"
                  name="linkedin"
                  errors={errors}
                >
                  <Form.TextInput
                    name="linkedin"
                    type="text"
                    value={""}
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
              </Form.Fieldset>
              <Form.Fieldset className="rows stacked">
                <Form.InputWrapper
                  label="City"
                  name="address['city']"
                  errors={errors}
                >
                  <Form.TextInput
                    name="address['city']"
                    forwardRef={register}
                    onChange={onChange}
                    value={""}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="State"
                  name="address['state']"
                  errors={errors}
                >
                  <Form.TextInput
                    name="address['state']"
                    forwardRef={register}
                    onChange={onChange}
                    value={""}
                  />
                </Form.InputWrapper>

                <Form.InputWrapper
                  label="Zip"
                  name="address['zip']"
                  errors={errors}
                >
                  <Form.TextInput
                    name="address['zip']"
                    type="text"
                    className="x-small"
                    forwardRef={register}
                    onChange={onChange}
                    placeholder=""
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="Country"
                  name="address['country']"
                  errors={errors}
                >
                  <Form.TextInput
                    name="address['country']"
                    type="text"
                    forwardRef={register}
                    onChange={onChange}
                    placeholder="USA"
                    className="x-small"
                  />
                </Form.InputWrapper>
              </Form.Fieldset>

              <Form.FieldsetActions>
                {errorMessage && <Form.Error message={errorMessage} />}
                {!formSubmission ? (
                  <>
                    <Button primary>Save Contact</Button>
                    <Button type="button">
                      <Link to="/bd/contacts">Cancel</Link>
                    </Button>
                  </>
                ) : (
                  <LoadingSubmit></LoadingSubmit>
                )}
              </Form.FieldsetActions>
            </ol>
          </>
        </form>
      </StyledForm>
    </Container>
  );
}
