import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { DateTime } from "../../helpers/DateTime";
import { CompanyService } from "../../services/Company";
import { Card, ListDl } from "../../styles/Common";
import { Form } from "./Form";
import { Validate } from "../Shared/Validate";
import { Colors } from "../../styles/Colors";
import { toast } from "react-toastify";
import { LoadingSubmit } from "../../styles/Animation";
import { get as _get } from "lodash";
import moment from "moment";

const StyledUpdateableInfo = styled(Card)`
  position: relative;
`;

/*
 * Quick Notes
 */
export const UpdateableInfo = ({
  info,
  detail,
  entity,
  setEntity,
  Service,
}) => {
  //react-hook-form
  const { register, getValues, setValue, reset } = useForm();

  //candidate info
  const [infoOptions, setInfoOptions] = useState(info);

  useEffect(() => {
    //set focus, so onBlur will be recognized
    let option = infoOptions.filter(o => o.edit)[0];

    if (option) {
      let input = document.getElementsByName(option.value)[0];
      if (input) {
        input.focus();
      }
    }
  }, [infoOptions]);

  //show input field
  const showInput = option => {
    if (option.editable !== false) {
      option.edit = true;
      setInfoOptions([...infoOptions]);
    }
  };

  const onChange = (e, option) => {
    if (option.type === "phone") {
      e.target.value = Validate.Phone(e.target.value);
    }
    if (option.type === "year") {
      e.target.value = e.target.value.replace(/(.{4}).*/, "$1");
    }
  };
  const onSelectChange = async (opt, actionMeta, option) => {
    option.saving = true;
    await setInfoOptions([...infoOptions]);
    if (!opt) {
      opt = { value: "" };
    }
    let val = opt.value ? opt.value.toString() : ""; //string required by api
    await setValue(option.value, val);
    saveInfo(option);
  };
  const onCreateOption = (value, option) => {
    //let opt = {label: value, value: "-newCompany-", data: name};
    console.log("opt", value);
    console.log("name", option);
    console.log("info", infoOptions);

    let io = infoOptions.find(i => i.value === option.value);
    io.options.push({ value: "", label: value, data: "" });

    console.log("io", io.options);
    setInfoOptions([...infoOptions]);
    if (option.value === "parentCompany[name]") {
      setValue(option.value, "-newCompany-");
      setValue("newParentCompanyName", value);
    } else {
      setValue(option.value, value);
    }
    saveInfo(option);
  };

  const onDateChange = async (dateRaw, dateFormatted, option) => {
    console.log("dateRaw", dateRaw);
    console.log("dateFormatted", dateFormatted);
    console.log("option", option);
    await setValue(option.value, dateFormatted);
    saveInfo(option);
  };
  const onDateBlur = async (e, option) => {
    await setValue(option.value, e.target.value);
    saveInfo(option);
  };

  //cancel Select Change
  const onSelectBlur = option => {
    option.edit = false;
    setInfoOptions([...infoOptions]);
  };

  //check for ENTER
  const handleKeyDown = (e, option) => {
    if (e.key === "Enter" || e.key === "Tab") {
      saveInfo(option);
      e.preventDefault();
    }
  };
  //save to DB
  const saveInfo = async option => {
    let data = getValues();
    let error = false;
    //set back to a closed state
    console.log("start save");
    option.edit = false;
    option.saving = true;
    console.log("data", data);
    //option.value = data[option.value];
    setInfoOptions([...infoOptions]);
    console.log("end save");
    //update db
    data.id = entity.id;
    console.log("entity a", entity);

    //custom validations

    //used only for positions owner/secondary/primary
    const ownerFormatting = handle => {
      let option = infoOptions
        .find(i => i.value === handle)
        .data.find(d => d.value === data[handle]);

      if (option) {
        return option.label;
      } else {
        return undefined;
      }
    };

    if (option.type === "email") {
      if (!Validate.Email(data[option.value])) {
        error = option.label + " must be a valid address";
      }
    } else if (detail.type === "position" && data.title !== undefined) {
      if (!data.title.trim()) {
        error = "Position must include a title";
      }
    } else if (detail.type === "candidate" && data.firstName !== undefined) {
      if (!data.firstName.trim()) {
        error = "Candidate must have a first name";
      }
    } else if (detail.type === "candidate" && data.lastName !== undefined) {
      if (!data.lastName.trim()) {
        error = "Candidate must have a last name";
      }
    } else if (data.linkedinShortened !== undefined) {
      data.linkedinShortened =
        "/" +
        data.linkedinShortened
          .replace(/(.*)\/(.+?)\/?$/, "$2")
          .replace(/\//g, "");
      data.linkedinUrl = data.linkedinShortened
        ? "https://www.linkedin.com/in" + data.linkedinShortened + "/"
        : "";
      entity.linkedinUrl = data.linkedinUrl;
    } else if (data.workExperience !== undefined) {
      if (data.workExperience.length > 0 && data.workExperience.length < 4) {
        if (data.workExperience <= 50) {
          data.workExperience = parseInt(
            data.workExperience.padStart("4", "2000")
          );
        } else {
          data.workExperience = parseInt(
            data.workExperience.padStart("4", "1900")
          );
        }
        entity.workExperience = data.workExperience;
      }
      console.log("data update info", data.workExperience);
      if (!data.workExperience) {
        data.workExperience = 0;
      } else {
        data.workExperience = parseInt(data.workExperience);
      }
    } else if (data.startDateFormatted !== undefined) {
      data.kickoff = moment(data.startDateFormatted).toDate();
      let timeSinceKickOff = DateTime.timeSince(
        new Date(data.kickoff),
        new Date()
      );
      data.timeSinceKickOff = timeSinceKickOff > 0 ? timeSinceKickOff : 0;
    } else if (data.rekickoffFormatted !== undefined) {
      data.rekickoff = moment(data.rekickoffFormatted).toDate();
    } else if (data.startDateConferenceFormatted !== undefined) {
      data.startDate = moment(data.startDateConferenceFormatted).toDate();
    } else if (data.endDateConferenceFormatted !== undefined) {
      data.endDate = moment(data.endDateConferenceFormatted).toDate();
    } else if (data.deadlineFormatted !== undefined) {
      data.deadline = data.deadlineFormatted;
    } else if (data.createdAtFormatted !== undefined) {
      data.createdAt = data.createdAtFormatted;
    } else if (data.status !== undefined && data.status === "On Hold") {
      if (!entity.holdDate) {
        data.holdDate = entity.holdDate = new Date();
      }
    } else if (data.holdDateFormatted !== undefined) {
      data.holdDate = data.holdDateFormatted
        ? moment(data.holdDateFormatted).toDate()
        : new Date();
    } else if (data.compensationFormatted !== undefined) {
      data.compensation = data.compensationFormatted;
      data.compensationFormatted = data.compensation
        ? parseInt(data.compensation).toLocaleString()
        : "";
    } else if (data["currentEmployments[title]"] !== undefined) {
      data.title = data["currentEmployments[title]"];
      if (!data.title) {
        //if deleting... remove company as well
        data.companyName = data["currentEmployments[companyName]"] = "";
      } else {
        data.companyName = data["currentEmployments[companyName]"] = _get(
          entity,
          infoOptions.find(i => i.value === "currentEmployments[companyName]")
            .value
        );
      }
    } else if (data["currentEmployments[companyName]"] !== undefined) {
      data.companyName = data["currentEmployments[companyName]"];
      if (!data.companyName) {
        //if deleting... remove title as well
        data.title = data["currentEmployments[title]"] = "";
      } else {
        data.title = data["currentEmployments[title]"] = _get(
          entity,
          infoOptions.find(i => i.value === "currentEmployments[title]").value
        );
      }
    } else if (data["parentCompany[name]"] !== undefined) {
      data.parentCompany = data["parentCompany[name]"];

      //create parentCompany first if needed
      if (data.newParentCompanyName) {
        console.log("data", data);
        let responseParent = await Service.create({
          name: data.newParentCompanyName,
          parentCompany: undefined,
          newParentCompanyName: undefined,
        });

        if (!responseParent.error) {
          data.parentCompany = responseParent.id;
        }
      }

      if (data.parentCompany) {
        data["parentCompany[companyId]"] = data.parentCompany;

        //get name if uuid sent (not newly created)
        if (data.newParentCompanyName) {
          data["parentCompany[name]"] = data.newParentCompanyName;
        } else {
          data["parentCompany[name]"] = infoOptions
            .find(i => i.value === "parentCompany[name]")
            .options.find(c => c.value === data.parentCompany).label;
        }
      } else {
        //delete
        data["parentCompany"] = null;
      }
    } else if (
      data.city !== undefined ||
      data.country !== undefined ||
      data.state !== undefined ||
      data.zip !== undefined
    ) {
      //build address, state/city required by api
      let address = {
        city:
          data.city !== undefined
            ? data.city
            : entity.city !== undefined
            ? entity.city
            : "",
        state:
          data.state !== undefined
            ? data.state
            : entity.state !== undefined
            ? entity.state
            : "",
        zip:
          data.zip !== undefined
            ? data.zip
            : entity.zip !== undefined
            ? entity.zip
            : "",
        country:
          data.country !== undefined
            ? data.country
            : entity.country !== undefined
            ? entity.country
            : "",
      };

      if (detail.type === "candidate" || detail.type === "position") {
        data.address = address;
      } else if (detail.type === "company") {
        data = { ...data, ...address };
      }
    } else if (data.isClientDisplay !== undefined) {
      data.isClient = data.isClientDisplay === "Yes" ? true : false;
    } else if (data.manualInterviewDisplay !== undefined) {
      data.manualInterview =
        data.manualInterviewDisplay === "Yes" ? false : true;
    } else if (data.ownerInfoFormatted !== undefined) {
      data.owner = data.ownerInfoFormatted ? data.ownerInfoFormatted : null;
      data.ownerInfoFormatted = ownerFormatting("ownerInfoFormatted");
    } else if (data.primaryInfoFormatted !== undefined) {
      data.primary = data.primaryInfoFormatted
        ? data.primaryInfoFormatted
        : null;
      data.primaryInfoFormatted = ownerFormatting("primaryInfoFormatted");
    } else if (data.secondaryInfoFormatted !== undefined) {
      data.secondary = data.secondaryInfoFormatted
        ? data.secondaryInfoFormatted
        : null;
      data.secondaryInfoFormatted = ownerFormatting("secondaryInfoFormatted");
    }

    //validation error?
    if (error) {
      toast.error(error, {
        position: "bottom-center",
        closeOnClick: false,
      });
    } else {
      await setEntity({ ...entity, ...data }); //moved b4 response because it will be overwritten otherwise
      //values to reset so they won't be sent to api endpoint | must be called AFTER setEntity
      data.linkedinShortened = undefined;
      data.compensationFormatted = undefined;
      data.isClientDisplay = undefined;
      data.manualInterviewDisplay = undefined;
      data.startDateFormatted = undefined;
      data.timeSinceKickOff = undefined;
      data.ownerInfoFormatted = undefined;
      data.secondaryInfoFormatted = undefined;
      data.primaryInfoFormatted = undefined;
      data["parentCompany[name]"] = undefined;
      data["parentCompany[companyId]"] = undefined;
      data.holdDateFormatted = undefined;
      data.newParentCompanyName = undefined;
      data.startDateConferenceFormatted = undefined;
      data.endDateConferenceFormatted = undefined;

      let response;

      if (detail.type === "event") {
        console.log("data1", data);

        //for back compatibility for events before activity functionality was set
        if (!entity.activityId) {
          //fill in defaults if doesn't exist
          data = {
            org: data.org ?? "Dragonfly Stories",
            urgency: data.urgency ?? "medium",
            activityType: "Dragonfly",
          };
          let _actresp = await Service.create({
            ...data,
            eventId: entity.bdEventId,
          });

          if (_actresp.id) {
            data.id = _actresp.id;
          } else {
            toast.error(_actresp.error);
          }
        } else {
          data.id = entity.activityId;
        }
      }
      if (
        detail.type === "candidate" &&
        (data["currentEmployments[title]"] !== undefined ||
          data["currentEmployments[companyName]"] !== undefined)
      ) {
        //if X'd out... do removal w/ delete endpoint
        if (data.title === "" && data.companyName === "") {
          //if not set... no need to delete
          if (entity.currentEmployments) {
            option.saving = false;
            //current company data, in case we need id for deletion
            /*response = await CompanyService.deleteEmployee({
              id: entity.currentEmployments.companyId,
              candidateId: entity.id
            }); */
            //switching to
            response = await Service.deleteEmployment({
              id: entity.id,
              employmentId: entity.currentEmployments.employmentId,
            });

            setEntity({ ...entity, currentEmployments: undefined });
          }
        }
        //not even created yet?
        else if (!entity.currentEmployments) {
          //bandaid way to find id of company
          let company;
          if (data["currentEmployments[companyName]"] !== undefined) {
            company = infoOptions[0].options.find(
              o => o.value === data["currentEmployments[companyName]"]
            );
            data.title = data["currentEmployments[title]"] =
              "Individual Contributer";
          } else {
            //just pick the first one
            company = infoOptions[0].options[0];
            data.companyName = data["currentEmployments[companyName]"] =
              company.value;
          }
          //do insert
          response = await CompanyService.addEmployee({
            id: company.data,
            candidateId: entity.id,
          });

          setEntity({
            ...entity,
            currentEmployments: {
              companyName: data.companyName,
              title: data.title,
              companyId: company.data,
              employmentId: response._embedded.id,
            },
          });
          //then do update
          //WILL NOW NEED employment ID as well
          response = await Service.updateEmployment({
            id: entity.id,
            employmentId: response._embedded.id,
            data: { companyName: data.companyName, title: data.title },
          });
        }
        //employment exists already
        else {
          console.log("CE UPDATE");
          console.log("data up", data);
          let company = infoOptions[0].options.find(
            o => o.value === data["currentEmployments[companyName]"]
          );
          response = await Service.updateEmployment({
            id: entity.id,
            employmentId: entity.currentEmployments.employmentId,
            //data: { title: data.title, companyName: data.companyName },
            data: { title: data.title, companyId: company.data },
          });
          setEntity({
            ...entity,
            currentEmployments: {
              companyName: data.companyName,
              title: data.title,
              companyId: company.data,
              employmentId: entity.currentEmployments.employmentId,
            },
          });
        }
      } else if (detail.type === "company" && data.city !== undefined) {
        response = await Service.updateAddress(data);
      } else if (detail.type === "event") {
        //back compat, insert done earlier
        if (!entity.activityId) {
          entity.activityId = data.id;
          setEntity({
            ...entity,
            activityId: data.id,
            org: data.org,
            urgency: data.urgency,
            activityTypes: [
              {
                label: data.activityType,
                value: data.activityType,
              },
            ],
          });
        } else {
          response = await Service.update(data);
        }
      } else {
        response = await Service.update(data);
      }

      if (response && response._links) {
        //reset();
      }
      reset();
    }
    option.saving = false;
    setInfoOptions([...infoOptions]);
  };

  return (
    <StyledUpdateableInfo>
      <h1>{detail.title ? detail.title : "Info"}</h1>
      <div className="content">
        <ListDl>
          {infoOptions.map(option => {
            let selectedValue = _get(entity, option.value);
            return (
              <React.Fragment key={option.value}>
                {option.value !== "holdDateFormatted" ||
                (option.value === "holdDateFormatted" &&
                  entity.status === "On Hold") ? (
                  <div key={option.label}>
                    <dt>{option.label}</dt>
                    <dd className={option.type + (option.edit ? " edit" : "")}>
                      {!option.edit && (
                        <span onClick={() => showInput(option)}>
                          {selectedValue !== "" &&
                          selectedValue !== undefined &&
                          selectedValue !== 0
                            ? (option.value === "compensationFormatted"
                                ? "$"
                                : "") +
                              selectedValue +
                              (option.value === "workExperience"
                                ? " (" +
                                  (new Date().getFullYear() - selectedValue) +
                                  " Years)"
                                : "")
                            : option.editable && (
                                <>
                                  {option.value === "workExperience"
                                    ? "Not Given "
                                    : ""}
                                  <span className="edit-handle">edit</span>
                                </>
                              )}
                        </span>
                      )}
                      {option.edit && (
                        <>
                          {option.type === "select" ? (
                            <>
                              {option.admin ? (
                                <>
                                  {option.value === "parentCompany[name]" ? (
                                    <input
                                      type="hidden"
                                      ref={register({
                                        name: "newParentCompanyName",
                                        value: "",
                                      })}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <CreatableSelect
                                    ref={register({ name: option.value })}
                                    isClearable={true}
                                    autoFocus={true}
                                    className="select"
                                    classNamePrefix="position-title"
                                    options={option.options}
                                    placeholder=""
                                    value={{
                                      label: selectedValue,
                                      value: selectedValue,
                                    }}
                                    onCreateOption={value =>
                                      onCreateOption(value, option)
                                    }
                                    onChange={(opt, meta) =>
                                      onSelectChange(opt, meta, option)
                                    }
                                  />
                                </>
                              ) : (
                                <Select
                                  ref={register({ name: option.value })}
                                  isClearable={true}
                                  filterOption={(opt, filter) => {
                                    let regexp = new RegExp("^" + filter, "i");
                                    if (
                                      !filter ||
                                      (filter &&
                                        opt.value.toString().match(regexp))
                                    ) {
                                      return true;
                                    }
                                  }}
                                  autoFocus={true}
                                  className="select"
                                  classNamePrefix="position-title"
                                  options={option.options}
                                  value={{
                                    label: option.label,
                                    value: selectedValue,
                                  }}
                                  placeholder=""
                                  onChange={(opt, meta) =>
                                    onSelectChange(opt, meta, option)
                                  }
                                  onBlur={() => onSelectBlur(option)}
                                />
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {option.type === "date" ? (
                            <DateTimePicker
                              time={false}
                              name={option.value}
                              defaultValue={
                                new Date(
                                  selectedValue ? selectedValue : new Date()
                                )
                              }
                              ref={register({
                                name: option.value,
                                value: new Date(
                                  selectedValue ? selectedValue : ""
                                ),
                              })}
                              onChange={(dateRaw, dateFormatted) =>
                                onDateChange(dateRaw, dateFormatted, option)
                              }
                              onBlur={e => onDateBlur(e, option)}
                            />
                          ) : (
                            ""
                          )}

                          {!option.type ||
                          option.type.match(/email|text|year|phone/) ? (
                            <Form.TextInput
                              name={option.value}
                              type={
                                option.type === "email" ? option.type : "text"
                              }
                              forwardRef={register}
                              value={selectedValue}
                              onBlur={() => saveInfo(option)}
                              onKeyDown={e => handleKeyDown(e, option)}
                              onChange={e => onChange(e, option)}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      )}
                      {option.saving && (
                        <div className="saving">
                          <LoadingSubmit
                            size={"15"}
                            color={Colors.highlight}
                          ></LoadingSubmit>
                        </div>
                      )}
                    </dd>
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}
        </ListDl>
      </div>
    </StyledUpdateableInfo>
  );
};
