import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { AuthService } from "../../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import device from "../../../config/device";
import { camelCase as _camelCase } from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { ContactService } from "../../../services/Contact";
import { CandidateService } from "../../../services/Candidate";
import { ConferenceService } from "../../../services/Conference";
import { CompanyService } from "../../../services/Company";
import { Activities } from "../../Shared/DetailActivities";
import { DocumentUpload } from "../../Shared/DocumentUpload";
import { parseLinkedInData } from "../../../helpers/Parser";
import { Colors } from "../../../styles/Colors";
import { addToPageHistory } from "../../../helpers/Search";
import { formatAddress, formatCompensation } from "../../../helpers/Display";
import { LoadingSubmit } from "../../../styles/Animation";
import { UpdatableEntity } from "../../Shared/UpdatableEntity";
import { UpdateableInfo } from "../../Shared/UpdateableInfo";
import { CandidateCompanyPosition } from "../../Shared/CandidateCompanyPosition";
import { Form } from "../../../components/Shared/Form";
import { Form as StyledForm, Input } from "../../../styles/Form";

//import { Tags as StyledTags } from "../../styles/Form";
import {
  Container,
  Button,
  Card,
  Editable,
  MediaTable,
  ButtonLink,
} from "../../../styles/Common";
import styled from "styled-components";
import imgChevron from "../../../assets/chevron.svg";
import imgDocument from "../../../assets/document.svg";
import imgLinkedIn from "../../../assets/linkedin.svg";
import imgPhone from "../../../assets/phone.svg";
import imgMail from "../../../assets/mail.svg";
import imgSend from "../../../assets/send.svg";
import imgCopy from "../../../assets/copy.svg";
import imgCircleX from "../../../assets/circle-x.svg";
import { toast } from "react-toastify";

const Styled = styled.div`
  display: flex;
  &:before {
    content: "";
    display: block;
    height: 256px;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background-image: linear-gradient(0deg, #5f6a7b 0%, #515b69 100%);
    box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
    background: ${Colors.primary};
  }
  @media ${device.laptopLMax} {
    &:before {
      height: auto;
    }
  }

  .main {
    margin-top: 15px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 33% 1fr;
    grid-gap: 15px;
    align-items: flex-start;

    @media ${device.tabletLMax} {
      display: block;
    }
    .col-1 {
      grid-area: 1 / 1 / 2 / 2;
      display: grid;
      grid-row-gap: 15px;
      @media ${device.laptopMax} {
        margin-bottom: 15px;
      }
    }
    .col-2 {
      display: grid;
      grid-row-gap: 15px;
      grid-area: 1 / 2 / 2 / 3;
      @media ${device.laptopMax} {
        .table {
          ${MediaTable}
        }
      }
    }
  }
`;

const Header = styled.header`
  color: ${Colors.gray};
  display: grid;
  min-height: 245px;
  margin-bottom: 30px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "top top top"
    "img name recruiter"
    "img position resources"
    "img address resources";

  @media ${device.laptopLMax} {
    height: auto;
    background: ${Colors.primary};
    margin: 0 -1rem;
    padding: 0 1rem 30px 1rem;
  }

  .back {
    grid-area: top;
    font-weight: 300;
    font-size: 1.143rem;
    height: 51px;
    padding: 11px 0 0;
    a {
      color: ${Colors.gray};
      vertical-align: middle;
    }
    a:before {
      content: "";
      vertical-align: middle;
      width: 14px;
      height: 17px;
      margin-right: 5px;
      vertical-align: middle;
      display: inline-block;
      background: url(${imgChevron}) no-repeat;
      background-size: cover;
      transform: rotate(90deg);
    }
  }

  .contact {
    grid-area: name;
    height: 26px;
  }
  .status {
    display: inline-block;
    height: 20px;
    padding-top: 13px;
    vertical-align: middle;
    span {
      font-size: 0.8571rem;
      display: block;
      height: 100%;
      box-sizing: border-box;
      background-color: ${Colors.gray};
      color: ${Colors.primary};
      padding: 0 15px 0 15px;
      border-radius: 10px;
    }
  }
  h1 {
    font-weight: 300;
    font-size: 2.857rem;
    margin-top: 12px;
    margin-bottom: 12px;
    vertical-align: middle;
    margin-right: 15px;
    display: inline-block;
    white-space: nowrap;
  }

  h2 {
    grid-area: position;
    font-weight: 500;
    font-size: 1.143rem;
    margin-top: 17px;
    span {
      font-weight: 300;
    }
    a {
      color: ${Colors.gray};
    }
  }
  .address {
    grid-area: address;
    font-weight: bold;
    font-size: 1.286rem;
    p {
      margin-top: 12px;
      display: inline-block;
      margin-right: 30px;
    }
    time {
      font-weight: 400;
      color: #a4acb9;
    }
    a {
      color: ${Colors.gray};
    }
  }
  @media ${device.tabletLMax} {
    h1 {
      font-size: 2rem;
    }
    .address {
      font-size: 1rem;
    }
  }

  #resources {
    grid-area: resources;
    text-align: right;
    color: #a4acb9;
    align-self: end;
    font-weight: 600;
    text-align: center;
    ul {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 100;
      ul {
        display: block;
        position: absolute;
        bottom: -75px;
        right: -15px;
        border-radius: 3px;
        background: #fff;
        padding: 0 15px;
        box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);
        border: 1px solid ${Colors.gray_accent};
      }
    }
    li {
      position: relative;
      margin-right: 30px;
      &:last-child {
        margin-right: 15px;
      }
      li {
        display: block;
        text-align: right;
        border-bottom: 1px solid ${Colors.gray_accent};
        margin: 0 !important;
        padding: 5px 0 7px 0;
        &:last-child {
          border-bottom: 0;
        }
        a,
        span {
          display: flex;
          flex-direction: row;
          height: auto;
          font-weight: normal;
          cursor: pointer;
          align-items: center;
          justify-content: flex-end;
          color: ${Colors.primary};
          &:after {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            background: no-repeat;
            background-size: contain;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        &.send {
          a:after {
            background-image: url(${imgSend});
          }
        }
        &.copy {
          span:after {
            background-image: url(${imgCopy});
          }
        }
      }
    }
    li {
      a:before,
      span:before {
        content: "";
        display: block;
        background: no-repeat;
        background-size: contain;
        margin: 0 auto 5px;
      }
      & > a,
      & > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: #a4acb9;
        height: 44px;
      }
      &.email {
        span {
          cursor: default;
        }
        & > a:before,
        & > span:before {
          width: 20px;
          height: 16px;
          background-image: url(${imgMail});
        }
        > span:after {
          content: "";
          width: 8px;
          height: 9px;
          position: absolute;
          bottom: 7px;
          right: -13px;
          background: url(${imgChevron}) no-repeat;
          background-size: cover;
        }
      }
      &.resume {
        a:before {
          width: 16px;
          height: 20px;
          background-image: url(${imgDocument});
        }
      }
      &.linkedin {
        a:before {
          width: 20px;
          height: 19px;
          background-image: url(${imgLinkedIn});
        }
      }
      &.phone {
        a:before {
          width: 21px;
          height: 21px;
          background-image: url(${imgPhone});
        }
      }
    }
  }
  .recruiter {
    color: #a4acb9;
    grid-area: recruiter;
    text-align: right;
    align-self: end;
    .name {
      font-weight: 600;
    }
  }

  @media ${device.laptopMax} {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
      "top top"
      "img name"
      "img position"
      "img address"
      "resources resources";
    .recruiter {
      display: none;
    }
    #resources {
      margin: 20px 0 0 auto;
    }
  }
  @media ${device.tabletMMax} {
    .status {
      display: none;
    }
    .address time {
      margin-top: 5px;
      display: block;
    }
  }
`;

export default function ContactDetail(props) {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);
  const jwtInfo = AuthService.jwtInfo();
  //console.log("jwt", jwtInfo);

  console.log("props contact history", props.history);

  //get contact / possible position
  const [contact, setContact] = useState(null);

  /**
   * Get Contact
   */

  const getContact = async () => {
    let _contact = await ContactService.get(props.match.params.id);

    console.log("contact", _contact);

    if (
      _contact.error ||
      (_contact.message && _contact.message === "Failed validation")
    ) {
      props.history.push("/bd/contacts");
    } else {
      //save into search history
      addToPageHistory({
        title: _contact.firstName + " " + _contact.lastName,
        info: _contact.currentStage ? "In process" : "Available",
        url: "/bd/contact/" + _contact.id,
      });
      _contact.bdContactId = props.match.params.id;
      _contact.id = _contact.candidateId;
      _contact.emailPersonal = _contact.email; //for updating to candidate

      _contact.documents = _contact.documents || [];

      //company reformat
      if (_contact.companyId) {
        _contact.company = {
          companyId: _contact.companyId,
          name: _contact.companyName,
        };
      }
      //flatten address
      if (_contact.address) {
        _contact.city = _contact.address.city;
        _contact.state = _contact.address.state;
        _contact.country = _contact.address.country;
        _contact.zip = _contact.address.zip;
      }

      //so it wont break layout
      _contact.linkedinShortened = _contact.linkedInUrl
        ? _contact.linkedInUrl
            .replace(/https?:\/\/www\.linkedin\.com\/in/, "")
            .replace(/\/$/, "")
        : "";

      setContact(_contact);
    }
  };

  useEffect(() => {
    getContact();
  }, []);

  //email resource click
  const [openEmailOptions, setOpenEmailOptions] = useState(false);
  const handleEmailOpen = () => {
    if (!openEmailOptions) {
      document.addEventListener("click", removeEmailOpen);
      setOpenEmailOptions(true);
    }
  };
  const removeEmailOpen = () => {
    document.removeEventListener("click", removeEmailOpen);
    setOpenEmailOptions(false);
  };

  //check authentication
  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Styled>
        {contact && (
          <Container>
            <section>
              <Header>
                <div className="contact">
                  <h1>
                    {contact.firstName} {contact.lastName}
                  </h1>
                </div>

                <p className="back">
                  <a href="#" onClick={() => props.history.goBack()}>
                    Back
                  </a>
                </p>

                {contact.refferedBy && (
                  <p className="recruiter">
                    Referred by:{" "}
                    <span className="name">{contact.refferedBy}</span>
                  </p>
                )}

                {contact.city
                  ? formatAddress(
                      contact.city,
                      contact.state,
                      contact.country,
                      false, //TODO: display time based on geo data from DB
                      true //link to maps
                    )
                  : ""}

                <address id="resources">
                  <ul>
                    {(contact.linkedinUrl || contact.linkedInUrl) && (
                      <li className="linkedin">
                        <a
                          href={contact.linkedinUrl ?? contact.linkedInUrl}
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          LinkedIn
                        </a>
                      </li>
                    )}
                    {contact.phone && (
                      <li className="phone">
                        <a
                          href={"tel:" + contact.phone.replace(/[()-\s]+/g, "")}
                        >
                          {contact.phone}
                        </a>
                      </li>
                    )}
                    {contact.emailPersonal && (
                      <li className="email" onClick={() => handleEmailOpen()}>
                        <span>Email</span>
                        {openEmailOptions && (
                          <ul>
                            <li className="send">
                              <a
                                href={
                                  "mailto:" +
                                  (contact.emailPersonal
                                    ? contact.emailPersonal
                                    : contact.emailWork)
                                }
                              >
                                Send Email
                              </a>
                            </li>
                            <li
                              className="copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  contact.emailPersonal
                                );
                                toast.success("Email address copied");
                              }}
                            >
                              <span id="email-address">
                                {contact.emailPersonal}
                              </span>
                            </li>
                          </ul>
                        )}
                      </li>
                    )}
                  </ul>
                </address>
              </Header>

              <div className="main">
                <div className="col-1">
                  <Info candidate={contact} setCandidate={setContact} />
                  <Company contact={contact} setContact={setContact} />
                  {/*<CandidateCompanyPosition
                    candidate={contact}
                    setCandidate={setContact}
                  />*/}
                  <Events contact={contact} />
                </div>
                <div className="col-2">
                  <Activities
                    detail={{ type: "Contact" }}
                    entity={{ ...contact, id: contact.bdContactId }}
                    Service={ContactService}
                  />

                  <DocumentUpload
                    detail={{
                      type: "contacts",
                    }}
                    entity={contact}
                    Service={CandidateService}
                    setEntity={setContact}
                  />
                </div>
              </div>
            </section>
          </Container>
        )}
      </Styled>
    </>
  );
}

const StyledLink = styled(Link)`
  ${props => (props.hidetext ? "text-indent: -6000px;" : "")}
  display: flex;
  :after {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0 auto auto;
    content: "";
    vertical-align: middle;
    width: 16px;
    height: 10px;
    display: inline-block;
    background: url(${imgChevron}) no-repeat;
    background-size: cover;
    background-position: 0 -21px;
    transform: rotate(-90deg);
  }
`;

const StyledCompany = styled(Card)`
  position: relative;
  z-index: 1; /* not flowing over opportunities section correctly */
  .company {
    font-weight: 600;
    color: ${Colors.primary};
  }
  .website {
    color: ${Colors.primary};
  }
  .content {
    position: relative;
  }
  .view-company {
    position: absolute;
    right: 0;
    top: 0;
  }
  .companies {
    margin: 0 0 20px;
    li {
      padding: 14.5px 0;
      border-bottom: 1px solid #e2e5e9;

      a {
        font-weight: bold;
        font-size: 14px;
      }
      .address a {
        font-weight: normal;
      }
    }

    button {
      margin-top: 30px;
    }
  }
  .cancel {
    width: 32px;
    height: 32px;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
  }
  .form {
    margin: 30px 0 0;
    position: relative;
    fieldset {
      margin-top: 0;
    }
    .actions {
      margin-top: 0;
    }
  }

  .filter {
    position: relative;
    ul {
      position: absolute;
      top: 42px;
      background: #fff;
      width: 100%;
      border: 1px solid #eee;
      padding: 5px;
    }
    li {
      display: flex;
      align-items: center;
      padding: 5px;
      border: 1px solid #eee;
      &:hover {
        background: #eee;
      }
      button {
        margin-right: 20px;
      }
    }
  }
`;
const Company = ({ contact, setContact }) => {
  const [addCompany, setAddCompany] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [formSubmission, setFormSubmission] = useState(false);
  //react-hook-form
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    const getCompanies = async () => {
      let _companies = await CompanyService.list({ limit: 10000 });
      console.log("_companies", _companies);
      if (_companies && _companies._embedded) {
        setCompanies(
          _companies._embedded.map(c => {
            //add in address, as company api returns a flat format, but conference ocmpany includes address object
            c.address = {
              city: c.city,
              state: c.state,
              zip: c.zip,
              country: c.country,
            };
            return c;
          })
        );
        setFilteredCompanies(_companies._embedded);
      }
    };
    getCompanies();
  }, []);
  const onChange = e => {};
  //Submit Form
  const onSubmit = async data => {
    console.log("data", data);

    setFormSubmission(true);

    let responseCompany = await CompanyService.create(data);

    //company created
    if (!responseCompany.error) {
      saveCompany({
        companyId: responseCompany.id,
        id: responseCompany.id,
        name: data.name,
        address: {
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: data.country,
        },
      });
      setNewCompany(false); //hide new company form
      // let response = await ContactService.update({
      //   id: contact.bdContactId,
      //   companyId: responseCompany.id,
      // });

      // console.log("response", response);

      // if (response.error || response.errors) {
      //   toast.error(response.error, { position: "bottom-center" });
      // } else if (response.id) {
      //   toast.success("Company added", { position: "bottom-center" });

      //   //fix
      //   setContact({
      //     ...contact,
      //     companies: [
      //       ...contact.companies,
      //       { ...data, companyId: response.id },
      //     ], //response.id may have to be updated to numeric return instead of UUID (for deletion etc)
      //   });

      //   setAddCompany(false); //display "add company" button
      //   setFilterInput(""); //reset input filter
      //   setNewCompany(false); //hide new company form
      // }

      // setFormSubmission(false);
    } else {
      toast.error(responseCompany.error, { position: "bottom-center" });
    }
    setFormSubmission(false);
  };

  const saveCompany = async company => {
    console.log("contact", contact);
    let response = await ContactService.update({
      id: contact.bdContactId,
      companyId: company.companyId,
    });

    if (response.error || response.errors) {
      toast.error(response.error, { position: "bottom-center" });
    } else {
      toast.success("Company added", { position: "bottom-center" });

      setContact({
        ...contact,
        company: company, //response.id may have to be updated to numeric return instead of UUID (for deletion etc)
      });

      setAddCompany(false); //display "add company" button
      setFilterInput(""); //reset input filter
    }
  };
  //Filter from search
  const [filterInput, setFilterInput] = useState("");
  const [newCompany, setNewCompany] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState("");
  const handleFilterChange = e => {
    const value = e.target.value || undefined;

    if (value) {
      let regex = new RegExp(value, "i");
      setFilteredCompanies(companies.filter(c => c.name.match(regex)));
    } else {
      setFilteredCompanies(companies);
    }
    //setFilter("main", value); //useFilter()
    setFilterInput(value);
  };

  return (
    <StyledCompany>
      <h1>Company</h1>
      <div className="content">
        {contact.company && contact.company.companyId ? (
          <>
            <span className="company">
              <Link to={"/bd/company/" + contact.company.companyId}>
                {contact.company.name}
              </Link>
            </span>
            <br />
            {contact.company.address
              ? formatAddress(
                  contact.company.address.city,
                  contact.company.address.state,
                  contact.company.address.country,
                  false,
                  true
                )
              : ""}
            {contact.company.website ? (
              <>
                <span className="website">
                  <a
                    href={contact.company.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contact.company.website}
                  </a>
                </span>
                <br />
              </>
            ) : (
              ""
            )}
            {contact.company.linkedinUrl ? (
              <>
                <span className="linkedin">
                  <a
                    href={contact.company.linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contact.company.linkedinUrl}
                  </a>
                </span>
                <br />
              </>
            ) : (
              ""
            )}
            {contact.company.phone ? (
              <>
                <span className="phone">{contact.company.phone}</span>
                <br />
              </>
            ) : (
              ""
            )}
            {contact.company.email ? (
              <>
                <span className="email">
                  <a href={"mailto:" + contact.company.email}>
                    {contact.company.email}
                  </a>
                </span>
              </>
            ) : (
              ""
            )}

            <StyledLink
              className="view-company"
              hidetext="true"
              to={"/company/" + contact.company.companyId}
            >
              View Company
            </StyledLink>
          </>
        ) : (
          ""
        )}

        {!addCompany && !contact.company && (
          <Button onClick={() => setAddCompany(true)}>Add Company</Button>
        )}

        {addCompany ? (
          <>
            {!newCompany && (
              <div className="filter">
                <Input className="search" type="search">
                  <Form.TextInput
                    name="search"
                    className="full"
                    type="text"
                    autoComplete={"Off"}
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder={"Type company name"}
                  />
                  <span className="go"></span>
                </Input>
                {filterInput ? (
                  <ul>
                    {filteredCompanies.length
                      ? filteredCompanies.map((c, i) => (
                          <li key={i}>
                            <Button size="small" onClick={() => saveCompany(c)}>
                              Add
                            </Button>
                            <span>{c.name}</span>
                          </li>
                        ))
                      : ""}
                    <li onClick={() => setNewCompany(true)}>
                      {filterInput} (Create new)
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            )}

            {newCompany ? (
              <StyledForm className="form">
                <span className="cancel" onClick={() => setAddCompany(false)}>
                  <img src={imgCircleX} title="Cancel" alt="Cancel" />
                </span>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <>
                    <ol>
                      <Form.Fieldset
                        legend="Add Company"
                        className="rows stacked"
                      >
                        <Form.InputWrapper
                          label="Name"
                          name="name"
                          errors={errors}
                          required={true}
                        >
                          <Form.TextInput
                            name="name"
                            type="text"
                            value={filterInput}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                        <Form.InputWrapper
                          label="City"
                          name="city"
                          errors={errors}
                        >
                          <Form.TextInput
                            name="city"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                        <Form.InputWrapper
                          label="State"
                          name="state"
                          errors={errors}
                        >
                          <Form.TextInput
                            name="state"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                        <Form.InputWrapper
                          label="Zip"
                          name="zip"
                          errors={errors}
                        >
                          <Form.TextInput
                            name="zip"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                        <Form.InputWrapper
                          label="Country"
                          name="country"
                          errors={errors}
                        >
                          <Form.TextInput
                            name="country"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>

                        <Form.InputWrapper
                          label="Description"
                          name="description"
                          errors={errors}
                        >
                          <Form.Textarea
                            name="description"
                            type="text"
                            className="smaller"
                            value={""}
                            forwardRef={register}
                            onChange={onChange}
                          />
                        </Form.InputWrapper>
                      </Form.Fieldset>
                      <Form.FieldsetActions>
                        {!formSubmission ? (
                          <Button primary>Save Company</Button>
                        ) : (
                          <LoadingSubmit></LoadingSubmit>
                        )}
                      </Form.FieldsetActions>
                    </ol>
                  </>
                </form>
              </StyledForm>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </StyledCompany>
  );
};

const StyledEvents = styled(Card)`
  ul {
    margin-bottom: 30px;
  }
  li {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 7px 30px 7px 5px;
    border-bottom: 1px solid ${Colors.gray};
  }
  .name {
  }
`;
const Events = ({ contact }) => {
  const getStage = stage => {
    switch (stage) {
      case "bd":
        return "BD";
      case "tapSent":
        return "TAP Sent";
      case "tapSigned":
        return "TAP Signed";
      case "toBeInvoiced":
        return "To Be Invoiced";
      case "workInProcess":
        return "Work In Process";
      case "placementFee":
        return "Engagement Fee";
      case "completed":
        return "Completed";
    }
  };
  return (
    <StyledEvents>
      <h1>BD Events</h1>
      <div className="content">
        {contact.opportunities && contact.opportunities.length ? (
          <ul>
            {contact.opportunities.map((event, i) => (
              <li key={i}>
                <Link to={"/bd/event/" + event.id} className="name">
                  {event.position?.title || event.company?.name}
                </Link>
                <StyledLink to={"/bd/event/" + event.id}>
                  {getStage(event.stage)}
                </StyledLink>
              </li>
            ))}
          </ul>
        ) : (
          "Contact is not associated with any events"
        )}

        {/* <ButtonLink to="/bd/event/new" size="small">
          Add Opportunity
        </ButtonLink> */}
      </div>
    </StyledEvents>
  );
};

const Info = ({ candidate, setCandidate }) => {
  const info = [
    {
      label: "First Name",
      value: "firstName",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Last Name",
      value: "lastName",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "City",
      value: "city",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "State",
      value: "state",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Zip",
      value: "zip",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Country",
      value: "country",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Email",
      value: "emailPersonal",
      type: "email",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Phone",
      value: "phone",
      edit: false,
      type: "phone",
      saving: false,
      editable: true,
    },
    {
      label: "LinkedIn",
      value: "linkedinShortened",
      edit: false,
      saving: false,
      editable: true,
    },
  ];

  return (
    <UpdateableInfo
      info={info}
      detail={{ title: "Info", type: "candidate" }}
      entity={candidate}
      setEntity={setCandidate}
      Service={CandidateService}
    />
  );
};
