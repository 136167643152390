import Config from "../config/config";

const get = inputData => {
  return fetch(
    Config.API_URL +
      "bookmarks/?limit=" +
      inputData.limit +
      "&degree=" +
      inputData.degree,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const list = inputData => {
  return fetch(
    Config.API_URL +
      "bookmarks?limit=" +
      (inputData.limit ? inputData.limit : 1000) +
      (inputData.offset ? "&offset=" + inputData.offset : ""),
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const create = inputData => {
  return fetch(Config.API_URL + "bookmarks", {
    method: "POST",
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const update = inputData => {
  return fetch(Config.API_URL + "bookmarks/" + inputData.id, {
    method: "PATCH",
    body: JSON.stringify({ ...inputData.data }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const del = id => {
  return fetch(Config.API_URL + "bookmarks/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
export const BookmarkService = {
  list,
  get,
  create,
  update,
  del,
};
