/*
 * TMG decided not to use an web integrated email interface to the preference of sending emails through their default providers
 */

import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import Calendar from "react-widgets/lib/Calendar";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import moment from "moment";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { InterviewService } from "../../services/Interview";
import { Container, Button, ButtonClose, PageTitle } from "../../styles/Common";
import { Colors } from "../../styles/Colors";
import { Form as StyledForm } from "../../styles/Form";
import { Form } from "../Shared/Form";
import { Card } from "../Shared/Common";
import imgX from "../../assets/x.svg";
import { toast } from "react-toastify";

Moment.locale("en");
momentLocalizer();

const Styled = styled.div`
  margin: 0 auto;
  width: 760px;
  .display {
    margin: 70px 0 0;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0 0 50px;
  }
  li {
    width: 217px;
    height: 182px;
    margin-left: 30px;
    background: #fff;
    border: 1px solid rgba(198, 203, 211, 0.5);
    box-shadow: 2px 2px 21px 0 rgba(81, 91, 105, 0.2);
    border-radius: 3px;
    text-align: center;
    padding-top: 56px;
    box-sizing: border-box;
    &.active {
      border-color: ${Colors.highlight};
    }
  }
  .date {
    display: block;
    font-size: 1.3rem;
    font-size: 1.125rem;
    white-space: nowrap;
    cursor: default;
    margin: 0 0 5px;
  }
  .time {
    display: block;
    padding-top: 20px;
    font-size: 2.5rem;
    font-weight: 300;
    cursor: default;
  }

  .actions {
    text-align: center;
    margin: 0 0 0 28px;
    .suggest {
      font-weight: 500;
      cursor: default;
    }
  }
  .confirmed {
    background: ${Colors.success};
    padding: 20px;
    color: #fff;
    font-size: 1.1rem;
  }
`;

const CalendarWrapper = styled.div`
  width: 321px;
  h1 {
    font-size: 0.75rem;
    font-weight: 600;
  }
`;
const StyledCalendar = styled(Card)`
  margin: 20px 0;
  .rw-calendar {
    border: 0;
    padding: 15px;
  }
  .rw-calendar-month .rw-cell {
    vertical-align: middle;
  }
  .rw-cell.rw-state-selected,
  .rw-cell.rw-state-selected:hover {
    background-color: ${Colors.highlight};
    border-color: ${Colors.highlight};
  }
  .rw-cell.rw-state-focus,
  .rw-cell.rw-state-focus:hover {
    border-color: ${Colors.highlight};
  }
  .rw-head-cell {
    border-bottom: none;
  }
`;

export default function JobOrderInterviewHiringManager(props) {
  let params = useParams();

  console.log("params", params);
  if (!params || !params.target) {
    props.history.push("/");
  }
  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    reset,
  } = useForm();

  //get candidate
  const [interview, setInterview] = useState(false);
  const [interviewConfirmed, setInterviewConfirmed] = useState(false);
  const [selectedTime, setSelectedTime] = useState(false);
  const [manager, setManager] = useState(
    params.target === "manager" ? true : false
  );
  const [interviewTimes, setInterviewTimes] = useState([]);

  //not really get interview... but get "link" for manager to set times
  const getInterview = async () => {
    console.log("wha");
    let response = await InterviewService.get({ id: params.id });
    if (response._links) {
      console.log("interview", response);
      setInterview(response);

      //set times to confirm
      let times = [];
      for (let i = 3; i >= 1; i--) {
        if (response["suggestedDate" + i]) {
          times.push({
            date: response["suggestedDate" + i],
            selected: i === 3 ? true : false,
          });
        }
      }
      console.log(props);
      //this means manager hadn't picked any times for whatever reason, but somehow this link was sent to someone
      if (!times.length) {
        props.history.push("/");
      }
      setInterviewTimes(times);
    } else {
      props.history.push("/");
    }
  };
  useEffect(() => {
    getInterview();
  }, []);

  const confirmDateTime = async () => {
    console.log("interviews", interviewTimes);

    let response = await InterviewService.confirm({
      interviewDate: selectedTime[0].date,
      status: "Confirmed",
      id: interview.interviewId,
    });

    if (!response.error) {
      setInterviewConfirmed(true);
    } else {
      toast.error("There was an error confirming you selected date", {
        position: "bottom-center",
      });
    }
  };

  const selectTime = time => {
    time.selected = true;
    setSelectedTime(
      interviewTimes.map(i => {
        i.selected = false;
        if (i === time) {
          i.selected = true;
        }
        return i;
      })
    );
  };

  return (
    <Container>
      <Styled>
        <PageTitle>Confirm Interview</PageTitle>
        {!interviewConfirmed ? (
          <p>
            Please confirm a time and date for an {interview.type} interview
            with {interview.hiringManagerFirstName}{" "}
            {interview.hiringManagerLastName} at {interview.company} for the{" "}
            {interview.position} position
          </p>
        ) : (
          <p className="confirmed">
            Interview time has been confirmed, thank you!
          </p>
        )}

        <div className="display">
          {!interviewConfirmed ? (
            <>
              <ul>
                {interviewTimes.map((time, i) => {
                  return (
                    <li
                      key={i}
                      className={time.selected ? "active" : ""}
                      onClick={() => selectTime(time)}
                    >
                      <span className="date">
                        {moment(time.date).format("MMMM D, YYYY")}
                      </span>
                      <span className="time">
                        {moment(time.date).format("h:mmA")}
                      </span>
                    </li>
                  );
                })}
              </ul>

              <div className="actions">
                <Button primary onClick={() => confirmDateTime()}>
                  Confirm Dates &amp; Times
                </Button>
                <Link
                  to={
                    "/job-order/" +
                    interview.interviewId +
                    "/select-interview-times/" +
                    (manager ? "manager" : "candidate")
                  }
                  className="suggest"
                >
                  Suggest a different date &amp; time
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </Styled>
    </Container>
  );
}
