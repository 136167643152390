import styled from "styled-components";
import { Colors } from "./Colors";
import imgChevron from "../assets/chevron.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .nav {
    display: flex;
    margin-left: 85px;
  }
`;

const RowsPerPage = styled.div`
  color: #a4acb9;
  p {
    display: inline-block;
  }
  .filter {
    margin-left: 5px;
    position: relative;
    display: inline-block;
    &:after {
      content: "";
      display: inline-block;
      height: 6.3px;
      width: 10.5px;
      background: url(${imgChevron}) no-repeat;
      background-position: 0 -6.3px;
      background-size: cover;
      margin-left: 35px;
      margin-bottom: 1px;
    }
    &.show {
      ol {
        padding: 10px 0;
        border: 1px solid rgba(198, 203, 211, 0.5);
        background: #fff;
        box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);
        border-radius: 3px;
      }
      li{        
        display: block;
        text-align:right;
        &:hover{background:#eee;}
        &:first-child{
          background:#ddd;
        }
      }
    }
  }
  ol {
    position: absolute;
    top: -1px;
    left: 0;
}


  }
  li {
    display:none;
    padding:2px 10px;
    cursor:pointer;
    &:first-child {
      display:block;
    }
  }
`;

const Paging = styled.ol`
  display: flex;
  align-items: center;
  li {
    cursor: default;
    min-width: 21px;
    height: 20px;
    text-align: center;
    color: #8792a3;
    span {
      cursor: pointer;
    }
    &.active {
      color: ${Colors.highlight};
      font-size: 1.125rem;
      font-weight: 500;
      &:after {
        content: "";
        display: block;
        margin: 1px 3px 0;
        width: 15px;
        height: 1px;
        background-color: ${Colors.highlight};
      }
    }
    &.skip-to-page {
      line-height: 10px;
      cursor: default;

      &.last:before,
      &.first:after {
        content: "...";
        margin: 0 7px;
        margin-bottom: 10px;
        vertical-align: middle;
        display: inline-block;
      }
      &.last {
        margin-right: 15px;
      }
      &.first {
        margin-left: 15px;
      }
    }
  }
`;

const Direction = styled.button`
    background:none;
    opacity:${props => (props.disabled ? ".4" : "1")};
    margin:3px 20px 0;
    border:0;
    cursor:${props => (props.disabled ? "default" : "pointer")};
    outline:none;
    padding:0;
    font-size:.75rem;
    color:#8792a3;
    &:${props => (props.direction === "previous" ? "before" : "after")} {
        content:'';
        color:#a4acb9;
        display:inline-block;
        width:15px;height:9px;
        margin:0 5px;
        transform: rotate(${props =>
          props.direction === "previous" ? "90deg" : "-90deg"});
        background:url(${imgChevron}) no-repeat;
        background-position: 0 -9px;
        background-size:cover;
    }
  
`;

export const Pagination = {
  Wrapper,
  Direction,
  RowsPerPage,
  Paging,
};
