import { Validate } from "../components/Shared/Validate";
import { startCase as _startCase } from "lodash";

export const parseResumeData = data => {
  let parsedData = {};

  //define fields we want to fill
  let valueSet = [
    { name: "firstName", type: "single" },
    { name: "lastName", type: "single" },
    { name: "employment", type: "employment" },
    { name: "education", type: "education" },
    { name: "contactInfo", type: "contactInfo" },
    { name: "desc", type: "single", map: "bio" },
  ];
  valueSet.forEach(v => {
    switch (v.type) {
      case "single":
        parsedData[v.map ? v.map : v.name] = _startCase(
          data[v.name].toLowerCase()
        );
        break;
      case "employment":
        parsedData.additionalEmployment = [];
        if (data[v.name].length) {
          data[v.name].forEach((e, i) => {
            if (i === 0) {
              e.companyName = _startCase(e.companyName.toLowerCase());
              e.title = _startCase(e.title.toLowerCase());
              parsedData.currentEmployments = {
                companyName: e.companyName,
                title: e.title,
              };
            } else {
              parsedData.additionalEmployment.push(e);
            }
          });
        }

        break;
      case "contactInfo":
        parsedData.address = {};
        data[v.name].forEach(contact => {
          if (contact.city) {
            parsedData.address.city = contact.city;
          }
          if (contact.state) {
            parsedData.address.state = contact.state;
          }
          if (contact.zip) {
            parsedData.address.zip = contact.zip;
          } else if (contact.telephone) {
            parsedData.phone = Validate.Phone(contact.telephone);
          } else if (contact.mobilePhone) {
            parsedData.phone = Validate.Phone(contact.mobilePhone);
          } else if (contact.emailPersonal) {
            parsedData.emailPersonal = contact.emailPersonal;
          } else if (contact.linkedinUrl) {
            parsedData.linkedinUrl = contact.linkedinUrl;
          }
        });

        break;
      case "education":
        {
          let education = [];
          parsedData.workExperience = 0;
          data[v.name].map(edu => {
            let r = {};
            r.name = _startCase(edu.name.toLowerCase());
            r.degreeType = edu.degree ? getDegreeType(edu.degree) : "";
            r.endYear = edu.endDate ? edu.endDate : "";
            education.push(r);

            if (
              edu.endDate &&
              parsedData.workExperience < parseInt(edu.endDate)
            ) {
              parsedData.workExperience = edu.endDate;
            }
          });
          parsedData.education = education;
        }
        break;
      default:
        break;
    }
  });

  return parsedData;
};

//determine degree regex based degree type
export const getDegreeType = degree => {
  console.log("degree", degree);
  if (degree.trim().match(/Master of Business|^MBA$/i)) {
    return "MBA";
  } else if (degree.trim().match(/Master|^MS$/i)) {
    return "MS";
  } else if (degree.trim().match(/Bach|^BS$/i)) {
    return "BS";
  } else if (degree.trim().match(/Juris/i)) {
    return "JD";
  } else if (degree.trim().match(/^Ph|Doctor/i)) {
    return "Phd";
  }
  return degree.trim();
};

export const parseLinkedInData = data => {
  let parsedData = {};

  //education
  if (data.education) {
    let education = [];
    parsedData.workExperience = 0;
    data.education.map(edu => {
      let r = {};
      r.name = edu.name ? edu.name : "";
      r.degreeType = edu.degree ? getDegreeType(edu.degree) : "";
      r.endYear = edu.endDate ? edu.endDate : "";
      education.push(r);

      if (edu.endDate && parsedData.workExperience < parseInt(edu.endDate)) {
        parsedData.workExperience = edu.endDate;
      }
    });
    parsedData.education = education;
  }
  //employment
  if (data.employment) {
    parsedData.additionalEmployment = [];

    data.employment.forEach((e, i) => {
      if (i === 0) {
        parsedData.currentEmployments = {
          title: e.title,
          companyName: e.companyName,
        };
      } else {
        parsedData.additionalEmployment.push(e);
      }
    });
  }
  //split and set name
  //Name data is not always accurate. The string length is to separate a real name from the long text blob that LinkIn send when a name isn't available. The length is arbitrary
  if (data.name) {
    //if there are comma's (like degrees after name, split it first)
    if (data.name.match(",")) {
      let _name = data.name.split(",");
      data.name = _name[0];
    }
    let name = data.name
      .replace(/\((.*?)\)/g, "") //update names like FirstName (Nickname) LastName
      .replace(/\s\s/g, " ") //replace multiple spaces w/ single space
      .split(" ");
    if (name.length === 2) {
      parsedData.firstName = name[0];
      parsedData.lastName = name[1];
    }
  }
  /*
        If location data is available split on the first comma and assume index 0 = city and index 1 = state
        The user can edit if it's wrong
    */
  if (data.location) {
    parsedData.address = {};
    let location = data.location.split(",");
    if (location.length < 2) {
      parsedData.address.city = location[0].trim();
    } else {
      parsedData.address.city = location[0].trim();
      parsedData.address.state = location[1].trim();
    }
  }

  return parsedData;
};
