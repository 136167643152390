import React, { useState } from "react";
import { AuthService } from "../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { Form } from "../Shared/Form";
import { Button, Container, PageTitle } from "../../styles/Common";
import { Form as StyledForm } from "../../styles/Form";

const Login = props => {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn");
  const [errorMessage, setErrorMessage] = useState("");

  //react-hook-form
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    let response = await AuthService.authorize(data);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.token) {
      // localStorage.setItem("token", response.token); //temporary if user is no set in database
      setIsLoggedIn(true);
    }
  };

  const onChange = () => {
    setErrorMessage("");
  };

  //redirect to dashboard if alreeady logged in
  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Container className="form">
      <PageTitle>Login</PageTitle>

      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol>
            <Form.Fieldset>
              <Form.InputWrapper label="Email" name="email" errors={errors}>
                <Form.TextInput
                  name="email"
                  type="email"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                  placeholder=""
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Password"
                name="password"
                errors={errors}
              >
                <Form.TextInput
                  name="password"
                  type="password"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                  placeholder=""
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.FieldsetActions>
              {errorMessage && <Form.Error message={errorMessage} />}
              <Button primary>Submit</Button>
            </Form.FieldsetActions>
          </ol>
        </form>
      </StyledForm>
    </Container>
  );
};

export default Login;
