import React, { useState, useEffect } from "react";
import { LoadingSubmit } from "../../styles/Animation";
import styled from "styled-components";
import { get as _get } from "lodash";
import { Colors } from "../../styles/Colors";
import imgCircleX from "../../assets/circle-x.svg";

/*
 * Quick Notes
 */
export const ParsedData = ({
  medium,
  parsedData,
  entity,
  setEntity,
  Service,
}) => {
  const Styled = styled.div`
    .bg {
      display: block;
      content: "";
      background: #000000c7;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 1000;
      left: 0;
      top: 0;
    }
    .display {
      background: #fff;
      width: 500px;
      padding: 20px 30px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1001;
    }
    .cancel {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      cursor: pointer;
    }
    p {
      margin-bottom: 20px;
      background: ${Colors.primary};
      color: #fff;
      padding: 10px 20px;
    }
    table {
      width: 100%;
    }
    thead th {
      text-align: left;
      background: #f2f2f2;
      border: 1px solid #fff;
      color: ${Colors.primary};
      padding: 10px;
      &:nth-child(1) {
        background: none;
      }
    }
    tr {
    }
    td {
      vertical-align: top;
      border-bottom: ${Colors.gray};
    }
    tbody th {
      font-weight: 600;
      text-align: right;
      white-space: nowrap;
      padding: 5px;
      vertical-align: top;
    }
    .new {
      span {
      }
    }
    .current,
    .new {
      .wrapper {
        display: flex;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
        border-bottom: 1px solid ${Colors.gray};
        &:hover {
          background: #eee;
        }
      }
    }
    .company-name {
      font-weight: 600;
      width: 140px;
      display: inline-block;
    }
    .title {
    }
  `;

  const [parserLoading, setParserLoading] = useState(true);
  const [confirmData, setConfirmData] = useState([]);
  const [standardData, setStandardData] = useState(false);
  const [additionalEmployment, setAdditionalEmployment] = useState(false);
  let _confirmData = [];

  let _standardData = false;
  let _additionalEmployment = false;
  useEffect(() => {
    console.log("parseData Start", parsedData);
    if (parsedData) {
      Object.entries(parsedData).forEach(arr => {
        let value = _get(entity, arr[0]);

        //employment
        if (arr[0].match(/currentEmployments/)) {
          //exists? replace employment?
          if (
            entity.currentEmployments &&
            entity.currentEmployments.companyName !==
              parsedData.currentEmployments.companyName
          ) {
            _confirmData.push({
              type: "Employment",
              name: "currentEmployments",
              confirmed: false,
              current: entity.currentEmployments.companyName,
              new: parsedData.currentEmployments.companyName,
              data: parsedData.currentEmployments,
            });
            _standardData = true;
          } else {
            //not exists ... just insert
            addParsedEmployment(parsedData.currentEmployments);
            entity.currentEmployments = parsedData.currentEmployments;
          }
        } else if (arr[0].match(/additionalEmployment/)) {
          console.log(parsedData.additionalEmployment);
          setAdditionalEmployment(true);

          parsedData.additionalEmployment.forEach(e => {
            _confirmData.push({
              type: "Additional Employment",
              name: "additionalEmployment",
              confirmed: false,
              data: e,
            });
            _additionalEmployment = true;
          });
        } else if (arr[0].match(/education/)) {
          //maybe auto add
          let _workExperience = 0;
          console.log("ENTITY EDUCATION", entity.education);
          for (let edu of parsedData.education) {
            //school doesn't exit? add it

            console.log("name edu", edu.name);
            console.log(
              "found edu",
              entity.education.find(e => e.name === edu.name)
            );
            if (
              !entity.education ||
              !entity.education.find(e => e.name === edu.name)
            ) {
              addParsedEducation(edu);
            }

            //determine year graduated change while we're at it
            //entity.education.push(edu);

            if (
              (edu.endDate && !entity.workExperience) ||
              parseInt(edu.endDate) > entity.workExperience
            ) {
              _workExperience = parseInt(edu.endDate);
            }

            if (
              entity.workExperience &&
              entity.workExperience !== _workExperience &&
              _workExperience
            ) {
              console.log("entity we", entity.workExperience);
              console.log("entity _workExperience", _workExperience);
              _confirmData.push({
                type: "Year Graduated",
                name: "workExperience",
                confirmed: false,
                current: entity.workExperience,
                new: _workExperience,
              });
              _standardData = true;
            } else if (!entity.workExperience && _workExperience) {
              //push it thru
              Service.update({
                id: entity.id,
                workExperience: _workExperience,
              });
              entity.workExperience = _workExperience;
            }
            //setEntity({...entity, education: [...entity.education, edu]});
          }
        }
        //address
        else if (arr[0].match(/address/)) {
          if (parsedData.address.city && !parsedData.address.state) {
            parsedData.address.state = "";
          }
          if (parsedData.address.state && !parsedData.address.city) {
            parsedData.address.city = "";
          }
          updateParsedAddress(parsedData.address);
        }
        //standard values
        else if (value != arr[1] && arr[1]) {
          let type;
          switch (arr[0]) {
            case "firstName":
              type = "First Name";
              break;
            case "phone":
              type = "Phone";
              break;
            case "emailPersonal":
              type = "Personal Email";
              break;
            case "bio":
              type = "Bio";
              break;
            case "linkedinUrl":
              type = "LinkedIn";
              break;
            case "lastName":
              type = "Last Name";
              break;
            case "workExperience":
              type = "Year Graduated";
              break;
            default:
              break;
          }

          //if it doesn't exist yet, go ahead and set it for entity
          if (!value) {
            console.log("autoupdate", arr[0]);
            if (arr[1]) {
              Service.update({ id: entity.id, [arr[0]]: arr[1] });
              entity[arr[0]] = arr[1];
            }
          } else {
            _confirmData.push({
              type: type,
              name: arr[0],
              confirmed: false,
              current: value,
              new: arr[1],
            });
            _standardData = true;
          }
        } else {
          console.log("not found arr", arr);
        }
      });
      setConfirmData(_confirmData);
      setStandardData(_standardData);
      setParserLoading(false);

      setEntity({ ...entity });
    }
  }, [parsedData]);

  const setConfirm = cd => {
    cd.confirmed = true;
    setConfirmData([...confirmData]);
    if (
      !confirmData.filter(
        c => !c.confirmed && c.type !== "Additional Employment"
      ).length
    ) {
      setStandardData(false);
    }
    if (
      !confirmData.filter(
        c => !c.confirmed && c.type === "Additional Employment"
      ).length
    ) {
      setAdditionalEmployment(false);
    }
  };
  //Resume Parser Response Handling
  const updateParsedAddress = async address => {
    //fill in required and missing pieces
    if (address.city && !address.state) {
      address.state = entity.state ? entity.state : "";
    }
    if (address.state && !address.city) {
      address.city = entity.city ? entity.city : "";
    }
    address.zip = address.zip ? address.zip : entity.zip;
    address.country = address.country ? address.country : entity.country;

    let response = await Service.update({ id: entity.id, address });
    if (!response.error) {
      setEntity({ ...entity, ...address });
    }
  };
  const addParsedEducation = async edu => {
    let response = await Service.addEducation({
      candidateId: entity.id,
      data: edu,
    });
    if (!response.error) {
      setEntity({
        ...entity,
        education: [
          ...entity.education,
          {
            name: edu.name,
            degreeType: edu.degreeType,
            endYear: edu.endYear,
            educationId: response._embedded.educationIds[0],
          },
        ],
      });
    }
  };
  const updateParsedEmployment = async cd => {
    let response = await Service.updateEmployment({
      id: entity.id,
      employmentId: entity.currentEmployments.employmentId,
      data: cd.data,
    });
    if (!response.error) {
      setEntity({
        ...entity,
        currentEmployments: {
          companyName: cd.data.companyName,
          title: cd.data.title,
          companyId: undefined, //not a true client/company for TMG
          employmentId: entity.currentEmployments.employmentId,
        },
      });
    }
    setConfirm(cd);
  };
  const addParsedEmployment = async emp => {
    console.log("emp", emp);
    let response = await Service.addEmployment({
      id: entity.id,
      data: { companyName: emp.companyName, title: emp.title },
    });
    if (!response.error) {
      console.log("auto add employee", {
        companyName: emp.companyName,
        title: emp.title,
        companyId: response.id,
        employmentId: parseInt(response._links.next.href.replace(/(.*)\//, "")),
      });
      setEntity({
        ...entity,
        currentEmployments: {
          companyName: emp.companyName,
          title: emp.title,
          //companyId: response.id, //not the UUID of company being returned
          employmentId: parseInt(
            response._links.next.href.replace(/(.*)\//, "")
          ),
        },
      });
    }
  };
  const update = async cd => {
    let response = await Service.update({
      id: entity.id,
      [cd.name]: cd.new,
    });
    if (!response.error) {
      setEntity({ ...entity, [cd.name]: cd.new });
    }
    setConfirm(cd);
  };

  const setAllConfirmed = () => {
    setConfirmData([]);
    setStandardData(false);
  };

  const addAdditionalEmploymentNote = async cd => {
    let response = await Service.addNote({
      title: "Additional Employment",
      note:
        (cd.data.companyName ? "<b>" + cd.data.companyName + "</b> - " : "") +
        cd.data.title.replace(/^Title\s|\n/, ""),
      type: "linkedin",
      id: entity.id,
    });
    if (!response.error) {
      setConfirm(cd);
      setEntity({ ...entity, updateNotes: entity.updateNotes + 1 });
    }
  };

  console.log("confirmData", confirmData);

  console.log("parserLoading", parserLoading);

  return (
    <>
      {confirmData.filter(cd => !cd.confirmed).length || parserLoading ? (
        <Styled>
          <div className="bg"></div>
          <div className="display">
            {parserLoading ? (
              <div className="loading linkedin-loading">
                <LoadingSubmit></LoadingSubmit>
                <span style={{ marginLeft: "25px", verticalAlign: "middle" }}>
                  loading data from{" "}
                  {medium === "linkedin" ? "LinkedIn" : "Resume"}
                </span>
              </div>
            ) : (
              <>
                <img
                  className="cancel"
                  onClick={setAllConfirmed}
                  src={imgCircleX}
                  title="Cancel"
                  alt="Cancel"
                />
                <h1>Choose {standardData ? "Correct" : ""} Entries</h1>
                {standardData ? (
                  <p>
                    There were items from{" "}
                    {medium === "linkedin" ? "linkedIn" : "the resume"} that did
                    not match the current values. Please select which data to
                    keep
                  </p>
                ) : (
                  ""
                )}

                {standardData ? (
                  <table
                    style={{ marginBottom: additionalEmployment ? 20 : 0 }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Current Info</th>
                        <th>
                          {medium === "linkedin" ? "LinkedIn" : "Resume"} Info
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {confirmData
                        .filter(
                          cd =>
                            !cd.confirmed && cd.type !== "Additional Employment"
                        )
                        .map((cd, i) => {
                          return (
                            <tr key={i}>
                              <th>{cd.type}</th>
                              <td
                                className="current"
                                onClick={() => setConfirm(cd)}
                              >
                                <div className="wrapper">{cd.current}</div>
                              </td>
                              <td
                                className="new"
                                onClick={
                                  cd.type === "Employment"
                                    ? () => updateParsedEmployment(cd)
                                    : () => update(cd)
                                }
                              >
                                <div className="wrapper">{cd.new}</div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}

                {additionalEmployment ? (
                  <>
                    <p>
                      Additional Employment (Click to save to additional info)
                    </p>
                    <table>
                      <tbody>
                        {confirmData
                          .filter(
                            cd =>
                              !cd.confirmed &&
                              cd.type === "Additional Employment"
                          )
                          .map((cd, i) => {
                            return (
                              <tr key={i}>
                                <td
                                  className="new"
                                  onClick={() =>
                                    addAdditionalEmploymentNote(cd)
                                  }
                                >
                                  <div className="wrapper">
                                    <span className="company-name">
                                      {cd.data.companyName}
                                    </span>
                                    <span className="title">
                                      {cd.data.title}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </Styled>
      ) : (
        ""
      )}
    </>
  );
};
