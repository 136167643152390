import React from "react";
import { Button } from "../styles/Common";

/*
 * rss

*/
//set initial Presenting Company
/*export const resetItemDELETE = (initial, index, iterableInputs, iterableKey) => {
  let initial_index = index ? index : Math.random();
  let _resetData = {
    initial: initial,
    index: initial_index,
    key: initial_index,
  };
  console.log("resetItem", iterableKey);
  iterableInputs[iterableKey].fields.forEach(field => {
    _resetData[field.name] = "";
  });

  return _resetData;
}; */
export const resetItem = (iterableInputs, iterableKey) => {
  let _data = [];
  iterableInputs[iterableKey].fields.forEach(field => {
    _data[field.name] = field.defaultValue || "";
  });

  return _data;
};

export const addItem = (items, setItems, iterableInputs, iterableKey) => {
  //set them, then add new item to the mix
  setItems([...items, resetItem(iterableInputs, iterableKey)]);
};

export const deleteItem = (
  index,
  items,
  setItems,
  iterableInputs,
  iterableKey
) => {
  const _items = [...items];
  //if its the first one, and just being reset, lets just reset the form
  if (!index) {
    setItems([resetItem(iterableInputs, iterableKey)]);
  } else {
    _items.splice(index, 1);
    setItems(_items);
  }
};

export const handleIteratorChange = (e, i, items, setItems) => {
  const { name, value } = e.target;
  items[i][name.replace(/^(.*?)\[(.*?)\]/i, "$2")] = value;
  setItems([...items]);
};

export const handleIteratorChangeDateTime = (
  date,
  i,
  items,
  setItems,
  name
) => {
  items[i][name] = date;
  setItems([...items]);
};
export const handleIteratorChangeCreateSelect = (
  opt,
  actionMeta,
  i,
  items,
  setItems
) => {
  if (actionMeta.action === "select-option") {
    items[i][actionMeta.name.replace(/^(.*?)\[(.*?)\]/i, "$2")] = opt.value;
    items[i]["create"] = false;
  } else if (actionMeta.action === "create-option") {
    items[i]["create"] = opt.label;
    items[i][items[i].createPopulateKey] = opt.label; //populate chosen field w/ a value
  }
  setItems([...items]);
};

export const setItemInputValues = (items, iterableInputs, iterableKey) => {
  //need to get all current values of inputs before rewriting
  let inputs = document.querySelectorAll(
    'input[name^="' + iterableKey + '"], textarea[name^="' + iterableKey + '"]'
  );
  let _items = [];

  let _id,
    id = "";
  let _buildItem;
  let i = 0;
  let nodes = Array.from(inputs);
  let numNodes = nodes.length;
  let numInGroup = 0;
  let temp_id = "";
  let r = 1; //iterates to know when next item starts based on prev id being the same
  nodes.forEach((input, x) => {
    //seleced candidate from list
    id = input.name.split("][")[0].split(iterableKey + "[")[1];
    if (temp_id !== id) {
      r = 1;
      temp_id = id;
    }
    numInGroup = nodes.filter(n => n.name.match(new RegExp(id))).length;
    if (i === 0 && x === 0) {
      _buildItem = resetItem(true, id, iterableInputs, iterableKey);
    }
    _buildItem.index = _buildItem.key = id;
    //get changed value
    //set value for react hook

    let field = iterableInputs[iterableKey].fields.find(
      f => iterableKey + "[" + id + "][" + f.name + "]" === input.name
    );
    if (field) {
      //iterableInputs[iterableKey].fields.forEach((field, index) => {
      if (field.type === "date") {
        //TODO: something w/ the types if needed
        _buildItem[field.name] = input.value;
      } else {
        _buildItem[field.name] = input.value;
      }
      //set focus on next item
      /*
      TODO: finish this, focus is not getting on the correct item.. also this is the only area where "items" param is needed
      if (
        iterableInputs[iterableKey].fields.length !== i + 1 && //not the last one
        input.value !== items[i][field.name] && //only if value just changed, so go to next one
        field.type !== "hidden"
      ) {
        for (
          let _i = x + 1;
          _i <= iterableInputs[iterableKey].fields.length;
          _i++
        ) {
          console.log("looping: ", iterableInputs[iterableKey].fields[_i].name);
          if (iterableInputs[iterableKey].fields[_i].type !== "hidden") {
            console.log(
              "focus is now on:" + iterableInputs[iterableKey].fields[_i].name
            );
            _buildItem.focus = iterableInputs[iterableKey].fields[_i].name;
            break;
          }
        }
      } */
      //});

      //keep _id set to latest id
      _id = id;
      if (_id && r % numInGroup === 0) {
        _items.push(_buildItem);
        _buildItem = resetItem(false, false, iterableInputs, iterableKey);
        i++;
      }
      r++;
    }
  });

  return _items;
};

/*
 * Button to add new hiring manager
 */
export const fieldsetActions = (
  items,
  setItems,
  iterableInputs,
  iterableKey,
  verbiage
) => {
  return (
    <Button
      type="button"
      fieldsetAction
      size="small"
      style={{ marginBottom: "14px" }}
      onClick={() => addItem(items, setItems, iterableInputs, iterableKey)}
    >
      {verbiage}
    </Button>
  );
};
