import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card as StyledCard,
  Content as ContentStyled,
  BackLink as StyledBackLink,
  Button,
  Dropdown as StyledDropdown,
} from "../../styles/Common";

export const BackLink = props => {
  return (
    <StyledBackLink>
      <p>
        <Link to={props.to}>{props.verbiage}</Link>
      </p>
    </StyledBackLink>
  );
};
export const Card = props => {
  return (
    <StyledCard
      className={"card" + (props.className ? " " + props.className : "")}
      id={props.id ? " " + props.id : ""}
      onClick={props.onClick}
    >
      {props.title ? <h1>{props.title}</h1> : ""}
      {props.children}
    </StyledCard>
  );
};

export const ContentExpandable = props => {
  const [contracted, setContracted] = useState(true);

  return (
    <>
      <ContentStyled
        className={
          (props.enabled ? "expandable " : "") +
          (contracted ? "contracted" : "expanded")
        }
        initialHeight={props.initialHeight}
        edit={props.edit}
      >
        {props.children}
        {props.enabled && !props.edit ? (
          <Button
            size={props.buttonSize}
            onClick={() => setContracted(!contracted)}
          >
            {contracted
              ? props.verbiageContracted
                ? props.verbiageContracted
                : "View More"
              : props.verbiageExpanded
              ? props.verbiageExpanded
              : "View Less"}
          </Button>
        ) : (
          ""
        )}
      </ContentStyled>
    </>
  );
};

export const Dropdown = props => {
  const [active, setActive] = useState(false);

  const Open = () => {
    if (!active) {
      document.addEventListener("click", Close);
      setActive(true);
    }
  };
  const Close = e => {
    let close = true;
    if (props.listId && e.target.tagName === "INPUT") {
      let list = document.getElementById(props.listId);

      if (list.contains(e.target)) {
        close = false;
      }
    }
    if (close) {
      if (props.listId) {
        let input = document.querySelector("#" + props.listId + " input");
        if (input) {
          input.value = "";
        }
      }
      document.removeEventListener("click", Close);
      setActive(false);
    }
  };

  return (
    <StyledDropdown
      position={props.position}
      className={
        (props.className ? props.className : "") + (active ? " active" : "")
      }
      onClick={() => Open()}
    >
      {props.children}
    </StyledDropdown>
  );
};
