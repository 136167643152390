import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";

let renderAreaChart = props => {
  // console.log('------areachart props-----', props);
  let chartData = props.data;
  let areas = [];
  if (Object.keys(chartData).length !== 0) {
    let keys = Object.keys(chartData[0]);
    keys.forEach((k, i) => {
      // create a bar for each object, only keys are needed
      if (k !== "name") {
        areas.push(
          <Area
            dataKey={k}
            key={k + props.colorArray[i]}
            type="monotone"
            fillOpacity={0.9}
            stackId="a"
            stroke={props.colorArray[i] || props.chartColors.primaryColor}
            fill={props.colorArray[i] || props.chartColors.primaryColor}
          />
        );
      }
    });
  } else {
    return "No data found";
  }

  return (
    <ResponsiveContainer width={"100%"} height={250}>
      <AreaChart
        width={props.chartConfigs.width}
        height={props.chartConfigs.height}
        data={props.data}
        margin={props.chartConfigs.margin}
      >
        <Legend content={props.renderLegend} verticalAlign="top" height={36} />
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {areas}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default renderAreaChart;
