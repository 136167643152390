import React, { useState, useEffect } from "react";
import { CandidateService } from "../../services/Candidate";
import { Email } from "../../components/Shared/Email";

export default function CandidateEmail(props) {
  //get candidate
  const [candidate, setCandidate] = useState(false);
  const candidateId = props.match.params.id;

  useEffect(() => {
    const getCandidate = async () => {
      let _candidate = await CandidateService.get(candidateId);
      _candidate.id = candidateId;
      if (_candidate) {
        setCandidate(_candidate);
      }
    };
    getCandidate();
  }, [candidateId]);

  return (
    <>
      {candidate && (
        <Email
          entity={candidate}
          detail={{
            type: "candidate",
            title: "New Email",
            history: props.history,
          }}
          emailAddresses={[
            candidate.emailPersonal
              ? candidate.emailPersonal
              : candidate.emailWork,
          ]}
        />
      )}
    </>
  );
}
