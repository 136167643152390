import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import device from "../../config/device";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Colors } from "../../styles/Colors";

import imgChevron from "../../assets/chevron.svg";
import { PositionService } from "../../services/Position";
import { InterviewService } from "../../services/Interview";
import { Form } from "../Shared/Form";
import { Input } from "../../styles/Form";
import {
  Button,
  DateTimePicker as StyledDateTimePicker,
} from "../../styles/Common";
import { orderBy as _orderBy } from "lodash";
import moment from "moment";

const Columns = styled.div`
  margin-top: 16px;
  margin-bottom: 31px;
  display: flex;
  position: relative;
  min-height: 300px;

  @media ${device.laptopLMax} {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto auto auto auto;
  }
  &.modal:after {
    content: "";
    background: #000;
    opacity: 0.8;
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .column {
    position: relative;
    margin: 0 13.5px;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 164px;

    .header {
      display: flex;
      h2 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.857rem;
        white-space: nowrap;
        .count {
          margin-left: 5px;
        }
      }
      .contract {
        border: 1px solid ${Colors.primary};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 21px;
        height: 14px;
        :before {
          content: "";
          display: block;
          width: 7px;
          height: 4.8px;
          transform: rotate(-90deg);
          background: url(${imgChevron});
          background-size: cover;
          background-position: 0 0;
          margin: 1px 2.5px 0 3.5px;
        }
      }
    }
    .board {
      margin-top: 11px;
      padding: 5px;
      background: ${Colors.gray_accent_opaque};
      border-radius: 3px;
      height: calc(100% - 26px);
    }
    @media ${device.laptopLMax} {
      .board {
        height: 200px;
        margin-bottom: 11px;
      }
    }

    li {
      position: relative;
      .name {
        font-weight: 600;
        color: ${Colors.primary};
        margin-bottom: 5px;
        white-space: nowrap;
      }
      userselect: "none";
      background: #fff;
      padding: 10px;
      color: #8792a3;
      margin-bottom: 5px;
      min-height: 89px;
      box-sizing: border-box;
      .days {
        position: absolute;
        top: 5px;
        right: 5px;
        border: 4px solid ${Colors.highlight};
        display: block;
        width: 21px;
        height: 21px;
        line-height: 21px;
        font-weight: 600;
        text-align: center;
        border-radius: 21px;
        font-size: 0.9rem;
        background: #fff;
      }
      .create-interview,
      .time-presented {
        font-weight: 600;
        cursor: pointer;
      }
      .interview-date {
        white-space: nowrap;
      }
    }

    &.collapsed {
      width: 58px;
      flex-grow: 0;
      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
      }
      .contract {
        margin: 0 29px 0 21px;
        &:before {
          transform: rotate(90deg);
        }
      }
      ul {
        display: none;
      }
    }
  }
`;

const StyledModal = styled.div`
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 300px;
  padding: 10px;
  border-radius: 3px;
  .title {
    font-weight: 600;
  }
  p {
    margin-bottom: 5px;
  }
  .actions {
    margin-top: 5px;
    text-align: right;
    button {
      margin-right: 0;
      margin-left: 15px;
    }
  }
`;

export const DragNDrop = ({ stages, candidates, positionId, position }) => {
  //react-hook-form
  const { register, getValues, reset } = useForm();

  const sortStages = stages => {
    let ordering = {};
    let sortOrder = [
      "Qualified",
      "Presented",
      "On Hold",
      "Telephone",
      "F2F",
      "Offer Conversations",
      "Reference Checking", //or Reference Checks
      "Offer",
      "Passed",
    ];
    for (let i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }
    stages.sort(function(a, b) {
      return ordering[a.name] - ordering[b.name];
    });
    return stages;
  };
  stages = sortStages(stages);

  //add candidates etc to stages
  const prepareColumns = (stages, candidates) => {
    let _stages = {};

    for (let stage of stages) {
      _stages[stage.name] = {
        stage: stage.name,
        collapseRejections: false,
        candidates: candidates.filter(c => c.stage === stage.name),
      };
    }
    return _stages;
  };

  const [columns, setColumns] = useState([]);
  const [modal, setModal] = useState(false);
  const [newStage, setNewStage] = useState("");
  const [activeCandidate, setActiveCandidate] = useState();

  useEffect(() => {
    if (stages) {
      setColumns(prepareColumns(stages, candidates));
    }
  }, []);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removedCandidate] = sourceClone.splice(droppableSource.index, 1);

    removedCandidate.daysInStage = 0;
    setActiveCandidate(removedCandidate);
    setNewStage(droppableDestination.droppableId);

    if (
      droppableDestination.droppableId === "Rejected" ||
      droppableDestination.droppableId === "Passed"
    ) {
      setModal(true);
    } else if (droppableDestination.droppableId.match(/Telephone|F2F/)) {
      setModal(true);
    } else {
      //update candidate in database
      PositionService.updateCandidateStage({
        candidateId: removedCandidate.id,
        positionId: positionId,
        stage: droppableDestination.droppableId,
        rejectionReason: "",
      });
    }

    destClone.splice(droppableDestination.index, 0, removedCandidate);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const setCollapseRejections = column => {
    let _column = {
      ...columns[column],
      collapseRejections: !columns[column].collapseRejections,
    };
    columns[column].collapseRejections = !columns[column].collapseRejections;
    setColumns({ ...columns });
  };
  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      //no change in order
      if (source.index === destination.index) {
        return;
      }
      // if moving with a column
      const result = reorder(
        columns[source.droppableId].candidates,
        source.index,
        destination.index
      );

      //replace result
      const updatedSource = {
        [source.droppableId]: {
          ...columns[source.droppableId],
          candidates: result,
        },
      };
      //merge into stages
      setColumns({ ...columns, ...updatedSource });
    } else {
      // if moving to a new column
      const result = move(
        columns[source.droppableId].candidates,
        columns[destination.droppableId].candidates,
        source,
        destination
      );

      //update new candidate lists and set new state

      const updatedSource = {
        [source.droppableId]: {
          ...columns[source.droppableId],
          candidates: result[source.droppableId],
        },
      };
      const updatedDest = {
        [destination.droppableId]: {
          ...columns[destination.droppableId],
          candidates: result[destination.droppableId],
        },
      };
      //merge into stages
      setColumns({ ...columns, ...updatedSource, ...updatedDest });
    }
  };

  const onModalRejection = async () => {
    let data = getValues();

    if (newStage === "Rejected" || newStage === "Passed") {
      let response = await PositionService.updateCandidateStage({
        candidateId: activeCandidate.id,
        positionId: positionId,
        stage: "Passed",
        rejectionReason: data.rejectionReason,
      });
      activeCandidate.rejectionReason = data.rejectionReason;

      if (response.error) {
        toast.error(response.error, { position: "bottom-center" });
      }
    }
    reset();
    setNewStage("");
    setModal(false);
  };

  const getInterviewType = (candidate, stage) => {
    let type;
    stage = stage ? stage : candidate.stage;
    switch (stage) {
      case "Technical Telephone":
      case "Telephone":
        type = "phone";
        break;
      case "Onsite":
      case "F2F":
        type = "onsite";
        break;
    }

    return type;
  };

  const onCreateInterview = async (candidate, stage, date) => {
    let type = getInterviewType(candidate, stage);

    console.log("type", type);
    let response = await InterviewService.create({
      candidateId: candidate.id,
      positionId: positionId,
      type: type,
      manual: date ? true : false,
    });

    if (response._embedded) {
      if (date) {
        //still have to send confirm because interviewDate is not being added on create
        let responseConfirm = await InterviewService.confirm({
          interviewDate: moment(date).toDate(),
          id: response._embedded.id,
          status: "Confirmed",
        });
      }
      //get user to send email page
      toast.success(
        date
          ? "Interview date has been set"
          : "Interview has been created and hiring manager has been notified to select scheduled times",
        {
          position: "bottom-center",
        }
      );
    } else {
      toast.error("There was an error creating the interview", {
        position: "bottom-center",
      });
    }
    //update candidate in database
    if (stage !== candidate.stage && stage) {
      PositionService.updateCandidateStage({
        candidateId: candidate.id,
        positionId: positionId,
        stage: stage,
        rejectionReason: "",
      });
    }

    setNewStage("");
    setModal(false);
  };

  const onSkipInterviewCreate = (candidateId, stage) => {
    if (newStage) {
      //update candidate in database
      PositionService.updateCandidateStage({
        candidateId: candidateId,
        positionId: positionId,
        stage: stage,
        rejectionReason: "",
      });
    }
    setNewStage("");
    setModal(false);
  };

  const checkExistingInterview = (candidate, stage) => {
    let type = getInterviewType(candidate, stage);
    //check if candidate has inteview set, otherwise create new
    let currentInterview = false;
    if (candidate.interviews && candidate.interviews.length) {
      currentInterview = candidate.interviews[0];
    }
    if (currentInterview && currentInterview.type === type) {
      return currentInterview;
    }
    return false;
  };

  const setInterviewDate = async (candidate, stage) => {
    let existingInterview = checkExistingInterview(candidate, stage);

    let interviewDate = document.getElementById("interviewDate_input");
    if (interviewDate) {
      interviewDate = interviewDate.value;
    }
    //if has interview and its the same type... (onsite or phone)
    if (existingInterview) {
      let response = await InterviewService.confirm({
        interviewDate: moment(interviewDate.value).toDate(),
        id: existingInterview.interviewId,
      });
      if (!response.error) {
        toast.success("Interview time has been updated", {
          position: "bottom-center",
        });
      } else {
        toast.error(response.error, {
          position: "bottom-center",
        });
      }
      setNewStage("");
      setModal(false);
    } else {
      onCreateInterview(candidate, stage, interviewDate);
    }
  };

  const onCancelTimePresented = (candidateId, stage) => {
    setNewStage("");
    setModal(false);
  };
  /**
   * Show Time Presented Modal
   */
  const onSetTimePresented = candidate => {
    setNewStage("Presented");
    setActiveCandidate(candidate);
    setModal(true);
  };

  /**
   * Set time in database (updates)
   */
  const setTimePresented = async () => {
    let presentedDate = document.getElementById("presentedDate_input");
    if (presentedDate) {
      presentedDate = presentedDate.value;
      let response = await PositionService.updateCandidateStage({
        candidateId: activeCandidate.id,
        positionId: positionId,
        stage: "Presented",
        presentedDate: presentedDate,
      });

      if (!response.error) {
        activeCandidate.presentedDate = presentedDate;
        toast.success("Time presented has been updated", {
          position: "bottom-center",
        });
      } else {
        toast.error(response.error, {
          position: "bottom-center",
        });
      }
    }
    setModal(false);
    setNewStage("");
  };

  const showModal = candidate => {
    setActiveCandidate(candidate);
    setModal(true);
    setNewStage("");
  };

  const ModalInterview = () => {
    return (
      <>
        <p className="title">Create Interview</p>

        {position.manualInterview ? (
          <>
            <p>Choose date &amp; time</p>
            <StyledDateTimePicker>
              <DateTimePicker
                name="interviewDate"
                id="interviewDate"
                defaultValue={new Date()}
              />
            </StyledDateTimePicker>

            <div className="actions">
              <Button
                size="small"
                onClick={() =>
                  onSkipInterviewCreate(activeCandidate.id, newStage)
                }
              >
                Skip
              </Button>

              <Button
                primary
                size="small"
                onClick={() => setInterviewDate(activeCandidate, newStage)}
              >
                Set Interview Time
              </Button>
            </div>
          </>
        ) : (
          <>
            {position.hiringManagers &&
            position.hiringManagers.filter(hm => hm.isPrimary && hm.email)
              .length ? (
              <>
                <p>
                  This will send an email to the hiring manager to set up
                  interview times
                </p>

                <div className="actions">
                  <Button
                    size="small"
                    onClick={() =>
                      onSkipInterviewCreate(activeCandidate.id, newStage)
                    }
                  >
                    Skip
                  </Button>
                  <Button
                    primary
                    size="small"
                    onClick={() => onCreateInterview(activeCandidate, newStage)}
                  >
                    Email Hiring Manager
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p>
                  There are no hiring managers associated with this position
                </p>
                <div className="actions">
                  <Button
                    size="small"
                    onClick={() =>
                      onSkipInterviewCreate(activeCandidate.id, newStage)
                    }
                  >
                    Close
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const ModalTimePresented = () => {
    return (
      <>
        <p className="title">Set Date Presented</p>
        <p>Choose date &amp; time</p>
        <StyledDateTimePicker>
          <DateTimePicker
            name="presentedDate"
            id="presentedDate"
            defaultValue={
              activeCandidate.presentedDate
                ? moment(activeCandidate.presentedDate).toDate()
                : new Date()
            }
          />
        </StyledDateTimePicker>

        <div className="actions">
          <Button
            size="small"
            onClick={() => onCancelTimePresented(activeCandidate.id, newStage)}
          >
            Cancel
          </Button>

          <Button
            primary
            size="small"
            onClick={() => setTimePresented(activeCandidate)}
          >
            Set Time Presented
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Columns className={modal ? "modal" : ""}>
        <DragDropContext onDragEnd={onDragEnd}>
          {stages &&
            stages.map((stage, i) => {
              return (
                <React.Fragment key={stage.name + "-" + i}>
                  {columns[stage.name] ? (
                    <div
                      className={
                        "column" +
                        (columns[stage.name].collapseRejections
                          ? " collapsed"
                          : "")
                      }
                    >
                      <div className="header">
                        <h2>
                          {stage.name === "Rejected" ? "Passed" : stage.name}{" "}
                          {/* Display name changed to Passed */}
                          <span className="count">
                            ({columns[stage.name].candidates.length})
                          </span>
                        </h2>

                        {stage.name === "Rejected" ||
                        stage.name === "Passed" ? (
                          <span
                            className="contract"
                            onClick={() => setCollapseRejections(stage.name)}
                          ></span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="board">
                        <Board
                          key={i}
                          droppableId={stage.name}
                          candidates={columns[stage.name].candidates}
                          onCreateInterview={onCreateInterview}
                          position={position}
                          showModal={showModal}
                          onSetTimePresented={onSetTimePresented}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })}
        </DragDropContext>
        {modal && (
          <StyledModal>
            {(newStage === "Rejected" || newStage === "Passed") && (
              <>
                <p className="title">Reason for Passing?</p>
                <Input className="auto">
                  <Form.Textarea
                    id=""
                    name="rejectionReason"
                    forwardRef={register}
                    value=""
                  />
                </Input>
                <div className="actions">
                  <Button
                    primary
                    size="small"
                    onClick={() => onModalRejection()}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
            {newStage.match(/Telephone|F2F|Onsite/) || newStage === "" ? (
              <ModalInterview />
            ) : (
              ""
            )}
            {newStage === "Presented" ? <ModalTimePresented /> : ""}
          </StyledModal>
        )}
      </Columns>
    </>
  );
};

/*
 * Boards
 */
const Board = ({
  candidates,
  droppableId,
  onCreateInterview,
  onSetTimePresented,
  position,
  showModal,
}) => {
  const getListStyle = isDraggingOver => ({
    /*background: isDraggingOver ? "lightblue" : "lightgrey",
        width: 250*/
    height: "100%",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    backgroundColor: isDragging ? Colors.highlight : "",
    ...draggableStyle,
  });
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          <ul
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {candidates.map((candidate, index) => {
              //interviews set?
              let phoneInterview = false,
                f2fInterview = false;
              if (candidate.interviews && candidate.interviews.length) {
                phoneInterview = candidate.interviews.find(
                  t =>
                    t.type === "phone" && (t.confirmedDate || t.interviewDate)
                );
                if (phoneInterview) {
                  phoneInterview.confirmedDate =
                    phoneInterview.confirmedDate ||
                    phoneInterview.interviewDate; //inconsistend api returns
                }

                f2fInterview = candidate.interviews.find(
                  t =>
                    t.type === "onsite" && (t.confirmedDate || t.interviewDate)
                );
                if (f2fInterview) {
                  f2fInterview.confirmedDate =
                    f2fInterview.confirmedDate || f2fInterview.interviewDate; //inconsistend api returns
                }
              }

              return (
                <Draggable
                  key={candidate.id}
                  draggableId={candidate.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <span className="name">
                        <Link to={"/candidate/" + candidate.id}>
                          {candidate.name.length > 20
                            ? candidate.name.substring(0, 17) + "..."
                            : candidate.name}
                        </Link>
                      </span>
                      <br />
                      {candidate.compensation ? (
                        <>
                          <span className="compensation">
                            {candidate.compensation}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {candidate.visa ? (
                        <>
                          <span className="visa">{candidate.visa}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span
                        className="days"
                        title={candidate.daysInStage + " days in current stage"}
                      >
                        {candidate.daysInStage ? candidate.daysInStage : 0}
                      </span>
                      {droppableId === "Passed"
                        ? candidate.rejectionReason
                        : ""}

                      {droppableId === "F2F" && f2fInterview ? (
                        <>
                          <span className="interview-date">
                            {moment(f2fInterview.confirmedDate).format(
                              "MMM D @ h:mmA"
                            )}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {droppableId === "Telephone" && phoneInterview ? (
                        <>
                          <span className="interview-date">
                            {moment(phoneInterview.confirmedDate).format(
                              "MMM D @ h:mmA"
                            )}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {droppableId === "Presented" ? (
                        <>
                          {candidate.presentedDate ? (
                            <>
                              <span className="interview-date">
                                {moment(candidate.presentedDate).format(
                                  "MMM D @ h:mmA"
                                )}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          <span
                            className="time-presented"
                            onClick={() => onSetTimePresented(candidate)}
                          >
                            Set Time Presented
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {droppableId === "F2F" || droppableId === "Telephone" ? (
                        <>
                          {candidate.presentedDate ? (
                            <>
                              <span className="interview-date">
                                {moment(candidate.presentedDate).format(
                                  "MMM D @ h:mmA"
                                )}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          <span
                            className="time-presented"
                            onClick={() => onSetTimePresented(candidate)}
                          >
                            Set Time Presented
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {droppableId === "F2F" || droppableId === "Telephone" ? (
                        <>
                          {!position.manualInterview ? (
                            <span
                              className="create-interview"
                              onClick={() =>
                                onCreateInterview(candidate, droppableId, false)
                              }
                            >
                              Create Interview
                            </span>
                          ) : (
                            <span
                              className="create-interview"
                              onClick={() => showModal(candidate)}
                            >
                              Schedule Interview
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </li>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        </div>
      )}
    </Droppable>
  );
};
