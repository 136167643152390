import { list } from "./lib";

import Config from "../config/config";

const create = inputData => {
  return fetch(Config.API_URL + "candidates", {
    method: "POST",
    //body: formData,
    body: JSON.stringify({ ...inputData }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const update = inputData => {
  //PATCH so additional data is not sent
  inputData.city = undefined;
  inputData.zip = undefined;
  inputData.country = undefined;
  inputData.state = undefined;

  console.log("service update", inputData);

  return fetch(Config.API_URL + "candidates/" + inputData.id, {
    method: "PUT",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const get = id => {
  return fetch(Config.API_URL + "candidates/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const del = id => {
  return fetch(Config.API_URL + "candidates/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
// EMPLOYMENT
const addEmployment = inputData => {
  return fetch(Config.API_URL + "candidates/" + inputData.id + "/employments", {
    method: "POST",
    body: JSON.stringify({ ...inputData.data }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const updateEmployment = inputData => {
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.id +
      "/employments/" +
      inputData.employmentId,
    {
      method: "PATCH",
      body: JSON.stringify({ ...inputData.data }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteEmployment = inputData => {
  console.log("inputData", inputData);
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.id +
      "/employments/" +
      inputData.employmentId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

// NOTES
const getNotes = inputData => {
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.id +
      "/notes?limit=" +
      inputData.limit +
      "&offset=" +
      inputData.offset,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const getLinkedInNotes = inputData => {
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.id +
      "/notes?" +
      "q=$notes.type$:linkedin", //hack per ricky
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      // console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

const addNote = inputData => {
  console.log("addNote", inputData);
  return fetch(Config.API_URL + "candidates/" + inputData.id + "/notes", {
    method: "POST",
    body: JSON.stringify({ ...inputData, id: undefined }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

// TAGS
const addTag = inputData => {
  console.log("inputData", inputData);
  return fetch(Config.API_URL + "candidates/" + inputData.id + "/tags", {
    method: "POST",
    body: JSON.stringify([inputData.tagId]),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteTag = inputData => {
  console.log("inputData", inputData);
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.entityId +
      "/tags/" +
      inputData.tagId,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

// EDUCATION
const updateEducation = inputData => {
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.candidateId +
      "/education/" +
      inputData.id,
    {
      method: "PUT",
      body: JSON.stringify({ ...inputData.data }), //TODO reove .data once education api is fixed... if ever
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const addEducation = inputData => {
  return fetch(
    Config.API_URL + "candidates/" + inputData.candidateId + "/education",
    {
      method: "POST",
      body: JSON.stringify([{ ...inputData.data }]),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
const deleteEducation = inputData => {
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.candidateId +
      "/education/" +
      inputData.id,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};

// POSITIONS
const getPositionHistory = inputData => {
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.id +
      "/positions_history?limit=" +
      inputData.limit,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      console.log("json", responseJson);
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
// LINKEDIN
const linkedin = url => {
  return fetch(
    Config.API_URL +
      'candidates?q=linkedinUrl:"' +
      url +
      '" OR linkedinUrl:"' +
      url.replace(/\/$/, "") +
      '"',
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
// DOCUMENTS
const associateCandidateDocument = inputData => {
  console.log("inputdata", inputData);
  return fetch(
    Config.API_URL +
      "candidates/" +
      inputData.id +
      "/documents/" +
      inputData.documentId,
    {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      return error;
    });
};
export const CandidateService = {
  create,
  del,
  get,
  list: query => list("candidates", { query }),
  update,

  // EMPLOYMENT
  addEmployment,
  updateEmployment,
  deleteEmployment,

  // NOTES
  getNotes,
  addNote,

  // TAGS
  deleteTag,
  addTag,

  // EDUCATION
  updateEducation,
  addEducation,
  deleteEducation,

  // POSITIONS
  getPositionHistory,

  // LINKEDIN
  linkedin,
  getLinkedInNotes,

  // DOCUMENTS
  associateCandidateDocument,
};
