import React from "react";
import ReactExport from "react-export-excel";
import { Button } from "../../styles/Common";
import { DateTime } from "../../helpers/DateTime";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/* test data
const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true
  }
];

const dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7
  }
];
*/
export const ExportCompanyPositions = ({ company, positions }) => {
  //prepare data
  let data = [];

  positions.forEach(p => {
    data.push({
      title: p.title,
      hiringManagers: p.hiringManagers
        ? p.hiringManagers
            .map((hm, i) => {
              return hm.firstName + " " + hm.lastName;
            })
            .join(",")
        : "",
      owner: p.owner,
      candidates: p.candidates.length,
    });
  });

  return (
    <ExcelFile
      filename={company.name}
      element={<Button size="small">Export</Button>}
    >
      <ExcelSheet data={data} name="Positions">
        <ExcelColumn label="Position" value="title" />
        <ExcelColumn label="Hiring Managers" value="hiringManagers" />
        <ExcelColumn label="Owner" value="owner" />
        <ExcelColumn label="Candidates" value="candidates" />
      </ExcelSheet>
    </ExcelFile>
  );
};
export const ExportPositionCandidates = ({ position, candidates }) => {
  /*
     ExportPositionCandidates is only exported from the position page therefore it will only have one position, but many candidates
  */
  //prepare data
  let data = [];
  let p = position;

  candidates.forEach(c => {
    let daysSinceKickOff =
      p.kickoff && DateTime.timeSince(new Date(p.kickoff), new Date());
    data.push({
      name: c.firstName + " " + c.lastName,
      visa: c.visa,
      compensation: c.compensation,
      stage: c.stage,
      stageDate: DateTime.formatDate(c.updatedAt),
      rejectionReason: c.rejectionReason,
      owner: p.owner,
      primary: p.primary,
      secondary: p.secondary,
      kickoff: p.kickoff && DateTime.formatDate(new Date(p.kickoff)),
      urgency: p.urgency,
      daysSinceKickOff: daysSinceKickOff && daysSinceKickOff,
    });
  });

  return (
    <ExcelFile
      filename={position.title}
      element={<Button size="small">Export</Button>}
    >
      <ExcelSheet data={data} name="Position">
        <ExcelColumn label="Candidate" value="name" />
        <ExcelColumn label="Recruiter Owner" value="owner" />
        <ExcelColumn label="Urgency" value="urgency" />
        <ExcelColumn label="Visa" value="visa" />
        <ExcelColumn label="Compensation" value="compensation" />
        <ExcelColumn label="Kickoff Date" value="kickoff" />
        <ExcelColumn label="Days in process" value="daysSinceKickOff" />
        <ExcelColumn label="Stage" value="stage" />
        <ExcelColumn label="Stage Date" value="stageDate" />
        <ExcelColumn label="Pass Reason" value="rejectionReason" />
        <ExcelColumn label="Recruiter Primary" value="primary" />
        <ExcelColumn label="Recruiter Secondary" value="secondary" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const ExportSearchResults = ({ results }) => {
  console.log("resutsl", results);
  //prepare data
  let data = { candidates: [], positions: [], companies: [] };
  let searchTitle = "Search--";

  results.forEach(r => {
    switch (r.key) {
      case "search":
        searchTitle = searchTitle + r.value.textual;
        break;
      case "candidates":
        r.value.forEach(i => {
          data.candidates.push({
            name: i.firstName + " " + i.lastName,
            email: i.emailPersonal,
            city: i.address ? i.address.city : "",
            state: i.address ? i.address.state : "",
            zip: i.address ? i.address.zip : "",
            country: i.address ? i.address.country : "",
            title: i.currentEmployments ? i.currentEmployments.title : "",
            company: i.currentEmployments ? i.currentEmployments.company : "",
          });
        });
        break;
      case "companies":
        r.value.forEach(i => {
          data.companies.push({
            name: i.name,
          });
        });
        break;
      case "positions":
        r.value.forEach(i => {
          data.positions.push({
            title: i.title,
            company: i.company,
          });
        });
        break;
      default:
        break;
    }
  });

  return (
    <ExcelFile
      filename={searchTitle}
      element={<Button size="small">Export Results</Button>}
    >
      <ExcelSheet data={data.candidates} name="Candidates">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="City" value="city" />
        <ExcelColumn label="State" value="state" />
        <ExcelColumn label="Zip" value="zip" />
        <ExcelColumn label="Country" value="country" />
        <ExcelColumn label="Title" value="title" />
        <ExcelColumn label="Company" value="company" />
      </ExcelSheet>

      <ExcelSheet data={data.companies} name="Companies">
        <ExcelColumn label="Name" value="name" />
      </ExcelSheet>

      <ExcelSheet data={data.positions} name="Positions">
        <ExcelColumn label="Title" value="title" />
        <ExcelColumn label="Company" value="company" />
      </ExcelSheet>
    </ExcelFile>
  );
};
