import { uniqBy as _uniqBy } from "lodash";

export const addToPageHistory = obj => {
  let pageHistory = localStorage.getItem("pageHistory");

  //new array if needed
  if (!pageHistory) {
    pageHistory = [];
  } else {
    pageHistory = JSON.parse(pageHistory);
  }
  pageHistory.unshift(obj);

  //set new item and remove duplicate values
  localStorage.setItem(
    "pageHistory",
    JSON.stringify(_uniqBy(pageHistory, "title"))
  );
};

export const addToSearchHistory = obj => {
  let searchHistory = localStorage.getItem("searchHistory");
  let limit = 20;

  //new array if needed
  if (!searchHistory) {
    searchHistory = [];
  } else {
    searchHistory = JSON.parse(searchHistory);
  }
  searchHistory.unshift(obj);
  //limit # in array
  if (searchHistory.length > limit) {
    searchHistory.pop();
  }

  //set new item and remove duplicate values
  localStorage.setItem(
    "searchHistory",
    JSON.stringify(_uniqBy(searchHistory, "textual"))
  );
};

/*
 * Advanced search Input

*/
export const parseUserInput = (parts, elements) => {
  let indexSkip = 0;
  console.log("PARTS", parts);

  elements.forEach((ie, i) => {
    console.log("i", i);
    //if already added to prev part... skip
    if (i < indexSkip) {
      return;
    }

    let pc = 0;
    let po = 0;
    let buildBack = [];
    let buildForward = [];
    let part = {
      start: 0,
      operator: ie.value,
      pieces: [],
      build: [], //junk really
      children: [],
      end: 0,
      splits: [],
    };
    /* AND | OR:
     * find opening paren... or beginning of line
     * if no parens found, use beginning of line for build
     * if close paren found, but opening is never > closeing, start build rigt
     */
    if (ie.type === "operator" && ie.value.match(/^AND$|^OR$/)) {
      //BACKWARDS traverse, will also find first piece of clause
      for (let a = i - 1; a >= indexSkip; a--) {
        //find paren boundaries OR beginning of clause
        if (
          elements[a].type === "parenthesis" &&
          elements[a].subtype === "close"
        ) {
          pc++;
        } else if (
          elements[a].type === "parenthesis" &&
          elements[a].subtype === "open"
        ) {
          po++;
        }
        //found our start if paren open is > paren closed
        console.log("type", elements[a].type);
        console.log("a", a);
        //needs to be split further?
        if (elements[a].type === "operator") {
          console.log("SHOULD NEVER REACH HERE");
          part.splits.push(a); //i imagine should never get to this spot
        }
        buildBack.unshift(elements[a]);
        if (po > pc || a === indexSkip) {
          console.log("back piece", buildBack);
          part.pieces.push(buildBack.find(bf => bf.type === "tag"));
          part.start = a;
          break;
        }
        //capture part
      }
      //include current operator
      buildBack.push(ie);

      //FORWARD traverse - find other piece, or JuNK to send thru again
      for (let a = i + 1; a <= elements.length - 1; a++) {
        //find paren boundaries OR end of clause
        if (
          elements[a].type === "parenthesis" &&
          elements[a].subtype === "close"
        ) {
          pc++;
        }
        if (
          elements[a].type === "parenthesis" &&
          elements[a].subtype === "open"
        ) {
          po++;
        }
        //another operator found
        if (elements[a].type === "operator") {
          part.splits.push(a);

          //parseParts = (ie, level, i, indexSkip, elements);
        }
        //add parts
        buildForward.push(elements[a]);

        if (pc >= po || elements.length - 1 === a) {
          if (part.splits.length) {
            //cycle thru again
            console.log("SPLIT", part.splits);
            //send entire forward thru again
            part.children = parseUserInput(
              part.children,
              buildForward.slice(0, part.splits[0])
            );
          } else {
            part.pieces.push(buildForward.find(bf => bf.type === "tag"));
          }
          part.end = a;
          indexSkip = a + 1;
          break;
        }
      }
      console.log("pieces", part.pieces);
      part.build = buildBack.concat(buildForward);

      //indexSkip = part.end + 1;

      console.log(part);

      parts.push(part);
    }
    /*
     * NOT:
     * only move forward
     */
    if (ie.type === "operator" && ie.value === "NOT") {
      part.start = i;
      //FORWARD traverse - find other piece, or JuNK to send thru again
      for (let a = i + 1; a <= elements.length - 1; a++) {
        //find paren boundaries OR end of clause
        if (
          elements[a].type === "parenthesis" &&
          elements[a].subtype === "close"
        ) {
          pc++;
        }
        if (
          elements[a].type === "parenthesis" &&
          elements[a].subtype === "open"
        ) {
          po++;
        }
        //another operator found
        if (elements[a].type === "operator") {
          part.splits.push(a);
        }
        //add parts
        buildForward.push(elements[a]);

        if (pc >= po || elements.length - 1 === a) {
          if (part.splits.length) {
            //cycle thru again
            console.log("SPLIT", part.splits);
            console.log("i", i);
            console.log("child", part.splits[0]);
            //send entire forward thru again
            part.children = parseUserInput(
              part.children,
              buildForward.slice(0, part.splits[0])
            );
          } else {
            part.pieces.push(buildForward.find(bf => bf.type === "tag"));
          }
          part.end = a;
          indexSkip = a + 1;
          break;
        }
      }
      console.log("pieces", part.pieces);
      part.build = buildBack.concat(buildForward);

      //indexSkip = part.end + 1;

      console.log(part);

      parts.push(part);
    }
  });

  return parts;
};

export const convertPartsToJSON = parts => {
  //build JSON
  let build = '{"search":{"where":[';
  build += partBuild(parts);
  build += "]}}";

  build = build
    .replace(/\},\]/g, "}]") //remove extra comma
    .replace(/\},\}/g, "}}"); //remove extra comma
  console.log("jsoStringBuild", build);

  try {
    return JSON.parse(build);
  } catch (error) {
    return false;
  }
};

const partBuild = parts => {
  let build = "";
  parts.forEach(part => {
    if (part.pieces.length) {
      //operator open
      build += '{"' + part.operator + '":[';
      //pieces for this operator
      part.pieces.forEach(p => {
        build += '{"' + p.subtype + '":"' + p.value + '"},';
      });
      //children //loop thru again
      if (part.children.length) {
        build += partBuild(part.children);
      }
      build += "]},";
    }
  });

  return build;
};

export const searchInputValidation = elements => {
  //reset
  let errors = false;

  //declare vars
  let openParens = 0,
    closedParens = 0;

  //loop input Elements
  let numElements = elements.length;
  elements.forEach((ie, i) => {
    ie.error = false;
    // skip empty input
    if (ie.type === "empty" || errors) {
      return;
    }
    if (i === 1 && ie.type === "operator" && ie.value.match(/^AND$|^OR$/)) {
      errors = "Search must cannot begin with AND or OR";
    }
    //first item cannot be closing paren
    else if (i === 1 && ie.value === ")") {
      errors = "Search cannot begin with closing parenthesis";
    }
    //no AND or OR right after open paren
    else if (
      i > 2 &&
      elements[i - 2].type === "parenthesis" &&
      elements[i - 2].subtype === "open" &&
      ie.value.match(/^AND$|^OR$/)
    ) {
      errors = "AND / OR operator cannot come after opening parenthesis";
      ie.error = true;
    }
    //no consecutive oerators
    else if (
      i > 2 &&
      elements[i - 2].type === "operator" &&
      ie.type === "operator"
    ) {
      errors = "AND / OR / NOT operators cannot be consecutive";
      ie.error = true;
    }
    //cannot end in operator
    else if (
      i === numElements - 2 &&
      (elements[i].subtype === "open" || ie.value.match(/^AND$|^OR$|^NOT$/))
    ) {
      errors = "Search ends w/ AND/OR/NOT or open parenthesis";
      ie.error = true;
    }
    // ) ( paren structure not allowed
    else if (
      i > 2 &&
      ie.type === "parenthesis" &&
      elements[i - 2].type === "parenthesis" &&
      ie.subtype === "open" &&
      elements[i - 2].subtype === "close"
    ) {
      errors = "Closing parenthesis cannot be followed by opening parenthesis";
      ie.error = true;
    }
    // ( ) paren structure not allowed
    else if (
      i > 2 &&
      ie.subtype === "close" &&
      elements[i - 2].subtype === "open"
    ) {
      errors = "Empty parenthesis";
      ie.error = true;
    }
    //terms cannot be back to back
    else if (i > 2 && ie.type === "tag" && elements[i - 2].type === "tag") {
      errors = "Search terms should have an AND / OR operator seperating them";
      ie.error = true;
    }
    //terms cannot follow closed parens
    else if (
      i > 2 &&
      ie.type === "tag" &&
      elements[i - 2].type === "parenthesis" &&
      elements[i - 2].subtype === "close"
    ) {
      errors = "Search terms should not follow a closing parenthesis";
      ie.error = true;
    }
    //terms cannot be followed by open parens
    else if (
      i > 2 &&
      ie.type === "parenthesis" &&
      ie.subtype === "open" &&
      elements[i - 2].type === "tag"
    ) {
      errors = "Opening parenthesis should not come after a search term";
      ie.error = true;
    }
    //operators cannot be followed by closed parens
    else if (
      i > 2 &&
      ie.type === "parenthesis" &&
      ie.subtype === "close" &&
      elements[i - 2].type === "operator"
    ) {
      errors =
        "AND / OR / NOT operators should not be followed by a closing parenthesis";
      ie.error = true;
    }

    //count parens
    if (ie.type === "parenthesis") {
      if (ie.subtype === "open") {
        openParens++;
      } else {
        closedParens++;
      }
      //at no time should there be more closed than open
      if (closedParens > openParens) {
        errors = "There are prematurely closed parenthesis";
        ie.error = true;
      }
    }
  });

  if (!errors) {
    //parens match?
    if (openParens !== closedParens) {
      errors =
        openParens > closedParens
          ? "Make sure to close all open parenthesis"
          : "There are more closing parenthesis than open";
    }
  }

  return errors;
};

//user input editable area
export const emptyInput = focus => {
  return {
    type: "empty",
    subtype: "",
    value: "",
    key: Math.random(),
    focus: focus,
    error: false,
  };
};
export const isOperator = value => value.match(/^AND$|^OR$|^NOT$/i);
export const isParenthesis = value => value.match(/^\($|^\)$/);

export const convertTextualToInput = display => {
  let _inputElements = [];

  display.forEach(d => {
    let subtype, type, operator, value;
    if (typeof d === "object") {
      subtype = Object.keys(d)[0];
      type = "tag";
      value = d[subtype];
    } else {
      operator = isOperator(d);
      subtype = operator ? d.toLowerCase() : d === ")" ? "close" : "open";
      type = isOperator(d) ? "operator" : "parenthesis";
      value = d;
    }

    _inputElements.push(emptyInput(false), {
      type: type,
      subtype: subtype,
      value: value,
      key: Math.random(),
      focus: false,
      error: false,
    });
  });
  _inputElements.push(emptyInput(false));

  return _inputElements;
};

/*
interface FieldFilter {
  field: string;
  value: string;
}

interface OpAndFilter {
  AND: (
    | FieldFilter
    | OpOrFilter
    | OpNotFilter
  )[];
}

interface OpOrFilter {
  OR: (
    | FieldFilter
    | OpAndFilter
    | OpNotFilter
  )[];
}

interface OpNotFilter {
  NOT: 
    | FieldFilter
    | OpAndFilter
    | OpOrFilter;
}

interface Filter {
  search: 
    | FieldFilter
    | OpAndFilter
    | OpOrFilter
    | OpNotFilter
    | string // Just use what was supplied, don't try to interrpet it as a smart data structre.
}
*/

function processOperation(
  operation /*:
  | FieldFilter
  | OpAndFilter
  | OpOrFilter */
) /*:string */ {
  if ("field" in operation) {
    return `${operation.field}:"${operation.value}"`; // Safer to always quote.
  } else if ("NOT" in operation) {
    return `NOT ( ${processOperation(operation.NOT)} )`;
  } else {
    return Object.entries(operation)
      .map(
        ([operator, values]) =>
          "(" +
          values
            .map(childOperation => processOperation(childOperation))
            .join(` ${operator} `) +
          ")"
      )
      .join(" OR "); // In case someone decides that it's funny to have multiple operators in parallel.
  }
}

/**
 * Build query for list tables
 * @param {object} search
 * @return {string} searchBuild
 */
export function filterTable(filter /*: Filter */) {
  //build search query if set in filter
  let searchBuild = "";

  if (filter && filter.search) {
    if (typeof filter.search === "string") {
      searchBuild = filter.search;
    } else {
      searchBuild = processOperation(filter.search);
    }
  }

  return searchBuild;
}
