import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { AuthService } from "../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import device from "../../config/device";
import { camelCase as _camelCase } from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import Select from "react-select";
import SanitizedHTML from "react-sanitized-html";
import { CandidateService } from "../../services/Candidate";
import { DateTime } from "../../helpers/DateTime";
import { DocumentService } from "../../services/Document";
import { TitleService } from "../../services/Title";
import { CompanyService } from "../../services/Company";
import { PositionService } from "../../services/Position";
import { DegreeService } from "../../services/Degree";
import { InterviewService } from "../../services/Interview";
import { Activities } from "../Shared/DetailActivities";
import { DocumentUpload } from "../Shared/DocumentUpload";
import { ProfileImage } from "../Shared/ProfileImage";
import { Tags } from "../Shared/Tags";
import { compensationBuild, visaBuild } from "../Shared/Data";
import { UpdatableEntity } from "../Shared/UpdatableEntity";
import { UpdateableInfo } from "../Shared/UpdateableInfo";
import { ParsedData } from "../Shared/ParsedData";
import { parseLinkedInData } from "../../helpers/Parser";
import { Colors } from "../../styles/Colors";
import { addToPageHistory } from "../../helpers/Search";
import {
  formatAddress,
  formatCompensation,
  formatUUID,
} from "../../helpers/Display";
import { LoadingSubmit } from "../../styles/Animation";

//import { Tags as StyledTags } from "../../styles/Form";
import {
  Container,
  Button,
  Card,
  ButtonDropdown,
  Editable,
  Table,
  MediaTable,
} from "../../styles/Common";
import { Form } from "../../components/Shared/Form";
import { Dropdown } from "../../components/Shared/Common";
import styled from "styled-components";
import imgChevron from "../../assets/chevron.svg";
import imgDocument from "../../assets/document.svg";
import imgLinkedIn from "../../assets/linkedin.svg";
import imgPhone from "../../assets/phone.svg";
import imgMail from "../../assets/mail.svg";
import imgCircleCheck from "../../assets/circle-check.svg";
import imgSend from "../../assets/send.svg";
import imgCopy from "../../assets/copy.svg";
import { toast } from "react-toastify";

const Styled = styled.div`
  display: flex;
  &:before {
    content: "";
    display: block;
    height: 256px;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background-image: linear-gradient(0deg, #5f6a7b 0%, #515b69 100%);
    box-shadow: 2px 2px 20px 0 rgba(81, 91, 105, 0.7);
    background: ${Colors.primary};
  }
  @media ${device.laptopLMax} {
    &:before {
      height: auto;
    }
  }

  .main {
    margin-top: 15px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 33% 1fr;
    grid-gap: 15px;
    align-items: flex-start;

    @media ${device.tabletLMax} {
      display: block;
    }
    .col-1 {
      grid-area: 1 / 1 / 2 / 2;
      display: grid;
      grid-row-gap: 15px;
      @media ${device.laptopMax} {
        margin-bottom: 15px;
      }
    }
    .col-2 {
      display: grid;
      grid-row-gap: 15px;
      grid-area: 1 / 2 / 2 / 3;
      @media ${device.laptopMax} {
        .table {
          ${MediaTable}
        }
      }
    }
  }
`;

const Header = styled.header`
  color: ${Colors.gray};
  display: grid;
  min-height: 245px;
  margin-bottom: 30px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "top top top"
    "img name recruiter"
    "img position resources"
    "img address resources";

  @media ${device.laptopLMax} {
    height: auto;
    background: ${Colors.primary};
    margin: 0 -1rem;
    padding: 0 1rem 30px 1rem;
  }

  .back {
    grid-area: top;
    font-weight: 300;
    font-size: 1.143rem;
    height: 51px;
    padding: 11px 0 0;
    a {
      color: ${Colors.gray};
      vertical-align: middle;
    }
    a:before {
      content: "";
      vertical-align: middle;
      width: 14px;
      height: 17px;
      margin-right: 5px;
      vertical-align: middle;
      display: inline-block;
      background: url(${imgChevron}) no-repeat;
      background-size: cover;
      transform: rotate(90deg);
    }
  }

  .candidate {
    grid-area: name;
    height: 26px;
  }
  .status {
    display: inline-block;
    height: 20px;
    padding-top: 13px;
    vertical-align: middle;
    span {
      font-size: 0.8571rem;
      display: block;
      height: 100%;
      box-sizing: border-box;
      background-color: ${Colors.gray};
      color: ${Colors.primary};
      padding: 0 15px 0 15px;
      border-radius: 10px;
    }
  }
  h1 {
    font-weight: 300;
    font-size: 2.857rem;
    margin-top: 12px;
    margin-bottom: 12px;
    vertical-align: middle;
    margin-right: 15px;
    display: inline-block;
    white-space: nowrap;
  }

  h2 {
    grid-area: position;
    font-weight: 500;
    font-size: 1.143rem;
    margin-top: 17px;
    span {
      font-weight: 300;
    }
    a {
      color: ${Colors.gray};
    }
  }
  .address {
    grid-area: address;
    font-weight: bold;
    font-size: 1.286rem;
    p {
      margin-top: 12px;
      display: inline-block;
      margin-right: 30px;
    }
    time {
      font-weight: 400;
      color: #a4acb9;
    }
    a {
      color: ${Colors.gray};
    }
  }
  @media ${device.tabletLMax} {
    h1 {
      font-size: 2rem;
    }
    .address {
      font-size: 1rem;
    }
  }

  #resources {
    grid-area: resources;
    text-align: right;
    color: #a4acb9;
    align-self: end;
    font-weight: 600;
    text-align: center;
    ul {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 100;
      ul {
        display: block;
        position: absolute;
        bottom: -75px;
        right: -15px;
        border-radius: 3px;
        background: #fff;
        padding: 0 15px;
        box-shadow: 2px 12px 25px 0 rgba(81, 91, 105, 0.3);
        border: 1px solid ${Colors.gray_accent};
      }
    }
    li {
      position: relative;
      margin-right: 30px;
      &:last-child {
        margin-right: 15px;
      }
      li {
        display: block;
        text-align: right;
        border-bottom: 1px solid ${Colors.gray_accent};
        margin: 0 !important;
        padding: 5px 0 7px 0;
        &:last-child {
          border-bottom: 0;
        }
        a,
        span {
          display: flex;
          flex-direction: row;
          height: auto;
          font-weight: normal;
          cursor: pointer;
          align-items: center;
          justify-content: flex-end;
          color: ${Colors.primary};
          &:after {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            background: no-repeat;
            background-size: contain;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        &.send {
          a:after {
            background-image: url(${imgSend});
          }
        }
        &.copy {
          span:after {
            background-image: url(${imgCopy});
          }
        }
      }
    }
    li {
      a:before,
      span:before {
        content: "";
        display: block;
        background: no-repeat;
        background-size: contain;
        margin: 0 auto 5px;
      }
      & > a,
      & > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: #a4acb9;
        height: 44px;
      }
      &.email {
        span {
          cursor: default;
        }
        & > a:before,
        & > span:before {
          width: 20px;
          height: 16px;
          background-image: url(${imgMail});
        }
        > span:after {
          content: "";
          width: 8px;
          height: 9px;
          position: absolute;
          bottom: 7px;
          right: -13px;
          background: url(${imgChevron}) no-repeat;
          background-size: cover;
        }
      }
      &.resume {
        a:before {
          width: 16px;
          height: 20px;
          background-image: url(${imgDocument});
        }
      }
      &.linkedin {
        a:before {
          width: 20px;
          height: 19px;
          background-image: url(${imgLinkedIn});
        }
      }
      &.phone {
        a:before {
          width: 21px;
          height: 21px;
          background-image: url(${imgPhone});
        }
      }
    }
  }
  .recruiter {
    color: #a4acb9;
    grid-area: recruiter;
    text-align: right;
    align-self: end;
    .name {
      font-weight: 600;
    }
  }

  @media ${device.laptopMax} {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
      "top top"
      "img name"
      "img position"
      "img address"
      "resources resources";
    .recruiter {
      display: none;
    }
    #resources {
      margin: 20px 0 0 auto;
    }
  }
  @media ${device.tabletMMax} {
    .status {
      display: none;
    }
    .address time {
      margin-top: 5px;
      display: block;
    }
  }
`;

const CurrentStage = styled(Card)`
  margin-top: 25px;
  font-size: 0.8571rem;
  font-weight: 600;
  padding: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  height: 41px;
  align-items: center;
  grid-column-gap: 30px;
  h2 {
    margin-left: 7px;
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
  }
  ol {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-items: center;
    background: ${Colors.gray};
    border-radius: 20.5px;
    height: 100%;
    position: relative;
    border: 1px solid ${Colors.gray_accent};
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      height: calc(100% - 6px);
      width: calc(100% - 6px);
      top: 3px;
      left: 3px;
      background: ${Colors.highlight};
      background: linear-gradient(to bottom right, #3fb509, #82ce36);
      grid-area: 1 / 1 / 2 / ${props => props.currentStageColumn};
      z-index: 1;
      border-radius: 20.5px;
    }
  }
  li {
    position: relative;
    z-index: 10;
    text-align: center;
    time {
      font-size: 0.9em;
      font-style: italic;
      margin-left: 5px;
      color: ${Colors.primary};
    }
  }
  li.recruited:before {
    content: "";
    width: 17px;
    height: 17px;
    display: inline-block;
    background: url(${imgCircleCheck}) no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin: 0 8px 0 0;
  }

  @media ${device.laptopMax} {
    ol {
      grid-template-columns: repeat(
        ${props =>
          props.currentStageColumn === 2 || props.currentStageColumn === 6
            ? "2"
            : "3"},
        1fr
      );
      &:before {
        grid-area: 1 / 1 / 2 /
          ${props => (props.currentStageColumn === 2 ? "2" : "3")};
      }
    }
    li {
      display: none;
    }
    li.show {
      display: block;
    }
  }
  @media ${device.tabletSMax} {
    ol {
      grid-template-columns: auto;
      &:before {
        grid-area: 1 / 1 / 2 / 2;
      }
    }
    li.show {
      display: none;
    }
    li.active {
      display: block;
    }
  }
`;

const StyledPositionsAvailable = styled(Card)`
  position: relative;
  .dropdown-positions {
    input {
      width: 100%;
      box-sizing: border-box;
      height: 34px;
    }
    li {
      cursor: default;
      display: flex;
      white-space: nowrap;
      align-items: center;
      .title {
        margin-right: 20px;
      }
    }
  }
`;

const StyledAdditionalInfo = styled(Card)`
  .type {
    font-weight: 400;
    font-size: 0.9em;
  }
  .title {
    font-weight: 700;
  }
  li {
    padding: 10px 0 0;
  }
`;
const StyledEducation = styled(Card)`
  ul {
    margin: 0;
    &:last-child {
      margin-bottom: 11px;
    }
  }
  li {
    position: relative;
    min-height: 34px;
    display: flex;
    border-bottom: 1px solid;
    font-weight: 14px;
    border-bottom: 1px solid #d8d8d8;
    justify-content: space-between;
    padding: 5px 0;
    box-sizing: border-box;
    &:last-child {
      border-bottom: none;
    }
  }
  ${Editable}
  .school,
  .major {
    display: block;
  }
  time,
  .school-major {
    display: flex;
    align-items: center;
    text-align: right;
  }
  .school-major {
    text-align: left;
    input {
      text-align: left;
    }
  }
  .saving {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding-top: 10px;
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: center;
  }
`;

const PlacementHistory = styled(Card)`
  margin-bottom: 15px;
`;
const StyledOfferHistory = styled(Card)`
  margin-bottom: 15px;
`;

const PotentialPlacement = styled(Card)`
  color: ${Colors.gray};
  background: #8792a3;
  margin-bottom: 15px;
  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-items: end;
  }

  dl {
    grid-area: 1/ 1 / 2 / 3;
  }
  dt,
  dd {
    display: block;
    a {
      color: ${Colors.gray};
    }
  }
  dt.position,
  dt.company {
    display: none;
  }
  dt.presented {
    font-size: 0.857rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  dd.presented {
    font-size: 1.286rem;
    font-weight: 700;
    color: ${Colors.gray_accent};
    margin-bottom: 10px;
  }
  dd.position {
    font-size: 1.143rem;
    margin-bottom: 5px;
  }
  dd.company {
    font-weight: 0.857rem;
    margin-bottom: 15px;
  }
  .interview-onsite,
  .interview-telephone {
    font-weight: 600;
    p {
      margin-bottom: 5px;
    }
  }
  .interview-onsite {
    grid-area: 1 / 3 / 2 / 4;
  }
  .interview-telephone {
    grid-area: 1 / 2 / 2 / 3;
  }

  @media ${device.laptopMax} {
    .content {
      display: block;
    }
    dl,
    .interview-onsite,
    .interview-telephone {
      display: block;
      padding: 10px 0;
    }
  }
`;

export default function CandidateDetail(props) {
  const [isLoggedIn, setIsLoggedIn] = useStore("isLoggedIn", false);
  const jwtInfo = AuthService.jwtInfo();
  console.log("jwt", jwtInfo);

  console.log("props history", props.history);

  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
  } = useForm();

  //get candidate / possible position
  const [candidate, setCandidate] = useState(null);
  const [position, setPosition] = useState(null);

  // CANDIDATE ********************************

  const prepareCandidate = async () => {
    let _candidate = await CandidateService.get(props.match.params.id);

    console.log("candidate a", _candidate);
    if (
      _candidate.error ||
      (_candidate.message && _candidate.message === "Failed validation")
    ) {
      props.history.push("/candidates");
    } else {
      //save into search history
      addToPageHistory({
        title: _candidate.firstName + " " + _candidate.lastName,
        info: _candidate.currentStage ? "In process" : "Available",
        url: "/candidate/" + _candidate.id,
      });
      _candidate.id = props.match.params.id;

      //get status (currently returns position furthest along)
      if (_candidate.statuses) {
        let _cs = _candidate.statuses.find(s =>
          s.stage.match(
            /Telephone|OnSite|F2F|Offer Conversations|Reference Checking/
          )
        );
        let _cs2 = _candidate.statuses.find(s => s.stage.match(/Presented/));
        let _cs3 = _candidate.statuses.find(s =>
          s.stage.match(/Short List|Possible|Qualified/)
        );
        let _cs4 = _candidate.statuses.find(s =>
          s.stage.match(/Rejected|Passed|Offer/)
        );
        if (_cs) {
          _candidate.currentStage = 3;
          setPosition(_cs);
        } else if (_cs2) {
          _candidate.currentStage = 2;
          setPosition(_cs2);
        } else if (_cs3) {
          _candidate.currentStage = 1;
          setPosition(_cs3);
        } else {
          _candidate.currentStage = 0;
        }
      }

      //resume?
      _candidate.resume = _candidate.documents
        ? _candidate.documents.find(d => d.documentType.match(/resume/i))
        : "";

      //mix in editable education var
      if (_candidate.education) {
        _candidate.education = _candidate.education.map(e => {
          e.editEndYear = false;
          e.editDegreeType = false;
          e.editName = false;
          e.saving = false;
          return e;
        });
      }
      //address... turn it flat for consistent updating purposes
      /*if (_candidate.address) {
      Object.entries(_candidate.address).forEach(obj => {
        _candidate[obj[0]] = obj[1];
      });
    } */

      //just an update to linked in notes
      _candidate.updateNotes = 0;

      //tags
      _candidate.skills = _candidate.skills || [];
      //education
      _candidate.education = _candidate.education || [];

      //employments array... take only one
      if (
        _candidate.currentEmployments &&
        _candidate.currentEmployments.length
      ) {
        _candidate.currentEmployments = _candidate.currentEmployments[0];
      }

      _candidate.compensationFormatted = _candidate.compensation
        ? formatCompensation(_candidate.compensation, "long")
        : "";

      //so it wont break layout
      _candidate.linkedinShortened = _candidate.linkedinUrl
        ? _candidate.linkedinUrl
            .replace(/https?:\/\/www\.linkedin\.com\/in/, "")
            .replace(/\/$/, "")
        : "";
      console.log("candidate b", _candidate);
      setCandidate(_candidate);

      //LINKEDIN CHECK **********************************
      let q = window.location.href;
      console.log("q", q);
      let linkedinUrl = false;
      let matches = q.match(/linkedin/i);
      if (matches) {
        if (_candidate.linkedinUrl) {
          let url = _candidate.linkedinUrl;
          getLinkedInData(url);
        }
      }
    }
  };
  const [parserModal, setParserModal] = useState(false);
  const [parseMedium, setParseMedium] = useState("");
  const [parsedData, setParsedData] = useState(null);
  const getLinkedInData = async url => {
    setParserModal(true);
    setParseMedium("linkedin");
    let response = await DocumentService.linkedIn({ url: url });
    if (!response.error) {
      setParsedData(parseLinkedInData(response));
    } else {
      toast.error(response.error, {
        position: "top-center",
      });
      setParserModal(false);
    }
  };

  useEffect(() => {
    prepareCandidate();
  }, []);

  //email resource click
  const [openEmailOptions, setOpenEmailOptions] = useState(false);
  const handleEmailOpen = () => {
    if (!openEmailOptions) {
      document.addEventListener("click", removeEmailOpen);
      setOpenEmailOptions(true);
    }
  };
  const removeEmailOpen = () => {
    document.removeEventListener("click", removeEmailOpen);
    setOpenEmailOptions(false);
  };

  //Placement History
  const [placementHistory, setPlacementHistory] = useState(false);

  //Set Interview and redirect to send email to hiring manager
  const onScheduleInterview = async type => {
    let response = await InterviewService.create({
      candidateId: candidate.id,
      positionId: position.positionId,
      type: type,
    });

    if (response._embedded) {
      //get user to send email page
      //TODO: currently the server has emails being automatically sent to hiring manager
      toast.success(
        "Interview has been created and hiring manager has been notified to select scheduled times",
        {
          position: "bottom-center",
        }
      );
    } else {
      toast.error("There was an error creating the interview", {
        position: "bottom-center",
      });
    }
  };

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  console.log("history", props.history);

  return (
    <>
      <Styled>
        {candidate && (
          <Container>
            <section>
              <Header>
                <div className="candidate">
                  <h1>
                    {candidate.firstName} {candidate.lastName}
                  </h1>
                  {candidate.currentStage ? (
                    <p className="status">
                      <span>In Process</span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                <p className="back">
                  <a href="#" onClick={() => props.history.goBack()}>
                    Back
                  </a>
                </p>

                <ProfileImage
                  detail={{
                    type: "candidates",
                    uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                  }}
                  entity={candidate}
                  setEntity={setCandidate}
                />

                {candidate.currentEmployments &&
                Object.entries(candidate.currentEmployments).length ? (
                  <h2>
                    {candidate.currentEmployments.title + " "}
                    {candidate.currentEmployments.companyName && (
                      <span>
                        at{" "}
                        {candidate.currentEmployments.companyId ? (
                          <Link
                            to={
                              "/client/" +
                              candidate.currentEmployments.companyId
                            }
                          >
                            {candidate.currentEmployments.companyName}
                          </Link>
                        ) : (
                          <>{candidate.currentEmployments.companyName}</>
                        )}
                      </span>
                    )}
                  </h2>
                ) : (
                  ""
                )}
                {position?.ownerInfo ? (
                  <p className="recruiter">
                    Recruiter:{" "}
                    <span className="name">
                      {position.ownerInfo.firstName || ""}{" "}
                      {position.ownerInfo.lastName || ""}
                    </span>
                  </p>
                ) : (
                  ""
                )}

                {candidate.city
                  ? formatAddress(
                      candidate.city,
                      candidate.state,
                      candidate.country,
                      false, //TODO: display time based on geo data from DB
                      true //link to maps
                    )
                  : ""}

                <address id="resources">
                  <ul>
                    {candidate.resume && (
                      <li className="resume">
                        <a
                          href="#"
                          onClick={() =>
                            DocumentService.getUrl(candidate.resume.documentId)
                          }
                        >
                          Resume
                        </a>
                      </li>
                    )}
                    {candidate.linkedinUrl && (
                      <li className="linkedin">
                        <a
                          href={candidate.linkedinUrl}
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          LinkedIn
                        </a>
                      </li>
                    )}
                    {candidate.phone && (
                      <li className="phone">
                        <a
                          href={
                            "tel:" + candidate.phone.replace(/[()-\s]+/g, "")
                          }
                        >
                          {candidate.phone}
                        </a>
                      </li>
                    )}
                    {candidate.emailPersonal && (
                      <li className="email" onClick={() => handleEmailOpen()}>
                        <span>Email</span>
                        {openEmailOptions && (
                          <ul>
                            <li className="send">
                              <a
                                href={
                                  "mailto:" +
                                  (candidate.emailPersonal
                                    ? candidate.emailPersonal
                                    : candidate.emailWork)
                                }
                              >
                                Send Email
                              </a>
                            </li>
                            <li
                              className="copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  candidate.emailPersonal
                                );
                                toast.success("Email address copied");
                              }}
                            >
                              <span id="email-address">
                                {candidate.emailPersonal}
                              </span>
                            </li>
                          </ul>
                        )}
                      </li>
                    )}
                  </ul>
                </address>
              </Header>

              {position && candidate.currentStage ? (
                <CurrentStage currentStageColumn={candidate.currentStage + 1}>
                  <h2>
                    <span>Current Stage</span>
                  </h2>
                  <ol className={"stage-" + candidate.currentStage}>
                    <li
                      className={
                        "recruited" +
                        ([1, 2].includes(candidate.currentStage)
                          ? " show"
                          : "") +
                        (candidate.currentStage === 1 ? " active" : "")
                      }
                    >
                      Being Recruited
                      {candidate.currentStage === 1 ? (
                        <time>{DateTime.formatDate(position.updatedAt)}</time>
                      ) : (
                        ""
                      )}
                    </li>
                    <li
                      className={
                        ([1, 2, 3].includes(candidate.currentStage)
                          ? " show"
                          : "") +
                        (candidate.currentStage === 2 ? " active" : "")
                      }
                    >
                      Presented
                      {candidate.currentStage === 2 ? (
                        <time>{DateTime.formatDate(position.updatedAt)}</time>
                      ) : (
                        ""
                      )}
                    </li>
                    <li
                      className={
                        ([2, 3, 4].includes(candidate.currentStage)
                          ? " show"
                          : "") +
                        (candidate.currentStage === 3 ? " active" : "")
                      }
                    >
                      Interview Process
                      {candidate.currentStage === 3 ? (
                        <time>{DateTime.formatDate(position.updatedAt)}</time>
                      ) : (
                        ""
                      )}
                    </li>
                  </ol>
                </CurrentStage>
              ) : (
                ""
              )}

              <div className="main">
                <div className="col-1">
                  <Tags
                    entity={candidate}
                    setEntity={setCandidate}
                    Service={CandidateService}
                    detail={{
                      type: "skill",
                      typePlural: "skills",
                      typeDisplay: "skills",
                      title: "Skills",
                    }}
                  />

                  <PositionsAvailable
                    candidate={candidate}
                    setCandidate={setCandidate}
                    position={position}
                    setPosition={setPosition}
                    CandidateService={CandidateService}
                  />

                  <UpdatableEntity
                    entity={candidate}
                    setEntity={setCandidate}
                    Service={CandidateService}
                    detail={{
                      title: "Bio",
                      key: "bio",
                      expandable: true,
                    }}
                  />

                  <Education
                    candidate={candidate}
                    setCandidate={setCandidate}
                  />

                  <Info candidate={candidate} setCandidate={setCandidate} />

                  <CurrentPosition
                    candidate={candidate}
                    setCandidate={setCandidate}
                  />
                </div>
                <div className="col-2">
                  <Activities
                    detail={{ type: "Candidate" }}
                    entity={candidate}
                    Service={CandidateService}
                  />

                  {position && candidate.currentStage ? (
                    <PotentialPlacement>
                      <h1>In Process</h1>

                      <div className="content">
                        <dl>
                          <dt className="position">Position</dt>
                          <dd className="position">
                            <Link to={"/position/" + position.positionId}>
                              {position.title} {formatUUID(position.positionId)}
                            </Link>
                          </dd>
                          <dt className="company">Company</dt>
                          <dd className="company">
                            <Link to={"/client/" + position.companyId}>
                              {position.company}
                            </Link>
                          </dd>
                          <dt className="presented">{position.stage}</dt>
                          <dd className="presented">
                            <time>
                              {DateTime.formatDate(position.updatedAt)}
                            </time>
                          </dd>
                        </dl>

                        {position.stage.match(/F2F|Telephone/) ? (
                          <>
                            <div className="interview-telephone">
                              <p>Telephone Interview</p>
                              <Button
                                type="alt"
                                size="small"
                                onClick={() => onScheduleInterview("phone")}
                              >
                                Schedule
                              </Button>
                            </div>
                            <div className="interview-onsite">
                              <p>Onsite Interview</p>
                              <Button
                                type="alt"
                                size="small"
                                onClick={() => onScheduleInterview("onsite")}
                              >
                                Schedule
                              </Button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </PotentialPlacement>
                  ) : (
                    ""
                  )}

                  {placementHistory && (
                    <PlacementHistory className="card">
                      <h1>Placement History</h1>
                      <Table className="table">
                        <table>
                          <thead>
                            <tr>
                              <th>Position</th>
                              <th>Company</th>
                              <th>Start Date</th>
                              <th>Resignation</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Software Quality Engineer</td>
                              <td>Lexiqvolax</td>
                              <td>02/07/2019</td>
                              <td>02/07/2019</td>
                            </tr>
                          </tbody>
                        </table>
                      </Table>
                    </PlacementHistory>
                  )}

                  <OfferHistory candidate={candidate} />

                  <DocumentUpload
                    detail={{
                      type: "candidates",
                      uploader: jwtInfo.firstName + " " + jwtInfo.lastName,
                    }}
                    entity={candidate}
                    Service={CandidateService}
                    setEntity={setCandidate}
                  />

                  <AdditionalInfo candidate={candidate} />
                </div>
              </div>
            </section>
          </Container>
        )}
      </Styled>
      {parserModal ? (
        <ParsedData
          medium={parseMedium}
          parsedData={parsedData}
          entity={candidate}
          setEntity={setCandidate}
          Service={CandidateService}
        />
      ) : (
        ""
      )}
    </>
  );
}

const PositionsAvailable = ({
  candidate,
  setCandidate,
  CandidateService,
  position,
  setPosition,
}) => {
  const [positions, setPositions] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPositions = async () => {
    let response = await PositionService.list({
      limit: 10000,
    });
    if (!response.error) {
      //filter out current candidate positions
      let _positions = candidate.statuses
        ? response._embedded.filter(
            p => !candidate.statuses.find(s => s.positionId === p.id)
          )
        : response._embedded;
      setPositions(_positions);
      setFilteredPositions(_positions);
    }
  };
  useEffect(() => {
    getPositions();
  }, []);

  const filterDropdown = e => {
    let value = e.target.value.toLowerCase();

    let regex = new RegExp(value, "i");
    setFilteredPositions([
      ...positions.filter(p => {
        if (p.title.match(regex)) {
          return p;
        }
      }),
    ]);
  };

  /**
   * Add current candidate to selected position
   * @param {object} position
   */
  const addCandidateToPosition = async position => {
    setLoading(true);
    let response = await PositionService.addCandidates({
      id: position.id,
      candidateIds: [candidate.id],
    });
    if (!response.error) {
      //updateStage(response.id);
      toast.success(
        candidate.firstName +
          " has been added to the " +
          position.title +
          "  job order",
        { position: "bottom-center" }
      );

      //new status? or addition to status
      let newPosition = {
        companyId: response._embedded.companyId,
        companyName: response._embedded.company,
        positionId: response.id,
        owner: response._embedded.owner,
        stage: "Short List",
        title: position.title,
        updatedAt: Date.now(),
      };

      //update candidate/current position
      if (candidate.statuses) {
        candidate.statuses.push(newPosition);
      } else {
        candidate.statuses = [newPosition];
      }
      candidate.currentStage = 1;

      setPosition(newPosition);
      console.log("new can", candidate);
      setCandidate({ ...candidate });
    }
    setLoading(false);
  };
  /**
   * Update stage on new position
   * @param {string} id
   */
  const updateStage = async id => {
    PositionService.updateCandidateStage({
      candidateId: candidate.id,
      positionId: id,
      stage: "Short List",
    });
  };

  return (
    <StyledPositionsAvailable>
      <h1>Available Job Orders</h1>
      <div className="content">
        {!loading ? (
          <Dropdown className="dropdown-positions" listId="positions-filter">
            <ButtonDropdown className="positions-filter">Select</ButtonDropdown>
            <div className="list" id="positions-filter">
              <input onKeyUp={filterDropdown} />
              <ul>
                {filteredPositions.map((pos, i) => {
                  return (
                    <li key={i}>
                      <Button
                        size="small"
                        onClick={() => addCandidateToPosition(pos)}
                      >
                        Add to
                      </Button>
                      <span className="title">
                        {pos.title} ({pos.company})
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Dropdown>
        ) : (
          <LoadingSubmit size="25"></LoadingSubmit>
        )}
      </div>
    </StyledPositionsAvailable>
  );
};

const AdditionalInfo = ({ candidate }) => {
  const [notes, setNotes] = useState([]);

  const getNotes = async () => {
    let response = await CandidateService.getLinkedInNotes({
      id: candidate.id,
    });

    if (!response.error) {
      setNotes(response._embedded);
    }
  };
  useEffect(() => {
    getNotes();
  }, [candidate.updateNotes]);

  return (
    <>
      {notes.length ? (
        <StyledAdditionalInfo>
          <h1>Additional Info</h1>
          <div className="content">
            <ul>
              {notes.map((note, i) => {
                return (
                  <li key={i}>
                    <span className="type">{note.title}</span>
                    <article>
                      <SanitizedHTML html={note.note} />
                    </article>
                  </li>
                );
              })}
            </ul>
          </div>
        </StyledAdditionalInfo>
      ) : (
        ""
      )}
    </>
  );
};

const Education = ({ candidate, setCandidate }) => {
  //react-hook-form
  const { register, getValues, setValue, reset } = useForm();

  const [degrees, setDegrees] = useState([]);

  useEffect(() => {
    const getDegrees = async () => {
      //available degrees
      let _degrees = await DegreeService.get({ limit: 200, degree: "" });
      if (_degrees && _degrees._embedded) {
        setDegrees(
          _degrees._embedded.map(d => {
            return { value: d.name, label: d.name };
          })
        );
      }
    };
    getDegrees();
  }, []);

  const onSelectChange = async (opt, actionMeta, education, input) => {
    education.saving = true;
    console.log("opt", opt);
    console.log("meta", actionMeta);

    //allow clearing of degree field
    if (actionMeta.action === "clear") {
      opt = { value: "" };
    }
    console.log(_camelCase(input.replace(/edit/, "")));
    await setValue(_camelCase(input.replace(/edit/, "")), opt.value);
    saveEducation(education, input);
  };

  //save to DB
  const saveEducation = async (education, input) => {
    console.log("input", input);
    let data = getValues();
    console.log("getvalues", getValues());

    //set back to a closed state
    education[input] = false;
    education.saving = true;
    //get real input name
    let inputName = _camelCase(input.replace(/edit/, ""));

    //loop through to fill in missing data
    let deleteItem = true;
    ["endYear", "degreeType", "name"].forEach(i => {
      if (i !== inputName) {
        data[i] = education[i];
        if (education[i] !== "" && education[i] !== undefined) {
          deleteItem = false;
        }
      } else if (data[inputName]) {
        deleteItem = false;
      }
    });

    //DELETE check that item should be deleted (if user deletes all info)
    if (deleteItem) {
      if (deleteItem) {
        if (education.educationId) {
          CandidateService.deleteEducation({
            id: education.educationId,
            candidateId: candidate.id,
          });
        }

        setCandidate({
          ...candidate,
          education: candidate.education.filter(
            e =>
              e.educationId !== undefined &&
              e.educationId !== education.educationId
          ),
        });
        return;
      }
    }

    //ADD education to database if needed
    if (typeof education.educationId === "undefined") {
      let _newEducation = await CandidateService.addEducation({
        candidateId: candidate.id,
        data: education,
      });
      //give education new id
      if (_newEducation._embedded) {
        education.educationId = _newEducation._embedded.educationIds[0];
      }
    }
    setCandidate({
      ...candidate,
      education: [
        ...candidate.education.map(e => {
          if (e === education) {
            return { ...education, ...getValues() }; //incorporate new value
          } else {
            return e;
          }
        }),
      ],
    });
    //update db
    let response = await CandidateService.updateEducation({
      data: data,
      candidateId: candidate.id,
      id: education.educationId,
    }); //TODO, this should no longer need to be an array
    education.saving = false;

    //update candidate on page
    if (response._links) {
      reset();
      setCandidate({
        ...candidate,
        education: [
          ...candidate.education.map(e => {
            if (e === education) {
              return { ...education, ...data };
            } else {
              return e;
            }
          }),
        ],
      });
    }
  };
  //show input field
  const showInput = async (education, input) => {
    education[input] = true;

    await setCandidate({
      ...candidate,
      education: [
        ...candidate.education.map(e => {
          if (e === education) {
            return education;
          } else {
            return e;
          }
        }),
      ],
    });
    //set focus to specific input
    let inputName = _camelCase(input.replace(/edit/, ""));
    let el = document.getElementsByName(inputName)[0];
    if (el) {
      el.focus();
    }

    // setInfoOptions([...infoOptions]);
  };

  const onChange = (e, education) => {
    if (e.target.name === "endYear") {
      e.target.value = e.target.value.replace(/(.{4}).*/, "$1");
    }
  };

  //add Education
  const addEducation = () => {
    setCandidate({
      ...candidate,
      education: [
        ...candidate.education,
        {
          educationId: undefined,
          name: "",
          endYear: "",
          degreeType: "",
          edit: false,
          editName: false,
          editEndYear: false,
          editDegreeType: false,
          saving: false,
        },
      ],
    });
    console.log("can 1", candidate.education);
  };

  //check for ENTER
  const handleKeyDown = (e, option, input) => {
    if (e.key === "Enter" || e.key === "Tab") {
      saveEducation(option, input);
      e.preventDefault();
    }
  };

  return (
    <StyledEducation>
      <h1>Education</h1>

      <div className="content">
        {candidate.education && candidate.education.length ? (
          <ul>
            {candidate.education.map(education => (
              <li key={Math.random()}>
                <div className="school-major">
                  <span>
                    {!education.editName ? (
                      <span
                        className="school"
                        onClick={() => showInput(education, "editName")}
                      >
                        {education.name ? (
                          education.name
                        ) : (
                          <span className="edit-handle">edit school</span>
                        )}
                      </span>
                    ) : (
                      <Form.TextInput
                        name="name"
                        type="text"
                        forwardRef={register}
                        value={education.name}
                        onBlur={() => saveEducation(education, "editName")}
                        onKeyDown={e => handleKeyDown(e, education, "editName")}
                      />
                    )}

                    {!education.editDegreeType ? (
                      <span
                        className="major"
                        onClick={() => showInput(education, "editDegreeType")}
                      >
                        {education.degreeType ? (
                          education.degreeType
                        ) : (
                          <span className="edit-handle">edit degree</span>
                        )}
                      </span>
                    ) : (
                      <div className="select">
                        <Select
                          ref={register({ name: "degreeType" })}
                          isClearable={true}
                          name="degreeType"
                          className="select"
                          classNamePrefix="position-title"
                          options={degrees}
                          placeholder=""
                          value={{
                            label: education.degreeType,
                            value: education.degreeType,
                          }}
                          onBlur={(opt, meta) =>
                            onSelectChange(
                              opt,
                              meta,
                              education,
                              "editDegreeType"
                            )
                          }
                          onChange={(opt, meta) =>
                            onSelectChange(
                              opt,
                              meta,
                              education,
                              "editDegreeType"
                            )
                          }
                        />
                      </div>
                    )}
                  </span>
                </div>

                <time>
                  {!education.editEndYear ? (
                    <span onClick={() => showInput(education, "editEndYear")}>
                      {education.endYear ? (
                        education.endYear
                      ) : (
                        <span className="edit-handle">edit year graduated</span>
                      )}
                    </span>
                  ) : (
                    <Form.TextInput
                      name="endYear"
                      type="text"
                      forwardRef={register}
                      value={education.endYear}
                      onBlur={() => saveEducation(education, "editEndYear")}
                      onKeyDown={e =>
                        handleKeyDown(e, education, "editEndYear")
                      }
                      onChange={e => onChange(e, education)}
                    />
                  )}
                </time>
                {education.saving && (
                  <div className="saving">
                    <LoadingSubmit
                      size={"15"}
                      color={Colors.highlight}
                    ></LoadingSubmit>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
        <Button size="small" onClick={addEducation}>
          Add
        </Button>
      </div>
    </StyledEducation>
  );
};

const Info = ({ candidate, setCandidate }) => {
  const info = [
    {
      label: "First Name",
      value: "firstName",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Last Name",
      value: "lastName",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "City",
      value: "city",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "State",
      value: "state",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Zip",
      value: "zip",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Country",
      value: "country",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Personal Email",
      value: "emailPersonal",
      type: "email",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Work Email",
      value: "emailWork",
      type: "email",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Mobile",
      value: "phone",
      edit: false,
      type: "phone",
      saving: false,
      editable: true,
    },
    {
      label: "LinkedIn",
      value: "linkedinShortened",
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Year Graduated",
      value: "workExperience",
      edit: false,
      type: "year",
      saving: false,
      editable: true,
    },
    {
      label: "Base Salary",
      value: "compensationFormatted",
      type: "select",
      options: compensationBuild(),
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Immigration Status",
      value: "visa",
      type: "select",
      options: visaBuild,
      edit: false,
      saving: false,
      editable: true,
    },
  ];

  return (
    <UpdateableInfo
      info={info}
      detail={{ title: "Info", type: "candidate" }}
      entity={candidate}
      setEntity={setCandidate}
      Service={CandidateService}
    />
  );
};

const CurrentPosition = ({ candidate, setCandidate }) => {
  const [titles, setTitles] = useState([]);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const getTitles = async () => {
      //available titles
      let _titles = await TitleService.get({ limit: 200, title: "" });
      if (_titles && _titles._embedded) {
        setTitles(
          _titles._embedded.map(t => {
            return {
              value: t.name,
              label: t.name,
            };
          })
        );
      }
    };
    getTitles();

    const getCompanies = async () => {
      let _companies = await CompanyService.list({ limit: 10000 });
      console.log("_companies", _companies);
      if (_companies && _companies._embedded) {
        setCompanies(
          _companies._embedded.map(c => {
            return {
              value: c.name,
              label: c.name,
              data: c.companyId, //needed when creating new employment
            };
          })
        );
      }
    };
    getCompanies();
  }, []);

  console.log("titles", titles);
  const info = [
    {
      label: "Company",
      value: "currentEmployments[companyName]",
      type: "select",
      options: companies,
      edit: false,
      saving: false,
      editable: true,
    },
    {
      label: "Level",
      value: "currentEmployments[title]",
      type: "select",
      options: titles,
      edit: false,
      saving: false,
      editable: true,
    },
  ];

  return (
    <>
      {/* unfortunate way of having to do this, for unknown reason props wont populate dropdowns otherwise*/}
      {info[1].options.length && info[0].options.length ? (
        <UpdateableInfo
          info={info}
          detail={{ title: "Current Position", type: "candidate" }}
          entity={candidate}
          setEntity={setCandidate}
          Service={CandidateService}
        />
      ) : (
        <Card title="Current Position">
          <Button primary>Add</Button>
        </Card>
      )}
    </>
  );
};

const OfferHistory = ({ candidate }) => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const getOfferHistory = async () => {
      let _offers = await CandidateService.getPositionHistory({
        id: candidate.id,
        limit: 10,
      });
      console.log("offer", _offers);

      if (_offers._embedded) {
        setOffers(_offers._embedded);
      }
    };
    getOfferHistory();
  }, []);

  console.log("length of offers", offers.length);
  return (
    <>
      {offers.length ? (
        <StyledOfferHistory className="card">
          <h1>TMG History</h1>
          <Table className="table">
            <table>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Client</th>
                  <th>Submitted</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {offers.map((offer, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className="wrap">
                          <div className="m">Position</div>
                          <div className="render">
                            <Link to={"/position/" + offer.positionId}>
                              {offer.title}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">Client</div>
                          <div className="render">
                            <Link to={"/client/" + offer.companyId}>
                              {offer.companyName}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">Submitted</div>
                          <div className="render">{offer.currentStage}</div>
                        </div>
                      </td>
                      <td>
                        <div className="wrap">
                          <div className="m">Reason</div>
                          <div className="render">{offer.rejectionReason}</div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Table>
        </StyledOfferHistory>
      ) : (
        ""
      )}
    </>
  );
};
