import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import CandidateList from "./Candidate/List";
import CandidateNew from "./Candidate/New";
import CandidateDetail from "./Candidate/Detail";
import CandidateEmail from "./Candidate/Email";
import CompaniesList from "./Companies/List";
import CompanyNew from "./Companies/New";
import CompanyDetail from "./Companies/Detail";
import PositionNew from "./Positions/New";
import PositionsList from "./Positions/List";
import PositionDetail from "./Positions/Detail";
//import PositionTest from "./Positions/Test";
import PositionEmail from "./Positions/Email";
import PositionsReporting from "./Positions/Reporting";
import JobOrderInterviewHiringManager from "./JobOrders/InterviewHiringManager";
import JobOrderInterviewCandidate from "./JobOrders/InterviewCandidate";
import Search from "./Search/Index";
import SearchAddToPosition from "./Search/SearchAddToPosition";
import SetPassword from "./User/SetPassword";
import UpdatePassword from "./User/UpdatePassword";
import ResetUsersPassword from "./Settings/ResetUserPassword";
import Login from "./User/Login";
import SettingsIndex from "./Settings/Index";
import ManageEntities from "./Settings/ManageEntities";
import AddUser from "./User/Add";
import AuthorizeEmail from "./Settings/AuthorizeEmail";
import Logout from "./User/Logout";
import DashboardManager from "./Dashboard/Manager";
import Notes from "./Notes/index";

import BizDevDasboard from "./BizDev/Dashboard";
import BizDevContactNew from "./BizDev/Contacts/New";
import BizDevContactsList from "./BizDev/Contacts/List";
import BizDevContactDetail from "./BizDev/Contacts/Detail";
import BizDevCompanyNew from "./BizDev/Companies/New";
import BizDevCompaniesList from "./BizDev/Companies/List";
import BizDevCompanyDetail from "./BizDev/Companies/Detail";

import BizDevEventsList from "./BizDev/Events/List";
import BizDevEventNew from "./BizDev/Events/New";
import BizDevEventDetail from "./BizDev/Events/Detail";

import BizDevConferencesList from "./BizDev/Conferences/List";
import BizDevConferenceNew from "./BizDev/Conferences/New";
import BizDevConferenceDetail from "./BizDev/Conferences/Detail";
import RemoveUser from "./Settings/RemoveUser";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route
        exact
        path="(/bd)?/settings"
        render={props => <SettingsIndex {...props} />}
      ></Route>
      <Route
        exact
        path="(/bd)?/settings/add-user"
        render={props => <AddUser {...props} />}
      ></Route>
      <Route
        exact
        path="(/bd)?/settings/reset-users-password"
        render={props => <ResetUsersPassword {...props} />}
      ></Route>
      <Route
        exact
        path="(/bd)?/settings/remove-user"
        render={props => <RemoveUser {...props} />}
    ></Route>
      <Route
        exact
        path="(/bd)?/oauth_done"
        render={props => <AuthorizeEmail {...props} />}
      ></Route>
      <Route
        exact
        path="(/bd)?/settings/authorize-email"
        render={props => <AuthorizeEmail {...props} />}
      ></Route>

      <Route
        exact
        path="(/bd)?/settings/admin/:entity"
        render={props => <ManageEntities {...props} />}
      ></Route>
      <Route exact path="/login" render={props => <Login />}></Route>
      <Route exact path="/logout" render={props => <Logout />}></Route>
      <Route
        exact
        path="/set-password/:token"
        render={props => <SetPassword {...props} />}
      ></Route>
      <Route
        exact
        path="(/bd)?/settings/update-password"
        render={props => <UpdatePassword {...props} />}
      ></Route>
      <Route
        exact
        path="/dashboard"
        render={props => <DashboardManager />}
      ></Route>
      <Route exact path="(/bd)?/notes" render={props => <Notes />}></Route>
      <Route
        exact
        path="(/bd)?/search"
        render={props => <Search {...props} />}
      ></Route>
      <Route
        exact
        path="(/bd)?/search/add-to-position/:candidateId/:positionId"
        render={props => <SearchAddToPosition {...props} />}
      ></Route>
      <Route
        exact
        path="(/bd)?/search/add-to-position/:candidateId"
        render={props => <SearchAddToPosition {...props} />}
      ></Route>
      <Route
        path="(/bd)?/search/filter::filter/positionId::positionId"
        render={props => <Search {...props} />}
      ></Route>
      <Route
        path="(/bd)?/search/filter::filter/companyId::companyId"
        render={props => <Search {...props} />}
      ></Route>
      <Route
        path="(/bd)?/search/filter::filter"
        render={props => <Search {...props} />}
      ></Route>
      <Route
        exact
        path="/candidates"
        render={props => <CandidateList {...props} />}
      ></Route>
      <Route
        exact
        path="/candidate/new"
        render={props => <CandidateNew {...props} />}
      ></Route>
      <Route
        exact
        path="/candidate/:id"
        render={props => <CandidateDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/candidate/:id/linkedin"
        render={props => <CandidateDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/candidate/email/:id"
        render={props => <CandidateEmail {...props} />}
      ></Route>
      <Route
        exact
        path="/clients"
        render={props => <CompaniesList {...props} />}
      ></Route>
      <Route
        exact
        path="/client/new"
        render={props => <CompanyNew {...props} />}
      ></Route>
      <Route
        exact
        path="/client/:id"
        render={props => <CompanyDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/companies"
        render={props => <CompaniesList {...props} />}
      ></Route>
      <Route
        exact
        path="/company/new"
        render={props => <CompanyNew {...props} />}
      ></Route>
      <Route
        exact
        path="/company/:id"
        render={props => <CompanyDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/job-order/:id/select-interview-times/:target"
        render={props => <JobOrderInterviewHiringManager {...props} />}
      ></Route>
      <Route
        exact
        path="/job-order/:id/confirm-interview-time/:target"
        render={props => <JobOrderInterviewCandidate {...props} />}
      ></Route>
      <Route
        exact
        path="/position/new"
        render={props => <PositionNew {...props} />}
      ></Route>
      <Route
        exact
        path="/positions/reporting"
        render={props => <PositionsReporting {...props} />}
      ></Route>
      <Route
        exact
        path="/positions"
        render={props => <PositionsList {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/position/:id"
        render={props => <PositionDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/position/:id"
        render={props => <PositionDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/position/email/:id"
        render={props => <PositionEmail {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/dashboard"
        render={props => <BizDevDasboard {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/contact/new"
        render={props => <BizDevContactNew {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/contact/:id"
        render={props => <BizDevContactDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/contacts"
        render={props => <BizDevContactsList {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/contacts/:id"
        render={props => <BizDevContactDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/company/new"
        render={props => <BizDevCompanyNew {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/company/:id"
        render={props => <BizDevCompanyDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/companies"
        render={props => <BizDevCompaniesList {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/companies/:id"
        render={props => <BizDevCompanyDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/event/new"
        render={props => <BizDevEventNew {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/event/:id/edit"
        render={props => <BizDevEventNew {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/event/:id"
        render={props => <BizDevEventDetail {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/events/:id"
        render={props => <BizDevEventsList {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/events"
        render={props => <BizDevEventsList {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/conferences"
        render={props => <BizDevConferencesList {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/conference/new"
        render={props => <BizDevConferenceNew {...props} />}
      ></Route>
      <Route
        exact
        path="/bd/conference/:id"
        render={props => <BizDevConferenceDetail {...props} />}
      ></Route>
      <Redirect to="/" />
    </Switch>
  );
}
