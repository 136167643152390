import React, { useState, useEffect, useMemo, useParams } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { AuthService } from "../../services/Auth";
import { UserService } from "../../services/User";
import { BackLink } from "../Shared/Common";

import { Colors } from "../../styles/Colors";
import styled from "styled-components";
import { Container, PageTitle, Button } from "../../styles/Common";
import { Form as StyledForm } from "../../styles/Form";
import { Form } from "../Shared/Form";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Styled = styled.div`
  .items {
    ul {
      column-count: 4;
      column-gap: 20px;
    }
    li {
      padding: 2px 0 2px 10px;
      break-inside: avoid;
      border-left: 1px solid ${Colors.gray};
      display: flex;
      justify-content: space-between;
      border-radius: 3px;
      span {
        margin-bottom: 10px;
      }
      &:hover {
        background: #eee;
      }
      input,
      select {
        margin-right: 5px;
        border: 1px solid ${Colors.gray};
      }
      button {
        margin-right: 0;
      }
    }
  }
`;

export default function RemoveUser(props) {
  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    reset,
  } = useForm();

  //users
  const [users, setUsers] = useState([]);
  const [currentUserId, setCurrentId] = useState();

  useEffect(() => {
    const getUsers = async () => {
      let response = await UserService.list({
        limit: 1000,
        sortBy: "lastName.asc",
        filter: {
          search: {
            NOT: {
              field: "password",
              value: "^$"
            }
          }
        }
      });

      if (response._embedded) {
        console.log("users", response._embedded);
        setUsers(
          response._embedded.map(u => {
            return {
              label: u.firstName + " " + u.lastName,
              value: u.id,
            };
          })
        );
      }
    };
    getUsers();
  }, []);
  //roles

  const onRemoveUser = async () => {
    if (!currentUserId) return

    const response = await UserService.deleteUser(currentUserId)
    if (response.error) {
      setError(response.error);
    } else {
      reset();
      //toast notificiation
      toast.success(
          "User was removed",
          { position: "bottom-center" }
      );
    }

  }

  const onUserChange = async (opt, actionMeta) => {
    console.log("opt", opt);
    console.log("actionMeta", actionMeta);
    setCurrentId(opt.value);
  };

  //first select user, then show associated roles... click change as necessary
  return (
    <Styled>
      <Container className="form">
        <BackLink to="/settings" verbiage="Back to settings" />
        <PageTitle>Remove User</PageTitle>

        <StyledForm>
          <form>
            <ol>
              <Form.Fieldset>
                <Form.InputWrapper
                  label="Select User"
                  name="user"
                  errors={errors}
                  className="react-select position-title"
                >
                  <Select
                    ref={register}
                    name="user"
                    isClearable={false}
                    className="select"
                    classNamePrefix="position-title"
                    options={users}
                    placeholder=""
                    onChange={onUserChange}
                  />
                </Form.InputWrapper>

                {currentUserId ? (
                  <Form.InputWrapper
                    className="row"
                    name="accountRoles"
                    errors={errors}
                    label="Remove User"
                  >
                    <Button onClick={() => onRemoveUser()}>Remove User</Button>
                  </Form.InputWrapper>
                ) : (
                  ""
                )}
              </Form.Fieldset>
            </ol>
          </form>
        </StyledForm>
      </Container>
    </Styled>
  );
}
