import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { CompanyService } from "../../../services/Company";
import { ContactService } from "../../../services/Contact";
import { ActivitiesService } from "../../../services/Activities";
import styled from "styled-components";

import { Colors } from "../../../styles/Colors";
import {
  Container,
  Button,
  PageTitle,
  LinkClose,
  ButtonDropdown,
} from "../../../styles/Common";
import { Dropdown } from "../../Shared/Common";
import { Form } from "../../../components/Shared/Form";
import { Input, Form as StyledForm } from "../../../styles/Form";
import imgCircleX from "../../../assets/circle-x.svg";
import { toast } from "react-toastify";

/** duplicated from Company New,  */
import { AuthService } from "../../../services/Auth";
import { useStore } from "@kwhitley/use-store";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { TagService } from "../../../services/Tag";
import { Validate } from "../../Shared/Validate";

import {
  teamSizesBuild,
  urgencyOptionsBuild,
  jobLevelBuild,
  functionsBuild,
  regionsBuild,
  lookingToHireBuild,
} from "../../Shared/Data";
import { LoadingSubmit } from "../../../styles/Animation";
import { EventService } from "../../../services/Event";
/** duplicated from Company New,  */

import { CandidateService } from "../../../services/Candidate";
import { PositionService } from "../../../services/Position";
import { StageService } from "../../../services/Stage";
import { UserService } from "../../../services/User";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import imgX from "../../../assets/x.svg";

Moment.locale("en");
MomentLocalizer();

const Styled = styled.div`
  .display {
    margin: 44px auto 0;
    width: 721px;
  }
  .search {
    z-index: 100;
  }
  .search-display {
    position: relative;
    label {
      font-size: 12px;
      font-weight: 600;
      color: ${Colors.primary};
      display: block;
      margin: 0 0 10px;
    }
    .dropdown {
      display: none;
      position: absolute;
      top: 38px;
      left: 0;
      background: #fff;
      border: 1px solid #eee;
      width: 100%;
      margin: 0 0px;
      box-sizing: border-box;
      &.active {
        display: block;
      }

      li {
        min-height: 60px;
        box-sizing: border-box;
        font-size: 12px;
        border-bottom: 1px solid ${Colors.gray_accent};
        padding: 20px;
        cursor: pointer;
        &:hover {
          background: #eee;
        }
        .name {
          font-size: 14px;
          font-weight: 500;
          width: 40%;
          margin: 0 10px 0 0;
        }
      }
      .info {
        display: flex;
        justify-content: space-between;
        width: 70%;
      }
      .create-new {
        border: 0;
      }
    }
    .progress {
      margin: 16px 0 0;
      font-size: 16px;
      color: ${Colors.primary};
    }
    .actions {
      margin: 60px 0 0;
      .list {
        li {
          white-space: nowrap;
        }
      }
    }
  }
  .search {
    input {
      width: 100%;
    }
    .go {
      cursor: pointer;
    }
  }
  .filter {
    margin: 29px 0 0;
  }
`;

export default function New(props) {
  //authenticate
  const jwtInfo = AuthService.jwtInfo();

  let params = useParams();

  const [page, setPage] = useState("create");

  //event
  const [BDEvent, setBDEvent] = useState(null);
  //search
  const [searchText, setSearchText] = useState("");
  //stage
  const [stage, setStage] = useState("company");

  //company
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [initCompanies, setInitCompanies] = useState([]);
  const [companySelected, setCompanySelected] = useState(false);
  const [newCompanySelected, setNewCompanySelected] = useState(false);
  //contact
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [initContacts, setInitContacts] = useState([]);
  const [contactSelected, setContactSelected] = useState(false);
  const [newContactSelected, setNewContactSelected] = useState(false);
  //activities
  const [activitiesSelected, setActivitiesSelected] = useState(false);
  const [newActivitiesSelected, setNewActivitiesSelected] = useState(false);
  //position
  const [positionSelected, setPositionSelected] = useState(false);
  const [newPositionSelected, setNewPositionSelected] = useState(false);

  useEffect(() => {
    const getCompanies = async () => {
      let _companies = await CompanyService.list();

      if (_companies._embedded) {
        setFilteredCompanies(_companies._embedded);
        setInitCompanies(_companies._embedded);
      }
    };
    getCompanies();
  }, []);

  useEffect(() => {
    const getContacts = async () => {
      let _contacts = await ContactService.list({
        filter: { owner: false },
      });

      if (_contacts._embedded) {
        setFilteredContacts(_contacts._embedded);
        setInitContacts(_contacts._embedded);
      }
    };
    getContacts();

    if (params && params.id) {
      const getEvent = async () => {
        let _event = await EventService.get(props.match.params.id);

        if (_event.error) {
          props.history.push("/bd/event/" + _event.bdEventId);
        } else {
          _event.id = params.id;
          setPage("edit");

          setBDEvent(_event);
          //company
          if (_event.company.companyId) {
            setCompanySelected(_event.company);
            setNewCompanySelected(false);
            setStage("contact");
          }
          //contact
          if (_event.contact.contactId) {
            setContactSelected(_event.contact);
            setStage("position");
          }
          //position
          if (_event.position.id) {
            setPositionSelected(_event.position);
          }
          if (
            _event.position.id &&
            _event.contact.contactId &&
            _event.companyId
          ) {
            props.history.push("/bd/event/" + _event.bdEventId);
          }
        }
      };
      getEvent();
    }
  }, []);

  const onChange = e => {
    let search = e.target.value;

    switch (stage) {
      case "company":
        setCompanySelected(false);
        break;
      case "contact":
        setContactSelected(false);
        break;
    }
    //setCompanySelected(false);
    if (search) {
      let regex = new RegExp(search, "i");

      switch (stage) {
        case "company":
          setFilteredCompanies(initCompanies.filter(p => p.name.match(regex)));
          break;
        case "contact":
          setFilteredContacts(
            initContacts.filter(
              p => p.firstName.match(regex) || p.lastName.match(regex)
            )
          );
          break;
      }
    } else {
      switch (stage) {
        case "company":
          setFilteredCompanies(initCompanies);
          break;
        case "contact":
          setFilteredContacts(initContacts);
          break;
      }
    }
    setSearchText(search);
  };

  //select entity
  const onSelectEntity = entity => {
    setSearchText("");
    let searchValue = "";
    switch (stage) {
      case "company":
        setCompanySelected(entity);
        searchValue = entity.name;
        break;
      case "contact":
        setContactSelected(entity);
        searchValue = entity.firstName + " " + entity.lastName;
        break;
    }
    document.getElementById("search").value = searchValue;
  };
  const onSelectNew = () => {
    setSearchText("");
    document.getElementById("search").value = "";
    let splitSearchText = searchText.split(" ");
    let lastName = "";
    switch (stage) {
      case "company":
        setNewCompanySelected({ name: searchText });
        break;
      case "contact":
        if (splitSearchText[1]) {
          lastName = splitSearchText[1];
        }

        setNewContactSelected({
          firstName: splitSearchText[0],
          lastName: lastName,
        });
        break;
    }
  };

  //save BD Event
  const saveBDEvent = async continued => {
    //prepare next stage in process
    let nextStage = "";
    let response = {};
    switch (stage) {
      case "company":
        nextStage = "contact";
        break;
      case "contact":
        nextStage = "activities";
        setNewActivitiesSelected(true);
        break;
      case "activities":
        nextStage = "position";
        break;
      case "position":
        nextStage = "";
        //continued = false;
        break;
    }

    if (stage !== "activities") {
      //not created yet
      if (!BDEvent) {
        if (companySelected) {
          response = await EventService.create({
            companyId: companySelected.companyId,
          });
          if (!response.error) {
            setBDEvent({
              bdEventId: response._embedded.bdEventId,
              companyId: response._embedded.companyId,
            });
          }
          //error
          else {
            toast.error(response.error, {
              position: "bottom-center",
            });
          }
        }
      }
      //already created, do update
      else if (BDEvent) {
        //prepare data for update
        let data = {};
        switch (stage) {
          case "company":
            data.companyId = companySelected.companyId;
            break;
          case "contact":
            data.bdContactId = contactSelected.bdContactId;
            break;
          case "position":
            data.positionId = positionSelected.positionId;
            break;
        }

        response = await EventService.update({
          id: BDEvent.bdEventId,
          data: data,
        });

        //if contact, add to company as well
        if (stage === "contact") {
          //add to given company
          let responseCompanyAdd = await ContactService.update({
            id: contactSelected.bdContactId,
            companyId: companySelected.companyId,
          });

          if (responseCompanyAdd.error || responseCompanyAdd.errors) {
            toast.error(responseCompanyAdd.error);
          }
        }
      }
    }

    //check errors, and redirects

    if (!response.error && continued && nextStage) {
      if (!toast.isActive("eventSuccess")) {
        toast.success("Event saved, please add " + nextStage, {
          position: "bottom-center",
          toastId: "eventSuccess",
        });
      }

      setStage(nextStage);
      if (nextStage === "position") {
        setNewPositionSelected(true);
      }

      //reset search input
      let search = document.getElementById("search");
      if (search) {
        search.value = "";
      }
    } else if (!response.error && !continued) {
      if (!toast.isActive("eventSuccess")) {
        toast.success("Event created", {
          position: "bottom-center",
          toastId: "eventSuccess",
        });
        //redirect, no continue
        props.history.push(
          "/bd/event/" + (BDEvent ? BDEvent.bdEventId : response.id)
        );
      }
    } else if (response.error) {
      toast.error(response.error, {
        position: "bottom-center",
      });
    }
  };

  //not bizdev
  if (!jwtInfo || !jwtInfo.roles.includes("BizDev")) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Styled>
      <Container className="form">
        <div>
          <PageTitle>
            {page === "create" ? "Create" : "Edit"} BD Event
          </PageTitle>

          {stage !== "activities" &&
          !newCompanySelected &&
          !newContactSelected &&
          !newPositionSelected ? (
            <LinkClose to="/bd/events">
              <img src={imgCircleX} title="Cancel" alt="Cancel" />
            </LinkClose>
          ) : (
            ""
          )}

          {!newCompanySelected &&
          !newContactSelected &&
          !newActivitiesSelected &&
          !newPositionSelected ? (
            <div className="search-display">
              <label>
                Select a {stage === "company" && "Company"}
                {stage === "contact" && "Contact"}
                {stage === "position" && "Position"}
              </label>
              <Input className="search" type="search">
                <Form.TextInput
                  name="search"
                  type="text"
                  value={""}
                  onChange={onChange}
                  placeholder={"Search..."}
                  autoComplete="off"
                />
                <span className="go"></span>

                {searchText ? (
                  <div className={"dropdown active"}>
                    <ul>
                      {!companySelected &&
                        stage === "company" &&
                        filteredCompanies.map((company, i) => {
                          return (
                            <li key={i} onClick={() => onSelectEntity(company)}>
                              <span className="name">{company.name}</span>
                              {company.recentBdEvent ? (
                                <div className="info">
                                  <span className="opportunity">
                                    {`has an opportunity in ${company.recentBdEvent.stage.toUpperCase()}`}
                                  </span>
                                  <span className="activity">
                                    {`${
                                      company.recentBdEvent.creator.firstName
                                    } ${
                                      company.recentBdEvent.creator.lastName
                                    } added an event ${new Date(
                                      company.recentBdEvent.createdAt
                                    ).toLocaleDateString()}`}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </li>
                          );
                        })}
                      {!contactSelected &&
                        stage === "contact" &&
                        filteredContacts.map((contact, i) => {
                          return (
                            <li key={i} onClick={() => onSelectEntity(contact)}>
                              <span className="name">
                                {contact.firstName} {contact.lastName}
                              </span>
                              {contact.title || contact.companyName ? (
                                <div className="info">
                                  <span className="opportunity">
                                    {contact.title
                                      ? contact.title + " at "
                                      : ""}
                                    {contact.companyName || ""}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </li>
                          );
                        })}
                      {searchText && (
                        <li
                          className="create-new"
                          onClick={() => onSelectNew()}
                        >
                          <span className="name">{searchText}</span>
                          <span>
                            (Create as new {stage === "company" && "company"}
                            {stage === "contact" && "contact"}
                            {stage === "position" && "position"})
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </Input>

              <div className="progress">
                {page === "create" ? "Creating" : "Editing"} a BD Event
                {companySelected && " for " + companySelected.name}
                {contactSelected &&
                  " with " +
                    contactSelected.firstName +
                    " " +
                    contactSelected.lastName}
              </div>

              <div className="actions">
                {(companySelected && stage === "company") ||
                (contactSelected && stage === "contact") ||
                (positionSelected && stage === "position") ? (
                  <Dropdown>
                    <ButtonDropdown primary>Save</ButtonDropdown>
                    <div className="list">
                      <ul>
                        <li onClick={() => saveBDEvent(true)}>
                          Save &amp; Add {stage === "company" && "Contact"}
                          {stage === "contact" && "Activities"}
                        </li>
                        <li onClick={() => saveBDEvent(false)}>
                          Save &amp; Create BD Event
                        </li>
                      </ul>
                    </div>
                  </Dropdown>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {newCompanySelected && (
            <CompanyCreateForm
              contactSelected={contactSelected}
              companySelected={companySelected}
              setCompanySelected={setCompanySelected}
              newCompanySelected={newCompanySelected}
              setNewCompanySelected={setNewCompanySelected}
              saveBDEvent={saveBDEvent}
              history={props.history}
              setStage={setStage}
            />
          )}
          {newContactSelected && (
            <ContactCreateForm
              companySelected={companySelected}
              activitiesSelected={activitiesSelected}
              contactSelected={contactSelected}
              setContactSelected={setContactSelected}
              newContactSelected={newContactSelected}
              setNewContactSelected={setNewContactSelected}
              BDEvent={BDEvent}
              saveBDEvent={saveBDEvent}
              history={props.history}
              setStage={setStage}
            />
          )}
          {newActivitiesSelected && (
            <ActivitiesForm
              positionSelected={positionSelected}
              activitiesSelected={activitiesSelected}
              setActivitiesSelected={setActivitiesSelected}
              newActivitiesSelected={newActivitiesSelected}
              setNewActivitiesSelected={setNewActivitiesSelected}
              BDEvent={BDEvent}
              saveBDEvent={saveBDEvent}
              history={props.history}
              setStage={setStage}
            />
          )}
          {newPositionSelected && (
            <PositionCreateForm
              positionSelected={positionSelected}
              setPositionSelected={setPositionSelected}
              setNewPositionSelected={setNewPositionSelected}
              companySelected={companySelected}
              BDEvent={BDEvent}
              saveBDEvent={saveBDEvent}
              history={props.history}
              setStage={setStage}
            />
          )}
        </div>
      </Container>
    </Styled>
  );
}

const CompanyCreateForm = ({
  contactSelected,
  companySelected,
  setCompanySelected,
  newCompanySelected,
  setNewCompanySelected,
  saveBDEvent,
  page,
}) => {
  //react-hook-form
  const { register, handleSubmit, errors, setValue, reset } = useForm();

  const [saveAndContinue, setSaveAndContinue] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);
  const [parentCompanies, setParentCompanies] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [initAvailableMarkets, setInitAvailableMarkets] = useState([]);
  const [initAvailableSpecialties, setInitAvailableSpecialties] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [initAvailableTechnologies, setInitAvailableTechnologies] = useState(
    []
  );
  const [technologies, setTechnologies] = useState([]);
  const initTeamSizes = teamSizesBuild;
  const teamSizes = initTeamSizes;
  const initSelectValues = {
    parentCompany: [],
    markets: [],
    specialties: [],
    technologies: [],
    size: [],
  };
  const [selectValues, setSelectValues] = useState(initSelectValues);

  //create new select option
  const onCreateOption = (value, name) => {
    let opt = { label: value, value: "-newCompany-", data: name };
    setParentCompanies([...parentCompanies, opt]);
    setValue(name, "-newCompany-"); //for react-hook
    setValue("newParentCompanyName", value);
    selectValues[name] = opt;
    setSelectValues({ ...selectValues });
  };
  const onSelectChange = (opt, actionMeta) => {
    let name = actionMeta.name;

    let value;
    //dropdown
    if (!opt) {
      //empty data
      value = null;
    } else if (opt && opt.value) {
      value = opt.value;
    } else {
      //muliselect
      value = opt;
    }
    selectValues[name] = opt;
    setValue(name, value); //for react-hook
    setSelectValues({ ...selectValues });

    //limit # selections for markets
    if (name === "markets") {
      switch (actionMeta.action) {
        case "select-option":
          if (opt.length >= 3) {
            setMarkets([]); //remove options LIMIT to 2
          }
          break;
        case "remove-value": //add values back in, minus currently selected
          if (!opt) {
            setMarkets(initAvailableMarkets);
          } else {
            setMarkets(initAvailableMarkets.filter(at => !opt.includes(at)));
          }
          break;
      }
    }
    if (name === "specialties") {
      switch (actionMeta.action) {
        case "select-option":
          if (opt.length >= 3) {
            setSpecialties([]); //remove options LIMIT to 2
          }
          break;
        case "remove-value": //add values back in, minus currently selected
          if (!opt) {
            setSpecialties(initAvailableSpecialties);
          } else {
            setSpecialties(
              initAvailableSpecialties.filter(at => !opt.includes(at))
            );
          }
          break;
      }
    }
    if (name === "technologies") {
      switch (actionMeta.action) {
        case "select-option":
          if (opt.length >= 3) {
            setTechnologies([]); //remove options LIMIT to 2
          }
          break;
        case "remove-value": //add values back in, minus currently selected
          if (!opt) {
            setTechnologies(initAvailableTechnologies);
          } else {
            setTechnologies(
              initAvailableTechnologies.filter(at => !opt.includes(at))
            );
          }
          break;
      }
    }
    //reset any errors
    setErrorMessage("");
  };

  const getMarketTags = async () => {
    //available tags
    let _tags = await TagService.get({ type: "market" });
    if (_tags._embedded) {
      let _availableTags = _tags._embedded.map(t => {
        return { value: t.tagId, label: t.tag, data: "markets" };
      });
      setInitAvailableMarkets(_availableTags);

      setMarkets(_availableTags);
    }
  };
  const getSubMarketTags = async () => {
    //available tags
    let _tags = await TagService.get({ type: "submarket" });
    if (_tags._embedded) {
      let _availableTags = _tags._embedded.map(t => {
        return { value: t.tagId, label: t.tag, data: "specialties" };
      });

      setInitAvailableSpecialties(_availableTags);
      setSpecialties(_availableTags);
    }
  };
  const getTechnologyTags = async () => {
    //available tags
    let _tags = await TagService.get({ type: "technology" });
    if (_tags._embedded) {
      let _availableTags = _tags._embedded.map(t => {
        return { value: t.tagId, label: t.tag, data: "technologies" };
      });

      setInitAvailableTechnologies(_availableTags);
      setTechnologies(_availableTags);
    }
  };
  //Parent Companies
  const getParentCompanies = async () => {
    let _parentCompanies = await CompanyService.list();
    if (_parentCompanies._embedded) {
      setParentCompanies(
        _parentCompanies._embedded.map(pc => {
          return { label: pc.name, value: pc.companyId };
        })
      );
    }
  };

  useEffect(() => {
    getParentCompanies();
    getMarketTags();
    getSubMarketTags();
    getTechnologyTags();
  }, []);

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");

    if (e.target.name === "phone") {
      e.target.value = Validate.Phone(e.target.value);
    }
    if (e.target.name === "zip") {
      e.target.value = Validate.Zip(e.target.value);
    }
    if (e.target.name === "country") {
      e.target.value = e.target.value.replace(/(.{3}).*/, "$1");
    }
  };

  const _saveBDEvent = async continued => {
    setSaveAndContinue(continued);
    await handleSubmit(onSubmit)();
  };

  const onSubmit = async data => {
    //convert markets to string for api DB input
    if (data.markets) {
      data.markets = data.markets.map(t => t.value);
    } else {
      data.markets = undefined;
    }
    if (data.specialties) {
      data.specialties = data.specialties.map(t => t.value);
    } else {
      data.specialties = undefined;
    }
    if (data.technologies) {
      data.technologies = data.technologies.map(t => t.value);
    } else {
      data.technologies = undefined;
    }
    //linkedin validate
    if (data.linkedinUrl && !Validate.LinkedIn(data.linkedinUrl)) {
      setErrorMessage("Please enter a linkedin URL in the correct format");
      return;
    }

    //create parent company if needed
    if (data.parentCompany === "-newCompany-") {
      let responseParent = await CompanyService.create({
        ...data,
        name: data.newParentCompanyName,
        parentCompany: undefined,
        newParentCompanyName: undefined,
      });
      if (!responseParent.error) {
        data.parentCompany = responseParent.id;
      } else {
        setErrorMessage("There was an error creating the parent company");
        return;
      }
    } else if (!data.parentCompany) {
      data.parentCompany = undefined;
    }

    //SUBMIT FORM
    setFormSubmission(true);
    data.newParentCompanyName = undefined;
    let response = await CompanyService.create(data);
    setFormSubmission(false);

    if (response.error) {
      setErrorMessage(response.error);
      toast.error(response.error, { position: "bottom-center" });
    } else if (response.id) {
      {
        /* kh, revisit */
      }
      setCompanySelected({ companyId: response.id, name: data.name });
      setNewCompanySelected(false);
      //clear form
      reset();
      //TODO reset linkedin, city, state, workExp
      setMarkets(initAvailableMarkets);
      setSpecialties(initAvailableSpecialties);
      setSelectValues(initSelectValues);
    }
  };

  //ready for bdevent save
  if (companySelected) {
    saveBDEvent(saveAndContinue);
  }
  return (
    <>
      <LinkClose to="#" onClick={() => setNewCompanySelected(false)}>
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>
      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol>
            <Form.Fieldset legend="Company Information" className="rows">
              <Form.InputWrapper
                label="Company Name"
                name="name"
                errors={errors}
                required={true}
              >
                <Form.TextInput
                  name="name"
                  type="text"
                  value={newCompanySelected.name} /* kh, revisit */
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <input
                type="hidden"
                ref={register({ name: "newParentCompanyName", value: "" })}
              />
              <Form.InputWrapper
                label="Parent Company"
                name="parentCompany"
                errors={errors}
                className="react-select position-title"
              >
                <CreatableSelect
                  ref={register({
                    name: "parentCompany",
                  })}
                  name="parentCompany"
                  isClearable={true}
                  className="select"
                  classNamePrefix="position-title"
                  options={parentCompanies}
                  placeholder=""
                  value={selectValues.parentCompany}
                  onCreateOption={value =>
                    onCreateOption(value, "parentCompany")
                  }
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Team Size"
                name="size"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "size",
                  })}
                  name="size"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={teamSizes}
                  value={selectValues.size}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="Market"
                name="markets"
                errors={errors}
                className="react-select tags"
              >
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  ref={register({ name: "markets" })}
                  name="markets"
                  isMulti
                  isClearable={false}
                  className="multi-select"
                  classNamePrefix="tags"
                  onChange={onSelectChange}
                  options={markets}
                  defaultValue={[]}
                  value={selectValues.markets}
                  placeholder=""
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Submarkets"
                name="specialties"
                errors={errors}
                className="react-select tags"
              >
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  ref={register({ name: "specialties" })}
                  isMulti
                  name="specialties"
                  isClearable={false}
                  className="multi-select"
                  classNamePrefix="tags"
                  onChange={onSelectChange}
                  options={specialties}
                  defaultValue={[]}
                  value={selectValues.specialties}
                  placeholder=""
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Technologies"
                name="technologies"
                errors={errors}
                className="react-select tags"
              >
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  ref={register({ name: "technologies" })}
                  isMulti
                  name="technologies"
                  isClearable={false}
                  className="multi-select"
                  classNamePrefix="tags"
                  onChange={onSelectChange}
                  options={technologies}
                  defaultValue={[]}
                  value={selectValues.technologies}
                  placeholder=""
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper label="Website" name="website" errors={errors}>
                <Form.TextInput
                  name="website"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="LinkedIn"
                name="linkedinUrl"
                errors={errors}
              >
                <Form.TextInput
                  name="linkedinUrl"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper label="Phone" name="phone" errors={errors}>
                <Form.TextInput
                  name="phone"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>
            <Form.Fieldset className="rows stacked"></Form.Fieldset>
            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="City"
                name="city"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="city"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="State"
                name="state"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="state"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                />
              </Form.InputWrapper>

              <Form.InputWrapper
                label="Zip"
                name="zip"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="zip"
                  type="text"
                  className="x-small"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  placeholder=""
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Country"
                name="country"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="country"
                  type="text"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  placeholder="USA"
                  className="x-small"
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.FieldsetActions>
              {errorMessage && <Form.Error message={errorMessage} />}
              {!formSubmission ? (
                <>
                  <Dropdown>
                    <ButtonDropdown primary>Save</ButtonDropdown>
                    <div className="list">
                      <ul>
                        {!contactSelected ? (
                          <li onClick={() => _saveBDEvent(true)}>
                            Save &amp; Add Contact
                          </li>
                        ) : (
                          ""
                        )}
                        <li onClick={() => _saveBDEvent(false)}>
                          {page === "create"
                            ? "Save &amp; Create BD Event"
                            : "Save BD Event"}
                        </li>
                      </ul>
                    </div>
                  </Dropdown>
                </>
              ) : (
                <LoadingSubmit></LoadingSubmit>
              )}
            </Form.FieldsetActions>
          </ol>
        </form>
      </StyledForm>
    </>
  );
};

const ActivitiesForm = ({
  positionSelected,
  activitiesSelected,
  setActivitiesSelected,
  setNewActivitiesSelected,
  BDEvent,
  saveBDEvent,
  setStage,
}) => {
  //react-hook-form
  const { handleSubmit, errors, setValue, reset, control } = useForm();

  const [saveAndContinue, setSaveAndContinue] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);
  const [activityTypes, setActivityTypes] = useState([]);

  useEffect(() => {
    const getActivityTypes = async () => {
      let response = await ActivitiesService.listTypes({});
      if (!response.error) {
        setActivityTypes(
          response._embedded.map(at => {
            return { label: at.activityType, value: at.activityType };
          })
        );
        setValue("org", {
          label: "unassigned",
          value: "unassigned",
        });
        setValue("urgency", { label: "Low", value: "low" });
        setValue("tags", []);
      }
    };
    getActivityTypes();
  }, []);

  //pre-save bdevent
  const _saveBDEvent = async continued => {
    setSaveAndContinue(continued);
    await handleSubmit(onSubmit)();
  };

  //Submit Form
  const onSubmit = async data => {
    let errors = false;

    //SUBMIT FORM
    setFormSubmission(true);
    //activityType, org, urgency

    if (!data.tags) {
      errors = true;
      setErrorMessage("Please fill out all fields");
    }

    if (!errors) {
      let response = await ActivitiesService.create({
        org: data.org.value,
        urgency: data.urgency.value,
        activityType: data.tags.map(t => t.value).join("\t"), //temporailiy converting to string w/ tab delimitor until corrected in api
        eventId: BDEvent.bdEventId,
      });

      // console.log("response", response);
      setFormSubmission(false);
      if (response.error || response.errors) {
        setErrorMessage(
          response.error ? response.error : response.errors.message
        );
      } else if (response.id) {
        setActivitiesSelected(true);
        setNewActivitiesSelected(false);
        //success
        //clear form
        reset();
      }
    }
  };

  //ready for bdevent save
  if (activitiesSelected) {
    saveBDEvent(saveAndContinue);
  }

  return (
    <>
      <LinkClose
        to="#"
        onClick={() => {
          setNewActivitiesSelected(false);
          setStage("contact");
        }}
      >
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>
      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <ol>
              <Form.Fieldset legend="Activity Information" className="rows">
                <Form.InputWrapper
                  label="TMG Company"
                  name="org"
                  errors={errors}
                  className="react-select position-title"
                >
                  <Controller
                    as={
                      <Select
                        name="org"
                        isClearable={false}
                        className="select"
                        options={[
                          { label: "unassigned", value: "unassigned" },
                          {
                            label: "Dragonfly Stories",
                            value: "Dragonfly Stories",
                          },
                          { label: "Interim", value: "Interim" },
                          { label: "Permanent", value: "Permanent" },
                          { label: "TMG360", value: "TMG360" },
                        ]}
                        defaultValue={{
                          label: "unassigned",
                          value: "unassigned",
                        }}
                      />
                    }
                    name="org"
                    control={control}
                  />
                </Form.InputWrapper>

                <Form.InputWrapper
                  label="Urgency"
                  name="urgency"
                  className="react-select position-title"
                  errors={errors}
                >
                  <Controller
                    as={
                      <Select
                        name="urgency"
                        isClearable={false}
                        className="select"
                        options={[
                          {
                            label: "Low",
                            value: "low",
                          },
                          { label: "Medium", value: "medium" },
                          { label: "High", value: "high" },
                          { label: "On Hold", value: "onHold" },
                        ]}
                        defaultValue={{
                          label: "Low",
                          value: "low",
                        }}
                      />
                    }
                    name="urgency"
                    control={control}
                  />
                </Form.InputWrapper>

                <Form.InputWrapper
                  label="Activities"
                  name="tags"
                  errors={errors}
                  className="react-select tags"
                >
                  <Controller
                    as={
                      <Select
                        name="tags"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        isMulti
                        className="multi-select"
                        classNamePrefix="tags"
                        isClearable={false}
                        options={activityTypes}
                        defaultValue={[]}
                      />
                    }
                    name="tags"
                    control={control}
                  />
                </Form.InputWrapper>
              </Form.Fieldset>

              <Form.FieldsetActions>
                {errorMessage && <Form.Error message={errorMessage} />}
                {!formSubmission ? (
                  <>
                    <Dropdown>
                      <ButtonDropdown primary>Save</ButtonDropdown>
                      <div className="list">
                        <ul>
                          {!positionSelected ? (
                            <li onClick={() => _saveBDEvent(true)}>
                              Save &amp; Add Position
                            </li>
                          ) : (
                            ""
                          )}
                          <li onClick={() => _saveBDEvent(false)}>
                            Save &amp; Create BD Event
                          </li>
                        </ul>
                      </div>
                    </Dropdown>
                  </>
                ) : (
                  <LoadingSubmit></LoadingSubmit>
                )}
              </Form.FieldsetActions>
            </ol>
          </>
        </form>
      </StyledForm>
    </>
  );
};

const ContactCreateForm = ({
  companySelected,
  activitiesSelected,
  contactSelected,
  setContactSelected,
  newContactSelected,
  setNewContactSelected,
  saveBDEvent,
  setStage,
}) => {
  //authenticate
  const [isLoggedIn] = useStore("isLoggedIn", AuthService.verifyLogin());
  //react-hook-form
  const { register, handleSubmit, errors, getValues, reset } = useForm();

  {
    /* kh, revisit */
  }
  const [saveAndContinue, setSaveAndContinue] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");

    //remove anything over 4 digits
    if (e.target.name.match(/yearEntered|endYear|workExperience/)) {
      e.target.value = e.target.value.replace(/(.{4}).*/, "$1");
    }
    if (e.target.name === "phone") {
      e.target.value = Validate.Phone(e.target.value);
    }
    if (e.target.name === "zip") {
      e.target.value = Validate.Zip(e.target.value);
    }
    if (e.target.name === "country") {
      e.target.value = e.target.value.replace(/(.{3}).*/, "$1");
    }
  };

  //pre-save bdevent
  const _saveBDEvent = async continued => {
    setSaveAndContinue(continued);
    await handleSubmit(onSubmit)();
  };

  //Submit Form
  const onSubmit = async data => {
    //email validation
    if (!Validate.Email(data.email)) {
      setErrorMessage("Please enter a valid personal email address");
      return;
    }
    if (!Validate.Email(data.emailWork)) {
      setErrorMessage("Please enter a valid work email address");
      return;
    }
    //linkedin validate
    if (data.linkedinUrl && !Validate.LinkedIn(data.linkedinUrl)) {
      setErrorMessage("Please enter a linkedin URL in the correct format");
      return;
    }

    //make sure address is not sending empty values if set
    if (!data.address.city || !data.address.state) {
      data.address = undefined;
    }

    //SUBMIT FORM
    setFormSubmission(true);

    let response = await ContactService.create(data);

    setFormSubmission(false);
    if (response.error || response.errors) {
      setErrorMessage(
        response.error ? response.error : response.errors.message
      );
    } else if (response.id) {
      setContactSelected({ bdContactId: response.id });
      setNewContactSelected(false);
      reset();

      //success
      //clear form
    }
  };

  //check authentication
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  //ready for bdevent save
  {
    /* kh, revisit */
  }
  if (contactSelected) {
    saveBDEvent(saveAndContinue);
  }

  return (
    <>
      <LinkClose
        to="#"
        onClick={() => {
          setNewContactSelected(false);
          setStage("company");
        }}
      >
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>
      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <ol>
              <Form.Fieldset legend="Contact Information" className="rows">
                <Form.InputWrapper
                  label="First Name"
                  name="firstName"
                  errors={errors}
                  required={true}
                >
                  <Form.TextInput
                    name="firstName"
                    type="text"
                    value={newContactSelected.firstName}
                    forwardRef={register({ required: true })}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="Last Name"
                  name="lastName"
                  errors={errors}
                  required={true}
                >
                  <Form.TextInput
                    name="lastName"
                    type="text"
                    value={newContactSelected.lastName}
                    forwardRef={register({ required: true })}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="Personal Email"
                  name="personalEmail"
                  errors={errors}
                >
                  <Form.TextInput
                    name="email"
                    type="email"
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper label="Phone" name="phone" errors={errors}>
                  <Form.TextInput
                    name="phone"
                    type="text"
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper label="Title" name="title" errors={errors}>
                  <Form.TextInput
                    name="title"
                    type="text"
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
                {/* removed?<Form.InputWrapper
                  label="Referred by"
                  name="refferedBy"
                  errors={errors}
                >
                  <Form.TextInput
                    name="refferedBy"
                    type="text"
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper> */}
                <Form.InputWrapper
                  label="LinkedIn"
                  name="linkedin"
                  errors={errors}
                >
                  <Form.TextInput
                    name="linkedin"
                    type="text"
                    value={""}
                    forwardRef={register}
                    onChange={onChange}
                  />
                </Form.InputWrapper>
              </Form.Fieldset>
              <Form.Fieldset className="rows stacked">
                <Form.InputWrapper
                  label="City"
                  name="address['city']"
                  errors={errors}
                >
                  <Form.TextInput
                    name="address['city']"
                    forwardRef={register}
                    onChange={onChange}
                    value={""}
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="State"
                  name="address['state']"
                  errors={errors}
                >
                  <Form.TextInput
                    name="address['state']"
                    forwardRef={register}
                    onChange={onChange}
                    value={""}
                  />
                </Form.InputWrapper>

                <Form.InputWrapper
                  label="Zip"
                  name="address['zip']"
                  errors={errors}
                >
                  <Form.TextInput
                    name="address['zip']"
                    type="text"
                    className="x-small"
                    forwardRef={register}
                    onChange={onChange}
                    placeholder=""
                  />
                </Form.InputWrapper>
                <Form.InputWrapper
                  label="Country"
                  name="address['country']"
                  errors={errors}
                >
                  <Form.TextInput
                    name="address['country']"
                    type="text"
                    forwardRef={register}
                    onChange={onChange}
                    placeholder="USA"
                    className="x-small"
                  />
                </Form.InputWrapper>
              </Form.Fieldset>

              <Form.FieldsetActions>
                {errorMessage && <Form.Error message={errorMessage} />}
                {!formSubmission ? (
                  <>
                    <Dropdown>
                      <ButtonDropdown primary>Save</ButtonDropdown>
                      <div className="list">
                        <ul>
                          {!activitiesSelected ? (
                            <li onClick={() => _saveBDEvent(true)}>
                              Save &amp; Add Activities
                            </li>
                          ) : (
                            ""
                          )}
                          <li onClick={() => _saveBDEvent(false)}>
                            Save &amp; Create BD Event
                          </li>
                        </ul>
                      </div>
                    </Dropdown>
                  </>
                ) : (
                  <LoadingSubmit></LoadingSubmit>
                )}
              </Form.FieldsetActions>
            </ol>
          </>
        </form>
      </StyledForm>
    </>
  );
};

const PositionCreateForm = ({
  positionSelected,
  setPositionSelected,
  setNewPositionSelected,
  saveBDEvent,
  setStage,
}) => {
  //needed for default owner
  const jwtInfo = AuthService.jwtInfo();

  const initSelectValues = {
    companyId: [],
    tags: [],
    jobLevel: jobLevelBuild[0],
    functionality: functionsBuild[0],
    region: regionsBuild[0],
    deadline: lookingToHireBuild[0],
    owner: {
      label: jwtInfo.lastName + ", " + jwtInfo.firstName,
      value: jwtInfo.user,
    },
    primary: [],
    secondary: [],
  };
  const [selectValues, setSelectValues] = useState(initSelectValues);
  //react-hook-form
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      deadline: selectValues.deadline.value,
      region: selectValues.region.value,
      owner: selectValues.owner.value,
      jobLevel: selectValues.jobLevel.value,
      functionality: selectValues.functionality.value,
    },
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmission, setFormSubmission] = useState(false);

  const [urgencyOptions, setUrgencyOptions] = useState(urgencyOptionsBuild);
  //Get those Stages
  const [initStageOptions, setInitStageOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  //get those companies
  const [companies, setCompanies] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [users, setUsers] = useState([]);

  const onSelectChange = (opt, actionMeta) => {
    let name = actionMeta.name;
    let value;
    //dropdown
    if (!opt) {
      //empty data
      value = null;
    } else if (opt && opt.value) {
      value = opt.value;
    } else {
      //multiselect
      value = opt;
    }
    selectValues[name] = opt;

    setValue(name, value); //for react-hook
    setSelectValues({ ...selectValues });
    //reset any errors
    setErrorMessage("");

    //when choosing a company, automatically fill out address if not already filled in
    if (name === "companyId") {
      let data = getValues();
      let companySelected = companies.find(c => c.data.companyId === value);
      if (!data["address[city]"]) {
        setValue("address[city]", companySelected.data.city);
      }
      if (!data["address[state]"]) {
        setValue("address[state]", companySelected.data.state);
      }
      if (!data["address[zip]"]) {
        setValue("address[zip]", companySelected.data.zip);
      }
      if (!data["address[country]"]) {
        setValue("address[country]", companySelected.data.country);
      }
    }
  };

  const prepareStages = _stages => {
    return _stages.map(s => {
      let readOnly = false;
      if (
        s.name.match(/Possible|Short List|Presented|Rejected|Passed|^Offer$/)
      ) {
        readOnly = true;
      }
      return {
        label: s.name,
        value: s.stageId,
        checked: true,
        readOnly: readOnly,
      };
    });
  };
  //get candidates
  const getCandidates = async () => {
    let _candidates = await CandidateService.list();

    if (_candidates._embedded) {
      setCandidates(
        _candidates._embedded.map(c => {
          return {
            value: c.candidateId,
            label: c.firstName + " " + c.lastName,
            email: c.emailPersonal ? c.emailPersonal : c.emailWork,
            phone: c.phone,
          };
        })
      );
    }
  };

  //get users
  const getUsers = async () => {
    let response = await UserService.list({
      limit: 1000,
      sortBy: "lastName.asc",
      filter: {
        search: {
          NOT: {
            field: "password",
            value: "^$"
          }
        }
      }
    })

    if (response._embedded) {
      setUsers(
        response._embedded.map(o => {
          return {
            label: o.lastName + ", " + o.firstName,
            value: o.id,
          };
        })
      );
    }
  };
  //get companies
  const getCompanies = async () => {
    let _companies = await CompanyService.list();
    if (_companies && _companies._embedded) {
      setCompanies(
        _companies._embedded.map(c => {
          return { value: c.companyId, label: c.name, data: c };
        })
      );
    }
  };
  //get Stages
  const getStages = async () => {
    let _stages = await StageService.list();

    if (_stages._embedded) {
      setInitStageOptions(prepareStages(_stages._embedded));
      setStageOptions(prepareStages(_stages._embedded));
    }
  };
  useEffect(() => {
    getStages();
    getCompanies();
    getUsers();
    getCandidates();
  }, []);

  //Get those Tags
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      //available tags
      let _tags = await TagService.get({ type: "positionTag" });
      //console.log("tags", _tags);
      if (_tags) {
        setTags(
          _tags._embedded.map(t => {
            return { value: t.tagId, label: t.tag };
          })
        );
      }
    };
    fetchTags();
  }, []);

  const onChange = e => {
    if (e.target.name.match(/phone/)) {
      e.target.value = Validate.Phone(e.target.value);
    }
    //reset any errors
    setErrorMessage("");
  };
  //Submit Form
  const onSubmit = async data => {
    //prevent company coming up as type undefined
    //data.company = data.company ? data.company : "";
    if (!data.companyId) {
      setErrorMessage("Please choose a Client for this position");
      return;
    }
    //company Name is required by API (doesn't look it up dynamically)
    //data.company = companies.find(c => c.companyId === data.companyId).name;
    //console.log("companies", companies);
    //return;

    data.onHold = "No";
    data.status = "Open";

    //reverse it and change to boolean
    data.manualInterview = data.manualInterview ? false : true;

    //map tags to values only if set
    if (data.tags && data.tags.length) {
      data.tags = data.tags.map(t => t.value);
    } else {
      data.tags = [];
    }
    //map stages to ints only if set
    if (data.stages && data.stages.length) {
      data.stages = data.stages.map(s => parseInt(s));
    } else {
      data.stages = [];
    }
    //startDate
    data.kickoff = Moment(
      document.querySelector('input[name="kickoff"]').value
    ).toDate();

    data.hiringManagers = [];
    if (hiringManagers.length) {
      hiringManagers.map(s => {
        if (s.firstName.trim()) {
          //could be blank

          data.hiringManagers.push({
            candidateId: false,
            firstName: s.firstName,
            lastName: s.lastName,
            phone: s.phone,
            email: s.email,
            isPrimary: s.isPrimary,
          });
        } else if (s.candidateId !== "false" && s.candidateId) {
          data.hiringManagers.push({
            candidateId: s.candidateId,
            isPrimary: s.isPrimary,
          });
        }
      });
    }

    //api errors on null
    data.primary = data.primary ? data.primary : undefined;
    data.secondary = data.secondary ? data.secondary : undefined;

    //post to database
    setFormSubmission(true);
    let response = await PositionService.create(data);
    setFormSubmission(false);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.id) {
      {
        /**kh, revisit */
      }
      setPositionSelected({ positionId: response.id });
      setNewPositionSelected(false);

      //clear form
      reset();
      setItems([resetItem(true, false)]);
      setSelectValues(initSelectValues);
      setStageOptions(initStageOptions);
      setUrgencyOptions(urgencyOptionsBuild);
    }
  };

  //set initial school
  const resetItem = (initial, index) => {
    let initial_index = index ? index : Math.random();
    return {
      initial: initial,
      index: initial_index,
      key: initial_index,
      candidateId: "",
      candidate: null,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      focus: "",
      isPrimary: initial ? true : false,
    };
  };
  const [hiringManagers, setItems] = useState([resetItem(true, false)]);

  const addItem = () => {
    //set them, then add new item to the mix
    setItems([...setItemInputValues(), resetItem(false, false)]);
  };
  const deleteItem = item => {
    //set values, then unregister input hooks
    setItems(setItemInputValues().filter(e => e.index != item.index));
    unregister("hiringManagers[" + item.index + "][firstName]");
    unregister("hiringManagers[" + item.index + "][lastName]");
    unregister("hiringManagers[" + item.index + "][phone]");
    unregister("hiringManagers[" + item.index + "][email]");
    unregister("hiringManagers[" + item.index + "][isPrimary]");
  };

  //radio check for primary hiring manager
  const setHiringManagerPrimary = item => {
    setItems([
      ...hiringManagers.map(i => {
        if (i === item) {
          i.isPrimary = true;
        } else {
          i.isPrimary = false;
        }
        return i;
      }),
    ]);
    //setItemInputValues();
  };
  //create new select option
  const onCreateHiringManager = (value, item) => {
    let ele = document.getElementsByName(
      "hiringManagers[" + item.index + "][candidateId]"
    )[0];
    ele.value = "NEW MANAGER-" + value;
    setItems([...setItemInputValues()]);

    return;
  };

  const selectHiringManager = (opt, actionMeta, item) => {
    let ele = document.getElementsByName(actionMeta.name)[0];
    if (actionMeta.action === "clear") {
      ele.value = "";
    } else {
      ele.value = opt.value;
    }

    //ele.id = actionMeta.name;
    //setItems(setItemInputValues);
    setItems([...setItemInputValues()]);

    //document.getElementById(item.index).value = opt.value;
    //hiringManagers[" + props.item.index + "][candidateId]
  };

  const setItemInputValues = () => {
    //need to get all current values of inputs before rewriting
    let inputs = document.querySelectorAll('input[name^="hiringManagers"]'); //, [id^="hiringManagers-"] input
    let _items = [];
    //console.log("inputs", inputs);
    //return [];

    let _id,
      id = "";
    let _buildItem;
    let i = 0;
    let nodes = Array.from(inputs);
    let numInGroup = 0;
    let temp_id = "";
    let r = 1; //iterates to know when next item starts based on prev id being the same
    nodes.forEach((input, x) => {
      //seleced candidate from list
      id = input.name.split("][")[0].split("hiringManagers[")[1];
      if (temp_id !== id) {
        r = 1;
        temp_id = id;
      }
      numInGroup = nodes.filter(n => n.name.match(new RegExp(id))).length;

      if (i === 0 && x === 0) {
        _buildItem = resetItem(true, id);
      }
      _buildItem.index = _buildItem.key = id;
      //get changed value
      //set value for react hook
      if (input.name.match(/candidateId/)) {
        //create a new one!
        if (input.value.match(/NEW MANAGER-/)) {
          _buildItem.candidateId = false;
          _buildItem.firstName = input.value.split("-")[1];
          _buildItem.focus = "lastName";
        } else {
          //fill in candidate
          _buildItem.firstName = ""; //reset if needed
          _buildItem.candidateId = input.value;
          _buildItem.candidate = candidates.find(
            c => c.value === _buildItem.candidateId
          );
        }
      } else if (input.name.match(/firstName/)) {
        _buildItem.firstName = input.value;
        if (input.value !== hiringManagers[i].firstName) {
          _buildItem.focus = "lastName";
        }
      } else if (input.name.match(/lastName/)) {
        _buildItem.lastName = input.value;
        if (input.value !== hiringManagers[i].lastName) {
          _buildItem.focus = "phone";
        }
      } else if (input.name.match(/phone/)) {
        _buildItem.phone = Validate.Phone(input.value);
        if (input.value !== hiringManagers[i].phone) {
          _buildItem.focus = "email";
        }
      } else if (input.name.match(/email/)) {
        _buildItem.email = input.value;
      } else if (input.name.match(/isPrimary/)) {
        _buildItem.isPrimary = input.checked;
      }
      //reset build for new input group
      //TODO: get set of ID's to determine length instead hardcoding below
      //keep _id set to latest id
      _id = id;
      if (_id && r % numInGroup === 0) {
        _items.push(_buildItem);
        _buildItem = resetItem(false);
        i++;
      }
      r++;
    });

    return _items;
  };
  /*
   * Button to add new hiring manager
   */
  const fieldsetActionsHiringManager = () => {
    return (
      <Button
        type="button"
        fieldsetAction
        size="small"
        style={{ marginBottom: "14px" }}
        onClick={() => addItem()}
      >
        Add Hiring Manager
      </Button>
    );
  };

  const ButtonClose = styled.button`
    border: 0;
    padding: 0;
    background: none;
    width: 16px;
    height: 16px;
    margin: ${props => (props.skills ? "13px 0 0 11px" : "35px 0 0")};
  `;
  const HiringManager = props => {
    return (
      <li className="row group">
        <ol>
          {props.item.candidateId !== "false" &&
          props.item.candidateId !== false ? (
            <Form.InputWrapper
              label="Select Manager"
              name={"hiringManagers[" + props.item.index + "][candidateId]"}
              errors={errors}
              className="react-select position-title"
            >
              <CreatableSelect
                id={"hiringManagers-" + props.item.index}
                name={"hiringManagers[" + props.item.index + "][candidateId]"}
                isClearable={true}
                createOptionPosition="first"
                className="select"
                classNamePrefix="position-title"
                options={candidates}
                placeholder=""
                value={
                  props.item.candidateId
                    ? {
                        label: props.item.candidate
                          ? props.item.candidate.label
                          : "",
                        value: props.item.candidateId,
                      }
                    : null
                }
                onCreateOption={value =>
                  onCreateHiringManager(value, props.item)
                }
                onChange={(opt, actionMeta) =>
                  selectHiringManager(opt, actionMeta, props.item)
                }
              />
            </Form.InputWrapper>
          ) : (
            <input
              type="hidden"
              value="false"
              name={"hiringManagers[" + props.item.index + "][candidateId]"}
            />
          )}

          {props.item.candidateId === false ||
          props.item.candidateId === "false" ? (
            <Form.InputWrapper
              label="First Name"
              name={"hiringManagers[" + props.item.index + "][firstName]"}
              errors={errors}
            >
              <Form.TextInput
                name={"hiringManagers[" + props.item.index + "][firstName]"}
                type="text"
                className="smaller"
                value={props.item.firstName}
                onBlur={() => setItems(setItemInputValues)}
                focus={props.item.focus === "firstName"}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}

          {props.item.candidateId === false ||
          props.item.candidateId === "false" ? (
            <Form.InputWrapper
              label={props.item.candidateId === false ? "Last Name" : "Name"}
              name={"hiringManagers[" + props.item.index + "][lastName]"}
              errors={errors}
            >
              <Form.TextInput
                name={"hiringManagers[" + props.item.index + "][lastName]"}
                type="text"
                className="smaller"
                value={props.item.lastName}
                onBlur={() => setItems(setItemInputValues)}
                focus={props.item.focus === "lastName"}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Phone"
              name={"hiringManagers[" + props.item.index + "][phone]"}
              errors={errors}
            >
              {props.item.candidateId === false ||
              props.item.candidateId === "false" ? (
                <Form.TextInput
                  name={"hiringManagers[" + props.item.index + "][phone]"}
                  type="text"
                  className="smaller"
                  value={props.item.phone}
                  onBlur={() => setItems(setItemInputValues)}
                  focus={props.item.focus === "phone"}
                  onChange={onChange}
                />
              ) : (
                <div className="static">
                  {props.item.candidate.phone
                    ? props.item.candidate.phone
                    : "-"}
                </div>
              )}
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Email"
              name={"hiringManagers[" + props.item.index + "][email]"}
              errors={errors}
            >
              {props.item.candidateId === false ||
              props.item.candidateId === "false" ? (
                <Form.TextInput
                  name={"hiringManagers[" + props.item.index + "][email]"}
                  type="email"
                  className="smaller"
                  value={props.item.email}
                  onBlur={() => setItems(setItemInputValues)}
                  focus={props.item.focus === "email"}
                />
              ) : (
                <div className="static">
                  {props.item.candidate.email
                    ? props.item.candidate.email
                    : "-"}
                </div>
              )}
            </Form.InputWrapper>
          ) : (
            ""
          )}
          {props.item.candidateId !== "" ? (
            <Form.InputWrapper
              label="Primary?"
              name={"hiringManagers[" + props.item.index + "][isPrimary]"}
              errors={errors}
            >
              <Form.RadioButton
                id={props.item.index}
                name={"hiringManagers[" + props.item.index + "][isPrimary]"}
                option={{
                  value: props.item.isPrimary ? 1 : 0,
                  checked: props.item.isPrimary,
                }}
                onClick={() => setHiringManagerPrimary(props.item)}
              />
            </Form.InputWrapper>
          ) : (
            ""
          )}
        </ol>
        {!props.item.initial && (
          <ButtonClose type="button" onClick={() => deleteItem(props.item)}>
            <img src={imgX} alt="Delete" title="Delete" />
          </ButtonClose>
        )}
      </li>
    );
  };

  const [automaticScheduling, setAutomaticScheduling] = useState([
    { label: "Automatic Scheduling?", value: 1, checked: false },
  ]);

  //ready for bdevent save
  if (positionSelected) {
    saveBDEvent(false);
  }

  return (
    <>
      <LinkClose
        to="#"
        onClick={() => {
          setNewPositionSelected(false);
          setStage("activities");
        }}
      >
        {" "}
        {/* kh revisit */}
        <img src={imgCircleX} title="Cancel" alt="Cancel" />
      </LinkClose>

      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol>
            <Form.Fieldset className="rows">
              <Form.InputWrapper
                label="Title/Role"
                name="title"
                errors={errors}
                required={true}
              >
                <Form.TextInput
                  name="title"
                  type="text"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Company"
                name="companyId"
                errors={errors}
                className="react-select position-title"
                required={true}
              >
                <Select
                  ref={register({
                    name: "companyId",
                  })}
                  name="companyId"
                  isClearable={true}
                  className="select"
                  classNamePrefix="position-title"
                  options={companies}
                  value={selectValues.companyId}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Job Level"
                name="jobLevel"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "jobLevel",
                  })}
                  name="jobLevel"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={jobLevelBuild}
                  value={selectValues.jobLevel}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Function"
                name="functionality"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "functionality",
                  })}
                  name="functionality"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={functionsBuild}
                  value={selectValues.functionality}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>

              <Form.InputWrapper
                label="Compensation"
                name="compensation"
                errors={errors}
              >
                <Form.TextInput
                  name="compensation"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>

              <Form.InputWrapper
                label="Position Brief"
                name="brief"
                errors={errors}
              >
                <Form.TextInput
                  name="brief"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>
            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="City"
                name="address[city]"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="address[city]"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="State"
                name="address[state]"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="address[state]"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  className="smaller"
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Zip"
                name="address[zip]"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="address[zip]"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  className="smaller"
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Country"
                name="address[country]"
                errors={errors}
                required={false}
              >
                <Form.TextInput
                  name="address[country]"
                  type="text"
                  forwardRef={register({ required: false })}
                  onChange={onChange}
                  placeholder="USA"
                  className="x-small"
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Region"
                name="region"
                errors={errors}
                className="react-select region"
                style={{ width: 214 }}
              >
                <Select
                  ref={register({
                    name: "region",
                  })}
                  name="region"
                  isClearable={false}
                  className="select"
                  classNamePrefix="region"
                  options={regionsBuild}
                  value={selectValues.region}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="Description"
                name="description"
                errors={errors}
              >
                <Form.Textarea
                  className="span-2"
                  name="description"
                  forwardRef={register}
                  onChange={onChange}
                />
              </Form.InputWrapper>

              <Form.InputWrapper
                label="Tags"
                name="tags"
                errors={errors}
                className="react-select tags rowspan-2 "
              >
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  ref={register({ name: "tags" })}
                  isMulti
                  name="tags"
                  isClearable={false}
                  className="multi-select"
                  classNamePrefix="tags"
                  onChange={onSelectChange}
                  options={tags}
                  value={selectValues.tags}
                  placeholder=""
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset
              legend="Hiring Manager(s)"
              fieldsetActions={fieldsetActionsHiringManager()}
            >
              {hiringManagers.length &&
                hiringManagers.map(hiringManager => (
                  <HiringManager key={hiringManager.key} item={hiringManager} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset
              className="rows stacked"
              style={{ position: "relative", zIndex: 1 }}
            >
              <Form.InputWrapper name={"manualInterview"} errors={errors}>
                <Form.Checkboxes
                  forwardRef={register}
                  name={"manualInterview"}
                  options={automaticScheduling}
                  setOptions={setAutomaticScheduling}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset legend="Intake Details" className="rows">
              <Form.InputWrapper
                label="Looking to Hire"
                name="deadline"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "deadline",
                  })}
                  name="deadline"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={lookingToHireBuild}
                  value={selectValues.deadline}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Kick Off Date"
                name="kickoff"
                errors={errors}
              >
                <DateTimePicker
                  time={false}
                  name="kickoff"
                  defaultValue={new Date()}
                  ref={register({
                    name: "kickoff",
                    value: new Date(),
                  })}
                />
              </Form.InputWrapper>
            </Form.Fieldset>
            <Form.Fieldset className="rows stacked">
              <Form.InputWrapper
                label="Owner"
                name="owner"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "owner",
                  })}
                  name="owner"
                  isClearable={false}
                  className="select"
                  classNamePrefix="position-title"
                  options={users}
                  value={selectValues.owner}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Primary"
                name="primary"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "primary",
                  })}
                  name="primary"
                  isClearable={true}
                  className="select"
                  classNamePrefix="position-title"
                  options={users}
                  value={selectValues.primary}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Secondary"
                name="secondary"
                errors={errors}
                className="react-select position-title"
              >
                <Select
                  ref={register({
                    name: "secondary",
                  })}
                  name="secondary"
                  isClearable={true}
                  className="select"
                  classNamePrefix="position-title"
                  options={users}
                  value={selectValues.secondary}
                  placeholder=""
                  onChange={onSelectChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset
              className="rows stacked"
              style={{ position: "relative", zIndex: 1 }}
            >
              <Form.InputWrapper
                label="Urgency"
                name="urgency"
                className="tabs"
                errors={errors}
              >
                <Form.RadioButtons
                  name="urgency"
                  type="text"
                  forwardRef={register}
                  onChange={onChange}
                  options={urgencyOptions}
                  setOptions={setUrgencyOptions}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.Fieldset className="stacked">
              <Form.InputWrapper
                label="Stages"
                name="stages"
                className="columns"
                errors={errors}
              >
                <Form.Checkboxes
                  name="stages"
                  type="text"
                  forwardRef={register}
                  options={stageOptions}
                  setOptions={setStageOptions}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.FieldsetActions>
              {errorMessage && <Form.Error message={errorMessage} />}
              {!formSubmission ? (
                <>
                  <Button primary>Create BD Event</Button>
                </>
              ) : (
                <LoadingSubmit></LoadingSubmit>
              )}
            </Form.FieldsetActions>
          </ol>
        </form>
      </StyledForm>
    </>
  );
};
