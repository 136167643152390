import device from "../config/device";
import styled, { css } from "styled-components";
import { Colors } from "./Colors";
import imgCircleX from "../assets/circle-x.svg";
import imgSearch from "../assets/search.svg";
import imgChecked from "../assets/check.svg";

export const fileUpload = css`
  .filename {
    border: 1px solid ${Colors.gray};
    display: flex;
    justify-content: center;
    &.docx,
    &.doc {
      .ext {
        color: #2b579a;
      }
    }
    &.pdf {
      .ext {
        color: #d91c22;
      }
    }
    &.pages {
      color: #fa9e19;
    }
  }
  .ext {
    text-transform: uppercase;
    margin-right: 10px;
    font-weight: 600;
    white-space: nowrap;
    max-width: 201px;
    overflow: hidden;
  }
`;
export const Form = styled.div`
  form {
    ol > li.rows {
      li {
        display: inline-block;
        margin-right: 15px;
        vertical-align: middle;
      }
    }
  }
  fieldset {
    margin-top: 70px;
    padding: 0;
  }
  .stacked {
    fieldset {
      margin-top: 0;
    }
  }
  legend {
    font-weight: 700;
    font-size: 1.286rem;
    margin: 0 0 15px 10px;
  }
  .group {
    width: 100%;
    display: flex;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  ol ol {
    li {
      margin-bottom: 14px;
      &.error {
        .title {
          .msg {
            color: ${Colors.error};
            font-size: 0.8em;
            display: inline-block;
            margin-left: 5px;
          }
        }
        input {
          border: 1px solid #ff0000;
        }
      }
      &.row {
        li {
          display: inline-block;
          margin-right: 14px;
          vertical-align: top;
        }
        .input {
          input[type="checkbox"] + label,
          .checkbox .overlay + label {
            margin-left: 5px;
          }
        }
        .static,
        .radio {
          height: 40px;
          line-height: 40px;
        }
        .static {
          width: 210px;
        }
      }
    }
    .title {
      padding: 2px 10px;
      label {
        font-size: 0.857rem;
        font-weight: 600;
        abbr {
          color: ${Colors.warn};
          margin-left: 5px;
          text-decoration: none;
        }
      }
    }
  }

  .columns {
    ul {
      columns: 3;
      overflow: hidden;
    }
    .input li {
      padding: 1px;
    }

    @media ${device.tabletSMax} {
      ul {
        columns: 2;
      }
    }
    @media ${device.mobileLMax} {
      ul {
        columns: 1;
      }
    }
  }

  .tabs {
    .input {
      width: 260px;
      ul {
        margin-top: 5px;
      }
      li {
        margin-bottom: 0;
      }
    }
    .radio {
      height: 40px;
      box-sizing: border-box;
      label {
        font-weight: 600;
        position: relative;
        z-index: 5;
        padding: 0 15px;
        white-space: nowrap;
        overflow: hidden;
        line-height: 35px;
      }
      .box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 40px;
        border: none;
        background-color: #efefef;
        border-radius: 3px;
      }
      &.checked {
        .box {
          background-color: ${Colors.primary};
        }
        color: #fff;
      }
    }
  }

  .note {
    font-size: 0.785rem;
    ${fileUpload}
  }

  .actions {
    margin-top: 5.625rem;
  }
  @media ${device.tabletLMax} {
    fieldset {
      margin-top: 40px;
    }
  }
  @media ${device.mobileMMax} {
    fieldset {
      margin-top: 30px;
    }
    .actions {
      margin-top: 20px;

      button {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  .react-select {
    width: 260px;
    vertical-align: top !important;
    .input {
      .select {
        width: 260px;
      }
      .span-2 {
        width: 535px;
      }
      input {
        height: 27px;
      }
    }
    &.region {
      .select {
        width: 214px;
      }
    }
    &.rowspan-2 {
      .tags__value-container {
        height: 104px;
        align-items: flex-start;
        align-content: start;
      }
      .input {
        input {
          height: auto !important;
        }
      }
    }
    .position-title__menu {
      z-index: 10;
    }
    .tags__multi-value {
      background: ${Colors.primary};
      color: #fff;
      border-radius: 10px;
      height: 20px;
    }
    .tags__multi-value__label {
      color: #fff;
      font-size: 0.857rem;
      font-weight: 500;
      line-height: 1.3rem;
      margin-left: 8px;
      padding: 0;
    }
    .tags__multi-value__remove {
      border-radius: 0;
      margin-right: 3px;
      padding: 0;
      margin-left: 5px;
      width: 14.5px;
      height: 20px;
      &:before {
        content: "";
        display: block;
        height: 14.5px;
        width: 14.5px;
        background: url(${imgCircleX}) no-repeat;
        background-size: cover;
        background-position: 0 -14.5px;
      }
      &:hover {
        background-color: transparent;
      }
      svg {
        display: none;
      }
    }
    .tags__menu {
      border: 1px solid #979797;
      box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.2);
      border-radius: 0;
      padding: 0;
      width: 100%;
      z-index: 100;
    }
    .tags__option {
      font-size: 1rem;
      color: ${Colors.primary_accent}bf;
    }
    .tags__option--is-focused {
      background: #eee;
      color: ${Colors.primary_accent};
    }
  }
  .skills {
    .addSkill {
      margin-left: 550px;
    }
    .row {
      margin-bottom: 0;
    }
    .row > ol {
      display: flex;
    }
  }
  .react-select.isolated {
    max-width: 150px;
    margin-right: 10px;
  }

  @media ${device.tabletLMax} {
    .skills .addSkill {
      margin-left: 275px;
    }
    .skills .row > ol {
      display: block;
    }
  }
  @media ${device.tabletSMax} {
    .skills .addSkill {
      margin-left: 0;
    }
  }
  .relevant-skills {
    margin-bottom: 0;
    width: 260px;
    input {
      max-width: 235px;
      margin-bottom: 14px;
    }
  }

  @media ${device.tabletLMax} {
    .react-select .input .span-2 {
      width: 260px;
    }
  }
  .range {
    .display {
      margin: 0 10px;
      display: flex;
      .value {
        margin-right: 10px;
      }
    }
  }
`;
export const FormError = styled.p`
  color: ${Colors.error};
  padding: 10px 0;
`;

const InputRange = css`
  input[type="range"] {
    -webkit-appearance: none;
    margin: 0 0;
    width: 260px;
    border: 0;
    height: 15px;
    padding: 0;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: ${Colors.primary};
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid ${Colors.gray_accent};
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: ${Colors.primary};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: ${Colors.primary};
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #2497e3;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497e3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #2497e3;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #2497e3;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497e3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #2497e3;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #2497e3;
  }
`;
export const Input = styled.div`
  ul {
    margin-top: 20px;
    input + label {
      margin-left: 10px;
      vertical-align: middle;
    }
  }
  input[type="checkbox"] {
    vertical-align: middle;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="search"],
  input[type="number"],
  input[type="range"],
  .mimic-input,
  textarea {
    width: 260px;
    height: 40px;
    padding: 5px 10px;
    border: 1px solid ${Colors.gray_accent};
    border-radius: 3px;
    vertical-align: middle;
    box-sizing: border-box;
    &.full {
      width: 100%;
    }
    &.small {
      width: 191px;
    }
    &.smaller {
      width: 166px;
    }
    &.x-small {
      width: 123px;
    }
  }
  textarea {
    height: 104px;
    &.smaller {
      width: 348px;
    }
  }
  .mimic-input {
    height: auto;
  }

  .error {
    color: ${Colors.error};
  }
  .span-2 input[type="text"],
  textarea {
    width: 535px;
  }
  &.auto {
    textarea {
      width: 100%;
    }
  }
  @media (max-width: 596px) {
    .span-2 input[type="text"],
    textarea {
      width: 260px;
    }
  }
  &.search {
    position: relative;
    input {
      padding-right: 30px;
    }
    .go {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      display: block;
      position: absolute;
      right: 13px;
      top: 13px;
      background: url(${imgSearch}) no-repeat;
      background-size: cover;
    }
  }
  .rw-datetime-picker {
    .rw-widget-container {
      width: 260px;
      border: 1px solid ${Colors.gray_accent};
    }
    input {
      border: 0;
      width: 100% !important;
      height: 38px;
    }
  }
  .rw-cell.rw-state-selected,
  .rw-cell.rw-state-selected:hover {
    border: none;
    background-color: ${Colors.highlight};
  }
  ${InputRange}
`;

export const Tag = styled.div`
  .tags__multi-value {
    background: ${Colors.primary};
    color: #fff;
    border-radius: 10px;
    height: 20px;
  }
  .tags__multi-value__label {
    color: #fff;
    font-size: 0.857rem;
    font-weight: 500;
    line-height: 1.3rem;
    margin-left: 8px;
    padding: 0;
  }
  .tags__multi-value__remove {
    border-radius: 0;
    margin-right: 3px;
    padding: 0;
    margin-left: 5px;
    width: 14.5px;
    height: 20px;
    &:before {
      content: "";
      display: block;
      height: 14.5px;
      width: 14.5px;
      background: url(${imgCircleX}) no-repeat;
      background-size: cover;
      background-position: 0 -14.5px;
    }
    &:hover {
      background-color: transparent;
    }
    svg {
      display: none;
    }
  }
`;

export const Editor = styled.div`
  position: relative;
  .rdw-editor-wrapper {
    margin: 10px 0 0;
    border-radius: 3px;
    border: 1px solid ${Colors.gray_accent};
    position: relative;
    padding-bottom: 40px;
  }
  .rdw-editor-main {
    padding: 0 10px;
    min-height: 80px;
    overflow: visible;
  }
  .rdw-editor-toolbar {
    visibility: visible;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-width: 1px 0 0 0;
  }
  .rdw-option-wrapper {
    border: 0;
    padding: 2px;
    &:hover {
      box-shadow: none;
    }
  }
  .rdw-link-decorator-wrapper {
    text-decoration: underline;
  }
  .rdw-suggestion-dropdown {
    border: 1px solid rgba(198, 203, 211, 0.5);
    box-shadow: 2px 2px 21px 0 rgba(81, 91, 105, 0.2);
    border-radius: 3px;
    padding: 15px;
    max-height: 200px;
    min-width: 150px;
    padding: 0 0;
    left: -16px;
  }
  .rdw-suggestion-option {
    padding: 3px 15px;
    cursor: pointer;
    border-bottom: none;
    position: relative;
    &:before {
      content: "@";
    }
    &:after {
      content: "";
      height: 1px;
      position: absolute;
      border-bottom: 1px solid #f1f1f1;
      width: calc(100% - 30px);
      margin: 0 15px;
      left: 0;
      bottom: 0;
    }
    &:hover {
      background: #eee;
    }
  }
  .rdw-mention-link {
    color: ${Colors.primary};
    background-color: transparent;
    font-weight: 500;
  }
  .public-DraftEditorPlaceholder-inner {
    white-space: nowrap !important;
  }
  .actions {
    text-align: right;
    button {
      margin: 15px 0 0 15px;
      display: inline-block;
    }
  }
`;

export const Tabs = styled.div`
  ul {
    display: flex;
    align-items: center;
    cursor: default;
    li {
      padding: 0 15px;
      border-radius: 3px;
      height: 40px;
      font-weight: 600;
      line-height: 38px;
      &.active {
        color: #fff;
        background-color: ${Colors.primary};
      }
    }
  }
`;

export const CheckboxRadio = styled.span`
  position: relative;
  display: block;
  height: 18px;
  &.radio {
    text-align: center;
  }
  &:before {
    z-index: 100;
    content: "";
    background: transparent;
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
  input {
    position: absolute;
    top: -20px;
    left: -20px;
  }
  .box {
    z-index: 1;
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 18px;
    height: 18px;
    border: 1px solid #a4acb9;
    border-radius: ${props =>
      props.type && props.type == "radio" ? "9px" : "0"};
    background: #eee;
    vertical-align: middle;
    margin: -3px 5px 0 0;
  }
  &.checked .box:before,
  &.indeterminate .box:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 2px;
    width: 14px;
    height: 10px;
    background: url(${imgChecked}) no-repeat;
    background-size: contain;
  }
  &.indeterminate .box:before {
    background: ${Colors.primary};
    height: 2px;
    top: 8px;
  }
  &.read-only {
    opacity: 0.6;
  }
`;
