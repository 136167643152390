import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "../Shared/Form";
import { AuthService } from "../../services/Auth";
import { useForm } from "react-hook-form";
import { UserService } from "../../services/User";
import { Container, Button, PageTitle } from "../../styles/Common";
import { BackLink } from "../Shared/Common";
import { Form as StyledForm } from "../../styles/Form";

export default function AddUser() {
  //console.log("jwt", AuthService.jwtInfo());

  const jwtInfo = AuthService.jwtInfo();

  //if user is not allowed to create user, will hv to redirect him then eh?
  let userCanAccessPage = false;
  if (jwtInfo && jwtInfo.roles.includes("Admin")) {
    userCanAccessPage = true;
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [userCreated, setUserCreated] = useState(false);

  const [accountRoleOptions, setAccountRoleOptions] = useState([]);

  const getUserRoles = async () => {
    let response = await UserService.roles({});
    if (response._embedded) {
      console.log("roles", response._embedded);
      setAccountRoleOptions(
        response._embedded.map((r, i) => {
          return {
            label: r.name,
            value: r.roleId,
            checked: i === 0 ? true : false,
          };
        })
      );
    }
  };

  useEffect(() => {
    getUserRoles();
  }, []);
  //react-hook-form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    setValue,
    reset,
  } = useForm();

  const onSubmit = async data => {
    //post react-hooks validation
    if (
      !data.accountRoles ||
      (data.accountRoles && !data.accountRoles.length)
    ) {
      setError("accountRoles", "required", "Must choose at least one");
      return;
    }
    //convert accountRole strings to Int for api validation
    data.accountRoles = data.accountRoles.map(role => parseInt(role));

    data.password = ""; //can be random

    let response = await UserService.create(data);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.id) {
      //for redirect
      //clear form
      reset();
      //toast notificiation
      toast.success(
        "Contact (" +
          data.firstName +
          " " +
          data.lastName +
          ") has been created",
        { position: "bottom-center" }
      );
      setUserCreated(true);
    }
  };

  const onChange = e => {
    //reset any form error messages
    setErrorMessage("");
    clearError();
  };
  //user is not admin

  //user created or user not allowed on page, send to dashboard
  if (!userCanAccessPage) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Container className="form">
      <BackLink to="/settings" verbiage="Back to settings" />
      <PageTitle>Add User</PageTitle>

      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol>
            <Form.Fieldset>
              <Form.InputWrapper
                label="First Name"
                name="firstName"
                errors={errors}
              >
                <Form.TextInput
                  name="firstName"
                  type="text"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                label="Last Name"
                name="lastName"
                errors={errors}
              >
                <Form.TextInput
                  name="lastName"
                  type="text"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper label="Email" name="email" errors={errors}>
                <Form.TextInput
                  name="email"
                  type="email"
                  forwardRef={register({ required: true })}
                  onChange={onChange}
                />
              </Form.InputWrapper>
              <Form.InputWrapper
                className="row"
                name="accountRoles"
                errors={errors}
                label="Account Roles"
              >
                <Form.Checkboxes
                  name="accountRoles"
                  forwardRef={register}
                  options={accountRoleOptions}
                  setOptions={setAccountRoleOptions}
                  onChange={onChange}
                />
              </Form.InputWrapper>
            </Form.Fieldset>

            <Form.FieldsetActions>
              {errorMessage && <Form.Error message={errorMessage} />}
              <Button primary>Submit</Button>
            </Form.FieldsetActions>
          </ol>
        </form>
      </StyledForm>
    </Container>
  );
}
